import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { Store } from '@ngrx/store';
import {
  FetchBidItemListWithChangeOrderInSideNavRequest,
  FetchBidItemsSitesListRequest,
  FetchChangeOrderFilterInSideNavRequest,
} from '../../../../../../../../core/projects.actions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { bidItemsSiteListInfo, changeOrderFilterInSideNav } from '../../../../../../../../core/projects.selectors';

@Component({
  selector: 'app-bid-items-sites-details',
  templateUrl: './bid-items-sites-details.component.html',
  styleUrls: ['./bid-items-sites-details.component.scss'],
})
export class BidItemsSitesDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data;
  errorMessages: any = {};
  errorMessageMap = {
    unitPrice: {
      required: 'This field is required.',
    },
    totalQuantity: {
      required: 'This field is required.',
    },
    desc: {
      required: 'This field is required.',
    },
    uom: {
      required: 'This field is required.',
    },
  };
  isOpen = false;
  selectedValue = {};
  changeOrderFilter: any;
  form = this.fb.group<any>({
    unitPrice: new FormControl({ value: null, disabled: true }),
    totalQuantity: new FormControl({ value: null, disabled: true }),
    changeOrder: new FormControl({ value: null, disabled: false }),
    desc: new FormControl({ value: null, disabled: false }),
    uom: new FormControl({ value: null, disabled: false }),
  });
  projectId: any;
  pagination = {
    pageIndex: 0, pageSize: 100000,
  };
  siteList: any = [];
  bidItemId: any;
  iterableSitesList: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private store: Store,
    private cdRef: ChangeDetectorRef,
  ) {

  }

  ngOnChanges() {
    if (this.data) {
      this.projectId = this.data?.projectId;
      this.bidItemId = this.data?.bid_item_id;
      this.selectedValue = this.data?.changeOrderFilterSelectedValue;
      if (this.selectedValue['id'] === -1) {
        this.fetchBidItemsSitesList();
      } else {
        this.fetchBidItemListWithChangeOrderInSideNav(this.selectedValue['id']);
      }
    }

    if (this.data?.bid_item_id) {
      const payload = {
        bidItemId: this.data?.bid_item_id,
        qp: {
          include: [
            'change_orders',
          ],
        },
      };
      this.store.dispatch(FetchChangeOrderFilterInSideNavRequest({ payload }));
    }

    this.store.select(changeOrderFilterInSideNav)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data?.list) {
          this.changeOrderFilter = [
            { key: -1, value: 'Current', order_number: 'Current' },
            ...data?.list,
          ];
        }
      });

    this.store.select(bidItemsSiteListInfo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((data: any) => {
        if (data?.bidItemInfo) {
          let unitPriceValue = data?.bidItemInfo?.unit_price;
          let totalQuantityValue = data?.bidItemInfo?.quantity;
          let descriptionValue = data?.bidItemInfo?.description;
          let uomValue = data?.bidItemInfo?.uom;

          if (data?.bidItemInfo?.previous_value?.length > 0) {
            const previousUnitPrice = data?.bidItemInfo?.previous_value[0]?.unit_price;
            const previousQuantity = data?.bidItemInfo?.previous_value[0]?.quantity;
            const previousDescription = data?.bidItemInfo?.previous_value[0]?.description;
            const previousUom = data?.bidItemInfo?.previous_value[0]?.uom;

            if (previousUnitPrice && previousUnitPrice !== unitPriceValue) {
              unitPriceValue = `<span class="highlighted-value text-type-11">${data?.bidItemInfo?.unit_price} </span><span class="text-type-10">(from ${previousUnitPrice})</span>`;
            }

            if (previousQuantity && previousQuantity !== totalQuantityValue) {
              totalQuantityValue = `<span class="highlighted-value text-type-11">${data?.bidItemInfo?.quantity} </span><span class="text-type-10">(from ${previousQuantity})</span>`;
            }

            if (previousDescription && previousDescription !== descriptionValue) {
              descriptionValue = `<span class="highlighted-value text-type-11">${data?.bidItemInfo?.description}</span><span class="text-type-10"> (from ${previousDescription})</span>`;
            }

            if (previousUom && previousUom !== uomValue) {
              uomValue = `<span class="highlighted-value text-type-11">${data?.bidItemInfo?.uom}</span><span class="text-type-10"> (from ${previousUom})</span>`;
            }
          }

          this.form.patchValue({
            unitPrice: unitPriceValue,
            totalQuantity: totalQuantityValue,
            desc: descriptionValue,
            uom: uomValue,
          });

        }

        this.siteList = data?.list;
        this.iterableSitesList = data?.list?.filter(item => item.quantity > 0);
        this.iterableSitesList?.forEach(item => {
          if (item.quantity > 0) {
            this.errorMessageMap[`${item.site_name}`] = {
              required: 'This field is required.',
            };

            let value;
            if (item?.previous_value && item?.previous_value?.length > 0) {
              value = `<span class="highlighted-value text-type-11">${item.quantity}</span><span class="text-type-10"> (from ${item.previous_value[0]?.quantity})</span>`;
            } else {
              value = `${item.quantity}`;
            }

            if (this.form.controls[item.site_name]) {
              this.form.patchValue({ [item.site_name]: value });
            } else {
              this.form.addControl(`${item.site_name}`, new FormControl({ value, disabled: true }));
            }
          }
        });
        this.cdRef.detectChanges();
      });

    this.form.controls.changeOrder.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data !== -1) {
          this.fetchBidItemListWithChangeOrderInSideNav(data);
        } else {
          this.fetchBidItemsSitesList();
        }
      });
  }

  ngOnInit() {
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }

  changeOrderFilterItemClicked(value: any) {
    this.isOpen = false;
    this.selectedValue = value;
    this.form.controls.changeOrder.setValue(value.key);
  }

  fetchBidItemsSitesList() {
    const payload = {
      bidItemId: this.data?.bid_item_id,
      qp: {
        include: [
          'site_quantities',
        ],
      },
    };
    this.store.dispatch(FetchBidItemsSitesListRequest({ payload }));
  }

  fetchBidItemListWithChangeOrderInSideNav(value) {
    const payload = {
      projectId: this.projectId,
      changeOrderId: value,
      qp: {
        include: ['bid_items'],
      },
      bidItemId: this.bidItemId,
    };
    this.store.dispatch(FetchBidItemListWithChangeOrderInSideNavRequest({ payload }));
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

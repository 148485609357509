<div class="wrapper">
  <div class="total-section flex flex-col">
    <div class="quantity-total-label text-type-14">QUANTITY:</div>
    <div class="quantity-total-value text-type-11">{{ quantityTotal | number: '1.2-2' }}</div>
  </div>
  <div class="line-items-section">
    <ng-container *ngFor="let lineItem of lineItems">
      <div
        [class.is-expanded]="selectedLineItemId === lineItem.id"
        class="line-item-accordion"
      >
        <div class="line-item-header flex justify-between items-center">
          <div
            (click)="selectLineItem(lineItem.id)"
            class="left flex justify-start items-center gap-[12px] grow"
          >
            <div class="details text-type-8">
              {{ lineItem.created_at | date: 'MMMM dd, YYYY, hh:mm a' }}
            </div>
          </div>
          <div class="right flex justify-center items-center gap-[10px]">
            <div class="chevron">
              <mat-icon
                (click)="selectLineItem(lineItem.id)"
                [svgIcon]="selectedLineItemId === lineItem.id ? 'chevron-up' : 'chevron-down'"
                class="chevron-icon"
              />
            </div>
            <div *ngIf="!disabled" class="menu-section">
              <mat-icon
                [matMenuTriggerFor]="menu"
                class="menu-icon"
              >more_horiz
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button (click)="deleteLineItem(lineItem.id)" mat-menu-item>
                  <mat-icon svgIcon="trash" />
                  Delete line item
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div [id]="lineItem.id" class="line-item-content">
          <ng-container *ngIf="bidItem?.rollup === false; else allowRollup">
            <form [formGroup]="getFormGroup(form, lineItem.id)">
              <app-number-input
                [isDecimalAllowed]="true"
                [label]="'Quantity (' + bidItem.uom + ')'"
                [parentFormGroup]="getFormGroup(form, lineItem.id)"
                class="each-item-quantity-block"
                controlName="quantity"
              />
              <div
                *ngIf="getFormGroup(form, lineItem.id).controls?.comment !== undefined"
                class="comment-section"
              >
                <app-text-area
                  [parentFormGroup]="getFormGroup(form, lineItem.id)"
                  controlName="comment"
                  label="COMMENT"
                />
              </div>
              <app-pictures-section
                *ngIf="selectedLineItemMediaFiles.length"
                [pictures]="selectedLineItemMediaFiles"
                class="pictures-section"
                (onDelete)="!disabled && deletePicture($event)"
                [isEditable]="!disabled"
              />
              <app-location-section
                *ngIf="showLocationSection(lineItem.id)"
                [latitude]="getFormGroup(form, lineItem.id)?.controls?.latitude?.value"
                [longitude]="getFormGroup(form, lineItem.id)?.controls?.longitude?.value"
                class="location-section"
                (onDelete)="!disabled && deleteLocation(lineItem.id)"
                [isEditable]="!disabled"
              />
            </form>
          </ng-container>
          <ng-template #allowRollup>
            <ng-container *ngIf="bidItem?.rollup_quantity_editable === false; else allowEditableRollup">

              <ng-container *ngIf="isFormReady">
                <form
                  [formGroup]="getFormGroup(form, lineItem.id)"
                >
                  <div class="quantity-section each-item-quantity-block">
                    <div class="disabled-quantity-label text-type-13">QUANTITY ({{ bidItem.uom }}):</div>
                    <div class="disabled-quantity-value text-type-11">{{
                        getFormGroup(form,
                          lineItem.id).controls?.quantity.value
                      }}
                    </div>
                  </div>
                  <div class="fields-row input-block">
                    <ng-container *ngFor="let field of bidItem.fields">
                      <ng-container *ngIf="field.type === 'number' || false">
                        <app-number-input
                          [controlName]="field.id"
                          [label]="field.label + ' (' + field.uom + ')'"
                          [parentFormGroup]="getFormGroup(form, lineItem.id)"
                        />
                      </ng-container>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="!!getFormGroup(form, lineItem.id).controls?.comment"
                    class="comment-section"
                  >
                    <app-text-area
                      [parentFormGroup]="getFormGroup(form, lineItem.id)"
                      controlName="comment"
                      label="COMMENT"
                    />
                  </div>
                  <app-pictures-section
                    *ngIf="selectedLineItemMediaFiles.length"
                    [pictures]="selectedLineItemMediaFiles"
                    class="pictures-section"
                    (onDelete)="!disabled && deletePicture($event)"
                    [isEditable]="!disabled"
                  />
                  <app-location-section
                    *ngIf="showLocationSection(lineItem.id)"
                    [latitude]="getFormGroup(form, lineItem.id)?.controls?.latitude?.value"
                    [longitude]="getFormGroup(form, lineItem.id)?.controls?.longitude?.value"
                    class="location-section"
                    (onDelete)="!disabled && deleteLocation(lineItem.id)"
                    [isEditable]="!disabled"
                  />
                </form>
              </ng-container>
            </ng-container>
            <ng-template #allowEditableRollup>
              <ng-container *ngIf="isFormReady">
                <form [formGroup]="getFormGroup(form, lineItem.id)">
                  <div class="fields-row each-item-quantity-block">
                    <app-dropdown
                      [label]="'QUANTITY (' + bidItem.uom + ')'"
                      [options]="autoCalculateOptions"
                      [parentFormGroup]="getFormGroup(form, lineItem.id)"
                      controlName="valueType"
                    />
                    <ng-container
                      *ngIf="getFormGroup(form, lineItem.id)?.controls?.valueType?.value ===
                      'dynamic_rollup'; else manualEntry"
                    >
                      <div class="quantity-section">
                        <div class="spacer h-[30px]"></div>
                        <div class="disabled-quantity-value text-type-11">{{
                            getFormGroup(form,
                              lineItem.id).controls?.quantity?.value
                          }}
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #manualEntry>
                      <app-number-input
                        [parentFormGroup]="getFormGroup(form, lineItem.id)"
                        controlName="quantity"
                      />
                    </ng-template>
                  </div>
                  <div class="fields-row input-block">
                    <ng-container *ngFor="let field of bidItem.fields">
                      <ng-container *ngIf="field.type === 'number' || false">
                        <app-number-input
                          [controlName]="field.id"
                          [label]="field.label + ' (' + field.uom + ')'"
                          [parentFormGroup]="getFormGroup(form, lineItem.id)"
                        />
                      </ng-container>
                    </ng-container>
                  </div>
                  <div
                    *ngIf="!!getFormGroup(form, lineItem.id).controls?.comment"
                    class="comment-section"
                  >
                    <app-text-area
                      [parentFormGroup]="getFormGroup(form, lineItem.id)"
                      controlName="comment"
                      label="COMMENT"
                    />
                  </div>
                  <app-pictures-section
                    (onDelete)="deletePicture($event)"
                    *ngIf="selectedLineItemMediaFiles.length"
                    [pictures]="selectedLineItemMediaFiles"
                    class="pictures-section"
                    [isEditable]="!disabled"
                  />
                  <app-location-section
                    *ngIf="showLocationSection(lineItem.id)"
                    [latitude]="getFormGroup(form, lineItem.id)?.controls?.latitude?.value"
                    [longitude]="getFormGroup(form, lineItem.id)?.controls?.longitude?.value"
                    (onDelete)="!disabled && deleteLocation(lineItem.id)"
                    [isEditable]="!disabled"
                    class="location-section"
                  />
                </form>
              </ng-container>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="wrapper">
  <div class="heading text-type-5">{{ data?.avgtemp }} &deg;F</div>
  <div class="description-text text-type-10">{{ data?.condition?.text }}</div>
  <div class="wind-speed text-type-10">{{ data?.wind }} MP/H</div>
  <div class="site-details flex flex-row justify-start items-center">
    <div class="label uppercase text-type-13">
      Site:
    </div>
    <div class="site text-type-14 description-text mb-[2px] ml-[5px]">{{ data?.site?.name }}</div>
  </div>
</div>


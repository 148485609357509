import { BaseModel } from './base-model';

export class Attachment extends BaseModel {

  /* tslint:disable */
  mime: string;
  original_name: string;
  original_url: string;
  size: number;
  thumb_url: string;
  preview_url: string;
  fileType?: string;

  // for CASL subject detection on code minification
  static get modelName() {
    return 'Attachment';
  }

  /* tslint:enable */

  get isImage() {
    if (this.mime === 'image/heif') {
      return false;
    }
    return /^image/.test(this.mime);
  }

  get preview() {
    if (this.isImage) {
      return this.preview_url || this.thumb_url || this.original_url;
    }

    if (this.thumb_url || this.preview_url) {
      return this.preview_url || this.thumb_url;
    }

    let icon = '/assets/icons/file.png';
    switch (this.mime) {
      case 'application/pdf':
        icon = '/assets/icons/pdf.png';
        break;
      // case 'text/csv': icon = '/assets/icons/csv.png'; break;
    }
    return icon;
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    delete this.created_at;
    delete this.updated_at;
  }

  getThumbnail() {
    const defaultImg = '/assets/default.jpg';
    return this.thumb_url || (/^image/.test(this.mime) ? this.original_url : defaultImg);
  }
}

<div class="wrapper">
  <div class="heading">
    Bid item {{itemName}}
  </div>
  <div class="content section">
    <mat-tab-group>
      <mat-tab label="Details" selected>
        <app-map-sidenav-bid-item-details
          [data]="data"
        />
      </mat-tab>
      <mat-tab label="Tasks" disabled> Content 2 </mat-tab>
      <mat-tab label="Discussion" disabled> Content 3 </mat-tab>
    </mat-tab-group>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html',
  styleUrls: ['./page403.component.scss']
})
export class Page403Component implements OnInit {

  public targetUrl = 'this page';

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location) { }

  /**
   * OnInit hook
   */
  ngOnInit() {
    // if "url" is available in query-params, mask the window.location with "url"
    // This way, 403 path will not be visible in address bar
    const targetUrl = this.activatedRoute.snapshot.queryParams['url'];
    if (targetUrl) {
      this.targetUrl = targetUrl;
      this.location.replaceState(targetUrl);
    }
  }

}

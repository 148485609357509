<div class="wrapper">
  <div
    *ngIf="picturesData?.picturesList?.length"
    class="carousel-block"
  >
    <button
    *ngIf="!isSideNavEnable"
      class="close-button"
      mat-icon-button
      (click)="clearPicturesData()"
    >
      <mat-icon  title="Close">close</mat-icon>
    </button>
    <app-carousel-type-two
      [slidesData]="picturesData?.picturesList"
      class="image-carousel"
    >
      <ng-template let-carouselOption>
        <app-image
          [url]="carouselOption.preview_url"
        />
      </ng-template>
    </app-carousel-type-two>
    <div class="info">
      <div class="heading">{{ picturesData?.bidItemName }}</div>
      <div class="details">
        <div class="label">COMMENT:</div>
        <div class="value">{{ picturesData?.comment }}</div>
      </div>
    </div>
  </div>

  <div class="details" *ngIf="!isSideNavEnable">
    <div class="header">
      <mat-icon
        class="icon close-icon"
        (click)="closeSidenav()"
      >close
      </mat-icon>
    </div>
    <template #container></template>
  </div>
</div>

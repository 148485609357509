import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  @Input() isDisabled = false;
  @Input() isLoading = false;
  @Input() buttonId = '';
  @Input() color: ThemePalette = '' || 'primary';
  @Output() handleClick = new EventEmitter();
  @Output() handleKeypressEnter = new EventEmitter();

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.handleKeypressEnter.emit();
    }
  }
}

<ng-container *ngIf="initialized">

  <div class="mat-body-strong" *ngIf="!lineage.length">
    No logs found
  </div>

  <!-- List -->
  <div class="list" *ngIf="lineage.length">
    <div class="list-item" *ngFor="let line of lineage">
      <div>
        <b>{{line.state.comment}}</b>
        <span *ngIf="line.actor?.id"> by {{line.actor?.name}}</span>
        <span> on {{line.created_at | dateFormat: 'very-long'}}</span>
      </div>
      <div class="quote" *ngIf="line.input?.formValues?.comments">{{line.input?.formValues?.comments}}</div>
    </div>
  </div>
  <!-- List -->

  <mat-paginator (page)="pageChanged($event)" *ngIf="pagination.length && lineage.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

</ng-container>

<div class="header">
  <div class="left-icons">
    <button (click)="onClose()" mat-icon-button aria-label="Back">
      <mat-icon title="Back">arrow_back</mat-icon>
    </button>
  </div>
  <div class="title">
    <ng-container *ngIf="headerItem?.id">Bid item</ng-container>
    <ng-container *ngIf="!headerItem?.id">Add bid item</ng-container>
  </div>
  <div class="right-icons">
    <button type="button" class="mat-inline-button" mat-button color="primary" (click)="saveAll()"
      [disabled]="!headerItem?._inputForm.controls['heading_id'].valid">Done</button>
  </div>
</div>

<div class="body">
  <div class="heading">
    <div class="mat-subheading-2">{{biditem?.item}}</div>
    <div class="caption">{{biditem?.description}}</div>
  </div>

  <ng-container *ngIf="initialized && !byStation">
    <ng-container [ngTemplateOutlet]="SingleForm" [ngTemplateOutletContext]="{ 'rbi': headerItem }">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="initialized && byStation">

    <!-- Heading selector -->
    <section>
      <mat-form-field>
        <mat-label>Heading</mat-label>
        <mat-select (selectionChange)="onHeadingChange($event.value)" [(value)]="heading">
          <mat-option *ngFor="let heading of headings" [value]="heading">{{heading.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <!-- /Heading selector -->

    <!-- Station adder -->
    <section *ngIf="heading">
      <div class="mat-subheading-1">Add Stations</div>
      <div class="row">
        <mat-form-field>
          <mat-label>From</mat-label>
          <input type="text" matInput [formControl]="fromStation"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of fromOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <mat-form-field>
          <mat-label>To</mat-label>
          <input type="text" matInput [formControl]="toStation"
            [matAutocomplete]="auto2">
          <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of toOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <div class="icons">
          <button mat-icon-button [disabled]="!(fromStation.value && toStation.value)"
            (click)="addStations()">
            <mat-icon title="Add">playlist_add</mat-icon>
          </button>
        </div>
      </div>
    </section>
    <!-- /Station adder -->

    <section *ngIf="rollupField">
      <div class="mat-subheading-1">Quantity</div>
      <mat-form-field>
        <span class="mat-subheading-1" matSuffix *ngIf="rollupField?.uom || biditem?.uom">
          <ng-container *ngIf="rollupField?.uom">(S{{rollupField?.uom}})</ng-container>
          <ng-container *ngIf="!rollupField?.uom && biditem?.uom">({{biditem?.uom}})</ng-container>
        </span>
        <input matInput type="number" autocomplete="off" [disabled]="true" [value]="headerItem?.quantity" />
      </mat-form-field>
    </section>

    <!-- Station Forms -->
    <mat-accordion *ngIf="lineItems.length" class="line-items">
      <mat-expansion-panel *ngFor="let li of lineItems;" [expanded]="li._id === selectedItem?._id">
        <mat-expansion-panel-header (click)="selectLineItem(li)">
          <div class="header-row">
            <span>
              <mat-icon color="warn" *ngIf="(!li._inputForm.valid && !li._inputForm.pristine)">error</mat-icon>
              <ng-container>{{li?.station?.name}}</ng-container>
              <ng-container *ngIf="li?.station_break">
                <mat-icon>emoji_flags</mat-icon>
              </ng-container>
              <ng-container *ngIf="li?.start_of_work">
                <mat-icon>start</mat-icon>
              </ng-container>
            </span>
            <div class="icons">
              <!-- More menu -->
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="toggleFlag(li, 'station_break')" *ngIf="rollupField">
                  <mat-icon>emoji_flags</mat-icon>
                  <span>
                    <ng-container *ngIf="li?.station_break">Remove station break</ng-container>
                    <ng-container *ngIf="!li?.station_break">Add station break</ng-container>
                  </span>
                </button>
                <button mat-menu-item (click)="toggleFlag(li, 'start_of_work')" *ngIf="heading?.is_circular">
                  <mat-icon>start</mat-icon>
                  <span>
                    <ng-container *ngIf="li?.start_of_work">Remove start of work</ng-container>
                    <ng-container *ngIf="!li?.start_of_work">Add start of work</ng-container>
                  </span>
                </button>
                <button mat-menu-item (click)="delete(li)">
                  <mat-icon>delete</mat-icon>
                  <span>Remove station</span>
                </button>

              </mat-menu>
              <!-- /More menu -->
            </div>
          </div>
        </mat-expansion-panel-header>
        <ng-container [ngTemplateOutlet]="SingleForm" [ngTemplateOutletContext]="{'rbi': li}">
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- /Station Forms -->

    <!-- Delete all items -->
    <section>
      <br />
      <button type="button" class="mat-inline-button" mat-button color="primary" (click)="deleteAll()">
        <mat-icon>delete</mat-icon> Delete bid item
      </button>
    </section>
    <!-- /Delete all items -->
    <ng-container [ngTemplateOutlet]="readOnlyToolbarItems"
      [ngTemplateOutletContext]="{'rbi': headerItem}"></ng-container>

  </ng-container>
</div>

<div class="footer" *ngIf="initialized">
  <app-report-item-toolbar *ngIf="selectedItem" [lineItem]="selectedItem" [project]="project"
    (changed)="onLineItemChange($event)"></app-report-item-toolbar>
</div>

<ng-template #SingleForm let-rbi="rbi" let-i="i">
  <form [formGroup]="rbi._inputForm">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
      <input matInput type="text" formControlName="bid_item_id" />
    </section>

    <section *ngIf="!rollupField">

      <div class="width-50">
        <div class="mat-subheading-1">Quantity</div>
        <div
          *ngIf="biditem['rollup']; else notRollup"
          class="rollup-allowed"
        >
          <div
            *ngIf="biditem.rollup_quantity_editable; else rollupNotEditable"
            class="rollup-editable"
          >
            <mat-form-field
              *ngIf="biditem['rollup']"
            >
              <mat-select
                formControlName="isManual"
              >
                <mat-option [value]="false">Auto Calculate</mat-option>
                <mat-option [value]="true">Enter Manually</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <span class="mat-subheading-1" matSuffix *ngIf="biditem?.uom">({{biditem?.uom}})</span>
              <input
                matInput
                type="text"
                onlyDigits
                autocomplete="off"
                formControlName="quantity"
                [errorStateMatcher]="matcher"
              />
              <mat-error *ngIf="rbi._inputForm.controls.quantity.hasError('required')">
                This field is required
              </mat-error>
              <mat-error *ngIf="rbi._inputForm.controls.quantity.hasError('error')">
                {{rbi._inputForm.controls.quantity.errors?.error}}
              </mat-error>
            </mat-form-field>
          </div>
          <ng-template #rollupNotEditable>
            <div class="quantity-not-editable">
              {{ rbi._inputForm.controls.quantity.value }}
            </div>
          </ng-template>
        </div>
        <ng-template #notRollup>
          <mat-form-field>
            <span class="mat-subheading-1" matSuffix *ngIf="biditem?.uom">({{biditem?.uom}})</span>
            <input
              matInput
              type="text"
              onlyDigits
              autocomplete="off"
              formControlName="quantity"
              [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="rbi._inputForm.controls.quantity.hasError('required')">
              This field is required
            </mat-error>
            <mat-error *ngIf="rbi._inputForm.controls.quantity.hasError('error')">
              {{rbi._inputForm.controls.quantity.errors?.error}}
            </mat-error>
          </mat-form-field>
        </ng-template>
      </div>
    </section>

    <section class="custom-fields">
      <app-field-input *ngFor="let field of fields"
                       [notifyOnChange]="field?.id === rollupField?.id ||
                       biditem['rollup']"
        (onValueChange)="updateRollup()" [station]="rbi.station" [field]="field"
        [recentValueQry]="recentValueQry"
        [form]="rbi._inputForm" [matcher]="matcher" [class]="getWidth(field)"></app-field-input>
    </section>
    <div style="clear: both"></div>

    <section *ngIf="!byStation">
      <br />
      <button type="button" class="mat-inline-button" mat-button color="primary" (click)="delete(rbi)">
        <mat-icon>delete</mat-icon> Delete bid item
      </button>
    </section>
  </form>
  <ng-container [ngTemplateOutlet]="readOnlyToolbarItems" [ngTemplateOutletContext]="{'rbi': rbi}"></ng-container>

</ng-template>

<ng-template #readOnlyToolbarItems let-rbi="rbi">
  <section *ngIf="rbi.pictures.length">
    <div style="margin-top: 20px" class="mat-subheading-1">Pictures</div>
    <div class="preview-list">
      <div *ngFor="let pic of rbi.pictures">
        <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, rbi.pictures, pic)" />
      </div>
    </div>
  </section>

  <section *ngIf="rbi.annotations.length">
    <div class="mat-subheading-1">Annotations</div>
    <div class="preview-list">
      <div *ngFor="let ann of rbi.annotations">
        <img [src]="ann.getThumbnail()" class="link" />
      </div>
    </div>
  </section>

  <section *ngIf="!rbi.location.isEmpty">
    <div class="mat-subheading-1">Location</div>
    <div>
      <app-location-picker [isDialog]="false" [latitude]="rbi.location.lat" [longitude]="rbi.location.lng"
        height="100px" [readOnly]="true"></app-location-picker>
    </div>
  </section>

  <section *ngIf="rbi.comment">
    <div class="mat-subheading-1">Comment</div>
    <mat-form-field appearance="outline">
      <textarea matInput rows="5" [value]="rbi.comment" [disabled]="true"></textarea>
    </mat-form-field>
  </section>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';

/* current module imports */
import { IssueRoutingModule } from './issue-routing.module';
import { HomeComponent } from './components/home/home.component';
import { IssueCardComponent } from './components/issue-card/issue-card.component';
import { AccountModule } from '../account/account.module';
import { IssueCardTypeTwoComponent } from './components/issue-card-type-two/issue-card-type-two.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    HomeComponent,
    IssueCardComponent,
    IssueCardTypeTwoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    IssueRoutingModule,
    AccountModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
  ],
  exports: [
    IssueCardComponent,
  ],
})
export class IssueModule {
}

<ng-container *ngIf="initialized">
  <!-- <div *ngIf="!projectReports.length" class="mat-body-strong">Found no reports to show</div> -->

  <!-- Status card -->
  <div class="status-container">
    <ng-container *ngFor="let pr of statuses">
      <div
        [class.active]="pr.status == 'signed'"
        [class.error]="pr.status == 'rejected'"
        [class.in-progress]="pr.status == 'submitted'"
        class="status-card">
        <div class="mat-subheading-2">
          <a
            [routerLink]="pr.status !== 'todo' ? [pr.project_id, 'project-report', pr.id] : []"
            class="no-style"
            [class.link]="pr.status !== 'todo'">
            {{ pr.report_date | dateFormat: 'long' }}
          </a>
        </div>

        <div class="mat-caption" [ngSwitch]="pr.status">
          <ng-container *ngSwitchCase="'todo'">Awaiting creation</ng-container>
          <ng-container *ngSwitchCase="'draft'">Awaiting submission</ng-container>
          <ng-container *ngSwitchCase="'submitted'">Sent for signature</ng-container>
          <ng-container *ngSwitchCase="'rejected'">Rejected</ng-container>
          <ng-container *ngSwitchCase="'signed'">Signed off</ng-container>
        </div>

      </div>
    </ng-container>
  </div>
  <!-- /Status card -->
</ng-container>

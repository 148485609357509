import { BaseModel, Location } from './base-model';
import { Attachment } from './attachment';
import { BidItem } from './bid-item';
import { Annotation } from './annotation';
import { Heading } from './heading';
import { Station } from './station';
import { Field } from './field';
import { InternalTest } from './internal-test';
import * as moment from 'moment';
import { Util } from '../helpers';

export class ReportInternalTest extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'ReportInternalTest'; }

  /* tslint:disable */
  record_time: string;
  comment: string;
  latitude: string;
  longitude: string;
  heading: Heading;
  station: Station;
  bid_item: BidItem;
  internal_test: InternalTest;

  pictures: Attachment[];
  annotations: Annotation[];

  bid_item_id: string;
  internal_test_id: string;
  site_id: string;
  daily_report_id: string;
  heading_id: string;
  station_id: string;
  __deleted: boolean;
  location: Location;

  /* tslint:enable */

  get relations() {
    return {
      pictures: Attachment,
      bid_item: BidItem,
      annotations: Annotation,
      heading: Heading,
      station: Station,
      internal_test: InternalTest,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    this.record_time = this.record_time ?? moment().format('HH:mm:ss');
    // cast
    this.annotations = this.annotations || [];
    this.pictures = this.pictures || [];
    this.location = new Location(this.latitude, this.longitude);
    this._id = Math.random().toString(16).substring(2);

    this.station_id = this.station?.id || this.station_id;
    this.heading_id = this.heading?.id || this.heading_id;
    this.internal_test_id = this.internal_test?.id || this.internal_test_id;
    this.bid_item_id = this.bid_item?.id || this.bid_item_id;

  }

  /**
   * Converts this model to POST payload json object
   * @param include boolean Include line-items or not
   * @return {object}
   */
  toPayload(values: any = {}, fields: Field[] = []) {

    let record_time = values?.record_time || this.record_time || '';
    record_time = /^\d{2}:\d{2}$/.test(record_time) ? `${record_time}:00` : record_time;

    return {
      id: values?.id || this.id || null,
      record_time,
      comment: values?.comment || this.comment || '',
      latitude: values?.latitude || this.location?.lat || '',
      longitude: values?.longitude || this.location?.lng || '',
      bid_item_id: values?.bid_item_id || this.bid_item?.id || null,
      internal_test_id: this.internal_test?.id || this.internal_test_id || null,
      pictures: (values?.pictures || this.pictures || []).map(o => o.id),
      annotations: (values?.annotations || this.annotations || []).map(o => o.id),
      field_values: fields.map((f) => ({ id: f.id, value: values[f.id] })),
      heading_id: values?.heading_id || this.heading?.id || this.heading_id || null,
      station_id: values?.station_id || this.station?.id || null,
    };
  }

  get thumbUrl() {
    const { annotations, pictures } = this;
    if (annotations.length && annotations[0].thumb_url) {
      return annotations[0].thumb_url;
    }
    if (pictures.length) {
      return pictures[0].thumb_url;
    }
    return '/assets/default.jpg';
  }

  /**
   * Returns record_time with local timezone
   */
  get recordTimeZ() {
    return moment().format('YYYY-MM-DDTHH:mm:ssZ')
      .replace(/T\d{2}:\d{2}:\d{2}/, `T${this.record_time}`);
  }
}

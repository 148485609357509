export class CircularArray {
  values: any[] = [];
  pointer: number = 0;
  /**
   *
   * @param {array} values Array
   * @param {integer} pointer Starting position of pointer
   */
  constructor(values = [], pointer = 0) {
    this.values = values;
    this.pointer = pointer;
  }

  /**
   * Is similar to Array.prototype.slice() and works in cirular way
   * If from > to, will return from to array-end and 0 to to
   * e.g, a = new CircularArray(['a', 'b', 'c', 'd', 'e', 'f'])
   * a.slice(4) // returns [e, f]
   * a.slice(1, 3) // returns [b, c]
   * a.slice(4, 2) // returns [e, f, a]
   * @param {number} from arrayIndex
   * @param {number} to arrayIndex
   * @returns {array}
   */
  slice(from, to = null) {
    if (to === null) {
      return this.values.slice(from);
    }
    if (from < to || to < 0) {
      return this.values.slice(from, to);
    }
    return [].concat(this.values.slice(from)).concat(this.values.slice(0, to));
  }
}
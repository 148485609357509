<table [dataSource]="dataSource" class="table" mat-table multiTemplateDataRows>
  <ng-container matColumnDef="action">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell>&nbsp;</th>
    <td *matCellDef="let eachRow" class="text-type-10" mat-cell>
      <mat-icon
        (click)="toggleRow(eachRow); $event.stopPropagation();"
        [ngClass]="{'rotate': expandedElement === eachRow}"
        [svgIcon]="'chevron-right'"
        class="icon"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="item">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell> Bid Item</th>
    <td *matCellDef="let eachRow" mat-cell>
      <div class="text-type-10">{{ eachRow?.item }}</div>
      <div class="description text-type-14">{{ eachRow?.description }}</div>
    </td>
  </ng-container>

  <ng-container matColumnDef="site">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell> Site</th>
    <td *matCellDef="let eachRow" class="text-type-10" mat-cell>
      {{ eachRow?.totalSites }} site{{ eachRow?.site !== 1 ? 's' : '' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell> Quantity</th>
    <td *matCellDef="let eachRow" class="text-type-10" mat-cell>
      {{ eachRow?.totalQuantity | number : '1.0-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell> Amount</th>
    <td *matCellDef="let eachRow" class="text-type-10" mat-cell>
      $ {{ eachRow?.totalAmount | number : '1.0-2' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell> Created By</th>
    <td *matCellDef="let eachRow"
        class="text-type-10"
        mat-cell
    >
      {{ eachRow?.createdByCount }} {{ eachRow?.createdByCount !== 1 ? 'people' : 'person' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th *matHeaderCellDef class="text-type-6" mat-header-cell> Status</th>
    <td *matCellDef="let eachRow" class="text-type-10 " mat-cell>
      <div class="flex flex-row items-center gap-[10px]">
        <div
          [class.disagree]="eachRow.status.disagree && !eachRow.status.agree"
          class="status"
        >
          <ng-container *ngIf="eachRow?.status">
          <span *ngIf="eachRow.status.agree && eachRow.status.disagree">
            {{ eachRow.status.agree }} Agree, {{ eachRow.status.disagree }} Disagree
          </span>
            <span *ngIf="eachRow.status.disagree && !eachRow.status.agree">
            Disagree
          </span>
            <span *ngIf="eachRow.status.agree && !eachRow.status.disagree">
            Agree
          </span>
          </ng-container>
        </div>
        <div class="info-icon"></div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="expandedDetail">
    <td *matCellDef="let eachRow" [attr.colspan]="columnsToDisplay.length" mat-cell>
      <div [@detailExpand]="eachRow == expandedElement ? 'expanded' : 'collapsed'" class="detail-section">
        <div (click)="onRowSelect(child)"
             *ngFor="let child of eachRow?.children; let i = index"
             class="detail w-full flex flex-row justify-start items-start"
             [class.selected-child]="selectedChildRowId === child?.id"
        >
          <div class="detail-action flex flex-col justify-center items-start text-type-10">
            <div class="icon"> &nbsp;</div>
          </div>
          <div class="detail-item flex flex-col justify-center items-start text-type-10">
            <div class="text-type-10">&nbsp;</div>
            <div class="description text-type-14">&nbsp;</div>
          </div>
          <div class="detail-site flex flex-col justify-center items-start text-type-10">
            <span class="detail-site-name">{{ child?.site?.name }}</span>
          </div>
          <div class="detail-quantity flex flex-col justify-center items-start text-type-10">
            {{ child?.quantity | number : '1.0-2' }}
          </div>
          <div class="detail-amount flex flex-col justify-center items-start text-type-10">
            $ {{ child?.totalAmount | number : '1.0-2' }}
          </div>
          <div class="detail-created-by flex flex-col justify-center items-start text-type-10">
            {{ child?.dailyReportCreatedBy?.first_name }} {{ child.dailyReportCreatedBy?.last_name }}
          </div>
          <div class="detail-status flex flex-col justify-center items-start text-type-10">
            <div class="flex flex-row justify-between items-center gap-[10px]">
              <div
                (click)="$event.stopPropagation();"
                [class.disagree]="!child?.status"
                [matMenuTriggerFor]="menu"
                class="status flex flex-row justify-between items-center"
              >
                {{ child?.status ? 'Agree' : 'Disagree' }}
                <mat-icon
                  [ngClass]="{'rotate': isOpen}"
                  class="menu-list-icon"
                  svgIcon="chevron-down"
                />
              </div>
              <mat-menu #menu="matMenu" class="status-menu-panel">
                <button
                  (click)="onSelect(status.key, eachRow.id, i, child)"
                  *ngFor="let status of statusList"
                  mat-menu-item
                >
                  {{ status?.value }}
                </button>
              </mat-menu>
              <mat-icon
                (click)="$event.stopPropagation(); onSelect(0,eachRow.id, i, child)"
                *ngIf="child.contractor_status === 'disagree'; else emptyDiv"
                class="info-icon"
                svgIcon="issues"
              />
              <ng-template #emptyDiv>
                <div class="info-icon"></div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </td>
  </ng-container>

  <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
  <tr *matRowDef="let eachRow; columns: columnsToDisplay"
      class="each-row"
      mat-row>
  </tr>
  <tr
    *matRowDef="let eachRow; columns: ['expandedDetail']"
    [class.expanded-row]="expandedElement === eachRow" class="detail-row"
    mat-row
  >

  </tr>
</table>



<!-- <mat-progress-bar color="accent" mode="indeterminate" *ngIf="loading"></mat-progress-bar> -->
<div *ngIf="initialized">

  <div class="row">
    <div></div>
    <div class="icons">
      <button mat-flat-button color="accent" (click)="openEdit()"
        [disabled]="!('create' | able: 'MaterialTestLab')">Add Lab</button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!labs.length">
    Found no records to show
  </div>

  <!-- List -->
  <div class="list">

    <!-- list-card -->
    <div class="list-card" *ngFor="let lab of labs">

      <!-- actions -->
      <div class="hover-icons">
        <a mat-fab
          [disabled]="!('update' | able: lab)"
          (click)="openEdit(lab)"
          color="accent"
          aria-label="Edit {{lab.name}}"
          title="Edit {{lab.name}}">
          <mat-icon>edit</mat-icon>
        </a>
      </div>
      <!-- /actions -->

      <div class="body">
        <div class="mat-subheading-2">{{lab.name}}</div>
        <div class="mat-caption"><mat-icon color="accent">phone</mat-icon> {{lab.phone}}</div>
        <div class="mat-caption"><mat-icon color="accent">email</mat-icon> {{lab.email}}</div>
      </div>
    </div>
    <!-- list-card -->

  </div>
  <!-- /List -->

  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && labs.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

</div>

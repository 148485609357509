<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <div
    *ngIf="label"

    class="form-field-label text-type-13 mb-[8px]"
  >
    {{ label }}
  </div>
  <mat-form-field
    [formGroup]="parentFormGroup"
    appearance="outline"
    class="form-field"
    hideRequiredMarker
  >
    <textarea
      #textarea
      [formControlName]="controlName"
      [maxlength]="maxLength"
      class="text-area"
      matInput
      placeholder="{{placeholder}}"
      rows="4"
    >
    </textarea>
    <mat-error *ngIf="error">{{ error }}</mat-error>
  </mat-form-field>

</div>

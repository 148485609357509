import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MaterialTest, Project } from 'src/app/shared/models';
import { MaterialTestService, ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-material-tests',
  templateUrl: './project-material-tests.component.html',
  styleUrls: ['./project-material-tests.component.scss']
})
export class ProjectMaterialTestsComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input()
  public project: Project;

  public materialTests: MaterialTest[] = [];
  public projectMaterialTests: MaterialTest[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private materialTestService: MaterialTestService
  ) { }

  ngOnInit(): void {
    this.fetchMaterialTests();
  }

  /**
   * Fetch all material-tests
   */
  fetchMaterialTests() {
    this.materialTestService.getRecords({ pageIndex: 0, pageSize: 10000 })
      .then((resp: any) => {
        this.materialTests = resp.result;
        this.fetchProjectMaterialTests();
      })
      .catch((errResp: HttpErrorResponse) => {
        this.snackBar.open(errResp.message, '', { duration: 5000 });
      });
  }

  /**
   * Fetch project's material-tests
   */
  fetchProjectMaterialTests() {
    this.materialTestService.getProjectRecords(this.project, { pageIndex: 0, pageSize: 10000 })
      .then((resp: any) => {
          this.projectMaterialTests = resp.result;
          this.initialized = true;
          // mark material-tests as checked/unchecked
          this.materialTests.map((o: any) => o.checked = !!this.projectMaterialTests.find(t => t.id === o.id));
        })
        .catch((errResp: HttpErrorResponse) => {
          this.snackBar.open(errResp.message, '', { duration: 5000 });
        });
  }

  /**
   * OnChange event of checkbox
   * @param materialTest MaterialTest
   * @param checked boolean
   */
  onChange(materialTest: MaterialTest, checked) {
    if (checked) {
      this.addMaterialTestToProject(materialTest);
    } else {
      this.removeMaterialTestFromProject(materialTest);
    }
  }

  /**
   * Add material-test to project
   * @param materialTest MaterialTest
   */
  addMaterialTestToProject(materialTest) {
    this.materialTestService.addMaterialTestToProject(this.project, materialTest)
      .subscribe({
        next: (resp: any) => {
          this.snackBar.open(resp.message, '', { duration: 5000 });
        },
        error: (errResp: HttpErrorResponse) => {
          this.snackBar.open(errResp.message, '', { duration: 5000 });
        }
      });
  }

  /**
   * Add material-test to project
   * @param materialTest MaterialTest
   */
  removeMaterialTestFromProject(materialTest) {
    this.materialTestService.removeMaterialTestFromProject(this.project, materialTest)
      .subscribe({
        next: (resp: any) => {
          this.snackBar.open(resp.message, '', { duration: 5000 });
        },
        error: (errResp: HttpErrorResponse) => {
          this.snackBar.open(errResp.message, '', { duration: 5000 });
        }
      });
  }

}

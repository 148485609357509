import { BaseModel } from './base-model';
import { Field } from './field';
import { Heading } from './heading';
import { InternalTest } from './internal-test';
import { Material } from './material';
import { Project } from './project';
import { Site } from './site';

export class BidSheet extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() { return 'BidSheet'; }

  /* tslint:disable */
  name: string;
  bidItems: BidItem[];
  project: Project;

  project_id: string; // for acl
  /* tslint:enable */

  get relations() {
    return {
      project: Project,
      bidItems: BidItem,
    };
  }
}

export class BidItem extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'BidItem'; }

  /* tslint:disable */
  item: string;
  quantity: number;
  unit_price: number;
  uom: string;
  description: string;
  record_by_station: boolean;
  track_material_quantity: boolean;
  site: Site;
  site_id: string;
  headings: Heading[];
  materials: Material[];
  internal_tests: InternalTest[];
  fields: Field[];
  project: Project;
  rollup_field: Field;
  rollup_formula: string;
  rollup_quantity_editable: boolean;
  site_quantities: any[];

  project_id: string; // for acl
  bid_sheet_id: string;
  /* tslint:enable */

  get relations() {
    return {
      site: Site,
      headings: Heading,
      fields: Field,
      project: Project,
      materials: Material,
      internal_tests: InternalTest,
      rollup_field: Field,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.site_id = this.site?.id || this.site_id;
    this.project_id = this.project?.id || this.project_id;
  }

}

<div class="container">

  <nav mat-tab-nav-bar class="sub-nav" *ngIf="editing">
    <a mat-tab-link active="active">
      <ng-container *ngIf="lookup?.id">Update {{lookup?.value}}</ng-container>
      <ng-container *ngIf="!lookup?.id">Add lookup</ng-container>
    </a>
  </nav>
  <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- lookups listing -->
  <div class="row">
    <div>&nbsp;</div>
    <div class="icons">
      <button mat-icon-button aria-label="Add lookup" color="none" [disabled]="!canEdit"
        (click)="toggleForm(null, true)">
        <mat-icon title="Add">add</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!lookups.length">
    No lookups found for this {{type}}
    <button mat-button color="accent" class="mat-inline-button" [disabled]="!canEdit" (click)="toggleForm(null, true)"
      aria-label="Add" title="Add">
      Add lookup?</button>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let record of lookups">
      <div class="body">
        <div class="mat-subheading-2">
          {{record.value}}
        </div>
      </div>
    </div>
  </div>
  <!-- Lookup listing -->
</ng-template>


<ng-template #InputFormContent>
  <!-- Lookup Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!editing">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>
    <!-- value section -->
    <section>
      <mat-form-field>
        <mat-label>Value</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="value" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.value.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.value.hasError('error')">
          {{inputForm.controls.value.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /value section -->

    <section>&nbsp;</section>
    <!-- Form Actions -->
    <section>
      <div class="row" *ngIf="editing">
        <button mat-stroked-button color="accent" [disabled]="!canEdit" (click)="save(inputForm)">
          <ng-container *ngIf="lookup?.id">Update</ng-container>
          <ng-container *ngIf="!lookup?.id">Create</ng-container>
        </button>
        <button mat-stroked-button (click)="onCancel()" [disabled]="!canEdit">Cancel</button>
      </div>
    </section>
    <!-- Form Actions -->
  </form>
  <!-- /Input form -->
</ng-template>
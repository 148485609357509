import { Injectable } from '@angular/core';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { v1URL, v2URL } from '../constants/urls.constants';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private commonServices: CommonService) {
  }

  fetchAppData(): Observable<any> {
    const urlConfig = v1URL.appData;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url);
  }

  uploadFile(payload: any): Observable<any> {
    const urlConfig = v1URL.attachment;

    return this.commonServices.callAPI(urlConfig.method, urlConfig.url, payload, {
      headers: new HttpHeaders({}),
    });
  }

  getVideoById(payload: any): Observable<any> {
    const urlConfig = v2URL.video.fetch;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url(payload));
  }

  getVideoId(): Observable<any> {
    const urlConfig = v2URL.video.getId;
    return this.commonServices.callAPI(urlConfig.method, urlConfig.url());
  }

  uploadVideoFile(payload: any): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'video/mp4',
        Accept: '*/*',
      }),
    };
    return this.commonServices.callAPI('put', payload.upload_url, payload.formData, options);
  }
}

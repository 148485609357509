<ng-container *ngIf="initialized">

  <div class="row" *ngIf="bidSheet?.id">
    <div class="mat-headline">
      <!-- {{bidSheet.name}} -->
    </div>
    <div class="icons">
      <mat-spinner strokeWidth="3" diameter="32" mode="indeterminate" color="accent" *ngIf="downloading">
      </mat-spinner>
      <button mat-icon-button [disabled]="!('read' | able: bidSheet)" (click)="downloadBidItems(bidSheet)"
        *ngIf="!downloading" title="Download bid sheet" color="accent">
        <mat-icon>download</mat-icon>
      </button>
      <app-file-uploader [disabled]="!('create' | able: newBidItem)" param="inputFile" [targetClass]="clsBidItem"
        [target]="csvEndpoint" icon="file_upload" accept="text/csv" (onError)="onUploadError($event)"
        (complete)="onUploadComplete($event)" title="Upload csv">
      </app-file-uploader>
      <button mat-icon-button [disabled]="!('create' | able: newBidItem)" (click)="openBidItemEdit(newBidItem)"
        title="Add bid item" color="accent">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!bidItems.length">
    Found no records to show
  </div>

  <!-- list -->
  <div class="list">
    <div class="list-card" *ngFor="let bidItem of bidItems">

      <!-- actions -->
      <div class="hover-icons">
        <a mat-mini-fab [disabled]="!('update' | able: bidItem)" (click)="openBidItemEdit(bidItem)" color="accent"
          aria-label="Edit {{bidItem.item}}" title="Edit {{bidItem.item}}">
          <mat-icon>edit</mat-icon>
        </a>

        <button mat-mini-fab [disabled]="!('delete' | able: bidItem)" (click)="delete(bidItem)" color="warn"
          aria-label="Delete {{bidItem.item}}" title="Delete {{bidItem.item}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <!-- /actions -->

      <div class="body">
        <div class="mat-title row">
          <div>{{bidItem.item}}</div>
          <div>Quantity: {{bidItem.quantity}}</div>
          <div>Unit Price: {{bidItem.unit_price}}</div>
          <div>UOM: {{bidItem.uom}}</div>
          <div *ngIf="bidItem?.site?.id">
            Site: {{bidItem?.site?.name}}
          </div>
        </div>
        <div class="mat-caption">{{bidItem.description}}</div>
      </div>
    </div>
  </div>
  <!-- ./list -->

  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && bidItems.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

</ng-container>

<div class="" *ngIf="initialized">
  <div class="row">
    <div>
      <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
    </div>
    <div class="icons">
      <button mat-icon-button aria-label="Add" color="none" [disabled]="!('create' | able: newCrew)"
        (click)="toggleForm(null, true)">
        <mat-icon title="Add">add</mat-icon>
      </button>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- listing -->
  <div class="mat-body-strong" *ngIf="!crews.length">
    No crews found for this project
    <button mat-button color="accent" class="mat-inline-button" [disabled]="!('create' | able: newCrew)"
      (click)="toggleForm(null, true)" aria-label="Add" title="Add">
      Add crew?</button>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let record of crews">
      <div class="hover-icons">
        <a mat-icon-button [disabled]="!('update' | able: record)" (click)="toggleForm(record, true)" color="none"
          aria-label="Edit" title="Edit">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button [disabled]="!('delete' | able: record)" (click)="delete(record)" color="warn"
          aria-label="Delete" title="Delete">
          <mat-icon>delete</mat-icon>
        </a>
      </div>

      <div class="body">
        <div class="mat-subheading-2">
          {{record.name}}
          <div class="mat-subheading-1" *ngFor="let labor of record?.labors">
            {{labor?.first_name}} {{labor?.last_name}}
            <span class="mat-caption">({{labor?.default_job_code?.name}})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- listing -->
  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length" [length]="pagination.length"
    [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>
</ng-template>


<ng-template #InputFormContent>
  <!-- Field Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!editing">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>
    <div class="row">
      <!-- name section -->
      <section>
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.name.hasError('error')">
            {{inputForm.controls.name.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /name section -->

      <!-- labor_ids section -->
      <section>
        <mat-form-field>
          <mat-label>Select labor</mat-label>
          <mat-select formControlName="labor_ids" [errorStateMatcher]="matcher" multiple>
            <mat-option *ngFor="let labor of labors" [value]="labor.id">
              {{labor.first_name}} {{labor.last_name}}
              <span class="mat-caption">({{labor?.default_job_code?.name}})</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inputForm.controls.labor_ids.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.labor_ids.hasError('error')">
            {{inputForm.controls.labor_ids.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /labor_ids section -->

      <!-- Form Actions -->
      <section *ngIf="editing" class="icons">
        <button mat-icon-button
          [disabled]="!('update' | able: crew)"
          (click)="save(inputForm)">
          <mat-icon title="Save">save</mat-icon>
        </button>
        <button mat-icon-button (click)="onCancel()">
          <mat-icon title="Cancel">cancel</mat-icon>
        </button>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
  <!-- /Input form -->
</ng-template>
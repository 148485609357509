import { Component, Input, OnInit } from '@angular/core';
import { ReportEquipment } from 'src/app/shared/models';

@Component({
  selector: 'app-report-equipment-card',
  templateUrl: './report-equipment-card.component.html',
  styleUrls: ['./report-equipment-card.component.scss']
})
export class ReportEquipmentCardComponent implements OnInit {

  public initialized: boolean = false;

  @Input() reportEquipment: ReportEquipment;

  constructor() { }

  ngOnInit(): void {
    if (this.reportEquipment) {
      this.initialized = true;
    }
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, BidSheet } from 'src/app/shared/models';
import { BidService } from 'src/app/shared/services/bid.service';

@Component({
  selector: 'app-bid-item-selector',
  templateUrl: './bid-item-selector.component.html',
  styleUrls: ['./bid-item-selector.component.scss']
})
export class BidItemSelectorComponent implements OnInit {
  @Input() project: any;
  @Input() selectedBidItem: BidItem = new BidItem();

  private isDialog: boolean = false;

  public loading: number = 0;
  public initialized: boolean = false;
  public bidItems: BidItem[] = [];

  constructor(
    private bidService: BidService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BidItemSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any
  ) { }

  ngOnInit(): void {

    // if component loaded in MAT dialog, take attribute data from injected data
    const { project, selectedBidItem, biditems, options = {} } = this.inputData;
    const { fetchData = true } = options;
    if (project || selectedBidItem) {
      this.isDialog = true;
      this.project = project;
      this.selectedBidItem = selectedBidItem;
      this.bidItems = biditems;
    }

    if (fetchData) {
      this.fetchRecords();
    } else {
      this.initialized = true;
    }
  }

  fetchRecords(): void {
    this.loading++;
    this.bidService.getBidSheet(this.project.id, { include: ['bidItems'] })
      .subscribe({
        next: (bidSheet: BidSheet) => {
          this.bidItems = bidSheet.bidItems.map(o => {
            o.project_id = this.project.id;
            o.bid_sheet_id = bidSheet.id;
            return o;
          });
          this.initialized = true;
          this.loading--;
        },
        error: (err: HttpErrorResponse) => {
          this.loading--;
          this.initialized = true;

          this.snackBar.open(err.message, '', { duration: 5000 });
        },
      });
  }

  onSelected(bidItem: BidItem): void {

    if (this.isDialog) {
      this.dialogRef.close(bidItem);
    }
    // Todo: emit output event
  }

}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Labor, Project } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class LaborService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Labors with pagination
   * @param project Project
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: Labor[]}
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/project/${project?.id}/labors`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Labor(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Labor
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Labor>}
   */
  create(payload: any = null, qp: any = {}): Promise<Labor> {
    return this.appHttpService
      .postService(`/v2/project/${payload?.project_id}/labor`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Labor(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Labor
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Labor>}
   */
  update(payload: any = null, qp: any = {}): Promise<Labor> {
    return this.appHttpService
      .putService(`/v2/labor/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Labor(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Labor
   * @param model Labor
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Labor): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/labor/${model?.id}`)
      .toPromise();
  }
}

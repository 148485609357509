import { Component, ViewChild, OnInit, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { environment as ENV } from 'src/environments/environment';

declare const WebViewer: any;

@Component({
  selector: 'web-viewer',
  templateUrl: './webviewer.component.html',
  styleUrls: ['./webviewer.component.scss']
})
export class WebViewerComponent implements OnInit, AfterViewInit {

  @Input() readOnly = true;
  @Input() user: any;
  @Input() documentUrl: string;
  @Input() xfdf: string; // xml string adobe-xfdf format
  @Input() masterAnnotId: string;

  @Output() annotationsChange = new EventEmitter();

  @ViewChild('viewer', { static: true }) viewer: ElementRef;

  wvInstance: any;
  masterAnnot: any;

  ngOnInit() {
    this.wvDocumentLoadedHandler = this.wvDocumentLoadedHandler.bind(this);
    this.annotationsChanged = this.annotationsChanged.bind(this);
  }

  wvDocumentLoadedHandler(): void {
    const annotManager = this.wvInstance.annotManager;

    if (this.xfdf) {
      annotManager.importAnnotations(this.xfdf);
    }

    annotManager.on('annotationChanged', this.annotationsChanged);

    // Set Master annot
    this.masterAnnot = annotManager.getAnnotationsList().find(a => a.Id === this.masterAnnotId);
    if (this.masterAnnotId && !this.masterAnnot) {
      throw new Error('Invalid xfdf: masterAnnotId not Found.');
    }
    if (!this.masterAnnot) {
      this.masterAnnot = new this.wvInstance.Annotations.FreeHandAnnotation();
      annotManager.addAnnotation(this.masterAnnot);
    }
  }

  ngAfterViewInit(): void {

    WebViewer({
      licenseKey: ENV.PDFTRON_LICENSE_KEY,
      pdftronServer: ENV.PDFTRON_SERVER_URL,
      path: '../../../../wv-resources/lib',
      forceClientSideInit: true,
      isAdminUser: !this.readOnly,
      isReadOnly: this.readOnly,
      disabledElements: [
        'viewControlsButton',
        'viewControlsOverlay',
        'toolbarGroup-Shapes',
      ]
    }, this.viewer.nativeElement)
      .then(instance => {

        this.wvInstance = instance;
        if (this.user && !this.readOnly) {
          // this method is resetting custom ui settings, set this before custom ui options
          this.wvInstance.annotManager.setCurrentUser(this.user.name);
        }

        /* customize ui */
        // instance.openElement('notesPanel');
        instance.disableElements(['toolbarGroup-Shapes']);
        instance.disableElements(['toolbarGroup-Edit']);
        instance.disableElements(['toolbarGroup-Insert']);
        // instance.disableElements(['toolbarGroup-View']);
        // instance.disableElements(['toolbarGroup-Annotate']);
        /* /customize ui */

        instance.loadDocument(this.documentUrl);
        instance.docViewer.on('documentLoaded', this.wvDocumentLoadedHandler);

      });

  }

  /**
   * Callback onAnnotationsChanged
   * @param annots Annotations
   * @param action Annotations change action
   * @param options Annotations change options
   */
  annotationsChanged(annots, action, options:any = {}): void {
    const { imported } = options;
    if (imported) {
      return;
    }

    const { docViewer, annotManager } = this.wvInstance;
    const doc = docViewer.getDocument();

    // Adding new annotations to master annot group
    // Todo: Should we consider only FreeHand annots?
    const newAnnots = annots.filter(a => a.Id !== this.masterAnnot?.Id && !a.isGrouped());
    if (newAnnots.length) {
      annotManager.groupAnnotations(this.masterAnnot, newAnnots);
    }

    // Apply any filters here, display annots from other entities
    const allAnnots = annotManager.getAnnotationsList();
    annotManager.exportAnnotations({
      annotList: allAnnots,
    }).then(xfdf => {
      // extract only first page
      doc.extractPages([1], xfdf).then(data => {
        const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
        this.annotationsChange.emit({ xfdf, file: blob, masterAnnotId: this.masterAnnot.Id });
      });

      // // extract whole file
      // doc.getFileData({ xfdfString: xfdf })
      //   .then(data => {
      //     const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
      //     this.annotationsChange.emit({ xfdf, file: blob});
      //   });
    });
  }
}

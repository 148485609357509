import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppService } from '../../../services';
import { NgxSkeletonLoaderConfig } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-pictures-section',
  templateUrl: './pictures-section.component.html',
  styleUrls: ['./pictures-section.component.scss'],
})
export class PicturesSectionComponent implements OnInit {
  @Input() pictures: any[] = [];
  @Input() isEditable = true;
  @Input() maxHeight?: string;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  loading = true;

  loaderAppearance: NgxSkeletonLoaderConfig['appearance'] = '';
  loaderTheme: NgxSkeletonLoaderConfig['theme'] = {
    width: '100%',
    height: '100%',
  };
  dummyUrl = 'assets/images/png/video-processing.png';

  constructor(
    public appService: AppService,
  ) {
  }

  ngOnInit() {

  }

  hideLoader() {
    this.loading = false;
  }

  handleError() {
    this.hideLoader();
  }

  deletePicture(picture) {
    this.onDelete.emit(picture);
  }
}

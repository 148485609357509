import { BaseModel } from './base-model';
import { Account } from './account';
import { Project } from './project';

export class Drawing extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Drawing'; }

  /* tslint:disable */
  mime: string;
  original_name: string;
  size: number;
  url: string;
  tags: string[];
  thumb_url: string;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      project: Project,
    };
  }

  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    this.tags = this.tags || [];
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      tags: this.tags,
    };
    return payload;
  }
}

<ng-container *ngIf="!projectConsolidatedReport">
<div *ngFor="let site of sites">
  <div *ngIf="!isSingleReport" class="mat-subheading-1">SITE {{site.name}}</div>
  <div class="cards-container">
    <div *ngIf="!isSingleReport && !site.summary.length">No records</div>
    <ng-container *ngFor="let summary of site.summary">
      <div class="card">
        <!-- BidItem view -->
        <div class="mat-subheading-2">{{summary.biditem.item}}</div>
        <div class="mat-body">{{summary.biditem.description}}</div>
        <div class="mat-caption">UOM: {{summary.biditem.uom}}</div>
        <!-- /BidItem view -->
        <div class="line-items">
          <ng-container *ngIf="!summary.headings" [ngTemplateOutlet]="LineItem"
            [ngTemplateOutletContext]="{ 'biditem': summary.biditem, 'lineItems': summary.lineItems }"></ng-container>
          <ng-container *ngIf="summary.headings" [ngTemplateOutlet]="HeadingItem"
            [ngTemplateOutletContext]="{ 'biditem': summary.biditem, 'headings': summary.headings }"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
</ng-container>

<ng-container *ngIf="projectConsolidatedReport">
<div *ngFor="let bi of usedBidItems">
  <div class="mat-subheading-1">
    <span>{{bi.item}} {{bi.description}}</span>
    <br /><br />
    <span class="total">TOTAL: {{bi.totalQuantity}}</span>
  </div>
  <div class="cards-container">
    <div *ngIf="!bi.summary.length">No records</div>
    <ng-container *ngFor="let summary of bi.summary">
      <div class="card">
        <!-- site view -->
        <!-- <div class="mat-subheading-2">{{summary.biditem.item}}</div> -->
        <div class="mat-body">{{summary.site.name}}</div>
        <div class="mat-caption">UOM: {{bi.uom}}</div>
        <!-- /site  view -->
        <div class="line-items">
          <ng-container *ngIf="!summary.headings" [ngTemplateOutlet]="LineItem"
            [ngTemplateOutletContext]="{ 'biditem': bi, 'lineItems': summary.lineItems }"></ng-container>
          <ng-container *ngIf="summary.headings" [ngTemplateOutlet]="HeadingItem"
            [ngTemplateOutletContext]="{ 'biditem': bi, 'headings': summary.headings }"></ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
</ng-container>


<ng-template #LineItem let-biditem="biditem" let-lineItems="lineItems">
  <div class="row link" *ngFor="let li of lineItems" (click)="openRBI(li)">
    <img [src]="getPreviewUrl(null, lineItems)" alt="Thumb image" />
    <div>
      <div>QTY: {{li?.quantity || li?.calculatedQuantity}}</div>
      <div *ngIf="li.comment">Comment: {{li.comment}}</div>
    </div>
  </div>
</ng-template>

<ng-template #HeadingItem let-biditem="biditem" let-headings="headings">
  <div class="row link" *ngFor="let h of headings" (click)="openRBI(h.header)">
    <img [src]="getPreviewUrl(h.header, h.lineItems)" alt="Thumb image" />
    <div>
      <div>QTY
        <ng-container *ngIf="getUom(biditem)">({{getUom(biditem)}})</ng-container>:
        <ng-container *ngIf="biditem?.rollup_field"> {{h.rollUp}}</ng-container>
        <ng-container *ngIf="!biditem?.rollup_field"> {{h.header?.quantity}}</ng-container>
      </div>
      <div>{{h.heading?.name}}</div>
    </div>
  </div>
</ng-template>

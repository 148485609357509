import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { projectReportsList } from '../../../../../../core/projects.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-project-reports-list',
  templateUrl: './project-reports-list.component.html',
  styleUrls: ['./project-reports-list.component.scss'],
})
export class ProjectReportsListComponent implements OnInit, OnDestroy {
  filteredProjectReportsList: any[] = [];
  projectReportsList: any[] = [];
  isExpanded = false;

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (
          qp.tab === 'consolidated-reports' &&
          qp.date === undefined
        ) {
          this.router.navigate([], {
            queryParams: {
              date: this.projectReportsList.length ? this.projectReportsList[0].report_date : moment().format('YYYY-MM-DD'),
            },
            queryParamsHandling: 'merge',
          });
        }
      });

    this.store.select(projectReportsList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(projectReports => {
        this.projectReportsList = projectReports.filter(o => o.status === 'submitted');
        this.filteredProjectReportsList = this.projectReportsList;

        const qp = this.route.snapshot.queryParams;
        if (
          qp.tab === 'consolidated-reports' &&
          qp.date === undefined &&
          this.projectReportsList.length
        ) {
          this.router.navigate([], {
            queryParams: {
              date: this.projectReportsList[0].report_date,
            },
            queryParamsHandling: 'merge',
          });
        } else if (
          qp.tab === 'consolidated-reports' &&
          qp.date &&
          this.projectReportsList.length
        ) {
          const report = this.projectReportsList.filter(o => o.report_date === qp.date)[0];
          const i = this.projectReportsList.indexOf(report);

          this.selectReport(report, i);
        }
      });
  }

  ngOnInit() {

  }

  selectReport(report, i) {
    this.router.navigate([], {
      queryParams: {
        date: report?.report_date,
      },
      queryParamsHandling: 'merge',
    });

    const selectedReport = i + 1;

    if (selectedReport > 3) {
      let rowNumber = ((selectedReport - (selectedReport % 3)) / 3);

      if (selectedReport % 3 !== 0) {
        rowNumber++;
      }

      this.filteredProjectReportsList = [...this.projectReportsList].splice(3 * (rowNumber - 1), 3);
    }
    this.isExpanded = false;
  }

  handleExpansion() {
    this.isExpanded = !this.isExpanded;

    if (this.isExpanded) {
      this.filteredProjectReportsList = this.projectReportsList;
    }
  }


  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { Account, Project, Site } from '../models';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteService {
  rbvs: RVObservable;

  constructor(
    private appHttpService: AppHttpService
  ) {
    this.rbvs = new RVObservable();
  }

  /**
   * Creates a new site
   * @param payload Site
   */
  create(payload) {
    return this.appHttpService.postService('/v1/site', payload).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Site(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Updates Site
   * @param payload Site
   */
  update(id, payload) {
    return this.appHttpService.putService(`/v1/site/${id}`, payload).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Site(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  getRecord(id: string, qp = {}) {
    return this.appHttpService.getService(`/v1/site/${id}`, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Site(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Returns list of Records
   * @param pagination start
   * @param qp query params
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/project/${project.id}/sites`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Site(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetches all project sites
   * @param project Project
   * @param qp query-params
   * @returns {Promise<Site[]>}
   */
  getProjectSites(project: Project, qp: any = {}): Promise<Site[]> {
    qp = Object.assign(qp, { start: 0, total: 10000 });
    return this.appHttpService.getService(`/v1/project/${project.id}/sites`, qp).toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new Site(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Site
   * @param site Site
   */
  delete(site: Site) {
    // Todo check acl here
    return this.appHttpService.deleteService(`/v1/site/${site.id}`);
  }

  /**
   * Fetches Site accounts
   * @param site project
   * @param pagination pagination object
   * @param qp query-params
   */
  getAccounts(site: Site, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/site/${site.id}/account`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Account(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Add account to site
   * @param site Site
   * @param account Account
   */
  addAccount(site: Site, account: Account) {
    const path = `/v1/site/${site.id}/account/${account.id}`;
    return this.appHttpService.postService(path, {});
  }

  /**
   * Remove account from site
   * @param site Site
   * @param account Account
   */
  removeAccount(site: Site, account: Account) {
    const path = `/v1/site/${site.id}/account/${account.id}`;
    return this.appHttpService.deleteService(path, {});
  }

  /**
   * Fetches Site
   * @param id string
   * @param qp query params
   * @returns {Promise<Site>}
   */
  fetchSite(id: string, qp: any = {}): Promise<Site> {
    return this.appHttpService.getService(`/v1/site/${id}`, qp)
      .toPromise()
      .then((resp: any) => new Site(resp.data));
  }

  fetchRecentBiditemValues(options: any = {}) {
    const { siteId, biditemId, date, headingId } = options;
    const qp = {
      report_date: date,
      heading_id: headingId,
    }
    const k = `${siteId}/${biditemId}/${headingId}/${date}`;
    if (k in this.rbvs.data) {
      return;
    }

    return this.appHttpService.getService(
      `/v2/site/${siteId}/bid-item/${biditemId}/recent-values`,
      qp,
    ).toPromise().then((resp: any) => {
      this.rbvs.add(k, resp.data, { override: true });
      this.rbvs.resolvePending(k);
    })
  }

  getRecentBiditemValue(stationId: string, fieldId: string, options: any = {}): Observable<any> {
    const { siteId, biditemId, headingId, date } = options
    const k = `${siteId}/${biditemId}/${headingId}/${date}`;
    return this.rbvs.find(k, stationId, fieldId);
  }
}

class RVObservable {
  data: any = {};

  add(k: string, values: any[], options: any = {}) {
    const { override = false } = options;

    if (!(k in this.data)) {
      this.data[k] = {
        values: values,
        observables: {},
      };
    }

    if (override) {
      this.data[k].values = values;
    }
  }

  find(k: string, sid: string, fid: string): Observable<any> {
    const sk = `${sid}/${fid}`;

    if (!(k in this.data)) {
      this.data[k] = {
        values: [],
        observables: {},
      };
    }

    // return value and complete observable if data already exists
    const row = this.findRow(k, sk);
    if (row) {
      return new Observable((sub) => {
        sub.next(row?.value);
        sub.complete();
      });
    }

    // create new waiting sub and return
    const { observables } = this.data[k];
    if (!(sk in observables)) {
      observables[sk] = new Subject<any>();
    }

    return observables[sk];
  }

  findRow(k, sk) {
    const rows = this.data[k].values || [];
    const [sid, fid] = sk.split('/');
    return rows.find(o => o.station?.id === sid && o.field.id === fid);
  }

  resolvePending(k: string) {
    for (const [sk, obs] of Object.entries(this.data[k].observables)) {
      const row = this.findRow(k, sk);
      (obs as Subject<any>).next(row?.value);
      (obs as Subject<any>).complete();
    }
  }
}
<div class="container" *ngIf="initialized">


  <div class="mat-body-strong" *ngIf="!jobCodes.length">
    No data found
  </div>

  <div class="columns">
    <div>
      <div class="mat-subheading-2">Org jobcodes</div>
      <div *ngFor="let jobCode of jobCodes">
        <mat-checkbox [checked]="jobCode.checked" (change)="onCheckToggle(jobCode, $event.checked)">
          <div class="mat-body-strong">
            {{jobCode.name}}
            <span class="mat-caption" *ngIf="!jobCode.is_master">(user added)</span>
          </div>
        </mat-checkbox>
      </div>
    </div>

    <div>
      <div class="mat-subheading-2">Project jobcodes</div>
      <div class="mat-body-strong" *ngFor="let jobCode of projectJobCodes">
        {{jobCode.name}}
        <span class="mat-caption" *ngIf="!jobCode.is_master">(user added)</span>
      </div>
    </div>
  </div>

</div>
import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Site, Heading } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class HeadingService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Creates a new Heading
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Heading>}
   */
  create(payload: any = null, qp: any = {}): Promise<Heading> {
    return this.appHttpService
      .postService(`/v2/alignment/${payload?.alignment_id}/heading`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Heading(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Heading
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Heading>}
   */
  update(payload: any = null, qp: any = {}): Promise<Heading> {
    return this.appHttpService
      .putService(`/v2/heading/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Heading(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Heading
   * @param model Heading
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Heading): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/heading/${model?.id}`)
      .toPromise();
  }
}

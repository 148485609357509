import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DailyReport, Project, Site } from 'src/app/shared/models';
import { AppService, ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-status-summary',
  templateUrl: './project-status-summary.component.html',
  styleUrls: ['./project-status-summary.component.scss']
})
export class ProjectStatusSummaryComponent implements OnInit {

  public initialized: boolean = false;
  public loading: number = 0;

  @Input() public project: Project;
  @Input() public days: number = 5;

  public dates: moment.Moment[] = [];
  public summaries: any[] = [];

  constructor(
    private projectSrv: ProjectService,
    private appSrv: AppService
  ) { }

  ngOnInit(): void {
    this.days = +this.days;

    this.dates = this.appSrv.getLastNDates(this.days, moment(this.project.start_date), moment(this.project.end_date));
    this.getSummaries(this.project, this.dates)
      .then((summaries: any[]) => {
        this.summaries = summaries;
        this.initialized = true;
      })
  }

  /**
   *
   * @param project Project
   * @param dates dates to fetch summary for
   * @returns {Promise<any[]>}
   */
  public getSummaries(project: Project, dates: moment.Moment[]): Promise<any[]> {
    let summaries = [];

    return new Promise(async (resolve, reject) => {

      try {
        const qp = {
          from: dates[dates.length - 1].format('YYYY-MM-DD'),
          to: dates[0].format('YYYY-MM-DD'),
          include: 'site',
        };

        const rs = await this.projectSrv.getDailyReports(project, qp).toPromise();
        const drs: DailyReport[] = rs.result || [];

        summaries = dates.map((date: moment.Moment) => {
          const siteStatuses = [];
          project.sites.map((site: Site) => {
            const siteReports = drs.filter(dr => dr.site.id === site.id && date.isSame(dr.report_date, 'day'));
            if (siteReports.length) {
              siteReports.map(s => siteStatuses.push(s.status));
            } else {
              siteStatuses.push('todo');
            }
          });

          return {
            date,
            completed: siteStatuses.length && siteStatuses.length === siteStatuses.filter(o => o === 'approved').length,
            todo: siteStatuses.filter(o => o === 'todo').length, // awaiting creation
            draft: siteStatuses.filter(o => o === 'draft').length, // awaiting submission
            submitted: siteStatuses.filter(o => ['submitted', 'reviewed'].indexOf(o) > -1 ).length, // awaiting approval
            rejected: siteStatuses.filter(o => o === 'rejected').length, // rejected
            approved: siteStatuses.filter(o => o === 'approved').length, // Approved
          };
        });

        resolve(summaries);

      } catch(e) {
        reject(e);
      }
    });
  }

}

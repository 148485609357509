import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-status-summary',
  templateUrl: './status-summary.component.html',
  styleUrls: ['./status-summary.component.scss'],
})
export class StatusSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any[] = [];
  @Input() projectId = '';
  days = 15;
  initialized = false;
  loading = 0;
  dates: moment.Moment[] = [];
  summary: any[] = [];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data?.currentValue) {
      const newData = JSON.parse(JSON.stringify(this.data))?.reduce((rv, x) => {
        if (x.status === 'submitted' || x.status === 'reviewed') {
          x.status = 'submitted-reviewed';
          x.status_text = 'Awaiting approval';
        }

        if (rv[x.status]) {
          rv[x.status] = {
            ...rv[x.status],
            ...x,
            report_count: rv[x.status].report_count + Number(x.report_count),
          };
        } else {
          rv[x.status] = {
            ...x,
            report_count: Number(x.report_count)
          };
        }

        return rv;
      }, {});

      const statusArr = [];

      // Approved status dont need to be shown
      delete newData.approved;

      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < Object.keys(newData).length; i++) {
        const key = Object.keys(newData)[i];
        statusArr.push({
          count: newData[key].report_count,
          type: newData[key].status,
          label: newData[key].status_text || newData[key].status,
        });
      }

      this.summary = statusArr.sort((a, b) => a.type - b.type);
    }
  }

  handleClick(type) {
    this.router.navigate([`/projects/${this.projectId}`], {
      queryParams: {
        status: type,
        tab: 'sites'
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Equipment, Project } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class EquipmentService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Equipments with pagination
   * @param project Project
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: Equipment[]}
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/project/${project?.id}/equipments`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Equipment(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Equipment
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Equipment>}
   */
  create(payload: any = null, qp: any = {}): Promise<Equipment> {
    return this.appHttpService
      .postService(`/v2/project/${payload?.project_id}/equipment`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Equipment(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Equipment
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Equipment>}
   */
  update(payload: any = null, qp: any = {}): Promise<Equipment> {
    return this.appHttpService
      .putService(`/v2/equipment/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Equipment(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Equipment
   * @param model Equipment
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Equipment): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/equipment/${model?.id}`)
      .toPromise();
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-map-popup-summary-type-one',
  templateUrl: './map-popup-summary-type-one.component.html',
  styleUrls: ['./map-popup-summary-type-one.component.scss', '../popup.styles.scss'],
})
export class MapPopupSummaryTypeOneComponent {
  @Input() data: any = {};
}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { DailyReport, Project, ProjectReport } from '../models';

@Injectable({ providedIn: 'root' })
export class ProjectReportService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Fetches ProjectReport
   * @param id string ProjectReport.id
   * @param qp query-params
   * @returns {Promise<ProjectReport>}
   */
  getRecord(id: string, qp: any = {}): Promise<ProjectReport> {
    return this.appHttpService.getService(`/v1/project-report/${id}`, qp).toPromise()
      .then(resp => {
        if ('data' in resp) {
          return new ProjectReport(resp.data);
        }
        throw new Error('Error parsing data');
      });
  }

  /**
   * Return list of project reports
   * @param project Project
   * @param days number
   * @param qp query-params
   * @returns {Promise<ProjectReport[]>}
   */
  findRecords(project: Project, qp: any = {}): Promise<ProjectReport[]> {
    qp = Object.assign({
      projectId: project.id,
    }, qp);

    return this.appHttpService.getService(`/v1/project-report`, qp).toPromise()
      .then((resp: any) => {
        if ('data' in resp && Array.isArray(resp.data)) {
          return (resp.data || []).map(o => new ProjectReport(o));
        };
        throw new Error('Error parsing data');
      });
  }


}

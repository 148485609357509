<div class="container">
  <mat-tab-group [selectedIndex]="0" class="vertical-tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Job Codes</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-job-codes [project]="project"></app-project-job-codes>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Labor</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-labors [project]="project"></app-project-labors>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Crew</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-crews [project]="project"></app-project-crews>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Fields</span>
      </ng-template>
      <ng-template matTabContent>
        <app-fields
          entityType="project"
          context="labor_fields"
          [entity]="project"></app-fields>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
import { Injectable } from '@angular/core';
import { Organization, Project } from 'src/app/shared/models';
import { AppHttpService } from 'src/app/shared/services';
import { SubContractor } from './sub-contractor';


@Injectable({ providedIn: 'root' })
export class SubContractorService {

  constructor(
    private appHttpService: AppHttpService
  ) { }

  /**
   * Fetches records
   * @param qp query-params {project_id, organization_id, pagination, include...}
   * @returns {Promise<any>}
   */
  public fetchRecords(pagination: any = {}, qp: any = {}): Promise<any> {
    qp = Object.assign(qp, {
      start: pagination.pageIndex ?? 0 * pagination.pageSize ?? 10,
      total: pagination.pageSize ?? 10,
    });

    return this.appHttpService.getService(`/v1/sub-contractor/`, qp).toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new SubContractor(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Saves Subcontractor
   * @param organization Organization
   * @param sc SubContractor
   * @param payload post body
   * @param qp query-params
   * @returns {Promise<SubContractor>}
   */
  public save(organization: Organization, sc: SubContractor, payload: any = null, qp: any = {}): Promise<SubContractor> {

    payload = payload ?? sc.toPayload();
    payload.organization_id = payload.organization_id ?? organization.id;

    return this.appHttpService.postService('/v1/sub-contractor', payload, qp).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new SubContractor(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Add sub contractor to project
   * @param project Project
   * @param sc SubContractor
   * @param qp query-params
   * @returns {Promise<SubContractor>}
   */
  addSubContractorToProject(project: Project, sc: SubContractor, qp: any = {}): Promise<SubContractor> {
    return this.appHttpService.postService(`/v1/sub-contractor/${sc.id}/project/${project.id}`, qp).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new SubContractor(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Remove sub contractor to project
   * @param project Project
   * @param sc SubContractor
   * @returns {Promise<any>}
   */
  removeSubContractorFromProject(project: Project, sc: SubContractor): Promise<any> {
    return this.appHttpService.deleteService(`/v1/sub-contractor/${sc.id}/project/${project.id}`).toPromise();
  }

}

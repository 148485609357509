import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { FetchAllBidItemsRequest } from '../../../../../../../../core/projects.actions';
import { allBidItems, dailyReport, siteDetails } from '../../../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { BidItemDetailsWrapperComponent } from '../bid-item-details-wrapper/bid-item-details-wrapper.component';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';

@Component({
  selector: 'app-bid-item-details-add-bid-item',
  templateUrl: './bid-item-details-add-bid-item.component.html',
  styleUrls: ['./bid-item-details-add-bid-item.component.scss'],
})
export class BidItemDetailsAddBidItemComponent implements OnInit, OnDestroy {
  bidItemsList = [];
  filteredBidItemsList = [];
  form = new FormGroup({
    searchText: new FormControl(''),
  });
  focusedIn = false;
  searchText: string;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
    private commonService: CommonService,
  ) {
  }

  ngOnInit() {

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(site => {
        this.store.dispatch(FetchAllBidItemsRequest({
          payload: {
            projectId: site.project.id,
            qp: {
              include: [
                {
                  bid_items: ['fields'],
                },
              ],
            },
          },
        }));
      });

    this.store.select(allBidItems)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((bidItems) => {
        this.bidItemsList = bidItems;
        this.filteredBidItemsList = bidItems;
      });

    this.form.controls.searchText.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(searchText => {
        this.searchText = searchText;
        if (searchText) {
          this.filteredBidItemsList = this.bidItemsList.filter(item => {
            const name = item.item;
            const description = item.description;

            if (name?.toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }

            if (description.toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }
          });
        } else {
          this.searchText = '';
          this.filteredBidItemsList = this.bidItemsList;
        }
      });

    this.commonService.focusedIn
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data) {
          this.focusedIn = data;
          if (this.searchText) {
            this.filteredBidItemsList = this.bidItemsList.filter(item => {
              const name = item.item;
              const description = item.description;

              if (name?.toLowerCase().includes(this.searchText.toLowerCase())) {
                return true;
              }

              if (description.toLowerCase().includes(this.searchText.toLowerCase())) {
                return true;
              }
            });
          } else {
            this.filteredBidItemsList = this.bidItemsList;
          }
        } else {
          this.filteredBidItemsList = [];
          this.focusedIn = false;
        }
      });
  }

  addBidItem(bidItem) {
    if (bidItem.record_by_station) {
      this.projectsService.openRightPanel.next({
        component: BidItemDetailsWrapperComponent,
        data: {
          bidItem,
          heading: {},
          stations: [],
        },
      });
    } else {
      this.store.select(dailyReport)
        .subscribe((report) => {
          const details = report.reportDetails;
          let lineItems = [];

          if (details) {
            const { bidItems } = details;
            const selectedElement = bidItems?.find((o) => bidItem.id === o.bidItem.id);

            if (selectedElement) {
              lineItems = selectedElement.lineItems;
            }
          }

          this.projectsService.openRightPanel.next({
            component: BidItemDetailsWrapperComponent,
            data: {
              bidItem,
              lineItems,
            },
          });
        }).unsubscribe();
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<div class="component-container">
  <mat-icon
    (click)="currentPage === 1 ? '' : prevPage()"
    [svgIcon]="currentPage === 1 ? 'chevron-right-disabled' : 'chevron-right'"
    class="icon left"
  ></mat-icon>

  <div
    (click)="goToPage(page)"
    *ngFor="let page of pages"
    [class.active]="page === currentPage"
    class="element text-type-6"
  >
    {{ page }}
  </div>

  <mat-icon
    (click)="currentPage === totalPages ? '' : nextPage()"
    [svgIcon]="currentPage === totalPages ? 'chevron-right-disabled' : 'chevron-right'"
    class="icon right"
  ></mat-icon>

  <span class="summary">Showing {{ currentEndIndex }}/{{ totalItems }}</span>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { VirtualTimeScheduler } from 'rxjs';
import { WorkflowLineage } from '../../models';
import { WorkflowService } from '../../services/workflow.service';

@Component({
  selector: 'app-workflow-lineage',
  templateUrl: './workflow-lineage.component.html',
  styleUrls: ['./workflow-lineage.component.scss']
})
export class WorkflowLineageComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input() ex: any;
  @Input() pageSize = 20;

  public lineage: WorkflowLineage[] = [];
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };

  constructor(
    private wfService: WorkflowService
  ) { }

  ngOnInit(): void {
    this.fetchLineage();
  }

  /**
   * Fetches lineage of given ex
   */
  fetchLineage() {
    this.wfService.fetchLineage(this.ex, this.pagination, { include: ['actor', 'state'] })
      .subscribe({
        next: (resp: any) => {
          this.lineage = resp.result;
          this.pagination.length = resp.meta.totalRecords || 0;
          this.initialized = true;
        },
        error: (err: any) => {
          console.error(err);
        }
      });
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchLineage();
  }
}

import { Injectable } from '@angular/core';
import { Organization, Project, Site } from 'src/app/shared/models';
import { AppHttpService } from 'src/app/shared/services';
import { Invitation } from './invitation';

@Injectable({ providedIn: 'root' })
export class InvitationService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Fetches invitations
   * @param model Project|Organization
   * @param pagination pagination object
   * @param qp query-params
   * @returns {Promise<Invitation[]>}
   */
  getRecords(model: Project|Organization, pagination: any, qp: any = {}): Promise<any> {
    qp = Object.assign(qp, {
      start: pagination.pageIndex * pagination.pageSize,
      total: pagination.pageSize,
    });

    console.log(model);

    if (model instanceof Project) {
      qp.project_id = model.id;
    }
    if (model instanceof Organization) {
      qp.organization_id = model.id;
    }

    return this.appHttpService.getService(`/v1/invitation`, qp).toPromise()
      .then((resp: any) => {
        if ('data' in resp && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Invitation(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Invites new user to Site
   * @param site Site
   * @param role string
   * @param payload body
   * @param qp query-params
   * @returns {Promise<Invitation>}
   */
  newInviteToSite(site: Site, role: string, payload: any, qp: any = {}): Promise<Invitation> {
    return this.appHttpService.postService(`/v1/invitation/site/${site.id}/${role}`, payload, qp).toPromise()
      .then((resp: any) => {
        if ('data' in resp) {
          return new Invitation(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Invites new user to Project
   * @param project Project
   * @param role string
   * @param payload body
   * @param qp query-params
   * @returns {Promise<Invitation>}
   */
  newInviteToProject(project: Project, role: string, payload: any, qp: any = {}): Promise<Invitation> {
    return this.appHttpService.postService(`/v1/invitation/project/${project.id}/${role}`, payload, qp).toPromise()
      .then((resp: any) => {
        if ('data' in resp) {
          return new Invitation(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Invites new user to Organization
   * @param org Organization
   * @param role string
   * @param payload body
   * @param qp query-params
   * @returns {Promise<Invitation>}
   */
  newInviteToOrganization(org: Organization, role: string, payload: any, qp: any = {}): Promise<Invitation> {
    return this.appHttpService.postService(`/v1/invitation/organization/${org.id}/${role}`, payload, qp).toPromise()
      .then((resp: any) => {
        if ('data' in resp) {
          return new Invitation(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

}

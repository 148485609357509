<div class="wrapper">
  <div class="heading text-type-5">
    {{ data?.heading }}
  </div>
  <div
    *ngIf="data?.subheading"
    class="subheading text-type-11"
  >
    {{ data.subheading }}
  </div>
  <div
    *ngIf="data?.subType !== 'bidItemWithMaterials'; else bidItemWithMaterials"
    class="subheading text-type-11 flex gap-[4px]"
    [class.mb-[24px]]="data.subType === 'onlyMaterial'"
  >
    <div class="label">{{data.type === 'delivery' ? 'Received' : 'Used'}}:</div>
    <div class="value">{{ data.quantity }} {{data.uom}}</div>
  </div>
  <ng-template #bidItemWithMaterials>
    <div class="subheading text-type-11 flex gap-[4px]" >
      {{data.materials?.length}} Material{{ data.materials?.length === 1 ? '' : 's'}}
    </div>
  </ng-template>
</div>

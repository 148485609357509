<mat-progress-bar color="accent" mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="container">
  <div class="left-tab-viewer">
    <div class="list">
      <mat-selection-list [multiple]="false" (selectionChange)="selectionChange($event)">
        <mat-list-option *ngFor="let report of reports" [value]="report" [selected]="report.selected">
          <mat-icon mat-list-icon>analytics</mat-icon>
          <div mat-line>
            <span class="mat-body-strong">{{report.name}}</span>
          </div>
          <div mat-line>
            <span class="mat-caption">{{report.updated_at | date}}</span>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </div>
    <div class="content">

      <div class="mat-body-strong" style="text-align: center;" *ngIf="!reports.length">
        No reports found.
        <button mat-button color="accent" class="mat-inline-button" [disabled]="!('create' | able: newReport)"
          (click)="toggleForm(null, true)" aria-label="Add" title="Add">
          Add report?</button>
      </div>

      <form #vcForm [formGroup]="inputForm">
        <section style="display: none;">
          <input matInput type="text" formControlName="id" />
        </section>
        <div class="row">
          <!-- name section -->
          <mat-form-field>
            <mat-label>Name</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.name.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.name.hasError('error')">
              {{stationForm.controls.name.errors.error}}
            </mat-error>
          </mat-form-field>
          <!-- /label section -->

          <div class="icons">
            <button mat-icon-button aria-label="Add" color="none" (click)="toggleForm(null, true)" *ngIf="report?.id" [disabled]="!('create' | able: newReport)">
              <mat-icon title="Add">add</mat-icon>
            </button>
            <button mat-icon-button aria-label="Save" color="none" (click)="save(inputForm)">
              <mat-icon title="Save">save</mat-icon>
            </button>
            <button mat-icon-button aria-label="Delete" color="warn" (click)="delete(report)" [disabled]="!('delete' | able: report)" *ngIf="report?.id">
              <mat-icon title="Delete">delete</mat-icon>
            </button>
          </div>
        </div>
        <section>
          <mat-form-field>
            <textarea class="editor" rows="40" matInput formControlName="template" [errorStateMatcher]="matcher"></textarea>
            <mat-error *ngIf="inputForm.controls.template.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.template.hasError('error')">
              {{stationForm.controls.template.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
      </form>

    </div>
  </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { Account, Issue, IssueType, Project } from '../../../../shared/models';
import { ProjectService } from '../../../../shared/services';

@Component({
  selector: 'app-issue-card-type-two',
  templateUrl: './issue-card-type-two.component.html',
  styleUrls: ['./issue-card-type-two.component.scss']
})
export class IssueCardTypeTwoComponent implements OnInit {

  public initialized = false;
  @Input() public isSelected: boolean;
  @Input() public issue: Issue;
  @Input() public issueTypes: IssueType[] = [];
  @Input() public project: Project;
  @Input() public type = true;
  @Input() public status = true;
  @Input() public internal = true;

  public account: Account;

  constructor(
    private projectSrv: ProjectService
  ) { }

  ngOnInit(): void {
    if (this.issue.createdBy) {
      this.account = this.issue.createdBy;
    }
    this.initialized = true;
  }

  issueTypeLabel(type) {
    return (this.issueTypes || []).find(o => o?.type === type)?.label || type;
  }
}

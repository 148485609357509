import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import {
  MatLegacySnackBar as MatSnackBar,
} from '@angular/material/legacy-snack-bar';
import {
  BidItem,
  DailyReport,
  Field,
  Heading,
  Project,
  ReportBidItem,
  Station,
} from 'src/app/shared/models';
import {
  AppErrorStateMatcher,
  AppService,
  DailyReportService,
  FieldService,
  ProjectService,
  SiteService,
} from 'src/app/shared/services';
import {
  BidItemSelectorComponent,
} from '../../bid-item-selector/bid-item-selector.component';
import { BidService } from 'src/app/shared/services/bid.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/shared/components';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Parser } from 'expr-eval';

@Component({
  selector: 'app-rbi',
  templateUrl: './rbi.component.html',
  styleUrls: ['./rbi.component.scss']
})
export class RbiComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  public initialized = false;
  public loading = 0;
  public changes = 0;

  @Input() public editable = false;
  @Input() public isDialog = true;
  public dialogOptions: any = {};
  public componentResult: any = null;

  @Input() rbis: ReportBidItem[] = [];
  @Input() biditem: BidItem;
  @Input() heading: Heading;
  @Input() dr: DailyReport;
  @Input() project: Project;

  public fields: Field[] = [];
  public headings: Heading[] = [];
  public rollupField: Field;
  public headerItem: ReportBidItem;
  public lineItems: ReportBidItem[] = [];
  public selectedItem: ReportBidItem;

  public recentValueQry: any;

  public fromStation = new UntypedFormControl();
  public toStation = new UntypedFormControl();
  public fromOptions: Observable<Station[]>;
  public toOptions: Observable<Station[]>;

  public matcher = new AppErrorStateMatcher();

  get byStation(): boolean {
    return this.biditem?.record_by_station || false;
  }

  get hasDirtyItems(): boolean {
    return [
      this.headerItem?.isDirty,
      ...this.lineItems.map(li => li.isDirty)
    ].reduce((p, c) => p || c, false);
  }

  isQuantityEditable: boolean;

  constructor(
    private projectSrv: ProjectService,
    private siteSrv: SiteService,
    private fieldSrv: FieldService,
    private bidSrv: BidService,
    private drSrv: DailyReportService,
    public appSrv: AppService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RbiComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.isDialog) {
      const {
        project,
        rbis = [],
        biditem,
        heading,
        dailyReport,
        options
      } = this.inputData;

      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.biditem = biditem ?? null;
      this.heading = heading ?? null;
      this.rbis = rbis.slice();
      this.project = project;
      this.dr = dailyReport;
    }

    // link some project level data to line items
    const biditems = this.project.bid_items || [];
    const allHeadings = this.project.sites.map(s => s.headings || []).reduce((p, c) => p.concat(c), []);
    const allStations = allHeadings.map(h => h.stations || []).reduce((p, c) => p.concat(c), []);
    this.rbis.map(o => {
      o.heading = allHeadings.find(h => h.id === o.heading_id);
      o.station = allStations.find(s => s.id === o.station_id);
      o.bid_item = biditems.find(bi => bi.id === o.bid_item_id);
    });

    if (!this.biditem) {
      this.openBidItemSelector();
    } else {
      this.init();
    }
  }

  init(): void {
    const { fields = [], rollup_field, headings = [] } = this.biditem;
    this.fields = fields || [];
    this.rollupField = rollup_field || null;
    this.headings = headings || [];
    this.heading = this.headings.find(o => o.id === this.heading?.id);
    this.isQuantityEditable = this.biditem.rollup_quantity_editable;

    const rbis = this.rbis.filter(o => {
      return (o.bid_item_id === this.biditem?.id)
             && (this.byStation ? o?.heading_id === this.heading?.id : true);
    });

    if (this.byStation) {

      // grab heading from lineitems
      if (!this.heading) {
        const [headingId] = [...new Set(rbis.map(o => o.heading?.id)
          .filter(o => !!o))];
        this.heading = this.headings.find(o => headingId === o.id);
      }

      if (this.biditem && this.heading) {
        // recent field qry properties
        this.recentValueQry = {
          siteId: this.dr?.site_id,
          date: this.dr?.report_date.format('YYYY-MM-DD'),
          biditemId: this.biditem?.id,
          headingId: this.heading?.id
        };
        this.siteSrv.fetchRecentBiditemValues(this.recentValueQry);
        this.initStationAutoSuggest();
      }
    }

    this.lineItems = [];
    rbis.filter(o => !!o.station_id).map(o => this.addLineItem(o));
    this.selectHeaderItem(rbis);
    this.initialized = true;
  }

  addLineItem(li: any, heading: Heading = null, station: Station = null) {
    if (!this.byStation) {
      return;
    }

    const i = this.lineItems.find(o => o.station?.name === station?.name);
    if (i) {
      return;
    }
    li = li ?? new ReportBidItem({
      daily_report_id: this.dr?.id,
      bid_item: this.biditem,
      heading: heading ?? null,
      station: station ?? null,
    });

    this.toReactiveForm(li);
    this.lineItems.push(li);

    // sort lineitems wrt heading.stations
    this.heading.sortLineItems(this.lineItems);

    // open newly added lineitem
    const newLi = this.lineItems.find(o => !o?.id);
    if (newLi && newLi?._id == li?._id) {
      this.selectLineItem(newLi);
    }
  }

  selectHeaderItem(rbis: ReportBidItem[] = []): void {
    const newLi = new ReportBidItem({
      daily_report_id: this.dr?.id,
      bid_item: this.biditem,
      heading: this.heading,
    });

    if (this.byStation) {
      this.headerItem = rbis.find(o => o.heading?.id && !o.station) || newLi;
    } else {
      this.headerItem = rbis.length ? rbis[0] : newLi;
    }
    this.headerItem._isHeader = true;

    this.toReactiveForm(this.headerItem);

    this.selectLineItem();
    this.updateRollup();
  }

  /**
   * Opens BidItem Selector
   */
  openBidItemSelector(): void {
    const dialogRef = this.dialog.open(BidItemSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: this.project,
        biditems: this.project?.bid_items,
        options: {
          fetchData: false,
        }
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof BidItem) {
        this.biditem = resp;
        this.init();
      } else {
        this.dialogRef.close(null);
      }
    });
  }

  onLineItemChange(li: ReportBidItem) {
    const { comment, pictures, annotations, location } = li;
    this.selectedItem.setValue('comment', comment);
    this.selectedItem.setValue('pictures', pictures);
    this.selectedItem.setValue('annotations', annotations);
    this.selectedItem.setValue('location', location);
  }

  toReactiveForm(m: any) {
    const isHeader = m?._isHeader || false;

    let form = this.fb.group({
      id: [''],
      bid_item_id: [''],
      quantity: [''],
      isEditable: [false],
      isManual: [false],
      initial: [true],
      heading_id: ['', (isHeader && this.byStation) ? Validators.required : []]
    });

    // add biditem fields to form
    if (this.fields.length) {
      form = this.fieldSrv.toFormGroup(form, this.fields);
    }
    form.controls.quantity.enable();

    if (this.biditem['rollup']) {
      form.controls.isManual.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe((d) => {
          if (!d) {
            form.controls.quantity.disable({emitEvent: false});
          } else {
            form.controls.quantity.enable({emitEvent: false});
          }
        });
    }

    form.reset({
      id: m?.id,
      bid_item_id: this.biditem?.id,
      quantity: m?.quantity,
      heading_id: this.heading?.id,
      isEditable: !this.biditem['rollup'] || (this.biditem['rollup'] && this.biditem.rollup_quantity_editable),
      isManual: (this.biditem['rollup'] && this.biditem.rollup_quantity_editable) ? false : true,
      initial: true,
      ...(m?.field_values || []).reduce((p, c) => ({ ...p, [c.id]: c.value }), {}),
    });
    m._inputForm = form;
  }

  /**
   * onSubmit() handler for station form
   * @param form FormGroup
   * @returns {void}
   */
  async save(rbi: ReportBidItem, options: any = {}) {
    const { showMessage = true } = options;
    const form = rbi?._inputForm;

    if (!form.valid) {
      return;
    }

    const payload = rbi.toPayload(form.getRawValue(), this.fields);

    this.loading++;
    return await this.drSrv.saveLineItem('bid_item', payload, this.dr, { include: [] })
      .then((resp: ReportBidItem) => {
        if (showMessage) {
          this.snackBar.open('Saved Bid item', '', { duration: 5000 });
        }
        this.changes++;

        rbi = Object.assign(rbi, resp);
        this.toReactiveForm(rbi);
        return resp;
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
        return resp;
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes line item
   * @param li LineItem
   * @returns void
   */
  delete(li: ReportBidItem, options: any = {}): void {
    const { showMessage = true } = options;
    if (!li.id && li._id) {
      this.lineItems.splice(this.lineItems.findIndex(o => o._id === li._id), 1);
      return;
    }

    if (!showMessage) {
      this.drSrv.deleteLineItem('bid_item', li, this.dr)
        .then(() => {
          this.changes++;
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
        });
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting bid item cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.loading++;
      this.drSrv.deleteLineItem('bid_item', li, this.dr)
        .then(resp => {
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
          this.changes++;
          this.updateRollup();
          this.snackBar.open(resp.message || 'Deleted', '', { duration: 5000 });
          if (li.id === this.headerItem?.id) {
            this.dialogRef.close(1);
          }
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        })
        .finally(() => {
          this.loading--;
        });
    });
  }

  onHeadingChange(heading: Heading) {
    if (!heading) {
      return;
    }

    if (!this.hasDirtyItems) {
      this.init();
      return;
    }

    if (this.hasDirtyItems) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes in this heading.
          <br />Are you sure you want to discard?`
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.init();
        } else {
          // undo selection for dropdown
          this.heading = this.headings.find(o => o.id === this.headerItem?.heading?.id);
        }
      });
    }
  }

  addStations() {
    const from = this.fromStation.value;
    const to = this.toStation.value;
    const stations = this.heading.stations || [];

    const rs = stations.slice(
      stations.findIndex((o) => from.name === o.name),
      stations.findIndex((o) => to.name === o.name) + 1,
    );

    // add or merge lineitems
    rs.map((s) => this.addLineItem(null, this.heading, s));
    this.fromStation.setValue(null);
    this.toStation.setValue(null);
  }


  saveAll() {
    // dirty workaround to trigger validations
    // mark all forms as touched
    const isValid = [
      this.headerItem,
      ...this.lineItems,
    ].map(o => {
      o?._inputForm?.markAsDirty();
      o?._inputForm?.markAllAsTouched();
      return o?._inputForm?.valid;
    }).reduce((p, c) => p && c, true);

    if (!isValid) {
      // show some error
      return;
    }

    // attempt save
    Promise.all([
      this.save(this.headerItem, { showMessage: false }),
      ...this.lineItems.map((o) => this.save(o, { showMessage: false })),
    ]).then((d) => {
      this.snackBar.open('Saved Bid item', '', { duration: 5000 });
      this.dialogRef.close(1);
    });
  }

  deleteAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting biditem cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      Promise.all([
        ...this.lineItems.map((o) => this.delete(o, { showMessage: false })),
        this.delete(this.headerItem, { showMessage: false }),
      ]).then(() => {
        this.snackBar.open('Biditem deleted', '', { duration: 5000 });
        this.dialogRef.close(1);
      });
    });
  }

  selectLineItem(li: any = null) {
    if (!li) {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = this.headerItem || undefined, 200);
      return;
    }

    if (this.selectedItem?._id === li?._id) {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = this.headerItem || undefined, 200);
    } else {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = li, 200);
    }
  }

  updateRollup() {
    if ((!this.headerItem || !this.rollupField) && !this.biditem['rollup']) {
      return;
    }

    // todo trigger rollup on rollupfield input changes
    this.headerItem.quantity = this.bidSrv.getRollup(this.biditem, this.headerItem, this.lineItems);

    if (this.headerItem) {
      const fieldObj = {};
      // Created a map of the field name vs value.
      // This will be utilised in the eval parser to calculate the values
      this.headerItem?.bid_item?.fields?.map(o => {
        fieldObj[o.name.toLowerCase()] = Number(this.headerItem?._inputForm.controls[o.id].value);

        this.headerItem?._inputForm?.controls[o.id]?.valueChanges
          .pipe(takeUntil(this.onDestroy))
          .subscribe(data => {
            this.headerItem?._inputForm?.controls?.initial.setValue(false, { emitEvent: false });
            fieldObj[o.name.toLowerCase()] = data;


            if (!this.headerItem?._inputForm?.controls?.isManual.value) {
              const rolledUpValue = Number(
                (Parser.evaluate(this.headerItem.bid_item.rollup_formula, fieldObj))
                  .toFixed(2),
              );
              this.headerItem?._inputForm?.controls?.quantity?.setValue(rolledUpValue, { emitEvent: false });
            }
          });
      });

      // Using "Temp" to create an array of all rollup variables to see if
      // all values exists before calculating.
      const temp = Object.values(fieldObj).filter(o => o);

      this.headerItem?._inputForm?.controls.isManual.valueChanges
        .pipe(
          takeUntil(this.onDestroy)
        )
        .subscribe(data => {
          if (!data && temp.length === this.headerItem?.bid_item?.fields?.length) {
            const rolledUpValue = Number(
              (Parser.evaluate(this.headerItem.bid_item.rollup_formula, fieldObj))
                .toFixed(2)
            );
            this.headerItem?._inputForm?.controls?.quantity?.setValue(rolledUpValue, {emitEvent: false});
          }
        });

      if (temp.length === this.headerItem?.bid_item?.fields?.length) {
        const rolledUpValue = Number(
          (Parser.evaluate(this.headerItem.bid_item.rollup_formula, fieldObj))
            .toFixed(2)
        );

        if (
          this.headerItem?._inputForm?.controls?.quantity?.value &&
          this.headerItem?._inputForm?.controls?.initial?.value
        ) {
          this.headerItem?._inputForm.controls.isManual.setValue(true);
        } else {
          if (this.headerItem?._inputForm?.controls?.initial?.value) {
            this.headerItem?._inputForm.controls.isManual.setValue(false, {emitEvent: false});
            this.headerItem?._inputForm?.controls?.quantity?.setValue(rolledUpValue, {emitEvent: false});
          }
        }
      }

      if (this.headerItem?._inputForm?.controls?.quantity?.value && temp.length < Object.values(fieldObj).length) {
        this.headerItem?._inputForm.controls.isManual.setValue(true, { emitEvent: false });
        this.headerItem?._inputForm?.controls?.quantity.enable({ emitEvent: false });
      }
    }

    this.lineItems.map(li => {
      li?._inputForm?.controls?.quantity?.setValue(li.quantity);
    });
  }

  getWidth(field) {
    return field?.type === 'textarea' ? 'width-100' : 'width-50';
  }

  onClose() {
    if (!this.hasDirtyItems) {
      this.dialogRef.close(this.changes);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Unsaved changes',
        `There are unsaved changes since the last opening of this item.
        <br />Are you sure you want to discard?`,
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(this.changes);
      }
    });
  }

  toggleFlag(li: ReportBidItem, flag: string) {
    li[flag] = !li[flag];
    this.save(li);
  }

  initStationAutoSuggest() {
    this.fromOptions = this.fromStation.valueChanges.pipe(
      startWith(''),
      map((name: string) => this._filter(name, this.heading?.stations))
    );
    this.toOptions = this.toStation.valueChanges.pipe(
      startWith(''),
      map((name: string) => this._filter(name, this.heading?.stations))
    );
  }

  private _filter(q: string | Station, list: Station[]): Station[] {
    const name = (typeof q === 'string' ? q : q?.name || '').toLocaleLowerCase();
    if (!name) {
      return (list || []).slice(0, 5);
    }
    return (list || [])
      .filter(o => o.name.toLowerCase().includes(name)).slice(0, 5);
  }

  displayFn(s: any): string {
    return s && s.name ? s.name : '';
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

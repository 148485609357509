import { BaseModel } from './base-model';
import { Project } from './project';
import { Labor } from './labor';

export class Crew extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Crew'; }

  /* tslint:disable */
  name: string;
  labor_ids: string[];
  labors: Labor[];
  project: Project;
  project_id: string;
  /* tslint:enable */

  get relations() {
    return {
      labors: Labor,
      project: Project,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.project_id = this.project?.id || this.project_id;
    this.labor_ids = Array.isArray(this.labors)
      ? this.labors.map(o => o.id)
      : this.labor_ids || [];
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      name: this.name,
      labor_ids: this.labors.map(o => o.id),
    };
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../state/app.state';
import { issueTypesLabelMap } from '../../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-issue-summary',
  templateUrl: './issue-summary.component.html',
  styleUrls: ['./issue-summary.component.scss'],
})
export class IssueSummaryComponent implements OnInit, OnDestroy {
  @Input() issue: any;
  issueTypesLabelMap: any = {
    standard: 'Standard',
    environment: 'Environment',
    safety: 'Safety',
    'as-built': 'As built',
  };
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit() {
    this.store.select(issueTypesLabelMap)
      .pipe(takeUntil(this.onDestroy))
      .subscribe((d) => {
        if (Object.keys(d).length > 0) {
          this.issueTypesLabelMap = d;
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

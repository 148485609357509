import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-consolidated-report-bid-item-details',
  templateUrl: './consolidated-report-bid-item-details.component.html',
  styleUrls: ['./consolidated-report-bid-item-details.component.scss'],
})
export class ConsolidatedReportBidItemDetailsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  pictures = [];
  form: FormGroup = new FormGroup({
    quantity: new FormControl({ value: '', disabled: true }),
    comment: new FormControl({ value: '', disabled: true }),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    pictures: new FormControl('[]'),
  });
  disableOptionMenu = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.form.patchValue({
        quantity: this.data?.quantity,
        comment: this.data?.comment,
        latitude: this.data?.latitude,
        longitude: this.data?.longitude,
        pictures: JSON.stringify(this.data?.contractor_pictures),
      });

      this.pictures = this.data?.contractor_pictures;
    }
  }

  ngOnDestroy() {
    this.cdRef.detach();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

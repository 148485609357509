<div
  class="wrapper"
  [class.selected]="selectedReportDate === report?.report_date"
>
  <div class="heading text-type-5 md-[4px]">
    {{
      report.report_date | date:'MMMM dd, YYYY'
    }}
  </div>
  <!--
      Since all reports are of status 'submitted',
      We are hard coding the status text
  -->
  <div class="subheading text-type-10">Sent for signature</div>
</div>

<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>

    <button *ngIf="initialized" mat-mini-fab (click)="toggleForm(bidItem, true)"
      [disabled]="!('update' | able: bidItem)">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <div class="container">
    <!-- title section -->
    <section class="header">
      <div class="title">
        <ng-container *ngIf="bidItem?.id">{{bidItem.item}}</ng-container>
        <ng-container *ngIf="!bidItem?.id">Add Biditem</ng-container>
      </div>
    </section>
    <!-- /title section -->
  </div>

  <mat-tab-group [selectedIndex]="0">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Details">
          info
        </span>
      </ng-template>
      <ng-template matTabContent>
        <ng-container *ngIf="initialized">
          <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
        </ng-container>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Headings">
          tour
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-biditem-headings [bidItem]="bidItem"></app-biditem-headings>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Fields">
          settings_timelapse
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-fields entityType="bid-item" context="fields" captureRecentField="true" recordRange="true"
          [entity]="bidItem" [stationProperties]="stationProperties"></app-fields>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Materials">
          package_2
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-biditem-materials [bidItem]="bidItem"></app-biditem-materials>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Internal Tests">
          labs
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-biditem-internal-tests [bidItem]="bidItem"></app-biditem-internal-tests>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Site Qty">
          map
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-biditem-site-quantities [bidItem]="bidItem"></app-biditem-site-quantities>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>


<ng-template #InputFormContent>
  <!-- Input Form -->
  <form [formGroup]="inputForm">
    <div class="container">
      <!-- item section -->
      <section>
        <div class="mat-subheading-1 row">Item:</div>
        <div class="mat-body" *ngIf="!editing">{{bidItem.item}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="text" autocomplete="off" formControlName="item" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.item.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.item.hasError('error')">
            {{inputForm.controls.item.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /item section -->

      <!-- quantity section -->
      <section>
        <div class="mat-subheading-1 row">Quantity:</div>
        <div class="mat-body" *ngIf="!editing">{{bidItem.quantity}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="number" autocomplete="off" formControlName="quantity" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.quantity.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.quantity.hasError('error')">
            {{inputForm.controls.quantity.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /quantity section -->

      <!-- uom section -->
      <section>
        <div class="mat-subheading-1 row">UOM:</div>
        <div class="mat-body" *ngIf="!editing">{{bidItem.uom}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="text" autocomplete="off" formControlName="uom" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.uom.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.uom.hasError('error')">
            {{inputForm.controls.uom.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /uom section -->

      <!-- unit_price section -->
      <section>
        <div class="mat-subheading-1 row">Unit price:</div>
        <div class="mat-body" *ngIf="!editing">{{bidItem.unit_price}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="number" autocomplete="off" formControlName="unit_price" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.unit_price.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.unit_price.hasError('error')">
            {{inputForm.controls.unit_price.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /unit_price section -->

      <!-- track_material_quantity section -->
      <section>
        <div class="mat-subheading-1 row">
          <div>Track Qty:</div>
          <div class="icons">
            <ng-container *ngIf="!editing">
              <ng-container *ngIf="bidItem?.track_material_quantity">Yes</ng-container>
              <ng-container *ngIf="!bidItem?.track_material_quantity">No</ng-container>
            </ng-container>
            <ng-container *ngIf="editing">
              <mat-slide-toggle formControlName="track_material_quantity"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.controls.track_material_quantity.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.track_material_quantity.hasError('error')">
                {{inputForm.controls.track_material_quantity.errors.error}}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- /track_material_quantity section -->

      <!-- record by station -->
      <section>
        <div class="mat-subheading-1 row">
          <div>Record by station:</div>
          <div class="icons">
            <ng-container *ngIf="!editing">
              <ng-container *ngIf="bidItem?.record_by_station">Yes</ng-container>
              <ng-container *ngIf="!bidItem?.record_by_station">No</ng-container>
            </ng-container>
            <ng-container *ngIf="editing">
              <mat-slide-toggle formControlName="record_by_station"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.controls.record_by_station.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.record_by_station.hasError('error')">
                {{inputForm.controls.record_by_station.errors.error}}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- /record by station -->

      <!-- site_id section -->
      <section>
        <div class="mat-subheading-1 row">Site:</div>
        <div class="mat-body capitalize" *ngIf="!editing">{{bidItem.site?.name}}</div>
        <mat-form-field *ngIf="editing">
          <mat-label>Select site</mat-label>
          <mat-select formControlName="site_id" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let site of sites" [value]="site.id">
              <div>{{site.name}}</div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inputForm.controls.site_id.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.site_id.hasError('error')">
            {{inputForm.controls.site_id.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /site_id section -->

      <!-- rollup_quantity_editable section -->
      <section>
        <div class="mat-subheading-1 row">
          <div>Rollup Qty Editable:</div>
          <div class="icons">
            <ng-container *ngIf="!editing">
              <ng-container *ngIf="bidItem?.rollup_quantity_editable">Yes</ng-container>
              <ng-container *ngIf="!bidItem?.rollup_quantity_editable">No</ng-container>
            </ng-container>
            <ng-container *ngIf="editing">
              <mat-slide-toggle formControlName="rollup_quantity_editable"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.controls.rollup_quantity_editable.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.rollup_quantity_editable.hasError('error')">
                {{inputForm.controls.rollup_quantity_editable.errors.error}}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- /rollup_quantity_editable section -->

      <!-- rollup_formula section -->
      <section>
        <div class="mat-subheading-1 row">Rollup formula:</div>
        <div class="mat-body capitalize" *ngIf="!editing">{{bidItem.rollup_formula}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput formControlName="rollup_formula" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.rollup_formula.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.rollup_formula.hasError('error')">
            {{inputForm.controls.rollup_formula.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /rollup_formula section -->

      <!-- rollup_field_id section -->
      <!-- <section>
        <div class="mat-subheading-1 row">Rollup field:</div>
        <div class="mat-body capitalize" *ngIf="!editing">{{bidItem.rollup_field?.label}}</div>
        <mat-form-field *ngIf="editing">
          <mat-label>Select rollup field</mat-label>
          <mat-select formControlName="rollup_field_id" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let field of bidItem?.fields" [value]="field.id">
              {{field.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inputForm.controls.rollup_field_id.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.rollup_field_id.hasError('error')">
            {{inputForm.controls.rollup_field_id.errors.error}}
          </mat-error>
        </mat-form-field>
      </section> -->
      <!-- /rollup_field_id section -->

      <!-- description section -->
      <section>
        <div class="mat-subheading-1 row">Description:</div>
        <div class="mat-body" *ngIf="!editing">{{bidItem.description}}</div>
        <mat-form-field *ngIf="editing">
          <textarea matInput formControlName="description" [errorStateMatcher]="matcher"></textarea>
          <mat-error *ngIf="inputForm.controls.description.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.description.hasError('error')">
            {{inputForm.controls.description.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /description section -->

      <!-- Form Actions -->
      <section>
        <div class="row" *ngIf="editing">
          <button mat-stroked-button color="accent" (click)="save()">
            <ng-container *ngIf="bidItem?.id">Update</ng-container>
            <ng-container *ngIf="!bidItem?.id">Create</ng-container>
          </button>
          <button mat-stroked-button (click)="onCancel()">Cancel</button>
        </div>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
  <!-- /Input form -->
</ng-template>
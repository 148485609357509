import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { Router } from '@angular/router';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DailyReportService } from '../../../../daily-report.service';
import { FetchEquipmentFilterRequest, FetchSubContractorFilterRequest } from '../../../../../../../../core/projects.actions';
import { dailyReport, equipmentFilter, subContractorFilter } from '../../../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';

@Component({
  selector: 'app-old-equipment-format-type-form',
  templateUrl: './old-equipment-format-type-form.component.html',
  styleUrls: ['./old-equipment-format-type-form.component.scss'],
})
export class OldEquipmentFormatTypeFormComponent implements OnInit, OnChanges, OnDestroy {

  @Input() data: any;
  subContractorOptions = [];
  equipmentOptions = [];
  projectId: string;
  pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 10000,
  };
  errorMessages: any = {};
  errorMessageMap = {
    type: {
      required: 'This field is required.',
    },
    sub_contractor: {
      required: 'This field is required.',
    },
    quantity: {
      required: 'This field is required.',
    },
  };
  form = new FormGroup({
    type: new FormControl({ value: null, disabled: false }, {
      validators: [Validators.required],
    }),
    sub_contractor: new FormControl({ value: null, disabled: false }),
    quantity: new FormControl({ value: null, disabled: false }, {
      validators: [Validators.required],
    }),
    duration: new FormControl({ value: null, disabled: false }),
    idle_duration: new FormControl({ value: null, disabled: false }),
    comment: new FormControl({ value: null, disabled: false }),
    is_rental: new FormControl({ value: false, disabled: false }),
  });
  formSubscription: Subscription = new Subscription();
  isRental: any;
  protected readonly event = event;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef,
  ) {
    const url = this.router.url;
    const segments = url.split('/');
    this.projectId = segments[2];

    const payload = {
      projectId: this.projectId,
      qp: {
        start: this.pagination.pageIndex * this.pagination.pageSize,
        total: this.pagination.pageSize,
        include: [''],
      },
    };

    this.store.dispatch(FetchSubContractorFilterRequest({ payload }));
    this.store.dispatch(FetchEquipmentFilterRequest({ payload }));

    this.store.select(subContractorFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.subContractorOptions = data;
      });

    this.store.select(equipmentFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.equipmentOptions = data;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.projectsService.isAnyFormDirty.next(true);
      });
  }

  ngOnInit() {
    if (this.data) {
      this.form.patchValue({
        type: this.data?.type,
        sub_contractor: this.data?.sub_contractor?.id,
        quantity: this.data?.quantity,
        duration: this.data?.duration,
        idle_duration: this.data?.idle_duration,
        comment: this.data?.comment,
        is_rental: this.data?.is_rental,
      }, { emitEvent: false });
      this.isRental = this.data.is_rental;
    } else {
      this.dailyReportService.disableOptionMenu.next(true);

      const newEquipment = {
        id: `new_${new Date().getTime()}`,
        sub_contractor: null,
        quantity: null,
        duration: null,
        idle_duration: null,
        comment: '',
        type: null,
        new_labor_format: false,
        is_rental: false,
      };

      this.store.select(dailyReport)
        .subscribe(() => {
          this.data = {
            ...newEquipment,
          };

          this.cdRef.detectChanges();
        });
    }

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.save();
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.delete();
        }
      });
  }

  save() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }
    let values = this.form.getRawValue();
    const { sub_contractor, ...rest } = values;
    const tempData = this.subContractorOptions.find((o) => o.id === sub_contractor);
    values = {
      ...rest,
      sub_contractor: tempData,
    };
    const payload = {
      ...this.data,
      ...values,
    };

    this.dailyReportService.save.next({
      type: 'equipment',
      data: [payload],
    });
  }

  delete() {
    this.dailyReportService.delete.next({
      type: 'equipment',
      id: this.data.id,
    });
  }

  onToggle(event: MatLegacySlideToggleChange) {
    this.form.controls['is_rental'].setValue(event.checked);
    this.isRental = event.checked;
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<ng-container *ngIf="initialized">

  <div class="mat-body-strong" *ngIf="!issues.length">No issues</div>

  <ng-container *ngIf="issues.length">

    <!-- Open issues -->
    <section *ngIf="open.length">
      <div class="mat-subheading-1">Open</div>
      <div class="list-items">
        <app-issue-card *ngFor="let issue of open"
          [project]="project"
          [issue]="issue" class="stroked-card link"
          (click)="onClick(issue)">
        </app-issue-card>
      </div>
    </section>
    <!-- /Open issues -->

    <!-- closed today issues -->
    <section *ngIf="closedToday.length">
      <div class="mat-subheading-1">Closed today</div>
      <div class="list-items">
        <app-issue-card *ngFor="let issue of closedToday"
          [project]="project"
          [issue]="issue"
          class="stroked-card link" [status]="false"
          (click)="onClick(issue)"></app-issue-card>
      </div>
    </section>
    <!-- /closed today issues -->

    <section>
      <a class="no-style link mat-subheading-1" [routerLink]="['/issues/project', project.id]"
        [queryParams]="{ e: 'site', i: site.id }">
        View all
      </a>
    </section>
  </ng-container>

</ng-container>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { Organization, OrganizationType, Account, Invitation } from '../models';

@Injectable({ providedIn: 'root' })
export class OrganizationService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  getRecord(id: string, qp = {}) {
    return this.appHttpService.getService(`/v1/organization/${id}`, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Organization(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetch OrganizationTypes
   */
  getTypes() {
    return this.appHttpService.getService('/v1/organization/types').pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(o => new OrganizationType(o));
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Returns list of Records
   * @param pagination start
   * @param filters query params
   */
  getRecords(pagination, filters = []) {
    const start = pagination.pageIndex * pagination.pageSize;
    const qp = { start, total: pagination.pageSize, include: ['type', 'createdBy', 'updatedBy']};
    return this.appHttpService.getService('/v1/organization', qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Organization(r));
          // e.g. total records, type etc.,
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Creates a new organization
   * @param input Organization
   */
  create(input) {
    return this.appHttpService.postService('/v1/organization', input).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Organization(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Updates Organization
   * @param body Organization
   */
  update(id, body) {
    return this.appHttpService.putService(`/v1/organization/${id}`, body).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Organization(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Deletes Organization
   * @param organization Organization
   */
  delete(organization: Organization) {
    // Todo check acl here
    return this.appHttpService.deleteService(`/v1/organization/${organization.id}`);
  }

  /**
   * Fetches Organization accounts
   * @param organization
   * @param pagination
   * @param qp
   */
  getAccounts(organization: Organization, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/organization/${organization.id}/accounts`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Account(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetches Organization invites
   * @param organization
   * @param pagination
   * @param qp
   */
  getInvites(organization: Organization, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    qp.organization_id = organization.id;
    const path = `/v1/invite/search`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Invitation(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Invite a user to organization
   * @param organization Organization
   * @param invitee email|phone
   * @param role Role.id
   * @param qp query-params
   */
  inviteUser(organization: Organization, invitee: string, role: string, qp: any = {}) {
    const payload = {
      invitee,
      invitee_role: role,
      invite_type: 'organization',
      invite_to: organization.id,
    };

    return this.appHttpService.postService('/v1/invite/account', payload).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Invitation(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }
}

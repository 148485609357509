import { BaseModel, Account, Attachment } from 'src/app/shared/models';

export class MaterialTestReport extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'MaterialTestReport'; }

  /* tslint:disable */
  comment: string;
  attachments: Attachment[];
  /* tslint:enable */

  /**
   * Workaround to initialize default values
   */
  init() {
    this.attachments = [];
  }

  get relations() {
    return {
      createdBy: Account,
      attachments: Attachment,
    };
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      attachments: (this.attachments || []).map(o => o.id),
      comment: this.comment,
    };
    return payload;
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-consolidated-report-expandable-block',
  templateUrl: './consolidated-report-expandable-block.component.html',
  styleUrls: ['./consolidated-report-expandable-block.component.scss'],
})
export class ConsolidatedReportExpandableBlockComponent implements OnInit, OnDestroy {
  @Input() icon = '';
  @Input() heading = '';
  @Input() subheading = '';
  @Input() isExpanded = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-map-popup-summary-type-two',
  templateUrl: './map-popup-summary-type-two.component.html',
  styleUrls: ['./map-popup-summary-type-two.component.scss', '../popup.styles.scss']
})
export class MapPopupSummaryTypeTwoComponent {
  @Input() data: any = {};
}

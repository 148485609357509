<div class="wrapper">
  <div class="header flex justify-between">
    <!-- Header Section -->
    <div class="heading text-type-1">Projects</div>

    <div
      [matMenuTriggerFor]="menu"
      class="menu-button text-type-8 flex gap-[20px]"
    >
      <div>
        {{ frequency }}
      </div>
      <mat-icon class="menu-icon" svgIcon="chevron-down" />

    </div>

    <mat-menu #menu="matMenu">
      <button (click)="setFrequency('All')" mat-menu-item>All</button>
      <button (click)="setFrequency('Recently updated')" mat-menu-item>Recently updated</button>
    </mat-menu>
  </div>

  <!-- Project List Section -->
  <div *ngIf="!isLoading" class="project-list-section">
    <ng-container *ngIf="paginatedFilteredProjects?.length; else noData">
      <ng-container
        *ngFor="let project of paginatedFilteredProjects; let lastItem = last;"
        class="each-project"
      >
        <app-projects-page-each-project-card
          [project]="project"
          [showProgress]="true"
          class="project-card"
          picHeight="150px"
        />
        <hr *ngIf="!lastItem" class="hr-line" />
      </ng-container>
    </ng-container>
    <ng-template #noData>
      <div class="no-data">
        There are no projects available for your selection.
      </div>
    </ng-template>
  </div>
  <app-paginator
    (pageChanged)="onPageChanged($event)"
    [itemsPerPage]="itemsPerPage"
    [totalItems]="filteredProjects.length"
    class="paginator"
  >
  </app-paginator>
</div>

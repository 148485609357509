<!--<div-->
<!--  class="filter-button status-filter flex flex-row gap-[20px]"-->
<!--  [matMenuTriggerFor]="menu"-->
<!--&gt;-->
<!--  <div class="label text-type-8">{{ buttonLabel }}</div>-->
<!--  <mat-icon-->
<!--    class="filter-icon"-->
<!--    svgIcon="filters"-->
<!--  />-->
<!--</div>-->
<!--<mat-menu-->
<!--  #menu="matMenu"-->
<!--  class="project-details-page-status-filter-popup"-->
<!--&gt;-->
<!--  <div class="filter-content" (click)="$event.stopPropagation();">-->
<!--    <mat-tab-group>-->
<!--      <mat-tab label="Status">-->
<!--        <mat-checkbox-->
<!--          value="all"-->
<!--          color="primary"-->
<!--          [checked]="isStatusAllChecked"-->
<!--          [indeterminate]="statusPartiallyComplete()"-->
<!--          (change)="onStatusCheckboxChange($event, -1)"-->
<!--        >-->
<!--          All-->
<!--        </mat-checkbox>-->
<!--        <div *ngFor="let status of statusOptions; let i = index">-->
<!--          <mat-checkbox-->
<!--            color="primary"-->
<!--            [checked]="status.isChecked"-->
<!--            [value]="status.value"-->
<!--            (change)="onStatusCheckboxChange($event, i)">-->
<!--            {{ status.label }}-->
<!--          </mat-checkbox>-->
<!--        </div>-->
<!--      </mat-tab>-->
<!--      <mat-tab label="Sites">-->
<!--        <form [formGroup]="searchForm">-->
<!--          <div class="filter-section search-section">-->
<!--            <div class="search">-->
<!--              <mat-icon svgIcon="search"></mat-icon>-->
<!--              <input-->
<!--                matInput-->
<!--                formControlName="sitesSearch"-->
<!--                placeholder="Search for sites"-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--        </form>-->

<!--        <mat-checkbox-->
<!--          *ngIf="!searchForm.controls.sitesSearch.value"-->
<!--          value="all"-->
<!--          color="primary"-->
<!--          [checked]="isSitesAllChecked"-->
<!--          [indeterminate]="sitesPartiallyComplete()"-->
<!--          (change)="onSiteCheckboxChange($event, -1)"-->
<!--        >-->
<!--          All-->
<!--        </mat-checkbox>-->
<!--        <div *ngFor="let site of filteredSitesOptions; let i = index">-->
<!--          <mat-checkbox-->
<!--            color="primary"-->
<!--            [checked]="site.isChecked"-->
<!--            [value]="site.value"-->
<!--            (change)="onSiteCheckboxChange($event, i)">-->
<!--            {{ site.label }}-->
<!--          </mat-checkbox>-->
<!--        </div>-->
<!--      </mat-tab>-->

<!--      <mat-tab label="Created by">-->
<!--        <form [formGroup]="searchForm">-->
<!--          <div class="filter-section search-section">-->
<!--            <div class="search">-->
<!--              <mat-icon svgIcon="search"></mat-icon>-->
<!--              <input-->
<!--                matInput-->
<!--                formControlName="creatorsSearch"-->
<!--                placeholder="Search for creator"-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
<!--        </form>-->

<!--        <mat-checkbox-->
<!--          *ngIf="!searchForm.controls.creatorsSearch.value"-->
<!--          value="all"-->
<!--          color="primary"-->
<!--          [checked]="isCreatorsAllChecked"-->
<!--          [indeterminate]="creatorsPartiallyComplete()"-->
<!--          (change)="onCreatorCheckboxChange($event, -1)"-->
<!--        >-->
<!--          All-->
<!--        </mat-checkbox>-->
<!--        <div *ngFor="let creator of filteredCreatorOptions; let i = index">-->
<!--          <mat-checkbox-->
<!--            color="primary"-->
<!--            [checked]="creator.isChecked"-->
<!--            [value]="creator.value"-->
<!--            (change)="onCreatorCheckboxChange($event, i)">-->
<!--            {{ creator.label }}-->
<!--          </mat-checkbox>-->
<!--        </div>-->
<!--      </mat-tab>-->
<!--    </mat-tab-group>-->
<!--  </div>-->
<!--</mat-menu>-->

<div
  #trigger="cdkOverlayOrigin"
  (click)="isOpen = true" cdkOverlayOrigin class="filter-button status-filter flex flex-row gap-[20px]" type="button"
>
  <div class="label text-type-8">{{ buttonLabel }}</div>
  <mat-icon
    class="filter-icon"
    svgIcon="filters"
  />
</div>

<ng-template
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlay
>
  <div class="project-details-page-status-filter-popup">
    <mat-tab-group>
      <mat-tab label="Status">
        <app-checkbox-list-with-filter
          (selectionChange)="onStatusCheckboxChange($event)"
          [displaySearch]="false"
          [displaySelectAll]="true"
          [options]="statusOptions"
          [parentFormGroup]="searchForm"
          controlName="statusSearch"
        />
      </mat-tab>
      <mat-tab label="Sites">
        <app-checkbox-list-with-filter
          (selectionChange)="onSiteCheckboxChange($event)"
          [displaySearch]="true"
          [displaySelectAll]="true"
          [options]="sitesOptions"
          [parentFormGroup]="searchForm"
          controlName="sitesSearch"
        />
      </mat-tab>
      <mat-tab label="Created by">
        <app-checkbox-list-with-filter
          (selectionChange)="onCreatorCheckboxChange($event)"
          [displaySearch]="true"
          [displaySelectAll]="true"
          [options]="creatorOptions"
          [parentFormGroup]="searchForm"
          controlName="creatorsSearch"
        />
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'humanReadable',
})
export class HumanReadablePipe implements PipeTransform {
  transform(input: number, decimals: number = 2) {
    decimals = isNaN(decimals) ? 2 : decimals;
    const a = [ 'K', 'M', 'B', 'T' ];
    let n = Number(input);
    let u = '';

    while (a.length && Math.floor(n) >= 1000) {
      n = n / 1000;
      u = a.shift();
    }

    return `${parseFloat(n.toFixed(decimals))} ${u}`;
  }
}
@Pipe({
  name: 'humanReadableSize',
})
export class HumanReadableSizePipe implements PipeTransform {
  transform(input: number, decimals: number = 2) {
    decimals = isNaN(decimals) ? 2 : decimals;
    const a = [ 'kb', 'mb', 'gb', 'tb' ];
    let n = Number(input), u = 'bytes';

    while (a.length && Math.floor(n) > 1024) {
      n = n / 1024;
      u = a.shift();
    }

    return `${parseFloat(n.toFixed(decimals))} ${u}`;
  }
}

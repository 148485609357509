import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor(private http: HttpClient) {}

  downloadCSV(apiUrl: string) {
    const headers = new HttpHeaders({
      Accept: 'text/csv'  // Ensures that the response is of CSV format
    });

    // Make the GET request with responseType as 'blob'
    return this.http.get(apiUrl, {
      headers,
      responseType: 'blob' as 'json' // 'blob' is needed for file downloads
    });
  }
}

<div class="header">
  <div class="left-icons">
    <button (click)="onClose()" mat-icon-button aria-label="Back">
      <mat-icon title="Back">arrow_back</mat-icon>
    </button>
  </div>
  <div class="title">Activities</div>
  <div class="right-icons">
    <button type="button" class="mat-inline-button" mat-button color="primary" (click)="saveAll()"
      [disabled]="!lineItems?.length">Done</button>
  </div>
</div>

<div class="body">
  <ng-container *ngIf="initialized">

    <!-- Lineitem Forms -->
    <section *ngFor="let li of lineItems;">
      <div class="row">
        <div (click)="selectLineItem(li)">
          <ng-container [ngTemplateOutlet]="SingleForm" [ngTemplateOutletContext]="{'activity': li}"></ng-container>
        </div>
        <div class="icons">
          <button mat-icon-button (click)="delete(li)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </section>
    <!-- /Lineitem Forms -->

    <!-- Add lineitem -->
    <section>
      <button mat-icon-button (click)="addLineItem()" color="primary">
        <mat-icon>add</mat-icon> Add activity
      </button>
    </section>
    <!-- /Add lineitem -->
  </ng-container>
</div>

<div class="footer" *ngIf="initialized">
  <app-report-item-toolbar *ngIf="selectedItem" [lineItem]="selectedItem" [project]="project"
    (changed)="onLineItemChange($event)" [tools]="tools"></app-report-item-toolbar>
</div>


<ng-template #SingleForm let-activity="activity">
  <form [formGroup]="activity._inputForm">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>

    <section>
      <mat-form-field>
        <input matInput type="text" autocomplete="off" formControlName="title" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="activity._inputForm.controls.title.hasError('required')">
          This field is required
        </mat-error>
        <mat-error *ngIf="activity._inputForm.controls.title.hasError('error')">
          {{activity._inputForm.controls.title.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
  </form>
  <ng-container [ngTemplateOutlet]="readOnlyToolbarItems" [ngTemplateOutletContext]="{'li': activity}"></ng-container>

</ng-template>



<ng-template #readOnlyToolbarItems let-li="li">
  <section *ngIf="li.pictures.length">
    <div class="mat-subheading-1">Pictures:</div>
    <div class="preview-list">
      <div *ngFor="let pic of li.pictures">
        <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, li.pictures, pic)" />
      </div>
    </div>
  </section>

  <section *ngIf="li.annotations.length">
    <div class="mat-subheading-1">Annotations:</div>
    <div class="preview-list">
      <div *ngFor="let ann of li.annotations">
        <img [src]="ann.getThumbnail()" class="link" />
      </div>
    </div>
  </section>

  <section *ngIf="!li.location.isEmpty">
    <div class="mat-subheading-1">Location:</div>
    <div>
      <app-location-picker [isDialog]="false" [latitude]="li.location.lat" [longitude]="li.location.lng" height="100px"
        [readOnly]="true"></app-location-picker>
    </div>
  </section>
</ng-template>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectsEffects } from './core/projects.effects';
import { reducer } from './core/projects.reducer';
import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectsWrapperComponent } from './wrapper/projects-wrapper.component';
import { ProjectsPageComponent } from './pages/projects-page/screen/projects-page.component';
import { ProjectCardComponent } from './pages/projects-page/components/project-card/project-card.component';
import { IssuesSummaryComponent } from './pages/projects-page/components/issues-summary/issues-summary.component';
import { StatusSummaryComponent } from './pages/projects-page/components/status-summary/status-summary.component';
import { ContractProgressCardComponent } from './pages/projects-page/components/progress-card/contract-progress-card.component';
import { ProjectDetailsScreenComponent } from './pages/projects-details-page/screen/project-details-screen.component';
import { CreateDailyReportComponent } from './components/create-daily-report/create-daily-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  StatusSiteFilterComponent,
} from './pages/projects-details-page/components/filters/status-site-filter/status-site-filter.component';
import { DateFilterComponent } from './pages/projects-details-page/components/filters/date-filter/date-filter.component';
import {
  PayappDateFilterComponent,
} from './pages/projects-details-page/components/filters/payapp-date-filter/payapp-date-filter.component';
import {
  PayappMultiFilterComponent,
} from './pages/projects-details-page/components/filters/payapp-multi-filter/payapp-multi-filter.component';
import { ProjectsLocationMapComponent } from './components/maps/projects-location-map/projects-location-map.component';
import { MapWrapperComponent } from './components/maps/map-wrapper/map-wrapper.component';
import {
  SiteWiseReportsComponent,
} from './pages/projects-details-page/components/tab-screens/sites/site-wise-reports/site-wise-reports.component';
import {
  SiteReportCardComponent,
} from './pages/projects-details-page/components/tab-screens/sites/site-report-card/site-report-card.component';
import {
  SiteReportsPanelComponent,
} from './pages/projects-details-page/components/tab-screens/sites/site-reports-panel/site-reports-panel.component';
import {
  ProjectSettingsComponent,
} from './pages/projects-details-page/components/tab-screens/settings/project-settings/project-settings.component';
import { ProjectModule } from '../project/project.module';
import { SubContractorModule } from '../sub-contractor/sub-contractor.module';
import { SitesLocationMapComponent } from './components/maps/sites-location-map/sites-location-map.component';
import { PayappComponent } from './pages/projects-details-page/components/tab-screens/pay-app/pay-app.component';
import {
  CreatePayappDialogComponent,
} from './pages/projects-details-page/components/dialogs/create-payapp-dialog/create-payapp-dialog.component';
import {
  PayappCommentsDialogComponent,
} from './pages/projects-details-page/components/dialogs/payapp-comments-dialog/payapp-comments-dialog.component';
import {
  AddReasonComponent,
} from './pages/projects-details-page/components/dialogs/add-reason/add-reason.component';
import {
  ConsolidatedReportWrapperComponent,
} from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-report-wrapper/consolidated-report-wrapper.component';
import {
  ProjectReportsListComponent,
} from './pages/projects-details-page/components/tab-screens/consolidated-reports/project-reports-list/project-reports-list.component';
import {
  ProjectReportsCardComponent,
} from './pages/projects-details-page/components/tab-screens/consolidated-reports/project-reports-card/project-reports-card.component';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { SiteDetailsScreenComponent } from './pages/site-details-page/screen/site-details-screen.component';
import {
  DailyReportWrapperComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-wrapper/daily-report-wrapper.component';
import { DailyReportComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report/daily-report.component';
import {
  DailyReportExpandableBlockComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-expandable-block/daily-report-expandable-block.component';
import {
  DailyReportsListComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-reports-list/daily-reports-list.component';
import {
  StationWiseBidItemSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/bid-item-summary/station-wise-bid-item-summary/station-wise-bid-item-summary.component';
import {
  MultilineBidItemSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/bid-item-summary/multiline-bid-item-summary/multiline-bid-item-summary.component';
import {
  BidItemDetailsWrapperComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/bid-item-details-wrapper/bid-item-details-wrapper.component';
import {
  StationWiseBidItemDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/station-wise-bid-item-details/station-wise-bid-item-details.component';
import {
  MultilineBidItemDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/multiline-bid-item-details/multiline-bid-item-details.component';
import { ActionsSectionComponent } from './pages/site-details-page/components/actions-section/actions-section.component';
import {
  BidItemsListComponent,
} from './pages/projects-details-page/components/tab-screens/change-orders/bid-items-list/bid-items-list.component';
import {
  UploadChangesComponent,
} from './pages/projects-details-page/components/tab-screens/change-orders/upload-changes/upload-changes.component';
import {
  BidItemsSideNavScreenComponent,
} from './pages/projects-details-page/components/tab-screens/change-orders/bidItemsSideNav/bid-items-side-nav-screen/bid-items-side-nav-screen.component';
import {
  BidItemsSitesDetailsComponent,
} from './pages/projects-details-page/components/tab-screens/change-orders/bidItemsSideNav/components/bid-items-sites-details/bid-items-sites-details.component';
import { SuccessComponent } from './pages/projects-details-page/components/tab-screens/change-orders/success-component/success.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  BidItemDetailsAddBidItemComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/bid-item-details/bid-item-details-add-bid-item/bid-item-details-add-bid-item.component';
import {
  ActivitySummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/activity-summary/activity-summary.component';
import {
  ActivityItemDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/activitiy-details/activity-item-details/activity-item-details.component';
import {
  ConsolidatedReportComponent,
} from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-report/consolidated-report.component';
import {
  ConsolidatedReportExpandableBlockComponent,
} from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-report-expandable-block/consolidated-report-expandable-block.component';
import {
  BidItemsExpandableRowTableComponent,
} from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/summary-components/bid-items-expandable-row-table/bid-items-expandable-row-table.component';
import {
  MaterialDeliveryDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/material-delivery-and-usage-details/material-delivery-details/material-delivery-details.component';
import { ConsolidatedReportBidItemDetailsComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/detail-components/consolidated-report-bid-item-details/consolidated-report-bid-item-details.component';

import {
  MaterialDeliveryAndUsageSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/material-delivery-and-usage-summary/material-delivery-and-usage-summary.component';
import {
  WeatherSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/weather-summary/weather-summary.component';
import {
  IssueSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/issue-summary/issue-summary.component';
import {
  AddDeliveryDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/delivery-details/add-delivery-details/add-delivery-details.component';
import {
  AddUsageDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/usage-details/add-usage-details/add-usage-details.component';
import {
  DeliveryDetailsWrapperComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/delivery-details/delivery-details-wrapper/delivery-details-wrapper.component';
import {
  DeliveryDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/delivery-details/delivery-details/delivery-details.component';
import {
  DeliveryDetailsFormComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/delivery-details/delivery-details-form/delivery-details-form.component';
import {
  UsageDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/usage-details/usage-details/usage-details.component';
import {
  UsageDetailsFormComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/usage-details/usage-details-form/usage-details-form.component';
import {
  UsageDetailsWrapperComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/usage-details/usage-details-wrapper/usage-details-wrapper.component';
import {
  LaborSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/labor-summary/labor-summary.component';
import {
  EquipmentSummaryComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/equipment-summary/equipment-summary.component';
import {
  LaborDetailsWrapperComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/labor-details/labor-details-wrapper/labor-details-wrapper.component';
import {
  SummarizedLaborDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/labor-details/summarized-labor-details/summarized-labor-details.component';
import {
  EquipmentDetailsWrapperComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/equipment-details/equipment-details-wrapper/equipment-details-wrapper.component';
import {
  SummarizedEquipmentDetailsComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/equipment-details/summarized-equipment-details/summarized-equipment-details.component';
import {
  OldLaborFormatTypeFormComponent,
} from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/labor-details/old-labor-format-type-form/old-labor-format-type-form.component';
import { OldEquipmentFormatTypeFormComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/equipment-details/old-equipment-format-type-form/old-equipment-format-type-form.component';
import { WeatherDetailsWrapperComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/weather-details/weather-details-wrapper/weather-details-wrapper.component';
import { WeatherDetailsFormComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/weather-details/weather-details-form/weather-details-form.component';
import { IssueDetailsWrapperComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/issue-details/issue-details-wrapper/issue-details-wrapper.component';
import { IssueDetailsFormComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/detail-components/issue-details/issue-details-form/issue-details-form.component';
import { SiteDetailsMapComponent } from './components/maps/site-details-map/site-details-map.component';
import { MaterialTestSummaryExternalComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/material-test-summary/material-test-summary-external/material-test-summary-external.component';
import { MaterialTestSummaryInternalComponent } from './pages/site-details-page/tab-screens/daily-report/daily-report-components/summary-components/material-test-summary/material-test-summary-internal/material-test-summary-internal.component';
import { ConsolidatedWeatherSummaryCardComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/summary-components/consolidated-weather-summary-card/consolidated-weather-summary-card.component';
import { ConsolidatedReportWeatherDetailsComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/detail-components/consolidated-report-weather-details/consolidated-report-weather-details.component';
import { ConsolidatedReportIssuesSummaryCardComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/summary-components/consolidated-report-issues-summary-card/consolidated-report-issues-summary-card.component';
import { ConsolidatedReportIssueDetailsComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/detail-components/consolidated-report-issue-details/consolidated-report-issue-details.component';
import { ConsolidatedReportActivitySummaryCardComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/summary-components/consolidated-report-activity-summary-card/consolidated-report-activity-summary-card.component';
import { ConsolidatedReportActivityDetailsComponent } from './pages/projects-details-page/components/tab-screens/consolidated-reports/consolidated-reports-components/detail-components/consolidated-report-activity-details/consolidated-report-activity-details.component';


@NgModule({
  declarations: [
    ProjectsWrapperComponent,
    ProjectsPageComponent,
    ProjectCardComponent,
    IssuesSummaryComponent,
    StatusSummaryComponent,
    ContractProgressCardComponent,
    ProjectDetailsScreenComponent,
    CreateDailyReportComponent,
    StatusSiteFilterComponent,
    DateFilterComponent,
    PayappDateFilterComponent,
    PayappMultiFilterComponent,
    ProjectsLocationMapComponent,
    MapWrapperComponent,
    SiteWiseReportsComponent,
    SiteReportCardComponent,
    SiteReportsPanelComponent,
    ProjectSettingsComponent,
    SitesLocationMapComponent,
    ConsolidatedReportWrapperComponent,
    PayappComponent,
    ProjectReportsListComponent,
    ProjectReportsCardComponent,
    SiteDetailsScreenComponent,
    DailyReportWrapperComponent,
    DailyReportComponent,
    DailyReportExpandableBlockComponent,
    DailyReportsListComponent,
    StationWiseBidItemSummaryComponent,
    MultilineBidItemSummaryComponent,
    BidItemDetailsWrapperComponent,
    StationWiseBidItemDetailsComponent,
    MultilineBidItemDetailsComponent,
    ActionsSectionComponent,
    BidItemsListComponent,
    UploadChangesComponent,
    BidItemsSideNavScreenComponent,
    BidItemsSitesDetailsComponent,
    SuccessComponent,
    CreatePayappDialogComponent,
    PayappCommentsDialogComponent,
    BidItemDetailsAddBidItemComponent,
    ActivitySummaryComponent,
    ActivityItemDetailsComponent,
    MaterialDeliveryAndUsageSummaryComponent,
    WeatherSummaryComponent,
    IssueSummaryComponent,
    AddDeliveryDetailsComponent,
    AddUsageDetailsComponent,
    DeliveryDetailsWrapperComponent,
    DeliveryDetailsComponent,
    DeliveryDetailsFormComponent,
    UsageDetailsComponent,
    UsageDetailsFormComponent,
    UsageDetailsWrapperComponent,
    LaborSummaryComponent,
    EquipmentSummaryComponent,
    LaborDetailsWrapperComponent,
    SummarizedLaborDetailsComponent,
    EquipmentDetailsWrapperComponent,
    SummarizedEquipmentDetailsComponent,
    OldLaborFormatTypeFormComponent,
    OldEquipmentFormatTypeFormComponent,
    WeatherDetailsWrapperComponent,
    WeatherDetailsFormComponent,
    IssueDetailsWrapperComponent,
    IssueDetailsFormComponent,
    MaterialTestSummaryExternalComponent,
    MaterialTestSummaryInternalComponent,
    AddReasonComponent,
    SiteDetailsMapComponent,
    ConsolidatedReportComponent,
    ConsolidatedReportExpandableBlockComponent,
    BidItemsExpandableRowTableComponent,
    MaterialDeliveryDetailsComponent,
    AddReasonComponent,
    ConsolidatedReportBidItemDetailsComponent,
    ConsolidatedWeatherSummaryCardComponent,
    ConsolidatedReportWeatherDetailsComponent,
    ConsolidatedReportIssuesSummaryCardComponent,
    ConsolidatedReportIssueDetailsComponent,
    ConsolidatedReportActivitySummaryCardComponent,
    ConsolidatedReportActivityDetailsComponent,
  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    SharedModule,
    StoreModule.forFeature('sitenotes.projects', reducer),
    EffectsModule.forFeature([ProjectsEffects]),
    FormsModule,
    ReactiveFormsModule,
    ProjectModule,
    SubContractorModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    MatTooltipModule,
  ],
  entryComponents: [BidItemDetailsWrapperComponent, ProjectsWrapperComponent],
})
export class ProjectsModule {
}

<ng-container *ngIf="initialized">
  <form [formGroup]="inputForm">
    <div class="container">

      <!-- Result -->
      <section>
        <div class="mat-subheading-1">Test result:</div>
        <div class="mat-body" *ngIf="!editing">
          <ng-container *ngIf="smt.status === 'passed'">Passed</ng-container>
          <ng-container *ngIf="smt.status === 'failed'">Failed</ng-container>
        </div>
        <mat-radio-group matInput aria-label="Select category" formControlName="result" *ngIf="editing"
          [errorStateMatcher]="matcher">
          <mat-radio-button value="passed">Passed</mat-radio-button>
          <mat-radio-button value="failed">Failed</mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="inputForm.controls.result.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.result.hasError('error')">
          {{inputForm.controls.type.errors.error}}
        </mat-error>
      </section>
      <!-- /Result -->

      <!-- Comment section -->
      <section *ngIf="inputForm.controls.result.value == 'failed'">
        <div class="mat-subheading-1">Note:</div>
        <div class="mat-body" *ngIf="!editing">{{smt.failed_reason}}</div>
        <mat-form-field *ngIf="editing">
          <textarea matInput formControlName="comment" [errorStateMatcher]="matcher"></textarea>
          <mat-error *ngIf="inputForm.controls.comment.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.comment.hasError('error')">
            {{inputForm.controls.comment.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /Comment section -->

      <!-- Form Actions -->
      <section>
        <div class="row" *ngIf="editing">
          <button mat-stroked-button color="primary" (click)="saveItem()">Submit</button>
        </div>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
</ng-container>

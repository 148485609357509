import {Component, OnDestroy} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Store, select} from '@ngrx/store';
import {Subject} from 'rxjs';
import { delay, take, takeUntil } from 'rxjs/operators';
import * as fromRoot from '../../../state/app.state';
import {isLoading} from '../../core/shared.selectors';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent implements OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private dialogRef: MatDialogRef<LoadingScreenComponent>,
    private commonService: CommonService,
    private store: Store<fromRoot.State>
  ) {
    this.commonService.closeLoadingScreen
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Material, Project } from 'src/app/shared/models';
import { AppErrorStateMatcher, MaterialService } from 'src/app/shared/services';

@Component({
  selector: 'app-material-create',
  templateUrl: './material-create.component.html',
  styleUrls: ['./material-create.component.scss']
})
export class MaterialCreateComponent implements OnInit {
  public loading = 0;
  public initialized = false;
  public editing = false;

  public dialogOptions: any = {};
  public componentResult: any = null;
  @Input() public data: any;
  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;

  public material: Material = new Material();
  public project: Project;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required]],
    uom: ['', [Validators.required]],
    track_received: ['', [Validators.required]],
    track_used: ['', [Validators.required]],
  });

  constructor(
    private materialSrv: MaterialService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MaterialCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  ngOnInit(): void {
    const { data, project, options } = this.inputData;
    if (data) {
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.project = project;
      this.data = data || { project_id: this.project?.id };
    }
    this.material = new Material(JSON.parse(JSON.stringify(this.data)));

    // open edit for new entity
    if (!this.material?.id) {
      this.toggleForm(this.material, true);
    }
    this.initialized = true;
  }

  /**
   * Toggles input form with model
   * @param model Material
   * @param show boolean
   */
  toggleForm(model: Material = null, show = true): void {
    this.material = model?.id ? model : new Material({
      project_id: this.project?.id,
    });
    this.editing = show;
    this.toReactiveForm(this.material, this.inputForm);
  }

  /**
   * Assign base model to FormGroup
   * @param material Material
   * @param form FormGroup
   */
  toReactiveForm(material: Material = null, form: UntypedFormGroup) {
    form.controls.name.setValue(material?.name);
    form.controls.track_received.setValue(material?.track_received || false);
    form.controls.track_used.setValue(material?.track_used || false);
    form.controls.uom.setValue(material?.uom);
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    if (this.material?.id) {
      this.toggleForm(this.material, false);
    } else {
      this.dialogRef.close(null);
    }

  }

  /**
   * Form submit
   */
  save() {

    if (!this.inputForm.valid) {
      return;
    }
    const isNew = !this.material?.id;

    const body = this.inputForm.value;
    this.loading++;

    const qp = { include: [] };
    const req = this.material.id
      ? this.materialSrv.update(this.material, body, qp)
      : this.materialSrv.create(this.material, this.project, body, qp);

    req.then((material: Material) => {
      material.project_id = this.project?.id;
      this.toggleForm(material, false);
      this.componentResult = this.material;
      this.snackBar.open(
        `${isNew ? 'Added': 'Updated'} material ${material?.name}`,
        '',
        { duration: 5000 },
      );
    })
    .catch((resp: HttpErrorResponse) => {
      if (resp.status === 422) {
        this.matcher.setServerErrors(this.inputForm, resp);
        return;
      }

      if (resp.error.error) {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      }
    })
    .finally(() => {
      this.loading--;
    });
  }

}

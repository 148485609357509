import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services';
import { Account } from 'src/app/shared/models';

/*
  In xfdf
    annot (annots.{index})
      name is unique
*/

// Todo Customize view with css/or config

@Component({
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AnnotationComponent implements OnInit {

  documentUrl: string;
  account: Account;

  annotations = `<?xml version="1.0" encoding="UTF-8"?>
  <xfdf xmlns="http://ns.adobe.com/xfdf/" xml:space="preserve">
      <annots>
          <text page="0" rect="232.06,573.21,263.06,604.21" color="#FFCD45" flags="print,nozoom,norotate" name="949c9a61-8285-dd61-4662-dc233b92d75a" title="John Doe" subject="Note" date="D:20200929145605+05'30'" creationdate="D:20200929145600+05'30'" icon="Comment" statemodel="Review">
              <trn-custom-data bytes="{&quot;userId&quot;:&quot;jmxN9FtyLrbwfs33MuHXLV&quot;,&quot;trn-mention&quot;:{&quot;contents&quot;:&quot;Hello&quot;,&quot;ids&quot;:[]}}" />
              <contents>Hello</contents>
          </text>
          <ink page="0" rect="798.8705735204393,676.3705735204393,824.0294264795607,692.3694264795607" color="#00CC63" flags="print" name="a433d542-cd54-20f1-c7b0-34ca69e6b51b" title="John Doe" subject="Free Hand" date="D:20200929145614+05'30'" width="5.709426479560707" creationdate="D:20200929145611+05'30'">
              <trn-custom-data bytes="{&quot;userId&quot;:&quot;jmxN9FtyLrbwfs33MuHXLV&quot;}" />
              <inklist>
                  <gesture>806.11,682.08;806.11,682.08;807.63,682.08;809.16,682.08;810.69,682.08;813.74,682.08;815.27,682.08;816.79,682.08;818.32,682.08;816.79,683.6;816.79,683.6;812.21,685.13;812.21,685.13;809.16,686.66;809.16,686.66;807.63,686.66;807.63,686.66;806.11,686.66;804.58,686.66</gesture>
              </inklist>
          </ink>
      </annots>
      <pages>
          <defmtx matrix="1,0,0,-1,0,792" />
      </pages>
  </xfdf>`;

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.documentUrl = 'https://site-notes-beta.s3-us-west-1.amazonaws.com/attachments/bkpXmhY2GUGHmmmxaBuG1i/original.pdf';
    this.account = this.authService.getCurrentUser();
  }

  annotationsChanged(annotationsXfdf) {
    console.log(annotationsXfdf);
    // Save annotations to database/server
  }

}

<div class="wrapper">
  <div class="heading text-type-2">Add delivery</div>
  <form [formGroup]="form" class="flex flex-col gap-[20px]">
    <app-text-input
      [parentFormGroup]="form"
      controlName="searchText"
      placeholder="Search bid item, material"
      label="DELIVERY ITEM"
      suffixIcon="search"
      (focusin)="showList = true"
    />

    <div *ngIf="showList" class="materials-list">
      <ng-container *ngIf="filteredElementsList.length > 0; else noData">
        <div
          *ngFor="let eachElement of filteredElementsList; let first = first; let last = last"
          class="each-item flex justify-start items-center gap-[16px]"
          [class.first]="first"
          [class.last]="last"
          (click)="addDelivery(eachElement)"
        >
          <div class="flex flex-col justify-center items-start">
            <div class="element-heading text-type-6">{{ eachElement.item || eachElement.name }}</div>
            <div
              *ngIf="eachElement.description"
              class="description text-type-13"
            >
              {{ eachElement.description }}
            </div>
            <div class="uom text-type-14">{{ eachElement.uom }}</div>
          </div>
        </div>
      </ng-container>
      <ng-template #noData>
        <div class="no-data">
          No data available.
        </div>
      </ng-template>
    </div>
  </form>
</div>

import { BaseModel, Account, Attachment } from 'src/app/shared/models';
import * as moment from 'moment';
import { GuestAccount } from '../../guest-actions/models';
import { SubmittalPackage } from './submittal-package';
import { Submittal } from './submittal';

export class SubmittalPackageActivity extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'SubmittalPackageActivity';
  }

  /* tslint:disable */
  type: string;
  description: string;
  created_at: string | Date | moment.Moment;
  attachments: Attachment[];

  submittal: Submittal;
  createdBy: Account;
  guest_account: GuestAccount;
  submittal_package: SubmittalPackage;

  // acl assist variables
  submittal_package_id: string;
  /* tslint:enable */

  init() {
    this.attachments = [];
  }

  get relations() {
    return {
      submittal: Submittal,
      submittal_package: SubmittalPackage,
      createdBy: Account,
      guest_account: GuestAccount,
      attachments: Attachment,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // add few attributes to assist acl module
    this.submittal_package_id = this.submittal_package?.id || this.submittal_package_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      type: this.type,
      attachments: (this.attachments || []).map(o => o.id),
    };
    return payload;
  }

  get createdByName() {
    return this.createdBy ? this.createdBy.name : this.guest_account ? this.guest_account.name : '';
  }
}

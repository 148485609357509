<div
  class="popup"
  [style.left.px]="x"
  [style.top.px]="y"
  #popupContentElement
>
  <ng-container
    *ngFor="let eachObj of popupContent; let isLast = last"
  >
    <app-map-popup-type-one
      *ngIf="allowTypeOne(eachObj.type)"
      [data]="eachObj"
    />
    <app-map-popup-type-two
      *ngIf="allowTypeTwo(eachObj.type)"
      [data]="eachObj"
    />
    <div
      class="action"
      (click)="onMoreClick(eachObj)"
    >
      {{eachObj.more_link_text}}
    </div>
    <hr *ngIf="!isLast"/>
  </ng-container>

</div>

<mat-toolbar>
  <mat-toolbar-row>
    <a href="/" class="logo"></a>
    <span class="flex-space"></span>

    <nav mat-tab-nav-bar color="primary" id="main-nav">
      <div
        class="header-item"
        (click)="navigate('/projects')"
        [class.is-active]="currentPath === '/projects'"
      >
        Projects
      </div>
      <div
        class="header-item"
        (click)="navigate('/reports')"
        [class.is-active]="currentPath === '/reports'"
      >
        Reports
      </div>
      <div
        class="header-item"
        (click)="navigate('/organization')"
        [class.is-active]="currentPath === '/organization'"
      >
        Organization
      </div>
      <div
        *ngIf="('update' | able: 'AppData')"
        class="header-item"
        (click)="navigate('/app-settings')"
        [class.is-active]="currentPath === '/app-settings'"
      >
        App Settings
      </div>
    </nav>

    <ng-container *ngIf="authService.isLoggedIn()">
      <button mat-icon-button [matMenuTriggerFor]="accountMenu">
        <ng-container *ngIf="!account.picture?.id">
          <mat-icon color="primary">account_circle</mat-icon>
        </ng-container>
        <ng-container *ngIf="account.picture?.id">
          <img [src]="account.picture.getThumbnail()" title="{{ account.name }} photo" class="icon-image" />
        </ng-container>
      </button>

      <mat-menu #accountMenu class="account-menu">
        <div class="account-info" (click)="$event.stopPropagation()">
          <div class="preview-list" *ngIf="account.picture?.id">
            <div>
              <img [src]="account.picture.getThumbnail()" title="{{ account.name }} photo" />
            </div>
          </div>

          <div class="mat-subheading-2">
            {{ account.name }}
            <a href="/account/profile" class="no-style link">
              <mat-icon>settings</mat-icon>
            </a>
          </div>
          <div class="mat-caption">{{ account.email }}</div>
          <div class="mat-caption">{{ account.phone }}</div>
        </div>
        <button mat-menu-item (click)="logout()">
          <mat-icon>login</mat-icon><span>Sign Out</span>
        </button>
      </mat-menu>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

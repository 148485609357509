<div class="wrapper">
  <div class="location-section-label">LOCATION:</div>
  <div class="location-row">
    <mat-icon
      class="delete-location"
      [class.editable]="isEditable"
      svgIcon="trash"
      (click)="isEditable && deleteLocation()"
    />
    <app-location-picker
      class="location-tile"
      [isDialog]="false"
      [latitude]="latitude"
      [longitude]="longitude"
      height="100px"
      [readOnly]="true" />
  </div>
</div>

import { BaseModel } from './base-model';

export class Address extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Address'; }

  line1: string;
  line2: string;
  city: string;
  region: string;
  country: string;
  zip: string;

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    delete this.created_at;
    delete this.updated_at;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    if (!this.line1 && !this.line2 && !this.city && !this.region && !this.country && !this.zip) {
      return null;
    }
    const payload: any = {
      id: this.id || undefined,
      line1: this.line1,
      line2: this.line2,
      city: this.city,
      region: this.region,
      country: this.country,
      zip: this.zip,
    };
    return payload;
  }
}

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div class="dialog-header">
  <div class="mat-headline">{{site.name}} Members</div>
</div>

<div class="dialog-content">
  <ng-container *ngIf="initialized">
    <div class="mat-body-strong" *ngIf="!projectAccounts.length">
      Found no members to show
    </div>
    <div *ngFor="let pa of projectAccounts">
      <mat-checkbox class="check-list"
        [disabled]="!('manage-account' | able: site)"
        [checked]="pa.checked"
        (change)="onChange(pa, $event.checked)">
          <div class="mat-subheading-2">{{pa.name}}</div>
          <div class="mat-caption">{{pa.projectRole?.role_name}}</div>
      </mat-checkbox>
    </div>
  </ng-container>
</div>

<div class="dialog-footer form-actions">
  <button mat-flat-button color="accent" (click)="onClose(true)">Close</button>
</div>

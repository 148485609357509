import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {AppService, AuthService} from '../../services';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {ActivationEnd, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Account} from '../../models';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../confirm-dialog/confirm-dialog.component';
import * as moment from 'moment/moment';
import {ProjectsService} from '../../../modules/projects/core/projects.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {filter, map} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  public activePath: string;
  public account: Account;

  currentPath = '';

  constructor(
    private snackBar: MatSnackBar,
    private router: Router,
    private appSrv: AppService,
    public authService: AuthService,
    private projectsService: ProjectsService,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.activePath = this.router.url;
    this.account = this.authService.getCurrentUser();

    this.router.events.subscribe((event) => {
      if (event?.['routerEvent']?.url) {
        const url = (event?.['routerEvent']?.url.split('?')[0]).split('/')[1];
        this.currentPath = `/${url}`;
        this.cdRef.detectChanges();
      }
    });
  }

  logout() {
    this.authService.logout().subscribe({
      next: (resp: any) => {
        this.snackBar.open('Logged out successfully.', '', {duration: 3000});
        this.router.navigate(['/login']);
      },
      error: (resp: any) => {
        if (resp.error.error) {
          this.snackBar.open(resp.error.error, '', {duration: 5000});
        }
      }
    });
  }

  navigate(path) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`
        )
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate([path])
            .then(() => {
              this.projectsService.isAnyFormDirty.next(false);
            });
        }
      });
    } else {
      this.router.navigate([path]).then(() => {
        this.projectsService.closeRightPanel.next();
        this.projectsService.isAnyFormDirty.next(false);
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

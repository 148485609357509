import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, Material, Project } from 'src/app/shared/models';
import { MaterialService } from 'src/app/shared/services';
import { BidService } from 'src/app/shared/services/bid.service';

@Component({
  selector: 'app-biditem-materials',
  templateUrl: './biditem-materials.component.html',
  styleUrls: ['./biditem-materials.component.scss']
})
export class BiditemMaterialsComponent implements OnInit {
  public loading: number = 0;
  public initialized: boolean = false;

  @Input() public bidItem: BidItem;
  public materials: Material[] = [];
  public project: Project;

  constructor(
    private snackBar: MatSnackBar,
    private materialSrv: MaterialService,
    private bidSrv: BidService,
  ) { }

  ngOnInit(): void {
    this.fetchBidItem(this.bidItem.id);
    this.project = new Project({ id: this.bidItem?.project_id });
  }

  fetchBidItem(id: string): void {
    const qp = {
      include: ['materials'],
    }
    this.bidSrv.getBidItem(id, qp)
      .then((bidItem: BidItem) => {
        this.bidItem = bidItem;
        return this.fetchMaterials();
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  fetchMaterials(): void {
    this.materialSrv.getRecords(
      this.project,
      { pageIndex: 0, pageSize: 100000 },
      { include: [''] },
    ).then((resp: any) => {
      this.materials = resp.result;
      // mark materials as checked/unchecked
      this.materials.map((o: any) => o.checked = !!this.bidItem.materials.find((t) => t.id === o.id));
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    });
  }

  /**
   * OnChange event of checkbox
   * @param material Material
   * @param checked boolean
   */
  onChange(material: Material, checked) {
    const req = checked
      ? this.materialSrv.addBidItem(material, this.bidItem)
      : this.materialSrv.removeBidItem(material, this.bidItem);

    req.then((msg: string) => {
        this.snackBar.open(msg, '', { duration: 5000 });
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      });
  }

}

<button
  (click)="close($event)"
  aria-label="Close"
  class="close"
  mat-dialog-close
  mat-icon-button
>
  <mat-icon title="Close">close</mat-icon>
</button>
<swiper
  [initialSlide]="initialSlide"
  [keyboard]="true"
  [lazy]="true"
  [navigation]="true"
  [pagination]="{ clickable: true }"
  [preloadImages]="false"
  [slidesPerView]="1"
>
  <ng-template *ngFor="let att of attachments" swiperSlide>
    <div *ngIf="att?.['fileType'] === 'picture'" class="slide-header">
      <button
        (click)="download(att)"
        aria-label="Download {{ att.original_name }}"
        mat-icon-button
        title="Download {{ att?.original_name }} ({{
          att?.size | humanReadableSize
        }})"
      >
        <mat-icon>download</mat-icon>
      </button>
    </div>

    <div class="slide-content">
      <ng-container *ngIf="att?.['fileType'] === 'picture';else video">
        <img
          [attr.data-src]="att.preview || att.preview_url"
          alt="Preview of {{ att.original_name }}"
          class="swiper-lazy slide-image"
        />
      </ng-container>
      <ng-template #video>
        <ng-container *ngIf="att.thumb_url; else noVideo">
          <mux-player
            [attr.controls]="true"
            [attr.metadata-duration]="att?.['duration']"
            [attr.playback-id]="att?.['playback_id']"
            [attr.poster]="att.thumb_url"
            class="swiper-lazy swiper-no-swiping"
            stream-type="on-demand"
            style="width: auto; height: 90%;"
          >
          </mux-player>
        </ng-container>
        <ng-template #noVideo>
          <div class="no-video swiper-lazy swiper-no-swiping flex flex-col justify-center items-center gap-[20px]">
            <img alt="no-video" class="no-video-image" src="assets/images/png/video-processing.png">
            <div class="text text-type-3">
              Your video is still processing
            </div>

          </div>
        </ng-template>


      </ng-template>

      <div class="swiper-lazy-preloader"></div>
    </div>
  </ng-template>
</swiper>

<div class="wrapper flex gap-[20px] w-full">
  <app-primary-button
    (handleClick)="save()"
    [isDisabled]="!isFormDirty"
    class="save-button"
  >Save
  </app-primary-button>
  <div
    [class.disabled]="disableAddMenu"
    [matMenuTriggerFor]="add"
    class="action-button add-button"
  >
    <mat-icon class="add" svgIcon="plus" />
  </div>
  <div
    [class.disabled]="disableOptionMenu" [matMenuTriggerFor]="menu"
    class="action-button menu-button"
  >
    <mat-icon class="menu">more_horiz</mat-icon>
  </div>

  <mat-menu #add="matMenu">
    <button
      (click)="option.handler()"
      *ngFor="let option of addMenuOptions"
      mat-menu-item
    >
      <div class="add-menu-item flex justify-start items-center">
        <div class="flex justify-center items-center h-[48px]">
          <mat-icon [svgIcon]="option.icon" class="menu-icon" />
        </div>
        <div class="menu-label">{{ option.label }}</div>
      </div>
    </button>
  </mat-menu>
  <mat-menu #menu="matMenu">
    <button
      (click)="addNew()"
      *ngIf="!hideAddOption"
      mat-menu-item
    >
      <mat-icon svgIcon="plus" />
      Add
    </button>
    <button
      (click)="delete()"
      *ngIf="!hideDeleteOption"
      mat-menu-item
    >
      <mat-icon svgIcon="trash" />
      Delete
    </button>
    <button
      (click)="setStatus('close')"
      *ngIf="issue?.status === 'open'"
      mat-menu-item>
      <mat-icon svgIcon="pencil-outline" />
      Close
    </button>
    <button
      (click)="setStatus('reopen')"
      *ngIf="issue?.status === 'closed'"
      mat-menu-item>
      <mat-icon svgIcon="pencil-outline" />
      Reopen
    </button>
  </mat-menu>
</div>
<input
  #fileInput
  (change)="onFileSelected($event)"
  accept=".png, .jpg, .jpeg, .mp4"
  class="hidden"
  multiple="multiple"
  type="file"
/>

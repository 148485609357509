import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(private router: Router) {
  }

  scrollToElementById(id: string) {
    const element = this.__getElementById(id);
    this.scrollToElement(element);
  }

  private __getElementById(id: string): HTMLElement {
    return document.getElementById(id);
  }

  scrollToElement(element: HTMLElement) {
    element?.scrollIntoView({ behavior: 'smooth' });
  }
}

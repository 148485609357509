import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Lookup } from '../models';

@Injectable({ providedIn: 'root' })
export class LookupService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Lookup
   * @param type string attachment-tag
   * @param id string Project.id
   * @param qp query params
   * @returns {Promise<Lookup[]>}
   */
  getProjectRecords(type: string, id: string, qp: any = {}) {
    qp.start = 0;
    qp.total = 10000;
    return this.appHttpService
      .getService(`/v2/project/${id}/lookup/${type}/`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new Lookup(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Create Lookup
   * @param type type
   * @param id Project.id
   * @param payload Field
   * @param qp any
   * @returns {Promise<Field>}
   */
  create(id: string, type: string, payload: any, qp: any = {}): Promise<Lookup> {
    return this.appHttpService
      .postService(`/v2/project/${id}/lookup/${type}/`, payload, qp)
      .toPromise()
      .then((resp: any) => new Lookup(resp?.data));
  }
}

<div
  [class.map-full-screen]="isFullScreen"
  class="wrapper"
>
  <div
    class="left"
  >
    <app-map-wrapper class="map">
      <router-outlet name="map"></router-outlet>
    </app-map-wrapper>
    <div
      #triggerMenu="cdkOverlayOrigin"
      (click)="handleAdd()"
      *ngIf="!isFullScreen"
      cdkOverlayOrigin
      class="add-button"
    >
      <mat-icon svgIcon="add" />
      <ng-template
        (backdropClick)="isDailyReportMenuOpen = false"
        [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayOpen]="isDailyReportMenuOpen"
        [cdkConnectedOverlayOrigin]="triggerMenu"
        cdkConnectedOverlay
      >
        <div
          *ngIf="pageSlug === 'daily-report'"
          class="daily-report-menu"
        >
          <div
            (click)="eachConfig.handler()"
            *ngFor="let eachConfig of dailyReportMenuConfig; let first = first; let last = last"
            [class.first]="first"
            [class.last]="last"
            class="each-menu-option flex justify-start items-center gap-[8px] h-[48px] cursor-pointer"
          >
            <mat-icon [svgIcon]="eachConfig.icon" />
            <div class="menu-label text-type-10">{{ eachConfig.label }}</div>
          </div>
        </div>
      </ng-template>
    </div>
    <div
      *ngIf="!isFullScreen"
      [class.sidenav-open]="showSidenav"
      class="content"
    >
      <router-outlet name="content" />
    </div>
  </div>
  <div
    *ngIf="showSidenav"
    [@rightAnimation]="showSidenav ? 'sidenavOpen' : 'sidenavClosed'"
    class="right"
  >
    <div class="close-block">
      <mat-icon
        (click)="close()"
        class="close-icon"
        svgIcon="close"
      />
    </div>
    <ng-container #container></ng-container>
  </div>
</div>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { Drawing, Project } from '../models';

@Injectable({ providedIn: 'root' })
export class DrawingService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns list of Records
   * @param pagination pagination
   * @param qp query params
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/drawing/${project.id}`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Drawing(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Deletes Record
   * @param drawing Drawing
   */
  delete(drawing: Drawing) {
    return this.appHttpService.deleteService(`/v1/drawing/${drawing.id}`);
  }

  /**
   * Update drawing
   * @param drawing Drawing
   * @param payload request body
   * @param qp query-params
   * @returns {Promise<Drawing>}
   */
  update(drawing: Drawing, payload: any = null, qp: any = {}) {
    payload = payload || drawing.toPayload();
    return this.appHttpService.putService(`/v1/drawing/${drawing.id}`, payload, qp).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Drawing(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }
}

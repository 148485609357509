import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Drawing, Project } from 'src/app/shared/models';
import { DrawingService } from 'src/app/shared/services';
import { environment as ENV } from 'src/environments/environment';
import { DrawingCreateComponent } from '../components/drawings/drawing-create/drawing-create.component';

@Component({
  selector: 'app-project-drawings',
  templateUrl: './project-drawings.component.html',
  styleUrls: ['./project-drawings.component.scss']
})
export class ProjectDrawingsComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input()
  public project: Project;

  @Input()
  public pageSize = 20;

  public drawings: Drawing[] = [];
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };

  public zipUploadEndpoint: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private drawingService: DrawingService
  ) { }

  ngOnInit(): void {
    this.pagination.pageSize = this.pageSize;
    this.fetchRecords();
    this.zipUploadEndpoint = `${ENV.API_ENDPOINT}/v1/drawing/${this.project.id}/zip`;
  }

  /**
   * Fetch Records
   */
  fetchRecords() {
    this.loading++;
    this.drawingService.getRecords(this.project, this.pagination)
      .subscribe({
        next: (resp: any) => {
          this.drawings = resp.result.map(d => {
            d.project_id = this.project.id; // for acl
            return d;
          });
          this.pagination.length = resp.meta.totalRecords || 0;
          this.initialized = true;
        },
        error: (err: any) => {
          if (err.status === 403) {
            this.router.navigate(['/page-404'], { queryParams: { url: this.router.url } });
            return;
          }

          this.snackBar.open(err.error?.error || err.message, '', { duration: 5000 });
        }
      });
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords();
  }

  /**
   * Deletes Drawing
   * @param drawing Drawing
   */
  delete(drawing: Drawing) {

    const message = `Deleting <b>${drawing.original_name}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.drawingService.delete(drawing)
        .subscribe({
          next: (resp: any) => {
            this.drawings.splice(this.drawings.findIndex(o => o.id === drawing.id), 1);
            this.pagination.length--;
            this.snackBar.open(resp.message, '', { duration: 3000 });
          },
          error: (err: any) => {
            this.snackBar.open(err.error.error, '', { duration: 5000 });
          }
        });
    });
  }

  /**
   * Callback on upload success
   * @param attachment Attachment
   */
  onUploadComplete(resp: any) {
    if (resp.status == 200) {
      this.snackBar.open(resp.message, '', { duration: 5000 });
      this.pagination.pageIndex = 0;
      this.fetchRecords();
    }
  }

  /**
   * Callback on upload failure
   * @param resp Response
   */
  onUploadError(resp: HttpErrorResponse) {

    if (resp.error.status == 422) {
      this.snackBar.open(resp.error.error.inputFile.msg, '', { duration: 5000 });
      return;
    }
    if (resp.error.error) {
      // Todo Show some permanent visible error after design
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
      return;
    }

    this.snackBar.open(resp.message, '', { duration: 5000 });
  }

  /**
   * Open edit form
   * @param m Drawing
   */
  edit(m: Drawing) {
    const dialogRef = this.dialog.open(DrawingCreateComponent, {
      disableClose: true,
      data: m,
      width: '50vw',
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof Drawing) {
        const i = this.drawings.findIndex(o => o.id === resp.id);
        this.drawings[i] = new Drawing(Object.assign(this.drawings[i], resp));
      }
    });
  }

}

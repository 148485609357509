import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable()
export class ContentTypeInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const newReq = req.clone({ setHeaders: {
      Accept: 'application/json',
    }});

    if (!newReq.headers.has('Content-Type')) {
      newReq.headers.set('Content-Type', 'application/json');
    }

    return next.handle(newReq);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Weather } from 'src/app/shared/models/weather';
import { AppService } from 'src/app/shared/services';

@Component({
  selector: 'app-report-weather-card',
  templateUrl: './report-weather-card.component.html',
  styleUrls: ['./report-weather-card.component.scss']
})
export class ReportWeatherCardComponent implements OnInit {

  @Input() public weather: Weather;
  public initialized: boolean = false;
  public isEmpty: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.initialized = this.weather ? true : false;
    this.isEmpty = !(this.weather.avgtemp || this.weather.maxtemp || this.weather.mintemp);
  }

}

<div class="wrapper">
  <div class="header">
    <div class="heading">
      {{ heading }}
    </div>
    <div
      *ngIf="subheading"
      class="subheading"
    >
      {{ subheading }}
    </div>

  </div>

  <div
    class="section content"
    [class]="testType"
  >
    <app-onsite-tests
      *ngIf="testType === 'internal'"
      class="section"
      [data]="data"
    />
    <app-offsite-tests
      *ngIf="testType === 'external'"
      class="section"
      [data]="data"
    />
  </div>
</div>

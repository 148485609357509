import { Address, BaseModel, Account, Organization } from 'src/app/shared/models';

export class SubContractor extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() { return 'SubContractor'; }

  /* tslint:disable */
  name: string;
  poc: string;
  poc_phone: string;
  status: string;
  address: Address;
  createdBy: Account;
  updatedBy: Account;
  organization: Organization;

  // acl assist variables
  organization_id: string;
  checked: boolean;
  /* tslint:enable */

  init() {
    this.checked = false;
  }

  get relations() {
    return {
      address: Address,
      createdBy: Account,
      updatedBy: Account,
      organization: Organization,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // add few attributes to assist acl module
    this.organization_id = this.organization?.id || this.organization_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {

    if (this.address && !(this.address instanceof Address)) {
      this.address = new Address(this.address);
    }

    const payload: any = {
      id: this.id || undefined,
      name: this.name,
      poc: this.poc,
      poc_phone: this.poc_phone,
      address: this.address?.toPayload(),
      organization_id: this.organization?.id || this.organization_id,
    };
    return payload;
  }
}



import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { InternalTest, Project } from 'src/app/shared/models';
import { AppService, InternalTestService } from 'src/app/shared/services';
import { InternalTestCreateComponent } from '../internal-test-create/internal-test-create.component';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';

@Component({
  selector: 'app-internal-tests',
  templateUrl: './internal-tests.component.html',
  styleUrls: ['./internal-tests.component.scss']
})
export class InternalTestsComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input()
  public project: Project;

  @Input()
  public pageSize = 20;

  public internalTests: InternalTest[] = [];
  public newInternalTest: InternalTest; // for acl purpose
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private appSrv: AppService,
    private internalTestSrv: InternalTestService
  ) { }

  ngOnInit(): void {
    this.pagination.pageSize = this.pageSize;
    this.newInternalTest = new InternalTest({ project: this.project });
    this.fetchRecords();
  }

  /**
   * Fetches Material[] from API
   */
  fetchRecords(): void {
    this.loading++;

    this.internalTestSrv
      .getRecords(this.project, this.pagination, { include: ['bid_items', 'fields'] })
      .then((resp: any) => {
        const { result, meta } = resp;

        // for acl
        this.internalTests = result.map((o) => {
          o.project = this.project;
          return new InternalTest(o);
        });

        this.pagination.length = meta?.totalRecords ?? 0;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

  /**
   * Open sidebar for edit/create
   * @param internalTest InternalTest
   */
  openEdit(internalTest: InternalTest) {
    const sidebarRef = this.appSrv.openSidebar(InternalTestCreateComponent, {
      data: internalTest,
      project: this.project,
      editable: true,
    });

    sidebarRef.afterClosed().subscribe((resp) => {
      if (resp instanceof InternalTest) {
        const i = this.internalTests.findIndex((o) => o.id === resp.id);
        if (i >= 0) {
          this.internalTests[i] = Object.assign(this.internalTests[i], resp);
          this.snackBar.open(`"${resp.name}" updated successfully`, '', {
            duration: 5000,
          });
        } else {
          this.internalTests.push(resp);
          this.pagination.length++;
          this.snackBar.open(`"${resp.name}" added successfully`, '', {
            duration: 5000,
          });
        }
      }
    });
  }

  /**
   * Deletes InternalTest
   * @param internalTest InternalTest
   */
  delete(internalTest: InternalTest) {
    const message = `Deleting ${internalTest.name} cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.internalTestSrv.delete(internalTest)
        .then((resp: any) => {
          const i = this.internalTests.findIndex((o) => internalTest.id);
          this.internalTests.splice(i, 1);
          this.snackBar.open(`${resp.message}`, '', {
            duration: 3000,
          });
        })
        .catch((resp: HttpErrorResponse) => {
          if (resp.status === 403) {
            this.snackBar.open(
              resp.error.error || 'Oops! something went wrong.',
              '',
              { duration: 5000 }
            );
            return;
          }

          this.snackBar.open(
            'Oops! something went wrong, please check the logs',
            '',
            { duration: 5000 }
          );
        });
    });
  }

}

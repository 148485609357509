import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-map-popup-type-one',
  templateUrl: './map-popup-type-one.component.html',
  styleUrls: ['./map-popup-type-one.component.scss', '../popup.styles.scss'],
})
export class MapPopupTypeOneComponent {
  @Input() data: any = {};
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LocationPickerComponent } from 'src/app/shared/components/location-picker/location-picker.component';
import { Annotation, Attachment, BidItem, DailyReport, IssueType, Project, Site } from 'src/app/shared/models';
import { Issue } from 'src/app/shared/models/issue';
import { AppErrorStateMatcher, AppService, AuthService, IssueService, ProjectService } from 'src/app/shared/services';
import { AnnotComponent } from '../../annot/annot.component';
import { BidItemSelectorComponent } from '../../components/bid-item-selector/bid-item-selector.component';

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss']
})
export class IssueComponent implements OnInit {

  @Input() public data: any = {};
  @Input() public site: Site;
  @Input() public project: Project;
  @Input() public editable: boolean = false;

  private isDialog: boolean = false;
  public dialogOptions: any = {};
  public dialogResult: any = null;

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = false;

  public issue: Issue;
  public annotationPreviewUrl: string;
  public scopes: any[] = [];
  public issueTypes: IssueType[] = [];

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    title: ['', [Validators.required]],
    description: [''],
    type: ['', [Validators.required]],
    scope_id: ['', [Validators.required]],
  });

  constructor(
    public appSrv: AppService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private issueService: IssueService,
    private projectSrv: ProjectService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<IssueComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const { data, site, project, editable, options } = this.inputData;
    if (data) {
      this.isDialog = true;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = Object.assign(data, { project });
      this.site = site;
      this.project = project;
      this.editable = editable;
    }

    this.fetchIssueTypes(this.project);

    this.issue = new Issue({...this.data});
    this.toReactiveForm(this.issue, this.inputForm);

    this.issue.pictures = this.issue.pictures || [];
    this.issue.attachments = this.issue.attachments || [];

    if (!this.issue.id) {
      this.editing = !!this.editable; // new record
      this.issue.createdBy = this.authService.getCurrentUser();
    }

    this.scopes = [
      { id: `project/${this.project?.id}`, name: `Project ${this.project?.name}` },
    ];
    if (this.site?.id) {
      this.scopes.push({ id: `site/${this.site?.id}`, name: `Site ${this.site?.name}`, child: true });
    }
  }

  /**
   * Closes component or Dialog with response|event
   * @param resp data to be sent to parent
   */
  close(resp: any = null): void {
    if (this.isDialog) {
      this.dialogRef.close(resp || this.dialogResult);
    }
  }

  /**
   * Opens BidItem Selector
   */
  openBidItemSelector(): void {
    if (!this.editing) {
      return;
    }

    const dialogRef = this.dialog.open(BidItemSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: this.project,
        selectedBidItem: this.issue?.bid_item,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof BidItem) {
        this.issue.bid_item = resp;
      }
    });
  }

  /**
   * Assign base model to FormGroup
   * @param issue Issue
   * @param form FormGroup
   */
  toReactiveForm(issue: Issue, form: UntypedFormGroup) {
    form.controls.title.setValue(issue.title);
    form.controls.description.setValue(issue.description);
    form.controls.type.setValue(issue.type);
    form.controls.scope_id.setValue(`${issue.entity_type}/${issue.entity?.id}`);
  }

  /**
   * Update ReportBidItem
   */
  saveItem() {

    if (!this.inputForm.valid) {
      return;
    }

    const [ entity_type, entity_id ] = this.inputForm.value.scope_id.split('/');
    const payload = (Object.assign(this.issue, this.inputForm.value)).toPayload();
    payload.entity_type = entity_type;
    payload.entity_id = entity_id;

    this.loading++;
    const qp = { };
    this.issueService.save(this.issue, payload, qp)
      .then((issue: Issue) => {
        this.issue = issue;
        this.toReactiveForm(issue, this.inputForm);
        this.snackBar.open('Saved issue', '', { duration: 5000 });

        this.dialogResult = this.issue;
        this.editing = false;
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.projectSrv.fetchIssues.next(null);
      });
  }

  closeIssue(issue: Issue): void {
    this.loading++;
    this.issueService.close(issue)
      .then((issue: Issue) => {
        this.issue = issue;
        this.toReactiveForm(issue, this.inputForm);
        this.snackBar.open('Closed issue', '', { duration: 5000 });

        this.dialogResult = this.issue;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.projectSrv.fetchIssues.next(null);
      });
  }

  reopenIssue(issue: Issue): void {
    this.loading++;
    this.issueService.reopen(issue)
      .then((issue: Issue) => {
        this.issue = issue;
        this.toReactiveForm(issue, this.inputForm);
        this.snackBar.open('Reopened issue', '', { duration: 5000 });

        this.dialogResult = this.issue;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.projectSrv.fetchIssues.next(null);
      });
  }

  /**
   * Attach uploaded attachment to pictures
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.issue.pictures.push(a));
  }

  /**
   * Remove pic from list
   * @param pic Attachment
   */
  removePicture(pic) {
    this.issue.pictures.splice(this.issue.pictures.findIndex(p => p.id === pic.id), 1);
  }

  /**
   * Attach uploaded attachment to attachments
   * @param attachment Attachment
   */
  onAttachmentUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.issue.attachments.push(a));
  }

  /**
   * Remove attachment from list
   * @param attachment Attachment
   */
  removeAttachment(attachment) {
    this.issue.attachments.splice(this.issue.attachments.findIndex(t => t.id === attachment.id), 1);
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.editing = false;
    this.issue = new Issue(...this.data);
  }

  /**
   * Remove annot from list
   * @param annot Annotation
   */
  removeAnnot(annot) {
    this.issue.annotation = null;
  }

  /**
   * Opens annotations in Modal
   * @param annotation Annotation
   */
  openAnnot(annotation: Annotation = null) {
    if (!this.editing) {
      return;
    }
    if (!annotation) {
      annotation = new Annotation();
    }

    const dialogRef = this.dialog.open(AnnotComponent, {
      disableClose: true,
      data: {
        annotation,
        project: this.project,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      // if annotation has been changed, update it in list
      if (resp instanceof Annotation) {
        this.issue.annotation = resp;
      }
    });
  }

  /**
   * Open location to edit
   * @param issue Issue
   */
  editLocation(issue: Issue): void {
    if (!this.editing) { return; }
    issue = issue || new Issue({});

    const dialogRef = this.dialog.open(LocationPickerComponent, {
      disableClose: true,
      data: {
        latitude: issue.latitude,
        longitude: issue.longitude,
        readOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && 'latitude' in resp && 'longitude' in resp) {
        this.issue.latitude = resp.latitude;
        this.issue.longitude = resp.longitude;
      }
    });
  }

  /**
   * Fetches IssueType[]
   * @param project Project
   */
  fetchIssueTypes(project: Project): void {
    this.loading++;
    this.projectSrv.getIssueTypes(project)
      .then((issueTypes: IssueType[]) => {
        this.issueTypes = issueTypes;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  issueTypeLabel(type) {
    return (this.issueTypes || []).find(o => o?.type === type)?.label || type;
  }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AppHttpService } from './app-http.service';
import { Weather } from '../models/weather';

@Injectable({ providedIn: 'root' })
export class WeatherService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  getSiteWeather(siteId: string, date: string = null) {
    const qp = {
      date: date || moment().format('YYYY-MM-DD'),
      siteId,
    }
    return this.appHttpService.getService(`/v1/weather`, qp).toPromise()
      .then((resp: any) => {
        return new Weather(resp.data?.weather);
      });
  }
}

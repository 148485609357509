import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AddContractorStatusRequest, FetchProjectReportRequest } from '../../../../../../../../core/projects.actions';
import * as moment from 'moment/moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { FormControl, FormGroup } from '@angular/forms';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import {
  ConsolidatedReportBidItemDetailsComponent,
} from '../../detail-components/consolidated-report-bid-item-details/consolidated-report-bid-item-details.component';
import { AddReasonComponent } from '../../../../../dialogs/add-reason/add-reason.component';

@Component({
  selector: 'app-bid-items-expandable-row-table',
  templateUrl: './bid-items-expandable-row-table.component.html',
  styleUrls: ['./bid-items-expandable-row-table.component.scss', '../../../../../../../../../../../styles.projects.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class BidItemsExpandableRowTableComponent implements OnInit, OnDestroy {
  @Input() data: any[];
  dataSource = [];
  columnsToDisplay = ['action', 'item', 'site', 'quantity', 'amount', 'createdBy', 'status'];
  expandedElement: any | null;
  selectedValue = {};
  isOpen = false;
  statusList = [
    { key: 1, value: 'Agree' },
    { key: 0, value: 'Disagree' },
  ];
  parsedData: any;
  projectId = '';
  form = new FormGroup({
    date: new FormControl(),
  });
  selectedChildRowId: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private router: Router,
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
  ) {

  }

  ngOnInit() {
    this.parsedData = JSON.parse(JSON.stringify(this.data));
    this.dataSource = this.parsedData;
    this.selectedValue = this.statusList[0];

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp['expandedRowId']) {
          this.expandedElement = this.dataSource?.find(item => item.id === qp['expandedRowId']);
        } else {
          this.expandedElement = null;
        }
        this.cdRef.detectChanges();
      });


    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.date && moment(qp.date).isValid() && this.form.controls.date.value !== moment(qp.date)) {
          this.form.controls.date.setValue(moment(qp.date, 'YYYY-MM-DD'));
        }
      });

    this.route.params
      .pipe(takeUntil(this.onDestroy))
      .subscribe(p => {
        this.projectId = p.projectId;
      });

    this.projectsService.addContractorStatusSuccess
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchProjectReport();
      });
  }

  onSelect(value: any, parentId, index, data) {
    this.parsedData = this.parsedData.map(item => {
      if (item.id === parentId) {
        item.children[index].status = value;
      }

      return item;
    });

    this.cdRef.detectChanges();

    if (value !== 1) {
      this.dialog.open(AddReasonComponent, {
        disableClose: false,
        data: { x: 3, selectedRowData: data },
        panelClass: 'floating-dialog-box',
      }).afterClosed().subscribe(() => {
        this.fetchProjectReport();
      });
    } else {
      const picturesIdArray = [];
      data?.contractor_pictures.map((picture) => {
        picturesIdArray.push(picture.id);
      });

      const payload = {
        comments: value === 1 ? '' : data?.contractor_comments,
        suggested_quantity: value === 1 ? null : data?.quantity,
        reportBidItemId: data?.id,
        dailyReportId: data?.dailyReportId,
        status: value === 1 ? 'agree' : 'disagree',
        contractor_pictures: value === 1 ? [] : picturesIdArray,
      };
      this.store.dispatch(AddContractorStatusRequest({ payload }));
    }
  }

  toggleRow(row: any) {
    const currentRowId = this.route.snapshot.queryParams['expandedRowId'];
    const newRowId = currentRowId === row?.id.toString() ? null : row?.id.toString();

    this.router.navigate([], {
      queryParams: { expandedRowId: newRowId },
      queryParamsHandling: 'merge',
    });

    this.cdRef.detectChanges();
  }

  onRowSelect(data) {
    this.selectedChildRowId = data?.id;
    this.projectsService.openRightPanel.next({
      component: ConsolidatedReportBidItemDetailsComponent,
      data,
    });
  }


  fetchProjectReport() {
    if (this.projectId && this.form.controls.date.value) {
      this.store.dispatch(FetchProjectReportRequest({
        payload: {
          qp: {
            date: moment(this.form.controls.date.value).format('YYYY-MM-DD'),
            total: 1,
            projectId: this.projectId,
            include: [
              {
                daily_reports: [
                  'createdBy',
                  'site',
                  {
                    bid_items: [
                      {
                        bid_item: ['fields'],
                      },
                      'field_values',
                      'contractor_pictures',
                      'activities',
                    ],
                  },
                  {
                    activities: [
                      'pictures',
                      'address',
                      {
                        annotation: ['drawing'],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        },
      }));
    }
  }

  ngOnDestroy() {
    this.cdRef.detach();
  }
}

import { BaseModel } from './base-model';
import { Account} from './account';
import { Project } from './project';
import { Station } from './station';
import { InternalTest } from './internal-test';
import { Alignment } from './alignment';
import { Field } from './field';
import { Heading } from './heading';

export class Site extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Site'; }

  /* tslint:disable */
  name: string;
  description: string;
  start_date: Date|string;
  end_date: Date|string;
  duration: number;
  duration_used: number;
  site_value: number;
  earned_value: number;
  latitude: any;
  longitude: any;
  createdBy: Account;
  updatedBy: Account;
  project: Project;
  project_id: string;
  alignments: Alignment[];
  internal_tests: InternalTest[];
  delivery_tracking_fields: Field[];
  headings: Heading[];
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      project: Project,
      alignments: Alignment,
      internal_tests: InternalTest,
      delivery_tracking_fields: Field,
      headings: Heading,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.start_date = this.start_date && typeof this.start_date === 'string' ? new Date(this.start_date) : null;
    this.end_date = this.end_date && typeof this.end_date === 'string' ? new Date(this.end_date) : null;
    this.latitude = parseFloat(this.latitude) || null;
    this.longitude = parseFloat(this.longitude) || null;

    // set defaults, in case of null
    this.duration_used = this.duration_used || 0;
    this.duration = this.duration || 0;
    this.earned_value = this.earned_value || 0;
    this.site_value = this.site_value || 0;

    this.project_id = this.project?.id || this.project_id;
  }

  get durationProgress() {
    const f = this.duration_used / this.duration;
    return 100 * (f > 1 ? 1 : f);
  }

  get valueProgress() {
    const f = this.earned_value / this.site_value;
    return 100 * (f > 1 ? 1 : f);
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Project, Report, Site } from 'src/app/shared/models';
import { AppErrorStateMatcher, ProjectService, ReportService } from 'src/app/shared/services';

@Component({
  selector: 'app-report-renderer',
  templateUrl: './report-renderer.component.html',
  styleUrls: ['./report-renderer.component.scss']
})
export class ReportRendererComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;

  public loading: number = 0;
  public initialized: boolean = false;
  public downloading: boolean = false;

  public project: Project;
  public sites: Site[] = [];
  public reports: Report[] = [];

  public reportHtml: string = '';
  public reportWidth: number = 792;
  public reportHeight: number = 1100;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    reportId: ['', Validators.required],
    siteId: ['', Validators.required],
    date: ['', Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private reportSrv: ReportService,
    private projectSrv: ProjectService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.has('id')) {
      this.getProject(this.route.snapshot.paramMap.get('id'));
    }
  }

  getProject(id: string) {
    this.loading++;
    const qp: any = {
      include: ['sites', 'reports']
    };
    this.projectSrv.getRecord(id, qp).toPromise()
      .then((project: Project) => {
        this.project = project;
        this.reports = project?.reports || [];
        this.sites = project?.sites || [];
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }



  updateReportPreview(html) {
    // Override pagesize from css @page tag.
    // const regex = /@page\s*{\s*size:\s*(\d+)px\s*(\d+)px;/gi;
    // const found = regex.exec(html);
    // if (found.length) {
    //   this.reportWidth = parseInt(found[1]);
    //   this.reportHeight = parseInt(found[2]);
    // }

    let doc =
      this.iframe.nativeElement.contentDocument ||
      this.iframe.nativeElement.contentWindow;
    doc.open();
    doc.write(html);
    doc.close();
  }

  onSubmit(form) {
    if (!form.valid) {
      return;
    }

    const payload = form.value;
    payload.projectId = this.project?.id;
    payload.date = moment(payload.date).format('YYYY-MM-DD')

    this.loading++;
    this.reportHtml = '';

    this.reportSrv.renderReport(payload)
      .then((rs: any) => {
        this.reportHtml = rs?.html;
        this.updateReportPreview(rs?.html);

      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  downloadReport() {
    const { reportId, siteId, date } = this.inputForm.value;
    this.downloading = true;
    this.reportSrv
      .downloadReport(
        this.reports.find((o) => reportId === o.id),
        this.sites.find((o) => siteId === o.id),
        this.project,
        moment(date).format('YYYY-MM-DD')
      )
      .catch((err: HttpErrorResponse) => {
        this.snackBar.open(err.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.downloading = false;
      });
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-contract-progress-card',
  templateUrl: './contract-progress-card.component.html',
  styleUrls: ['./contract-progress-card.component.scss'],
})
export class ContractProgressCardComponent implements OnInit {
  @Input() heading: string;
  @Input() percent = 0.0;
  @Input() caption: string;

  public progressColor = '#8B94B3';
  border: any;

  constructor() {
  }

  ngOnInit(): void {
    if (this.percent > 100) {
      this.percent = 100;
    }
  }
}

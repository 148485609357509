import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { SharedService } from './shared.service';
import * as fromRoot from '../../state/app.state';
import { CommonService } from '../services/common.service';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import {
  FetchAppDataFailure,
  FetchAppDataRequest,
  FetchAppDataSuccess,
  GetVideoByIdFailure,
  GetVideoByIdRequest,
  GetVideoByIdSuccess,
  GetVideoIdFailure,
  GetVideoIdRequest,
  GetVideoIdSuccess,
  UploadFileFailure,
  UploadFileRequest,
  UploadFileSuccess,
  UploadVideoFileFailure,
  UploadVideoFileRequest,
  UploadVideoFileSuccess,
} from './shared.actions';

@Injectable()
export class SharedEffects {
  fetchAppData$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(FetchAppDataRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(() =>
        this.sharedService.fetchAppData().pipe(
          map(response => {
            const { data } = response;
            data.workConditions = data.workConditions.map(o => ({
              value: o.code,
              label: o.text,
            }));

            data.weatherConditions = data.weatherConditions.map(o => ({
              ...o,
              value: o.code,
              label: o.text,
            }));

            return FetchAppDataSuccess({ data });
          }),
          catchError(() => {
            return of(FetchAppDataFailure({}));
          }),
          tap((action: any) => {
            if (action.type === FetchAppDataSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === FetchAppDataFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  uploadFile$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UploadFileRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.sharedService.uploadFile(payload).pipe(
          map(response => {
            const { data } = response;
            data.forEach(eachItem => {
              if (eachItem.mime === 'image/jpeg' || eachItem.mime === 'image/png') {
                eachItem['fileType'] = 'picture';
              }
            });
            return UploadFileSuccess({ data });
          }),
          catchError(() => {
            return of(UploadFileFailure());
          }),
          tap((action: any) => {
            if (action.type === UploadFileSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.uploadedFilesInfo.next(action.data);
            } else if (action.type === UploadFileFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  getVideoId$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GetVideoIdRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.sharedService.getVideoId().pipe(
          map(response => {
            const { data } = response;
            const uploadVideoPayload = { ...data, formData: payload };
            return GetVideoIdSuccess({ uploadVideoPayload });
          }),
          catchError(() => {
            return of(GetVideoIdFailure());
          }),
          tap((action: any) => {
            if (action.type === GetVideoIdSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.store.dispatch(UploadVideoFileRequest({ payload: action.uploadVideoPayload }));
            } else if (action.type === GetVideoIdFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  uploadVideoFile$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(UploadVideoFileRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.sharedService.uploadVideoFile(payload).pipe(
          map(() => {

            return UploadVideoFileSuccess();
          }),
          catchError(() => {
            return of(UploadVideoFileFailure());
          }),
          tap((action: any) => {
            if (action.type === UploadVideoFileSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.store.dispatch(GetVideoByIdRequest({ payload }));
            } else if (action.type === UploadVideoFileFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  getVideoGetById$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(GetVideoByIdRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap(payload =>
        this.sharedService.getVideoById(payload).pipe(
          map(response => {
            const { data } = response;
            data['fileType'] = 'video';
            this.commonService.uploadedFilesInfo.next(data);
            return GetVideoByIdSuccess();
          }),
          catchError(() => {
            return of(GetVideoByIdFailure());
          }),
          tap((action: any) => {
            if (action.type === GetVideoByIdSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === GetVideoByIdFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          }),
        ),
      ),
    ),
  );

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private sharedService: SharedService,
    private actions: Actions,
  ) {
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Project } from 'src/app/shared/models';
import { ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  public project: Project;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.has('id')) {
      this.getRecord(this.route.snapshot.paramMap.get('id'));
    }
  }

  /**
   * Fetches organization and assigns to organization
   * @param id Organization.id
   */
  getRecord(id: string) {
    const qp = {
      include: [ 'createdBy', 'cover_photo', { owner_organization: [ 'type' ] } ],
    };
    this.projectService.getRecord(id, qp)
      .subscribe({
        next: (project: Project) => {
          this.project = project;
          this.initialized = true;
        },
        error: (err: any) => {
          if (err.status === 403) {
            this.router.navigate(['/page-404'], { queryParams: { url: this.router.url } });
            return;
          }

          console.log(err);
          // todo show some error message
        }
      });
  }
}

<div class="summary">
  <div
    *ngIf="data?.left"
    class="left"
  >
    <div class="label">{{ data?.left?.label }}</div>
    <div
      class="summary-bar"
    >
      <div
        class="bar"
        [ngStyle]="{
            'width': data?.left?.value
          }"
      ></div>
    </div>
    <div
      *ngIf="data?.left?.statistics"
      class="summary-text"
    >
      {{ data?.left?.statistics }}
    </div>
  </div>
  <div
    *ngIf="data?.right"
    class="right"
  >
    <div
      *ngIf="data?.right?.label"
      class="label"
    >
      {{ data?.right?.label }}
    </div>
    <div
      class="summary-bar"
    >
      <div
        class="bar"
        [ngStyle]="{
            'width': data?.right?.value + '%'
          }"
      ></div>
    </div>
    <div
      *ngIf="data?.right?.statistics"
      class="summary-text"
    >
      {{ data?.right?.statistics }}
    </div>
  </div>
</div>

<div class="container">
  <div *ngIf="apiLoaded | async">
    <google-map [options]="mapOptions" [zoom]="mapOptions.zoom" [center]="center" [width]="mapOptions.width"
      [height]="mapOptions.height" (mapClick)="onMapClick($event)">
      <map-marker [position]="marker?.position" [options]="marker?.options"></map-marker>
    </google-map>
  </div>
  <div class="row" [class.hide]="readOnly">
    <mat-form-field>
      <mat-label>Search location</mat-label>
      <input #searchInput matInput type="text" autocomplete="off" />
    </mat-form-field>
  </div>
  <div class="row" [class.hide]="readOnly" *ngIf="apiLoaded | async">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" (click)="close()" [disabled]="!modified">Done</button>
  </div>
</div>
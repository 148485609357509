import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-map-tooltip',
  templateUrl: './map-tooltip.component.html',
  styleUrls: ['./map-tooltip.component.scss']
})
export class MapTooltipComponent implements AfterViewInit{
  @Input() screenPoint: { x: number, y: number };
  @Input() tooltipContent: string;

  @ViewChild('tooltipContentElement') tooltipContentElement: ElementRef;
  x = null;
  y = null;

  constructor(
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    // Adjust tooltip location based on position of the map

    const map = document.getElementById('projectMap');
    const popup = this.tooltipContentElement.nativeElement.getBoundingClientRect();

    const { x, y } = this.screenPoint;
    const { height, width } = map.getBoundingClientRect();

    if (height - y > popup.height) {
      this.y = y;
    } else {
      this.y = (height - popup.height) - 20;
    }

    if (width - x > popup.width) {
      this.x = x + 20;
    } else {
      this.x = (x - popup.width) - 20;
    }

    this.cdRef.detectChanges();
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipment-block',
  templateUrl: './equipment-block.component.html',
  styleUrls: ['../../styles.css', './equipment-block.component.scss'],
})
export class EquipmentBlockComponent implements OnInit{
  @Input() data: any;

  make = '';
  model = '';
  number = '';

  ngOnInit() {
    const {equipment} = this.data;

    this.make = equipment.make;
    this.model = equipment.model;
    this.number = equipment.number;
  }
}

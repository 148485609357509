import { BaseModel, Account } from 'src/app/shared/models';

export class MaterialTestLab extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'MaterialTestLab'; }

  /* tslint:disable */
  name: string;
  email: string;
  phone: string;
  /* tslint:enable */

  project_id: string;

  get relations() {
    return {
      createdBy: Account,
    };
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      name: this.name,
      email: this.email,
      phone: this.phone,
      project_id: this.project_id || undefined,
    };
    return payload;
  }

}

<ng-container *ngIf="initialized">

  <div class="row">
    <div></div>
    <div class="icons">
      <button [disabled]="!('create' | able: newSite)" mat-flat-button
        color="accent" (click)="openEdit(newSite)">New Site</button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!sites.length">
    Found no records to show
  </div>

  <div class="list">
    <div class="list-card" *ngFor="let site of sites">

      <!-- actions -->
      <div class="hover-icons">
        <a mat-fab
          [disabled]="!('update' | able: site)"
          (click)="openStations(site)"
          color="none"
          aria-label="Stations"
          title="Stations">
          <mat-icon>tour</mat-icon>
        </a>

        <a mat-fab
          [disabled]="!('update' | able: site)"
          (click)="openEdit(site)"
          color="none"
          aria-label="Edit {{site.name}}"
          title="Edit {{site.name}}">
          <mat-icon>edit</mat-icon>
        </a>

        <button mat-fab
          [disabled]="!('delete' | able: site)"
          (click)="delete(site)"
          color="warn"
          aria-label="Delete {{site.name}}"
          title="Delete {{site.name}}">
          <mat-icon>delete</mat-icon>
        </button>

        <a mat-fab
          [disabled]="!('manage-account' | able: site)"
          (click)="openManageSiteMembers(site)"
          color="none"
          aria-label="Manage {{site.name}} members"
          title="Manage {{site.name}} members">
          <mat-icon>people</mat-icon>
        </a>
      </div>
      <!-- /actions -->

      <div class="body">
        <div class="mat-title">{{site.name}}</div>

        <div class="cards-holder">
          <div class="progress-card">
            <div class="mat-h4">Contract duration</div>
            <div class="progress-bar">
              <div class="progress" [style.width.%]="site.durationProgress"></div>
            </div>
            <div class="mat-caption">Used: {{site.duration_used}} of {{site.duration}} days</div>
          </div>

          <div class="progress-card">
            <div class="mat-h4">Contract value</div>
            <div class="progress-bar">
              <div class="progress" [style.width.%]="site.valueProgress"></div>
            </div>
            <div class="mat-caption">
              Earned: {{site.earned_value | humanReadable:1 }} /
              {{site.site_value | humanReadable:1 }}
            </div>
          </div>
        </div>

        <div class="mat-caption">{{site.description}}</div>
      </div>
    </div>
  </div>

</ng-container>

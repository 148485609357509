import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DailyReportService } from '../../../../daily-report.service';
import { takeUntil } from 'rxjs/operators';
import { weatherConditions } from '../../../../../../../../../../shared/core/shared.selectors';

@Component({
  selector: 'app-weather-details-form',
  templateUrl: './weather-details-form.component.html',
  styleUrls: ['./weather-details-form.component.scss'],
})
export class WeatherDetailsFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() data: any;
  skyConditionOptions = [];

  form = new FormGroup({
    avgtemp: new FormControl({ value: null, disabled: false }, {
      validators: [Validators.required],
    }),
    maxtemp: new FormControl({ value: null, disabled: false }, {
      validators: [Validators.required],
    }),
    mintemp: new FormControl({ value: null, disabled: false }, {
      validators: [Validators.required],
    }),
    skyCondition: new FormControl({ value: null, disabled: false }),
    wind: new FormControl({ value: null, disabled: false }),
    note: new FormControl({ value: null, disabled: false }),
  });
  formSubscription: Subscription = new Subscription();

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
  ) {

    this.store.select(weatherConditions)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.skyConditionOptions = data;
      });

  }

  ngOnChanges(changes: SimpleChanges) {
    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.projectsService.isAnyFormDirty.next(true);
      });
  }

  ngOnInit() {
    if (this.data) {
      this.dailyReportService.disableAddMenu.next(false);
      this.form.patchValue({
        avgtemp: this.data?.avgtemp,
        maxtemp: this.data?.maxtemp,
        mintemp: this.data?.mintemp,
        skyCondition: this.data?.condition.code,
        wind: this.data?.wind,
        note: this.data?.note,
      }, { emitEvent: false });
    }

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.save();
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.delete();
        }
      });
  }

  save() {
    let values = this.form.getRawValue();
    const { condition, ...restData } = this.data;
    const { skyCondition, ...rest } = values;
    const tempData = this.skyConditionOptions.find((o) => o.code === skyCondition);
    values = {
      ...rest,
      skyCondition: tempData,
    };

    const payload = {
      ...restData,
      condition: tempData,
      ...values,
    };

    this.dailyReportService.save.next({
      type: 'weather',
      data: payload,
    });
  }

  delete() {
    this.dailyReportService.delete.next({
      type: 'weather',
      id: this.data?.id,
    });
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

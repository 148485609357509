<div class="container">
  <mat-tab-group [selectedIndex]="0" class="vertical-tabs">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Equipment Types</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-equipment-types [project]="project"></app-project-equipment-types>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Equipment</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-equipments [project]="project"></app-project-equipments>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>Fields</span>
      </ng-template>
      <ng-template matTabContent>
        <app-fields
          entityType="project"
          context="equipment_fields"
          [entity]="project"></app-fields>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="container">
  <mat-tab-group [selectedIndex]="0">
    <mat-tab label="Custom report templates">
      <ng-template matTabContent>
        <app-reports [project]="project"></app-reports>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon title="Labor Setup" svgIcon="sn-labor"></mat-icon>
        <span>&nbsp;Labor setup</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-labor-setup [project]="project"></app-project-labor-setup>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span>&nbsp;Equipment setup</span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-equipment-setup [project]="project"></app-project-equipment-setup>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { IssueType, Organization } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class IssueTypeService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns IssueTypes
   * @param org Organization
   * @param qp query params
   * @returns {Promise<IssueType[]>}
   */
  getRecords(org: Organization, qp: any = {}) {
    return this.appHttpService
      .getService(`/v2/organization/${org?.id}/issue-types`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new IssueType(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Update IssueType
   * @param payload IssueType
   * @param qp any
   * @returns {Promise<IssueType>}
   */
  update(payload: any, qp: any): Promise<IssueType> {
    return this.appHttpService
      .putService(`/v2/organization-issue-type/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => new IssueType(resp?.data));
  }

  /**
   * Create IssueType
   * @param org Organization
   * @param payload IssueType
   * @param qp any
   * @returns {Promise<IssueType>}
   */
  create(org: Organization, payload: any, qp: any = {}): Promise<IssueType> {
    return this.appHttpService
      .postService(`/v2/organization/${org?.id}/issue-type`, payload, qp)
      .toPromise()
      .then((resp: any) => new IssueType(resp?.data));
  }

  /**
   * Deletes IssueType
   * @param model IssueType
   * @returns {Promise<any>} {status, message}
   */
  delete(model: IssueType): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/organization-issue-type/${model?.id}`)
      .toPromise();
  }
}

import { BaseModel, Account, Attachment, Annotation, BidItem, MaterialTest, Site, Project, Activity } from 'src/app/shared/models';
import { MaterialTestLab } from '../lab/material-test-lab';
import * as moment from 'moment';
import { MaterialTestInspection } from './material-test-inspection';
import { MaterialTestReport } from './material-test-report';

export class SiteMaterialTest extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'SiteMaterialTest'; }

  /* tslint:disable */
  request_date: any;
  request_interval: string;
  latitude: string;
  longitude: string;
  comment: string;
  cancellation_reason: string;
  failed_reason: string;
  status: string;
  pictures: Attachment[];
  annotation: Annotation;
  bid_item: BidItem;
  material_test: MaterialTest;
  lab: MaterialTestLab;
  original_test: SiteMaterialTest;
  inspection_detail: MaterialTestInspection;
  material_test_report: MaterialTestReport;
  activity_log: Activity[];
  /* tslint:enable */

  project: Project;
  project_id: string;
  site: Site;
  site_id: string;
  material_test_id: string;
  lab_id: string;

  /**
   * Workaround to initialize default values
   */
  init() {
    this.activity_log = [];
    this.pictures = [];
    this.request_date = moment();
    this.request_interval = 'PT1H';
  }

  get relations() {
    return {
      createdBy: Account,
      site: Site,
      project: Project,
      lab: MaterialTestLab,
      material_test: MaterialTest,
      bid_item: BidItem,
      pictures: Attachment,
      annotation: Annotation,
      activity_log: Activity,
      inspection_detail: MaterialTestInspection,
      material_test_report: MaterialTestReport,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.request_date = this.request_date ? moment(this.request_date) : null;

  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      request_date: moment(this.request_date).format(),
      request_interval: this.request_interval,
      bid_item_id: this.bid_item?.id,
      material_test_id: this.material_test_id || this.material_test?.id,
      lab_id: this.lab_id || this.lab?.id,
      latitude: this.latitude || undefined,
      longitude: this.longitude || undefined,
      pictures: (this.pictures || []).map(o => o.id),
      annotation_id: this.annotation?.id,
      comment: this.comment,
    };
    return payload;
  }

}

<div class="summary">
  <div
    [class]="['badge', data.typeClass]"
  >
    {{data.type}}
  </div>
  <div
    *ngIf="data.status"
    class="status-section"
  >
    <mat-icon
      *ngIf="data.icon"
      [svgIcon]="data.icon"
    />
    <div [class]="['status', data.statusClass]">
      {{data.status}}
    </div>
  </div>
  <div class="date">
    {{data.date}}
  </div>
</div>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-view-field',
  templateUrl: './view-field.component.html',
  styleUrls: ['../styles.scss', './view-field.component.scss']
})
export class ViewFieldComponent {
  @Input() label: string;
  @Input() uom: string;
  @Input() value: string;
  @Input() supportingValue: string;
  @Input() hint: string;
}

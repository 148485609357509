<div class="wrapper">
  <div class="heading text-type-2">{{ data?.heading }}</div>
  <div *ngIf="data?.subheading" class="description text-type-10">{{ data?.subheading }}</div>
  <ng-container *ngIf="data?.subType === 'onlyBidItem' || data?.subType === 'onlyMaterial'">
    <app-delivery-details-form
      (onDeletePicture)="deletePicture($event)"
      [customFields]="customFields"
      [form]="getFormGroup(form, selectedMaterialId)"
      [selectedMediaFiles]="selectedMediaFiles"
      [uom]="data.uom"
    />
  </ng-container>
  <ng-container *ngIf="data?.subType === 'bidItemWithMaterials'">
    <app-dropdown
      [closeOnEverySelect]="true"
      [enableMultiple]="true"
      [options]="materialOptions"
      [parentFormGroup]="materialForm"
      class="mb-[20px]"
      controlName="materials"
      label="MATERIAL"
    />

    <ng-container *ngFor="let material of data?.materials">
      <div
        [class.is-expanded]="selectedMaterialId === material.id"
        [id]="material.id"
        class="material-accordion"
      >
        <div class="material-header flex justify-between items-center">
          <div
            (click)="selectLineItem(material.id)"
            class="left flex justify-start items-center gap-[12px] grow"
          >
            <div class="details text-type-8">
              {{ material.heading }}
            </div>
          </div>
          <div class="right flex justify-center items-center gap-[10px]">
            <div class="chevron">
              <mat-icon
                (click)="selectLineItem(material.id)"
                [svgIcon]="selectedMaterialId === material.id ? 'chevron-up' : 'chevron-down'"
                class="chevron-icon"
              />
            </div>
            <div class="menu-section">
              <mat-icon
                [matMenuTriggerFor]="menu"
                class="menu-icon"
              >more_horiz
              </mat-icon>
              <mat-menu #menu="matMenu">
                <button (click)="deleteLineItem(material.id)" mat-menu-item>
                  <mat-icon svgIcon="trash" />
                  Delete material
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
        <div
          *ngIf="selectedMaterialId === material.id"
          class="material-content"
        >
          <app-delivery-details-form
            (onDeletePicture)="deletePicture($event)"
            [customFields]="customFields"
            [form]="getFormGroup(form, selectedMaterialId)"
            [selectedMediaFiles]="selectedMediaFiles"
            [uom]="material.uom"
          />
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="showHeaderDetails"
      class="header-details mt-[40px]"
    >
      <div
        *ngIf="!!getFormGroup(form, selectedMaterialId).controls['comment']"
        class="comment-section"
      >
        <app-text-area
          [parentFormGroup]="getFormGroup(form, selectedMaterialId)"
          controlName="comment"
          label="COMMENT"
        />
      </div>
      <app-pictures-section
        (onDelete)="deletePicture($event)"
        *ngIf="selectedMediaFiles.length"
        [pictures]="selectedMediaFiles"
        class="pictures-section"
      />
      <app-location-section
        (onDelete)="deleteLocation(selectedMaterialId)"
        *ngIf="showLocationSection(selectedMaterialId)"
        [latitude]="getFormGroup(form, selectedMaterialId)?.controls?.latitude?.value"
        [longitude]="getFormGroup(form, selectedMaterialId)?.controls?.longitude?.value"
        class="location-section"
      />
    </div>
  </ng-container>
</div>

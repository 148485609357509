<div class="dialog-actions">
  <button mat-icon-button [matMenuTriggerFor]="moreActionsMenu" aria-label="More actions">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #moreActionsMenu="matMenu">
    <button mat-menu-item [disabled]="issue.status !== 'closed' || !('create' | able: issue)"
      (click)="reopenIssue(issue)">
      <mat-icon>autorenew</mat-icon>
      <span>Reopen</span>
    </button>
    <button mat-menu-item [disabled]="issue.status !== 'open' || !('close' | able: issue)" (click)="closeIssue(issue)">
      <mat-icon>check_circle_outline</mat-icon>
      <span>Close</span>
    </button>
  </mat-menu>

  <button [mat-dialog-close]="dialogResult" class="dialog-close" mat-mini-fab aria-label="Close Sidebar"
    *ngIf="dialogOptions.closable">
    <mat-icon title="Close Sidebar">close</mat-icon>
  </button>

  <button *ngIf="initialized" mat-mini-fab (click)="editing = !editing" [disabled]="!editable">
    <mat-icon>edit</mat-icon>
  </button>
</div>

<ng-container *ngIf="initialized">
  <div class="content">

    <section class="header">
      <div class="title">{{issue.title}}</div>
      <div class="mat-body" *ngIf="issue.description">{{issue.description}}</div>
      <div class="row footer">
        <app-account-inline *ngIf="issue.createdBy" [account]="issue.createdBy" />
        <div class="mat-caption">{{issue.created_at | dateFormat : 'MMMM DD, YYYY'}}</div>
        <!-- <div class="mat-caption">2 edits</div> -->
      </div>
    </section>

    <nav mat-tab-nav-bar class="sub-nav">
      <a mat-tab-link active="active">Details</a>
    </nav>

    <form [formGroup]="inputForm">
      <div class="container">

        <!-- title section -->
        <section *ngIf="editing">
          <mat-form-field>
            <mat-label>Title</mat-label>
            <input matInput type="text" autocomplete="off" formControlName="title" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.title.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.title.hasError('error')">
              {{inputForm.controls.title.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /title section -->

        <!-- description section -->
        <section *ngIf="editing">
          <mat-form-field>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" [errorStateMatcher]="matcher"></textarea>
            <mat-error *ngIf="inputForm.controls.description.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.description.hasError('error')">
              {{inputForm.controls.description.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /description section -->

        <section *ngIf="!editing">
          <div class="mat-subheading-1 row">Status:</div>
          <div class="mat-body capitalize">{{issue.status}}</div>
        </section>

        <section>
          <div class="mat-subheading-1 row" *ngIf="!editing">Scope:</div>
          <div class="mat-body capitalize" *ngIf="!editing">{{issue.entity_type}} {{issue.entity?.name}}</div>
          <mat-form-field *ngIf="editing">
            <mat-label>SCOPE</mat-label>
            <mat-select formControlName="scope_id" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let scope of scopes" [value]="scope.id">
                <div [class.pad]="scope.child">{{scope.name}}</div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="inputForm.controls.scope_id.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.scope_id.hasError('error')">
              {{inputForm.controls.scope_id.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>

        <section>
          <div class="mat-subheading-1 row">
            <div>Internal:</div>
            <div class="icons">
              <mat-slide-toggle [(ngModel)]="issue.internal" [ngModelOptions]="{standalone: true}" [disabled]="!editing"
                [checked]="issue.internal">
              </mat-slide-toggle>
            </div>
          </div>
        </section>

        <section>
          <div class="mat-subheading-1 row">Category:</div>
          <div class="mat-body" *ngIf="!editing">{{issueTypeLabel(issue.type)}}</div>
          <mat-radio-group matInput aria-label="Select category" formControlName="type" *ngIf="editing"
            [errorStateMatcher]="matcher">
            <mat-radio-button *ngFor="let issueType of issueTypes" [value]="issueType.type">
              {{issueType.label}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error *ngIf="inputForm.controls.type.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.type.hasError('error')">
            {{inputForm.controls.type.errors.error}}
          </mat-error>
        </section>

        <!-- Bid Item section -->
        <section>
          <div class="mat-subheading-1 row">Bid Item:</div>
          <div class="bid-item link" (click)="openBidItemSelector()" *ngIf="issue.bid_item">
            <div class="title">{{issue.bid_item?.item}}</div>
            <div class="body">{{issue.bid_item?.description}}</div>
          </div>

          <div class="input-placeholder" *ngIf="!issue.bid_item" (click)="openBidItemSelector()">
            <button mat-icon-button [disabled]="!editing" color="accent">
              <mat-icon>add</mat-icon>
            </button>Add bid item
          </div>
        </section>
        <!-- /Bid Item section -->

        <!-- Annotation section -->
<!--        <section>-->
<!--          <div class="mat-subheading-1">Annotation:</div>-->
<!--          <div class="input-placeholder" *ngIf="!issue.annotation" (click)="openAnnot()">-->
<!--            <button mat-icon-button [disabled]="!editing" color="accent">-->
<!--              <mat-icon>add</mat-icon>-->
<!--            </button>Add annotation-->
<!--          </div>-->
<!--          <div class="preview-list">-->
<!--            <div *ngIf="issue.annotation">-->
<!--              <img class="link" [src]="issue.annotation.getThumbnail()" alt="Annotation Preview"-->
<!--                (click)="openAnnot(issue.annotation)" />-->
<!--              <button *ngIf="editing" class="hover-icon" mat-icon-button aria-label="Delete" title="Delete"-->
<!--                (click)="removeAnnot(issue.annotation)">-->
<!--                <mat-icon>delete</mat-icon>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->
        <!-- /Annotation section -->

        <!-- location section -->
        <section>
          <div class="mat-subheading-1">Location:</div>

          <div class="input-placeholder" *ngIf="!issue.latitude || !issue.longitude" (click)="editLocation(issue)">
            <button mat-icon-button [disabled]="!editing" color="accent">
              <mat-icon>location_on</mat-icon>
            </button>Add Location
          </div>

          <div (click)="editLocation(issue)" *ngIf="issue.latitude">
            <app-location-picker [isDialog]="false" [latitude]="issue.latitude" [longitude]="issue.longitude"
              height="100px" [readOnly]="true"></app-location-picker>
          </div>
        </section>
        <!-- /location section -->

        <!-- Pictures section -->
        <section>
          <div class="mat-subheading-1">Pictures:</div>
          <div class="preview-list">
            <div *ngFor="let pic of issue.pictures">
              <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, issue.pictures, pic)" />
              <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
                (click)="removePicture(pic)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <app-file-uploader class="file-uploader input-placeholder small"
                               [disabled]="!editing" icon="image" accept="image/*"
              (complete)="onUploadComplete($event)">
            </app-file-uploader>
          </div>
        </section>
        <!-- /Pictures section -->

        <!-- Attachments section -->
        <section>
          <div class="mat-subheading-1">Attachments:</div>
          <div class="preview-list">
            <div *ngFor="let att of issue.attachments">
              <img [src]="att.getThumbnail()" class="link"
                (click)="appSrv.openCarousel($event, issue.attachments, att)" />
              <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
                (click)="removeAttachment(att)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <app-file-uploader class="file-uploader input-placeholder small" [disabled]="!editing" icon="attach_file"
              accept="application/pdf" (complete)="onAttachmentUploadComplete($event)">
            </app-file-uploader>
          </div>
        </section>
        <!-- /Attachments section -->

        <!-- Form Actions -->
        <section>
          <div class="row" *ngIf="editing">
            <button [disabled]="!('create' | able: issue)" mat-stroked-button color="accent" (click)="saveItem()">
              <ng-container *ngIf="issue.id">Update</ng-container>
              <ng-container *ngIf="!issue.id">Create</ng-container>
            </button>
            <button mat-stroked-button (click)="onCancel()">Cancel</button>
          </div>
        </section>
        <!-- Form Actions -->

      </div>
    </form>

  </div>
</ng-container>

<div class="wrapper">
  <ng-content/>

  <!--  Close BUTTON  -->
  <div
    class="map-action close-section"
    *ngIf="isFullScreen && showButtons"
    (click)="closeMap()"
  >
    <mat-icon
      class="sidenav-icon"
    >close</mat-icon>
  </div>

  <!--  ZOOM IN BUTTON  -->
  <div
    *ngIf="showButtons"
    class="map-action zoom-in-section"
    (click)="zoomIn()"
  >
    <mat-icon
      class="zoom-in-icon"
      svgIcon="plus"
    />
  </div>

  <!--  ZOOM OUT BUTTON  -->
  <div
    *ngIf="showButtons"
    class="map-action zoom-out-section"
    (click)="zoomOut()"
  >
    <mat-icon
      class="zoom-out-icon"
      svgIcon="minus"
    />
  </div>

  <!--  FULL SCREEN BUTTON  -->
  <div
    *ngIf="!isFullScreen && showButtons"
    class="map-action full-screen-section"
    (click)="openInFullScreen()"
  >
    <mat-icon
      class="full-screen-icon"
      svgIcon="full-screen"
    />
  </div>

</div>

import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Parser } from 'expr-eval';
import { DailyReportService } from '../../../../daily-report.service';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import * as moment from 'moment';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import * as $ from 'jquery';
import { AppService } from '../../../../../../../../../../shared/services';
import { dailyReport } from '../../../../../../../../core/projects.selectors';
import { DeleteVideoFromMuxDbRequest } from '../../../../../../../../core/projects.actions';

@Component({
  selector: 'app-multiline-bid-item-details',
  templateUrl: './multiline-bid-item-details.component.html',
  styleUrls: ['../../input-component-styles.scss', './multiline-bid-item-details.component.scss'],
})
export class MultilineBidItemDetailsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() lineItems: any[];
  @Input() bidItem: any;
  @Input() disabled = false;
  @Input() clickId?: string;

  quantityTotal: number = null;
  form: FormGroup = new FormGroup({});
  formSubscription: Subscription = new Subscription();
  isFormReady = false;
  autoCalculateOptions = [
    {
      label: 'Dynamic rollup',
      value: 'dynamic_rollup',
    },
    {
      label: 'Manual entry',
      value: 'manual_entry',
    },
  ];
  selectedLineItemId = '';
  selectedLineItemMediaFiles = [];
  videoToBeDeletedFromMuxDb = [];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private dailyReportService: DailyReportService,
    private commonService: CommonService,
    private projectsService: ProjectsService,
    protected appService: AppService,
  ) {
    this.dailyReportService.add
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.store.select(dailyReport)
            .subscribe((report) => {
              const details = report.reportDetails;

              if (details) {
                const { bidItems } = details;

                const selectedElement = bidItems.find((o) => this.bidItem.id === o.bidItem.id);

                if (selectedElement) {
                  this.bidItem = selectedElement.bidItem;
                  this.lineItems = JSON.parse(JSON.stringify(selectedElement.lineItems));
                }
              }
            });


          const id = `new_${new Date().getTime()}`;
          this.selectedLineItemId = id;
          this.dailyReportService.disableAddMenu.next(false);

          const newElement = {
            id,
            bid_item_id: this.bidItem.id,
            created_at: moment().toISOString(),
            bid_item: this.bidItem,
            quantity: null,
            comment: '',
            station_id: null,
            latitude: '',
            longitude: '',
            heading_id: null,
            start_of_work: false,
            station_break: false,
            updated_at: null,
            pictures: [],
            videos: [],
            station: null,
            heading: null,
            annotations: [],
            ...(this.bidItem.rollup ?
              {
                field_values: this.bidItem?.fields?.map(o => ({
                    id: o.id,
                    value: null,
                  }),
                ),
              } : {}),

          };

          this.addNewLineItem(newElement);
        }

      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.clickId !== undefined && this.clickId !== null) {
        this.selectLineItem(this.clickId);
      }
    }, 100);

  }

  ngOnInit() {
    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const items = Array.isArray(data) ? data : [data];
        const formGroup = this.getFormGroup(this.form, this.selectedLineItemId);

        items.forEach(eachItem => {
          if (eachItem?.['fileType'] === 'picture') {
            let pictures = formGroup?.get('pictures')?.value || '[]';
            pictures = JSON.parse(pictures);
            pictures.push(eachItem);
            formGroup?.get('pictures')?.setValue(JSON.stringify(pictures));
          }

          if (eachItem?.['fileType'] === 'video') {
            let videos = formGroup?.get('videos')?.value || '[]';
            videos = JSON.parse(videos);
            videos.push(eachItem);
            formGroup?.get('videos')?.setValue(JSON.stringify(videos));
          }
        });

        const tempPictureData = this.getSelectedLineItemPictures() ?? [];
        const tempVideosData = this.getSelectedLineItemVideos() ?? [];
        const allNewItems = [...tempPictureData, ...tempVideosData];

        allNewItems.forEach(item => {
          const existingItem = this.selectedLineItemMediaFiles.find(ei => ei.id === item.id);

          if (!existingItem) {
            this.selectedLineItemMediaFiles.push(item);
          }
        });
      });

    this.dailyReportService.addComment
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          const tempControl = this.getFormGroup(this.form, this.selectedLineItemId).get('comment');

          if (!tempControl) {
            this.getFormGroup(this.form, this.selectedLineItemId)?.addControl('comment', new FormControl(null));

            setTimeout(() => {
              $(`#${this.selectedLineItemId} textarea`).focus();
            }, 100);
          }
        }
      });

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.dailyReportService.formatAndSaveMultilineBidItemPayload(this.form, this.bidItem, this.lineItems);
        }
        if (this.videoToBeDeletedFromMuxDb.length) {
          this.videoToBeDeletedFromMuxDb.forEach(videoId => {
            this.store.dispatch(DeleteVideoFromMuxDbRequest({ payload: videoId }));
          });
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.bidItem) {
          this.dailyReportService.delete.next({
            type: 'bidItems',
            id: this.bidItem.id,
          });
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        if (this.form) {
          this.getFormGroup(this.form, this.selectedLineItemId)?.patchValue(location);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dailyReportService.disableAddMenu.next(true);
    this.projectsService.isAnyFormDirty.next(false);
    this.formSubscription?.unsubscribe();

    this.isFormReady = false;

    if (this.lineItems && this.lineItems.length > 0) {
      this.form = this.createLineItemForm(this.bidItem, this.lineItems);

      this.form = this.calculateLineItemQuantity(this.form, this.bidItem, true);

      this.quantityTotal = this.calculateTotalQuantity(this.form);

      this.formSubscription = this.form.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(() => {
          this.form = this.calculateLineItemQuantity(this.form, this.bidItem);
          this.quantityTotal = this.calculateTotalQuantity(this.form);

          this.projectsService.isAnyFormDirty.next(true);
        });
    } else {
      this.dailyReportService.add.next();
    }

    if (this.disabled) {
      this.form.disable();
      this.projectsService.isAnyFormDirty.next(false);
    }

    this.isFormReady = true;
  }

  getFormGroup(form, controlName) {
    if (form && form.get(controlName)) {
      return form.get(controlName) as FormGroup;
    }

    return new FormGroup({}) as FormGroup;
  }

  getFieldValuesMap(values) {
    const mapObj = {};

    this.bidItem.fields.forEach(field => {
      mapObj[field.name] = Number(values[field.id]);
    });

    return mapObj;
  }

  selectLineItem(lineItemId) {
    this.selectedLineItemId = this.selectedLineItemId === lineItemId ? '' : lineItemId;
    const pictures = this.getSelectedLineItemPictures() ?? [];
    const videos = this.getSelectedLineItemVideos() ?? [];
    const allNewItems = [...pictures, ...videos];

    allNewItems.forEach(item => {
      const existingItem = this.selectedLineItemMediaFiles.find(ei => ei.id === item.id);

      if (!existingItem) {
        this.selectedLineItemMediaFiles.push(item);
      }
    });

    if (this.selectedLineItemId) {
      this.dailyReportService.disableAddMenu.next(false);
    } else {
      this.dailyReportService.disableAddMenu.next(true);
    }
  }

  createLineItemForm(bidItem, lineItems) {
    if (bidItem.rollup === false) {
      const parentFormBuilder = {};
      lineItems?.map(lineItem => {
        parentFormBuilder[lineItem.id] = this.fb.group({});

        parentFormBuilder[lineItem.id].addControl('quantity', new FormControl(lineItem.quantity));

        if (lineItem.comment) {
          parentFormBuilder[lineItem.id].addControl('comment', new FormControl(lineItem.comment));
        }

        parentFormBuilder[lineItem.id].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures)));
        parentFormBuilder[lineItem.id].addControl('videos', new FormControl(JSON.stringify(lineItem.videos)));

        parentFormBuilder[lineItem.id].addControl('latitude', new FormControl(lineItem.latitude));
        parentFormBuilder[lineItem.id].addControl('longitude', new FormControl(lineItem.longitude));
      });

      return this.fb.group(parentFormBuilder);
    } else if (bidItem.rollup === true) {
      if (bidItem.rollup_quantity_editable === false) {
        const parentFormBuilder = {};
        lineItems?.map(lineItem => {
          parentFormBuilder[lineItem.id] = this.fb.group({});

          lineItem.field_values?.map(field => {
            parentFormBuilder[lineItem.id].addControl(field.id, new FormControl(field.value));
          });

          parentFormBuilder[lineItem.id].addControl('quantity', new FormControl(null));

          if (lineItem.comment) {
            parentFormBuilder[lineItem.id].addControl('comment', new FormControl(lineItem.comment));
          }

          parentFormBuilder[lineItem.id].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures)));
          parentFormBuilder[lineItem.id].addControl('videos', new FormControl(JSON.stringify(lineItem.videos)));

          parentFormBuilder[lineItem.id].addControl('latitude', new FormControl(lineItem.latitude));
          parentFormBuilder[lineItem.id].addControl('longitude', new FormControl(lineItem.longitude));
        });

        return this.fb.group(parentFormBuilder);
      } else if (bidItem.rollup_quantity_editable === true) {
        const parentFormBuilder = {};
        this.lineItems?.map(lineItem => {
          parentFormBuilder[lineItem.id] = this.fb.group({});

          lineItem.field_values?.map(field => {
            parentFormBuilder[lineItem.id].addControl(field.id, new FormControl(field.value));
          });
          parentFormBuilder[lineItem.id].addControl('initialQuantity', new FormControl(lineItem.quantity));
          parentFormBuilder[lineItem.id].addControl('quantity', new FormControl(lineItem.quantity));
          parentFormBuilder[lineItem.id].addControl('valueType', new FormControl('dynamic_rollup'));

          if (lineItem.comment) {
            parentFormBuilder[lineItem.id].addControl('comment', new FormControl(lineItem.comment));
          }

          parentFormBuilder[lineItem.id].addControl('pictures', new FormControl(JSON.stringify(lineItem.pictures)));
          parentFormBuilder[lineItem.id].addControl('videos', new FormControl(JSON.stringify(lineItem.videos)));

          parentFormBuilder[lineItem.id].addControl('latitude', new FormControl(lineItem.latitude));
          parentFormBuilder[lineItem.id].addControl('longitude', new FormControl(lineItem.longitude));
        });

        return this.fb.group(parentFormBuilder);
      }
    }
  }

  calculateLineItemQuantity(form, bidItem, isInitial = false) {
    if (bidItem.rollup === true) {
      if (bidItem.rollup_quantity_editable === false) {
        const lineItemValues = form.getRawValue();

        for (const lineItemId in lineItemValues) {
          if (lineItemId in lineItemValues) {
            const lineItem = lineItemValues[lineItemId];
            const fieldValuesMap = this.getFieldValuesMap(lineItem);
            const quantity = (Parser.evaluate(bidItem.rollup_formula, fieldValuesMap)).toFixed(2);

            form.get(lineItemId)?.patchValue({ quantity }, { emitEvent: false });
          }
        }
      } else if (bidItem.rollup_quantity_editable === true) {
        const lineItemValues = form.getRawValue();

        for (const lineItemId in lineItemValues) {
          if (lineItemId in lineItemValues) {
            const lineItem = lineItemValues[lineItemId];
            const fieldValuesMap = this.getFieldValuesMap(lineItem);
            const quantity = Number((Parser.evaluate(bidItem.rollup_formula, fieldValuesMap)).toFixed(2));

            if (isInitial) {
              if (
                Number(lineItem.quantity) !== 0 &&
                quantity !== Number(lineItem.initialQuantity)
              ) {
                form.get(lineItemId)?.patchValue({ valueType: 'manual_entry' }, { emitEvent: false });
              } else {
                form.get(lineItemId)?.patchValue({ valueType: 'dynamic_rollup' }, { emitEvent: false });
                form.get(lineItemId)?.patchValue({ quantity }, { emitEvent: false });
              }
            } else {
              if ((form.get(lineItemId))['controls']?.valueType?.value === 'dynamic_rollup') {
                form.get(lineItemId)?.patchValue({ quantity }, { emitEvent: false });
              }
            }
          }
        }
      }
    }

    return form;
  }

  calculateTotalQuantity(form) {
    let temp = 0;
    const lineItemValues = form.getRawValue();

    for (const lineItemId in lineItemValues) {
      if (lineItemId in lineItemValues) {
        const lineItem = lineItemValues[lineItemId];

        temp += Number(lineItem.quantity);
      }
    }

    return temp;
  }

  save(form, bidItem, lineItems) {
    const values = form.getRawValue();

    const lineItemsPayload = [];

    if (bidItem.rollup === false) {
      lineItems.forEach((lineItem) => {
        lineItemsPayload.push({
          ...lineItem,
          quantity: isNaN(values[lineItem.id].quantity) ? '' : Number(values[lineItem.id].quantity),
          comment: values[lineItem.id].comment,
          pictures: JSON.parse(values[lineItem.id].pictures),
          videos: JSON.parse(values[lineItem.id].videos),
          latitude: values[lineItem.id].latitude,
          longitude: values[lineItem.id].longitude,
        });
      });
    } else if (bidItem.rollup === true) {
      if (bidItem.rollup_quantity_editable === false) {
        lineItems.forEach((lineItem) => {
          const fieldValues = [];

          bidItem?.fields?.forEach((fieldValue) => {
            fieldValues.push({
              id: fieldValue.id,
              value: isNaN(values[lineItem.id][fieldValue.id]) ? '' : Number(values[lineItem.id][fieldValue.id]),
            });
          });

          lineItemsPayload.push({
            ...lineItem,
            field_values: fieldValues,
            comment: values[lineItem.id].comment,
            pictures: JSON.parse(values[lineItem.id].pictures),
            videos: JSON.parse(values[lineItem.id].videos),
            latitude: values[lineItem.id].latitude,
            longitude: values[lineItem.id].longitude,
          });
        });
      } else if (bidItem.rollup_quantity_editable === true) {
        lineItems.forEach((lineItem) => {
          const fieldValues = [];

          bidItem?.fields?.forEach((fieldValue) => {
            fieldValues.push({
              id: fieldValue.id,
              value: isNaN(values[lineItem.id][fieldValue.id]) ? '' : Number(values[lineItem.id][fieldValue.id]),
            });
          });

          lineItemsPayload.push({
            ...lineItem,
            quantity: values[lineItem.id].valueType === 'dynamic_entry' ?
              '' :
              values[lineItem.id].quantity && Number(values[lineItem.id].quantity) || null,
            field_values: fieldValues,
            comment: values[lineItem.id].comment,
            pictures: JSON.parse(values[lineItem.id].pictures),
            videos: JSON.parse(values[lineItem.id].videos),
            latitude: values[lineItem.id].latitude,
            longitude: values[lineItem.id].longitude,
          });
        });
      }
    }

    this.dailyReportService.save.next({
      type: 'bidItems',
      data: lineItemsPayload,
      options: {
        bidItem,
      },
    });
  }

  getSelectedLineItemPictures() {
    const pictures = this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures')?.value || '[]';
    return JSON.parse(pictures);
  }

  getSelectedLineItemVideos() {
    const videos = this.getFormGroup(this.form, this.selectedLineItemId)?.get('videos')?.value || '[]';
    return JSON.parse(videos);
  }

  addNewLineItem(newElement) {
    this.lineItems.push(newElement);

    const values = this.form.getRawValue();

    this.form = this.createLineItemForm(this.bidItem, this.lineItems);

    this.form.patchValue(values);

    this.form = this.calculateLineItemQuantity(this.form, this.bidItem, true);

    this.quantityTotal = this.calculateTotalQuantity(this.form);

    this.formSubscription = this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.form = this.calculateLineItemQuantity(this.form, this.bidItem);
        this.quantityTotal = this.calculateTotalQuantity(this.form);

        this.projectsService.isAnyFormDirty.next(true);
      });
    this.selectedLineItemMediaFiles = [];


    if (this.disabled) {
      this.form.disable();
      this.projectsService.isAnyFormDirty.next(false);
    }
  }

  deleteLineItem(lineItemId) {
    this.form.removeControl(lineItemId);
    this.lineItems = this.lineItems.filter(o => o.id !== lineItemId);

    if (this.selectedLineItemId === lineItemId) {
      this.selectedLineItemId = null;
    }
  }

  deletePicture(pic) {
    if (pic.fileType === 'picture') {
      let pictures = this.getSelectedLineItemPictures();

      pictures = pictures?.filter(o => o.id !== pic.id);
      this.selectedLineItemMediaFiles = this.selectedLineItemMediaFiles?.filter(o => o.id !== pic.id);
      this.getFormGroup(this.form, this.selectedLineItemId)?.get('pictures').setValue(JSON.stringify(pictures));
    }

    if (pic.fileType === 'video') {
      let videos = this.getSelectedLineItemVideos();

      videos = videos?.filter(o => o.id !== pic.id);
      this.selectedLineItemMediaFiles = this.selectedLineItemMediaFiles?.filter(o => o.id !== pic.id);
      this.getFormGroup(this.form, this.selectedLineItemId)?.get('videos').setValue(JSON.stringify(videos));
      this.videoToBeDeletedFromMuxDb.push(pic.id);
    }
  }

  deleteLocation(lineItemId) {
    this.getFormGroup(this.form, lineItemId)?.get('latitude').setValue(null);
    this.getFormGroup(this.form, lineItemId)?.get('longitude').setValue(null);
  }

  showLocationSection(lineItemId) {
    return this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== null &&
      this.getFormGroup(this.form, lineItemId)?.controls?.latitude?.value !== '' &&
      this.getFormGroup(this.form, lineItemId)?.controls?.longitude?.value !== '';
  }

  ngOnDestroy() {
    this.formSubscription?.unsubscribe();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-project-details-page-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['../styles.scss', './date-filter.component.scss']
})
export class DateFilterComponent implements OnInit, OnDestroy {
  @Input() defaultDateValue: string;

  form = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.startDate && moment(qp.startDate).isValid()) {
          if ( this.form.controls.startDate.value !== qp.startDate) {
            this.form.controls.startDate.setValue(qp.startDate, { emitEvent: false });
          }
        } else {
          this.form.controls.startDate.setValue(null, { emitEvent: false });
        }

        if (qp.endDate && moment(qp.endDate).isValid()) {
          if (this.form.controls.endDate.value !== qp.endDate) {
            this.form.controls.endDate.setValue(qp.endDate, { emitEvent: false });
          }
        } else {
          this.form.controls.endDate.setValue(null, { emitEvent: false });
        }
      });

    this.form.controls.endDate.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(date => {
        let startDate = this.form.controls.startDate.value;

        startDate = moment(startDate).format('YYYY-MM-DD');

        let endDate = this.form.controls.endDate.value;

        endDate = moment(endDate).format('YYYY-MM-DD');

        if (moment(startDate).isValid() && moment(endDate).isValid()) {
          this.router.navigate([], {
            queryParams: {
              startDate,
              endDate,
            },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  removeDateFilter($event) {
    this.router.navigate([], {
      queryParams: {
        startDate: null,
        endDate: null,
      },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommonService } from '../../../../../../../../shared/services/common.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { AuthService } from '../../../../../../../../shared/services';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as $ from 'jquery';
import { UploadChangeOrderFileRequest } from '../../../../../../core/projects.actions';
import { bidSheet } from '../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { ProjectsService } from '../../../../../../core/projects.service';
import { v1URL } from '../../../../../../../../shared/constants/urls.constants';
import { FileDownloadService } from '../../../../../../../../shared/services/file-download.service';

@Component({
  selector: 'app-upload-changes',
  templateUrl: './upload-changes.component.html',
  styleUrls: ['./upload-changes.component.scss'],
})
export class UploadChangesComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploadRef') fileUploadRef: ElementRef;
  acceptedFileTypes = [
    'application/vnd.ms-excel', // XLS
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX,
    'text/csv',
  ];
  errorMessages: any = {};
  errorMessageMap = {
    comment: {
      required: 'This field is required.',
    },
    changeOrderNumber: {
      required: 'This field is required.',
    },
  };

  form = this.fb.group({
    changeOrderNumber: new FormControl({ value: '', disabled: false }),
    comment: new FormControl({ value: '', disabled: false }),
  });

  currentUser: any;
  file: any;
  errors: any;
  bidSheetId: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private projectsService: ProjectsService,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private authService: AuthService,
    private fileDownloadService: FileDownloadService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentUser = this.authService?.getCurrentUser() || {};
  }

  ngOnInit() {

    this.store.select(bidSheet)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data?.id) {
          this.bidSheetId = data.id;
        }
      });

    this.projectsService.error
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data) {
          this.errors = data;
        }
      });

  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }


  onSubmit() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      comment,
      changeOrderNumber,
    } = this.form.getRawValue();

    const payload = {
      comment,
      order_number: changeOrderNumber,
      inputFile: this.file,
      bidSheetId: this.bidSheetId,
    };

    if (this.file !== undefined) {
      this.store.dispatch(UploadChangeOrderFileRequest({ payload }));
    }
  }

  openFile(id) {
    $(`#${id}`).click();
  }

  handleFileUpload() {
    this.file = this.fileUploadRef.nativeElement.files[0];
    if (this.file) {
      if (!this.acceptedFileTypes.includes(this.file?.type)) {
        this.commonService.notification('Invalid file type.', 'danger');
        return;
      }
    }

    this.fileUploadRef.nativeElement.value = '';
    this.form.markAsDirty();
  }

  removeSelectedFile() {
    this.file = null;
    this.errors = null;
    this.form.markAsPristine();
  }

  getTemplateFile() {
    const payload = { bidSheetId: this.bidSheetId };
    const urlConfig = v1URL.bidSheet.fetchTemplateFile;
    const url = urlConfig.url(payload);

    this.fileDownloadService.downloadCSV(url).subscribe((response: Blob) => {
      const blob = new Blob([response], { type: 'text/csv' });
      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'template.csv';  // You can name the file here
      link.click();
    }, error => {
      console.error('Error downloading the file:', error);
    });
  }


  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  typeOf(data) {
    return typeof data;
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-weather-details-wrapper',
  templateUrl: './weather-details-wrapper.component.html',
  styleUrls: ['./weather-details-wrapper.component.scss'],
})
export class WeatherDetailsWrapperComponent implements OnInit, OnDestroy {
  @Input() data: any;
  disableOptionMenu = true;
  disableAddMenu = true;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}

import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {FetchProjectsListRequest, FetchSiteDetailsRequest} from '../../../core/projects.actions';
import {siteDetails} from '../../../core/projects.selectors';
import {takeUntil} from 'rxjs/operators';
import {MatTabGroup} from '@angular/material/tabs';
import {DailyReportService} from '../../../../../shared/services';
import {ProjectsService} from '../../../core/projects.service';
import {ConfirmDialogComponent, ConfirmDialogModel} from '../../../../../shared/components';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-site-details-screen',
  templateUrl: './site-details-screen.component.html',
  styleUrls: ['./site-details-screen.component.scss']
})
export class SiteDetailsScreenComponent implements OnInit, AfterViewInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  @ViewChild('tabGroup') tabs: MatTabGroup;

  siteDetails: any = {};

  tabIndexMap = {
    'daily-report': 0,
    details: 1,
    changes: 2
  };

  selectedTabIndex = 0;

  constructor(
    private store: Store<fromRoot.State>,
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private projectsService: ProjectsService,
    public dialog: MatDialog
  ) {
    this.store.dispatch(
      FetchSiteDetailsRequest({
        payload: {
          siteId: this.route.snapshot.params.siteId,
          qp: {
            include: [
              {
                project: [
                  'cover_photo',
                  'owner_organization'
                ]
              },
              {
                headings: [
                  'stations'
                ]
              }
            ]
          }
        }
      })
    );

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.fs === undefined) {
          if (qp.tab) {
            this.selectedTabIndex = this.tabIndexMap[qp.tab] || 0;
          } else {
            this.router.navigate([], {
              queryParams: {
                tab: 'daily-report'
              }
            });
          }
        }
      });
  }

  ngOnInit() {
    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(site => {
        this.siteDetails = site;
      });
  }

  ngAfterViewInit() {
    // Disable tab click, to change the tab. This is done to make the tab
    // change stay in sync with the query params.
    this.tabs._handleClick = this.onTabChange.bind(this);
  }

  onTabChange(tab) {
    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`
        )
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.router.navigate([], {
            queryParams: {
              tab: tab.textLabel.toLowerCase().replaceAll(' ', '-')
            }
          }).then(() => {
            this.projectsService.isAnyFormDirty.next(false);
          });
        }
      });
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

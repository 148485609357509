import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { WorkflowAction } from '../../models';
import { AppErrorStateMatcher } from '../../services';
import { WorkflowService } from '../../services/workflow.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  public initialized = false;
  public loading = 0;
  private _redraw: any;

  @Input() wf: string;
  @Input() id: string;
  @Input() input: any = {};
  @Input() projectId: string;
  @Output() actionResponse = new EventEmitter();
  @Input()
  set redraw(val: any) {
    if (this._redraw && this._redraw !== val) {
      this.ngOnInit();
    }
    this._redraw = val;
  }

  @Input()
  public actions: WorkflowAction[] = [];

  public matcher = new AppErrorStateMatcher();
  public formInputs: any[] = [];
  public inputForm: UntypedFormGroup;

  constructor(
    private snackBar: MatSnackBar,
    private wfService: WorkflowService,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    if (this.actions.length == 0) {
      this.getNextActions();
    } else {
      this.actions = this.actions.map(o => new WorkflowAction(o));
      this.createFormElements();
      this.initialized = true;
    }
  }

  /**
   * Creates dynamic FormGroup based on passed meta
   * @param formInputs dynamic form-fields meta
   * @return FormGroup
   */
  composeDynamicForm(formInputs = []): UntypedFormGroup {
    const group: any = {};
    for (const formInput of formInputs) {
      group[formInput.name] = formInput.required ? new UntypedFormControl('', Validators.required)
        : new UntypedFormControl('');
    }
    return new UntypedFormGroup(group);
  }

  /**
   * Fetch next available actions
   */
  getNextActions() {
    this.wfService.getNextActions(this.wf, this.id, this.projectId)
      .subscribe({
        next: (actions: WorkflowAction[]) => {
          this.actions = actions;
          this.createFormElements();
          this.initialized = true;
        },
        error: (err: any) => {
          console.error(err);
        }
      });
  }

  createFormElements() {
    // Todo create multiple forms. Each form for each state with tab controls
    // Create dynamic form
    let inputs = [];
    this.actions.map(action => {
      inputs = inputs.concat(action.options.formInputs || []);
    });
    this.formInputs = [...new Set(inputs.map(f => f.name))].map(name => inputs.find(f => f.name === name));
    this.inputForm = this.composeDynamicForm(this.formInputs);
  }

  /**
   * Run wf-action via REST API
   * @param action workflow-action
   */
  takeAction(action) {

    if (!this.inputForm.valid) {
      return;
    }

    const payload = Object.assign(this.input, {
      formValues: this.inputForm.value || {},
      id: this.id,
    });
    this.inputForm.reset();

    this.wfService.executeState(action.ex, action.wf, action.state, action.project_id, payload)
      .subscribe({
        next: (resp: any) => {
          // fetch next actions
          if (resp.message) {
            this.snackBar.open(resp.message, '', { duration: 5000 });
          }
          this.actionResponse.emit(resp);
          this.getNextActions();
        },
        error: (err: any) => {
          this.snackBar.open(err.error?.error || err.message, '', { duration: 5000 });
        }
      });
  }

}

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { MaterialTest, Project } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MaterialTestService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns list of Records
   * @param pagination start
   * @param qp query params
   */
  getRecords(pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = '/v1/material-test';
    return this.appHttpService.getService(path, qp).toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new MaterialTest(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new MaterialTest
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<MaterialTest>}
   */
  create(payload: any = null, qp: any = {}): Promise<MaterialTest> {
    return this.appHttpService
      .postService(`/v1/material-test`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new MaterialTest(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates MaterialTest
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<MaterialTest>}
   */
  update(payload: any = null, qp: any = {}): Promise<MaterialTest> {
    return this.appHttpService
      .putService(`/v1/material-test/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new MaterialTest(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes MaterialTest
   * @param model MaterialTest
   * @returns {Promise<any>} {status, message}
   */
  delete(model: MaterialTest): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v1/material-test/${model?.id}`)
      .toPromise();
  }


  /**
   * Fetches project's material-tests
   * @param project Project
   * @param pagination pagination
   * @param qp query-params
   */
  getProjectRecords(project: Project, pagination: any, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/project/${project.id}/material-test`;
    return this.appHttpService.getService(path, qp).toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new MaterialTest(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Add materialTest to project
   * @param project Project
   * @param materialTest MaterialTest
   */
  addMaterialTestToProject(project: Project, materialTest: MaterialTest) {
    const path = `/v1/project/${project.id}/material-test/${materialTest.id}`;
    return this.appHttpService.postService(path, {});
  }

  /**
   * Remove materialTest from project
   * @param project Project
   * @param materialTest MaterialTest
   */
  removeMaterialTestFromProject(project: Project, materialTest: MaterialTest) {
    const path = `/v1/project/${project.id}/material-test/${materialTest.id}`;
    return this.appHttpService.deleteService(path, {});
  }

}

<ng-container *ngIf="initialized">
  <div>&nbsp;</div>
  <div *ngFor="let ss of siteSummaries">
    <div *ngIf="!isSingleReport" class="mat-subheading-1">SITE {{ss.site.name}}</div>
    <div *ngIf="!isSingleReport && !ss.summary.length">No records</div>
    <div class="v-cards">
      <ng-container *ngFor="let itSummary of ss.summary" [ngTemplateOutlet]="ITSummary"
        [ngTemplateOutletContext]="{ 'it': itSummary.it, 'headings': itSummary.headings, 'site': ss.site }"></ng-container>
    </div>
  </div>
</ng-container>
<ng-template #ITSummary let-it="it" let-headings="headings" let-site="site">
  <div class="card" [ngClass]="{'active':  !it.record_by_station}" (click)="!it.record_by_station && openReportInternalTest(it, headings[0]?.heading, site)">
    <div class="mat-subheading-2">{{it.name}}</div>
    <div class="sub-item" *ngFor="let hs of headings">
      <div class="caption" (click)="openReportInternalTest(it, hs.heading, site)">
        {{hs.heading?.name}}
        <ng-container *ngIf="hs.rows">({{hs.rows}})</ng-container>
      </div>
    </div>
  </div>
</ng-template>
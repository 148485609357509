import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-labor-block',
  templateUrl: './labor-block.component.html',
  styleUrls: ['./labor-block.component.scss', '../../styles.css'],
})
export class LaborBlockComponent implements OnInit{
  @Input() data: any;

  fullName = '';
  jobTitle = '';
  bidItem = '';


  ngOnInit() {
    this.fullName = `${this.data.labor.first_name} ${this.data.labor.last_name}`;
    this.jobTitle = this.data.job_code.name;
    this.bidItem = `${this.data.bid_item.item} ${this.data.bid_item.description ? `(${this.data.bid_item.description})` : ''}`;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../../../../shared/services/map.service';
import { DateFormatPipe } from '../../../../../shared/pipes';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { projectDetails } from '../../../core/projects.selectors';

@Component({
  selector: 'app-sites-location-map',
  templateUrl: './sites-location-map.component.html',
  styleUrls: ['./sites-location-map.component.scss'],
})
export class SitesLocationMapComponent implements OnInit, OnDestroy {
  isFullScreen = false;
  mapComponentInstance: any;
  markersList: any[] = [];
  sites: any[] = [];
  filteredSites: any[] = [];
  showTooltip = false;
  tooltipContent = '';
  screenPoint = {
    x: 0,
    y: 0,
  };

  prevTab = '';

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private mapService: MapService,
    private dateFormatPipe: DateFormatPipe,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;

        if (qp.tab !== 'sites' && qp.tab !== this.prevTab) {
          this.filteredSites = this.sites;
          this.initializeMap();
        }

        if (this.prevTab !== qp.tab) {
          this.prevTab = qp.tab;
        }
      });
  }

  ngOnInit() {
    this.mapService.initiateSiteLocationsMap
      .pipe(takeUntil(this.onDestroy))
      .subscribe(d => {
        let update = false;

        if (JSON.stringify(this.filteredSites) !== JSON.stringify(d)) {
          update = true;
        }

        if (d.length) {
          this.filteredSites = d;
        } else {
          this.filteredSites = this.sites;
        }

        if (update) {
          this.initializeMap();
        }
      });
  }

  initializeMap() {
    // while creating marker, add level = 2
    // this is needed for the esri map component to show tooltip
    this.markersList = this.filteredSites?.map(site => {
      const location = {
        longitude: site.longitude,
        latitude: site.latitude,
      };

      const data = {
        ...site,
        level: 2,
      };

      return this.mapService.createMarker(location, data, 'site');
    });

    this.drawPointsOnMap();
  }

  onMarkerClick($event: any) {

  }

  onShowTooltip($event: any) {

  }

  onMapLoaded($event: any) {

  }

  onMapComponentLoaded($event: any) {
    this.mapComponentInstance = $event;
    this.drawPointsOnMap();
  }

  onViewLoaded($event: any) {

  }

  drawPointsOnMap() {
    if (this.mapComponentInstance) {
      this.mapService.removeAllMarkers(this.mapComponentInstance);
    }

    if (this.mapComponentInstance && this.markersList.length) {
      // Clear all markers previously drawn on the map
      this.mapService.removeAllMarkers(this.mapComponentInstance);

      // Draw markers on the map
      this.mapComponentInstance?.drawProjectSiteMarkers(this.markersList);

      // Reset the zoom level to get all points on available screen
      this.mapService.setExtent(this.mapComponentInstance, this.markersList, 1.2);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }

  protected _onShowTooltip($event) {
    if ($event) {
      this.showTooltip = true;
      const category = $event?.graphic?.attributes?.category;

      const data = {
        ...$event?.graphic?.attributes?.data,
        markerCategory: category,
      };

      this.screenPoint = $event?.screenPoint;

      const site = $event?.graphic?.attributes?.data;

      this.tooltipContent = `<span class="text-type-15 tooltip-bold" style="color: #060b1e;">${site.site_name}</span> <span class="text-type-15 tooltip-light" style="font-weight: 400;">(${this.dateFormatPipe.transform(site.last_updated_at, 'ago')} ago)</span>`;
    } else {
      this.showTooltip = false;
    }
  }
}

import { BaseModel } from './base-model';

export class IssueSummary extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'IssueSummary'; }

  /* tslint:disable */
  total: number;
  open: number;
  opened_today: number;
  closed: number;
  closed_today: number;
  /* tslint:enable */

  /**
   * Workaround to initialize default values
   */
  init() {
    this.total = 0;
    this.open = 0;
    this.opened_today = 0;
    this.closed = 0;
    this.closed_today = 0;
  }

}

import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ProjectMapService,
} from '../../../../modules/project/project-map/project-map.service';
import {MapService} from '../../../services/map.service';

@Component({
  selector: 'map-popup-wrapper',
  templateUrl: './map-popup-wrapper.component.html',
  styleUrls: ['./map-popup-wrapper.component.scss'],
})
export class MapPopupWrapperComponent implements OnInit, OnDestroy,
                                                 AfterViewInit {
  @Input() popupContent: any[];
  @Input() screenPoint: { x: number, y: number };
  @Input() showPopup: boolean;
  @Output() showPopupChange = new EventEmitter<boolean>();
  @Output() seeMore = new EventEmitter<any>();

  @ViewChild('popupContentElement') popupContentElement: ElementRef;

  x = 0;
  y = 0;

  componentTypeOneList = [
    'bid_items',
    'deliveries',
    'issues',
    'quality_test',
    'activities',
  ];

  componentTypeTwoList = [
    'labor_equipment',
  ];

  constructor(
    private mapService: MapService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {}

  ngOnDestroy() {}

  show() {
    this.showPopupChange.emit(true);
  }

  hide() {
    this.showPopupChange.emit(false);
  }

  toggle() {
    this.showPopupChange.emit(!this.showPopup);
  }

  @HostListener('document:mousedown', ['$event'])
  handleOutsideClick(event) {
    const popupRect = this.popupContentElement.nativeElement.getBoundingClientRect();
    const isInsidePopup =
      event.button === 0 &&
      event.clientX >= popupRect.left &&
      event.clientX <= popupRect.right &&
      event.clientY >= popupRect.top &&
      event.clientY <= popupRect.bottom;
    if (!isInsidePopup) {
      this.hide();
    }
  }

  allowTypeOne(type: string) {
    return this.componentTypeOneList.includes(type);
  }

  allowTypeTwo(type: string) {
    return this.componentTypeTwoList.includes(type);
  }

  onMoreClick(event: any) {
    this.mapService.seeMore.next({ type: event.type, ...event.data.markerData });
    this.hide();
  }

  ngAfterViewInit() {
    // Adjust popup location based on position of the map

    const map = document.getElementById('projectMap');
    const popup = this.popupContentElement.nativeElement.getBoundingClientRect();

    const { x, y } = this.screenPoint;
    const { height, width } = map.getBoundingClientRect();

    if (height - y > popup.height) {
      this.y = y;
    } else {
      this.y = (height - popup.height) - 20;
    }

    if (width - x > popup.width) {
      this.x = x + 20;
    } else {
      this.x = (x - popup.width) - 20;
    }

    this.cdRef.detectChanges();
  }
}

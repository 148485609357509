import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, Material, Project } from 'src/app/shared/models';
import { ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-material-biditem-selector',
  templateUrl: './material-biditem-selector.component.html',
  styleUrls: ['./material-biditem-selector.component.scss']
})
export class MaterialBiditemSelectorComponent implements OnInit {
  @Input() project: any;
  @Input() selectedEntity: BidItem | Material;
  @Input() type: string;

  private isDialog: boolean = false;
  public loading: number = 0;
  public initialized: boolean = false;
  public biditems: BidItem[] = [];
  public materials: Material[] = [];

  constructor(
    private projectSrv: ProjectService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MaterialBiditemSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any
  ) { }

  get filteredMaterials(): Material[] {
    if (this.type === 'usage') {
      return (this.materials || []).filter(o => o.track_used);
    }
    return (this.materials || []).filter(o => o.track_received);
  }

  get filteredBidItems(): BidItem[] {
    if (this.type === 'usage') {
      return this.biditems.filter(o => (o.materials || []).filter(p => p.track_used).length);
    }
    return this.biditems;
  }

  ngOnInit(): void {
    const { project, selectedEntity, type = 'delivery', options = {} } = this.inputData;
    const { fetchData } = options;
    if (project || selectedEntity) {
      this.isDialog = true;
      this.project = project;
      this.type = type;
      this.selectedEntity = selectedEntity;
    }

    if (fetchData) {
      this.fetchData();
    } else {
      const { materials, bid_items } = this.project;
      this.materials = materials;
      this.biditems = bid_items;
      this.initialized = true;
    }
  }

  fetchData(): void {
    this.loading++;
    const include = [
      { bid_items: ['materials'] },
      'materials'
    ];

    this.projectSrv.getRecord(this.project.id, { include }).toPromise()
      .then((project: Project) => {
        this.biditems = project.bid_items;
        this.materials = project.materials;
      })
      .catch(e => {
        this.snackBar.open(
          e.message || 'Oops! something went wrong',
          '',
          { duration: 5000 }
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      })
  }

  onSelected(entity: BidItem | Material): void {
    if (this.isDialog) {
      this.dialogRef.close(entity);
    }
  }

}

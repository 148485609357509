import { BaseModel } from './base-model';

export class Lookup extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Lookup'; }

  /* tslint:disable */
  type: string;
  value: string;
  /* tslint:enable */
}

<mat-tab-group *ngIf="initialized">
  <mat-tab label="Delivery">
    <div>&nbsp;</div>
    <ng-container matTabContent *ngTemplateOutlet="DeliverySummary"></ng-container>
  </mat-tab>
  <mat-tab label="Usage">
    <div>&nbsp;</div>
    <ng-container matTabContent *ngTemplateOutlet="UsageSummary"></ng-container>
  </mat-tab>
</mat-tab-group>


<ng-template #DeliverySummary>
  <div *ngFor="let site of sites">
    <div *ngIf="!isSingleReport" class="mat-subheading-1">SITE {{site.name}}</div>
    <div class="cards-container">
      <div class="mat-subheading-1"
        *ngIf="!isSingleReport && !site.delivery.bs.length && !site.delivery.ms.length">
        Found no data
      </div>

      <div class="v-cards">

        <!-- Materials summary -->
        <div class="card link" *ngFor="let ms of site.delivery.ms"
          (click)="openRMD(ms.rmd, 'delivery')">
          <div class="mat-subheading-2">{{ms.material.name}}</div>
          <div class="sub-item">
            <div class="caption">
              Received<span *ngIf="ms.material.uom"> ({{ms.material.uom}})</span>:
              {{ms.rmd?.received}}
            </div>
          </div>
        </div>
        <!-- /Materials summary -->

        <!-- Biditems summary -->
        <div class="card link" *ngFor="let bs of site.delivery.bs"
          (click)="openRMD(bs.headerItem, 'delivery')">
          <div class="mat-subheading-2">{{bs.biditem.item}}</div>
          <div class="caption">{{bs.biditem.description}}</div>
          <div class="caption muted" *ngIf="bs.biditem.uom">UOM: ({{bs.biditem.uom}})</div>

          <div class="sub-item" *ngFor="let li of bs.lineItems">
            <div class="mat-body-strong">{{li.material?.name}}</div>
            <div class="caption">
              Received<span *ngIf="li.material?.uom"> ({{li.material.uom}})</span>:
              {{li.received}}
            </div>
          </div>

          <div class="sub-item">
            <div class="caption" *ngIf="bs.headerItem">
              QTY<span *ngIf="bs.biditem.uom"> ({{bs.biditem.uom}})</span>:
              {{bs.headerItem?.quantity}}
            </div>
          </div>
        </div>
        <!-- /Biditems summary -->
      </div>

    </div>
  </div>
</ng-template>

<ng-template #UsageSummary>

  <div *ngFor="let site of sites">
    <div *ngIf="!isSingleReport" class="mat-subheading-1">SITE {{site.name}}</div>
    <div class="cards-container">
      <div class="mat-subheading-1"
        *ngIf="!isSingleReport && !site.usage.bs.length && !site.usage.ms.length">
        Found no data
      </div>

      <div class="v-cards">

        <!-- Materials summary -->
        <div class="card link" *ngFor="let ms of site.usage.ms" (click)="openRMD(ms.rmd, 'usage')">
          <div class="mat-subheading-2">{{ms.material.name}}</div>
          <div class="sub-item">
            <div class="caption">
              Used<span *ngIf="ms.material.uom"> ({{ms.material.uom}})</span>:
              {{ms.rmd?.used}}
            </div>
          </div>
        </div>
        <!-- /Materials summary -->

        <!-- Biditems summary -->
        <div class="card link" *ngFor="let bs of site.usage.bs" (click)="openRMD(bs.lineItems[0], 'usage')">
          <div class="mat-subheading-2">{{bs.biditem.item}}</div>
          <div class="caption">{{bs.biditem.description}}</div>
          <div class="caption muted" *ngIf="bs.biditem.uom">UOM: ({{bs.biditem.uom}})</div>

          <div class="sub-item" *ngFor="let li of bs.lineItems">
            <div class="mat-body-strong">{{li.material?.name}}</div>
            <div class="caption">
              Used<span *ngIf="li.material?.uom"> ({{li.material.uom}})</span>:
              {{li.used}}
            </div>
          </div>
        </div>
        <!-- /Biditems summary -->
      </div>

    </div>
  </div>
</ng-template>
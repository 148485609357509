<div *ngIf="initialized">
  <h1>Select Bid item</h1>
  <div>
    <div *ngIf="!bidItems.length" class="mat-body-strong">No Bid items found for this project.</div>
    <div class="cards-container">
      <ng-container *ngFor="let bidItem of bidItems" >
        <div class="stroked-card"
          (click)="onSelected(bidItem)"
          [class.selected]="selectedBidItem?.id === bidItem.id">
          <div class="">{{bidItem.item}}</div>
          <div class="">{{bidItem.uom}}</div>
          <div class="">{{bidItem.unit_price}}</div>
          <div class="">{{bidItem.description}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Account, Issue, IssueType, Project } from 'src/app/shared/models';
import { ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-issue-card',
  templateUrl: './issue-card.component.html',
  styleUrls: ['./issue-card.component.scss']
})
export class IssueCardComponent implements OnInit {

  public initialized = false;
  @Input() public issue: Issue;
  @Input() public project: Project;
  @Input() public type = true;
  @Input() public status = true;
  @Input() public internal = true;

  public account: Account;
  public issueTypes: IssueType[] = [];

  constructor(
    private projectSrv: ProjectService
  ) { }

  ngOnInit(): void {
    if (this.issue.createdBy) {
      this.account = this.issue.createdBy;
    }
    this.fetchIssueTypes(this.project);
    this.initialized = true;
  }

  /**
   * Fetches IssueType[]
   * @param project Project
   */
  fetchIssueTypes(project: Project): void {
    this.projectSrv.getIssueTypes(project)
      .then((issueTypes: IssueType[]) => {
        this.issueTypes = issueTypes;
      });
  }

  issueTypeLabel(type) {
    return (this.issueTypes || []).find(o => o?.type === type)?.label || type;
  }

}

'use strict';

import { UntypedFormGroup } from '@angular/forms';
import { Util } from '../helpers';

export abstract class BaseModel {
  /* tslint:enable */

  get modelName() {
    return this.constructor['modelName'];
  }

  get relations() {
    return {};
  }

  /**
   * Creates an object
   * @param jsonObj api response in json format
   */
  constructor(jsonObj = null) {
    if (jsonObj) {
      this.parseJSONData(jsonObj);
    }
  }

  get isDirty(): boolean {
    // new item
    if (!this.id) {
      // if new entity is added and not touched, ignore it
      if (this?._inputForm.touched === false) {
        return false;
      }
      return true;
    }

    if (this._inputForm) {
      for (const [k, fc] of Object.entries(this._inputForm?.controls || {})) {

        // The keys mentioned here are added for the purpose of maintaining
        // and controlling the state of _inputForm in the view section and
        // has no bearing to the calculation of weather the form is dirty or
        // not. Hence, we are skipping those keys here.
        if ([
          'isEditable',
          'isManual',
          'initial'
        ].includes(k)) {
          continue;
        }

        if (
          k === 'quantity' &&
          this[k] === 0 &&
          !this._inputForm.controls.isManual.value &&
          this._inputForm.controls.quantity.value &&
          !this._inputForm.dirty
        ) {
          return false;
        }

        let mv = k in this
                 ? this[k]
                 : this.getFieldValue(k, {getDirty: false});
        let fv = typeof fc.value === 'object' && fc.value?.id ? fc.value.id : fc.value;

        if (k === 'record_time') {
          fv = fv += ':00';
        }

        mv = Util.getNonEmpty(mv);
        fv = Util.getNonEmpty(fv);

        if (mv !== fv) {
          return true;
        }
      }

      if (this.__propertyChanges?.length) {
        return true;
      }
    }

    return false;
  }
  id: string;
  /* tslint:disable */
  created_at: Date | any;
  updated_at: Date | any;
  createdBy: any;
  updatedBy: any;
  created_by: string;
  updated_by: string;

  _id: string;
  field_values: any[];
  _inputForm: UntypedFormGroup;

  private __propertyChanges: any = [];

  /**
   * Workaround to initialize default values
   */
  init() {}

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    this.init();
    this.id = jsonObj?.id;

    // Assign attributes to this
    for (const key of Object.keys(jsonObj)) {
      if (jsonObj.hasOwnProperty(key)) {
        if (key in this.relations) {
          // If relation exists on key, create respective class object
          this[key] = Array.isArray(jsonObj[key])
            ? jsonObj[key].map(o => new this.relations[key](o))
            : jsonObj[key]
            ? new this.relations[key](jsonObj[key])
            : null;
        } else {
          this.setValue(key, jsonObj[key])
        }
      }
    }
    // Convert date strings to date objects
    this.created_at = this.created_at ? new Date(this.created_at) : null;
    this.updated_at = this.updated_at ? new Date(this.updated_at) : null;
    this.created_by = this.createdBy?.id || null;
    this.updated_by = this.updatedBy?.id || null;
  }

  getFieldValue (field: string|any, options: any = {}): any {
    const { getDirty = false } = options;
    const fieldId = typeof field === 'string' ? field : field?.id || '';

    const v = (this.field_values || []).find(o => o.id === fieldId)?.value;

    if (getDirty && this._inputForm) {
      const fv = this._inputForm?.controls[field?.id]?.value;
      return v === fv ? v : fv;
    }

    return v;
  }

  getFieldValueSummary(fields): string {
    const s = fields.map(f => {
      const v = this.getFieldValue(f);
      return Util.isEmpty(v) ? '' : `${v}${f.uom || ''}`.trim();
    }).filter(o => !!o);
    return s.join(', ');
  }
  public setValue(key: string, value: any): void {
    if (Util.serialize(this[key]) !== Util.serialize(value)) {
      this.__propertyChanges.push({ key, value });
      this[key] = value;
      return;
    }
  }
}


export class Location {
  lat: string;
  lng: string;

  constructor(lat = null, lng = null) {
    this.lat = lat;
    this.lng = lng;
  }

  get isEmpty() {
    return !(this.lat && this.lng);
  }

  toString() {
    return `${this.lat},${this.lng}`;
  }
}

<div class="page-header">
  <h1 class="mat-display-1">Projects</h1>
  <button
    mat-flat-button
    [disabled]="!('create' | able: 'Project')"
    color="accent"
    (click)="addProject()"
    aria-label="Add Project"
    title="Add Project"
  >
    Add project
  </button>
</div>

<div *ngIf="initialized && projects.length == 0">
  <h2>No records match the criteria.</h2>
</div>

<mat-spinner class="spinner" *ngIf="loading" color="accent" mode="indeterminate"></mat-spinner>

<div class="list">
  <div *ngFor="let project of projects">
    <!-- Project card -->
    <app-project-card
      [class.stripe-left]="project.ended"
      [attr.stripe-text]="project.ended ? 'closed': null"
      [project]="project"
      [showProgress]="true"
      picHeight="150px"
      (updated)="onProjectChange($event)">
    </app-project-card>
    <!-- Project card -->

    <!-- Project submittals -->
    <!-- Commented out as per Pavan's request
    <section *ngIf="'read' | able: project._project_submittal">
      <div class="mat-subheading-1">Submittals</div>
      <app-project-submittals-summary [project]="project"></app-project-submittals-summary>
    </section> -->
    <!-- Project submittals -->

    <!-- Project issues summary -->
    <section class="section" *ngIf="'read' | able: project._project_issue">
      <app-issues-summary entityType="project" [id]="project.id"></app-issues-summary>
    </section>
    <!-- /Project issues summary -->

    <!-- Project Report summaries -->
    <section *ngIf="'read_summary' | able: project._project_report">
      <div class="mat-subheading-1">Project Reports</div>
      <app-latest-project-report-summaries [project]="project" [days]="5"></app-latest-project-report-summaries>
    </section>
    <!-- /Project Report summaries -->

    <!-- Todo convert project-status-summary and latest-project-report-summaries into single component -->
    <!-- Project daily-report status -->
    <section *ngIf="('read' | able: project._daily_report)">
      <div class="mat-subheading-1">Status</div>
      <app-project-status-summary [project]="project" days="5"></app-project-status-summary>
    </section>
    <!-- /Project daily-report status -->

    <!-- <div class="mat-h4">Rfis &amp; submittals</div>
    <div class="mat-body">Awaiting approval (1 you & 2 others, 5 completed)</div> -->
  </div>
</div>

<mat-paginator
  (page)="pageChanged($event)"
  *ngIf="initialized && pagination.length"
  [length]="pagination.length"
  [pageIndex]="pagination.pageIndex"
  [pageSize]="pagination.pageSize"
>
</mat-paginator>

<ng-container *ngIf="initialized">
  <!-- Project actions -->
  <div class="hover-icons" *ngIf="showControls">
    <div class="hover-icons">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editProject(project)" [disabled]="!('update' | able : project)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <!-- /Project actions -->

  <!-- Project picture -->
  <div class="avatar" *ngIf="project.cover_photo?.id" [style.height]="picHeight" [style.width]="picHeight">
    <img [src]="project.cover_photo?.thumb_url" alt="{{ project.name }} picture" />
  </div>
  <!-- Project picture -->

  <div class="body">
    <!-- Project title -->
    <section class="title link" [routerLink]="['/project', project.id]">
      <div class="mat-headline">{{ project.name }}</div>
      <div class="mat-subheading-1">{{ project.number }}</div>
    </section>
    <!-- Project title -->

    <!-- Project org -->
    <section *ngIf="project.owner_organization?.id">
      <div class="mat-title">{{ project.owner_organization?.name }}</div>
    </section>
    <!-- Project org -->

    <!-- Project status: duration/value progress -->
    <section class="progress-container" *ngIf="showProgress">
      <app-progress-card
        heading="Contract duration"
        percent="{{ project.durationProgress }}"
        caption="Used: {{ project.duration_used }} of {{ project.duration }} days"
      ></app-progress-card>

      <app-progress-card
        heading="Contract value"
        percent="{{ project.valueProgress }}"
        caption="Earned: {{ project.earned_value | humanReadable : 1 }} / {{
          project.project_value | humanReadable : 1
        }}"
      >
      </app-progress-card>
    </section>
    <!-- /Project status: duration/value progress -->
  </div>
</ng-container>

import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttpService } from 'src/app/shared/services';
import { AppData } from '../models';


@Injectable({ providedIn: 'root' })
export class AppDataService {

  constructor(
    private appHttpService: AppHttpService
  ) { }


  getRecords(): Promise<AppData[]> {
    return this.appHttpService.getService(`/v2/app-data/all`)
      .toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new AppData(r));
        }
        throw new Error('Error parsing response');
      });
  }

  save(key: string, value: string): Promise<AppData> {
    const payload = {
      value,
    };
    return this.appHttpService
      .putService(`/v2/app-data/${key}`, payload)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new AppData(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  delete(model: AppData): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/app-data/${model?.id}`)
      .toPromise();
  }

  getRecord(key: string, defaultValue: any = []): Promise<AppData[]> {
    return this.appHttpService.getService(`/v2/app-data/${key}`)
      .toPromise()
      .then((resp: any) => {
        const v = resp.data?.value || defaultValue;
        return v;
      });
  }
}

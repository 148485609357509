import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Site } from 'src/app/shared/models';
import { AppErrorStateMatcher, SiteService } from 'src/app/shared/services';
import * as moment from 'moment';
import { LocationPickerComponent } from 'src/app/shared/components/location-picker/location-picker.component';

@Component({
  selector: 'app-site-create',
  templateUrl: './site-create.component.html',
  styleUrls: ['./site-create.component.scss'],
})
export class SiteCreateComponent implements OnInit {
  public loading = 0;
  public initialized = false;
  public editing = false;

  public dialogOptions: any = {};
  public componentResult: any = null;
  @Input() public data: any;
  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;

  public site: Site = new Site();

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    start_date: ['', [Validators.required]],
    end_date: ['', [Validators.required]],
    site_value: ['', [Validators.required]],
    latitude: ['', []],
    longitude: ['', []],
  });

  constructor(
    public dialog: MatDialog,
    private siteService: SiteService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SiteCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) {}

  ngOnInit(): void {
    const { data, editable, options } = this.inputData;
    if (data) {
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = data;
      this.editable = editable;
    }
    this.site = new Site(JSON.parse(JSON.stringify(this.data)));
    this.toReactiveForm(this.site, this.inputForm);

    if (this.editable && !this.site.id) {
      this.editing = true;
    }

    this.initialized = true;
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.editing = false;
    this.site = new Site(JSON.parse(JSON.stringify(this.data)));
  }

  /**
   * Assign base model to FormGroup
   * @param project Project
   * @param form FormGroup
   */
  toReactiveForm(site: Site, form: UntypedFormGroup) {
    form.controls.name.setValue(site.name);
    form.controls.description.setValue(site.description);
    form.controls.start_date.setValue(site.start_date);
    form.controls.end_date.setValue(site.end_date);
    form.controls.site_value.setValue(site.site_value);
    form.controls.latitude.setValue(site.latitude);
    form.controls.longitude.setValue(site.longitude);
  }

  /**
   * Form submit
   */
  onSubmit() {
    if (!this.inputForm.valid) {
      return;
    }

    const body = this.inputForm.value;
    body.project_id = this.site.project_id;
    body.latitude = this.site.latitude;
    body.longitude = this.site.longitude;
    // convert dates to iso-format and calculate duration in days.
    body.start_date = moment(body.start_date).format('YYYY-MM-DD');
    body.end_date = moment(body.end_date).format('YYYY-MM-DD');
    body.duration = moment(body.end_date).diff(moment(body.start_date), 'd') + 1;

    this.loading++;

    const sub = this.site.id
      ? this.siteService.update(this.site.id, body)
      : this.siteService.create(body);

    sub.subscribe({
      next: (resp: any) => {
        this.loading--;
        this.site = resp;
        this.componentResult = this.site;
        this.editing = false;
      },
      error: (resp: any) => {
        this.loading--;
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }

        if (resp.error.error) {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        }
      },
    });
  }

  /**
   * Open location to edit
   * @param site Site
   */
  editLocation(site: Site): void {
    site = site || new Site({});

    const dialogRef = this.dialog.open(LocationPickerComponent, {
      disableClose: true,
      data: {
        latitude: site.latitude,
        longitude: site.longitude,
        readOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && 'latitude' in resp && 'longitude' in resp) {
        this.site.latitude = null;
        this.site.longitude = null;
        setTimeout(() => {
          this.site.latitude = resp.latitude;
          this.site.longitude = resp.longitude;
        }, 500);
      }
    });
  }

}

import { BaseModel, Location } from './base-model';
import { Attachment } from '.';
import { Annotation } from './annotation';
import { Address } from './address';

export class ReportActivity extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'ReportActivity'; }

  /* tslint:disable */
  title: string;
  description: string;
  latitude: string;
  longitude: string;
  address: Address;
  pictures: Attachment[];
  annotations: Annotation[];

  address_id: string;
  site_id: string;
  daily_report_id: string;
  __deleted: boolean;
  location: Location;
  /* tslint:enable */

  get relations() {
    return {
      address: Address,
      pictures: Attachment,
      annotations: Annotation,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.annotations = this.annotations || [];
    this.pictures = this.pictures || [];
    this.location = new Location(this.latitude, this.longitude);
    this._id = Math.random().toString(16).substring(2);
  }

  /**
   * Converts this model to POST payload json object
   * @param include boolean Include line-items or not
   * @return {object}
   */
  toPayload(values: any = {}) {
    return {
      id: this.id || null,
      title: values?.title || this.title || null,
      description: values?.description || this.description || null,
      latitude: values?.latitude || this.location?.lat || '',
      longitude: values?.longitude || this.location?.lng || '',
      pictures: (this.pictures || []).map(o => o.id),
      annotations: (this.annotations || []).map(o => o.id),
    };
  }
}

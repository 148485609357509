import { BaseModel, Account, Project } from 'src/app/shared/models';

export class SubmittalLog extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() { return 'SubmittalLog'; }

  /* tslint:disable */
  section_no: string;
  sub_section_no: string;
  title: string;
  description: string;
  project: Project;
  createdBy: Account;
  updatedBy: Account;

  // acl assist variables
  project_id: string;
  /* tslint:enable */

  init() {}

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      project: Project,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // add few attributes to assist acl module
    this.project_id = this.project?.id || this.project_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      section_no: this.section_no,
      sub_section_no: this.sub_section_no,
      title: this.title,
      description: this.description,
    };
    return payload;
  }
}



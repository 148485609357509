<div class="container">
  <ng-container *ngIf="initialized">

    <div class="mat-body-strong" *ngIf="!internalTests.length">
        This project has no internal tests.
    </div>

    <div *ngFor="let internalTest of internalTests">
      <mat-checkbox
        [checked]="internalTest.checked"
        (change)="onChange(internalTest, $event.checked)">
        <div class="mat-body-strong">
          {{internalTest.name}}
          <span class="mat-caption" *ngIf="internalTest.record_by_station">(by station)</span>
        </div>

      </mat-checkbox>
    </div>
  </ng-container>

</div>


<div class="wrapper flex flex-col gap-[20px]">
  <div class="heading text-type-1">
    Create daily report
  </div>
  <form class="flex flex-col gap-[calc(40px-1.25em)]">
    <app-dropdown
      [parentFormGroup]="form"
      [options]="projectsList"
      controlName="project"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['project']"
      label="Project"
      placeholder="Select a project"
      [enableClearSelection]="false"
    />
    <app-dropdown
      [parentFormGroup]="form"
      [options]="sitesList"
      controlName="site"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['site']"
      label="Site"
      placeholder="Select a site"
      [enableClearSelection]="false"
      [enableSearch]="true"
    />
    <div>
      <app-date-picker
        [parentFormGroup]="form"
        controlName="date"
        (checkForErrors)="checkForErrors($event)"
        label="Date Picker"
        placeholder="Select a date"
        class="datepicker"
        [class.reduce-padding]="existingReportId"
      />
      <div
        *ngIf="existingReportId"
        class="report-exists flex"
      >
        <mat-icon svgIcon="error" class="error-icon"/>
        <div class="error-message text-type-14">
          A report is created by you for this date. Select a different date or
          <span class="clickable" (click)="goToReport()">go to report</span>
        </div>
      </div>
    </div>
    <app-dropdown
      [parentFormGroup]="form"
      [options]="dayTypeList"
      controlName="dayType"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['dayType']"
      label="Day Type"
      placeholder="Select a day type"
      [enableClearSelection]="false"
    />
    <app-text-area
      *ngIf="showNote"
      [parentFormGroup]="form"
      controlName="note"
      label="Add a short note"
    />
  </form>
  <div class="actions mt-[calc(100px-1.25em)]">
    <app-primary-button
      [isDisabled]="!form.valid"
      (handleClick)="onSubmit()"
    >
      Create
    </app-primary-button>
  </div>
</div>

<ng-container *ngIf="initialized && actions.length">
  <form [formGroup]="inputForm">
    <div class="form-inputs">

      <!-- Render Dynamic form fields -->
      <mat-form-field *ngFor="let formInput of formInputs">
        <mat-label>{{formInput.label || formInput.name}}</mat-label>
        <ng-container [ngSwitch]="formInput.type">

          <textarea *ngSwitchCase="'text'" matInput [formControlName]="formInput.name" [errorStateMatcher]="matcher">
          </textarea>

        </ng-container>

        <mat-error *ngIf="inputForm.controls[formInput.name].hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls[formInput.name].hasError('error')">
          {{inputForm.controls[formInput.name].errors.error}}
        </mat-error>

      </mat-form-field>
      <!-- /Render Dynamic form fields -->

      <div class="form-actions">
        <button *ngFor="let action of actions" mat-flat-button [color]="action.options?.styles?.color"
          (click)="takeAction(action)">{{action.options?.label || action.state | titlecase}}</button>
      </div>

    </div>
  </form>

</ng-container>

import { BaseModel, Location } from './base-model';
import { Attachment } from './attachment';
import { BidItem } from './bid-item';
import { Annotation } from './annotation';
import { Heading } from './heading';
import { Station } from './station';
import { Field } from './field';
import { FormGroup } from '@angular/forms';

export class ReportBidItem extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'ReportBidItem'; }

  /* tslint:disable */
  comment: string;
  quantity: number;
  latitude: string;
  longitude: string;
  start_of_work: boolean;
  station_break: boolean;
  pictures: Attachment[];
  bid_item: BidItem;
  annotations: Annotation[];
  field_values: any[];
  heading: Heading;
  station: Station;

  bid_item_id: string;
  site_id: string;
  daily_report_id: string;
  station_id: string;
  heading_id: string;
  __deleted: boolean;
  location: Location;

  _isHeader: boolean;
  /* tslint:enable */

  get relations() {
    return {
      pictures: Attachment,
      bid_item: BidItem,
      annotations: Annotation,
      heading: Heading,
      station: Station,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.annotations = this.annotations || [];
    this.pictures = this.pictures || [];
    this.location = new Location(this.latitude, this.longitude);
    this._id = Math.random().toString(16).substring(2);

    this.station_id = this.station?.id || this.station_id;
    this.heading_id = this.heading?.id || this.heading_id;
    this.bid_item_id = this.bid_item?.id || this.bid_item_id;
  }

  /**
   * Converts this model to POST payload json object
   * @param include boolean Include line-items or not
   * @return {object}
   */
  toPayload(values: any = {}, fields: Field[] = []) {
    return {
      id: values?.id || this.id || null,
      quantity: values?.isManual ? (values?.quantity || this.quantity) : '',
      comment: values?.comment || this.comment || '',
      latitude: values?.latitude || this.location?.lat || '',
      longitude: values?.longitude || this.location?.lng || '',
      bid_item_id: values?.bid_item_id || this.bid_item?.id || this.bid_item_id || null,
      pictures: (values?.pictures || this.pictures || []).map(o => o.id),
      annotations: (values?.annotations || this.annotations || []).map(o => o.id),
      field_values: fields.map((f) => ({ id: f.id, value: values[f.id] || null })),
      heading_id: this.heading?.id || null,
      station_id: this.station?.id || null,
      station_break: this.station_break || false,
      start_of_work: this.start_of_work || false,
    };
  }

  get thumbUrl() {
    const { annotations, pictures } = this;
    if (annotations.length && annotations[0].thumb_url) {
      return annotations[0].thumb_url;
    }
    if (pictures.length) {
      return pictures[0].thumb_url;
    }
    return '/assets/default.jpg';
  }
}

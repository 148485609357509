import { Injectable } from '@angular/core';
import { AppHttpService } from 'src/app/shared/services';
import { Project, Site } from 'src/app/shared/models';
import { SiteMaterialTest } from '../models';
import {Subject} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteMaterialTestService {

  fetchSiteMaterialTests: Subject<null> = new Subject();

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns list of Records
   * @param pagination pagination
   * @param qp query params
   */
  getRecords(type: string = 'site', entity: any, pagination, qp: any = {}): Promise<any> {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/site-material-test/${type}/${entity.id}`;
    return this.appHttpService.getService(path, qp).toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new SiteMaterialTest(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Saves record
   * @param smt SiteMaterialTest
   * @param qp query-params
   * @return {Promise<SiteMaterialTest>}
   */
  save(smt: SiteMaterialTest, payload: any = null, qp: any = {}): Promise<SiteMaterialTest> {

    const body = payload || smt.toPayload();
    return this.appHttpService.postService(`/v1/site-material-test/${smt.site.id}/draft`, body, qp).toPromise()
      .then(resp => {
        return new SiteMaterialTest(resp.data);
      });
  }

  /**
   * Send SiteMaterialTest to lab
   * @param smt SiteMaterialTest
   * @param qp query-params
   * @returns {Promise<SiteMaterialTest}
   */
  sendToLab(smt: SiteMaterialTest, qp: any = {}): Promise<SiteMaterialTest> {
    return this.appHttpService.postService(`/v1/site-material-test/${smt.id}/send-to-lab`, {}, qp).toPromise()
      .then((resp: any) => {
        return new SiteMaterialTest(resp.data);
      });
  }

  /**
   * Accept Lab schedule
   * @param smt SiteMaterialTest
   * @param qp query-params
   * @returns {Promise<SiteMaterialTest}
   */
  acceptLabSchedule(smt: SiteMaterialTest, qp: any = {}): Promise<SiteMaterialTest> {
    return this.appHttpService.postService(`/v1/site-material-test/${smt.id}/accept-schedule`, {}, qp).toPromise()
      .then((resp: any) => {
        return new SiteMaterialTest(resp.data);
      });
  }

  /**
   * Record lab activity
   * @param smt SiteMaterialTest
   * @param payload MaterialTestInspection
   * @param qp query-params
   * @returns {Promise<SiteMaterialTest}
   */
  recordLabActivity(smt: SiteMaterialTest, payload: any, qp: any = {}): Promise<SiteMaterialTest> {
    return this.appHttpService.postService(`/v1/site-material-test/${smt.id}/record`, payload, qp).toPromise()
      .then((resp: any) => {
        return new SiteMaterialTest(resp.data);
      });
  }

  /**
   * Update test report
   * @param smt SiteMaterialTest
   * @param payload MaterialTestInspection
   * @param qp query-params
   * @returns {Promise<SiteMaterialTest}
   */
  updateTestReport(smt: SiteMaterialTest, payload: any, qp: any = {}): Promise<SiteMaterialTest> {
    return this.appHttpService.postService(`/v1/site-material-test/${smt.id}/report`, payload, qp).toPromise()
      .then((resp: any) => {
        return new SiteMaterialTest(resp.data);
      });
  }

  /**
   * Update test result
   * @param smt SiteMaterialTest
   * @param payload object
   * @param qp query-params
   * @returns {Promise<SiteMaterialTest}
   */
  updateTestResult(smt: SiteMaterialTest, payload: any, qp: any = {}): Promise<SiteMaterialTest> {
    return this.appHttpService.postService(`/v1/site-material-test/${smt.id}/${payload.result}`, payload, qp).toPromise()
      .then((resp: any) => {
        return new SiteMaterialTest(resp.data);
      });
  }
}

import { HttpClient, HttpRequest, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { environment as ENV } from 'src/environments/environment';
import { Attachment } from 'src/app/shared/models/attachment';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {

  /** link text */
  @Input() text = 'Upload';
  /** Name used in form which will be sent */
  @Input() param = 'inputFiles';
  /** target path */
  @Input() target = `${ENV.API_ENDPOINT}/v1/attachment`;

  @Input() accept = 'image/*';
  @Input() multiple = false;
  @Input() color = 'accent';
  @Input() icon: string;
  @Input() targetClass: any;
  @Input() disabled = false;
  @Input() httpMethod: string = 'POST';
  @Input() formData: any = {};

  @Output() complete = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();

  public files: Array<FileUploadModel> = [];

  @ViewChild('fileInput') fileInputRef: ElementRef;

  constructor(
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
  }

  onClick() {
    const fileUploadInput = this.fileInputRef.nativeElement as HTMLInputElement;

    this.files = [];

    fileUploadInput.onchange = () => {
      for (let i = 0; i < fileUploadInput.files.length; ++i) {

        const file = fileUploadInput.files.item(i);
        this.files.push({
          data: file,
          state: 'in',
          inProgress: false,
          progress: 0,
          canRetry: false,
          canCancel: false,
        });

      }
      this.uploadFiles();
    };

    fileUploadInput.click();
  }

  private uploadFile(file: FileUploadModel) {
    const fd = new FormData();
    fd.append(this.param, file.data);

    // add other form data from object
    this.serializeFormData(this.formData).split('&').map(o => {
      const [k, v] = o.split('=');
      fd.append(k, decodeURIComponent(v));
    });


    const req = new HttpRequest(this.httpMethod, this.target, fd, {
      reportProgress: true
    });
    req.headers.set('Content-Type', 'multipart/form-data');

    file.inProgress = true;
    file.sub = this.http.request(req)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              file.progress = Math.round(event.loaded * 100 / event.total);
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => { }),
        last(),
        catchError((error: HttpErrorResponse) => {
          file.inProgress = false;
          file.canRetry = true;
          this.files = []; // reset files
          this.onError.emit(error);
          return of(`${file.data.name} upload failed.`);
        })
      ).subscribe(
        (event: any) => {
          if (typeof (event) === 'object') {
            this.removeFileFromArray(file);

            let resp;
            if (!this.targetClass) {
              this.targetClass = Attachment;
            }

            if (event.body.data) {
              resp = Array.isArray(event.body.data)
                ? event.body.data.map(o => new this.targetClass(o))
                : new this.targetClass(event.body.data);
            } else {
              resp = event.body;
            }

            this.complete.emit(resp);
          }
        }
      );
  }

  private removeFileFromArray(file: FileUploadModel) {
    const index = this.files.indexOf(file);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  private uploadFiles() {
    const fileUpload = this.fileInputRef.nativeElement as HTMLInputElement;
    fileUpload.value = '';

    this.files.forEach(file => {
      this.uploadFile(file);
    });
  }

  /**
   * converts object to formdata with arrays
   * @param obj object
   * @param prefix param name, null by default
   */
  serializeFormData(obj, prefix = null): string {
    const str = [];
    for (let [k, v] of Object.entries(obj || {})) {
      if (v === undefined) {
        continue;
      }
      k = prefix ? `${prefix}[${k}]` : k;

      const value = typeof v === 'object'
        ? this.serializeFormData(v, k)
        : `${k}=${encodeURIComponent(String(v))}`;
      str.push(value);

    }
    return str.join('&');
  }
}

export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}

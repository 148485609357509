<div class="wrapper">
  <ng-container
    *ngFor="let x of config"
  >
    <mat-expansion-panel
      *ngIf="nestedLayout; else onlyTime"
      hideToggle
      [(expanded)]="x.isExpanded"
      class="section station-section nested"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="block-heading">
          <div class="header-line-1">
            <div>{{x.heading}}</div>
            <mat-icon
              class="chevron-icon"
              [svgIcon]="x.isExpanded ? 'chevron-up' : 'chevron-down'"
            />
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="section-content">
        <ng-container
          *ngFor="let y of x.children"
        >
          <mat-expansion-panel
            hideToggle
            [(expanded)]="y.isExpanded"
            class="time-section"
          >
            <mat-expansion-panel-header>
              <mat-panel-title class="station block-heading">
                <div class="header-line-1">
                  <div class="child-heading">{{y.heading}}</div>
                  <mat-icon
                    class="chevron-icon"
                    [svgIcon]="y.isExpanded ? 'chevron-up' : 'chevron-down'"
                  />
                </div>
                <div class="child-subheading">{{y.subHeading}}</div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="section values-section">
              <app-view-field
                *ngFor="let field of y.fields"
                class="value-field"
                [label]="field.label"
                [value]="field.value"
                [hint]="field.hint"
                [uom]="field.uom"
              />
            </section>
            <section
              *ngIf="y.pictures?.length"
              class="section">
              <app-attachments-block
                [pictures]="y.pictures"
              />
            </section>
          </mat-expansion-panel>
        </ng-container>
      </div>
    </mat-expansion-panel>
    <ng-template #onlyTime>
      <mat-expansion-panel
        hideToggle
        [(expanded)]="x.isExpanded"
        class="time-section section"
      >
        <mat-expansion-panel-header>
          <mat-panel-title class="station block-heading">
            <div class="header-line-1">
              <div class="child-heading">{{x.heading}}</div>
              <mat-icon
                class="chevron-icon"
                [svgIcon]="x.isExpanded ? 'chevron-up' : 'chevron-down'"
              />
            </div>
            <div class="child-subheading">{{x.subHeading}}</div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <section class="section values-section">
          <app-view-field
            *ngFor="let field of x.fields"
            class="value-field"
            [label]="field.label"
            [value]="field.value"
            [hint]="field.hint"
            [uom]="field.uom"
          />
        </section>
        <section
          *ngIf="x.pictures?.length"
          class="section">
          <app-attachments-block
            [pictures]="x.pictures"
          />
        </section>
      </mat-expansion-panel>
    </ng-template>
  </ng-container>

</div>

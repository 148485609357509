<ng-container *ngIf="initialized">
  <form [formGroup]="inputForm">
    <div class="container">

      <!-- Arrival Time -->
      <section>
        <div class="mat-subheading-1">Time:</div>
        <div class="mat-body" *ngIf="!editing">
          {{materialTestInspection.arrival_time | dateFormat: 'very-long'}} to
          {{materialTestInspection.departure_time | dateFormat: 'hh:mm a'}}
        </div>
        <div class="row" *ngIf="editing">
          <mat-form-field>
            <mat-label>Arrival Time</mat-label>
            <input matInput autocomplete="off" [ngxTimepicker]="fromPicker" [format]="24" formControlName="arrival_time"
              [errorStateMatcher]="matcher" />
            <ngx-material-timepicker #fromPicker></ngx-material-timepicker>
            <mat-error *ngIf="inputForm.controls.arrival_time.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.arrival_time.hasError('error')">
              {{inputForm.controls.arrival_time.errors.error}}
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Departure Time</mat-label>
            <input matInput autocomplete="off" [ngxTimepicker]="toPicker" [format]="24" formControlName="departure_time"
              [errorStateMatcher]="matcher" />
            <ngx-material-timepicker #toPicker></ngx-material-timepicker>
            <mat-error *ngIf="inputForm.controls.departure_time.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.departure_time.hasError('error')">
              {{inputForm.controls.departure_time.errors.error}}
            </mat-error>
          </mat-form-field>
        </div>
      </section>
      <!-- /Time -->

      <!-- Break duration -->
      <section>
        <div class="mat-subheading-1">Break duration (Minutes):</div>
        <div class="mat-body" *ngIf="!editing">{{materialTestInspection.break_duration | durationFormat}}</div>
        <mat-form-field *ngIf="editing">
          <mat-label>Break duration (Minutes)</mat-label>
          <input matInput formControlName="break_duration" [errorStateMatcher]="matcher" autocomplete="off">
          <mat-error *ngIf="inputForm.controls.break_duration.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.break_duration.hasError('error')">
            {{inputForm.controls.break_duration.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /Break duration -->

      <!-- Pictures section -->
      <section>
        <div class="mat-subheading-1">Pictures:</div>
        <div class="preview-list">
          <app-file-uploader class="input-placeholder small" [disabled]="!editing" icon="image" accept="image/*"
            (complete)="onUploadComplete($event)">
          </app-file-uploader>
          <div *ngFor="let pic of materialTestInspection.pictures">
            <img [src]="pic.getThumbnail()" class="link"
              (click)="appSrv.openCarousel($event, materialTestInspection.pictures, pic)" />
            <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
              (click)="removePicture(pic)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
      </section>
      <!-- /Pictures section -->

      <!-- Comment section -->
      <section>
        <div class="mat-subheading-1">Note:</div>
        <div class="mat-body" *ngIf="!editing">{{materialTestInspection.comment}}</div>
        <mat-form-field *ngIf="editing">
          <textarea matInput formControlName="comment" [errorStateMatcher]="matcher"></textarea>
          <mat-error *ngIf="inputForm.controls.comment.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.comment.hasError('error')">
            {{inputForm.controls.comment.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /Comment section -->

      <!-- Form Actions -->
      <section>
        <div class="row" *ngIf="editing">
          <button mat-stroked-button color="accent" (click)="saveItem()">Save</button>
        </div>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
</ng-container>

<div class="filter-button datepicker flex flex-row">
  <div (click)="picker.open()" class="calendar">
    <div class="flex flex-row">
      <div class="label text-type-8">
        {{
          !form.controls.startDate.value && !form.controls.endDate.value ?
            'All Time' :
            form.controls.startDate.value === form.controls.endDate.value ?
              (form.controls.startDate.value | date: 'MMM d') :
              (
                (
                  form.controls.startDate.value &&
                  (form.controls.startDate.value | date: 'MMM d') ||
                  'From'
                )

                + ' - ' +

                (
                  form.controls.endDate.value &&
                  (form.controls.endDate.value |date: 'MMM d') ||
                  'To'
                )
              )
        }}
      </div>
      <mat-icon class="filter-icon" svgIcon="calendar"></mat-icon>
    </div>

  </div>
  <mat-icon *ngIf="form.controls.startDate.value || form.controls.endDate.value" class="filter-icon" (click)="removeDateFilter($event)">
    close
  </mat-icon>

  <form [formGroup]="form" class="h-0">
    <mat-form-field class="date-picker">
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="startDate" />
        <input matEndDate formControlName="endDate" />
      </mat-date-range-input>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</div>

import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ProjectsService} from '../../../core/projects.service';
import {MapService} from '../../../../../shared/services/map.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {SetQPForMap} from '../../../core/projects.actions';
import {qpForMap} from '../../../core/projects.selectors';

@Component({
  selector: 'app-map-wrapper',
  templateUrl: './map-wrapper.component.html',
  styleUrls: ['./map-wrapper.component.scss']
})
export class MapWrapperComponent implements OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  isFullScreen = false;
  existingQueryParams: any = {};
  showButtons = true;

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private projectsService: ProjectsService,
    private mapService: MapService,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;
      });

    this.mapService.showWrapperButtons
      .pipe(takeUntil(this.onDestroy))
      .subscribe(show => {
        this.showButtons = show;
      });

    this.mapService.triggerFullScreenToggle.pipe(takeUntil(this.onDestroy))
      .subscribe(show => {
        if (show) {
          this.openInFullScreen();
        } else {
          this.closeMap();
        }
      });
  }

  zoomIn() {
    this.projectsService.openRightPanel.next({});
  }

  zoomOut() {
    this.projectsService.closeRightPanel.next(null);
  }

  closeMap() {
    this.store.select(qpForMap)
      .subscribe(result => {
        this.router.navigate([], {
          queryParams: result
        });
      }).unsubscribe();
  }

  openInFullScreen() {
    this.store.dispatch(SetQPForMap({
      qpForMap: this.route.snapshot.queryParams
    }));
    this.router.navigate([], {
      queryParams: {
        fs: 1
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ProjectService } from '../../../../../../../shared/services';
import { IssueType, Project } from '../../../../../../../shared/models';
import * as moment from 'moment';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['../../styles.css', './wrapper.component.scss']
})
export class MapSidenavIssuesWrapperComponent implements OnInit {
  @Input() data: any;

  issueTypes;

  heading = '';
  description = '';
  testerName = '';
  testerAvatar = '';
  testTime = '';

  constructor(
    private projectService: ProjectService
  ) {

  }

  ngOnInit() {
    this.projectService.getIssueTypes(new Project({ id: this.data.projectId }))
      .then((issueTypes: IssueType[]) => {
        this.issueTypes = issueTypes;
      });

    this.heading = this.data?.title;
    this.description = this.data?.description;
    this.testerName = `${this.data?.createdBy?.first_name} ${this.data?.createdBy?.last_name}`;
    this.testerAvatar = `${this.data?.createdBy?.first_name?.charAt(0)?.toUpperCase()}${this.data?.createdBy?.last_name?.charAt(0)?.toUpperCase()}`;
    this.testTime = moment(this.data.created_at).format('MMMM DD, YYYY');
  }
}

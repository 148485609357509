import {Component, OnInit} from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar
} from '@angular/material/legacy-snack-bar';
import {ActivatedRoute, Router} from '@angular/router';

import {DailyReport, Project, Site} from 'src/app/shared/models';
import {
  AppService,
  DailyReportService,
  SiteService
} from 'src/app/shared/services';
import {IssueService} from '../../issue/issue.service';

@Component({
  selector: 'app-daily-report',
  templateUrl: './daily-report.component.html',
  styleUrls: ['./daily-report.component.scss']
})
export class DailyReportComponent implements OnInit {

  public initialized = false;
  public loading = 0;
  public dailyReport: DailyReport;
  public workflowEx: string[];

  constructor(
    private siteService: SiteService,
    private snackBar: MatSnackBar,
    private appService: AppService,
    private drService: DailyReportService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.has('drId')) {
      this.getDailyReport(this.route.snapshot.paramMap.get('drId'));
    } else {
      const { siteId, date } = this.route.snapshot.queryParams;
      if (!siteId || !date) {
        this.router.navigate(['/page-404'], { queryParams: { url: this.router.url } });
        return;
      }

      // Todo check access to create daily-report
      this.createNewDailyReport(siteId, date); // 8nAuS5LENMacZXyoJjSmMa
    }
  }

  createNewDailyReport(siteId: string, date: string) {
    this.siteService.getRecord(siteId, { include: 'project' }).subscribe({
      next: (site: Site) => {
        this.dailyReport = new DailyReport({
          report_date: date,
          site: site,
          project: site.project,
        });
        this.initialized = true;
      },
      error: (err: any) => {
        if (err.status == 404) {
          this.router.navigate(['/page-404'], { queryParams: { url: this.router.url } });
        }
      },
    })
  }

  /**
   * Fetches daily-report
   * @param id DailyReport.id
   */
  getDailyReport(id: string) {
    this.drService.getRecord(id)
      .subscribe({
        next: (dailyReport: DailyReport) => {
          this.dailyReport = dailyReport;
          this.initialized = true;

          // list of workflow ex, to show in report history
          this.workflowEx = [
            'site-daily-report-approval/' + this.dailyReport.id,
            'site-daily-report-withdrawal/' + this.dailyReport.id,
          ];

          // this.openReportBidItem(this.dailyReport.bid_items[0]); // for dev

        },
        error: (err: any) => {
          if ([403, 404].indexOf(err.status) > -1) {
            this.router.navigate(['/page-404'], { queryParams: { url: this.router.url } });
            return;
          }

          this.snackBar.open(err.error?.error || err.message, '', { duration: 5000 });
        }
      });
  }

  /**
   * Callback after workflow action is executed
   * @param wfResp workflow state execution response
   */
  dailyReportChanged(wfResp: any) {
    if (wfResp.data) {
      this.dailyReport.status = wfResp.data.status;
    }
  }

  /**
   * callback after daily-report is created/updated
   * @param dr DailyReport
   */
  onDailyReportChange(dr: DailyReport): void {
    if (dr instanceof DailyReport) {
      this.dailyReport = dr;
    }
  }

  deleteReport() {
    this.drService.delete(this.dailyReport.id);
  }
}

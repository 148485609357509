<mat-expansion-panel
  hideToggle
  class="status-section"
  [disabled]="isDisabled"
>
  <mat-expansion-panel-header
    class="accordion-header"
  >
    <mat-panel-title class="section-heading">
      <div
        class="stepper-block last"
        [class.disabled]="isDisabled"
      >
        <div class="stepper-circle"></div>
        <div class="stepper-line"></div>
      </div>
      <div class="header">
        <div class="line-1">
          <div class="block-heading">RESULT</div>
        </div>
        <mat-icon
          class="line-2 chevron-icon"
          [svgIcon]="isExpanded ? 'chevron-up' : 'chevron-down'"
        />
      </div>

    </mat-panel-title>
  </mat-expansion-panel-header>
  <div
    class="content-section last"
  >
    <section class="section">
      <div class="section-heading">STATUS:</div>
      <div class="section-content">
        {{ status }}
      </div>
    </section>
  </div>
</mat-expansion-panel>

<div class="wrapper">
  <ng-container *ngIf="data.newLaborFormat; else oldLaborFormat">

  </ng-container>
  <ng-template #oldLaborFormat>
    <app-summarized-equipment-details
      [data]="data"
    />
  </ng-template>
  <app-actions-section
    [disableAddMenu]="true"
    class="actions-section w-[455px]"
  />
</div>

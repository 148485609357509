import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BidItem, DailyReport, Heading, Project, ReportBidItem, Site } from 'src/app/shared/models';
import { RbiComponent } from '../rbi/rbi.component';
import { AppService } from 'src/app/shared/services';
import { BidService } from 'src/app/shared/services/bid.service';
import { FormBuilder } from '@angular/forms';
import {Parser} from 'expr-eval';

@Component({
  selector: 'app-rbi-summary',
  templateUrl: './rbi-summary.component.html',
  styleUrls: ['./rbi-summary.component.scss']
})
export class RbiSummaryComponent implements OnInit {

  public initialized = false;
  public loading = 0;

  @Output() changed = new EventEmitter<any>();
  @Output() summaryCaption = new EventEmitter<any>();
  @Input() rbis: ReportBidItem[] = [];
  @Input() project: Project;
  @Input() drs: DailyReport[] = [];
  @Input() projectConsolidatedReport: boolean = false;

  public byStation = false;
  public lineItems: ReportBidItem[] = [];
  public defaultUrl = '/assets/default.jpg';

  public isSingleReport = true;
  public sites: Site[] = [];
  public biditems: BidItem[] = [];
  public usedBidItems = [];

  summaryList = [];

  constructor(
    private bidSrv: BidService,
    private appSrv: AppService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    const { sites, bid_items } = this.project;
    this.sites = (sites || []).slice();
    this.biditems = bid_items || [];

    this.isSingleReport = [...new Set(this.rbis.map(o => o.daily_report_id))].length === 1;
    this.sites.map((o: any) => {
      o.summary = this.generateSiteSummary(o);
    });

    this.sites = this.sites.filter(o => o['summary']?.length);

    if (this.projectConsolidatedReport) {
      const usedBidItemIds = new Set(this.rbis.map(rbi => rbi.bid_item_id));
      this.usedBidItems = this.biditems.filter(o => usedBidItemIds.has(o.id));
      this.usedBidItems.map((o: any) => {
        o.summary = this.generateBidItemSummary(o);
        // get total qty for biditem across all sites
        let totalQuantity = 0;
        o.summary.forEach(siteObj => {
          siteObj.lineItems.forEach(li => {
            totalQuantity += li.quantity || li.calculatedQuantity || 0;
          });
        });
        o.totalQuantity = parseFloat(totalQuantity.toFixed(2));
      });
    }

    this.updateCaption(this.projectConsolidatedReport);
    this.initialized = true;

  }

  generateSiteSummary(site: Site) {
    const lis = this.rbis.filter(o => o.site_id === site?.id);
    const biIds = [...new Set(lis.map(o => o.bid_item_id))];
    const rs = [];

    biIds.map(biId => {
      const biditem = this.biditems.find(o => o.id === biId);

      if (biditem?.record_by_station) {
        const headingIds = [...new Set(lis
          .filter(o => o.heading_id && o.bid_item_id === biditem?.id)
          .map(o => o.heading_id))];

        rs.push({
          biditem,
          headings: headingIds.map(hId => {
            const heading = site.headings.find(o => o.id === hId);
            const header = this.rbis.find(o => o.site_id === site?.id
              && o.bid_item_id === biId && o.heading_id === hId && !o.station_id);
            const lineItems = this.rbis.filter(o => o.site_id === site?.id
              && o.bid_item_id === biId && o.heading_id === hId);

            return {
              heading,
              header: header || lineItems[0],
              lineItems,
              rollUp: this.updateRollup(biditem, heading, header, lineItems)
            };
          }),
        });
      } else {
        if (biditem?.rollup_formula) {

          const rsObj = {
              biditem,
              lineItems: this.rbis.filter(o => o.site_id === site?.id && o.bid_item_id === biId)
          };
          const fieldObj = {};
          rsObj.lineItems.map((li: any) => {
            rsObj.biditem?.fields?.map(bf => {
              const fieldValuesObj = {
                ...(li.field_values || []).reduce((p, c) => ({ ...p, [c.id]: c.value }), {})
              };
              fieldObj[bf.name] = Number(fieldValuesObj[bf.id]);
            });

            if (!li.quantity) {
              li.calculatedQuantity = Number(
                (Parser.evaluate(biditem.rollup_formula, fieldObj))
                  .toFixed(2)
              );
            }
          });
          rs.push(rsObj);
        } else {
          rs.push({
            biditem,
            lineItems: this.rbis.filter(o => o.site_id === site?.id && o.bid_item_id === biId)
          });
        }
      }
    });
    return rs;
  }

  generateBidItemSummary(bi: BidItem) {
    const approvedDailyReportIds = new Set(
      this.drs.filter(dr => dr.status === 'approved').map(dr => dr.id)
    );

    // filter rbis by bid_item_id and approved daily report ids
    const lis = this.rbis.filter(o =>
      o.bid_item_id === bi?.id && approvedDailyReportIds.has(o.daily_report_id)
    );

    const siteIds = [...new Set(lis.map(o => o.site_id))];
    const rs = [];

    siteIds.map(siteId => {
      const site = this.sites.find(o => o.id === siteId);

      if (bi?.record_by_station) {
        const headingIds = [...new Set(lis
          .filter(o => o.heading_id && o.bid_item_id === bi?.id)
          .map(o => o.heading_id))];

        rs.push({
          site,
          headings: headingIds.map(hId => {
            const heading = site.headings.find(o => o.id === hId);
            const header = this.rbis.find(o => o.site_id === site?.id
              && o.bid_item_id === bi.id && o.heading_id === hId && !o.station_id);
            const lineItems = this.rbis.filter(o => o.site_id === site?.id
              && o.bid_item_id === bi.id && o.heading_id === hId);

            return {
              heading,
              header: header || lineItems[0],
              lineItems,
              rollUp: this.updateRollup(bi, heading, header, lineItems)
            };
          }),
        });
      } else {
        if (bi?.rollup_formula) {

          let rsObj = {
              site,
              lineItems: this.rbis.filter(o => o.site_id === site?.id && o.bid_item_id === bi?.id)
          };
          const fieldObj = {};
          rsObj.lineItems.map((li: any) => {
            bi?.fields?.map(bf => {
              const fieldValuesObj = {
                ...(li.field_values || []).reduce((p, c) => ({ ...p, [c.id]: c.value }), {})
              };
              fieldObj[bf.name] = Number(fieldValuesObj[bf.id]);
            });

            if (!li.quantity) {
              li.calculatedQuantity = Number(
                (Parser.evaluate(bi.rollup_formula, fieldObj))
                  .toFixed(2)
              );
            }

          });
          rs.push(rsObj);
        } else {
          rs.push({
            site,
            lineItems: this.rbis.filter(o => o.site_id === site?.id && o.bid_item_id === bi.id)
          });
        }
      }
    });

    return rs;
  }




  getPreviewUrl(header: ReportBidItem, lis: ReportBidItem[] = []) {

    const items = [header, ...lis].filter(o => !!o);
    for (const item of items) {
      const { annotations, pictures } = item;

      if (annotations.length) {
        for (const ann of annotations) {
          if (ann.thumb_url) {
            return ann.thumb_url;
          }
        }
      }

      if (pictures.length) {
        for (const pic of pictures) {
          if (pic.thumb_url) {
            return pic.thumb_url;
          }
        }
      }
    }

    return this.defaultUrl;
  }

  getUom(biditem: BidItem): string {
    if (!biditem?.record_by_station) {
      return biditem.uom || '';
    }

    const { rollup_field } = biditem;
    if (rollup_field && rollup_field?.uom) {
      return `S${rollup_field?.uom}`;
    }
  }

  /**
   * Open ReportBidItem in sidebar
   * @param instance LineItem
   */
  openRBI(rbi: ReportBidItem) {
    const biditem = this.biditems.find(o => o.id === rbi.bid_item_id);
    const dr = this.drs.find(o => o.id === rbi.daily_report_id);
    const site = this.sites.find(o => o.id === dr?.site_id);
    const heading = (site.headings || []).find(o => o.id === rbi.heading_id);

    const sidebarRef = this.appSrv.openSidebar(RbiComponent, {
      rbis: this.rbis.filter(o => o.daily_report_id === dr?.id && (o.id === rbi.id || biditem?.record_by_station)),
      biditem,
      heading,
      project: this.project,
      dailyReport: dr,
    });

    sidebarRef.afterClosed().subscribe(n => {
      if (n) {
        this.changed.emit(n);
      }
    });
  }

  updateRollup(biditem: BidItem, heading: Heading, header, lineItems) {
    try {
      const rs = this.bidSrv.getRollup(
        biditem,
        header || new ReportBidItem({ heading }),
        lineItems,
      );
      return rs;
    } catch (e) {
      console.error(e);
    }
    return null;
  }

  updateCaption(isConsolidated: boolean = false) {
    const itemsCount = isConsolidated ? this.usedBidItems?.length 
      : this.sites.map((o: any) => o.summary.length)
      .reduce((p, c) => p + c, 0);
  
    this.summaryCaption.emit({
      biditems: itemsCount
    });
  }
  


}

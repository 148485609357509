import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/shared/models';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ProjectCreateComponent } from '../../../../../project/project-create/project-create.component';
import { Ability } from '@casl/ability';
import { AppService } from '../../../../../../shared/services';

@Component({
  selector: 'app-projects-page-each-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit, OnChanges {


  @Input() showControls = true;
  @Input() showProgress = false;
  @Input() picHeight = '150px';
  @Input() project: any;
  @Output() updated = new EventEmitter<Project>();
  initialized = false;
  protected readonly moment = moment;

  constructor(
    private router: Router,
    private ability: Ability,
    private appSrv: AppService,
  ) {
  }

  redirectToProjectDetails(project) {
    this.router.navigate([`/projects/${project.id}`], {
      queryParams: {
        tab: 'sites',
        status: ['submitted-reviewed', 'approved', 'draft'],
        startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      },
    });
  }

  ngOnInit(): void {
    if (this.project) {
      this.initialized = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  editProject(model: Project = null) {
    const sidebarRef = this.appSrv.openSidebar(ProjectCreateComponent, {
      data: model || {},
      editable: this.ability.can('update', model),
    });

    sidebarRef.afterClosed().subscribe((resp) => {
      if (resp instanceof Project) {
        this.updated.emit(resp);
      }
    });
  }
}

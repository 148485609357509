import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Alignment, Station } from 'src/app/shared/models';
import { AlignmentService, AppErrorStateMatcher, StationService } from 'src/app/shared/services';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'app-alignment-stations',
  templateUrl: './alignment-stations.component.html',
  styleUrls: ['./alignment-stations.component.scss']
})
export class AlignmentStationsComponent implements OnInit {
  public loading = 0;
  public initialized = false;
  public showStationForm = false;
  public showGeneratorForm = false;

  public downloading = false;
  public csvEndpoint: string;
  public clsStation: any = Station;

  @Input() alignment: Alignment;
  public stations: Station[];

  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcStationForm') vcStationForm;
  public stationForm: UntypedFormGroup = this.fb.group({
    id: [''],
    alignment_id: [''],
    name: ['', [Validators.required]],
  });

  @ViewChild('vcGeneratorForm') vcGeneratorForm;
  public generatorForm: UntypedFormGroup = this.fb.group({
    distance: ['', [Validators.required]],
    total: ['', [Validators.required]],
    start_at: ['0', [Validators.required]],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private stationSrv: StationService,
    private alignmentSrv: AlignmentService
  ) { }

  ngOnInit(): void {
    this.fetchRecord(this.alignment);
    this.csvEndpoint = `${ENV.API_ENDPOINT}/v2/alignment/${this.alignment.id}/stations-csv`;
  }

  fetchRecord(alignment: Alignment): void {
    this.alignmentSrv.getRecord(alignment.id, { include: ['stations'] })
    .then((resp: Alignment) => {
      this.stations = resp.stations;
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    })
    .finally(() => {
      this.initialized = true;
    });
  }

  /**
   * Toggles input form
   * @param model Alignment
   * @param show boolean
   */
  toggleStationForm(model: Station = null, show = true): void {
    // prepopulate with last records values
    const [lastStation] = this.stations.slice(-1);
    model = model?.id ? model : new Station();

    if (show) {
      this.vcStationForm?.nativeElement?.reset();
      this.stationForm.reset({
        id: model?.id || null,
        alignment_id: model?.alignment_id || this.alignment?.id || null,
        name: model?.name || null,
      });
      this.showGeneratorForm = false;
    }
    this.showStationForm = show;
  }

  /**
   * onSubmit() handler for station form
   * @param form FormGroup
   * @returns {void}
   */
  saveStation(form: UntypedFormGroup): void {
    if (!form.valid) {
      return;
    }
    const payload = form.value;
    const req = payload?.id
      ? this.stationSrv.update(payload, { include: [] })
      : this.stationSrv.create(payload, { include: [] });

    req
      .then((station: Station) => {
        if (form) {
          this.snackBar.open(
            `${payload?.id ? 'Updated' : 'Created'} station "${station?.name}"`,
            '',
            { duration: 5000 },
          );
        }

        const i = this.stations.findIndex(o => o.id === station.id);
        if (i >= 0) {
          this.stations.splice(i, 1, station);
        } else {
          this.stations.push(station);
          this.stations.sort((a, b) => {
            const p = a.name?.toUpperCase();
            const q = b.name?.toUpperCase();
            return p < q ? -1 : p > q ? 1 : 0;
          })
        }

        this.toggleStationForm(null, false);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes Station
   * @param model
   */
  delete(model: Station): void {
    const message = `Deleting ${model.name} cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.stationSrv.delete(model)
        .then((resp: any) => {
          this.stations.splice(
            this.stations.findIndex(o => o.id === model.id),
            1,
          );
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
      });
  }

  toggleGeneratorForm(show: boolean): void {
    if (show) {
      const [lastStation] = this.stations.slice(-1);
      this.vcGeneratorForm?.nativeElement?.reset();
      this.generatorForm.reset({
        distance: lastStation?.distance || 10,
        start_at: 0,
        total: 10,
      });
      this.showStationForm = false;
    }
    this.showGeneratorForm = show;
  }

  generate(form: UntypedFormGroup) {
    if (!form.valid) {
      return;
    }

    this.loading++;
    const req = this.alignmentSrv.generate(
      this.alignment,
      form.value,
      { include: ['stations'] }
    );

    req
      .then((resp: any) => {
        this.toggleGeneratorForm(false);
        this.stations = resp.result?.stations || [];
        this.snackBar.open(
          resp.meta?.message || 'Added stations to alignment',
          '',
          { duration: 5000 }
          );
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Download alignment stations csv
   * @param alignment Alignment
   */
  downloadCSV(alignment: Alignment): void {
    this.downloading = true;
    this.alignmentSrv
      .downloadStations(alignment)
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.message, 'close');
      })
      .finally(() => {
        this.downloading = false;
      });
  }

  /**
   * Callback on upload failure
   * @param resp Response
   */
  onUploadError(resp: HttpErrorResponse) {
    if (resp.error.status == 422) {
      // Todo create custom component to display multiple errors at once
      const errors = Object.values(resp.error.error);
      if (errors.length) {
        const msg = errors.map((o: any) => {
          return `${o.location}.${o.param} ${o.msg}`;
        }).join(', ');
        this.snackBar.open(
          msg.length > 80 ? `${msg.substring(0, 77)}...`: msg,
          'Close'
        );
      } else {
        this.snackBar.open('Upload failed, please check the logs', 'Close');
      }
      return;
    }
    if (resp.error.error) {
      // Todo Show some permanent visible error after design
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
      return;
    }

    this.snackBar.open(resp.message, '', { duration: 5000 });
  }

  /**
   * Callback on upload success
   * @param attachment Attachment
   */
  onUploadComplete(resp: any) {
    if (Array.isArray(resp)) {
      this.snackBar.open(`Updated ${resp.length} stations`, '', {
        duration: 5000,
      });
      this.fetchRecord(this.alignment);
    }
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { last, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppHttpService {
  API_ENDPOINT: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.API_ENDPOINT = ENV.API_ENDPOINT;
  }

  /**
   * converts object to url query-params string
   * @param obj object
   * @param prefix param name, null by default
   */
  serializeQueryParams(obj, prefix = null): string {
    const str = [];
    for (let [k, v] of Object.entries(obj || {})) {
      if (v === undefined) {
        continue;
      }
      k = prefix ? `${prefix}[${k}]` : k;

      const value = typeof v === 'object'
        ? this.serializeQueryParams(v, k)
        : `${k}=${v}`;
      str.push(value);

    }
    return str.join('&');
  }

  /**
   * http get request
   * @param path api url to make the request
   * @returns Observable
   */
  getService(path: string, qp = {}, options: any = {}): Observable<any> {
    return this.httpClient.get(this.API_ENDPOINT + path + '?' + this.serializeQueryParams(qp), options);
  }

  /**
   * http post request
   * @param path api url to make the request
   * @param body request body for the request
   * @param qp query-params
   * @returns Observable
   */
  postService(path: string, body: any, qp = {}): Observable<any> {
    return this.httpClient.post(this.API_ENDPOINT + path + '?' + this.serializeQueryParams(qp), body);
  }

  /**
   * http post request
   * @param path api url to make the request
   * @param body request body for the request
   * @returns Observable
   */
  putService(path: string, body: any, qp = {}): Observable<any> {
    return this.httpClient.put(this.API_ENDPOINT + path + '?' + this.serializeQueryParams(qp), body);
  }

  /**
   * http delete request
   * @param path api url to make the request
   * @param body request body for the request
   * @returns Observable
   */
  deleteService(path: string, body: any = {}): Observable<any> {
    return this.httpClient.delete(this.API_ENDPOINT + path, body);
  }

  /**
   * Send http request with multipart/form-data
   * @param method HTTP METHOD POST|PUT|PATCH|DELETE
   * @param path request url path
   * @param data request body
   * @param qp query-params
   */
  sendMultipartData(method: string, path: string, data: object, qp: any = {}): Observable<any> {
    const fd = new FormData();
    for (const [field, value] of Object.entries(data)) {
      fd.append(field, value);
    }

    const url = `${this.API_ENDPOINT}${path}?${this.serializeQueryParams(qp)}`;
    const req = new HttpRequest(method, url, fd);
    req.headers.set('Content-Type', 'multipart/form-data');
    return this.httpClient.request(req).pipe(
      map(event => {
        if (event.type === HttpEventType.Response) {
          return event.body;
        }
      }),
      tap(message => {}),
      last()
    );
  }

}

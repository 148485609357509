import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-create-payapp-dialog',
  templateUrl: './create-payapp-dialog.component.html',
  styleUrls: ['./create-payapp-dialog.component.scss']
})
export class CreatePayappDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<CreatePayappDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  getDateRangeMessage(): string {
    if (this.data?.startDate && this.data?.endDate) {
      return `from ${this.data.startDate} to ${this.data.endDate}`;
    } else if (this.data?.startDate) {
      return `starting from ${this.data.startDate}`;
    } else if (this.data?.endDate) {
      return `up to ${this.data.endDate}`;
    } else {
      return '';
    }
  }
  onConfirmClick(): void {
    this.dialogRef.close(this.data.payappName);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }
}

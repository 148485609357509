<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button mat-icon-button [matMenuTriggerFor]="moreActionsMenu" aria-label="More actions">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreActionsMenu="matMenu">
      <button mat-menu-item (click)="delete(reportLabor)"
        [disabled]="!editable">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>

    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>

    <button *ngIf="initialized" mat-mini-fab (click)="editing = !editing" [disabled]="!editable">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="initialized">
    <form [formGroup]="inputForm">
      <div class="container">

        <!-- title section -->
        <section class="header">
          <div class="title">{{reportLabor.type}}</div>
        </section>
        <!-- /title section -->

        <nav mat-tab-nav-bar class="sub-nav">
          <a mat-tab-link active="active">Details</a>
          <a mat-tab-link>Discussion</a>
        </nav>

        <!-- Sub contractor -->
        <section>
          <div class="mat-subheading-1 row" *ngIf="!editing">Sub contractor:</div>
          <div class="mat-body capitalize" *ngIf="!editing">{{reportLabor.sub_contractor?.name}}</div>
          <mat-form-field *ngIf="editing">
            <mat-label>Sub contractor</mat-label>
            <mat-select formControlName="sub_contractor_id" [errorStateMatcher]="matcher">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let sc of subContractors" [value]="sc.id">
                <div>{{sc.name}}</div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="inputForm.controls.sub_contractor_id.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.sub_contractor_id.hasError('error')">
              {{inputForm.controls.sub_contractor_id.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Sub contractor -->

        <!-- type section -->
        <section>
          <div class="mat-subheading-1 row">Labor:</div>
          <div class="mat-body" *ngIf="!editing">{{reportLabor.type}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="text" autocomplete="off" formControlName="type" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.type.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.type.hasError('error')">
              {{inputForm.controls.type.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /type section -->

        <!-- quantity section -->
        <section>
          <div class="mat-subheading-1 row">Quantity:</div>
          <div class="mat-body" *ngIf="!editing">{{reportLabor.quantity}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="number" autocomplete="off" formControlName="quantity" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.quantity.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.quantity.hasError('error')">
              {{inputForm.controls.quantity.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /quantity section -->

        <!-- duration section -->
        <section>
          <div class="mat-subheading-1 row">Hours:</div>
          <div class="mat-body" *ngIf="!editing">{{reportLabor.duration}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="number" autocomplete="off" formControlName="duration" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.duration.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.duration.hasError('error')">
              {{inputForm.controls.duration.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /duration section -->

        <!-- idle_duration section -->
        <section>
          <div class="mat-subheading-1 row">Hours idle:</div>
          <div class="mat-body" *ngIf="!editing">{{reportLabor.idle_duration}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="number" autocomplete="off" formControlName="idle_duration" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.idle_duration.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.idle_duration.hasError('error')">
              {{inputForm.controls.idle_duration.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /idle_duration section -->

        <!-- note section -->
        <section>
          <div class="mat-subheading-1 row">Note:</div>
          <div class="mat-body" *ngIf="!editing">{{reportLabor.comment}}</div>
          <mat-form-field *ngIf="editing">
            <textarea matInput formControlName="comment" [errorStateMatcher]="matcher">
              </textarea>
            <mat-error *ngIf="inputForm.controls.comment.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.comment.hasError('error')">
              {{inputForm.controls.comment.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /note section -->

        <!-- Form Actions -->
        <section>
          <div class="row" *ngIf="editing">
            <button [disabled]="!editable" mat-stroked-button color="accent" (click)="saveItem()">
              <ng-container *ngIf="reportLabor.id">Update</ng-container>
              <ng-container *ngIf="!reportLabor.id">Create</ng-container>
            </button>
            <button mat-stroked-button (click)="onCancel()">Cancel</button>
          </div>
        </section>
        <!-- Form Actions -->

      </div>
    </form>
  </ng-container>

</div>

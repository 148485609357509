import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-activity-summary',
  templateUrl: './activity-summary.component.html',
  styleUrls: ['./activity-summary.component.scss'],
})
export class ActivitySummaryComponent {
  @Input() data: any;

  constructor() {
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Site, Account, Project } from 'src/app/shared/models';
import { ProjectService, SiteService } from 'src/app/shared/services';

@Component({
  selector: 'app-manage-site-members',
  templateUrl: './manage-site-members.component.html',
  styleUrls: ['./manage-site-members.component.scss']
})
export class ManageSiteMembersComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  public site: Site = new Site();
  public project: Project;
  public projectAccounts: Account[] = [];
  public siteAccounts: Account[] = [];

  private allowedRoles = [
    'inspector',
    'lead-inspector',
    'contractor-inspector'
  ];

  constructor(
    private snackBar: MatSnackBar,
    private siteService: SiteService,
    private projectService: ProjectService,
    public dialogRef: MatDialogRef<ManageSiteMembersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data instanceof Site) {
      this.site = data;
      this.project = new Project({ id: this.site.project_id });
    }
  }

  ngOnInit(): void {
    this.fetchProjectAccounts();
  }

  /**
   * Get project accounts
   */
  fetchProjectAccounts() {
    this.loading++;
    this.projectService.getAccounts(this.project, { pageIndex: 0, pageSize: 10000 }, { include: 'roles' })
      .subscribe({
        next: (resp: any) => {
          this.projectAccounts = resp.result.map(a => {
            // user can have different roles for different project, filter out this project's role
            a.projectRole = a.roles.find(r => r.project_id === this.project.id);
            return a;
          })
          .filter(a => this.allowedRoles.indexOf(a.projectRole?.role) > -1); // ignore all roles except allowed
          this.fetchSiteAccounts();
          this.initialized = true;
          this.loading--;
        },
        error: (err: any) => {
          this.loading--;
          this.snackBar.open(err.message, '', { duration: 6000 });
        }
      });
  }

  /**
   * Get project accounts
   */
  fetchSiteAccounts() {
    this.loading++;
    this.siteService.getAccounts(this.site, { pageIndex: 0, pageSize: 10000 })
      .subscribe({
        next: (resp: any) => {
          this.siteAccounts = resp.result;
          this.projectAccounts.map((o: any) => o.checked = !!this.siteAccounts.find(t => t.id === o.id));
          this.loading--;
        },
        error: (err: any) => {
          this.loading--;
          this.snackBar.open(err.message, '', { duration: 6000 });
        }
      });
  }

  /**
   * Close dialog
   * @param resp any
   */
  onClose(resp): void {
    this.dialogRef.close(resp);
  }

  /**
   * OnChange event of checkbox
   * @param materialTest MaterialTest
   * @param checked boolean
   */
  onChange(account: Account, checked: boolean) {
    if (checked) {
      this.addAccountToSite(account);
    } else {
      this.removeAccountFromSite(account);
    }
  }

  /**
   * Add account to Site
   * @param account Account
   */
  addAccountToSite(account) {
    this.loading++;
    this.siteService.addAccount(this.site, account)
      .subscribe({
        next: (resp: any) => {
          this.loading--;
          this.snackBar.open(resp.message, '', { duration: 5000 });
        },
        error: (errResp: HttpErrorResponse) => {
          this.loading--;
          this.snackBar.open(errResp.message, '', { duration: 5000 });
        }
      });
  }

  /**
   * Remove account from site
   * @param account Account
   */
  removeAccountFromSite(account) {
    this.loading++;
    this.siteService.removeAccount(this.site, account)
      .subscribe({
        next: (resp: any) => {
          this.loading--;
          this.snackBar.open(resp.message, '', { duration: 5000 });
        },
        error: (errResp: HttpErrorResponse) => {
          this.loading--;
          this.snackBar.open(errResp.message, '', { duration: 5000 });
        }
      });
  }

}

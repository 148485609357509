import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubContractorsComponent } from './components/sub-contractors/sub-contractors.component';
import { SubContractorCreateComponent } from './components/sub-contractor-create/sub-contractor-create.component';
import { ProjectSubContractorsComponent } from './components/project-sub-contractors/project-sub-contractors.component';

@NgModule({
  declarations: [
    SubContractorsComponent,
    SubContractorCreateComponent,
    ProjectSubContractorsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
  ],
  exports: [
    SubContractorsComponent,
    ProjectSubContractorsComponent,
  ],
})
export class SubContractorModule { }

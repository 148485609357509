<div class="component-container flex flex-col gap-[40px]">
  <div
    class="action-section mt-[40px] flex flex-row justify-end gap-[20px]"
  >
    <div
      #trigger="cdkOverlayOrigin"
      (click)="isOpen = true" cdkOverlayOrigin class="change-order-filter-button flex flex-row gap-[20px]"
      type="button"
    >
      <div class="label text-type-7 flex flex-row justify-between items-center">
        <div
          class="pick-list-value"
          matTooltip="{{selectedValue['order_number']}}"
        >
          {{ selectedValue['order_number'] }}
        </div>
        <div
          *ngIf="selectedValue.created_at"
          class="current ml-[5px] text-[#60698B]"
        >
          ({{ selectedValue['created_at'] | date :'mediumDate' }})
        </div>
      </div>
      <mat-icon
        [ngClass]="{'rotate': isOpen}"
        class="dropdown-icon"
        svgIcon="chevron-down"
      />
    </div>
    <ng-template
      (backdropClick)="isOpen = false"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayOrigin]="trigger"
      cdkConnectedOverlay
    >
      <div class="change-order-filter-popup">
        <div class="change-order-filter-list">
          <div
            (click)="changeOrderFilterItemClicked(eachChangeOrder)"
            *ngFor="let eachChangeOrder of changeOrderFilter; let i = index"
            class="change-order-filter-item"
          >
            <div class="flex flex-col justify-center">
              <div
                *ngIf="eachChangeOrder['order_number']"
                [ngClass]="{'selected-filter-option': selectedValue.id === eachChangeOrder.id}"
                class="pick-list-option-value text-type-11"
                matTooltip="{{eachChangeOrder['order_number']}}"
              >
                {{ eachChangeOrder['order_number'] }}
              </div>
              <div *ngIf="eachChangeOrder['id'] !== -1" class="text-type-10">
                ({{ eachChangeOrder['created_at'] | date :'mediumDate' }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <button
      (click)="onUploadChangesClicked()"
      *ngIf="dataSource?.length"
      class="upload-btn"
      color="primary"
      mat-stroked-button
    >
      Upload changes
    </button>
  </div>
  <div *ngIf="dataSource?.length" class="bit-items-table">
    <table [dataSource]="dataSource" class="mat-elevation-z8 table" mat-table>

      <!-- Item Column -->
      <ng-container matColumnDef="item">
        <th *matHeaderCellDef class="text-type-5" mat-header-cell> Item</th>
        <td *matCellDef="let row" mat-cell>
          <div class="text-type-8">{{ row.item }}</div>
          <div
            [class.highlight]="row?.previous_value
                               && row?.previous_value[0]?.description
                               && row?.previous_value[0]?.description !== row?.description"
            class="description text-type-14"
          >
            {{ row.description }}
          </div>
        </td>
      </ng-container>

      <!-- Quantity Column -->
      <ng-container matColumnDef="quantity">
        <th *matHeaderCellDef class="text-type-5" mat-header-cell> Quantity</th>
        <td *matCellDef="let row" class="text-type-10" mat-cell>
          <div class="quantity">
            <div
              [class.highlight]="row?.previous_value
                                && row?.previous_value[0]?.quantity
                                && (row?.previous_value[0]?.quantity !== row?.quantity)
                                || row?.sites?.length"
            >
              {{ row?.quantity }}
            </div>
            <div *ngIf="row?.previous_value
                        && row?.previous_value[0]?.quantity
                        && (row?.previous_value[0]?.quantity !== row?.quantity)"
                 class="previous-value text-type-14">
              from {{ row?.previous_value[0]?.quantity }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Unit Price Column -->
      <ng-container matColumnDef="unitPrice">
        <th *matHeaderCellDef class="text-type-5" mat-header-cell> Unit price</th>
        <td *matCellDef="let row" class="text-type-10" mat-cell>

          <div class="unit-price">
            <div
              [class.highlight]="row?.previous_value
                                 && row?.previous_value[0]?.unit_price
                                 && row?.previous_value[0]?.unit_price !== row?.unit_price"
            >
              {{ row?.unit_price }}
            </div>
            <div *ngIf="row?.previous_value
                        && row?.previous_value[0]?.unit_price
                        && row?.previous_value[0]?.unit_price !== row?.unit_price"
                 class="previous-value text-type-14">
              from {{ row?.previous_value[0]?.unit_price }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- UOM Column -->
      <ng-container matColumnDef="uom">
        <th *matHeaderCellDef class="text-type-5" mat-header-cell> UOM</th>
        <td *matCellDef="let row" class="text-type-10" mat-cell>
          <div class="uom">
            <div
              [class.highlight]="row?.previous_value
                                 && row?.previous_value[0]?.uom
                                 && row?.previous_value[0]?.uom !== row?.uom"
            >
              {{ row.uom }}
            </div>
            <div *ngIf="row?.previous_value
                        && row?.previous_value[0]?.uom
                        && row?.previous_value[0]?.uom !== row?.uom"
                 class="previous-value text-type-14">
              from {{ row?.previous_value[0]?.uom }}
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Header and Row Definitions -->
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr (click)="rowClicked(row)"
          *matRowDef="let row; columns: displayedColumns;"
          [class.highlighted-row]="row === selectedRow"
          mat-row></tr>

    </table>
  </div>
  <ng-container *ngIf="!dataSource?.length">
    <div class="no-data text-type-8 mt-[80px]">
      You dont have any bid item yet. Use this <span class="clickable">template</span> to import your data.
    </div>
    <app-primary-button
      (handleClick)="import('fileUploadRef')"
      class="import-btn w-[200px]"
    >
      Import
    </app-primary-button>
    <input
      #fileUploadRef
      (change)="handleFileUpload()"
      [id]="'fileUploadRef'"
      class="input-files"
      type="file"
    />
  </ng-container>
  <app-paginator
    (pageChanged)="onPageChanged($event)"
    [itemsPerPage]="pagination.pageSize"
    [totalItems]="totalRecords"
    class="paginator"
  >
  </app-paginator>
</div>



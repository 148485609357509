import { Component, ContentChildren, Input, TemplateRef } from '@angular/core';
@Component({
  selector: 'app-carousel-type-two',
  templateUrl: './carousel-type-two.component.html',
  styleUrls: ['./carousel-type-two.component.scss'],
})
export class CarouselTypeTwoComponent {
  @ContentChildren(TemplateRef) slideTemplate;
  
  @Input() slidesData = [];


}

<div class="wrapper">
  <div class="configuration-section">
    <form [formGroup]="configurationForm">
      <app-dropdown
        [options]="headingOptions"
        [parentFormGroup]="configurationForm"
        controlName="heading"
        label="HEADING"
        placeholder="Select a heading"
      />
      <div class="stations-selection flex gap-[20px]">
        <app-autocomplete
          [options]="stationOptions"
          [parentFormGroup]="configurationForm"
          controlName="from"
          label="FROM"
          placeholder="Select a station"
        />
        <app-autocomplete
          [options]="stationOptions"
          [parentFormGroup]="configurationForm"
          controlName="to"
          label="To"
          placeholder="Select a station"
        />
        <mat-icon
          class="add-station mt-[30px] cursor-pointer"
        >
          playlist_add
        </mat-icon>
      </div>
    </form>
  </div>
  <div class="total-section flex flex-col">
    <div class="quantity-total-label text-type-14">QUANTITY:</div>
    <div class="quantity-total-value text-type-11">{{ quantityTotal }}</div>
  </div>
  <div class="stations-section">
    <ng-container *ngFor="let station of stations">
      <div
        [class.is-expanded]="selectedStationId === station.id"
        class="station-accordion"
      >
        <div
          (click)="selectStation(station.id)"
          class="station-header flex justify-between items-center"
        >
          <div class="left flex justify-center items-center gap-[12px]">
            <div class="station-name text-type-8">
              {{ station?.station?.name }}
            </div>
          </div>
          <div class="right">
            <div class="chevron">
              <mat-icon
                [svgIcon]="selectedStationId === station.id ? 'chevron-up' : 'chevron-down'"
                class="chevron-icon"
              />
            </div>
          </div>
        </div>
        <div class="station-content">
          <ng-container *ngIf="isFormReady">
            <form
              [formGroup]="getFormGroup(form, station.id)"
            >
              <div class="fields-row input-block">
                <ng-container *ngFor="let field of bidItem.fields">
                  <ng-container *ngIf="field.type === 'number' || false">
                    <app-number-input
                      [controlName]="field.id"
                      [label]="field.label + ' (' + field.uom + ')'"
                      [parentFormGroup]="getFormGroup(form, station.id)"
                    />
                  </ng-container>
                </ng-container>
              </div>
              <div class="comment-section">
                <app-text-area
                  [parentFormGroup]="getFormGroup(form, station.id)"
                  controlName="comment"
                  label="COMMENT"
                />
              </div>
              <div
                *ngIf="selectedStationPictures.length"
                class="pictures-section"
              >
                <div class="pictures-section-label">PICTURES:</div>
                <div class="pictures-row flex flex-wrap gap-[20px]">
                  <app-image
                    *ngFor="let picture of selectedStationPictures"
                    [url]="picture.thumb_url"
                    class="each-picture"
                  />
                </div>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, Heading, Project, Site } from 'src/app/shared/models';
import { SiteService } from 'src/app/shared/services';
import { BidService } from 'src/app/shared/services/bid.service';

@Component({
  selector: 'app-biditem-site-quantities',
  templateUrl: './biditem-site-quantities.component.html',
  styleUrls: ['./biditem-site-quantities.component.scss']
})
export class BiditemSiteQuantitiesComponent implements OnInit {
  public initialized: boolean = false;
  public loading: number = 0;

  @Input() public bidItem: BidItem;
  public sites: Site[] = [];
  public project: Project;

  constructor(
    private snackBar: MatSnackBar,
    private siteSrv: SiteService,
    private bidSrv: BidService
  ) { }

  ngOnInit(): void {
    this.project = new Project({ id: this.bidItem?.project_id });
    this.fetchBidItem(this.bidItem.id);
  }

  fetchBidItem(id: string): void {
    const qp = {
      include: ['site_quantities'],
    }
    this.loading++;
    this.bidSrv.getBidItem(id, qp)
      .then((bidItem: BidItem) => {
        this.bidItem = bidItem;
        return this.fetchSites(this.project);
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.message || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

  fetchSites(project: Project): void {
    this.siteSrv.getRecords(
      project,
      { pageIndex: 0, pageSize: 100000 },
      { include: [''] },
    ).toPromise()
    .then((resp: any) => {
      this.sites = resp.result.map((site: any) => {
        const r = (this.bidItem?.site_quantities || []).find((o) => o.site_id === site?.id);
        site.quantity = r?.quantity || null;
        return site;
      });
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    });
  }

  save(site: any): void {
    this.bidSrv.updateSiteQuantity(this.bidItem, site, site?.quantity || 1)
      .then((resp: any) => {
        this.snackBar.open('Saved site quantity', '', { duration: 5000 });
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      });
  }
}

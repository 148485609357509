import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-offsite-test-record-block',
  templateUrl: './offsite-test-record-block.component.html',
  styleUrls: [
    '../../../styles.css',
    '../styles.css',
    './offsite-test-record-block.component.scss',
  ],
})
export class OffsiteTestRecordBlockComponent implements OnChanges {
  @Input() activity: any;
  @Input() data: any;
  @Input() isDisabled: boolean;

  subheading = '';
  isExpanded = false;

  time = '';
  breakDuration: number = null;
  pictures: any[] = [];
  comment = '';

  constructor() {

  }

  ngOnChanges() {
    this.createSubheading();
    this.getInspectionTime();

    this.breakDuration = moment
      .duration(this.data?.inspection_detail?.break_duration, 'minutes')
      .asMinutes();

    this.pictures = this.data?.inspection_detail?.pictures || [];
    this.comment = this.data?.inspection_detail?.comment;
  }

  createSubheading() {
    if (this.activity.lab_activity_completed) {
      this.subheading = 'Visit details saved';
    } else {
      this.subheading = 'Fill in details';
    }
  }

  getInspectionTime() {
    const arrival = this.data?.inspection_detail?.arrival_time;
    const departure = this.data?.inspection_detail?.departure_time;

    this.time = `${moment(arrival)
      .format('dddd, MMMM DD - hh:mm a')} to ${moment(departure)
      .format('hh:mm: a')}`;
  }

  toggleState(event) {
    if (this.isDisabled) {
      this.isExpanded = false;
    } else {
      this.isExpanded = event;
    }
  }
}

<div class="wrapper flex flex-col">
  <div class="heading text-type-1 mb-[20px] flex justify-between items-center">
    <span>Disagree</span>
    <span>
      <button (click)="closeDialog()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </div>
  <form class="flex flex-col gap-[calc(40px-1.25em)]">
    <app-text-input
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['quantity']"
      [parentFormGroup]="form"
      controlName="quantity"
      label="Suggested Quantity"
    />
    <app-text-area-type-two
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['specifyOtherRelationDetails']"
      [parentFormGroup]="form"
      class="input w-full"
      controlName="comment"
      label="COMMENT:"
    />
  </form>
  <div
    *ngIf="mediaFiles?.length"
    class="pictures-section"
  >
    <div class="pictures-row flex flex-wrap gap-[20px]">
      <app-pictures-section
        (onDelete)="deletePicture($event)"
        *ngIf="mediaFiles?.length"
        [maxHeight]="'300px'"
        [pictures]="mediaFiles"
        class="pictures-section mb-[40px]"
      />

    </div>
  </div>
  <div>
    <mat-icon
      (click)="openFile('fileUploadRef')"
      [svgIcon]="'paperclip'"
      class="icon"
    />
    <input
      #fileUploadRef
      (change)="onFileSelected($event)"
      [id]="'fileUploadRef'"
      accept=".png, .jpg, .jpeg"
      class="hidden"
      multiple="multiple"
      type="file"
    />
    <hr class="hr">
  </div>
  <div class="actions mt-[calc(100px-1.25em)]">
    <app-primary-button
      (handleClick)="onSubmit()"
      [isDisabled]="!form.dirty"
    >
      Submit
    </app-primary-button>
  </div>
</div>

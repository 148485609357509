<div *ngIf="initialized">
  <h1>Select bid item, material</h1>
  <div>
    <div *ngIf="!filteredBidItems.length && !filteredMaterials.length" class="mat-body-strong">
      No bid items, materials found for this project.
    </div>
    <div class="cards-container">
      <ng-container *ngFor="let material of filteredMaterials">
        <div class="stroked-card" (click)="onSelected(material)" [class.selected]="selectedEntity?.id === material.id">
          <div class="">{{material.name}}</div>
          <div class="">{{material.uom}}</div>
        </div>
      </ng-container>

      <ng-container *ngFor="let biditem of filteredBidItems">
        <div class="stroked-card" (click)="onSelected(biditem)" [class.selected]="selectedEntity?.id === biditem.id">
          <div class="">{{biditem.item}}</div>
          <div class="">{{biditem.uom}}</div>
          <div class="">{{biditem.unit_price}}</div>
          <div class="">{{biditem.description}}</div>
        </div>
      </ng-container>

    </div>
  </div>
</div>
<button
  (mousedown)="$event.preventDefault()"
  (throttledClick)="!isDisabled && !isLoading && handleClick.emit($event)"
  [color]="color"
  [ngClass]="{
                disabled: isDisabled,
                loading: isLoading
            }"
  [throttleTime]="1000"
  class="button primary-button w-full whitespace-nowrap flex flex-row items-center justify-center"
  mat-stroked-button
  preventMultipleClick
>
  <div
    *ngIf="!isLoading; else loader"
    class="button-content w-full truncate"
  >
    <ng-content></ng-content>
  </div>
  <ng-template #loader>
    <mat-spinner
      [diameter]="20"
      class="button-loader"
      mode="indeterminate"
    >
    </mat-spinner>
  </ng-template>
  <div [id]="buttonId" class="overlay"></div>
</button>

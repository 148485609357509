<div *ngIf="initialized">

  <div class="mat-display-1">Project {{projectReport.project.name}}</div>
  <div class="mat-title">{{projectReport.report_date | dateFormat: 'long' }}</div>

  <mat-tab-group>
    <mat-tab label="Project report">
      <ng-template matTabContent>
        <div class="tab-content">

          <!-- Project report status -->
          <ng-container *ngTemplateOutlet="projectReportStatus"></ng-container>
          <!-- Project report status -->

          <app-project-consolidated-report
            [externalIssuesOnly]="true"
            [editable]="false"
            [dailyReports]="projectReport.dailyReports">
          </app-project-consolidated-report>

          <ng-container *ngIf="('read' | able: projectReport)">
            <app-workflow
              wf="project-report-approval"
              [id]="projectReport.id"
              [projectId]="projectReport.project.id"
              (actionResponse)="projectReportChanged($event)"
              [redraw]="projectReport.status"
            ></app-workflow>
          </ng-container>

        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Report history">
      <ng-template matTabContent>
        <app-workflow-lineage *ngIf="('read' | able: projectReport)"
          [ex]="wfEx">
        </app-workflow-lineage>
      </ng-template>
    </mat-tab>
  </mat-tab-group>


</div>

<ng-template #projectReportStatus>
  <div *ngIf="!projectReport.lastApprovalAction && projectReport.status == 'draft'">
    Draft by {{projectReport.createdBy?.name}}
    <span class="mat-caption">on {{projectReport.created_at | dateFormat: 'very-long'}}</span>
  </div>

  <div *ngIf="projectReport.lastApprovalAction?.id">
    {{projectReport.lastApprovalAction?.state?.comment}} by {{projectReport.lastApprovalAction?.actor?.name}}
    <span class="mat-caption">on {{projectReport.lastApprovalAction?.created_at | dateFormat: 'very-long'}}</span>
  </div>

  <!-- <div [ngSwitch]="projectReport.lastApprovalAction?.state?.name" *ngIf="projectReport.lastApprovalAction?.id">
    <ng-container *ngSwitchCase="'project-report-submit'">
      Sent for signature by {{projectReport.lastApprovalAction?.actor?.name}}
    </ng-container>
    <ng-container *ngSwitchCase="'project-report-sign'">
      Signed by {{projectReport.lastApprovalAction?.actor?.name}}
    </ng-container>
    <ng-container *ngSwitchCase="'project-report-reject'">
      Rejected by {{projectReport.lastApprovalAction?.actor?.name}}
    </ng-container>
    <span class="mat-caption">on {{projectReport.lastApprovalAction?.created_at | dateFormat: 'very-long'}}</span>
  </div> -->
</ng-template>

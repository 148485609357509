<div *ngIf="apiLoaded | async">
  <google-map
    [options]="mapOptions"
    [zoom]="mapOptions.zoom"
    [center]="mapOptions.center"
    [width]="mapOptions.width"
    [height]="mapOptions.height"
  >
    <map-marker
      *ngFor="let marker of markers"
      [position]="marker.position"
      [label]="marker.label"
      [title]="marker.title"
      [options]="marker.options"
    ></map-marker>
  </google-map>
</div>

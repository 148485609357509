import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, InternalTest, Project } from 'src/app/shared/models';
import { InternalTestService } from 'src/app/shared/services';
import { BidService } from 'src/app/shared/services/bid.service';

@Component({
  selector: 'app-biditem-internal-tests',
  templateUrl: './biditem-internal-tests.component.html',
  styleUrls: ['./biditem-internal-tests.component.scss']
})
export class BiditemInternalTestsComponent implements OnInit {

  public loading: number = 0;
  public initialized: boolean = false;

  @Input() public bidItem: BidItem;
  public internalTests: InternalTest[] = [];
  public project: Project;

  constructor(
    private snackBar: MatSnackBar,
    private internalTestSrv: InternalTestService,
    private bidSrv: BidService,
  ) { }

  ngOnInit(): void {
    this.fetchBidItem(this.bidItem.id);
    this.project = new Project({ id: this.bidItem?.project_id });
  }

  fetchBidItem(id: string): void {
    const qp = {
      include: ['internal_tests'],
    }
    this.bidSrv.getBidItem(id, qp)
      .then((bidItem: BidItem) => {
        this.bidItem = bidItem;
        return this.fetchInternalTests();
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  fetchInternalTests(): void {
    this.internalTestSrv.getRecords(
      this.project,
      { pageIndex: 0, pageSize: 100000 },
      { include: [''] },
    ).then((resp: any) => {
      this.internalTests = resp.result;
      // mark materials as checked/unchecked
      this.internalTests.map((o: any) => o.checked = !!this.bidItem.internal_tests.find((t) => t.id === o.id));
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    });
  }

  /**
   * OnChange event of checkbox
   * @param internalTest InternalTest
   * @param checked boolean
   */
  onChange(internalTest: InternalTest, checked) {
    const req = checked
      ? this.internalTestSrv.addBidItem(internalTest, this.bidItem)
      : this.internalTestSrv.removeBidItem(internalTest, this.bidItem);

    req.then((msg: string) => {
        this.snackBar.open(msg, '', { duration: 5000 });
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      });
  }

}

import { BaseModel } from 'src/app/shared/models';

export class AppData extends BaseModel {
  locale: any;
  // for CASL subject detection on code minification
  static get modelName() {
    return 'AppData';
  }

  /* tslint:disable */
  key: string;
  value: string;

  selected: boolean;
  /* tslint:enable */

  get relations() {
    return {};
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
  }
}

import { BaseModel } from './base-model';

export class WorkflowState extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'WorkflowState'; }

  /* tslint:disable */
  name: string;
  comment: string;
  type: string;
  state: any;
  resource: string;
  params: any;
  next: any;
  end: boolean;
  /* tslint:enable */

}

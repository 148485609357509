import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  DailyReport,
  Issue,
  ReportBidItem,
  ReportMaterialDelivery,
} from '../../../shared/models';
import * as moment from 'moment/moment';
import { Parser } from 'expr-eval';
import { BidService } from '../../../shared/services/bid.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectMapService {
  headingsList: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  seeMore: Subject<any> = new Subject();
  closeMap: Subject<any> = new Subject();
  closeSidenav: Subject<any> = new Subject();
  closeFilters: Subject<any> = new Subject();
  selectedMarkerData: Subject<any> = new Subject();
  selectedPicturesData: Subject<any> = new Subject();
  closePicturesPanel: Subject<any> = new Subject();

  constructor(private bidService: BidService) {}

  private _createLevelOneGhostLocations(element, details, type) {
    return {
      ...element,
      ...details,
      markerType: type,
      level: 1,
    };
  }

  private _createMarker(location, data, type) {
    return {
      geometry: {
        type: 'point',
        longitude: location.longitude,
        latitude: location.latitude,
      },
      symbol: type,
      data,
    };
  }

  createLevelOneMapData(dailyReports: DailyReport[]) {
    let markersList = [];

    let ghostLocations = [];

    dailyReports.forEach(dr => {
      const details = {
        projectId: dr.project_id,
        siteId: dr.site_id,
        reportId: dr.id,
      };

      // Bid Item Markers
      const bidItemDetails = this._createLevelOneBidItemMarkers(dr.bid_items, ghostLocations, details);
      const { bidItemMarkers } = bidItemDetails;
      ghostLocations = bidItemDetails.ghostLocations;

      // Material Delivery Markers
      const materialDeliveryDetails = this._createLevelOneMaterialDeliveryMarkers(
        dr.material_deliveries,
        ghostLocations,
        details
      );
      const { materialDeliveryMarkers } = materialDeliveryDetails;
      ghostLocations = materialDeliveryDetails.ghostLocations;

      // Issue Markers
      const issuesDetails = this._createLevelOneIssuesMarkers(dr.issues, ghostLocations, details);
      const { issueMarkers } = issuesDetails;
      ghostLocations = issuesDetails.ghostLocations;

      // Activity Details
      const activityDetails = this._createLevelOneActivityMarkers(dr.activities, ghostLocations, details);
      const { activityMarkers } = activityDetails;
      ghostLocations = activityDetails.ghostLocations;

      // Combined marker for labor and Equipment
      const laborEquipmentDetails = this._createLevelOneLaborEquipmentMarkers(
        {
          labor: dr.labor,
          equipment: dr.equipment,
        },
        ghostLocations,
        details
      );
      const { laborEquipmentMarkers } = laborEquipmentDetails;
      ghostLocations = laborEquipmentDetails.ghostLocations;

      // Test Markers
      const testsDetails = this._createLevelOneTestsMarkers(
        {
          internal: dr.internal_tests,
          external: dr.external_tests,
        },
        ghostLocations,
        details
      );
      const { testMarkers } = testsDetails;
      ghostLocations = testsDetails.ghostLocations;

      markersList = [
        ...markersList,
        ...bidItemMarkers,
        ...materialDeliveryMarkers,
        ...issueMarkers,
        ...activityMarkers,
        ...laborEquipmentMarkers,
        ...testMarkers,
      ];

      markersList = this.getGroupedMarkersInfo(markersList, 1);
    });

    return { markersList, ghostLocations };
  }

  private _createLevelOneBidItemMarkers(bidItems: ReportBidItem[], ghostLocations: any[], details: any) {
    const bidItemMarkers = [];

    let groupedBidItems: any = bidItems.filter(o => !!o.bid_item.record_by_station);
    const individualBidItems = bidItems.filter(o => !o.bid_item.record_by_station).map(o => [o]);

    if (groupedBidItems?.length) {
      groupedBidItems = groupedBidItems?.reduce((rv, x) => {
        if (x.heading) {
          (rv[`${x.bid_item.item}_${x.heading.id}`] = rv[`${x.bid_item.item}_${x.heading.id}`] || []).push(x);
        } else {
          (rv[x.bid_item.item] = rv[x.bid_item.item] || []).push(x);
        }
        return rv;
      }, {});
    }

    const bidItemValues: any = [...Object.values(groupedBidItems), ...individualBidItems];


    // tslint:disable-next-line:prefer-for-of
    for (let o = 0; o < bidItemValues.length; o++) {
      let found = false;
      let headerItem = {};
      if (bidItemValues[o].length > 1) {
        headerItem = bidItemValues[o].filter(j => j.bid_item.record_by_station && !j.station)[0];
        if (!headerItem) {
          headerItem = bidItemValues[o][0];
        }
      } else {
        headerItem = bidItemValues[o][0];
      }

      for (let i = 0; i <= bidItemValues[o].length; i++) {
        const eachBidItem = bidItemValues[o][i];

        const data = {
          ...(bidItemValues[o].length > 1 ? { children: bidItemValues[o] } : {}),
          ...headerItem,
          ...details,
          level: 1,
        };

        if (eachBidItem?.latitude && eachBidItem?.longitude) {
          const location = {
            latitude: eachBidItem.latitude,
            longitude: eachBidItem.longitude,
          };

          bidItemMarkers.push(this._createMarker(location, data, 'bid_items'));

          found = true;
          break;
        }

        if (eachBidItem?.station?.properties?.Latitude && eachBidItem?.station?.properties?.Longitude) {
          const location = {
            latitude: eachBidItem?.station?.properties?.Latitude,
            longitude: eachBidItem?.station?.properties?.Longitude,
          };

          bidItemMarkers.push(this._createMarker(location, data, 'bid_items'));

          found = true;
          break;
        }

        const pictures = eachBidItem?.pictures || [];

        if (pictures.length) {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < pictures.length; j++) {
            const eachPicture = pictures[j];
            if (eachPicture?.latitude && eachPicture?.longitude) {
              const location = {
                latitude: eachPicture.latitude,
                longitude: eachPicture.longitude,
              };

              bidItemMarkers.push(this._createMarker(location, data, 'bid_items'));

              found = true;
              break;
            }
          }
        }
      }

      console.log(found);

      if (!found) {
        ghostLocations.push(
          this._createLevelOneGhostLocations(
            {
              ...headerItem,
              ...(bidItemValues[o].length > 1 ? { children: bidItemValues[o] } : {}),
            },
            details,
            'bid_items'
          )
        );
      }
    }

    return { bidItemMarkers, ghostLocations };
  }

  private _createLevelOneMaterialDeliveryMarkers(
    materialDeliveries: ReportMaterialDelivery[],
    ghostLocations: any[],
    details: any
  ) {
    const materialDeliveryMarkers = [];
    materialDeliveries.forEach(element => {
      if (element.latitude && element.longitude) {
        const data = {
          ...element,
          ...details,
          level: 1,
        };

        const location = {
          latitude: element.latitude,
          longitude: element.longitude,
        };

        materialDeliveryMarkers.push(this._createMarker(location, data, 'deliveries'));
      } else {
        ghostLocations.push(this._createLevelOneGhostLocations(element, details, 'deliveries'));
      }
    });

    return { materialDeliveryMarkers, ghostLocations };
  }

  private _createLevelOneIssuesMarkers(issues: Issue[], ghostLocations: any[], details: any) {
    const issueMarkers = [];

    issues = issues.filter(o => moment(o.created_at).isSame(moment(), 'day'));

    issues.forEach(issue => {
      if (issue.latitude && issue.longitude) {
        const data = {
          ...issue,
          ...details,
          level: 1,
        };

        const location = {
          latitude: issue.latitude,
          longitude: issue.longitude,
        };

        issueMarkers.push(this._createMarker(location, data, 'issues'));
      } else {
        ghostLocations.push(this._createLevelOneGhostLocations(issue, details, 'issues'));
      }
    });

    return { issueMarkers, ghostLocations };
  }

  private _createLevelOneActivityMarkers(activities: any[], ghostLocations: any[], details: any) {
    const activityMarkers = [];

    let found = false;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];
      if (activity.latitude && activity.longitude) {
        const data = {
          ...activity,
          ...details,
          level: 1,
        };

        const location = {
          latitude: activity.latitude,
          longitude: activity.longitude,
        };

        activityMarkers.push(this._createMarker(location, data, 'activities'));
        found = true;
      } else {
        const { pictures } = activity;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures.length; j++) {
          const eachPicture = pictures[j];
          if (pictures[j].latitude && pictures[j].longitude) {
            const data = {
              ...activity,
              ...details,
              level: 1,
            };

            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude,
            };

            activityMarkers.push(this._createMarker(location, data, 'activities'));
            found = true;
          }
        }

        if (!found) {
          ghostLocations.push(this._createLevelOneGhostLocations(activity, details, 'activities'));
        }
      }
    }

    return { activityMarkers, ghostLocations };
  }

  private _createLevelOneLaborEquipmentMarkers(
    laborEquipments: { labor: any; equipment: any },
    ghostLocations: any[],
    details: any
  ) {
    const { labor, equipment } = laborEquipments;

    const list = [...labor, ...equipment];

    const popupData = {
      employee: 0,
      subcontractor: 0,
      employeeStandardTime: 0,
      employeeOverTime: 0,
      machine: equipment.length,
      machineStandardTime: 0,
      machineOverTime: 0,
    };

    const laborEquipmentMarkers = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < list.length; i++) {
      const eachLabor = list[i];
      if (eachLabor.latitude && eachLabor.longitude) {
        laborEquipmentMarkers.push({
          geometry: {
            type: 'point',
            longitude: eachLabor.longitude,
            latitude: eachLabor.latitude,
          },
          symbol: 'labor_equipment',
        });
        break;
      }
    }

    labor.forEach(eachLabor => {
      if (eachLabor.sub_contractor) {
        popupData.subcontractor = popupData.subcontractor + 1;
      } else {
        popupData.employee = popupData.employee + 1;
      }

      if (eachLabor.standard_time) {
        popupData.employeeStandardTime = popupData.employeeStandardTime + eachLabor.standard_time;
      }

      if (eachLabor.over_time) {
        popupData.employeeOverTime = popupData.employeeOverTime + eachLabor.over_time;
      }
    });

    popupData.machine = equipment.length;

    equipment.forEach(eachEquipment => {
      if (eachEquipment.standard_time) {
        popupData.machineStandardTime = popupData.machineStandardTime + eachEquipment.standard_time;
      }

      if (eachEquipment.over_time) {
        popupData.machineOverTime = popupData.machineOverTime + eachEquipment.over_time;
      }
    });

    if (list.length && !laborEquipmentMarkers.length) {
      ghostLocations.push(
        this._createLevelOneGhostLocations(
          {
            ...laborEquipments,
            ...popupData,
          },
          details,
          'labor_equipment',
        ),
      );
    }

    if (laborEquipmentMarkers.length) {
      laborEquipmentMarkers[0].data = {
        ...laborEquipments,
        ...popupData,
        ...details,
        level: 1,
      };
    }

    return { laborEquipmentMarkers, ghostLocations };
  }

  private _createLevelOneTestsMarkers(tests: any, ghostLocations: any[], details: any) {
    const testMarkers = [];

    tests.internal?.forEach(o => (o.test_type = 'internal'));
    tests.external?.forEach(o => (o.test_type = 'external'));

    const internalTestObj = tests?.internal.reduce((rv, x) => {
      if (rv[x.internal_test_id]) {
        rv[x.internal_test_id].push({
          ...x,
          test_type: 'internal',
          type: 'quality_test',
        });
      } else {
        rv[x.internal_test_id] = [
          {
            ...x,
            test_type: 'internal',
            type: 'quality_test',
          },
        ];
      }
      return rv;
    }, {});

    const externalTestObj = tests?.external?.reduce((rv, x) => {
      if (rv[x.bid_item.item]) {
        rv[x.bid_item.item].push({
          ...x,
          test_type: 'external',
          type: 'quality_test',
        });
      } else {
        rv[x.bid_item.item] = [
          {
            ...x,
            test_type: 'external',
            type: 'quality_test',
          },
        ];
      }
      return rv;
    }, {});

    const testList: any[] = [
      ...(internalTestObj ? Object.values(internalTestObj) : []),
      ...(externalTestObj ? Object.values(externalTestObj) : []),
    ];

    let found = false;
    // tslint:disable-next-line:prefer-for-of
    for (let o = 0; o < testList.length; o++) {
      for (let i = 0; i <= testList[o].length; i++) {
        const eachTestItem = testList[o][i];
        const data = {
          ...(testList[o].length > 1 ? { children: testList[o] } : {}),
          ...eachTestItem,
          ...details,
          level: 1,
        };
        if (eachTestItem?.latitude && eachTestItem?.longitude) {
          const location = {
            longitude: eachTestItem.longitude,
            latitude: eachTestItem.latitude,
          };

          testMarkers.push(this._createMarker(location, data, 'quality_test'));
          found = true;
          if (found) {
            break;
          }
        } else {
          const pictures = eachTestItem?.pictures || [];

          if (pictures.length) {
            // tslint:disable-next-line:prefer-for-of
            for (let j = 0; j < pictures.length; j++) {
              const eachPicture = pictures[j];
              if (eachPicture?.latitude && eachPicture?.longitude) {
                const location = {
                  longitude: eachPicture.longitude,
                  latitude: eachPicture.latitude,
                };

                testMarkers.push(this._createMarker(location, data, 'quality_test'));
                found = true;
                if (found) {
                  break;
                }
              }
            }
          } else {
            found = false;
          }

          if (!found) {
            ghostLocations.push(
              this._createLevelOneGhostLocations(
                {
                  ...(testList[o].length > 1 ? { children: testList[o] } : {}),
                  ...eachTestItem,
                },
                details,
                'quality_test'
              )
            );

            break;
          }
        }
      }
    }

    return { testMarkers, ghostLocations };
  }

  /****************************************************/
  /*************** HANDLE MARKER POPUP ****************/
  /****************************************************/

  levelOneBidItemPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    const stations = markerData.children?.filter(o => o.station);
    const quantity = this._getQuantity({
      bidItem: markerData.bid_item,
      stations: stations || [markerData],
      headerItem: markerData,
    });

    return [
      {
        type: category,
        title: 'BID ITEM',
        heading: markerData?.bid_item?.item,
        subheading1: markerData?.bid_item?.description,
        ...(stations && stations.length
          ? {
              subheading2: `Stations from ${stations[0].station.name} to ${stations[stations.length - 1].station.name}`,
            }
          : {}),
        summary: {
          left: {
            label: 'QUANTITY',
            value: (() => {
              try {
                let value = (quantity / markerData?.bid_item?.quantity) * 100;

                if (!value) {
                  value = 0;
                } else if (value > 100) {
                  value = 100;
                }

                value = Number(value.toFixed(2));

                return value;
              } catch (e) {
                return 0;
              }
            })(),
            statistics: (() => {
              const numerator = `${Number(quantity?.toFixed(2))}${markerData?.bid_item?.uom}`;
              const denominator = `${Number(markerData?.bid_item?.quantity?.toFixed(2))}${markerData?.bid_item?.uom}`;

              return `${numerator} / ${denominator}`;
            })(),
          },
        },
        description: markerData?.bid_item?.description,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category,
          },
          type: category,
          geometry,
          screenPoint,
        },
      },
    ];
  }

  levelOneDeliveriesPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    return [
      {
        type: category,
        title: 'DELIVERY & USAGE',
        heading: markerData?.material?.name || markerData?.bid_item?.name,
        subheading1: null,
        summary: {
          left: {
            label: 'TODAY',
            value: (() => {
              try {
                const { received, used } = markerData;

                if (markerData.material && received && used) {
                  let value = (used / received) * 100;

                  if (!value) {
                    value = 0;
                  } else if (value > 100) {
                    value = 100;
                  }

                  value = Number(value.toFixed(2));

                  return value;
                } else if (received && !used) {
                  return 0;
                }

                return 100;
              } catch (e) {
                return 100;
              }
            })(),
            statistics: (() => {
              try {
                let value = '';
                if (markerData.material) {
                  let { received, used } = markerData;

                  received = (received && Number(received.toFixed(2))) || 0;
                  used = (used && Number(used.toFixed(2))) || 0;

                  value = `Received: ${received} / Used: ${used}`;
                } else {
                  value = `Received: ${markerData.quantity}`;
                }

                return value;
              } catch (e) {
                return 'Received: 0 / Used: 0';
              }
            })(),
          },
          ...(markerData?.material
            ? {
                right: {
                  label: 'ALL TIME',
                  value: (() => {
                    const received = markerData?.material?.total_received;
                    const used = markerData?.material?.total_used;

                    try {
                      if (received && used) {
                        let value = (used / received) * 100;
                        if (!value) {
                          value = 0;
                        } else if (value > 100) {
                          value = 100;
                        }

                        value = Number(value.toFixed(2));

                        return value;
                      }

                      return 0;
                    } catch (e) {
                      return 0;
                    }
                  })(),
                  statistics: (() => {
                    let received = markerData?.material?.total_received;
                    let used = markerData?.material?.total_used;

                    received = (received && Number(received.toFixed(2))) || 0;
                    used = (used && Number(used.toFixed(2))) || 0;

                    return `Received: ${received} / Used: ${used}`;
                  })(),
                },
              }
            : {}),
        },
        description: markerData?.comment,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category,
          },
          type: category,
          geometry,
          screenPoint,
        },
      },
    ];
  }

  levelOneActivitiesPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    return [
      {
        type: category,
        title: 'Activities',
        heading: markerData?.title,
        subheading1: `${markerData.pictures?.length || 0} pictures${
          markerData.annotations?.length ? `, ${markerData.annotations?.length} annotation(s)` : ''
        }`,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category,
          },
          type: category,
          geometry,
          screenPoint,
        },
      },
    ];
  }

  levelOneQualityPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    const summary = {
      type: null,
      typeClass: null,
      icon: null,
      status: null,
      statusClass: null,
      date: null,
    };

    switch (markerData.status) {
      case 'passed':
        summary.type = 'Closed';
        summary.typeClass = 'closed';
        summary.icon = null;
        summary.status = 'Passed';
        summary.statusClass = 'passed';
        summary.date = `(${moment(markerData.updated_at)
          .format('MMMM dd, yyyy')})`;
        break;
      case 'failed':
        summary.type = 'Closed';
        summary.typeClass = 'closed';
        summary.icon = null;
        summary.status = 'Failed';
        summary.statusClass = 'failed';
        summary.date = `(${moment(markerData.updated_at)
          .format('MMMM dd, yyyy')})`;
        break;
      case 'sent_to_lab':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Requested';
        summary.statusClass = 'requested';
        break;
      case 'lab_scheduled':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Scheduled';
        summary.statusClass = 'scheduled';
        break;
      case 'lab_activity_completed':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Visit completed';
        summary.statusClass = 'visit-completed';
        break;
      case 'updated_report':
        summary.type = 'Open';
        summary.typeClass = 'open';
        summary.icon = null;
        summary.status = 'Results uploaded';
        summary.statusClass = 'results-uploaded';
        break;
    }

    return [
      {
        type: category,
        title: 'MATERIAL TESTING',
        heading: markerData.test_type === 'internal' ? markerData.internal_test?.name : markerData?.bid_item?.item,
        subheading1:
          markerData.test_type === 'internal' ? markerData?.bid_item?.item : markerData?.bid_item?.description,
        ...(markerData.test_type !== 'internal'
            ? { summary }
          : {}),
        description: markerData.comment,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category,
          },
          type: category,
          geometry,
          screenPoint,
        },
      },
    ];
  }

  levelOneIssuesPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let markerData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      markerData = $event;
      geometry = null;
      screenPoint = null;
    }

    return [
      {
        type: category,
        title: 'ISSUES',
        heading: markerData?.title,
        subheading1: markerData?.type,
        summary: {
          type: markerData?.status,
          typeClass: markerData?.status,
          date:
            markerData?.status === 'open'
              ? moment(markerData.created_at).format('MMMM DD, YYYY')
              : moment(markerData?.closed_at).format('MMMM DD, YYYY'),
        },
        description: markerData?.description,
        more_link_text: 'See More',
        data: {
          markerData: {
            ...markerData,
            markerCategory: category,
          },
          type: category,
          geometry,
          screenPoint,
        },
      },
    ];
  }

  levelOneLaborEquipmentPopupData($event: any, isGhost = false) {
    let category = $event?.graphic?.attributes?.category;
    let popupData = $event?.graphic?.attributes?.data;
    let geometry = $event?.graphic?.geometry;
    let screenPoint = $event?.screenPoint;

    if (isGhost) {
      category = $event.markerType;
      popupData = $event;
      geometry = null;
      screenPoint = null;
    }
    return [
      {
        type: category,
        title: 'LABOR & EQUIPMENT',
        stacks: [
          {
            heading: `${popupData.employee} Employee(s), ${popupData.subcontractor} Subcontractor(s)`,
            subheading1: `${popupData.employeeStandardTime} hour(s), ${popupData.employeeOverTime} hour(s) overtime`,
            description: popupData.labor.find(o => o.is_header === true)?.comment || null,
          },
          {
            heading: `${popupData.machine} Machine(s)`,
            subheading1: `${popupData.machineStandardTime} hour(s), ${popupData.machineOverTime} hour(s) overtime`,
            description: popupData.equipment.find(o => o.is_header === true)?.comment || null,
          },
        ],
        more_link_text: 'See More',
        data: {
          markerData: {
            ...popupData,
            markerCategory: category,
          },
          type: category,
          geometry,
          screenPoint,
        },
      },
    ];
  }

  levelOneGhostLocationsPopupData(ghostLocations) {
    let popupData = [];

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < ghostLocations.length; i++) {
      const eachLocation = ghostLocations[i];
      const category = eachLocation.markerType;

      switch (category) {
        case 'bid_items':
          popupData = [...popupData, ...this.levelOneBidItemPopupData(eachLocation, true)];
          break;
        case 'deliveries':
          popupData = [...popupData, ...this.levelOneDeliveriesPopupData(eachLocation, true)];
          break;
        case 'activities':
          popupData = [...popupData, ...this.levelOneActivitiesPopupData(eachLocation, true)];
          break;
        case 'quality_test':
          popupData = [...popupData, ...this.levelOneQualityPopupData(eachLocation, true)];
          break;
        case 'issues':
          popupData = [...popupData, ...this.levelOneIssuesPopupData(eachLocation, true)];
          break;
        case 'labor_equipment':
          popupData = [...popupData, ...this.levelOneLaborEquipmentPopupData(eachLocation, true)];
          break;
      }
    }

    return popupData;
  }

  levelOneGroupedLocationsPopupData($event: any) {
    const category = $event?.graphic?.attributes?.category;
    const children = $event?.graphic?.attributes?.data?.children;
    const geometry = $event?.graphic?.geometry;
    const screenPoint = $event?.screenPoint;

    return this.levelOneGhostLocationsPopupData(children);
  }

  // ********************************************************* //
  // *************      ZOOM LEVEL 2       ******************* //
  // ********************************************************* //

  createLevelTwoData = params => {
    const { children, type, ...details } = params;
    let combinedChildren = [];

    const { equipment, labor } = params;
    if (equipment && labor) {
      combinedChildren = [...equipment, ...labor];
    }

    let markersList: any;

    if (children || combinedChildren?.length) {
      switch (type) {
        case 'bid_items':
          markersList = this._createLevelTwoBidItemMarkers(children, details);
          break;
        case 'deliveries':
          break;
        case 'activities':
          break;
        case 'quality_test':
          markersList = this._createLevelTwoTestsMarkers(children, details);
          break;
        case 'issues':
          break;
        case 'labor_equipment':
          markersList = this._createLevelTwoLaborEquipmentMarkers(combinedChildren, details);
          break;
        default:
          return {
            markersList: [],
          };
      }

      console.log(markersList);

      return {
        markersList: this.getGroupedMarkersInfo(markersList, 2),
      };

    } else {
      return this.createLevelThreeData(params);
    }
  }

  private _createLevelTwoBidItemMarkers(bidItems, details) {
    const markersList = [];

    bidItems.forEach((eachBidItem: any) => {
      const data = {
        ...eachBidItem,
        ...details,
        level: 2,
      };

      let location = {
        latitude: null,
        longitude: null,
      };

      if (eachBidItem?.station?.properties?.Latitude && eachBidItem?.station?.properties?.Longitude) {
        location = {
          latitude: eachBidItem?.station?.properties?.Latitude,
          longitude: eachBidItem?.station?.properties?.Longitude,
        };
      } else if (eachBidItem?.latitude && eachBidItem.longitude) {
        location = {
          latitude: eachBidItem.latitude,
          longitude: eachBidItem.longitude,
        };
      } else {
        const { pictures } = eachBidItem;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures.length; j++) {
          const eachPicture = pictures[j];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude,
            };
            break;
          }
        }
      }

      if (location.latitude && location.longitude) {
        location = {
          latitude: String(location.latitude),
          longitude: String(location.longitude),
        };
        markersList.push(this._createMarker(location, data, 'bid_items'));
      }
    });

    return markersList;
  }

  private _createLevelTwoTestsMarkers(tests, details) {
    const markersList = [];

    tests.forEach((eachTest: any) => {
      if (eachTest?.latitude && eachTest.longitude) {
        const data = {
          ...eachTest,
          ...details,
          level: 2,
        };

        markersList.push(this._createMarker(eachTest, data, 'quality_test'));
      } else {
        const { pictures } = eachTest;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures?.length; j++) {
          const eachPicture = pictures[j];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            const data = {
              ...eachTest,
              ...details,
              level: 2,
            };

            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude,
            };

            markersList.push(this._createMarker(location, data, 'quality_test'));
            break;
          }
        }
      }
    });

    return markersList;
  }

  private _createLevelTwoLaborEquipmentMarkers(laborEquipment, details) {
    const markersList = [];

    laborEquipment.forEach((eachElement: any) => {
      if (eachElement?.latitude && eachElement.longitude) {
        const data = {
          ...eachElement,
          ...details,
          level: 2,
        };

        const location = {
          latitude: eachElement.latitude,
          longitude: eachElement.longitude,
        };

        markersList.push(this._createMarker(location, data, 'labor_equipment'));
      } else {
        const { pictures } = eachElement;
        // tslint:disable-next-line:prefer-for-of
        for (let j = 0; j < pictures.length; j++) {
          const eachPicture = pictures[j];
          if (eachPicture?.latitude && eachPicture?.longitude) {
            const data = {
              ...eachElement,
              ...details,
              level: 2,
            };

            const location = {
              latitude: eachPicture.latitude,
              longitude: eachPicture.longitude,
            };

            markersList.push(this._createMarker(location, data, 'labor_equipment'));
            break;
          }
        }
      }
    });

    return markersList;
  }

  // ********************************************************* //
  // *************      ZOOM LEVEL 3       ******************* //
  // ********************************************************* //

  createLevelThreeData = params => {
    const { element, type, ...details } = params;
    switch (type) {
      case 'bid_items':
      case 'deliveries':
      case 'activities':
      case 'quality_test':
      case 'issues':
        return {
          markersList: this._handleLevelThreeMarkers(element, details, type),
          imagesList: this._handleLevelThreePictures(element),
        };
      case 'labor_equipment':
        return this._createLevelThreeLaborMarkers(element, details);
      default:
        return {
          markersList: [],
          imagesList: [],
        };
    }
  }

  private _createLevelThreeLaborMarkers(laborEquipment, details) {
    const markersList = [];
    const imagesList = [];

    let list = [];
    if (laborEquipment.labor && laborEquipment.equipment) {
      list = [...laborEquipment.labor, ...laborEquipment.equipment];
    } else {
      list = [laborEquipment];
    }

    list.forEach(o => {
      markersList.push(...this._handleLevelThreeMarkers(o, details, 'labor_equipment'));
      imagesList.push(...this._handleLevelThreePictures(o));
    });

    return { markersList, imagesList };
  }

  private _handleLevelThreeMarkers(element, details, type) {
    if (element?.latitude && element.longitude) {
      const data = {
        ...element,
        ...details,
        level: 3,
      };

      const location = {
        longitude: element.longitude,
        latitude: element.latitude,
      };

      return [this._createMarker(location, data, type)];
    }

    return [];
  }

  private _handleLevelThreePictures(element) {
    console.log(element);
    const imagesList = [];

    const pictures = element.pictures.filter(o => o.latitude && o.longitude) || [];

    const picturesObj = pictures?.reduce((rv, x) => {
      (rv[`${x.latitude}_${x.longitude}`] = rv[`${x.latitude}_${x.longitude}`] || []).push(x);
      return rv;
    }, {});

    const pictureValues = Object.values(picturesObj);

    // tslint:disable-next-line:prefer-for-of
    for (let j = 0; j < pictureValues.length; j++) {
      const picturesList = pictureValues[j] || [];

      imagesList.push({
        url: picturesList[0].thumb_url,
        rotation: picturesList[0].direction,
        geometry: {
          type: 'point',
          longitude: picturesList[0].longitude,
          latitude: picturesList[0].latitude,
        },
        symbol: 'images',
        data: {
          picturesList,
          count: picturesList['length'],
          bidItemName: element?.bid_item?.item || null,
          comment: element.comment,
          type: 'image',
          level: 3,
        },
      });
    }

    return imagesList || [];
  }

  /****************************************************/
  /************** HANDLE ROLLUP QUANTITY **************/
  /****************************************************/

  private _getQuantity(params) {
    const { bidItem, stations, headerItem } = params;

    if (!bidItem.rollup) {
      return stations[0]?.quantity || 0;
    }

    if (!bidItem.record_by_station && bidItem.rollup) {
      return this._simpleRollup({
        rollupFormula: bidItem.rollup_formula,
        fields: this._getFields(bidItem.fields, stations[0].field_values),
      });
    }

    if (bidItem.record_by_station && bidItem.rollup) {
      const headings = this.headingsList.getValue();

      return this.bidService.getRollup(
        {
          ...bidItem,
          headings,
        },
        headerItem,
        stations
      );
    }
  }

  private _simpleRollup(params) {
    const { rollupFormula, fields } = params;
    return Number(Parser.evaluate(rollupFormula, fields).toFixed(2));
  }

  private _getFields(fields = [], values = [], isView = false) {
    let fieldValues = [];
    values.map(o => {
      const currentField = fields.find(i => o.id === i.id);

      if (currentField) {
        fieldValues = {
          ...fieldValues,
          [currentField.name]: o.value,
        };
      }
    });

    return fieldValues;
  }

  getTooltipContent(data) {
    switch (data.markerCategory) {
      case 'activities':
        return 'Activities';
      case 'bid_items':
        if (data.level >= 2) {
          return `${data.bid_item.item} - (${data.station.name})`;
        }
        return data.bid_item.item;
      case 'issues':
        return data.bid_item.item;
      case 'alignment':
        return data.name;
      case 'quality_test':
        if (data.test_type === 'internal') {
          return data.internal_test.name || 'Onsite Tests';
        }

        if (data.test_type === 'external') {
          return data.external_test.name || 'Offsite Tests';
        }

        break;
      case 'labor_equipment':
        if (data.labor) {
          return `${data.labor.first_name} ${data.labor.last_name}`;
        }

        if (data.equipment) {
          return `${data.equipment.description ? `${data.equipment.description} - ` : ''}${data.equipment.make}`;
        }

        return 'Labor & Equipment';
      case 'deliveries':
        return data?.material?.name || 'Material Delivery';
      default:
        return;
    }
  }

  getGroupedMarkersInfo(markersList, level) {
    const reduced = markersList?.reduce((rv, x) => {
      (rv[`${x.geometry.latitude}_${x.geometry.longitude}`] =
        rv[`${x.geometry.latitude}_${x.geometry.longitude}`] || []).push(x);
      return rv;
    }, {});

    const individualMarkers = [];
    const groupedMarkers = [];

    Object.values(reduced).forEach((o: any[]) => {
      if (o.length > 1) {
        groupedMarkers.push(o);
      } else {
        individualMarkers.push(...o);
      }
    });

    if (groupedMarkers.length) {
      groupedMarkers.forEach((eachGroupedMarker: any[]) => {
        const location = {
          latitude: eachGroupedMarker[0].geometry.latitude,
          longitude: eachGroupedMarker[0].geometry.longitude,
        };
        const data = [];
        eachGroupedMarker.forEach(marker => {
          data.push(this._createLevelOneGhostLocations(marker.data, {}, marker.symbol));
        });

        individualMarkers.push(this._createMarker(location, { level, children: data }, 'grouped_locations'));
      });
    }

    return individualMarkers;
  }
}

<div
  *ngIf="parentFormGroup &&
         parentFormGroup.controls &&
         parentFormGroup.controls[this.controlName]"
  class="component-container">
  <div *ngIf="label" class="text-type-13 label">{{ label }}:</div>
  <form
    *ngIf="displaySearch"
    [formGroup]="parentFormGroup"
  >
    <div class="filter-section search-section">
      <div class="search">
        <mat-icon svgIcon="search"></mat-icon>
        <input
          [formControlName]="controlName"
          matInput
          placeholder="Search"
        >
      </div>
    </div>
  </form>
  <div class="checkbox-section">
    <ng-container
      *ngIf="displaySelectAll"
    >
      <mat-checkbox
        (change)="onAllCheckboxChange($event)"
        *ngIf="!parentFormGroup.controls[controlName].value"
        [checked]="isAllChecked"
        [indeterminate]="isPartiallyChecked()"
        class="option"
        color="primary"
        value="all">
        All
      </mat-checkbox>
    </ng-container>

    <div *ngIf="filteredOptions.length === 0" class="no-options-found .text-type-11">
      No options found
    </div>
    <div *ngFor="let option of filteredOptions; let i = index" class="option">
      <mat-checkbox
        (change)="onCheckboxChange($event, i)"
        [checked]="option.isChecked"
        [value]="option.value"
        color="primary">
        {{ option.label }}
      </mat-checkbox>
    </div>
  </div>
</div>




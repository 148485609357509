import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { PortalComponent } from './portal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  AnnotationComponent,
} from 'src/app/modules/annotation/annotation.component';

@NgModule({
  declarations: [
    PortalComponent,
    HomeComponent,
    AnnotationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
  ],
})
export class PortalModule { }

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Organization, Project, Report } from '../models';
import { HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({ providedIn: 'root' })
export class ReportService {

  constructor(
    private appHttpService: AppHttpService
  ) { }

  /**
   * Returns Templates
   * @param qp query params
   * @returns {Promise<Report[]>}
   */
  getReports(project: Project = null, org: Organization = null, qp: any = {}) {
    const path = project?.id
      ? `project/${project?.id}`
      : `organization/${org?.id}`;
    return this.appHttpService
      .getService(`/v2/${path}/reports`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map((r) => new Report(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Report
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Report>}
   */
  create(project: Project = null, org: Organization = null, payload: any = null, qp: any = {}): Promise<Report> {
    const path = project?.id
      ? `project/${project?.id}`
      : `organization/${org?.id}`;
    return this.appHttpService
      .postService(`/v2/${path}/report`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Report(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Report
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Report>}
   */
  update(payload: any = null, qp: any = {}): Promise<Report> {
    return this.appHttpService
      .putService(`/v2/report/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Report(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Report
   * @param model Report
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Report): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/report/${model?.id}`)
      .toPromise();
  }

  /**
   * Renders report as html
   * @param payload {id, siteId, projectId, date}
   * @returns {Promise<{date, title, html}>}
   */
  renderReport(payload) {
    const qp = {
      siteId: payload.siteId,
      projectId: payload.projectId,
      date: payload.date,
      ...(payload.download ? { download: true } : {}),
    };

    if (payload.download) {
      return this.appHttpService
        .getService(`/v2/report/${payload.reportId}/render`, qp, {
          responseType: 'blob',
        })
        .toPromise()
        .then((resp: Blob) => {
          return saveAs(resp, 'Download Report');
        });
    } else {
      return this.appHttpService
        .getService(`/v2/report/${payload.reportId}/render`, qp)
        .toPromise();
    }
  }

  /**
   * Download report as pdf
   * @param payload {id, siteId, projectId, date}
   */
  downloadReport(report, site, project, date) {
    const qp = {
      siteId: site?.id,
      projectId: project.id,
      date,
      download: true,
    };
    return this.appHttpService.getService(
        `/v2/report/${report?.id}/render`,
        qp,
        { responseType: 'blob' }
      )
      .toPromise()
      .then((resp: Blob) => {
        return saveAs(resp, report?.name || 'report');
      });
  }
}

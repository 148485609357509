<div class="dialog-container">
  <div class="dialog-header">
    <h1 class="dialog-title">{{ title }}</h1>
    <button mat-icon-button (click)="onDismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="dialog-message">
    <p [innerHTML]="message"></p>
  </div>

  <div class="mat-dialog-actions">
    <button mat-stroked-button color="primary" (click)="onDismiss()">{{ cancelLabel }}</button>
    <button mat-flat-button color="primary" (click)="onConfirm()">{{ confirmLabel }}</button>
  </div>
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Drawing, Project } from 'src/app/shared/models';
import { MaterialTestLabService } from '../material-test-lab.service';
import { MaterialTestLab } from '../material-test-lab';
import { environment as ENV } from 'src/environments/environment';
import { MaterialTestLabCreateComponent } from '../material-test-lab-create/material-test-lab-create.component';

@Component({
  selector: 'app-project-labs',
  templateUrl: './project-labs.component.html',
  styleUrls: ['./project-labs.component.scss']
})
export class ProjectLabsComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input()
  public project: Project;

  @Input()
  public pageSize = 20;

  public labs: MaterialTestLab[] = [];
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };

  public zipUploadEndpoint: string;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private mtlSrv: MaterialTestLabService
  ) { }

  ngOnInit(): void {
    this.pagination.pageSize = this.pageSize;
    this.fetchRecords();
  }

  /**
   * Fetch Records
   */
  fetchRecords() {
    this.loading++;
    this.mtlSrv.getRecords(this.project, this.pagination)
      .then((resp: any) => {
        this.labs = resp.result.map(o => {
          o.project_id = this.project.id; // for acl
          return o;
        });
        this.pagination.length = resp.meta.totalRecords || 0;
        this.initialized = true;
      })
      .catch((e: any) => {
        this.snackBar.open(e.error?.error || e.message, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords();
  }

  /**
   * Opens dialog for EditView
   * @param site Site
   */
  openEdit(lab: MaterialTestLab|null = null) {
    lab = lab || new MaterialTestLab({ project_id: this.project.id });
    const dialogRef = this.dialog.open(MaterialTestLabCreateComponent, {
      disableClose: true,
      data: lab,
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (! (resp instanceof MaterialTestLab)) {
        return;
      }

      // object might've changed, update list
      resp.project_id = this.project.id; // for acl purposes
      const i = this.labs.findIndex(o => o.id === resp.id);
      if (i >= 0) {
        this.labs.splice(i, 1, resp);
      } else {
        this.labs.push(resp);
      }
    });
  }

}

<div class="wrapper">
  <app-offsite-test-request-block
    [activity]="activity"
    [data]="data"
    [isDisabled]="disabledStatus.request"
  />

  <app-offsite-test-schedule-block
    [activity]="activity"
    [data]="data"
    [isDisabled]="disabledStatus.schedule"
  />

  <app-offsite-test-record-block
    [activity]="activity"
    [data]="data"
    [isDisabled]="disabledStatus.record"
  />

  <app-offsite-test-report-block
    [activity]="activity"
    [data]="data"
    [isDisabled]="disabledStatus.report"
  />

  <app-offsite-test-result-block
    [activity]="activity"
    [data]="data"
    [isDisabled]="disabledStatus.result"
  />
</div>

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as moment from 'moment';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { BidItem, DailyReport, Field, Heading, InternalTest, Project, ReportInternalTest, Station } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService, DailyReportService, FieldService } from 'src/app/shared/services';
import { BidItemSelectorComponent } from '../../bid-item-selector/bid-item-selector.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-rit',
  templateUrl: './rit.component.html',
  styleUrls: ['./rit.component.scss']
})
export class RitComponent implements OnInit {

  public initialized: boolean = false;
  public loading: number = 0;
  public changes: number = 0;

  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;
  public dialogOptions: any = {};

  @Input() rits: ReportInternalTest[] = [];
  @Input() dr: DailyReport;
  @Input() internalTest: InternalTest;
  @Input() heading: Heading;
  @Input() project: Project;

  public selectedItem: ReportInternalTest;
  public lineItems: ReportInternalTest[] = [];

  public biditem: BidItem;
  public internalTests: InternalTest[] = [];
  public headings: Heading[] = [];
  public stationOptions: Observable<Station[]>;

  public matcher = new AppErrorStateMatcher();

  constructor(
    private fieldSrv: FieldService,
    private drSrv: DailyReportService,
    public appSrv: AppService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RitComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  get hasDirtyItems(): boolean {
    return this.lineItems.map(li => li.isDirty)
      .reduce((p, c) => p || c, false);
  }

  ngOnInit(): void {
    if (this.isDialog) {
      const { rits = [], internalTest, heading, dailyReport, project, options } = this.inputData;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.rits = rits.slice();
      this.project = project;
      this.dr = dailyReport;
      this.internalTest = internalTest ?? null;
      this.heading = heading ?? null;
    }

    // link some project level data to line items
    this.internalTests = this.project.internal_tests || [];
    const site = this.project.sites.find(o => o.id === this.dr.site_id);
    this.headings = site?.headings || [];
    const allHeadings = this.project.sites.map(s => s.headings || []).reduce((p, c) => p.concat(c), []);
    const allStations = allHeadings.map(h => h.stations || []).reduce((p, c) => p.concat(c), []);

    this.rits.map(o => {
      o.station = allStations.find(s => s.id === o.station_id);
    });

    this.init();
  }

  get isNew(): boolean {
    return !this.lineItems.filter(o => o.id).length;
  }

  get byStation(): boolean {
    return this.internalTest?.record_by_station;
  }

  get fields(): Field[] {
    return this.internalTest?.fields || [];
  }

  init() {
    const rits = this.rits.filter(o => {
      return (o.heading_id ?? null) === (this.heading?.id ?? null)
        && o.internal_test_id === this.internalTest?.id
    });

    const [bid] = rits.map(o => o.bid_item_id).filter(o => !!o);
    this.biditem = bid ? this.project?.bid_items?.find(o => o.id === bid) : this.biditem;

    this.lineItems = [];
    rits.map(o => this.addLineItem(o));
    this.initialized = true;
  }

  addLineItem(li: any) {
    li = li ?? new ReportInternalTest({
      daily_report_id: this.dr?.id,
      internal_test: this.internalTest,
      heading: this.heading,
      bid_item: this.biditem,
    });

    this.toReactiveForm(li);
    this.lineItems.push(li);

    // sort lineitems wrt heading.stations & time
    const so = (this.heading?.stations || []).map(o => o.name);
    this.lineItems.sort((a, b) => {
      const i = so.indexOf(a.station?.name);
      const j = so.indexOf(b.station?.name);

      return i < j ? -1 : i > j ? 1 : a.record_time > b.record_time ? 1 : a.record_time < b.record_time ? -1 : 0;
    });

    if (!li?.id) {  //open newly added lineitem
      this.selectLineItem(li);
    }

    if (this.byStation) {
      this.stationOptions = li._inputForm.get('station_id').valueChanges.pipe(
        startWith(''),
        map((name: string) => this._filter(name, this.heading?.stations))
      );
    }
  }

  private _filter(q: string|Station, list: Station[]): Station[] {
    const name = (typeof q === 'string' ? q : q?.name || '').toLocaleLowerCase();
    if (!name) {
      return (list || []).slice(0, 5);
    }
    return (list || [])
      .filter(o => o.name.toLowerCase().includes(name)).slice(0, 5);
  }

  displayFn(s: any): string {
    return s && s.name ? s.name : '';
  }

  toReactiveForm(m: any) {
    let form = this.fb.group({
      id: [''],
      record_time: [m?.record_time, Validators.required],
      station_id: [m?.station, this.byStation ? Validators.required : []],
    });

    // fields to form
    if (this.fields.length) {
      form = this.fieldSrv.toFormGroup(form, this.fields);
    }

    form.reset({
      id: m?.id || null,
      record_time: moment(m?.recordTimeZ).format('HH:mm'),
      station_id: m?.station,
      ...(m?.field_values || []).reduce((p, c) => ({ ...p, [c.id]: c.value }), {}),
    });
    m._inputForm = form;
  }

  onLineItemChange(li: ReportInternalTest) {
    const { comment, pictures, annotations, location } = li;
    this.selectedItem.setValue('comment', comment);
    this.selectedItem.setValue('pictures', pictures);
    this.selectedItem.setValue('annotations', annotations);
    this.selectedItem.setValue('location', location);
  }

  onHeadingChange(heading: Heading) {
    if (!heading) {
      return;
    }

    if (!this.hasDirtyItems) {
      this.init();
      return;
    }

    if (this.hasDirtyItems) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes in this heading.
          <br />Are you sure you want to discard?`
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.init();
        } else {
          // undo selection for dropdown
          this.heading = this.headings.find(o => o.id === this.lineItems[0]?.heading?.id);
        }
      });
    }
  }

  getWidth(field) {
    return field?.type === 'textarea' ? 'width-100' : 'width-50';
  }

  selectLineItem(li) {
    if (!li) {
      this.selectedItem = undefined;
      return;
    }

    if (this.selectedItem?._id === li?._id) {
      this.selectedItem = undefined;
    } else {
      setTimeout(() => this.selectedItem = li, 200);
    }
  }

  /**
   * Deletes line item
   * @param li LineItem
   * @returns void
   */
  delete(li: ReportInternalTest, options: any = {}): void {
    const { showMessage = true } = options;
    if (!li.id && li._id) {
      this.lineItems.splice(this.lineItems.findIndex(o => o._id === li._id), 1);
      return;
    }

    if (!showMessage) {
      this.changes++;
      this.drSrv.deleteLineItem('internal_test', li, this.dr)
        .then(() => {
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
        });
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting onsite test cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.loading++;
      this.drSrv.deleteLineItem('internal_test', li, this.dr)
        .then(resp => {
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
          this.changes++;
          this.snackBar.open('Onsite test deleted', '', { duration: 5000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        })
        .finally(() => {
          this.loading--;
        });
    });
  }

  /**
   * onSubmit() handler for station form
   * @param form FormGroup
   * @returns {void}
   */
  save(li: ReportInternalTest, options: any = {}): Promise<any> {
    const form = li._inputForm;
    if (!form.valid) {
      return;
    }

    // auto suggest picks whole object, pass only id in payload
    const station = form.get('station_id').value;
    form.value.station_id = station?.id;

    const payload = li.toPayload({
      ...form.value,
      bid_item_id: this.biditem?.id,
      heading_id: this.heading?.id
    }, this.fields);
    const dr = new DailyReport({ id: li.daily_report_id });

    this.loading++;
    return this.drSrv.saveLineItem('internal_test', payload, dr, { include: [] })
      .then((resp: ReportInternalTest) => {
        this.snackBar.open('Saved onsite test', '', { duration: 5000 });
        this.changes++;

        li = Object.assign(li, new ReportInternalTest(resp));
        this.toReactiveForm(li);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  saveAll() {
    // dirty workaround to trigger validations
    // mark all forms as touched
    let isValid = true;
    this.lineItems.map((o) => {
      o._inputForm?.markAsDirty();
      o._inputForm?.markAllAsTouched();
      isValid = isValid && o._inputForm.valid;
    });

    if (!isValid) {
      // show some error
      return;
    }

    // attempt save
    Promise.all(this.lineItems.map((o) => this.save(o, { showMessage: false })))
      .then(() => {
        this.dialogRef.close(this.changes);
      });
  }

  deleteAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting onsite test cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      Promise.all(this.lineItems.map((o) => this.delete(o, { showMessage: false })))
        .then(() => {
          this.snackBar.open('Onsite test deleted', '', { duration: 5000 });
          this.dialogRef.close(1);
        });
    });

  }

  onClose() {
    if (!this.hasDirtyItems) {
      this.dialogRef.close(this.changes);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Unsaved changes',
        `There are unsaved changes since the last opening of this item.
        <br />Are you sure you want to discard?`,
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(this.changes);
      }
    });
  }

  /**
   * Opens BidItem Selector
   */
  openBidItemSelector(): void {
    const dialogRef = this.dialog.open(BidItemSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: this.project,
        biditems: this.project?.bid_items,
        options: {
          fetchData: false,
        }
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof BidItem) {
        this.biditem = resp;
      }
    });
  }

}

import { Injectable } from '@angular/core';
import { Account } from 'src/app/shared/models';
import { AppHttpService } from 'src/app/shared/services';


@Injectable({ providedIn: 'root' })
export class AccountService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Fetches Account by id
   * @param id Account.id | 'me'
   * @param qp query-params
   * @returns {Promise<Account>}
   */
  public fetchAccount(id: string, qp: any = {}): Promise<Account> {
    return this.appHttpService.getService(`/v1/account/${id}`, qp).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Account(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  public updateProfile(account: Account, payload: any = null, qp: any = {}): Promise<Account> {
    return this.appHttpService.putService(`/v1/account/${account.id}`, payload || account.toPayload(), qp).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Account(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Update password for current login
   * @param password new password
   * @returns {Promise<any>}
   */
  public updatePassword(password: string): Promise<any> {
    return this.appHttpService.putService(`/v1/account/update-password`, { password }).toPromise();
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Alignment, Site } from 'src/app/shared/models';
import { AlignmentService, AppErrorStateMatcher } from 'src/app/shared/services';

@Component({
  selector: 'app-site-alignments',
  templateUrl: './site-alignments.component.html',
  styleUrls: ['./site-alignments.component.scss']
})
export class SiteAlignmentsComponent implements OnInit {

  public loading = 0;
  public initialized: boolean = false;
  public editing: boolean = false;
  public selectedTabIndex = 0;

  public dialogOptions: any = {};
  public componentResult: any = null;
  @Input() public data: any;
  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;


  @Input() public site: Site;
  public alignments: Alignment[] = [];
  public alignment: Alignment;

  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcInputForm') vcInputForm;
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    name: [''],
    distance_uom: [''],
    last_distance: [''],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private alignmentSrv: AlignmentService,
    public dialogRef: MatDialogRef<SiteAlignmentsComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  ngOnInit(): void {
    const { data, options } = this.inputData;
    if (data) {
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.site = data;
    }
    this.fetchRecords(this.site);
  }

  fetchRecords(site: Site): void {
    const qp = {
      include: [],
    }
    this.alignmentSrv.getRecords(
      site,
      { pageIndex: 0, pageSize: 100000 },
      { include: [''] },
    ).then((resp: any) => {
      this.alignments = resp.result;
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    })
    .finally(() => {
      this.initialized = true;
    });
  }

  /**
   * Toggles input form
   * @param model Alignment
   * @param show boolean
   */
  toggleForm(model: Alignment = null, show = true): void {
    this.alignment = model?.id ? model : new Alignment();
    this.editing = show;
    if (show) {
      this.vcInputForm?.nativeElement?.reset();
      this.inputForm.reset({
        id: model?.id,
        name: model?.name,
        distance_uom: model?.distance_uom,
        last_distance: model?.last_distance,
      })
    }
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.toggleForm(null, false);
  }

  /**
   * Saves Alignment
   * @returns {void}
   */
  save(form: UntypedFormGroup = null): void {
    if (!form) {
      return;
    }

    if (form && !form.valid) {
      return;
    }

    const payload = form.value;
    this.loading++;

    const req = payload?.id
      ? this.alignmentSrv.update(payload, { include: [] })
      : this.alignmentSrv.create(this.alignment, this.site, payload, { include: [] });

    req
      .then((alignment: Alignment) => {
        if (form) {
          this.snackBar.open(
            `${this.alignment?.id ? 'Updated' : 'Created'} alignment ${alignment?.name}`,
            '',
            { duration: 5000 },
          );
        }

        const i = this.alignments.findIndex(o => o.id === alignment.id);
        if (i >= 0) {
          this.alignments.splice(i, 1, alignment);
        } else {
          this.alignments.push(alignment);
        }

        this.toggleForm(null, false);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes Alignment
   * @param model
   */
  delete(model: Alignment): void {
    const message = `Deleting ${model.name} cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.alignmentSrv.delete(model)
        .then((resp: any) => {
          this.alignments.splice(
            this.alignments.findIndex(o => o.id === model.id),
            1,
          );
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
      });
  }

  showTab(alignment: Alignment, index: number): void {
    this.alignment = alignment;
    this.selectedTabIndex = index;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, Heading } from 'src/app/shared/models';
import { BidService } from 'src/app/shared/services/bid.service';

@Component({
  selector: 'app-biditem-headings',
  templateUrl: './biditem-headings.component.html',
  styleUrls: ['./biditem-headings.component.scss']
})
export class BiditemHeadingsComponent implements OnInit {
  public initialized: boolean = false;
  public loading: number = 0;

  @Input() public bidItem: BidItem;
  public headings: Heading[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private bidSrv: BidService
  ) { }

  ngOnInit(): void {
    this.fetchBidItem(this.bidItem.id);
  }

  fetchBidItem(id: string): void {
    const qp = {
      include: [
        'headings',
        { site: [{ alignments: ['headings'] }] },
      ],
    }
    this.loading++;
    this.bidSrv.getBidItem(id, qp)
      .then((bidItem: BidItem) => {
        this.bidItem = bidItem;

        // flatten headings from site.alignments
        this.headings = (bidItem.site?.alignments || [])
          .reduce((p, c) => {
            return [
              ...p,
              ...c.headings.map((o) => {
                o.alignment_name = c.name;
                o.alignment_id = c.id;
                return o;
              })
            ];
          }, []);

        // mark stations as checked/unchecked
        this.headings
          .map((o: any) => o.checked = !!this.bidItem?.headings
            .find(t => t.id === o.id));
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.message || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

  /**
   * OnChange event of checkbox
   * @param materialTest MaterialTest
   * @param checked boolean
   */
  onChange(heading: Heading, checked) {

    const req = checked
      ? this.bidSrv.addHeading(heading, this.bidItem)
      : this.bidSrv.removeHeading(heading, this.bidItem);

    req.then((msg: string) => {
      this.snackBar.open(msg, '', { duration: 5000 });
    })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      });
  }

}

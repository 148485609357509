import { BaseModel } from './base-model';
import { Account } from './account';
import { Project } from './project';
import { Organization } from './organization';

export class I18n extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'I18n';
  }

  /* tslint:disable */
  locale: string;
  translation: string;

  organization: Organization;
  organization_id: string;

  selected: boolean;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      organization: Organization,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    this.organization_id = this.organization?.id || this.organization_id;
  }
}

<div class="container">
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="row" hoverable>
          <span class="material-symbols-outlined" title="Attachment tags">photo_library</span>
          <span hover-toggle>Attachment tags</span>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-lookups
          type="attachment-tag"
          [project]="project"></app-lookups>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
<ng-container *ngIf="initialized">
  <div>
    <h1 *ngIf="!lab.id">Add Lab</h1>
    <h1 *ngIf="lab.id">Update lab "{{lab.name}}"</h1>
  </div>

  <form [formGroup]="inputForm">
    <div class="form-inputs">

      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="name"
          [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('error')">{{inputForm.controls.name.errors.error}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="phone"
          [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.phone.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.phone.hasError('error')">{{inputForm.controls.phone.errors.error}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="email"
          [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.email.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.email.hasError('error')">{{inputForm.controls.email.errors.error}}</mat-error>
      </mat-form-field>

    </div>

    <div class="form-actions">
      <button *ngIf="!lab.id" mat-stroked-button color="primary"
        type="button" [disabled]="!inputForm.valid" (click)="onSubmit()">Create</button>
      <button *ngIf="lab.id" mat-stroked-button color="primary"
        type="button" [disabled]="!inputForm.valid" (click)="onSubmit()">Update</button>
      <button mat-stroked-button color="accent" (click)="onClose(false)" disableRipple>Cancel</button>
    </div>
  </form>

</ng-container>

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { AppErrorStateMatcher, AuthService, InvitationService } from 'src/app/shared/services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { mobilePhoneValidator } from 'src/app/shared/validators';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public initialized = false;
  public actionInProgress = 0;
  public code: string;
  public matcher = new AppErrorStateMatcher();

  public inputForm: UntypedFormGroup = this.fb.group({
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.email, Validators.required]],
    password: ['', [Validators.required]],
  });

  constructor(
    private invitationService: InvitationService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.has('code')) {
      this.code = this.route.snapshot.paramMap.get('code');
      this.initialized = true;
    }
  }

  onSubmit() {
    if (this.inputForm.status === 'INVALID') {
      return;
    }
    const payload = this.inputForm.value;
    this.actionInProgress++;

    this.invitationService.acceptInvite(this.code, payload)
      .subscribe({
        next: (resp: any) => {
          this.snackBar.open('Signup successful, redirecting to login page', '', { duration: 3000 });
          this.router.navigate(['/login']);
          this.actionInProgress--;
        },
        error: (resp: any) => {
          this.actionInProgress--;
          if (resp.status === 422) {
            this.matcher.setServerErrors(this.inputForm, resp, { username: 'email'});
            return;
          }

          if (resp.error.error) {
            this.snackBar.open(resp.error.error, '', { duration: 5000 });
          }
        }
      });
  }

}

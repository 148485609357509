<!-- <mat-progress-bar color="accent" mode="indeterminate" *ngIf="loading"></mat-progress-bar> -->
<div *ngIf="initialized">

  <div class="row">
    <div></div>
    <div class="icons">
      <app-file-uploader
        [disabled]="!('create' | able: 'Drawing')"
        param="inputFile"
        [target]="zipUploadEndpoint"
        icon="file_upload"
        accept="application/zip"
        (onError)="onUploadError($event)"
        (complete)="onUploadComplete($event)"></app-file-uploader>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!drawings.length">
    Found no records to show
  </div>

  <!-- List -->
  <div class="list">

    <!-- list-card -->
    <div class="list-card" *ngFor="let drawing of drawings">

      <!-- actions -->
      <div class="hover-icons">
        <a mat-fab
          [disabled]="!('update' | able: drawing)"
          (click)="edit(drawing)"
          color="accent"
          aria-label="Edit {{drawing.original_name}}"
          title="Edit {{drawing.original_name}}">
          <mat-icon>edit</mat-icon>
        </a>

        <button mat-fab
          [disabled]="!('delete' | able: drawing)"
          (click)="delete(drawing)"
          color="warn"
          aria-label="Delete {{drawing.original_name}}"
          title="Delete {{drawing.original_name}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <!-- /actions -->

      <div class="avatar">
        <img [src]="drawing.thumb_url" alt="{{drawing.original_name}} Preview" />
      </div>
      <div class="body">
        <div class="mat-subheading-2">{{drawing.original_name}}</div>
        <div class="mat-caption">{{drawing.mime}}</div>
        <div class="mat-body-strong tags">
          <span class="tag" *ngFor="let tag of drawing.tags">{{tag}}</span>
        </div>
      </div>
    </div>
    <!-- list-card -->

  </div>
  <!-- /List -->

  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && drawings.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

</div>

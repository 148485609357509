// add-comments-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-payapp-comments-dialog',
  templateUrl: './payapp-comments-dialog.component.html',
  styleUrls: ['./payapp-comments-dialog.component.scss']
})
export class PayappCommentsDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PayappCommentsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { comments: string }
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    this.dialogRef.close(this.data.comments);
  }
}

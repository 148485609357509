import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

export class AppErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }

  /**
   * Attach serverError to FormControl.errors, so errors can be rendered in form
   * @param form FormGroup validated form
   * @param resp HttpErrorResponse server error response
   * @param KeyMap simple lookup object where server-keys doesn't match with formcontrol names
   *               E.g. { username: 'email' }, username is server-key, email is form control name
   */
  setServerErrors(form: UntypedFormGroup, resp: HttpErrorResponse, keyMap: object = {}): void {
    const respBody = resp.error;
    for (let [prop, error] of Object.entries(respBody.error)) {

      // assign custom field errors to form controls
      if (/^field_values\.(.*)/.test(prop)) {
        prop = prop.match(/^field_values\.(.*)/)[1];
      }

      const formControl = form.get(prop in keyMap ? keyMap[prop] : prop);
      if (formControl) {
        formControl.setErrors({ error: error['msg'] });
        formControl.markAsDirty(); // make form controls as dirty to display server side errors in nested groups
      }
    }
  }
}

<div class="wrapper pt-[40px] flex flex-col gap-[40px] flex-1">
  <app-project-reports-list />
  <app-horizontal-date-picker
    (onDateSelection)="handleDateChange($event)"
    [count]="16"
    [date]="date"
    class="date-picker-row"
  />
  <ng-container *ngIf="consolidatedProjectReport && Object.keys(consolidatedProjectReport).length > 0; else noReport">
    <app-consolidated-report [data]="consolidatedProjectReport" />
  </ng-container>
  <ng-template #noReport>
    <div class="no-data flex justify-start items-center">
      No consolidated report for this day.
    </div>
  </ng-template>
  <!-- Show workflow actions for project report -->
  <ng-container *ngIf="projectReport?.id && ('read' | able: projectReport)">
    <app-workflow
      (actionResponse)="projectReportChanged($event)"
      [id]="projectReport.id"
      [projectId]="projectId"
      [redraw]="projectReport.status"
      wf="project-report-approval"
    />
  </ng-container>
</div>

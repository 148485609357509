import { BaseModel, Account, Project } from 'src/app/shared/models';
import * as moment from 'moment';
import { Submittal } from './submittal';
import { GuestAccount } from '../../guest-actions/models';
import { SubmittalPackageActivity } from './submittal-package-activity';

export class SubmittalPackage extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'SubmittalPackage';
  }

  /* tslint:disable */
  package_no: string;
  revision: string;
  title: string;
  description: string;
  status: string;
  sent_date: string | Date | moment.Moment;
  due_date: string | Date | moment.Moment;
  returned_date: string | Date | moment.Moment;
  can_review: boolean;

  account_reviewers: Account[];
  guest_reviewers: GuestAccount[];
  submittals: Submittal[];
  project: Project;
  createdBy: Account;
  updatedBy: Account;
  activity: SubmittalPackageActivity[];

  // acl assist variables
  project_id: string;
  /* tslint:enable */

  init() {
    this.submittals = [];
    this.account_reviewers = [];
    this.guest_reviewers = [];
    this.can_review = false;
    this.activity = [];
  }

  get relations() {
    return {
      submittals: Submittal,
      createdBy: Account,
      updatedBy: Account,
      project: Project,
      account_reviewers: Account,
      guest_reviewers: GuestAccount,
      activity: SubmittalPackageActivity,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    this.sent_date = this.sent_date && typeof this.sent_date === 'string' ? new Date(this.sent_date) : this.sent_date;
    this.due_date = this.due_date && typeof this.due_date === 'string' ? new Date(this.due_date) : this.due_date;
    this.returned_date =
      this.returned_date && typeof this.returned_date === 'string' ? new Date(this.returned_date) : this.returned_date;

    // add few attributes to assist acl module
    this.project_id = this.project?.id || this.project_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      package_no: this.package_no,
      title: this.title,
      description: this.description,
      due_date: moment(this.due_date).format('YYYY-MM-DD'),
      submittals: (this.submittals || []).map(o => o.id),
      account_reviewers: (this.account_reviewers || []).map(o => o.id),
      guest_reviewers: this.guest_reviewers,
    };
    return payload;
  }
}

<div class="container" *ngIf="initialized">
  <!-- title section -->
  <section class="header">
    <div class="row">
      <div class="mat-subheading-1">{{alignment?.name}}</div>
      <div class="icons">
        <button mat-icon-button (click)="downloadCSV(alignment)" *ngIf="!downloading" title="Download CSV">
          <mat-icon>download</mat-icon>
        </button>
        <app-file-uploader param="inputFile" [targetClass]="clsStation" [target]="csvEndpoint" icon="file_upload"
          accept="text/csv" (onError)="onUploadError($event)" color="none" httpMethod="PUT"
          (complete)="onUploadComplete($event)" title="Upload csv">
        </app-file-uploader>
        <button mat-icon-button (click)="toggleStationForm(null, true)" aria-label="Add station" color="none">
          <mat-icon title="Add station">add</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleGeneratorForm(true)" aria-label="Generator" color="none">
          <mat-icon title="Show generator">library_add</mat-icon>
        </button>
      </div>
    </div>

  </section>
  <!-- /title section -->
</div>

<div class="container" *ngIf="initialized">
  <ng-container *ngTemplateOutlet="GeneratorFormContent"></ng-container>
  <ng-container *ngTemplateOutlet="StationFormContent"></ng-container>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- listing -->
  <div class="mat-body-strong" *ngIf="!stations.length">
    No stations found for this alignment
    <a class="mat-inline-button" color="accent" (click)="toggleStationForm(null, true)">Add station?
    </a>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let station of stations">
      <div class="hover-icons">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="toggleStationForm(station, true)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item (click)="delete(station)">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>

      <div class="body">
        <div class="mat-body-strong">{{station.name}}</div>
        <div class="tags muted">
          <div class="tag" *ngFor="let prop of station.getPropertiesAsArray()">
            {{prop.name}}: {{prop.value}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- listing -->
</ng-template>

<ng-template #StationFormContent>
  <!-- Form -->
  <form #vcStationForm [formGroup]="stationForm" [class.hide]="!showStationForm">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
      <input matInput type="text" formControlName="alignment_id" />
    </section>
    <!-- name section -->
    <section>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="stationForm.controls.name.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="stationForm.controls.name.hasError('error')">
          {{stationForm.controls.name.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /name section -->

    <!-- Form Actions -->
    <section>
      <div class="row">
        <button mat-stroked-button color="accent" (click)="saveStation(stationForm)">
          <ng-container *ngIf="stationForm.controls.id.value">Update</ng-container>
          <ng-container *ngIf="!stationForm.controls.id.value">Create</ng-container>
        </button>
        <button type="button" mat-stroked-button (click)="toggleStationForm(null, false)">Cancel</button>
      </div>
    </section>
    <!-- Form Actions -->
  </form>
  <!-- /form -->
</ng-template>

<ng-template #GeneratorFormContent>
  <!-- Form -->
  <form #vcGeneratorForm [formGroup]="generatorForm" [class.hide]="!showGeneratorForm">
    <!-- distance section -->
    <section>
      <mat-form-field>
        <mat-label>Distance</mat-label>
        <input matInput type="number" autocomplete="off" formControlName="distance" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="generatorForm.controls.distance.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="generatorForm.controls.distance.hasError('error')">
          {{generatorForm.controls.distance.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /distance section -->

    <!-- total section -->
    <section>
      <mat-form-field>
        <mat-label>Total</mat-label>
        <input matInput type="number" autocomplete="off" formControlName="total" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="generatorForm.controls.total.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="generatorForm.controls.total.hasError('error')">
          {{generatorForm.controls.total.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /total section -->

    <!-- start_at section -->
    <section>
      <mat-form-field>
        <mat-label>Start at</mat-label>
        <input matInput type="number" autocomplete="off" formControlName="start_at" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="generatorForm.controls.start_at.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="generatorForm.controls.start_at.hasError('error')">
          {{generatorForm.controls.start_at.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /start_at section -->

    <!-- Form Actions -->
    <section>
      <div class="row">
        <button mat-stroked-button color="accent" (click)="generate(generatorForm)">
          Generate
        </button>
        <button type="button" mat-stroked-button (click)="toggleGeneratorForm(false)">Cancel</button>
      </div>
    </section>
    <!-- Form Actions -->
  </form>
  <!-- /form -->
</ng-template>
<ng-container *ngIf="initialized">
  <div class="content">
    <form [formGroup]="inputForm">
      <div class="container">

        <!-- Bid Item section -->
        <section>
          <div class="link" (click)="openBidItemSelector()" *ngIf="smt.bid_item">
            <div class="mat-headline">{{smt.bid_item?.item}}</div>
            <div class="mat-body">{{smt.bid_item?.description}}</div>
          </div>

          <div class="input-placeholder" *ngIf="!smt.bid_item" (click)="openBidItemSelector()">
            <button mat-icon-button [disabled]="!editing" color="accent">
              <mat-icon>add</mat-icon>
            </button>Add bid item
          </div>
            <input style="display: none;" formControlName="bid_item_id" />
            <mat-error *ngIf="formSubmitted && inputForm.controls.bid_item_id.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="formSubmitted && inputForm.controls.bid_item_id.hasError('error')">
              {{inputForm.controls.bid_item_id.errors.error}}
            </mat-error>

        </section>
        <!-- /Bid Item section -->

        <!-- Test Name -->
        <section>
          <div class="mat-subheading-1 row">Test name:</div>
          <div class="mat-body capitalize" *ngIf="!editing">{{smt.material_test?.name}}</div>
          <mat-form-field *ngIf="editing">
            <mat-label>Test name</mat-label>
            <mat-select formControlName="material_test_id" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let test of materialTests" [value]="test.id">
                <div>{{test.name}}</div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="inputForm.controls.material_test_id.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.material_test_id.hasError('error')">
              {{inputForm.controls.material_test_id.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Test Name -->

        <!-- Lab -->
        <section>
          <div class="mat-subheading-1 row">Lab name:</div>
          <div class="mat-body capitalize" *ngIf="!editing">{{smt.lab?.name}}</div>
          <mat-form-field *ngIf="editing">
            <mat-label>Select lab</mat-label>
            <mat-select formControlName="lab_id" [errorStateMatcher]="matcher">
              <mat-option *ngFor="let lab of labs" [value]="lab.id">
                <div>{{lab.name}}</div>
              </mat-option>
            </mat-select>
            <mat-error *ngIf="inputForm.controls.lab_id.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.lab_id.hasError('error')">
              {{inputForm.controls.lab_id.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Lab -->

        <!-- Request Date -->
        <section>
          <div class="mat-subheading-1">Date &amp; Time:</div>
          <div class="mat-body" *ngIf="!editing">
            {{smt.request_date | dateFormat: 'long'}} {{smt.request_date | dateDurationFormat: smt.request_interval}}
          </div>
          <div class="row" *ngIf="editing">
            <mat-form-field>
              <mat-label>Choose a date</mat-label>
              <input matInput [matDatepicker]="requestDatePicker" formControlName="request_date"
                [errorStateMatcher]="matcher" autocomplete="off">
              <mat-datepicker-toggle matSuffix [for]="requestDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #requestDatePicker></mat-datepicker>
              <mat-error *ngIf="inputForm.controls.request_date.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.request_date.hasError('error')">
                {{inputForm.controls.request_date.errors.error}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row" *ngIf="editing">
            <mat-form-field>
              <mat-label>From time</mat-label>
              <input matInput autocomplete="off" [ngxTimepicker]="fromPicker" [format]="24" formControlName="from_time"
                [errorStateMatcher]="matcher" />
              <ngx-material-timepicker #fromPicker></ngx-material-timepicker>
              <mat-error *ngIf="inputForm.controls.from_time.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>To time</mat-label>
              <input matInput autocomplete="off" [ngxTimepicker]="toPicker" [format]="24" formControlName="to_time"
                [errorStateMatcher]="matcher" />
              <ngx-material-timepicker #toPicker></ngx-material-timepicker>
              <mat-error *ngIf="inputForm.controls.to_time.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </section>
        <!-- /Request Date -->

        <!-- Annotation section -->
<!--        <section>-->
<!--          <div class="mat-subheading-1">Annotation:</div>-->
<!--          <div class="input-placeholder" *ngIf="!smt.annotation">-->
<!--            <button mat-icon-button [disabled]="!editing" color="accent" (click)="openAnnot()">-->
<!--              <mat-icon>add</mat-icon>-->
<!--            </button>Add annotation-->
<!--          </div>-->
<!--          <div class="preview-list">-->
<!--            <div *ngIf="smt.annotation">-->
<!--              <img class="link" [src]="smt.annotation.getThumbnail()" alt="Annotation Preview"-->
<!--                (click)="openAnnot(smt.annotation)" />-->
<!--              <button *ngIf="editing" class="hover-icon" mat-icon-button aria-label="Delete" title="Delete"-->
<!--                (click)="removeAnnot(smt.annotation)">-->
<!--                <mat-icon>delete</mat-icon>-->
<!--              </button>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->
        <!-- /Annotation section -->

        <!-- location section -->
        <section>
          <div class="mat-subheading-1">Location:</div>
          <div class="input-placeholder" *ngIf="!smt.latitude || !smt.longitude" (click)="editLocation(issue)">
            <button mat-icon-button [disabled]="!editing" color="accent">
              <mat-icon>location_on</mat-icon>
            </button>Add Location
          </div>

          <div (click)="editLocation(smt)" *ngIf="smt.latitude">
            <app-location-picker [isDialog]="false" [latitude]="smt.latitude" [longitude]="smt.longitude" height="100px"
              [readOnly]="true"></app-location-picker>
          </div>
        </section>
        <!-- /location section -->

        <!-- Pictures section -->
        <section>
          <div class="mat-subheading-1">Pictures:</div>
          <div class="preview-list">
            <app-file-uploader class="file-uploader input-placeholder small"
                               [disabled]="!editing" icon="image" accept="image/*"
              (complete)="onUploadComplete($event)">
            </app-file-uploader>
            <div *ngFor="let pic of smt.pictures">
              <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, smt.pictures, pic)" />
              <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
                (click)="removePicture(pic)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </section>
        <!-- /Pictures section -->

        <!-- Comment section -->
        <section>
          <div class="mat-subheading-1">Comment:</div>
          <div class="mat-body" *ngIf="!editing">{{smt.comment}}</div>
          <mat-form-field *ngIf="editing">
            <textarea matInput formControlName="comment" [errorStateMatcher]="matcher"></textarea>
            <mat-error *ngIf="inputForm.controls.comment.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.comment.hasError('error')">
              {{inputForm.controls.comment.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Comment section -->

        <!-- Form Actions -->
        <section>
          <div class="row" *ngIf="editing && (smt.status === 'draft' || !smt.status)">
            <button mat-stroked-button color="primary" (click)="saveItem()">
              <ng-container *ngIf="smt.id">Update &amp; send</ng-container>
              <ng-container *ngIf="!smt.id">Create &amp; send</ng-container>
            </button>
          </div>
        </section>
        <!-- Form Actions -->

      </div>
    </form>
  </div>
</ng-container>

/* NgRx */
import { createAction, props } from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[projects] clear state';
export const ClearState = createAction(CLEAR_STATE);


/******** SET CURRENT LOCATION ***********/
const SET_CURRENT_LOCATION = '[projects] set current location';
export const SetCurrentLocation = createAction(SET_CURRENT_LOCATION, props<any>());


/******** SET QP FOR MAP ***********/
const SET_QP_FOR_MAP = '[projects] set qp for map';
export const SetQPForMap = createAction(SET_QP_FOR_MAP, props<any>());

/********** [/projects]  ************/

/******** FETCH PROJECTS LIST ***********/
const FETCH_PROJECTS_LIST_REQUEST = '[projects] fetch projects list request';
export const FetchProjectsListRequest = createAction(FETCH_PROJECTS_LIST_REQUEST, props<any>());

const FETCH_PROJECTS_LIST_SUCCESS = '[projects] fetch projects list success';
export const FetchProjectsListSuccess = createAction(FETCH_PROJECTS_LIST_SUCCESS, props<any>());

const FETCH_PROJECTS_LIST_FAILURE = '[projects] fetch projects list failure';
export const FetchProjectsListFailure = createAction(FETCH_PROJECTS_LIST_FAILURE, props<any>());

/********** [/projects/:projectId?tab=sites]  ************/

/******** FETCH PROJECT DETAILS ***********/
const FETCH_PROJECT_DETAILS_REQUEST = '[projects] fetch project details request';
export const FetchProjectDetailsRequest = createAction(FETCH_PROJECT_DETAILS_REQUEST, props<any>());

const FETCH_PROJECT_DETAILS_SUCCESS = '[projects] fetch project details success';
export const FetchProjectDetailsSuccess = createAction(FETCH_PROJECT_DETAILS_SUCCESS, props<any>());

const FETCH_PROJECT_DETAILS_FAILURE = '[projects] fetch project details failure';
export const FetchProjectDetailsFailure = createAction(FETCH_PROJECT_DETAILS_FAILURE, props<any>());

/******** FETCH SITE AND DAILY REPORTS SUMMARY ***********/
const FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_REQUEST = '[projects] fetch site wise daily report summary request';
export const FetchSiteWiseDailyReportSummaryRequest = createAction(FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_REQUEST, props<any>());

const FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_SUCCESS = '[projects] fetch site wise daily report summary success';
export const FetchSiteWiseDailyReportSummarySuccess = createAction(FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_SUCCESS, props<any>());

const FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_FAILURE = '[projects] fetch site wise daily report summary failure';
export const FetchSiteWiseDailyReportSummaryFailure = createAction(FETCH_SITE_WISE_DAILY_REPORT_SUMMARY_FAILURE);

/******** FETCH PROJECT MEMBERS ***********/
const FETCH_PROJECT_MEMBERS_REQUEST = '[projects] fetch project members request';
export const FetchProjectMembersRequest = createAction(FETCH_PROJECT_MEMBERS_REQUEST, props<any>());

const FETCH_PROJECT_MEMBERS_SUCCESS = '[projects] fetch project members success';
export const FetchProjectMembersSuccess = createAction(FETCH_PROJECT_MEMBERS_SUCCESS, props<any>());

const FETCH_PROJECT_MEMBERS_FAILURE = '[projects] fetch project members failure';
export const FetchProjectMembersFailure = createAction(FETCH_PROJECT_MEMBERS_FAILURE);

/********** [/projects] [WRAPPER]  ************/

/******** CREATE DAILY REPORT ***********/
const CREATE_DAILY_REPORT_REQUEST = '[projects] create daily report request';
export const CreateDailyReportRequest = createAction(CREATE_DAILY_REPORT_REQUEST, props<any>());

const CREATE_DAILY_REPORT_SUCCESS = '[projects] create daily report success';
export const CreateDailyReportSuccess = createAction(CREATE_DAILY_REPORT_SUCCESS, props<any>());

const CREATE_DAILY_REPORT_FAILURE = '[projects] create daily report failure';
export const CreateDailyReportFailure = createAction(CREATE_DAILY_REPORT_FAILURE);

/******** CHECK DAILY REPORT STATUS ***********/
const CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_REQUEST = '[projects] check if daily report can be created request';
export const CheckIfDailyReportCanBeCreatedRequest = createAction(CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_REQUEST, props<any>());

const CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_SUCCESS = '[projects] check if daily report can be created success';
export const CheckIfDailyReportCanBeCreatedSuccess = createAction(CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_SUCCESS, props<any>());

const CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_FAILURE = '[projects] check if daily report can be created failure';
export const CheckIfDailyReportCanBeCreatedFailure = createAction(CHECK_IF_DAILY_REPORT_CAN_BE_CREATED_FAILURE);

/********** [/projects/:projectId?tab=consolidated-reports]  ************/

/******** FETCH PROJECTS REPORTS LIST ***********/
const FETCH_PROJECT_REPORTS_LIST_REQUEST = '[projects] fetch project reports list request';
export const FetchProjectReportsListRequest = createAction(FETCH_PROJECT_REPORTS_LIST_REQUEST, props<any>());

const FETCH_PROJECT_REPORTS_LIST_SUCCESS = '[projects] fetch project reports list success';
export const FetchProjectReportsListSuccess = createAction(FETCH_PROJECT_REPORTS_LIST_SUCCESS, props<any>());

const FETCH_PROJECT_REPORTS_LIST_FAILURE = '[projects] fetch project reports list failure';
export const FetchProjectReportsListFailure = createAction(FETCH_PROJECT_REPORTS_LIST_FAILURE);

/******** FETCH PROJECTS REPORT ***********/
const FETCH_PROJECT_REPORT_REQUEST = '[projects] fetch project report request';
export const FetchProjectReportRequest = createAction(FETCH_PROJECT_REPORT_REQUEST, props<any>());

const FETCH_PROJECT_REPORT_SUCCESS = '[projects] fetch project report success';
export const FetchProjectReportSuccess = createAction(FETCH_PROJECT_REPORT_SUCCESS, props<any>());

const FETCH_PROJECT_REPORT_FAILURE = '[projects] fetch project report failure';
export const FetchProjectReportFailure = createAction(FETCH_PROJECT_REPORT_FAILURE);

/******** FETCH PAYAPP ITEMS ***********/
const FETCH_PAYAPP_ITEMS_REQUEST = '[projects] fetch payapp items request';
export const FetchPayappItemsRequest = createAction(FETCH_PAYAPP_ITEMS_REQUEST, props<any>());

const FETCH_PAYAPP_ITEMS_SUCCESS = '[projects] fetch payapp items success';
export const FetchPayappItemsSuccess = createAction(FETCH_PAYAPP_ITEMS_SUCCESS, props<any>());

const FETCH_PAYAPP_ITEMS_FAILURE = '[projects] fetch payapp items failure';
export const FetchPayappItemsFailure = createAction(FETCH_PAYAPP_ITEMS_FAILURE);

/******** FETCH PAYAPPS ***********/
const FETCH_PAYAPPS_REQUEST = '[projects] fetch payapps request';
export const FetchPayappsRequest = createAction(FETCH_PAYAPPS_REQUEST, props<any>());

const FETCH_PAYAPPS_SUCCESS = '[projects] fetch payapps success';
export const FetchPayappsSuccess = createAction(FETCH_PAYAPPS_SUCCESS, props<any>());

const FETCH_PAYAPPS_FAILURE = '[projects] fetch payapps failure';
export const FetchPayappsFailure = createAction(FETCH_PAYAPPS_FAILURE);

/******** FETCH PAYAPPS ***********/
const FETCH_PAYAPPDETAILS_REQUEST = '[projects] fetch payapp details request';
export const FetchPayappDetailsRequest = createAction(FETCH_PAYAPPDETAILS_REQUEST, props<any>());

const FETCH_PAYAPPDETAILS_SUCCESS = '[projects] fetch payapp details success';
export const FetchPayappDetailsSuccess = createAction(FETCH_PAYAPPDETAILS_SUCCESS, props<any>());

const FETCH_PAYAPPDETAILS_FAILURE = '[projects] fetch payapp details failure';
export const FetchPayappDetailsFailure = createAction(FETCH_PAYAPPDETAILS_FAILURE);

/******** GENERATE PAYAPP  ***********/
const GENERATE_PAYAPP_REQUEST = '[projects] generate payapp request';
export const GeneratePayappRequest = createAction(GENERATE_PAYAPP_REQUEST, props<any>());

const GENERATE_PAYAPP_SUCCESS = '[projects] generate payapp success';
export const GeneratePayappSuccess = createAction(GENERATE_PAYAPP_SUCCESS, props<any>());

const GENERATE_PAYAPP_FAILURE = '[projects] generate payapp failure';
export const GeneratePayappFailure = createAction(GENERATE_PAYAPP_FAILURE);

/******** DELETE PAYAPP  ***********/
const DELETE_PAYAPP_REQUEST = '[projects] delete payapp request';
export const DeletePayappRequest = createAction(DELETE_PAYAPP_REQUEST, props<any>());

const DELETE_PAYAPP_SUCCESS = '[projects] delete payapp success';
export const DeletePayappSuccess = createAction(DELETE_PAYAPP_SUCCESS, props<any>());

const DELETE_PAYAPP_FAILURE = '[projects] delete payapp failure';
export const DeletePayappFailure = createAction(DELETE_PAYAPP_FAILURE);

/******** UPDATE PAYAPP STATUS  ***********/
const UPDATE_PAYAPP_STATUS_REQUEST = '[projects] update payapp status request';
export const UpdatePayappStatusRequest = createAction(UPDATE_PAYAPP_STATUS_REQUEST, props<any>());

const UPDATE_PAYAPP_STATUS_SUCCESS = '[projects] update payapp status success';
export const UpdatePayappStatusSuccess = createAction(UPDATE_PAYAPP_STATUS_SUCCESS, props<any>());

const UPDATE_PAYAPP_STATUS_FAILURE = '[projects] update payapp status failure';
export const UpdatePayappStatusFailure = createAction(UPDATE_PAYAPP_STATUS_FAILURE);

/******** ADD CONTRACTOR STATUS ***********/
const ADD_CONTRACTOR_STATUS_REQUEST = '[projects] add contractor status request';
export const AddContractorStatusRequest = createAction(ADD_CONTRACTOR_STATUS_REQUEST, props<any>());

const ADD_CONTRACTOR_STATUS_SUCCESS = '[projects] add contractor status success';
export const AddContractorStatusSuccess = createAction(ADD_CONTRACTOR_STATUS_SUCCESS, props<any>());

const ADD_CONTRACTOR_STATUS_FAILURE = '[projects] add contractor status failure';
export const AddContractorStatusFailure = createAction(ADD_CONTRACTOR_STATUS_FAILURE);

/******** CLEAR BID SHEET ***********/
const CLEAR_BID_SHEET = '[projects] clear bid sheet';
export const ClearBidSheet = createAction(CLEAR_BID_SHEET);

/******** FETCH BID SHEET ***********/
const FETCH_BID_SHEET_REQUEST = '[projects] fetch bid sheet request';
export const FetchBidSheetRequest = createAction(FETCH_BID_SHEET_REQUEST, props<any>());

const FETCH_BID_SHEET_SUCCESS = '[projects] fetch bid sheet success';
export const FetchBidSheetSuccess = createAction(FETCH_BID_SHEET_SUCCESS, props<any>());

const FETCH_BID_SHEET_FAILURE = '[projects] fetch bid sheet failure';
export const FetchBidSheetFailure = createAction(FETCH_BID_SHEET_FAILURE, props<any>());

/******** FETCH BID ITEMS ***********/
const FETCH_BID_ITEMS_REQUEST = '[projects] fetch bid items request';
export const FetchBidItemsListRequest = createAction(FETCH_BID_ITEMS_REQUEST, props<any>());

const FETCH_BID_ITEMS_SUCCESS = '[projects] fetch bid items success';
export const FetchBidItemsListSuccess = createAction(FETCH_BID_ITEMS_SUCCESS, props<any>());

const FETCH_BID_ITEMS_FAILURE = '[projects] fetch bid items failure';
export const FetchBidItemsListFailure = createAction(FETCH_BID_ITEMS_FAILURE, props<any>());

/******** UPLOAD CHANGE ORDER FILE ***********/
const UPLOAD_CHANGE_ORDER_FILE_REQUEST = '[projects] upload change order file request';
export const UploadChangeOrderFileRequest = createAction(UPLOAD_CHANGE_ORDER_FILE_REQUEST, props<any>());

const UPLOAD_CHANGE_ORDER_FILE_SUCCESS = '[projects] upload change order file success';
export const UploadChangeOrderFileSuccess = createAction(UPLOAD_CHANGE_ORDER_FILE_SUCCESS, props<any>());

const UPLOAD_CHANGE_ORDER_FILE_FAILURE = '[projects] upload change order file failure';
export const UploadChangeOrderFileFailure = createAction(UPLOAD_CHANGE_ORDER_FILE_FAILURE, props<any>());

/******** FETCH BID ITEMS SITES LIST ***********/
const FETCH_BID_ITEMS_SITES_LIST_REQUEST = '[projects] fetch bid items sites list request';
export const FetchBidItemsSitesListRequest = createAction(FETCH_BID_ITEMS_SITES_LIST_REQUEST, props<any>());

const FETCH_BID_ITEMS_SITES_LIST_SUCCESS = '[projects] fetch bid items sites list success';
export const FetchBidItemsSitesListSuccess = createAction(FETCH_BID_ITEMS_SITES_LIST_SUCCESS, props<any>());

const FETCH_BID_ITEMS_SITES_LIST_FAILURE = '[projects] fetch bid items sites list failure';
export const FetchBidItemsSitesListFailure = createAction(FETCH_BID_ITEMS_SITES_LIST_FAILURE, props<any>());

/******** FETCH CHANGE ORDER FILTER ***********/
const FETCH_CHANGE_ORDER_FILTER_REQUEST = '[projects] fetch change order filter request';
export const FetchChangeOrderFilterRequest = createAction(FETCH_CHANGE_ORDER_FILTER_REQUEST, props<any>());

const FETCH_CHANGE_ORDER_FILTER_SUCCESS = '[projects] fetch change order filter success';
export const FetchChangeOrderFilterSuccess = createAction(FETCH_CHANGE_ORDER_FILTER_SUCCESS, props<any>());

const FETCH_CHANGE_ORDER_FILTER_FAILURE = '[projects] fetch change order filter failure';
export const FetchChangeOrderFilterFailure = createAction(FETCH_CHANGE_ORDER_FILTER_FAILURE, props<any>());

/******** FETCH BID ITEM LIST WITH CHANGE ORDER ***********/
const FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_REQUEST = '[projects] fetch bid item list with change order request';
export const FetchBidItemListWithChangeOrderRequest = createAction(FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_REQUEST, props<any>());

const FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_SUCCESS = '[projects] fetch bid item list with change order success';
export const FetchBidItemListWithChangeOrderSuccess = createAction(FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_SUCCESS, props<any>());

const FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_FAILURE = '[projects] fetch bid item list with change order failure';
export const FetchBidItemListWithChangeOrderFailure = createAction(FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_FAILURE, props<any>());

/******** FETCH CHANGE ORDER FILTER IN SIDE NAV ***********/
const FETCH_CHANGE_ORDER_FILTER_IN_SIDE_NAV_REQUEST = '[projects] fetch change order filter in side nav request';
export const FetchChangeOrderFilterInSideNavRequest = createAction(FETCH_CHANGE_ORDER_FILTER_IN_SIDE_NAV_REQUEST, props<any>());

const FETCH_CHANGE_ORDER_FILTER_IN_SIDE_NAV_SUCCESS = '[projects] fetch change order filter in side nav success';
export const FetchChangeOrderFilterInSideNavSuccess = createAction(FETCH_CHANGE_ORDER_FILTER_IN_SIDE_NAV_SUCCESS, props<any>());

const FETCH_CHANGE_ORDER_FILTER_IN_SIDE_NAV_FAILURE = '[projects] fetch change order filter in side nav failure';
export const FetchChangeOrderFilterInSideNavFailure = createAction(FETCH_CHANGE_ORDER_FILTER_IN_SIDE_NAV_FAILURE, props<any>());

/******** FETCH BID ITEM LIST WITH CHANGE ORDER IN SIDE NAV ***********/
const FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_IN_SIDE_NAV_REQUEST = '[projects] fetch bid item list with change order in side nav request';
export const FetchBidItemListWithChangeOrderInSideNavRequest = createAction(FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_IN_SIDE_NAV_REQUEST, props<any>());

const FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_IN_SIDE_NAV_SUCCESS = '[projects] fetch bid item list with change order in side nav success';
export const FetchBidItemListWithChangeOrderInSideNavSuccess = createAction(FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_IN_SIDE_NAV_SUCCESS, props<any>());

const FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_IN_SIDE_NAV_FAILURE = '[projects] fetch bid item list with change order in side nav failure';
export const FetchBidItemListWithChangeOrderInSideNavFailure = createAction(FETCH_BID_ITEM_LIST_WITH_CHANGE_ORDER_IN_SIDE_NAV_FAILURE, props<any>());
/********** [/projects/:projectId/sites/:siteId?tab=daily-report]  ************/

/******** FETCH SITE DETAILS ***********/
const FETCH_SITE_DETAILS_REQUEST = '[projects] fetch site details request';
export const FetchSiteDetailsRequest = createAction(FETCH_SITE_DETAILS_REQUEST, props<any>());

const FETCH_SITE_DETAILS_SUCCESS = '[projects] fetch site details success';
export const FetchSiteDetailsSuccess = createAction(FETCH_SITE_DETAILS_SUCCESS, props<any>());

const FETCH_SITE_DETAILS_FAILURE = '[projects] fetch site details failure';
export const FetchSiteDetailsFailure = createAction(FETCH_SITE_DETAILS_FAILURE);

/******** FETCH DAILY REPORTS BY DATE ***********/
const FETCH_DAILY_REPORTS_BY_DATE_REQUEST = '[projects] fetch daily reports by date request';
export const FetchDailyReportsByDateRequest = createAction(FETCH_DAILY_REPORTS_BY_DATE_REQUEST, props<any>());

const FETCH_DAILY_REPORTS_BY_DATE_SUCCESS = '[projects] fetch daily reports by date success';
export const FetchDailyReportsByDateSuccess = createAction(FETCH_DAILY_REPORTS_BY_DATE_SUCCESS, props<any>());

const FETCH_DAILY_REPORTS_BY_DATE_FAILURE = '[projects] fetch daily reports by date failure';
export const FetchDailyReportsByDateFailure = createAction(FETCH_DAILY_REPORTS_BY_DATE_FAILURE);

/******** CLEAR DAILY REPORT ***********/
const CLEAR_DAILY_REPORT_REQUEST = '[projects] clear daily report request';
export const ClearDailyReportRequest = createAction(CLEAR_DAILY_REPORT_REQUEST);

/******** FETCH DAILY REPORT ***********/
const FETCH_DAILY_REPORT_REQUEST = '[projects] fetch daily report request';
export const FetchDailyReportRequest = createAction(FETCH_DAILY_REPORT_REQUEST, props<any>());

const FETCH_DAILY_REPORT_SUCCESS = '[projects] fetch daily report success';
export const FetchDailyReportSuccess = createAction(FETCH_DAILY_REPORT_SUCCESS, props<any>());

const FETCH_DAILY_REPORT_FAILURE = '[projects] fetch daily report failure';
export const FetchDailyReportFailure = createAction(FETCH_DAILY_REPORT_FAILURE);

/******** SAVE DAILY REPORT ***********/
const SAVE_DAILY_REPORT_REQUEST = '[projects] save daily report request';
export const SaveDailyReportRequest = createAction(SAVE_DAILY_REPORT_REQUEST, props<any>());

const SAVE_DAILY_REPORT_SUCCESS = '[projects] save daily report success';
export const SaveDailyReportSuccess = createAction(SAVE_DAILY_REPORT_SUCCESS, props<any>());

const SAVE_DAILY_REPORT_FAILURE = '[projects] save daily report failure';
export const SaveDailyReportFailure = createAction(SAVE_DAILY_REPORT_FAILURE);

/******** FETCH ALL BID ITEMS ***********/
const FETCH_ALL_BID_ITEMS_REQUEST = '[projects] fetch all bid items request';
export const FetchAllBidItemsRequest = createAction(FETCH_ALL_BID_ITEMS_REQUEST, props<any>());

const FETCH_ALL_BID_ITEMS_SUCCESS = '[projects] fetch all bid items success';
export const FetchAllBidItemsSuccess = createAction(FETCH_ALL_BID_ITEMS_SUCCESS, props<any>());

const FETCH_ALL_BID_ITEMS_FAILURE = '[projects] fetch all bid items failure';
export const FetchAllBidItemsFailure = createAction(FETCH_ALL_BID_ITEMS_FAILURE);

/******** FETCH ISSUE TYPES ***********/
const FETCH_ISSUE_TYPES_REQUEST = '[projects] fetch issue types request';
export const FetchIssueTypesRequest = createAction(FETCH_ISSUE_TYPES_REQUEST, props<any>());

const FETCH_ISSUE_TYPES_SUCCESS = '[projects] fetch issue types success';
export const FetchIssueTypesSuccess = createAction(FETCH_ISSUE_TYPES_SUCCESS, props<any>());

const FETCH_ISSUE_TYPES_FAILURE = '[projects] fetch issue types failure';
export const FetchIssueTypesFailure = createAction(FETCH_ISSUE_TYPES_FAILURE);

/******** CLEAR SITES LIST ***********/
const CLEAR_SITES_LIST_REQUEST = '[projects] clear sites list request';
export const ClearSitesListRequest = createAction(CLEAR_SITES_LIST_REQUEST);

/******** FETCH SUB CONTRACTOR FILTER ***********/
const FETCH_SUB_CONTRACTOR_FILTER_REQUEST = '[projects] fetch sub contractor filter request';
export const FetchSubContractorFilterRequest = createAction(FETCH_SUB_CONTRACTOR_FILTER_REQUEST, props<any>());

const FETCH_SUB_CONTRACTOR_FILTER_SUCCESS = '[projects] fetch sub contractor filter success';
export const FetchSubContractorFilterSuccess = createAction(FETCH_SUB_CONTRACTOR_FILTER_SUCCESS, props<any>());

const FETCH_SUB_CONTRACTOR_FILTER_FAILURE = '[projects] fetch sub contractor filter failure';
export const FetchSubContractorFilterFailure = createAction(FETCH_SUB_CONTRACTOR_FILTER_FAILURE, props<any>());

/******** FETCH LABOR FILTER ***********/
const FETCH_LABOR_FILTER_REQUEST = '[projects] fetch labor filter request';
export const FetchLaborFilterRequest = createAction(FETCH_LABOR_FILTER_REQUEST, props<any>());

const FETCH_LABOR_FILTER_SUCCESS = '[projects] fetch labor filter success';
export const FetchLaborFilterSuccess = createAction(FETCH_LABOR_FILTER_SUCCESS, props<any>());

const FETCH_LABOR_FILTER_FAILURE = '[projects] fetch labor filter failure';
export const FetchLaborFilterFailure = createAction(FETCH_LABOR_FILTER_FAILURE, props<any>());

/******** FETCH EQUIPMENT FILTER ***********/
const FETCH_EQUIPMENT_FILTER_REQUEST = '[projects] fetch equipment filter request';
export const FetchEquipmentFilterRequest = createAction(FETCH_EQUIPMENT_FILTER_REQUEST, props<any>());

const FETCH_EQUIPMENT_FILTER_SUCCESS = '[projects] fetch equipment filter success';
export const FetchEquipmentFilterSuccess = createAction(FETCH_EQUIPMENT_FILTER_SUCCESS, props<any>());

const FETCH_EQUIPMENT_FILTER_FAILURE = '[projects] fetch equipment filter failure';
export const FetchEquipmentFilterFailure = createAction(FETCH_EQUIPMENT_FILTER_FAILURE, props<any>());

/******** UPDATE ISSUE DETAILS  ***********/
const UPDATE_ISSUE_DETAILS_REQUEST = '[projects] update issues details request';
export const UpdateIssuesDetailsRequest = createAction(UPDATE_ISSUE_DETAILS_REQUEST, props<any>());

const UPDATE_ISSUE_DETAILS_SUCCESS = '[projects] update issues details success';
export const UpdateIssuesDetailsSuccess = createAction(UPDATE_ISSUE_DETAILS_SUCCESS, props<any>());

const UPDATE_ISSUE_DETAILS_FAILURE = '[projects] update issues details failure';
export const UpdateIssuesDetailsFailure = createAction(UPDATE_ISSUE_DETAILS_FAILURE);

/******** ADD ISSUE DETAILS  ***********/
const ADD_ISSUE_DETAILS_REQUEST = '[projects] add issues details request';
export const AddIssuesDetailsRequest = createAction(ADD_ISSUE_DETAILS_REQUEST, props<any>());

const ADD_ISSUE_DETAILS_SUCCESS = '[projects] add issues details success';
export const AddIssuesDetailsSuccess = createAction(ADD_ISSUE_DETAILS_SUCCESS, props<any>());

const ADD_ISSUE_DETAILS_FAILURE = '[projects] add issues details failure';
export const AddIssuesDetailsFailure = createAction(ADD_ISSUE_DETAILS_FAILURE);

/******** CHANGE ISSUE STATUS  ***********/
const CHANGE_ISSUE_STATUS_REQUEST = '[projects] change issue status request';
export const ChangeIssueStatusRequest = createAction(CHANGE_ISSUE_STATUS_REQUEST, props<any>());

const CHANGE_ISSUE_STATUS_SUCCESS = '[projects] change issue status success';
export const ChangeIssueStatusSuccess = createAction(CHANGE_ISSUE_STATUS_SUCCESS, props<any>());

const CHANGE_ISSUE_STATUS_FAILURE = '[projects] change issue status failure';
export const ChangeIssueStatusFailure = createAction(CHANGE_ISSUE_STATUS_FAILURE);

/******** EXECUTE CURRENT ACTION STATE  ***********/
const EXECUTE_CURRENT_ACTION_STATE_REQUEST = '[projects] execute current action state request';
export const ExecuteCurrentActionStateRequest = createAction(EXECUTE_CURRENT_ACTION_STATE_REQUEST, props<any>());

const EXECUTE_CURRENT_ACTION_STATE_SUCCESS = '[projects] execute current action state success';
export const ExecuteCurrentActionStateSuccess = createAction(EXECUTE_CURRENT_ACTION_STATE_SUCCESS, props<any>());

const EXECUTE_CURRENT_ACTION_STATE_FAILURE = '[projects] execute current action state failure';
export const ExecuteCurrentActionStateFailure = createAction(EXECUTE_CURRENT_ACTION_STATE_FAILURE);

/******** FETCH NEXT ACTION STATE  ***********/
const FETCH_NEXT_ACTION_STATE_REQUEST = '[projects] fetch next action state request';
export const FetchNextActionStateRequest = createAction(FETCH_NEXT_ACTION_STATE_REQUEST, props<any>());

const FETCH_NEXT_ACTION_STATE_SUCCESS = '[projects] fetch next action state success';
export const FetchNextActionStateSuccess = createAction(FETCH_NEXT_ACTION_STATE_SUCCESS, props<any>());

const FETCH_NEXT_ACTION_STATE_FAILURE = '[projects] fetch next action state failure';
export const FetchNextActionStateFailure = createAction(FETCH_NEXT_ACTION_STATE_FAILURE);

/******** FETCH EXTERNAL TESTS ***********/
const FETCH_EXTERNAL_TESTS_REQUEST = '[projects] fetch external tests request';
export const FetchExternalTestsRequest = createAction(FETCH_EXTERNAL_TESTS_REQUEST, props<any>());

const FETCH_EXTERNAL_TESTS_SUCCESS = '[projects] fetch external tests success';
export const FetchExternalTestsSuccess = createAction(FETCH_EXTERNAL_TESTS_SUCCESS, props<any>());

const FETCH_EXTERNAL_TESTS_FAILURE = '[projects] fetch external tests failure';
export const FetchExternalTestsFailure = createAction(FETCH_EXTERNAL_TESTS_FAILURE);

/******** FETCH ISSUES ***********/
const FETCH_ISSUES_REQUEST = '[projects] fetch issues request';
export const FetchIssuesRequest = createAction(FETCH_ISSUES_REQUEST, props<any>());

const FETCH_ISSUES_SUCCESS = '[projects] fetch issues success';
export const FetchIssuesSuccess = createAction(FETCH_ISSUES_SUCCESS, props<any>());

const FETCH_ISSUES_FAILURE = '[projects] fetch issues failure';
export const FetchIssuesFailure = createAction(FETCH_ISSUES_FAILURE);

/******** DELETE VIDEO FROM MUX DB  ***********/
const DELETE_VIDEO_FROM_MUX_DB_REQUEST = '[projects] delete video from mux db request';
export const DeleteVideoFromMuxDbRequest = createAction(DELETE_VIDEO_FROM_MUX_DB_REQUEST, props<any>());

const DELETE_VIDEO_FROM_MUX_DB_SUCCESS = '[projects] delete video from mux db success';
export const DeleteVideoFromMuxDbSuccess = createAction(DELETE_VIDEO_FROM_MUX_DB_SUCCESS, props<any>());

const DELETE_VIDEO_FROM_MUX_DB_FAILURE = '[projects] delete video from mux db failure';
export const DeleteVideoFromMuxDbFailure = createAction(DELETE_VIDEO_FROM_MUX_DB_FAILURE);

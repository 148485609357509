import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IssueType, Project } from '../../../../../../../shared/models';
import { ProjectService } from '../../../../../../../shared/services';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { ProjectMapService } from '../../../../project-map.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-onsite-tests',
  templateUrl: './onsite-tests.component.html',
  styleUrls: ['../../styles.css', './onsite-tests.component.scss'],
})
export class OnsiteTestsComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  @Input() data: any;

  heading = '';
  fields = [];
  issueTypes: IssueType[];

  nestedLayout = false;
  config = [];

  constructor(
    private projectService: ProjectService,
    private projectMapService: ProjectMapService,
  ) {
  }

  ngOnInit() {
    let temp = {};


    if (this.data.children) {
      this.nestedLayout = true;

      temp = this.data.children.reduce((rv, x) => {
        if (x.station) {
          if (rv[x.station?.name]) {
            rv[x.station.name].push(x);
          } else {
            rv[x.station.name] = [x];
          }
        } else {
          this.nestedLayout = false;
          if (rv[x.internal_test?.created_at]) {
            rv[x.internal_test?.created_at].push(x);
          } else {
            rv[x.internal_test?.created_at] = [x];
          }
        }

        return rv;
      }, {});
    } else {
      if (this.data?.station) {
        this.nestedLayout = true;
        temp[this.data?.station?.name] = [this.data];
      }
    }

    Object.keys(temp).forEach((o) => {
      if (this.nestedLayout) {
        this.config.push({
          heading: o,
          isExpanded: false,
          children: temp[o].map((j) => {
            const fields = this.getFields(j.internal_test?.fields, j.field_values);
            const subHeadingArr = [];
            fields.map(i => {
              if (i.value) {
                subHeadingArr.push(`${i.value} ${i.uom}`);
              }
            });

            return {
              id: j.id,
              isExpanded: false,
              heading: moment(j.internal_test.created_at).format('hh:mm a'),
              subHeading: subHeadingArr.length ? `(${subHeadingArr.join(', ')})` : null,
              fields,
              pictures: j.pictures,
            };
          }),
        });
      } else {
        temp[o].map((j) => {
          const fields = this.getFields(j.internal_test?.fields, j.field_values);
          const subHeadingArr = [];
          fields.map(i => {
            if (i.value) {
              subHeadingArr.push(`${i.value} ${i.uom}`);
            }
          });

          this.config.push({
            id: j.id,
            isExpanded: false,
            heading: moment(j.internal_test.created_at).format('hh:mm a'),
            subHeading: subHeadingArr.length ? `(${subHeadingArr.join(', ')})` : null,
            fields,
            pictures: j.pictures,
          });
        });
      }

    });

    this.projectService.getIssueTypes(new Project({ id: this.data.projectId }))
      .then((issueTypes: IssueType[]) => {
        this.issueTypes = issueTypes;
      });

    this.projectMapService.selectedMarkerData
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (this.nestedLayout) {
          this.config.forEach(o => {
            o.isExpanded = false;

            o.children.forEach(i => {
              i.isExpanded = false;

              if (data.id === i.id) {
                o.isExpanded = true;
                i.isExpanded = true;
              }
            });
          });
        } else {
          this.config.forEach(o => {
            o.isExpanded = data.id === o.id;
          });
        }
      });
  }

  getFields(fields = [], fieldValues = []) {
    let fieldsList = [];
    fieldValues.map(o => {
      const currentField = fields.find(i => o.id === i.id);

      if (currentField) {
        fieldsList = [
          ...fieldsList,
          {
            label: currentField.label,
            uom: currentField.uom,
            hint: currentField.hint,
            value: o.value,
          },
        ];
      }
    });

    return fieldsList;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

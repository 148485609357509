<ng-container *ngIf="initialized">

  <div class="mat-body-strong" *ngIf="!accounts.length">
    <p></p>
    Found no records to show
  </div>

  <table mat-table [dataSource]="accounts" *ngIf="accounts.length">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let account">{{account.name}}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef>Email</th>
      <td mat-cell *matCellDef="let account">{{account.email}}</td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let account">{{account.phone}}</td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let account">
        <div *ngFor="let role of account.roles">
          <span>{{role.role_name}}</span> <b> ({{role.project_name}})</b>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let account">{{account.status}}</td>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef>Created at</th>
      <td mat-cell *matCellDef="let account">{{account.created_at | dateFormat: 'long'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length" [length]="pagination.length"
    [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

  </ng-container>

import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Annotation, Attachment, BidItem, MaterialTest, Project, Site } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService, MaterialTestService } from 'src/app/shared/services';
import { AnnotComponent } from '../../../annot/annot.component';
import { BidItemSelectorComponent } from '../../../components/bid-item-selector/bid-item-selector.component';
import { MaterialTestLab } from '../../lab/material-test-lab';
import { MaterialTestLabService } from '../../lab/material-test-lab.service';
import { SiteMaterialTest } from '../../models';
import { SiteMaterialTestService } from '../../services';
import * as moment from 'moment';
import { LocationPickerComponent } from 'src/app/shared/components/location-picker/location-picker.component';

@Component({
  selector: 'app-site-material-test-create',
  templateUrl: './site-material-test-create.component.html',
  styleUrls: ['./site-material-test-create.component.scss'],
})
export class SiteMaterialTestCreateComponent implements OnInit {
  @Input() public smt: SiteMaterialTest;
  @Input() public site: Site;
  @Input() public project: Project;
  @Input() public editable: boolean = false;

  @Output() dataChange = new EventEmitter<any>();

  public loading: number = 0;
  public initialized: boolean = false;
  public formSubmitted: boolean = false;
  public editing = true;

  public materialTests: MaterialTest[] = [];
  public labs: MaterialTestLab[] = [];

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    bid_item_id: ['', [Validators.required]],
    request_date: ['', [Validators.required]],
    from_time: ['', [Validators.required]],
    to_time: ['', [Validators.required]],
    material_test_id: ['', [Validators.required]],
    lab_id: ['', [Validators.required]],
    comment: [''],
  });

  constructor(
    public appSrv: AppService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private materialTestService: MaterialTestService,
    private labService: MaterialTestLabService,
    private smtSrv: SiteMaterialTestService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.smt.project = this.smt?.project || this.project;
    this.smt.site = this.smt?.site || this.site;

    this.toReactiveForm(this.smt, this.inputForm);

    this.smt.pictures = this.smt.pictures || [];

    this.fetchMaterialTests(this.smt?.project);
    this.fetchLabs(this.smt?.project);

    if (!this.smt.id) {
      this.editing = true; // new record
    } else {
      this.editing = this.editable && this.smt.status === 'draft';
    }
    this.initialized = true;
  }

  /**
   * Assign base model to FormGroup
   * @param issue Issue
   * @param form FormGroup
   */
  toReactiveForm(smt: SiteMaterialTest, form: UntypedFormGroup) {
    form.controls.bid_item_id.setValue(smt.bid_item?.id);
    form.controls.request_date.setValue(smt.request_date.format('YYYY-MM-DD'));
    form.controls.from_time.setValue(smt.request_date.format('HH:mm'));
    form.controls.to_time.setValue(moment(smt.request_date).add(smt.request_interval).format('HH:mm'));
    form.controls.material_test_id.setValue(smt.material_test_id || smt.material_test?.id);
    form.controls.lab_id.setValue(smt.lab_id || smt.lab?.id);
    form.controls.comment.setValue(smt.comment);
  }

  /**
   * Opens BidItem Selector
   */
  openBidItemSelector(): void {
    if (!this.editing) {
      return;
    }

    const dialogRef = this.dialog.open(BidItemSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: this.smt?.project,
        selectedBidItem: this.smt?.bid_item,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof BidItem) {
        this.smt.bid_item = resp;
        this.inputForm.controls.bid_item_id.setValue(resp.id);
      }
    });
  }

  fetchMaterialTests(project: Project) {
    this.materialTestService
      .getProjectRecords(project, { pageIndex: 0, pageSize: 1000 })
      .then((resp: any) => {
        this.materialTests = resp.result;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.message, '', { duration: 5000 });
      });
  }

  fetchLabs(project: Project) {
    this.labService
      .getRecords(project, { pageIndex: 0, pageSize: 1000 })
      .then((resp: any) => {
        this.labs = resp.result;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.message, '', { duration: 5000 });
      });
  }

  /**
   * Remove annot from list
   * @param annot Annotation
   */
  removeAnnot(annot) {
    this.smt.annotation = null;
  }

  /**
   * Opens annotations in Modal
   * @param annotation Annotation
   */
  openAnnot(annotation: Annotation = null) {
    if (!this.editing) {
      return;
    }
    if (!annotation) {
      annotation = new Annotation();
    }

    const dialogRef = this.dialog.open(AnnotComponent, {
      disableClose: true,
      data: {
        annotation,
        project: this.smt?.project,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof Annotation) {
        this.smt.annotation = resp;
      }
    });
  }

  /**
   * Attach uploaded attachment to pictures
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.smt.pictures.push(a));
  }

  /**
   * Remove pic from list
   * @param pic Attachment
   */
  removePicture(pic) {
    this.smt.pictures.splice(
      this.smt.pictures.findIndex(p => p.id === pic.id),
      1
    );
  }

  /**
   * Update ReportBidItem
   */
  saveItem() {
    this.formSubmitted = true;
    if (!this.inputForm.valid) {
      return;
    }

    // convert from time and to time to ISO duration
    let { request_date, from_time = '00:00', to_time = '00:00' } = this.inputForm.value;
    const request_interval = moment.duration(to_time).subtract(moment.duration(from_time)).toISOString();
    request_date = moment(request_date).add(moment.duration(from_time));

    this.smt = Object.assign(this.smt, this.inputForm.value, { request_interval, request_date });
    this.loading++;
    const qp = {};
    this.smtSrv
      .save(this.smt, null, qp)
      .then((smt: SiteMaterialTest) => {
        this.smt = smt;
        this.toReactiveForm(smt, this.inputForm);
        this.sendToLab(this.smt);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.formSubmitted = false;
        this.smtSrv.fetchSiteMaterialTests.next(null);
      });
  }

  /**
   * Send smt to lab
   * @param smt SiteMaterialTest
   */
  sendToLab(smt: SiteMaterialTest): void {
    this.loading++;
    this.smtSrv
      .sendToLab(smt)
      .then((smt: SiteMaterialTest) => {
        this.smt = smt;
        this.toReactiveForm(smt, this.inputForm);
        this.snackBar.open('Test created & sent to lab', '', { duration: 5000 });
        this.dataChange.emit(this.smt);
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Open location to edit
   * @param smt SiteMaterialTest
   */
  editLocation(smt: SiteMaterialTest): void {
    if (!this.editing) {
      return;
    }
    smt = smt || new SiteMaterialTest({});

    const dialogRef = this.dialog.open(LocationPickerComponent, {
      disableClose: true,
      data: {
        latitude: smt.latitude,
        longitude: smt.longitude,
        readOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && 'latitude' in resp && 'longitude' in resp) {
        this.smt.latitude = resp.latitude;
        this.smt.longitude = resp.longitude;
      }
    });
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-material-delivery-and-usage-summary',
  templateUrl: './material-delivery-and-usage-summary.component.html',
  styleUrls: ['./material-delivery-and-usage-summary.component.scss']
})
export class MaterialDeliveryAndUsageSummaryComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  @Input() data: any;

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppErrorStateMatcher } from 'src/app/shared/services';
import { SiteMaterialTest } from '../../models';
import { SiteMaterialTestService } from '../../services';

@Component({
  selector: 'app-smt-result',
  templateUrl: './smt-result.component.html',
  styleUrls: ['./smt-result.component.scss']
})
export class SmtResultComponent implements OnInit {

  @Input() public smt: SiteMaterialTest;
  @Input() editable: boolean = false;
  @Output() public dataChange: EventEmitter<any> = new EventEmitter<any>();

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = true;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    result: ['', [Validators.required]],
    comment: [''],
  });

  constructor(
    private smtSrv: SiteMaterialTestService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.toReactiveForm(this.smt, this.inputForm);

    this.initialized = true;
    this.editing = this.editable;

  }

  /**
   * Assign base model to FormGroup
   * @param model SiteMaterialTest
   * @param form FormGroup
   */
  toReactiveForm(model: SiteMaterialTest, form: UntypedFormGroup): void {
    const result = model.status === 'failed' ? 'failed' : 'passed'; // default to passed
    form.controls.comment.setValue(model.comment);
    form.controls.result.setValue(result);
  }

  /**
   * Update model
   */
  saveItem() {

    if (!this.inputForm.valid) {
      return;
    }

    const payload = {
      comment: this.inputForm.value.comment,
      result: this.inputForm.value.result,
    };

    this.loading++;
    this.smtSrv.updateTestResult(this.smt, payload)
      .then((smt: SiteMaterialTest) => {
        this.smt = smt;
        this.toReactiveForm(smt, this.inputForm);
        this.snackBar.open('Updated test result', '', { duration: 5000 });
        this.dataChange.emit(this.smt);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }
}

<div class="center" *ngIf="initialized">
  <mat-card class="stroked-card">

    <mat-card-header>
      <mat-card-title>Signup</mat-card-title>
      <mat-card-subtitle>&nbsp;</mat-card-subtitle>
    </mat-card-header>

    <form [formGroup]="inputForm" (ngSubmit)="onSubmit()">
      <mat-card-content>

        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="first_name"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.first_name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.first_name.hasError('error')">{{inputForm.controls.first_name.errors.error}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput type="email" autocomplete="off" formControlName="last_name"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.last_name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.last_name.hasError('error')">{{inputForm.controls.last_name.errors.error}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Mobile Number</mat-label>
          <input matInput type="email" autocomplete="off" formControlName="phone"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.phone.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.phone.hasError('mobilePhone')">
            Enter a valid US Mobile Number (+15554443322)
          </mat-error>
          <mat-error *ngIf="inputForm.controls.phone.hasError('error')">{{inputForm.controls.phone.errors.error}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput type="email" autocomplete="off" formControlName="email"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.email.hasError('email') && !inputForm.controls.email.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="inputForm.controls.email.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.email.hasError('error')">{{inputForm.controls.email.errors.error}}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="password"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.password.hasError('required')">
            Password is <strong>required</strong></mat-error>
          <mat-error *ngIf="inputForm.controls.password.hasError('error')">{{inputForm.controls.password.errors.error}}
          </mat-error>
        </mat-form-field>

      </mat-card-content>

      <mat-card-actions>
        <button mat-flat-button color="primary" type="submit">Signup</button>
        <a color="accent" mat-button routerLink="/login" disableRipple>Has account? Login</a>
      </mat-card-actions>
    </form>
  </mat-card>
</div>

<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>
    <button mat-mini-fab aria-label="Add alignment" color="none" (click)="toggleForm(null, true)">
      <mat-icon title="Add alignment">add</mat-icon>
    </button>
  </div>

  <div class="container">
    <!-- title section -->
    <section class="header">
      <div class="title">{{site.name}}</div>
    </section>
    <!-- /title section -->
  </div>

  <mat-tab-group [(selectedIndex)]="selectedTabIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Alignments">
          conversion_path
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="container">
          <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
          <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="!alignment?.id">
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Stations">
          tour
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-alignment-stations [alignment]="alignment"></app-alignment-stations>
      </ng-template>
    </mat-tab>

    <mat-tab [disabled]="!alignment?.id">
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Headings">
          signpost
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-alignment-headings [alignment]="alignment"></app-alignment-headings>
      </ng-template>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Internal Tests">
          lab_research
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-site-internal-tests [site]="site"></app-site-internal-tests>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Delivery tracking fields">
          settings_timelapse
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-fields
          recordRange="true"
          context="delivery_tracking_fields"
          entityType="site"
          [entity]="site"
          ></app-fields>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #ListingContent>
  <!-- listing -->
  <div class="mat-body-strong" *ngIf="!alignments.length">
    No alignments found for this site
    <a class="mat-inline-button" color="accent" (click)="toggleForm(null, true)" aria-label="Add new alignment"
      title="Add new alignment">Add alignment?
    </a>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let alignment of alignments">
      <div class="hover-icons">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item
            (click)="toggleForm(alignment, true)"
            [disabled]="!('update' | able: site)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item
            (click)="showTab(alignment, 1)"
            [disabled]="!('update' | able: site)">
            <mat-icon>list</mat-icon>
            <span>Stations</span>
          </button>
          <button mat-menu-item
            (click)="showTab(alignment, 2)"
            [disabled]="!('update' | able: site)">
            <mat-icon>list</mat-icon>
            <span>Headings</span>
          </button>
          <button mat-menu-item
            (click)="delete(alignment)"
            [disabled]="!('update' | able: site)">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>

      <div class="body">
        <div class="mat-subheading-1" *ngIf="alignment.name">{{alignment.name}}</div>
        <div class="mat-subheading-1 muted" *ngIf="!alignment.name">None</div>
        <div>{{alignment.total_stations}} stations</div>
        <div class="tags" *ngIf="alignment?.station_properties?.length">
          <div class="tag" *ngFor="let tag of alignment.station_properties">{{tag}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- listing -->
</ng-template>

<ng-template #InputFormContent>
  <!-- Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!editing">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>
    <!-- name section -->
    <section>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('error')">
          {{inputForm.controls.name.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /name section -->
    <!-- distance_uom section -->
    <section>
      <mat-form-field>
        <mat-label>Distance UOM</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="distance_uom" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.distance_uom.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.distance_uom.hasError('error')">
          {{inputForm.controls.distance_uom.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /distance_uom section -->

    <!-- last_distance section -->
    <section>
      <mat-form-field>
        <mat-label>Distance (last to first station)</mat-label>
        <input matInput type="number" autocomplete="off" formControlName="last_distance" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.last_distance.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.last_distance.hasError('error')">
          {{inputForm.controls.last_distance.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /last_distance section -->

    <!-- Form Actions -->
    <section>
      <div class="row" *ngIf="editing">
        <button mat-stroked-button color="accent" (click)="save(inputForm)">
          <ng-container *ngIf="alignment?.id">Update</ng-container>
          <ng-container *ngIf="!alignment?.id">Create</ng-container>
        </button>
        <button mat-stroked-button (click)="onCancel()">Cancel</button>
      </div>
    </section>
    <!-- Form Actions -->
  </form>
  <!-- /form -->
</ng-template>
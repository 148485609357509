<div class="container">
  <ng-container *ngIf="initialized">

    <div class="mat-body-strong" *ngIf="!headings.length">
      <ng-container *ngIf="!bidItem.record_by_station">
        Record by station is not enable for this biditem
      </ng-container>
      <ng-container *ngIf="!bidItem?.site && bidItem.record_by_station">
        Select a site to show headings
      </ng-container>
      <ng-container *ngIf="bidItem?.site?.id">
        Site "{{bidItem?.site?.name}}" has no headings
      </ng-container>
    </div>

    <div class="list">
      <div class="list-card" *ngFor="let heading of headings">
        <div class="body">
          <mat-checkbox [checked]="heading.checked" (change)="onChange(heading, $event.checked)">
            <div class="mat-body-strong">
              {{heading.name}}
              <span class="mat-caption muted">
                  {{heading.starts_from}} to {{heading.ends_at}}
              </span>
              <div class="mat-subheading-1">
                {{heading.alignment_name || 'None'}}
              </div>
            </div>
          </mat-checkbox>
        </div>
      </div>
    </div>
  </ng-container>

</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ability } from '@casl/ability';
import { ProjectCreateComponent } from 'src/app/modules/project/project-create/project-create.component';
import { Project } from 'src/app/shared/models';
import { AppService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent implements OnInit {
  public initialized: boolean = false;

  @Output() updated = new EventEmitter<Project>();

  @Input() public showControls: boolean = true;
  @Input() public showProgress: boolean = false;
  @Input() public picHeight: string = '150px';
  @Input() public project: Project;

  constructor(private ability: Ability, private appSrv: AppService) {}

  ngOnInit(): void {
    if (this.project) {
      this.initialized = true;
    }
  }

  /**
   * Open Project in sidebar
   * @param instance issue
   */
  editProject(model: Project = null) {
    const sidebarRef = this.appSrv.openSidebar(ProjectCreateComponent, {
      data: model || {},
      editable: this.ability.can('update', model),
    });

    sidebarRef.afterClosed().subscribe((resp) => {
      if (resp instanceof Project) {
        this.updated.emit(resp);
      }
    });
  }
}

export class Util {
  static isNumeric(v) {
    return (v - parseFloat(v) + 1) >= 0;
  }

  static isEmpty(v) {
    return ['', null, NaN, undefined].indexOf(v) >= 0;
  }

  static getNonEmpty(v: any) {
    return this.isEmpty(v) ? '' : v;
  }

  static serialize(v: any): string {
    if (['array', 'object'].indexOf(typeof v) > -1) {
      return v && 'toString' in v ? v.toString() : JSON.stringify(v);
    }
    return `${v}`;
  }
}

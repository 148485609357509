import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Ability } from '@casl/ability';
import { SubContractor, SubContractorService } from 'src/app/modules/sub-contractor';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { DailyReport, ReportLabor } from 'src/app/shared/models';
import { AppErrorStateMatcher, DailyReportService } from 'src/app/shared/services';

@Component({
  selector: 'app-report-labor',
  templateUrl: './report-labor.component.html',
  styleUrls: ['./report-labor.component.scss']
})
export class ReportLaborComponent implements OnInit {

  @Input() data: any;
  @Input() dailyReport: DailyReport;

  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;
  public dialogOptions: any = {};
  public componentResult: any = null;

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = false;

  public reportLabor: ReportLabor;
  public subContractors: SubContractor[] = [];

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    type: ['', [Validators.required]],
    quantity: ['', [Validators.required]],
    duration: ['', [Validators.required]],
    idle_duration: ['', [Validators.required]],
    comment: [''],
    sub_contractor_id: [''],
  });

  constructor(
    public ability: Ability,
    private scSrv: SubContractorService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private dailyReportService: DailyReportService,
    public dialogRef: MatDialogRef<ReportLaborComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    if (this.isDialog) {
      const { data, dailyReport, options } = this.inputData;
      this.dialogOptions = options;
      this.data = data;
      this.dailyReport = dailyReport;
    }

    this.reportLabor = new ReportLabor(JSON.parse(JSON.stringify(this.data)));
    this.toReactiveForm(this.reportLabor, this.inputForm);

    this.editable = this.ability.can('create', this.dailyReport);
    if (this.editable && !this.reportLabor.id) {
      this.editing = true; // new record
    }

    this.loading++;
    this.loadData()
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Load any initial data
   * @returns {Promise<any>}
   */
  loadData(): Promise<any> {
    return this.scSrv.fetchRecords({ pageIndex: 0, pageSize: 10000 }, {
      project_id: this.dailyReport.project_id,
      include: ''
    })
      .then((resp: any) => {
        this.subContractors = resp.result;
      });
  }

  /**
   * Closes component or Dialog with response|event
   * @param resp data to be sent to parent
   */
  close(resp: any = null): void {

    if (this.isDialog) {
      this.dialogRef.close(resp || this.componentResult);
    }
    // Todo: emit output event
  }

  /**
   * Assign base model to FormGroup
   * @param lineItem ReportLabor
   * @param form FormGroup
   */
  toReactiveForm(lineItem: ReportLabor, form: UntypedFormGroup) {
    form.controls.type.setValue(lineItem.type);
    form.controls.quantity.setValue(lineItem.quantity);
    form.controls.duration.setValue(lineItem.duration);
    form.controls.idle_duration.setValue(lineItem.idle_duration);
    form.controls.comment.setValue(lineItem.comment);
    form.controls.sub_contractor_id.setValue(lineItem.sub_contractor?.id);
  }

  /**
   * Update ReportLabor
   */
  saveItem() {

    if (!this.inputForm.valid) {
      return;
    }

    this.reportLabor = Object.assign(this.reportLabor, this.inputForm.value);
    this.reportLabor.sub_contractor = this.subContractors.find(o => o.id === this.reportLabor.sub_contractor_id);
    const payload = this.reportLabor.toPayload();

    this.loading++;
    const qp = { include: ['sub_contractor'] };

    this.dailyReportService.saveLineItem('labor', payload, this.dailyReport, qp)
      .then((reportLabor: ReportLabor) => {
        reportLabor.site_id = this.dailyReport.site_id;
        reportLabor.daily_report_id = this.dailyReport.id;
        this.toReactiveForm(reportLabor, this.inputForm);
        this.snackBar.open('Saved labor', '', { duration: 5000 });

        this.reportLabor = reportLabor;
        this.componentResult = this.reportLabor;
        this.loading--;
        this.editing = false;
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.editing = false;
    this.reportLabor = new ReportLabor(JSON.parse(JSON.stringify(this.data)));
  }

  /**
   * Deletes line item
   * @param li LineItem
   * @returns void
   */
  delete(li: ReportLabor): void {
    if (!li.id || !this.editable) {
      return;
    }

    const message = `Deleting labor <b>${li.type}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.loading++;
      this.dailyReportService.deleteLineItem('labor', li, this.dailyReport)
        .then(resp => {
          li.__deleted = true;
          li.daily_report_id = this.dailyReport.id;
          this.close(li);
          this.snackBar.open(resp.message || 'Deleted', '', { duration: 5000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        })
        .finally(() => {
          this.loading--;
        });
    });
  }

}

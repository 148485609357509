import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import {ActivatedRoute, Router} from '@angular/router';
import {DailyReportService} from '../../../../daily-report.service';
import {ProjectsService} from '../../../../../../../../core/projects.service';
import {dailyReport, projectDetails} from '../../../../../../../../core/projects.selectors';
import {takeUntil} from 'rxjs/operators';
import {
  DeliveryDetailsWrapperComponent
} from '../../delivery-details/delivery-details-wrapper/delivery-details-wrapper.component';
import {UsageDetailsWrapperComponent} from '../usage-details-wrapper/usage-details-wrapper.component';

@Component({
  selector: 'app-add-usage-details',
  templateUrl: './add-usage-details.component.html',
  styleUrls: ['./add-usage-details.component.scss']
})
export class AddUsageDetailsComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  elementsList: any[] = [];
  filteredElementsList: any[] = [];

  form = new FormGroup({
    searchText: new FormControl('')
  });

  showList = false;

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private dailyReportService: DailyReportService,
    private projectsService: ProjectsService
  ) {
  }

  ngOnInit() {
    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(project => {
        if (project) {
          const bidItemsList = project?.bid_items?.filter(o => {
            const materials = o.materials || [];
            for (const material of materials) {
              if (material.track_used) {
                return true;
              }
            }
          });

          const materialsList = project?.materials?.filter(o => o.track_used);


          this.elementsList = [
            ...materialsList,
            ...bidItemsList,
          ];

          this.filteredElementsList = this.elementsList;
        }
      });

    this.form.controls.searchText.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(searchText => {
        if (searchText) {
          this.filteredElementsList = this.elementsList.filter(item => {
            let keywordsArr = [];

            keywordsArr.push(item.name, item.item, item.description);

            if (item.materials?.length > 0) {
              item.materials?.forEach(o => {
                keywordsArr.push(o.name);
              });
            }

            keywordsArr = keywordsArr.filter(o => !!o);

            const filterString = keywordsArr.join('');

            if (filterString?.toLowerCase().includes(searchText.toLowerCase())) {
              return true;
            }

          });
        } else {
          this.filteredElementsList = this.elementsList;
        }
      });
  }

  addUsage(usageItem) {
    this.store.select(dailyReport)
      .subscribe(report => {
        const {reportDetails} = report;

        const usages = reportDetails?.usages;

        const foundElement = usages.find(usage => {

          if (usageItem.track_material_quantity !== undefined) {
            // Bid Item with materials
            if (usageItem?.id === usage.initialState?.bid_item_id && usageItem.materials?.length) {
              return true;
            }

            // Only bid item
            if (usageItem?.id === usage.initialState?.bid_item_id && !usageItem.materials?.length) {
              return true;
            }
          } else {
            // Only Material
            if (usage?.initalState?.material?.id === usageItem.id) {
              return true;
            }
          }
        });


        const element = foundElement ? JSON.parse(JSON.stringify(foundElement)) : this.dailyReportService.createNewUsageBlock(usageItem);

        this.projectsService.openRightPanel.next({
          component: UsageDetailsWrapperComponent,
          data: element
        });

      }).unsubscribe();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

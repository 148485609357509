import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Annotation, Drawing, Project } from 'src/app/shared/models';
import { AnnotationService, AuthService } from 'src/app/shared/services';
import { DrawingSelectorComponent } from '../components/drawing-selector/drawing-selector.component';

@Component({
  selector: 'app-annot',
  templateUrl: './annot.component.html',
  styleUrls: ['./annot.component.scss']
})
export class AnnotComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  public annotation: Annotation = new Annotation();
  public project: Project;
  public account: any;

  public updatedXfdf: string;
  private updatedPdf: Blob;
  private masterAnnotId: any;
  public editing = true;

  constructor(
    public dialog: MatDialog,
    private ngZone: NgZone,
    private authService: AuthService,
    private annotationService: AnnotationService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<AnnotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const { annotation, project } = data;
    this.project = project;
    if (annotation instanceof Annotation) {
      // data.drawing.url = 'http://localhost:4200/assets/original.pdf';
      this.annotation = new Annotation(JSON.parse(JSON.stringify(annotation)));
    }
  }

  ngOnInit(): void {
    this.account = this.authService.getCurrentUser();
    if (this.annotation.id) {
      this.initialized = true;
    } else {
      this.openDrawingSelector(this.project);
    }
  }

  /**
   * Opens Drawing selection box in modal
   * @param project Project
   */
  openDrawingSelector(project: Project): void {
    const dialogRef = this.dialog.open(DrawingSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: project,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof Drawing) {
        this.annotation.drawing = resp;
        this.initialized = true;
      }
    });
  }

  /**
   * Save annotation
   */
  save() {
    this.loading++;

    const qp = {
      include: 'drawing',
    };

    const opts = { masterAnnotId: this.masterAnnotId };
    const req = this.annotation.id
      ? this.annotationService.update(this.annotation.id, this.updatedXfdf, this.updatedPdf, qp, opts)
      : this.annotationService.add(this.annotation.drawing, this.updatedXfdf, this.updatedPdf, qp, opts);

    req.subscribe({
        next: (annotation: Annotation) => {
          this.loading--;
          this.annotation = annotation;
          this.snackBar.open('Annotation updated Successfully', '', { duration: 5000 });
          this.updatedPdf = null;
          this.updatedXfdf = null;
        },
        error: (err: any) => {
          this.loading--;
          console.error(err.error);
          this.snackBar.open('Oops! something went wrong, unable to save annotation', '', { duration: 5000 });
        }
      });
  }

  /**
   * Close dialog
   * @param resp any
   */
  onClose(): void {
    const resp = this.data.xfdf !== this.annotation.xfdf ? this.annotation : null;
    this.dialogRef.close(resp);
  }

  /**
   * Callback after annotations are changed
   * @param e updated xfdf and file
   */
  annotationsChanged(e: any) {

    const { xfdf, file, masterAnnotId } = e;
    this.updatedXfdf = xfdf;
    this.updatedPdf = file;
    this.masterAnnotId = masterAnnotId;
    this.ngZone.run(() => {
      this.updatedXfdf = xfdf;
    });
  }

}

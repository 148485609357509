import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-site-reports-panel',
  templateUrl: './site-reports-panel.component.html',
  styleUrls: ['./site-reports-panel.component.scss'],
})
export class SiteReportsPanelComponent implements OnInit, OnChanges, OnDestroy {
  @Input() site: any;
  isExpanded = false;
  reportsList: any[] = [];
  status = [];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.site) {
      this.reportsList = changes?.site?.currentValue?.daily_reports;
    }
  }

  onClick(report) {
    const url = `/projects/${this.route.snapshot.params.projectId}/sites/${this.site.site_id}`;
    this.router.navigate([url], {
      queryParams: {
        tab: 'daily-report',
        date: report.report_date,
        reportId: report.daily_report_id
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

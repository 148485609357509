import { Component, Input, OnInit } from '@angular/core';
import { IssueSummary } from 'src/app/shared/models';
import { IssueService } from 'src/app/shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-issues-summary',
  templateUrl: './issues-summary.component.html',
  styleUrls: ['./issues-summary.component.scss'],
})
export class IssuesSummaryComponent implements OnInit {
  public initialized = false;
  public loading = 0;

  @Input() public entityType: string;
  @Input() public id: string;

  public summary: IssueSummary;

  constructor(private issueSrv: IssueService, private router: Router) {}

  ngOnInit(): void {
    this.fetchSummary(this.entityType, this.id);
  }

  fetchSummary(entityType: string, id: string) {
    this.loading++;
    this.issueSrv
      .fetchSummary(entityType, id)
      .then((issueSummary: IssueSummary) => {
        this.summary = issueSummary;
      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

  goToIssues() {
    this.router.navigate([`/issues/${this.entityType}/${this.id}`]);
  }
}

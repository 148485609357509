<div class="" *ngIf="initialized">
  <div class="row">
    <div>
      <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
    </div>
    <div class="icons">
      <button mat-icon-button aria-label="Add" color="none" [disabled]="!('create' | able: newLabor)"
        (click)="toggleForm(null, true)">
        <mat-icon title="Add">add</mat-icon>
      </button>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- listing -->
  <div class="mat-body-strong" *ngIf="!labors.length">
    No labors found for this project
    <button mat-button color="accent" class="mat-inline-button" [disabled]="!('create' | able: newLabor)"
      (click)="toggleForm(null, true)" aria-label="Add" title="Add">
      Add labor?</button>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let record of labors">
      <div class="hover-icons">
        <a mat-icon-button [disabled]="!('update' | able: record)" (click)="toggleForm(record, true)" color="none"
          aria-label="Edit" title="Edit">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button [disabled]="!('delete' | able: record)" (click)="delete(record)" color="warn"
          aria-label="Delete" title="Delete">
          <mat-icon>delete</mat-icon>
        </a>
      </div>

      <div class="body">
        <div class="mat-subheading-2">
          {{record.first_name}} {{record.last_name}}
          <span class="mat-caption">{{record?.default_job_code?.name}}</span>
          <div class="mat-subheading-1" *ngIf="record?.sub_contractor">{{record?.sub_contractor?.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- listing -->
  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length" [length]="pagination.length"
    [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>
</ng-template>


<ng-template #InputFormContent>
  <!-- Field Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!editing">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>
    <div class="row">
      <!-- first_name section -->
      <section>
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="first_name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.first_name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.first_name.hasError('error')">
            {{inputForm.controls.first_name.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /first_name section -->

      <!-- last_name section -->
      <section>
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="last_name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.last_name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.last_name.hasError('error')">
            {{inputForm.controls.last_name.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /last_name section -->

      <!-- default_job_code_id section -->
      <section>
        <mat-form-field>
          <mat-label>Default job code</mat-label>
          <mat-select formControlName="default_job_code_id" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let jobCode of jobCodes" [value]="jobCode.id">
              <div>{{jobCode.name}}</div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inputForm.controls.default_job_code_id.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.default_job_code_id.hasError('error')">
            {{inputForm.controls.default_job_code_id.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /default_job_code_id section -->

      <!-- sub_contractor_id section -->
      <section>
        <mat-form-field>
          <mat-label>Sub Contractor</mat-label>
          <mat-select formControlName="sub_contractor_id" [errorStateMatcher]="matcher">
            <mat-option value="">None</mat-option>
            <mat-option *ngFor="let sc of subContractors" [value]="sc.id">
              <div>{{sc.name}}</div>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inputForm.controls.sub_contractor_id.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.sub_contractor_id.hasError('error')">
            {{inputForm.controls.sub_contractor_id.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /sub_contractor_id section -->

      <!-- Form Actions -->
      <section class="icons" *ngIf="editing">

        <button mat-icon-button [disabled]="!('update' | able: labor)" (click)="save(inputForm)">
          <mat-icon title="Save">save</mat-icon>
        </button>
        <button mat-icon-button (click)="onCancel()">
          <mat-icon title="Cancel">cancel</mat-icon>
        </button>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
  <!-- /Input form -->
</ng-template>
<div class="wrapper">
  <div class="heading">
    {{ heading }}
  </div>
  <div class="content">
    <div class="section description">
      {{ description }}
    </div>
    <div class="test-case-details">
      <div class="tester">
        <div class="tester-avatar">{{ testerAvatar }}</div>
        <div class="tester-name">{{ testerName }}</div>
      </div>
      <div class="test-time">{{ testTime }}</div>
    </div>
    <div class="details">
      <section class="section">
        <div class="section-heading">STATUS:</div>
        <div class="section-details">{{ data?.status }}</div>
      </section>
      <section class="section">
        <div class="section-heading">SCOPE:</div>
        <div class="section-details">{{ data?.entity?.description }}</div>
      </section>
      <section class="section">
        <div class="section-heading">INTERNAL:</div>
        <div class="section-details">{{ data?.internal ? 'Yes' : 'No' }}</div>
      </section>
      <section *ngIf="data?.pictures?.length" class="section">
        <app-attachments-block
          [pictures]="data?.pictures || []"
        />
      </section>
    </div>
  </div>
</div>

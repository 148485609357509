import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18n, Organization } from 'src/app/shared/models';
import { AppHttpService } from 'src/app/shared/services';


@Injectable({ providedIn: 'root' })
export class I18nService {

  constructor(
    private appHttpService: AppHttpService
  ) { }


  getRecords(orgId: string, qp: any = {}): Promise<I18n[]> {
    qp.start = 0;
    qp.total = 1000;
    return this.appHttpService.getService(`/v2/organization/${orgId}/i18ns`, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new I18n(r));
        }
        throw new Error('Error parsing response');
      });
  }

  save(org: Organization, locale: string, translation: any, qp: any = {}): Promise<I18n> {
    const payload = {
      locale,
      translation,
    };
    return this.appHttpService
      .putService(`/v2/organization/${org?.id}/i18n/${locale}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new I18n(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  delete(model: I18n): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/i18n/${model?.id}`)
      .toPromise();
  }
}

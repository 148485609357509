import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { Account, Invitation } from '../models';

@Injectable({ providedIn: 'root' })
export class InvitationService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  acceptInvite(code, payload: any, qp: any = {}) {
    return this.appHttpService.postService(`/v1/invitation/accept/${code}`, payload).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Account(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

}

import { BaseModel } from './base-model';
import { Drawing } from './drawing';

export class Annotation extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'Annotation';
  }

  /* tslint:disable */
  xfdf: string;
  thumb_url: string;
  user_agent: string;
  /* tslint:enable */
  drawing: Drawing;
  group_annotation_id: string;

  get relations() {
    return {
      drawing: Drawing,
    };
  }

  getThumbnail() {
    const defaultImg = '/assets/default.jpg';
    return this.thumb_url || defaultImg;
  }

  getPreviews() {
    return [
      {
        preview: this.thumb_url,
      },
    ];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { SiteMaterialTest } from '../../models';

@Component({
  selector: 'app-site-material-test-short',
  templateUrl: './site-material-test-short.component.html',
  styleUrls: ['./site-material-test-short.component.scss']
})
export class SiteMaterialTestShortComponent implements OnInit {

  @Input()
  public smt: SiteMaterialTest;

  public initialized: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.initialized = true;
    // console.log(this.smt);
  }

}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MatDatepicker } from '@angular/material/datepicker';


@Component({
  selector: 'app-payapp-date-filter',
  templateUrl: './payapp-date-filter.component.html',
  styleUrls: ['../styles.scss', './payapp-date-filter.component.scss']
})
export class PayappDateFilterComponent {
  @Output() dateChanged = new EventEmitter<{ startDate: string | null, endDate: string | null }>();
  @ViewChild('picker') picker: MatDatepicker<any>;
  form: FormGroup;

  ngAfterViewInit() {
    this.picker.openedStream.subscribe(() => {
      // Add the custom class to the overlay pane when the datepicker opens
      const overlayElement = document.querySelector('.cdk-overlay-pane');
      if (overlayElement) {
        overlayElement.classList.add('payapp-datepicker-overlay');
      }
    });
  }


  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      startDate: [null],
      endDate: [null],
    });

    this.form.valueChanges.subscribe(() => {
      this.emitDateChange();
    });
  }


  emitDateChange(): void {
    const startDate = this.form.controls.startDate.value ? moment(this.form.controls.startDate.value).format('YYYY-MM-DD') : null;
    const endDate = this.form.controls.endDate.value ? moment(this.form.controls.endDate.value).format('YYYY-MM-DD') : null;
    this.dateChanged.emit({ startDate, endDate });
  }

  removeDateFilter(event: Event): void {
    event?.stopPropagation();
    this.form.reset();
    this.emitDateChange();
  }
}

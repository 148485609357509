<div class="section-heading">
  ATTACHMENTS:
</div>
<div class="section-content">
  <app-image
    *ngFor="let image of pictures"
    class="attachment"
    [url]="image.preview_url"
    [loaderTheme]="{
             'height': '100%',
             'width': '100%'
          }"
    (click)="openPictures()"
  />
</div>

<div class="dialog-container">
  <div class="dialog-header">
    <h1 class="dialog-title">Generate payapp</h1>
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>  
  <div class="dialog-message">
    {{ data.itemCount }} items {{ getDateRangeMessage() }} are included in the report.
    Total amount of {{ data.totalAmount | currency:'USD' }} USD.  
  </div>
  <label class="payapp-label">ADD PAYAPP NAME (OPTIONAL):</label>
  <mat-form-field class="payapp-input" appearance="none">
    <input matInput [(ngModel)]="data.payappName" class="input-field">
  </mat-form-field>
  <div class="generate-button">
    <app-primary-button
    (handleClick)="onConfirmClick()"
    >
      Create
    </app-primary-button>
  </div>
</div>

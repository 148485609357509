<mat-expansion-panel
  hideToggle
  class="status-section"
  [disabled]="isDisabled"
>
  <mat-expansion-panel-header
    class="accordion-header"
  >
    <mat-panel-title class="section-heading">
      <div
        class="stepper-block"
        [class.disabled]="isDisabled"
      >
        <div class="stepper-circle"></div>
        <div class="stepper-line"></div>
      </div>
      <div class="header">
        <div class="line-1">
          <div class="block-heading">REPORT</div>
          <div
            *ngIf="subheading"
            class="section-heading"
          >
            {{ subheading }}
          </div>
        </div>
        <mat-icon
          class="line-2 chevron-icon"
          [svgIcon]="isExpanded ? 'chevron-up' : 'chevron-down'"
        />
      </div>

    </mat-panel-title>
  </mat-expansion-panel-header>
  <div
    class="content-section"
    [class.disabled]="isDisabled"
  >
    <section class="section">
      <div class="section-heading">UPLOADED BY:</div>
      <div class="section-content">{{uploadedBy}}</div>
      <div class="section-content">{{uploadedOn}}</div>
    </section>
    <section
      *ngIf="pictures?.length"
      class="section"
    >
      <app-attachments-block
        [pictures]="pictures"
      />
    </section>
    <section
      *ngIf="comment"
      class="section"
    >
      <div class="section-heading">REPORT NOTE:</div>
      <div class="section-content">
        {{ comment }}
      </div>
    </section>
  </div>
</mat-expansion-panel>

<div *ngIf="initialized">
  <div class="mat-body-strong" *ngIf="!subContractors.length">No sub contractors</div>

  <div *ngIf="subContractors.length" class="cards-container">

    <div *ngFor="let sc of subContractors" class="stroked-card">
      <div class="mat-body-strong capitalize">
        <mat-checkbox [checked]="sc.checked" [disabled]="!canAdd" (change)="onChange(sc, $event.checked)">
          {{sc.name}}
        </mat-checkbox>
      </div>
      <div class="mat-caption" *ngIf="sc.address">
        <div>{{sc.address?.line1}}</div>
        <div>{{sc.address?.line2}}</div>
        <div>{{sc.address?.city}}, {{sc.address?.region}}</div>
        <div>{{sc.address?.country}} {{sc.address?.zip}}</div>
      </div>
      <div class="line">
        <div class="line" *ngIf="sc.poc">
          <mat-icon color="primary">person</mat-icon>{{sc.poc}}
        </div>
        <div class="line" *ngIf="sc.poc_phone">
          <mat-icon color="primary">phone</mat-icon>{{sc.poc_phone}}
        </div>
      </div>
    </div>

    <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && subContractors.length"
      [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
    </mat-paginator>

  </div>

</div>

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DailyReportService } from '../../../../../../../site-details-page/tab-screens/daily-report/daily-report.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { dailyReport } from '../../../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-consolidated-report-activity-details',
  templateUrl: './consolidated-report-activity-details.component.html',
  styleUrls: ['./consolidated-report-activity-details.component.scss'],
})
export class ConsolidatedReportActivityDetailsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  pictures = [];
  form: FormGroup = new FormGroup({
    title: new FormControl({ value: '', disabled: true }),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    pictures: new FormControl('[]'),
  });
  disableOptionMenu = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.data) {
      this.form.patchValue({
        title: this.data?.title,
        latitude: this.data?.latitude,
        longitude: this.data?.longitude,
        pictures: JSON.stringify(this.data?.pictures),
      });

      this.pictures = this.data?.pictures;
    } else {
      this.disableOptionMenu = true;
      const newActivity = {
        id: `new_${new Date().getTime()}`,
        title: '',
        description: '',
        latitude: '',
        longitude: '',
        pictures: [],
        address: null,
        annotation: null,
      };

      this.store.select(dailyReport)
        .subscribe((res: any) => {
          const index = res.activities?.length || 0;

          this.data = {
            index: index + 1,
            ...newActivity,
          };

          this.cdRef.detectChanges();
        });
    }

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        let pictures = this.form.get('pictures')?.value || '[]';

        if (pictures) {
          pictures = JSON.parse(pictures);

          pictures = [...pictures, ...data];
        } else {
          pictures = [...data];
        }

        this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
        this.pictures = pictures;
      });

    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.save();
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.delete();
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        this.form.patchValue({
          latitude: location.latitude,
          longitude: location.longitude,
        });
      });

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res: any) => {
        this.projectsService.isAnyFormDirty.next(res);
      });
  }

  save(): void {
    const { index, ...rest } = this.data;

    const values = this.form.getRawValue();
    const payload = {
      ...rest,
      ...values,
      pictures: JSON.parse(values.pictures),
    };

    this.dailyReportService.save.next({
      type: 'activities',
      data: [payload],
    });
  }

  delete() {
    this.dailyReportService.delete.next({
      type: 'activities',
      id: this.data.id,
    });
  }

  deletePicture(id) {
    let pictures = this.getPictures();

    pictures = pictures?.filter(o => o.id !== id);

    this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
    this.pictures = pictures;
  }

  deleteLocation() {
    this.form?.get('latitude').setValue(null);
    this.form?.get('longitude').setValue(null);
  }

  getPictures() {
    const pictures = this.form.controls['pictures']?.value || '[]';
    return JSON.parse(pictures);
  }

  showLocationSection() {
    return this.form?.controls?.latitude?.value !== null &&
      this.form?.controls?.longitude?.value !== null &&
      this.form?.controls?.latitude?.value !== '' &&
      this.form?.controls?.longitude?.value !== '';
  }

  ngOnDestroy() {
    this.cdRef.detach();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

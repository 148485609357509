import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectReport } from 'src/app/shared/models';
import { ProjectReportService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-report-detail',
  templateUrl: './project-report-detail.component.html',
  styleUrls: ['./project-report-detail.component.scss']
})
export class ProjectReportDetailComponent implements OnInit {

  public initialized: boolean = false;
  public loading: number = 0;
  public projectReport: ProjectReport;

  public wfEx: string[] = [];

  constructor(
    private prSrv: ProjectReportService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.has('prId')) {
      this.getProjectReport(this.route.snapshot.paramMap.get('prId'));
    }
  }

  /**
   * Fetches ProjectReport
   * @param prId ProjectReport.id
   */
  getProjectReport(prId: string) {
    this.wfEx = [
      `project-report-approval/${prId}`,
    ]
    this.prSrv.getRecord(prId)
      .then((pr: ProjectReport) => {
        this.projectReport = pr;
      })
      .catch((e: any) => {
        // Todo handle error
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * callback after project-report action is performed
   * @param wfResp any
   */
  projectReportChanged(wfResp: any) {
    this.getProjectReport(this.projectReport.id);
  }
}

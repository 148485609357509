import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { projectDetails, siteWiseDailyReportsSummary } from '../../../../../../core/projects.selectors';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { MapService } from '../../../../../../../../shared/services/map.service';
import { FetchSiteWiseDailyReportSummaryRequest } from '../../../../../../core/projects.actions';
import { CommonService } from '../../../../../../../../shared/services/common.service';

@Component({
  selector: 'app-site-wise-reports',
  templateUrl: './site-wise-reports.component.html',
  styleUrls: ['./site-wise-reports.component.scss'],
})
export class SiteWiseReportsComponent implements OnInit, OnDestroy {
  sites: any[] = [];
  filteredSites: any[] = [];
  startDate = null;
  endDate = null;
  projectDetails: any;
  siteIdsToFilter: string[] = [];
  statusIdsToFilter: string[] = [];
  creatorIdsToFilter: string[] = [];
  itemsPerPage = 5;
  currentPage = 1;
  paginatedFilteredSites: any[];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private mapService: MapService,
    private commonService: CommonService,
  ) {

  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.startDate && moment(qp.startDate).isValid()) {
          this.startDate = qp.startDate;
        } else {
          this.startDate = null;
        }

        if (qp.endDate && moment(qp.endDate).isValid()) {
          this.endDate = qp.endDate;
        } else {
          this.startDate = null;
        }

        if (qp.status) {
          if (Array.isArray(qp.status)) {
            this.statusIdsToFilter = qp.status;
          } else {
            this.statusIdsToFilter = [qp.status];
          }
        } else {
          this.statusIdsToFilter = [];
        }

        if (qp.site) {
          if (Array.isArray(qp.site)) {
            this.siteIdsToFilter = qp.site;
          } else {
            this.siteIdsToFilter = [qp.site];
          }
        } else {
          this.siteIdsToFilter = [];
        }

        if (qp.creator) {
          if (Array.isArray(qp.creator)) {
            this.creatorIdsToFilter = qp.creator;
          } else {
            this.creatorIdsToFilter = [qp.creator];
          }
        } else {
          this.creatorIdsToFilter = [];
        }

        this.filterSites();
      });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (Object.keys(data).length !== 0) {
          this.projectDetails = data;
        }
      });

    this.store.dispatch(FetchSiteWiseDailyReportSummaryRequest({
      payload: {
        projectId: this.route.snapshot.params.projectId,
      },
    }));

    this.store.select(siteWiseDailyReportsSummary)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.sites = data;
        if (this.sites.length) {
          this.filterSites();
        }
      });

    this.commonService.resetPaginator
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.currentPage = 1;
      });
  }

  filterSites() {
    let filteredSites = JSON.parse(JSON.stringify(this.sites));

    filteredSites = filteredSites.filter(site => {
      if (this.siteIdsToFilter.length) {
        return this.siteIdsToFilter.includes(site.site_id);
      } else {
        return true;
      }
    })
      .filter(site => {
        if (this.statusIdsToFilter.length || this.creatorIdsToFilter.length) {
          site.daily_reports = site.daily_reports
            .filter(report => {
              if (this.statusIdsToFilter.length) {
                const status = JSON.parse(JSON.stringify(this.statusIdsToFilter));

                const index = this.statusIdsToFilter.indexOf('submitted-reviewed');

                if (index > -1) {
                  status.splice(index, 1);
                  status.push(...['submitted', 'reviewed']);
                }

                return status.includes(report.status);
              }
              return true;
            })
            .filter(report => {
              if (this.creatorIdsToFilter.length) {
                return this.creatorIdsToFilter.includes(report.created_by_id);
              }

              return true;
            }) || [];

          if (site.daily_reports.length) {
            return true;
          }
        } else {
          return true;
        }

      });

    if (this.startDate) {
      filteredSites = filteredSites
        .filter(site => {
            site.daily_reports = site?.daily_reports?.filter(o => moment(o.report_date, 'YYYY-MM-DD')
              .isSameOrAfter(moment(this.startDate, 'YYYY-MM-DD'))) || [];

            if (site.daily_reports.length) {
              return site;
            }
          },
        );
    }


    if (this.endDate) {
      filteredSites = filteredSites
        .filter(site => {
          site.daily_reports = site?.daily_reports?.filter(o => moment(o.report_date, 'YYYY-MM-DD')
            .isSameOrBefore(moment(this.endDate, 'YYYY-MM-DD')),
          ) || [];

          if (site.daily_reports.length) {
            return site;
          }
        });
    }

    filteredSites = filteredSites
      .filter(o => o?.daily_reports?.length)
      .sort((a, b) => {
        const lastUpdatedA = Math.max(...a.daily_reports?.map(report => new Date(report.updated_at).getTime()));
        const lastUpdatedB = Math.max(...b.daily_reports?.map(report => new Date(report.updated_at).getTime()));

        return lastUpdatedB - lastUpdatedA;
      });

    this.filteredSites = filteredSites;
    this.onPageChanged(1);
    this.commonService.resetPaginator.next(true);
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.updateFilteredSitesList();
  }

  updateFilteredSitesList(): void {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.filteredSites.length);

    this.paginatedFilteredSites = this.filteredSites.slice(startIndex, endIndex);
    this.mapService.initiateSiteLocationsMap.next(this.paginatedFilteredSites);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

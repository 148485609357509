<mat-form-field
  *ngIf="parentFormGroup.controls[controlName]"
  [formGroup]="parentFormGroup"
  [ngClass]="{
        'enabled-clear-selection': enableClearSelection &&
                                   parentFormGroup.controls[controlName].value &&
                                   parentFormGroup.controls[controlName].status !== 'DISABLED',
      }"
  appearance="legacy"
  class="form-field w-full"
  floatLabel="always"
  hideRequiredMarker
>
  <mat-label class="text-type-13">{{ label }}:</mat-label>
  <mat-select
    #select
    (keydown)="$event.preventDefault() && $event.preventDefault()"
    [formControlName]="controlName"
    [multiple]="enableMultiple"
    [ngClass]="{
            'enabled-clear-selections': (parentFormGroup.controls[controlName].status !== 'DISABLED') &&
            (enableClearSelection && parentFormGroup.getRawValue()[controlName])
          }"
    [panelClass]="optionsPanelClassName"
    [placeholder]="placeholder"
    class="select"
    disableOptionCentering
    panelClass="testClass"
  >
    <mat-option
      *ngIf="loading; else loaded"
      class="dropdown-loading"
      disabled
    >
      <mat-spinner
        [diameter]="20"
        class="dropdown-loader"
        mode="indeterminate"
      >
      </mat-spinner>
    </mat-option>
    <ng-template #loaded>
      <!--      SEARCH SECTION-->
      <form
        *ngIf="(options?.length || enableAddItem) && enableSearch"
        [formGroup]="searchForm"
      >
        <div class="filter-section search-section">
          <div class="search">
            <mat-icon svgIcon="search"></mat-icon>
            <input
              (keydown)="$event.stopPropagation()"
              formControlName="text"
              matInput
              placeholder="Search"
            >
          </div>
        </div>
      </form>

      <div *ngIf="filteredOptions?.length; else noData">
        <div
          (click)="addItem()"
          *ngIf="enableAddItem"
          [class.disabled]="addText.length === 0 || disabledClass"
          class="add-item flex justify-start items-center"
        >
          <span
            class="flex justify-start items-center"
          >
            <mat-icon
              [svgIcon]="'plus'"
              class="icon mr-[5px]"
            />
          Add {{ addText ? addText : 'item' }}
          </span>
        </div>
        <ng-container *ngIf="!enableDetailedOptionView">
          <ng-container *ngFor="let option of filteredOptions; let i = index">
            <mat-option
              *ngIf="!option['children']"
              [disabled]="(option.value !== -1 && optionsDisabled) ||
              option.disabled"
              [value]="option.value"
              class="single-select-option"

            >
              {{ option.label }}
            </mat-option>
            <ng-container
              *ngIf="option['children']"
            >
              <mat-option
                [disabled]="true"
                [value]="option.value"
                class="single-select-option"
              >
                {{ option.label }}
              </mat-option>
              <mat-option
                *ngFor="let child of option['children']"
                [disabled]="child.disabled"
                [value]="child.value"
                class="single-select-option child-option"
              >
                {{ child.label }}
              </mat-option>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="enableDetailedOptionView">
          <ng-container *ngFor="let option of filteredOptions; let i = index">
            <mat-option
              [disabled]="(option.value !== -1 && optionsDisabled) ||
              option.disabled"
              [value]="option.id"
              class="detailed-single-select-option"
            >
              <div
                class="each-item flex justify-start items-center gap-[16px]"
              >
                <div class="left flex justify-start items-center">
                  <div class="icon-wrapper">
                    <mat-icon svgIcon="daily-report-page-menu-bid-item" />
                  </div>
                </div>
                <div class="right flex flex-col justify-center items-start">
                  <div class="bid-item-heading text-type-6">Bid item {{ option.item }}</div>
                  <div class="h-[1px]">&nbsp;</div>
                  <div class="description text-type-14">{{ option.description }}</div>
                </div>
              </div>
            </mat-option>
          </ng-container>
        </ng-container>
      </div>
      <ng-template #noData>
        <div
          (click)="addItem()"
          *ngIf="enableAddItem"
          [class.disabled]="addText.length === 0 || disabledClass"
          class="add-item flex justify-start items-center"
        >
          <span
            class="flex justify-start items-center"
          >
            <mat-icon
              [svgIcon]="'plus'"
              class="icon mr-[5px]"
            />
          Add {{ addText ? addText : 'item' }}
          </span>
        </div>
        <mat-option
          class="no-data"
          disabled>
          No options available
        </mat-option>
      </ng-template>
    </ng-template>
  </mat-select>
  <mat-icon
    *ngIf="parentFormGroup.controls[this.controlName].status !== 'DISABLED'"
    class="suffix-icon"
    matSuffix
    svgIcon="chevron-down"
  />
  <mat-icon
    (click)="clearSelection($event)"
    *ngIf="
          defaultValue && defaultValue.length ?
          (
             parentFormGroup.getRawValue()[controlName]?.join(',') !==
             defaultValue.join(',')
          ) : (
            (parentFormGroup.controls[this.controlName].status !== 'DISABLED') &&
            (
              (!enableMultiple && enableClearSelection && parentFormGroup.getRawValue()[controlName]) ||
              (enableMultiple && enableClearSelection && parentFormGroup.getRawValue()[controlName]?.length)
            )
          )"
    class="clear-selection"
    matSuffix
    matTooltip="Clear"
  >close
  </mat-icon>
  <mat-error *ngIf="error">{{ error }}</mat-error>
</mat-form-field>


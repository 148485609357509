import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Issue, IssueSummary } from '../models';

@Injectable({ providedIn: 'root' })
export class IssueService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Saves record
   * @param issue Issue
   * @param qp query-params
   * @return {Promise<Issue>}
   */
  save(issue: Issue, payload: any = null, qp: any = {}): Promise<Issue> {

    if (!payload) {
      payload = issue.toPayload();
    }
    return this.appHttpService.postService(`/v1/issue`, payload, qp).toPromise()
      .then(resp => {
        return new Issue(resp.data);
      });
  }

  /**
   * close record
   * @param issue Issue
   * @param qp query-params
   * @return {Promise<Issue>}
   */
  close(issue: Issue, qp: any = {}): Promise<Issue> {
    return this.appHttpService.postService(`/v1/issue/${issue.id}/close`, null, qp).toPromise()
      .then(resp => new Issue(resp.data));
  }

  /**
   * reopen issue
   * @param issue Issue
   * @param qp query-params
   * @return {Promise<Issue>}
   */
  reopen(issue: Issue, qp: any = {}): Promise<Issue> {
    return this.appHttpService.postService(`/v1/issue/${issue.id}/reopen`, null, qp).toPromise()
      .then(resp => new Issue(resp.data));
  }

  /**
   * Fetches entity's issue summary
   * @param entityType string
   * @param id string
   * @returns {Promise<IssueSummary>}
   */
  fetchSummary(entityType: string, id: string) {
    return this.appHttpService.getService(`/v1/issue/summary/${entityType}/${id}`).toPromise()
      .then(resp => new IssueSummary(resp.data));
  }

}

<div class="wrapper">
  <div class="text-type-4 mb-[12px]">
    {{ site.site_name }} ({{ reportsList?.length || 0 }}
    report{{ reportsList?.length !== 1 ? 's' : '' }})
  </div>
  <div
    *ngIf="reportsList?.length; else noReports"
    class="reports-list"
    [class.collapsed]="!isExpanded"
  >
    <app-site-report-card
      *ngFor="let report of reportsList"
      [report]="report"
      (click)="onClick(report)"
    />
  </div>
  <div
    *ngIf="reportsList?.length > 3"
    class="see-more text-type-9 flex"
    (click)="isExpanded = !isExpanded"
  >
    {{ isExpanded ? 'See less' : 'See more' }}
  </div>
  <ng-template #noReports>
    <app-site-report-card />
  </ng-template>
</div>

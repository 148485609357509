import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { EquipmentType, Project } from 'src/app/shared/models';
import { EquipmentTypeService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-equipment-types',
  templateUrl: './equipment-types.component.html',
  styleUrls: ['./equipment-types.component.scss'],
})
export class EquipmentTypesComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input() public project: Project;
  public equipmentTypes: EquipmentType[] = [];

  public projectEquipmentTypes: EquipmentType[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private equipmentTypeSrv: EquipmentTypeService,
  ) {
  }

  ngOnInit(): void {
    this.getOrgEquipmentTypes();
  }

  getOrgEquipmentTypes(): void {
    this.loading++;
    this.equipmentTypeSrv.getRecords(
      this.project?.owner_organization,
      { pageIndex: 0, pageSize: 10000 },
      { include: [] },
    ).then((resp: any) => {
      const { result } = resp;
      this.equipmentTypes = result;
      return this.getProjectEquipmentTypes();
    }).finally(() => {
      this.initialized = true;
      this.loading--;
    });
  }

  getProjectEquipmentTypes() {
    const qp = {
      include: ['project_id', 'organization_id'],
    };
    return this.equipmentTypeSrv.getProjectRecords(this.project, qp)
      .then((equipmentTypes: EquipmentType[]) => {
        this.projectEquipmentTypes = equipmentTypes.filter(o => o.project_id);
        // mark as checked/unchecked
        this.equipmentTypes.map((o: any) => o.checked = !!equipmentTypes.find((p) => p.id === o.id));
      });
  }

  /**
   * OnChange event of checkbox
   * @param equipmentType EquipmentType
   * @param checked boolean
   */
  onCheckToggle(equipmentType: EquipmentType, checked) {
    const req = checked
      ? this.equipmentTypeSrv.addToProject(equipmentType, this.project)
      : this.equipmentTypeSrv.removeFromProject(equipmentType, this.project);

    req.then((msg: string) => {
      this.snackBar.open(msg, '', { duration: 5000 });
    })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      });
  }

}

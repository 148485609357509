import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import { FetchProjectDetailsRequest, FetchProjectMembersRequest } from '../../../core/projects.actions';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatTabGroup } from '@angular/material/tabs';
import { projectDetails, siteWiseDailyReportsSummary } from '../../../core/projects.selectors';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { ProjectsService } from '../../../core/projects.service';
import { AuthService} from 'src/app/shared/services';

class CustomDateAdapter extends MomentDateAdapter {
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow') {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  }
}

@Component({
  selector: 'app-project-details-screen',
  templateUrl: './project-details-screen.component.html',
  styleUrls: ['./project-details-screen.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'DD/MM/YYYY' },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'DD/MM/YYYY',
          monthYearA11yLabel: 'MM YYYY',
        },
      },
    },
  ],
})
export class ProjectDetailsScreenComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('tabGroup') tabs: MatTabGroup;
  projectId: string;
  projectDetails: any;
  currentUser: any;
  dailyReports: any[] = [];
  defaultDateValue = 'All time';
  isContractorMode: boolean;
  tabIndexMap = {
    sites: 0,
    'consolidated-reports': 1,
    settings: 2,
    payapp: 3,
    'change-orders': 4,
  };
  selectedTabIndex = 0;
  pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 1000,
  };
  sitesCount = 0;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private projectsService: ProjectsService,
  ) {
    this.projectId = this.route.snapshot.paramMap.get('projectId');
    this.currentUser = this.authService?.getCurrentUser() || {};
    this.isContractorMode = this.currentUser.roles.some(r => r.project_id === this.projectId && (r.role === 'contractor-pm' || r.role === 'contractor-inspector'));

    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.fs === undefined) {
          if (qp.tab) {
            this.selectedTabIndex = this.tabIndexMap[qp.tab] || 0;
          }
        }
      });
  }

  ngOnInit() {
    const payload = {
      projectId: this.projectId,
      qp: {
        include: [
          'cover_photo',
          'owner_organization',
          'sites',
        ],
      },
    };

    this.store.dispatch(FetchProjectDetailsRequest({ payload }));
    this.store.dispatch(FetchProjectMembersRequest({
      payload: {
        projectId: this.route.snapshot.params.projectId,
      },
    }));

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.projectDetails = data;
      });

    this.store.select(siteWiseDailyReportsSummary)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.sitesCount = data?.length || 0;

        this.cdRef.detectChanges();
      });
  }

  ngAfterViewInit() {
    // Disable tab click, to change the tab. This is done to make the tab
    // change stay in sync with the query params.
    this.tabs._handleClick = this.onTabChange.bind(this);
  }

  onTabChange(tab) {
    this.projectsService.closeRightPanel.next();
    this.router.navigate([], {
      queryParams: {
        tab: tab.textLabel.toLowerCase().replaceAll(' ', '-'),
        ...(tab.textLabel.toLowerCase() === 'sites' ? {
          status: ['submitted-reviewed', 'approved', 'draft'],
          startDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        } : {}),
      },
    });
  }

  ngOnDestroy() {
    this.cdRef.detach();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DateFormatPipe } from '../../../../../../../../shared/pipes';

@Component({
  selector: 'app-offsite-test-schedule-block',
  templateUrl: './offsite-test-schedule-block.component.html',
  styleUrls: ['../../../styles.css', '../styles.css', './offsite-test-schedule-block.component.scss']
})
export class OffsiteTestScheduleBlockComponent implements OnChanges {
  @Input() activity: any;
  @Input() data: any;
  @Input() isDisabled: boolean;

  subheading = '';
  isExpanded = false;

  constructor(
    private dateFormatPipe: DateFormatPipe,
  ) {
  }

  ngOnChanges() {
    this.createSubheading();
  }

  createSubheading() {
    if (!this.activity?.lab_scheduled) {
      this.subheading = 'Test request received';
      return;
    }

    if (this.activity.lab_scheduled) {
      this.subheading = this.dateFormatPipe.transform(this.data.request_date, 'very-long');
      return;
    }
  }
}

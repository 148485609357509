import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { InternalTest, Project, Site } from 'src/app/shared/models';
import { InternalTestService, SiteService } from 'src/app/shared/services';

@Component({
  selector: 'app-site-internal-tests',
  templateUrl: './site-internal-tests.component.html',
  styleUrls: ['./site-internal-tests.component.scss']
})
export class SiteInternalTestsComponent implements OnInit {

  public loading: number = 0;
  public initialized: boolean = false;

  @Input() public site: Site;
  public internalTests: InternalTest[] = [];
  public project: Project;

  constructor(
    private snackBar: MatSnackBar,
    private internalTestSrv: InternalTestService,
    private siteSrv: SiteService,
  ) { }

  ngOnInit(): void {
    this.fetchSite(this.site.id);
    this.project = new Project({ id: this.site?.project_id });
  }

  fetchSite(id: string): void {
    const qp = {
      include: ['internal_tests'],
    }
    this.siteSrv.fetchSite(id, qp)
      .then((site: Site) => {
        this.site = site;
        return this.fetchInternalTests();
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  fetchInternalTests(): void {
    this.internalTestSrv.getRecords(
      this.project,
      { pageIndex: 0, pageSize: 100000 },
      { include: [''] },
    ).then((resp: any) => {
      this.internalTests = resp.result;
      // mark materials as checked/unchecked
      this.internalTests.map((o: any) => o.checked = !!this.site.internal_tests.find((t) => t.id === o.id));
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    });
  }

  /**
   * OnChange event of checkbox
   * @param internalTest InternalTest
   * @param checked boolean
   */
  onChange(internalTest: InternalTest, checked) {
    const req = checked
      ? this.internalTestSrv.addSite(internalTest, this.site)
      : this.internalTestSrv.removeSite(internalTest, this.site);

    req.then((msg: string) => {
        this.snackBar.open(msg, '', { duration: 5000 });
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      });
  }

}

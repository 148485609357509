import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Util } from 'src/app/shared/helpers';
import { Field, Station } from 'src/app/shared/models';
import { SiteService } from 'src/app/shared/services';

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss']
})
export class FieldInputComponent implements OnInit {

  public Util = Util;

  @Input() field: Field;
  @Input() form: UntypedFormGroup;
  @Input() matcher: ErrorStateMatcher;
  @Input() notifyOnChange: boolean;
  @Input() recentValueQry: any;
  @Output() onValueChange = new EventEmitter<any>();

  @Input() station: Station;

  public prev: any;

  get formControl(): AbstractControl {
    return this.form.get(this.field?.id);
  }

  get isRequired(): boolean {
    const validator = this.formControl.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
    return false;
  }

  get isValid() {
    return !(this.formControl.touched && !this.formControl.valid);
  }

  get value() {
    return this.formControl.value;
  }

  getErrorMessage() {
    const errors = this.formControl.errors;
    return Object.entries(errors || {}).map(([type, e]) => {
      let msg: string = '';

      switch (type) {
        case 'required': msg = 'This field is required'; break;
        case 'min': msg = `This field must be greater than or equal to ${e.min}`; break;
        case 'max': msg = `This field must be lesser than or equal to ${e.max}`; break;
        case 'error': msg = e; break;
        default:
          msg = 'Invalid input, please check';
      }

      return msg;
    }).join('<br>');
  }

  get appearance() {
    return this.field?.type === 'textarea' ? 'outline' : null;
  }

  constructor(
    private siteSrv: SiteService
  ) { }

  ngOnInit(): void {
    if (this.notifyOnChange) {
      this.formControl.valueChanges.subscribe((v) => {
        this.onValueChange.emit(v);
      });
    }

    if (this.field?.recent_field) {
      this.siteSrv.getRecentBiditemValue(
        this.station?.id,
        this.field.recent_field.id,
        this.recentValueQry
      ).subscribe((v) => this.prev = v);
    }
  }

  get outOfRange(): boolean {
    if (!this.formControl.touched && Util.isEmpty(this.formControl.value)) {
      return false;
    }

    const { min = '', max = '' } = this.field?.range_values || {};

    if (Util.isNumeric(min) && Util.isNumeric(max)) {
      return this.value < min || this.value > max;
    }

    if (Util.isNumeric(min)) {
      return this.value < min;
    }

    if (Util.isNumeric(max)) {
      return this.value > max;
    }

    return false;
  }

  get range(): any {
    const { min = null, max = null } = this.field?.range_values || {};
    return { min, max };
  }

  get hint() {
    const { estimate_station_property = null, range_values: range = {} } = this.field || {};
    const props = this.station?.properties || {};
    if (estimate_station_property && estimate_station_property in props) {
      return {
        type: 'estimate',
        value: props[estimate_station_property],
      };
    }

    if (Util.isNumeric(range?.min) || Util.isNumeric(range?.max)) {
      return {
        type: 'range',
        value: range,
      };
    }

    return { type: 'none', value: null };
  }
}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Site, Station } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class StationService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Stations with pagination
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: Station[]}
   */
  getRecords(site: Site, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    qp.site_id = site?.id;

    return this.appHttpService
      .getService('/v2/stations', qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Station(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Station
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Station>}
   */
  create(payload: any = null, qp: any = {}): Promise<Station> {
    return this.appHttpService
      .postService(`/v2/alignment/${payload?.alignment_id}/station`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Station(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Station
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Station>}
   */
  update(payload: any = null, qp: any = {}): Promise<Station> {
    return this.appHttpService
      .putService(`/v2/station/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Station(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes station
   * @param model Station
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Station): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/station/${model?.id}`)
      .toPromise();
  }
}

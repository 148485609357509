<div class="container" *ngIf="initialized">
  <!-- title section -->
  <section class="header">
    <div class="row">
      <div class="mat-subheading-1">{{alignment?.name}}</div>
      <div class="icons">
        <button mat-icon-button (click)="toggleInputForm(null, true)" aria-label="Add heading" color="none">
          <mat-icon title="Add heading">add</mat-icon>
        </button>
      </div>
    </div>

  </section>
  <!-- /title section -->
</div>

<div class="container" *ngIf="initialized">
  <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- listing -->
  <div class="mat-body-strong" *ngIf="!headings.length">
    No headings found for this alignment
    <a class="mat-inline-button" color="accent" (click)="toggleInputForm(null, true)">Add heading?
    </a>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let heading of headings">
      <div class="hover-icons">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item
            (click)="toggleInputForm(heading, true)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button mat-menu-item
            (click)="delete(heading)">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
      </div>

      <div class="body">
        <div class="row">
          <div class="mat-body-strong">{{heading.name}}</div>
          <div class="mat-caption">
            <ng-container *ngIf="heading.direction == 'asc'">Up station</ng-container>
            <ng-container *ngIf="heading.direction == 'desc'">Down station</ng-container>
              {{heading.starts_from}} to {{heading.ends_at}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- listing -->
</ng-template>

<ng-template #InputFormContent>
  <!-- Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!showInputForm">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
      <input matInput type="text" formControlName="alignment_id" />
    </section>
    <!-- name section -->
    <section>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('error')">
          {{inputForm.controls.name.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /name section -->

    <!-- starts_from section -->
    <section>
      <mat-form-field>
        <mat-label>From station</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="starts_from" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.starts_from.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.starts_from.hasError('error')">
          {{inputForm.controls.starts_from.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /starts_from section -->

    <!-- ends_at section -->
    <section>
      <mat-form-field>
        <mat-label>To station</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="ends_at" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.ends_at.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.ends_at.hasError('error')">
          {{inputForm.controls.ends_at.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /ends_at section -->

    <!-- direction section -->
    <section>
      <mat-form-field>
        <mat-label>Direction</mat-label>
        <mat-select formControlName="direction" [errorStateMatcher]="matcher">
          <mat-option value="asc">Up station</mat-option>
          <mat-option value="desc">Down station</mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.controls.direction.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.direction.hasError('error')">
          {{inputForm.controls.direction.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /direction section -->

    <!-- Form Actions -->
    <section>
      <div class="row">
        <button mat-stroked-button color="accent" (click)="save(inputForm)">
          <ng-container *ngIf="inputForm.controls.id.value">Update</ng-container>
          <ng-container *ngIf="!inputForm.controls.id.value">Create</ng-container>
        </button>
        <button type="button" mat-stroked-button (click)="toggleInputForm(null, false)">Cancel</button>
      </div>
    </section>
    <!-- Form Actions -->
  </form>
  <!-- /form -->
</ng-template>


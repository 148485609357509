import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import * as fromRoot from '../../../../../../state/app.state';
import {Store} from '@ngrx/store';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-issues-summary',
  templateUrl: './issues-summary.component.html',
  styleUrls: ['./issues-summary.component.scss'],
})
export class IssuesSummaryComponent implements OnInit, OnDestroy {
  public initialized = false;
  public loading = 0;

  @Input() data: any;
  @Input() projectId = '';

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit(): void {
  }

  redirectToProjectIssues(status) {
    this.router.navigate([`/issues/project/${this.projectId}`], {
      queryParams: {
        status,
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

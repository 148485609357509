import { Attachment } from './attachment';
import { BaseModel } from './base-model';
import { Organization } from './organization';
import { Project } from './project';

export class Account extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'Account';
  }

  /* tslint:disable */
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  organization: Organization;
  role: string;
  picture: Attachment;
  has_password: boolean;
  locale: string;
  offline_support: boolean;

  email_notifications: boolean;
  push_notifications: boolean;
  sms_notifications: boolean;
  /* tslint:enable */
  token: string;
  abilities: any;
  roles: any;

  hasPicture: boolean;

  get relations() {
    return {
      picture: Attachment,
      organization: Organization,
    };
  }

  /**
   * Workaround to initialize default values
   */
  init() {
    this.hasPicture = false;
    this.sms_notifications = true;
    this.push_notifications = true;
    this.email_notifications = true;
    this.offline_support = true;
    this.has_password = false;
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    if (this.picture instanceof Attachment) {
      this.hasPicture = true;
    }
  }

  get name() {
    return `${this.first_name} ${this.last_name}`;
  }

  get profileChar() {
    return this.first_name[0];
  }

  get lastnameChar() {
    return this.last_name[0] || null;
  }

  getProjectRole(project: Project): any {
    return (this.roles || []).find(r => r.project_id === project.id) || {};
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || null,
      first_name: this.first_name,
      last_name: this.last_name,
      locale: this.locale,
      picture_id: this.picture?.id,
      offline_support: this.offline_support,
      sms_notifications: this.sms_notifications,
      push_notifications: this.push_notifications,
      email_notifications: this.email_notifications,
    };

    return payload;
  }
}

<!--<app-project-map-->
<!--  [mapLayers]="mapLayers"-->
<!--  [dailyReports]="dailyReports"-->
<!--  [project]="project"-->
<!--  [isFullScreen]="mapFullScreen"-->
<!--  (toggleMapFullScreen)="toggleMapFullScreen()"-->
<!--  [class.map-full-screen]="mapFullScreen"-->
<!--&gt;</app-project-map>-->

<div class="page-content" *ngIf="initialized">

  <!-- Project details -->
  <app-project-card [project]="project" [showProgress]="false" picHeight="90px"></app-project-card>

  <!-- Date tabs -->
  <nav mat-tab-nav-bar class="sub-nav">
    <a mat-tab-link *ngFor="let date of dates; let i = index" [routerLink]="['../', date.format('YYYY-MM-DD')]"
      [active]="date.isSame(reportDate, 'day')">{{date | dateFormat: 'first-long-short': i }}</a>
    <a mat-tab-link>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
      <input matInput style="width: 0; visibility: hidden;" [matDatepickerFilter]="projectDates"
        [matDatepicker]="picker" (ngModelChange)="datePicked($event)" [(ngModel)]="pickedDate" />
    </a>
  </nav>
  <!-- /Date tabs -->

  <!-- Tab Content -->
  <div class="tab-content">
    <ng-container *ngIf="projectReport?.id">

      <div class="stroked-card">
        <!-- Todo Missing/incorrect typography: 18/24 700-->
        <div class="mat-subheading-2">Project report</div>
        <div class="mat-subheading-2 panel-indicator" (click)="showConsolidatedReport = !showConsolidatedReport"
          [class.open]="showConsolidatedReport" [class.close]="!showConsolidatedReport">View consolidated daily report
        </div>
      </div>

      <div *ngIf="showConsolidatedReport" class="report-panel">

        <!-- Project report status -->
        <ng-container *ngTemplateOutlet="projectReportStatus"></ng-container>
        <!-- Project report status -->

        <app-project-consolidated-report
          [editable]="true"
          [dailyReports]="dailyReports"
          [showConsolidatedReport]="showConsolidatedReport"
        />

        <div class="form-actions">

          <!-- Create project report as draft if it is not done yet -->
          <button *ngIf="!projectReport?.id && ('create' | able: projectReport)" mat-flat-button color="primary"
            (click)="saveReport()">Save</button>

          <!-- Show workflow actions for project report -->
          <ng-container *ngIf="projectReport?.id && ('read' | able: projectReport)">
            <app-workflow wf="project-report-approval" [id]="projectReport.id" [projectId]="project.id"
              [redraw]="projectReport.status" (actionResponse)="projectReportChanged($event)"></app-workflow>
          </ng-container>
        </div>

      </div>

    </ng-container>

    <!-- /sites-list -->
    <ng-container *ngIf="!showConsolidatedReport">
      <section class="site-section" *ngFor="let site of sitesList">
        <div class="site-title">Site {{site.name}}</div>
        <div class="status-container">

          <ng-container *ngIf="dailyReports | filter: 'site_id': site.id as drs">
            <div *ngIf="!drs.length" class="status-card">
              <div class="mat-body">
                Awaiting creation
                <a mat-button color="primary" [disabled]="!('create' | able: site.newDR)"
                  [routerLink]="['../../daily-report/create']"
                  [queryParams]="{siteId: site.id, date: reportDate.format('YYYY-MM-DD')}">Create Report</a>
              </div>
            </div>

            <div *ngFor="let dr of drs" class="status-card" [routerLink]="['../../', 'daily-report', dr.id]"
              [class.active]="dr.status == 'approved'" [class.error]="dr.status == 'rejected'">

              <div class="mat-subheading-2">
                {{dr.createdBy?.name}}
              </div>
              <div class="mat-caption">
                Received at {{dr.created_at | date: 'shortTime'}}
              </div>
              <div class="mat-body" [ngSwitch]="dr.status">
                <ng-container *ngSwitchCase="'todo'">Awaiting creation</ng-container>
                <ng-container *ngSwitchCase="'draft'">Awaiting submission</ng-container>
                <ng-container *ngSwitchCase="'submitted'">Awaiting approval</ng-container>
                <ng-container *ngSwitchCase="'reviewed'">Awaiting approval</ng-container>
                <ng-container *ngSwitchCase="'rejected'">Rejected</ng-container>
                <ng-container *ngSwitchCase="'approved'">Approved</ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </section>
    </ng-container>
    <!-- /sites-list -->
  </div>
  <!-- /Tab Content -->
  <!-- /Report view -->
</div>


<ng-template #projectReportStatus>
  <div *ngIf="!projectReport?.lastApprovalAction && projectReport?.status == 'draft'">
    Draft by {{projectReport.createdBy?.name}}
    <span class="mat-caption">on {{projectReport.created_at | dateFormat: 'very-long'}}</span>
  </div>

  <div *ngIf="projectReport?.lastApprovalAction?.id">
    {{projectReport.lastApprovalAction?.state?.comment}} by {{projectReport.lastApprovalAction?.actor?.name}}
    <span class="mat-caption">on {{projectReport.lastApprovalAction?.created_at | dateFormat: 'very-long'}}</span>
  </div>
</ng-template>

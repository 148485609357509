import { Component, Input, OnInit } from '@angular/core';
import { ReportLabor } from 'src/app/shared/models';

@Component({
  selector: 'app-report-labor-card',
  templateUrl: './report-labor-card.component.html',
  styleUrls: ['./report-labor-card.component.scss']
})
export class ReportLaborCardComponent implements OnInit {

  public initialized: boolean = false;

  @Input() reportLabor: ReportLabor;

  constructor() { }

  ngOnInit(): void {
    if (this.reportLabor) {
      this.initialized = true;
    }
  }

}

<div class="payapp-container">
  <!-- Actions Bar -->
  <div class="actions-bar" *ngIf="!isSearchActive" >
    <mat-form-field class="payapp-dropdown no-border">
      <mat-select [(value)]="selectedPayapp" (selectionChange)="onPayappChange($event.value)" class="panel" disableOptionCentering panelClass="payapp-list-panel">
        <mat-select-trigger>
          {{ selectedPayapp?.name }}
        </mat-select-trigger>
        <mat-option *ngFor="let p of payapps" [value]="p">
          {{ p.name }}
          <ng-container *ngIf="p.start_date && p.end_date">
            ({{ p.start_date | date: 'MMM d, y' }} - {{ p.end_date | date: 'MMM d, y' }})
          </ng-container>
        </mat-option>
      </mat-select>
      <mat-icon
      class="suffix-icon"
      svgIcon="chevron-down"
      matSuffix
      />
    </mat-form-field>
    <app-payapp-date-filter #dateFilter
      (dateChanged)="onDateChanged($event)"
    ></app-payapp-date-filter>
    <app-payapp-multi-filter #multiFilter
    (filtersChanged)="onFiltersChanged($event)"
    [projectId]="projectDetails?.id || null"
    [isContractorMode]="isContractorMode"
    />
    <mat-form-field *ngIf="selectedSortColumn" class="sort-dropdown">
      <mat-select [(value)]="selectedSortColumn" (selectionChange)="onSortColumnChange($event)" disableOptionCentering panelClass="payapp-sort-panel">
        <mat-select-trigger>
          {{ selectedSortColumn ? 'Sort by: ' + selectedSortColumn : 'Sort: ' }}
        </mat-select-trigger>
        <mat-option *ngFor="let column of displayedColumns" [value]="column">
          {{ column | titlecase }}  
        </mat-option>
      </mat-select>
      <!-- <mat-icon>sync_alt</mat-icon> -->
      <mat-icon class="sort-close" (click)="removeSort($event)">
        close
      </mat-icon>
    </mat-form-field>
    <mat-form-field *ngIf="!selectedSortColumn" class="sort-dropdown nosort">
      <mat-select [(value)]="selectedSortColumn" (selectionChange)="onSortColumnChange($event)" disableOptionCentering panelClass="payapp-sort-panel">
        <mat-option *ngFor="let column of sortByColumns" [value]="column">
          {{ column | titlecase }}  
        </mat-option>
      </mat-select>
      <div class="label text-type-8">Sort: </div>
      <mat-icon>sync_alt</mat-icon>
    </mat-form-field>
    <mat-icon class="search-icon" matPrefix>search</mat-icon>
    <input 
      type="text" 
      class="search-bar" 
      placeholder="Search" 
      [formControl]="searchControl" 
      #searchInput
      (click)="onSearchClick($event)"
    />
    <button *ngIf="!readOnly && !isContractorMode; else deleteButtonTemplate"
    mat-stroked-button [disabled]="!hasReadyItems"class="generate-button" (click)="generatePayapp()">
      Generate payapp
    </button>

    <ng-template #deleteButtonTemplate>
    <button *ngIf="!isContractorMode" mat-stroked-button class="delete-button" color="primary" (click)="confirmDeletePayapp()">
    Recall payapp
    </button>
    </ng-template>
  </div>

  <!-- Search Bar Full Width -->
  <div class="search-bar-full" *ngIf="isSearchActive" >
    <div class="search-container">
      <mat-icon matPrefix>search</mat-icon>
      <input 
        type="text" 
        class="search-bar full-width" 
        placeholder="Search by site name" 
        [formControl]="searchControl"
        #searchInputFull
      />
      <button mat-icon-button class="clear-search" (click)="clearSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="summary-hdr" *ngIf="searchControl.value">
    <span class="label">{{ this.displayedItems?.length }} results</span>
  </div>

  <div class="summary-hdr" *ngIf="!searchControl.value">
    <span class="label">Total: {{ totalAmount | currency:'USD' }} USD</span>
  </div>

  <!-- Payapp Items Table -->
  <div *ngIf="payappItems?.length > 0; else noData">
    <table mat-table [dataSource]="displayedItems" class="mat-elevation-z0 payapp-table">
      <!-- Columns -->
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef>Date
          <span *ngIf="selectedSortColumn === 'Date'">
            <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          </span>
        </th>
        <td class="date" mat-cell *matCellDef="let element">{{ element.report_date | date: 'MMM d, y' }}</td>
      </ng-container>

      <ng-container matColumnDef="Site">
        <th mat-header-cell *matHeaderCellDef>Site
          <span *ngIf="selectedSortColumn === 'Site'">
            <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="site">{{ element.site_name }}</div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Created By">
        <th mat-header-cell *matHeaderCellDef>Created by
          <span *ngIf="selectedSortColumn === 'Created By'">
            <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.created_by }}</td>
      </ng-container>

      <ng-container matColumnDef="Bid Item">
        <th mat-header-cell *matHeaderCellDef>Bid item
          <span *ngIf="selectedSortColumn === 'Bid Item'">
            <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.item }}
          <div class="item-descr">{{ element.description }} </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="Quantity">
        <th mat-header-cell *matHeaderCellDef>Quantity
          <span *ngIf="selectedSortColumn === 'Quantity'">
            <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">
          <span 
            class="quantity"
            [ngClass]="{'disagree-qty': element.contractor_status === 'disagree'}"
            (click)="openContractorStatusDialog(element.report_bid_item_id)"
            >
            <mat-icon *ngIf="element.contractor_status === 'disagree'"
            svgIcon="open-issues"
            class="disagree-icon"
            />
            {{ element.quantity }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef>Amount
          <span *ngIf="selectedSortColumn === 'Amount'">
            <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
          </span>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.quantity * element.unit_price | currency:'USD' }}</td>
      </ng-container>

      <ng-container *ngIf="selectedPayapp?.name === 'Unpaid' && !isContractorMode">
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>Status
            <span *ngIf="selectedSortColumn === 'Status'">
              <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <mat-select 
                [value]="tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id]" 
                (selectionChange)="onStatusChange(element.report_bid_item_id, $event.value)" 
                panelClass="payapp-status-panel"
                yPosition="below"
                disableOptionCentering
                [ngClass]="{
                  'ready-status': (tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id]) === 'ready',
                  'deferred-status': (tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id]) === 'deferred',
                  'rejected-status': (tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id]) === 'rejected'
                }">
                <mat-option [ngClass]="{'selected-status-option': 'ready' === (tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id])}" value="ready">Ready</mat-option>
                <mat-option [ngClass]="{'selected-status-option': 'deferred' === (tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id])}" value="deferred">Deferred</mat-option>
                <mat-option [ngClass]="{'selected-status-option': 'rejected' === (tempStatusMap[element.report_bid_item_id] || statusMap[element.report_bid_item_id])}" value="rejected">Rejected</mat-option>
              </mat-select>
            </div>
            <mat-icon
            class="suffix-icon"
            svgIcon="chevron-down"
            matSuffix
            />
            <div class="comment-text" (click)="onStatusChange(element.report_bid_item_id, statusMap[element.report_bid_item_id])" *ngIf="commentsMap[element.report_bid_item_id]">
              {{ commentsMap[element.report_bid_item_id] }}
            </div>

          </td>
        </ng-container>
      </ng-container>  

      <ng-container *ngIf="isContractorMode">
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>Status
            <span *ngIf="selectedSortColumn === 'Status'">
              <mat-icon (click)="toggleSortDirection()" class="sort-column">{{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}</mat-icon>
            </span>
          </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <mat-select 
                [value]="tempContractorStatusMap[element.report_bid_item_id] || contractorStatusMap[element.report_bid_item_id]" 
                (selectionChange)="onContractorStatusChange(element.report_bid_item_id, $event.value)" 
                panelClass="payapp-status-panel"
                yPosition="below"
                disableOptionCentering
                [ngClass]="{
                  'agree-status': (tempContractorStatusMap[element.report_bid_item_id] || contractorStatusMap[element.report_bid_item_id]) === 'agree',
                  'disagree-status': (tempContractorStatusMap[element.report_bid_item_id] || contractorStatusMap[element.report_bid_item_id]) === 'disagree'
                }">
                <mat-option [ngClass]="{'selected-status-option': 'agree' === (tempContractorStatusMap[element.report_bid_item_id] || contractorStatusMap[element.report_bid_item_id])}" value="agree">Agree</mat-option>
                <mat-option [ngClass]="{'selected-status-option': 'disagree' === (tempContractorStatusMap[element.report_bid_item_id] || contractorStatusMap[element.report_bid_item_id])}" value="disagree">Disagree</mat-option>
              </mat-select>
            </div>
            <mat-icon
            class="suffix-icon"
            svgIcon="chevron-down"
            matSuffix
            />
            <div class="comment-text" (click)="onContractorStatusChange(element.report_bid_item_id, contractorStatusMap[element.report_bid_item_id])" *ngIf="contractorCommentsMap[element.report_bid_item_id]">
              {{ contractorCommentsMap[element.report_bid_item_id] }}
            </div>

          </td>
        </ng-container>
      </ng-container>  

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <!-- No Data Template -->
  <ng-template #noData>
    <div class="no-data">No records found.</div>
  </ng-template>

  <div class="paginator-wrapper">
    <app-paginator *ngIf="!searchControl.value"
      (pageChanged)="pageChanged($event)"
      [itemsPerPage]="pagination.pageSize"
      [totalItems]="pagination.length"
      class="paginator"
    >
  </app-paginator>
  </div>
</div>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from 'src/app/shared/services';
import { Project } from 'src/app/shared/models'
import { MaterialTestLab } from './material-test-lab';

@Injectable({ providedIn: 'root' })
export class MaterialTestLabService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns list of Records
   * @param pagination pagination
   * @param qp query params
   */
  getRecords(project: Project, pagination, qp: any = {}): Promise<any> {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    qp.projectId = project.id;
    const path = `/v1/material-test-lab`;
    return this.appHttpService.getService(path, qp).toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new MaterialTestLab(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates or updates MaterialTestlab
   * @param lab MaterialTestLab
   * @param payload custom object
   * @param qp query-params
   * @returns Promise<MaterialTestLab>
   */
  save(lab: MaterialTestLab, payload: any = null, qp: any = {}): Promise<MaterialTestLab> {

    const body = payload || lab.toPayload();
    const req = body.id
      ? this.appHttpService.putService(`/v1/material-test-lab/${body.id}`, body, qp)
      : this.appHttpService.postService(`/v1/material-test-lab/${body.project_id}`, body, qp);

    return req.toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new MaterialTestLab(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { projectDetails } from '../../../../../../core/projects.selectors';
import { Project } from '../../../../../../../../shared/models';

@Component({
  selector: 'app-each-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit, OnDestroy{
  private readonly onDestroy: Subject<any> = new Subject<any>();
  projectDetails: any;

  constructor(
    private store: Store<fromRoot.State>,
  ) {
  }

  ngOnInit() {
    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (Object.keys(data).length !== 0) {
          this.projectDetails = new Project(data);
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

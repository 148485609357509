<ng-container *ngIf="initialized">
  <!-- Project card -->
  <app-project-card [project]="project" [showProgress]="false" picHeight="144px">
  </app-project-card>
  <!-- Project card -->

  <mat-tab-group [selectedIndex]="0">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="row" hoverable>
          <span class="material-symbols-outlined" title="Sites">map</span>
          <span hover-toggle>Sites</span>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-project-sites [project]="project"></app-project-sites>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Members">
          group
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-accounts [project]="project"></app-project-accounts>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Invites">
          person_add
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-invites [project]="project"></app-project-invites>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Material tests">
          science
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-material-tests [project]="project"></app-project-material-tests>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Bid items">
          work
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-bid-items [project]="project"></app-project-bid-items>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Drawings">
          architecture
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-drawings [project]="project"></app-project-drawings>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="External labs">
          diagnosis
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-labs [project]="project"></app-project-labs>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Sub contractors">
          diversity_3
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-sub-contractors [project]="project"></app-project-sub-contractors>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Materials">
          package_2
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-materials [project]="project"></app-materials>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Internal Tests">
          labs
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-internal-tests [project]="project"></app-internal-tests>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Delivery tracking fields">
          settings_timelapse
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-large">
          <app-fields
            recordRange="true"
            entityType="project"
            context="delivery_tracking_fields"
            [entity]="project"></app-fields>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Project tags">
          sell
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-large">
          <app-project-lookups
            [project]="project"></app-project-lookups>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined" title="Settings">
          settings
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-large">
          <app-project-settings [project]="project"></app-project-settings>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-container>
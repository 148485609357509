<div class="wrapper">
  <div class="heading">
    Delivery of {{name}}
  </div>
  <div class="content">
    <section class="section usage-section">
      <app-usage-bar
        class="usage-bar usage-today"
        heading="TODAY"
        [percent]="usedToday.percent"
        [label]="usedToday.label"
      />
      <app-usage-bar
        class="usage-bar usage-all-time"
        *ngIf="data.material"
        heading="ALL TIME"
        [percent]="totalUsed.percent"
        [label]="totalUsed.label"
      />
    </section>
    <section
      *ngIf="data.bid_item"
      class="section details-section"
    >
      <div class="details-block">
        <div class="section-heading">
          BID ITEM
        </div>
        <div class="section-content">
          {{ data.bid_item?.item }} - {{data.bid_item?.description}}
        </div>
      </div>
    </section>
    <section
      *ngIf="data.comment"
      class="section attachments-section"
    >
      <div class="section-heading">
        DESCRIPTION
      </div>
      <div class="section-content">
        {{ data.comment }}
      </div>
    </section>
    <section class="section values-section">
      <app-view-field
        class="value-field"
        [label]="data.received !== null ? 'RECEIVED' : 'USED'"
        [value]="data.received !== null ? data.received : data.used"
        hint=""
        [uom]="data.material?.uom"
      />
      <app-view-field
        *ngFor="let field of fields"
        class="value-field"
        [label]="field.label"
        [value]="field.value"
        [hint]="field.hint"
        [uom]="field.uom"
      />
    </section>
    <section
      *ngIf="data.pictures?.length"
      class="section attachments-section"
    >
      <app-attachments-block
        [pictures]="data.pictures"
      />
    </section>
  </div>
</div>

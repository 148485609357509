import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services';
import { Ability, subject } from '@casl/ability';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private ability: Ability,
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const url: string = state.url;
      if (this.checkLogin(url) === false) {
        return false;
      }

      if (route.data.s && route.data.a) {
        let model: any = null;

        // consider id from url for update, delete...
        if (route.data.r && route.data.r instanceof RegExp) {
          const matches = url.match(route.data.r);
          if (matches) {
            model = { id: matches[1] };
          }
        }

        const bl = this.ability.can(route.data.a, model ? subject(route.data.s, model) : route.data.s);
        if (!bl) {
          this.router.navigate(['/page-403'], { queryParams: { url } });
        }
        return bl;
      }

      return true;
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(next, state);
  }

  checkLogin(url: string): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(['/login']);
    return false;
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards';

import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: 'project/:projectId',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { s: 'Issue', a: 'read' },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class IssueRoutingModule {
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { JobCode, Project } from 'src/app/shared/models';
import { JobCodeService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-job-codes',
  templateUrl: './job-codes.component.html',
  styleUrls: ['./job-codes.component.scss']
})
export class JobCodesComponent implements OnInit {

  public loading: number = 0;
  public initialized: boolean = false;

  @Input() public project: Project;
  public jobCodes: JobCode[] = [];
  public projectJobCodes: JobCode[] = [];

  constructor(
    private snackBar: MatSnackBar,
    private jobCodeSrv: JobCodeService,
  ) { }

  ngOnInit(): void {
    this.getOrgJobCodes();
  }

  getOrgJobCodes(): void {
    this.loading++;
    this.jobCodeSrv.getRecords(
      this.project?.owner_organization,
      { pageIndex: 0, pageSize: 10000 },
      { include: [] },
    ).then((resp: any) => {
      const { result } = resp;
      this.jobCodes = result;
      return this.getProjectJobCodes();
    }).finally(() => {
      this.initialized = true;
      this.loading--;
    });
  }

  getProjectJobCodes() {
    const qp = { include: ['project_id', 'organization_id'] };
    return this.jobCodeSrv.getProjectRecords(this.project, qp)
      .then((jobCodes: JobCode[]) => {
        this.projectJobCodes = jobCodes.filter(o => o.project_id);
        // mark as checked/unchecked
        this.jobCodes.map((o: any) => o.checked = !!jobCodes.find((p) => p.id === o.id));
      });
  }

  /**
   * OnChange event of checkbox
   * @param jobCode JobCode
   * @param checked boolean
   */
  onCheckToggle(jobCode: JobCode, checked) {
    const req = checked
      ? this.jobCodeSrv.addToProject(jobCode, this.project)
      : this.jobCodeSrv.removeFromProject(jobCode, this.project);

    req.then((msg: string) => {
      this.snackBar.open(msg, '', { duration: 5000 });
    })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong',
          '',
          { duration: 5000 },
        );
      });
  }

}

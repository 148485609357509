<div class="wrapper flex justify-between items-center">
  <div class="date-picker-row flex justify-start items-center gap-[30px]">
    <div
      (click)="selectDate(date)"
      *ngFor="let date of datesList, let i = index"
      [class.selected]="moment(date).isSame(moment(form.controls.date.value), 'day')"
      class="each-date text-type-10"
    >
      {{
        i === 0 ?
          (moment(date).isSame(moment(), 'day') ?
            'Today, ' + (date | date: 'MMM dd') :
            (date | date: 'MMM dd')) :
          (moment(date, 'YYYY-MM-DD').isSame(moment(), 'day') ?
            'Today, ' + (date | date: 'MMM dd') :
            moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment(date, 'YYYY-MM-DD').startOf('month').format('YYYY-MM-DD') ||
            moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') === moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD') ?
              (date | date: 'MMM dd') :
              (date | date: 'dd'))
      }}
    </div>
  </div>
  <div class="picker-icon">
    <mat-icon
      (click)="picker.open()"
      class="filter-icon ml-[45px]"
      svgIcon="calendar"
    />
    <mat-form-field
      [formGroup]="form"
      class="date-picker"
    >
      <mat-label>Choose a date</mat-label>
      <input
        [matDatepicker]="picker"
        formControlName="date"
        matInput
      >
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>



import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {projectMembersFilterList, sitesFilterList} from '../../../../../core/projects.selectors';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../state/app.state';
import {filter, takeUntil} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-payapp-multi-filter',
  templateUrl: './payapp-multi-filter.component.html',
  styleUrls: ['../styles.scss', './payapp-multi-filter.component.scss'],
})
export class PayappMultiFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: string;
  @Input() isContractorMode: boolean;
  @Output() filtersChanged = new EventEmitter<{ status: string[]; contractorStatus: string[]; site: string[]; creator: string[] }>();


  isStatusAllChecked = false;
  isSitesAllChecked = false;
  isCreatorsAllChecked = false;
  buttonLabel = 'Filters (0)';
  isOpen = false;
  sitesOptions = [];
  filteredSitesOptions = [];

  creatorOptions = [];
  filteredCreatorOptions = [];

  statusList = [];
  contractorStatusList = [];
  sitesList = [];
  creatorsList = [];

  statusOptions = [
    {
      label: 'Deferred',
      value: 'deferred',
      isChecked: false,
    },
    {
      label: 'Rejected',
      value: 'rejected',
      isChecked: false,
    },
    {
      label: 'Ready',
      value: 'ready',
      isChecked: false,
    }
  ];

  contractorStatusOptions = [
    {
      label: 'Agree',
      value: 'agree',
      isChecked: false,
    },
    {
      label: 'Disagree',
      value: 'disagree',
      isChecked: false,
    }
  ];


  searchForm = new FormGroup({
    statusSearch: new FormControl(''),
    contractorStatusSearch: new FormControl(''),
    sitesSearch: new FormControl(''),
    creatorsSearch: new FormControl(''),
  });


  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {

    this.searchForm.controls.sitesSearch.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(text => {
        if (text) {
          this.filteredSitesOptions =
            this.sitesOptions.filter(o => o.label?.toLowerCase()?.includes(text?.toLowerCase()));
        } else {
          this.filteredSitesOptions = this.sitesOptions;
        }
      });

    this.searchForm.controls.creatorsSearch.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(text => {
        if (text) {
          this.filteredCreatorOptions =
            this.creatorOptions.filter(o => o.label?.toLowerCase()?.includes(text?.toLowerCase()));
        } else {
          this.filteredCreatorOptions = this.creatorOptions;
        }
      });

    this.store.select(projectMembersFilterList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(list => {
        this.creatorOptions = JSON.parse(JSON.stringify(list));
        this.creatorOptions.forEach(o => {
          o.isChecked = this.creatorsList.includes(o.value);
        });
        this.filteredCreatorOptions = this.creatorOptions;
      })
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectId) {
      this.store.select(sitesFilterList, {projectId: this.projectId})
        .pipe(filter(o => !!o))
        .subscribe(list => {
          this.sitesOptions = JSON.parse(JSON.stringify(list));
          this.sitesOptions.forEach(o => {
            o.isChecked = this.sitesList.includes(o.value);
          });

          this.filteredSitesOptions = this.sitesOptions;
        }).unsubscribe();
    }
  }


  onStatusCheckboxChange(selectedOptions: string[]): void {
    this.statusList = (selectedOptions.length === this.statusOptions.length) ? [] : selectedOptions;
    this.emitFiltersChanged();
  }

  onContractorStatusCheckboxChange(selectedOptions: string[]): void {
    this.contractorStatusList = (selectedOptions.length === this.statusOptions.length) ? [] : selectedOptions;
    this.emitFiltersChanged();
  }

  onSiteCheckboxChange(selectedOptions: string[]): void {
    this.sitesList = (selectedOptions.length === this.sitesOptions.length) ? [] : selectedOptions;
    this.emitFiltersChanged();
  }

  onCreatorCheckboxChange(selectedOptions: string[]): void {
    this.creatorsList = (selectedOptions.length === this.creatorOptions.length) ? [] : selectedOptions;
    this.emitFiltersChanged();
  }

  resetFilters(): void {
    // Reset status options
    this.statusOptions.forEach(option => option.isChecked = false);

    // Reset contractor status options
    this.contractorStatusOptions.forEach(option => option.isChecked = false);

    // Reset site options
    this.sitesOptions.forEach(option => option.isChecked = false);

    // Reset creator options
    this.creatorOptions.forEach(option => option.isChecked = false);

    this.statusList = [];
    this.sitesList = [];
    this.creatorsList = [];
    this.emitFiltersChanged(); 
  }

  private emitFiltersChanged(): void {
    this.generateLabel();
    this.filtersChanged.emit({
      status: this.statusList,
      contractorStatus: this.contractorStatusList,
      site: this.sitesList,
      creator: this.creatorsList
    });
  }

  generateLabel() {
    const statusCount: any = this.statusList?.length ? 1 : 0;
    const contractorStatusCount: any = this.contractorStatusList?.length ? 1 : 0;
    const sitesCount: any = this.sitesList?.length ? 1 : 0;
    const creatorsCount: any = this.creatorsList?.length ? 1 : 0;

    const totalFilters = statusCount + contractorStatusCount + sitesCount + creatorsCount;

    this.buttonLabel = `Filter${totalFilters !== 1 ? 's' : ''} (${totalFilters})`;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<div class="wrapper">
  <div class="popup-row">
    <div class="title">{{ data.title }}</div>
  </div>
  <ng-container
    *ngFor="let item of data.stacks; let isLast = last; "
  >
    <div class="popup-row">
      <div
        *ngIf="item?.heading"
        class="heading"
      >
        {{ item?.heading }}
      </div>
      <div
        *ngIf="item?.subheading1"
        class="subheading-1"
      >
        {{ item?.subheading1 }}
      </div>
    </div>
    <div
      *ngIf="item?.description"
      class="popup-row"
    >
      <div class="description-section">
        <div class="label">DESCRIPTION:</div>
        <div class="description">
          {{ item?.description }}
        </div>
      </div>
    </div>

    <hr *ngIf="!isLast"/>
  </ng-container>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Page404Component } from '../page404/page404.component';
import { ProjectsPageComponent } from './pages/projects-page/screen/projects-page.component';
import { ProjectDetailsScreenComponent } from './pages/projects-details-page/screen/project-details-screen.component';
import { ProjectsLocationMapComponent } from './components/maps/projects-location-map/projects-location-map.component';
import { SitesLocationMapComponent } from './components/maps/sites-location-map/sites-location-map.component';
import { SiteDetailsScreenComponent } from './pages/site-details-page/screen/site-details-screen.component';
import {SiteDetailsMapComponent} from './components/maps/site-details-map/site-details-map.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: ProjectsLocationMapComponent,
        outlet: 'map',
      },
      {
        path: '',
        component: ProjectsPageComponent,
        outlet: 'content',
      },
    ],
  },
  {
    path: ':projectId',
    children: [
      {
        path: '',
        component: SitesLocationMapComponent,
        outlet: 'map',
      },
      {
        path: '',
        component: ProjectDetailsScreenComponent,
        outlet: 'content',
      },
    ],
  },
  {
    path: ':projectId/sites/:siteId',
    children: [
      {
        path: '',
        component: SiteDetailsMapComponent,
        outlet: 'map',
      },
      {
        path: '',
        component: SiteDetailsScreenComponent,
        outlet: 'content',
      },
    ],
  },
  {
    path: '**',
    children: [
      {
        path: '',
        component: Page404Component,
        outlet: 'content',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectsRoutingModule {
}

import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Drawing } from 'src/app/shared/models';
import { AppErrorStateMatcher, DrawingService } from 'src/app/shared/services';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { environment as ENV } from 'src/environments/environment';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-drawing-create',
  templateUrl: './drawing-create.component.html',
  styleUrls: ['./drawing-create.component.scss']
})
export class DrawingCreateComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  public drawing: Drawing = new Drawing();
  public drawingTags: Set<string>;
  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    tags: [''],
  });

  public clsDrawing: any = Drawing;
  public drawingUpdateUrl: string;

  constructor(
    private drawingSrv: DrawingService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DrawingCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data instanceof Drawing) {
      this.drawing = data;
    }
  }

  ngOnInit(): void {
    this.initialized = true;
    this.toReactiveForm(this.drawing, this.inputForm);
    this.drawingUpdateUrl = `${ENV.API_ENDPOINT}/v1/drawing/${this.drawing.id}`;
  }

  /**
   * Close dialog
   * @param resp any
   */
  onClose(): void {
    this.dialogRef.close(this.drawing);

  }

  toReactiveForm(model: Drawing, form: UntypedFormGroup): void {
    form.controls.tags.setValue(model.tags);
    this.drawingTags = new Set(model.tags || []);
  }

  /**
   * Form submit
   */
  onSubmit() {

    if (!this.inputForm.valid) {
      return;
    }

    const payload = Object.assign(this.drawing.toPayload(), {
      tags: this.inputForm.value.tags,
    });

    this.loading++;
    this.drawingSrv.update(this.drawing, payload)
      .then((drawing: Drawing) => {
        this.drawing = drawing;
        this.toReactiveForm(this.drawing, this.inputForm);
        this.snackBar.open(`Updated drawing`, '', { duration: 5000 });
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  addTagFromInput(event: MatChipInputEvent) {
    if (event.value) {
      this.drawingTags.add(event.value);
      this.inputForm.controls.tags.setValue([...this.drawingTags]);
      event.input.value = null;

    }
  }

  removeTag(tag: string) {
    this.drawingTags.delete(tag);
    this.inputForm.controls.tags.setValue([...this.drawingTags]);
  }

  /**
   * Callback on upload failure
   * @param resp Response
   */
  onUploadError(resp: HttpErrorResponse) {
    if (resp.error.status == 422) {
      // Todo create custom component to display multiple errors at once
      const errors = Object.values(resp.error.error);
      if (errors.length) {
        const msgs = errors.map((o: any) => {
          return `${o.location}.${o.param} ${o.msg}`;
        });
        this.snackBar.open(msgs.join(', '), 'Close');
      } else {
        this.snackBar.open('Upload failed, please check the logs', 'Close',);
      }
      return;
    }
    if (resp.error.error) {
      // Todo Show some permanent visible error after design
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
      return;
    }

    this.snackBar.open(resp.message || 'Upload failed, please check the logs', 'Close');
  }

  /**
   * Callback on upload success
   * @param attachment Attachment
  */
  onUploadComplete(resp: any) {
    if (resp instanceof Drawing) {
      this.drawing = resp;
      this.toReactiveForm(this.drawing, this.inputForm);
      this.snackBar.open(`Updated drawing`, '', { duration: 5000 });
    }
  }
}

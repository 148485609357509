import { BaseModel } from './base-model';
import { Account} from './account';
import { Project } from './project';
import { BidItem } from './bid-item';
import { Field } from './field';

export class InternalTest extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'InternalTest'; }

  /* tslint:disable */
  name: string;
  record_by_station: boolean;
  issue_type: string;
  status: string
  createdBy: Account;
  updatedBy: Account;
  project: Project;
  project_id: string;
  bid_items: BidItem[];
  fields: Field[];
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      project: Project,
      bid_items: BidItem,
      fields: Field,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.project_id = this.project?.id || this.project_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      name: this.name,
      record_by_station: this.record_by_station || false,
    };
  }
}

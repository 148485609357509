<div class="init-loader" *ngIf="!initialized && loading">
  <mat-spinner class="spinner" *ngIf="loading" color="accent" mode="indeterminate"></mat-spinner>
</div>
<div class="component-loader-bar" *ngIf="initialized && loading">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>

<div *ngIf="initialized">
  <div class="rows" *ngIf="isSingleReport && dailyReport">

    <!-- Daily Report status -->
    <ng-container *ngTemplateOutlet="dailyReportLastStatus"></ng-container>
    <!-- /Daily Report status -->

    <div class="row">
      <mat-form-field>
        <mat-label>
          <ng-container *ngIf="dailyReport.work_condition_code > 999">Non workday</ng-container>
          <ng-container *ngIf="!(dailyReport.work_condition_code > 999)">Workday</ng-container>
        </mat-label>
        <mat-select [compareWith]="softCompare" [disabled]="!editable" [(value)]="dailyReport.work_condition_code">
          <mat-option value="999">Workday</mat-option>
          <mat-optgroup label="Non-workday">
            <mat-option *ngFor="let wc of workConditions" [value]="wc.code">
              {{wc.text}}
            </mat-option>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div class="icon">
        <button mat-stroked-button
                color="primary"
                (click)="saveDailyReport()"
                *ngIf="editable">
          <ng-container *ngIf="dailyReport.id">Save</ng-container>
          <ng-container *ngIf="!dailyReport.id">Create</ng-container>
        </button>
      </div>
    </div>
    <div *ngIf="dailyReport.work_condition_code == 1004 && editable">
      <mat-form-field>
        <mat-label>Add a short note</mat-label>
        <textarea matInput [(ngModel)]="dailyReport.work_condition_comment"></textarea>
      </mat-form-field>
    </div>

  </div>

  <mat-accordion class="consolidated-report" multi>

    <!-- Weather -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <app-icon *ngIf="summary.weather" icon="weather/{{summary.weather.getIcon}}.png"></app-icon>
        <app-icon *ngIf="!summary.weather" icon="weather/116.png"></app-icon>
        <div>
          <div class="mat-subheading-2">Weather</div>
          <div class="mat-body">
            <ng-container *ngIf="summary.weather">
              {{summary?.weather?.avgtemp}}°F {{summary.weather?.condition?.text}}
            </ng-container>
          </div>
        </div>
      </mat-expansion-panel-header>
      <!-- weather content -->
      <ng-container class="rows">
        <ng-container *ngFor="let site of sites">
          <ng-container *ngIf="weathers | filter: 'site_id': site.id as l">
              <ng-container *ngFor="let weather of l">
              <!-- Weather not available -->
              <div class="mat-body-strong" *ngIf="weather.isEmpty">
                Weather not added
                <button mat-mini-fab title="Add weather" (click)="openReportWeather(weather)"
                  [disabled]="!weather.editable">
                  <mat-icon>add</mat-icon>
                </button>
              </div>
              <!-- Weather not available -->

              <div class="cards-container" *ngIf="!weather.isEmpty" >
                  <div class="line-items">
                    <div  class="mat-subheading-1">
                      SITE {{site.name}}
                    </div>
                    <app-report-weather-card class="card" [weather]="weather"
                    (click)="openReportWeather(weather)">
                  </app-report-weather-card>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
      <!-- /weather content -->
    </mat-expansion-panel>
    <!-- /Weather -->

    <!-- bid items -->
    <mat-expansion-panel [disabled]="!reportBidItems.length">
      <mat-expansion-panel-header>
        <div class="expansion-header">
          <div class="expansion-header-left-panel">
            <app-icon icon="bid-item.png"></app-icon>
            <div class="panel-header-body">
              <div class="mat-subheading-2">Bid items</div>
              <div class="mat-body"
                   *ngIf="reportBidItems.length">
                {{ rbiCaption?.biditems }} {rbiCaption?.biditems, plural, =1 {item} other {items}}
              </div>
            </div>
          </div>
          <div class="expansion-header-right-panel">
            <div class="panel-header-actions"
                 *ngIf="editable && dailyReport?.id">
              <button mat-icon-button
                      (click)="openReportBidItem($event, dailyReport)">
                <mat-icon class="add-icon"
                          inline="true"
                          title="Add New">add
                </mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <!-- bid items content -->
      <app-rbi-summary *ngIf="reportBidItems.length"
        [project]="project"
        [rbis]="reportBidItems"
        [drs]="dailyReports"
        [projectConsolidatedReport]="showConsolidatedReport"
        (summaryCaption)="updateValue($event, 'rbiCaption')"
        (changed)="onRbiChange($event)">
      </app-rbi-summary>
      <!-- /bid items content -->
    </mat-expansion-panel>
    <!-- /bid items -->

    <!-- material deliveries -->
    <mat-expansion-panel [disabled]="!rmds?.length">
      <mat-expansion-panel-header>
        <div class="expansion-header">
          <div class="expansion-header-left-panel">
            <app-icon icon="delivery.png"></app-icon>
            <div class="panel-header-body">
              <div class="mat-subheading-2">Material deliveries</div>
              <div class="mat-body"
                   *ngIf="rmdCaption && rmds?.length">
                <ng-container *ngIf="rmdCaption.delivery">{{ rmdCaption.delivery }}
                  delivery
                </ng-container>
                <ng-container *ngIf="rmdCaption.delivery && rmdCaption.usage">
                  &amp;
                </ng-container>
                <ng-container *ngIf="rmdCaption.usage">{{ rmdCaption.usage }}
                  usage
                </ng-container>
                {rmdCaption.total, plural, =0 {None} =1 {item} other {items}}
              </div>
            </div>
          </div>
          <div class="expansion-header-right-panel">
            <div class="panel-header-actions"
                 *ngIf="editable && dailyReport?.id">
              <button mat-icon-button
                      [matMenuTriggerFor]="menuMaterialDeliveries"
                      (click)="$event.stopPropagation()">
                <mat-icon class="add-icon"
                          inline="true"
                          title="Add New">add
                </mat-icon>
              </button>
              <mat-menu #menuMaterialDeliveries="matMenu">
                <button mat-menu-item
                        (click)="openRMD($event, 'delivery')">Add delivery
                </button>
                <button mat-menu-item
                        (click)="openRMD($event, 'usage')">Add usage
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <!-- material deliveries content -->
      <app-rmd-summary *ngIf="rmds?.length"
        (summaryCaption)="updateValue($event, 'rmdCaption')"
        (changed)="onRmdChange($event)"
        [drs]="dailyReports"
        [project]="project"
        [rmds]="rmds">
      </app-rmd-summary>
      <!-- /material deliveries content -->
    </mat-expansion-panel>
    <!-- /material deliveries -->

    <!-- material-tests -->
    <mat-expansion-panel [disabled]="!smts.length && !rits?.length">
      <mat-expansion-panel-header>
        <div class="expansion-header">
          <div class="expansion-header-left-panel">
            <app-icon icon="lab.png"></app-icon>
            <div class="panel-header-body">
              <div class="mat-subheading-2">Material Tests</div>
              <div class="mat-body">
                <ng-container *ngIf="smts.length">
                  <ng-container *ngIf="smts | filter: 'status': ['passed', 'failed', 'cancelled']: 'not in' as l">
                    <ng-container *ngIf="l.length">{{ l.length }} open
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="smts | filter: 'status': ['passed', 'failed', 'cancelled']: 'in' as l">
                    <ng-container *ngIf="l.length">{{ l.length }} closed
                    </ng-container>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="internalTests?.length">
                  {{ internalTests.length }} onsite
                </ng-container>
              </div>
            </div>
          </div>
          <div class="expansion-header-right-panel">
            <div class="panel-header-actions"
                 *ngIf="editable && dailyReport?.id">
              <button mat-icon-button
                      [matMenuTriggerFor]="menuMaterialTests"
                      (click)="$event.stopPropagation()">
                <mat-icon class="add-icon"
                          inline="true"
                          title="Add New">add
                </mat-icon>
              </button>
              <mat-menu #menuMaterialTests="matMenu">
                <button mat-menu-item
                        (click)="addLineItem($event, 'SiteMaterialTest')">Add
                  offsite test
                </button>
                <button mat-menu-item
                        (click)="openRIT($event)">Add onsite test
                </button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <mat-tab-group>
        <mat-tab label="Offsite">
          <ng-container matTabContent *ngTemplateOutlet="smtContent"></ng-container>
        </mat-tab>
        <mat-tab label="Onsite">
          <div class="mat-subheading-1 tab-content" *ngIf="!rits.length">
            No onsite tests are added
          </div>
          <app-rit-summary *ngIf="rits.length"
            (changed)="onRitChange($event)"
            [project]="project"
            [drs]="dailyReports"
            [rits]="rits"></app-rit-summary>
        </mat-tab>
      </mat-tab-group>
    </mat-expansion-panel>
    <!-- / material-tests -->

    <!-- issues -->
    <mat-expansion-panel [disabled]="!reportIssues.length">
      <mat-expansion-panel-header>
        <div class="expansion-header">
          <div class="expansion-header-left-panel">
            <app-icon icon="issue.png"></app-icon>
            <div class="panel-header-body">
              <div class="mat-subheading-2">Issues</div>
              <div class="mat-body" *ngIf="reportIssues.length">
                {{reportIssues.length}} {reportIssues.length, plural, =1 {issue} other {issues}}
              </div>
            </div>
          </div>
          <div class="expansion-header-right-panel">
            <div class="panel-header-actions" *ngIf="editable && dailyReport?.id">
              <button mat-icon-button (click)="addLineItem($event, 'Issue')">
                <mat-icon class="add-icon" inline="true" title="Add New">add</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <ng-container *ngTemplateOutlet="issuesContent"></ng-container>
    </mat-expansion-panel>
    <!-- /issues -->

    <!-- labor equipment -->
    <mat-expansion-panel [disabled]="!reportLabors.length && !reportEquipments.length">
      <mat-expansion-panel-header>
        <div class="expansion-header">
          <div class="expansion-header-left-panel">
            <app-icon icon="labor.png"></app-icon>
            <div class="panel-header-body">
              <div class="mat-subheading-2">Labor &amp; Equipment</div>
              <div class="mat-body" *ngIf="reportLabors.length">
                Labor: {{reportLabors.length}}
                Equipment: {{reportEquipments.length}}
              </div>
            </div>
          </div>
          <div class="expansion-header-right-panel">
            <div class="panel-header-actions" *ngIf="editable && dailyReport?.id">
              <button mat-icon-button [matMenuTriggerFor]="menuLaborEquipmentAdd">
                <mat-icon class="add-icon" inline="true" title="Add New">add</mat-icon>
              </button>
              <mat-menu #menuLaborEquipmentAdd="matMenu">
                <button mat-menu-item (click)="addLineItem($event, 'ReportLabor')">Add Labor</button>
                <button mat-menu-item (click)="addLineItem($event, 'ReportEquipment')">Add Equipment</button>
              </mat-menu>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>

      <ng-container *ngTemplateOutlet="laborContent"></ng-container>

    </mat-expansion-panel>
    <!-- /labor equipment -->

    <!-- activities -->
    <mat-expansion-panel [disabled]="!reportActivities.length">
      <mat-expansion-panel-header (click)="openReportActivity($event)">
        <app-icon icon="activity.png"></app-icon>
        <div class="panel-header-body">
          <div class="mat-subheading-2">Activities</div>
          <div class="mat-body">
            <ng-container *ngIf="reportActivities.length">
              {{reportActivities.length}} {reportActivities.length, plural, =1 {item} other {items}}
            </ng-container>
            <ng-container *ngIf="!reportActivities.length">None</ng-container>

          </div>
        </div>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <!-- /activities -->

  </mat-accordion>
</div>

<!-- issues content -->
<ng-template #issuesContent>
  <div class="rows">

    <ng-container *ngIf="isSingleReport">
      <ng-container *ngFor="let site of sites">
        <ng-container *ngIf="reportIssues | filter: 'entity_type': 'site' | filter: 'entity_id': site.id as issues">
          <app-report-issues
            *ngIf="issues.length"
            (bubbleClick)="openIssue($event)"
            [issues]="issues"
            [date]="reportDate" [project]="project"
            [site]="site">
          </app-report-issues>
        </ng-container>
      </ng-container>
    </ng-container>

    <!-- Site issues -->
    <mat-accordion *ngIf="!isSingleReport">
      <div *ngFor="let site of sites">
        <ng-container *ngIf="reportIssues | filter: 'entity_type': 'site' | filter: 'entity_id': site.id as issues">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              SITE {{site.name}} ({{issues.length}} issues)
            </mat-expansion-panel-header>
            <app-report-issues (bubbleClick)="openIssue($event)" [issues]="issues" [date]="reportDate"
              [project]="project" [site]="site">
            </app-report-issues>
          </mat-expansion-panel>
        </ng-container>
      </div>
    </mat-accordion>
    <!-- /Site issues -->

  </div>
</ng-template>
<!-- / issues content -->

<!-- smt content -->
<ng-template #smtContent>
  <div class="rows">
    <!-- Open tests -->
    <div>
      <ng-container *ngIf="smts | filter: 'status': ['passed', 'failed', 'cancelled']: 'not in' as l">
        <div class="section-title" *ngIf="l.length">Open</div>
        <div class="cards-container">
          <div class="card link" *ngFor="let smt of l">
            <app-site-material-test-short (click)="openSmt(smt)" [smt]="smt">
            </app-site-material-test-short>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- /Open tests -->

    <!-- Closed tests -->
    <div>
      <ng-container *ngIf="smts | filter: 'status': ['passed', 'failed', 'cancelled']: 'in' as l">
        <div class="section-title" *ngIf="l.length">Closed</div>
        <div class="cards-container">
          <div class="card link" *ngFor="let smt of l">
            <app-site-material-test-short (click)="openSmt(smt)" [smt]="smt">
            </app-site-material-test-short>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- /Closed tests -->
  </div>
</ng-template>
<!-- / smt content -->

<!-- labor equipment content -->
<ng-template #laborContent>
  <div class="rows">
    <div *ngFor="let site of sites">
      <div *ngIf="!isSingleReport" class="mat-subheading-1">SITE {{site.name}}</div>

      <div class="cards-container">
        <div class="card">
          <div class="rows">
            <section>
              <!-- Labor list -->
              <div class="section-title">Labor:</div>
              <ng-container *ngIf="reportLabors | filter: 'site_id': site.id as lineItems">
                <div *ngIf="!lineItems.length">No records</div>
                <div *ngFor="let li of lineItems" class="ruler-card">
                  <app-report-labor-card class="link" (click)="openReportLabor(li)" [reportLabor]="li">
                  </app-report-labor-card>
                </div>
              </ng-container>
              <!-- /Labor list -->
            </section>

            <section>
              <!-- Equipment list -->
              <div class="section-title">Equipment:</div>
              <ng-container *ngIf="reportEquipments | filter: 'site_id': site.id as lineItems">
                <div *ngIf="!lineItems.length">No records</div>
                <div *ngFor="let li of lineItems" class="ruler-card">
                  <app-report-equipment-card class="link" (click)="openReportEquipment(li)" [reportEquipment]="li">
                  </app-report-equipment-card>
                </div>
              </ng-container>
              <!-- /Equipment list -->
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- /labor equipment content -->

<ng-template #dailyReportLastStatus>

  <div *ngIf="!dailyReport.lastApprovalAction && dailyReport.status == 'draft'">
    Draft by {{dailyReport.createdBy?.name}}
    <span class="mat-caption">on {{dailyReport.created_at | dateFormat: 'very-long'}}</span>
  </div>
  <div *ngIf="dailyReport.lastApprovalAction?.id">
    {{dailyReport.lastApprovalAction?.state?.comment}} by {{dailyReport.lastApprovalAction?.actor?.name}}
    <span class="mat-caption">on {{dailyReport.lastApprovalAction?.created_at | dateFormat: 'very-long'}}</span>
  </div>

</ng-template>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-labor-details-wrapper',
  templateUrl: './labor-details-wrapper.component.html',
  styleUrls: ['./labor-details-wrapper.component.scss'],
})
export class LaborDetailsWrapperComponent implements OnInit, OnDestroy {
  @Input() data: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

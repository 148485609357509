<div *ngIf="initialized">
  <div class="header flex justify-between items-start">
    <div class="left">
      <div class="mat-display-1">Site {{dailyReport.site.name}}</div>
      <div class="mat-title">{{dailyReport.report_date | dateFormat: 'long' }}</div>
    </div>
    <div class="right" *ngIf="dailyReport.status === 'draft'">
      <mat-icon class="menu-icon" [matMenuTriggerFor]="menu">more_horiz</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item class="delete-menu" (click)="deleteReport()">
          <mat-icon class="trash-icon" svgIcon="trash"/>
          <div class="menu-label text-type-8">Delete daily report</div>
        </button>
      </mat-menu>
    </div>
  </div>



  <mat-tab-group>
    <mat-tab label="Daily report">
      <ng-template matTabContent>
        <div class="tab-content">
          <!-- Report Items -->
          <app-project-consolidated-report
            [dailyReport]="dailyReport"
            [editable]="('create' | able: dailyReport)"
            (dailyReportChange)="onDailyReportChange($event)"
          ></app-project-consolidated-report>

          <ng-container *ngIf="dailyReport.id && ('read' | able: dailyReport)">
            <app-workflow *ngFor="let workflow of dailyReport['next-actions']"
              [wf]="workflow.name"
              [actions]="workflow.actions"
              [id]="dailyReport.id"
              [projectId]="dailyReport.project.id"
              (actionResponse)="dailyReportChanged($event)"
              [redraw]="dailyReport.status"
            ></app-workflow>

          </ng-container>

          <!-- /Report Items -->
        </div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Report history">
      <ng-template matTabContent>
        <app-workflow-lineage *ngIf="workflowEx"
          [ex]="workflowEx">
        </app-workflow-lineage>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

</div>

import { BaseModel } from './base-model';

export class Field extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Field'; }

  static get types() {
    return [
      'number',
      'string',
      'textarea',
      'date',
      'time',
      'boolean',
      'json',
    ];
  }

  /* tslint:disable */
  name: string;
  label: string;
  type: string;
  uom: string;
  estimate_station_property: string;
  validation_rules: any;
  range_values: any;
  status: string;
  display_order: number;
  recent_field: Field;
  recent_field_id: string;
  recent_field_label: string;
  is_optional: boolean;

  value: any;
  /* tslint:enable */

  get relations() {
    return {
      recent_field: Field,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.recent_field_id = this.recent_field?.id || this.recent_field_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      id: this?.id || undefined,
      name: this.name,
      label: this.label,
      type: this.type,
      uom: this.uom,
      estimate_station_property: this.estimate_station_property,
      validation_rules: this.validation_rules,
      display_order: this.display_order,
      recent_field_id: this.recent_field_id,
      recent_field_label: this.recent_field_label,
      is_optional: this.is_optional || false,
    };
  }
}

<div *ngIf="initialized">

  <div class="row">
    <div></div>
    <div class="icons">
      <button mat-flat-button color="accent" (click)="openInviteCreate()"
        [disabled]="!('create' | able: newInvite)">Invite</button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!invitations.length">No invitations to show</div>
  <div *ngIf="invitations.length" class="cards-container">
    <div *ngFor="let invite of invitations" (click)="openInviteCreate(invite)" class="link stroked-card">
      <div class="line">
        <div class="mat-body-strong capitalize">{{invite.first_name}} {{invite.last_name}}</div>
        <div class="mat-caption">
          <ng-container [ngSwitch]="invite.status">
            <ng-container *ngSwitchCase="'invited'">invited on {{invite.created_at | dateFormat: 'long'}}</ng-container>
            <ng-container *ngSwitchCase="'logged_in'">logged in {{invite.accepted_at | dateFormat: 'long'}}
            </ng-container>
            <ng-container *ngSwitchCase="'accepted'">accepted on {{invite.accepted_at | dateFormat: 'long'}}
            </ng-container>
            <ng-container *ngSwitchCase="'added'">added {{invite.accepted_at | dateFormat: 'ago'}} ago</ng-container>
          </ng-container>
        </div>
      </div>
      <div class="line">
        <div class="line">
          <mat-icon color="primary">email</mat-icon>{{invite.email}}
        </div>
        <div class="line">
          <mat-icon color="primary">phone</mat-icon>{{invite.phone}}
        </div>
      </div>
      <div class="mat-caption">
        Invited to
        <ng-container *ngIf="invite.site?.id">Site {{invite.site?.name}}</ng-container>
        <ng-container *ngIf="!invite.site?.id && invite.project?.id">Project {{invite.project?.name}}</ng-container>
        <ng-container *ngIf="invite.inviter?.id"> by {{invite.inviter?.name}}</ng-container>
      </div>

    </div>
  </div>

  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && invitations.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>
</div>

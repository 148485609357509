import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { BidItem, Field, InternalTest, Project, Site } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class InternalTestService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns InternalTest with pagination
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: InternalTest[]}
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/project/${project?.id}/internal-tests`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new InternalTest(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new InternalTest
   * @param model InternalTest
   * @param project Project
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<InternalTest>}
   */
  create(model: InternalTest, project: Project = null, payload: any = null, qp: any = {}): Promise<InternalTest> {
    const body = payload || model.toPayload();

    return this.appHttpService
      .postService(`/v2/project/${project?.id}/internal-test`, body, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new InternalTest(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates InternalTest
   * @param model InternalTest
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<InternalTest>}
   */
  update(model: InternalTest, payload: any = null, qp: any = {}): Promise<InternalTest> {
    const body = payload || model.toPayload();

    return this.appHttpService
      .putService(`/v2/internal-test/${model?.id}`, body, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new InternalTest(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes InternalTest
   * @param model InternalTest
   * @returns {Promise<any>} {status, message}
   */
  delete(model: InternalTest): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/internal-test/${model?.id}`)
      .toPromise();
  }

  /**
   * Add InternalTest to biditem
   * @param model InternalTest
   * @param biditem Biditem
   * @returns {Promise<string>} message
   */
  addBidItem(model: InternalTest, biditem: BidItem): Promise<string> {
    return this.appHttpService
      .putService(`/v2/internal-test/${model?.id}/bid-item/${biditem.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  /**
   * Remove InternalTest to biditem
   * @param model InternalTest
   * @param biditem Biditem
   * @returns {Promise<string>} message
   */
  removeBidItem(model: InternalTest, biditem: BidItem): Promise<string> {
    return this.appHttpService
      .deleteService(`/v2/internal-test/${model?.id}/bid-item/${biditem.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  /**
   * Returns InternalTest
   * @param id InternalTest.id
   * @param qp query params
   * @returns {Promise<InternalTest>}
   */
  getRecord(id: string, qp: any = {}): Promise<InternalTest> {
    return this.appHttpService
      .getService(`/v2/internal-test/${id}`, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new InternalTest(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Add new field to internal test
   * @param model InternalTest
   * @param payload NewField
   * @param qp any
   * @returns {Promise<Field>}
   */
  addField(model: InternalTest, payload: any, qp: any): Promise<Field> {
    return this.appHttpService
      .postService(`/v2/internal-test/${model?.id}/field`, payload, qp)
      .toPromise()
      .then((resp: any) => new Field(resp?.data));
  }

  /**
   * Update field
   * @param payload NewField
   * @param qp any
   * @returns {Promise<Field>}
   */
  updateField(payload: any, qp: any): Promise<Field> {
    return this.appHttpService
      .putService(`/v2/field/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => new Field(resp?.data));
  }

  /**
   * Deletes field
   * @param model Field
   * @returns {Promise<any>} {status, message}
   */
  deleteField(model: Field): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/field/${model?.id}`)
      .toPromise();
  }

  /**
   * Add InternalTest to site
   * @param model InternalTest
   * @param site Site
   * @returns {Promise<string>} message
   */
  addSite(model: InternalTest, site: Site): Promise<string> {
    return this.appHttpService
      .putService(`/v2/internal-test/${model?.id}/site/${site.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  /**
   * Remove InternalTest from site
   * @param model InternalTest
   * @param site Site
   * @returns {Promise<string>} message
   */
  removeSite(model: InternalTest, site: Site): Promise<string> {
    return this.appHttpService
      .deleteService(`/v2/internal-test/${model?.id}/site/${site.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }
}

<swiper
  [slidesPerView]="1"
  [navigation]="true"
  [preloadImages]="false"
  [lazy]="true"
  [pagination]="{ clickable: true }"
  [keyboard]="true"
>
  <ng-template
    *ngFor="let slide of slidesData"
    swiperSlide
  >
    <ng-container
      *ngTemplateOutlet="slideTemplate.first; context: { $implicit: slide }"
    >
    </ng-container>
  </ng-template>
</swiper>

import { BaseModel } from './base-model';
import { Heading } from './heading';
import { Station } from './station';

export class Alignment extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Alignment'; }

  /* tslint:disable */
  name: string;
  distance_uom: string;
  last_distance: number;
  stations: Station[];
  headings: Heading[];
  station_properties: number[];
  total_stations: number;
  /* tslint:enable */

  get relations() {
    return {
      stations: Station,
      headings: Heading,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
  }

}

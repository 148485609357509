import { SubContractor } from 'src/app/modules/sub-contractor';
import { BaseModel } from './base-model';
import { JobCode } from './job-code';
import { Project } from './project';

export class Labor extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Labor'; }

  /* tslint:disable */
  first_name: string;
  last_name: string;
  default_job_code: JobCode;
  default_job_code_id: string;
  sub_contractor: SubContractor;
  sub_contractor_id: string;
  project: Project;
  project_id: string;
  /* tslint:enable */

  get relations() {
    return {
      default_job_code: JobCode,
      sub_contractor: SubContractor,
      project: Project,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.project_id = this.project?.id || this.project_id;
    this.sub_contractor_id = this.sub_contractor?.id || this.sub_contractor_id;
    this.default_job_code_id = this.default_job_code?.id || this.default_job_code_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      first_name: this.first_name,
      last_name: this.last_name,
      default_job_code_id: this.default_job_code?.id || this.default_job_code_id,
      sub_contractor_id: this.sub_contractor?.id || this.sub_contractor_id,
    };
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DailyReport } from 'src/app/shared/models';
import { Weather } from 'src/app/shared/models/weather';
import { AppService, DailyReportService } from 'src/app/shared/services';
import { WeatherService } from 'src/app/shared/services/weather.service';

@Component({
  selector: 'app-report-weather',
  templateUrl: './report-weather.component.html',
  styleUrls: ['./report-weather.component.scss']
})
export class ReportWeatherComponent implements OnInit {

  @Input() weather: Weather;
  @Input() dailyReport: DailyReport;
  private data: any; // to save original

  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;
  public dialogOptions: any = {};
  public componentResult: any = null;

  public loading: number = 0;
  public initialized: boolean = false;
  public editing: boolean = false;
  private siteWeather: Weather = new Weather();

  public weatherConditions: any[] = [];

  constructor(
    private weatherService: WeatherService,
    private appService: AppService,
    private dailyReportService: DailyReportService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportWeatherComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any
  ) { }

  ngOnInit(): void {

    if (this.isDialog) {
      const { data, dailyReport, options } = this.inputData;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = data;
      this.dailyReport = dailyReport;
    }

    this.weather = new Weather(JSON.parse(JSON.stringify(this.data)));

    this.appService.getAppdata()
      .then((appData: any) => {
        this.weatherConditions = (appData.weatherConditions || []);
      });

    if (!this.weather.avgtemp) {
      this.fetchSiteWeather(this.dailyReport.site_id, this.dailyReport.report_date.format('YYYY-MM-DD'));
    }
    this.initialized = true;
  }

  /**
   * Fetches site weather
   * @param siteId Site.id
   */
  fetchSiteWeather(siteId: string, date: string) {
    this.loading++;
    this.weatherService.getSiteWeather(siteId, date)
      .then((sw: Weather) => {
        this.siteWeather = sw;
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 500) {
          this.snackBar.open('Unable to fetch forecast, please add manually', 'Close');
        }
      })
      .finally(() => {
        this.loading--;
      })
  }

  toggleEdit() {
    this.editing = !this.editing;
    if (this.editing && !this.weather.avgtemp && this.siteWeather.avgtemp) {
      Object.assign(this.weather, this.siteWeather);
    }
  }

  /**
   * Closes component or Dialog with response|event
   * @param resp data to be sent to parent
   */
  close(resp: any = null): void {

    if (this.isDialog) {
      this.dialogRef.close(resp || this.componentResult);
    }
    // Todo: emit output event
  }

  onWeatherConditionChange(code) {
    this.weather.condition.text = (this.weatherConditions.find(o => o.code === code)).text;
  }

  /**
   * Update ReportBidItem
   */
  saveItem() {
    const payload = this.dailyReport.toPayload(true);
    payload.weather = this.weather.toPayload();

    this.loading++;
    this.dailyReportService.save(this.dailyReport, payload)
      .then((dr: DailyReport) => {
        this.weather = dr.weather;
        this.snackBar.open('Saved Weather', '', { duration: 5000 });
        this.componentResult = this.weather;
        this.editing = false;
      })
      .catch((e: any) => {
        this.snackBar.open(e.message, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.editing = false;
    this.weather = new Weather(JSON.parse(JSON.stringify(this.data)));
  }
}

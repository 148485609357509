import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(date: any, type: string = 'short', i: number = -1) {
    date = date ? moment(date) : moment();
    let str = '';
    let format = '';
    switch (type) {
      case 'first-long-short':
        format = i === 0 ? date.isSame(moment(), 'day') ? '[Today], MMMM DD' : 'dddd, MMMM DD' : 'DD';
        str = date.format(format);
        break;
      case 'short':
        str = date.format('DD');
        break;
      case 'long':
        format = date.isSame(moment(), 'day') ? '[Today], MMMM DD' : 'dddd, MMMM DD';
        str = date.format(format);
        break;
      case 'very-long':
        format = 'dddd, MMMM DD h:mm a';
        str = date.format(format);
        break;
      case 'ago':
        str = date.fromNow(true);
        break;
      case 'ago-tiny':
        moment.locale('en', {
          relativeTime: {
            future: 'in %s',
            past: '%s ago',
            s: '%ds',
            ss: '%ds',
            m: '%dm',
            mm: '%dm',
            h: '%dh',
            hh: '%dh',
            d: '%dd',
            dd: '%dd',
            M: '%dmonth',
            MM: '%dmonth',
            y: '%dy',
            yy: '%dy',
          },
        });
        str = date.fromNow(true);
        break;
      default:
        str = date.format(type);
        break;
    }
    return str;
  }
}

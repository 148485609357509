import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-offsite-tests',
  templateUrl: './offsite-tests.component.html',
  styleUrls: ['../../styles.css', './offsite-tests.component.scss'],
})
export class OffsiteTestsComponent implements OnChanges, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() data: any;

  activity: any;

  disabledStatus = {
    request: false,
    schedule: false,
    record: false,
    report: false,
    result: false,
  };

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateActivity();
    this.setDisableStatus();
  }

  updateActivity(): void {
    this.activity = {
      sent_to_lab: (this.data.activity_log || []).filter(o => o.type === 'sent_to_lab')[0] || null,
      lab_scheduled: (this.data.activity_log || []).filter(o => o.type === 'lab_scheduled')[0] || null,
      lab_activity_completed: (this.data.activity_log || []).filter(o => o.type === 'lab_activity_completed')[0] || null,
      updated_report: (this.data.activity_log || []).filter(o => o.type === 'updated_report')[0] || null,
      passed: (this.data.activity_log || []).filter(o => o.type === 'passed')[0] || null,
      failed: (this.data.activity_log || []).filter(o => o.type === 'failed')[0] || null,
      cancelled: (this.data.activity_log || []).filter(o => o.type === 'cancelled')[0] || null,
    };
  }

  setDisableStatus() {
    this.disabledStatus = {
      request: false,
      schedule: false,
      record: false,
      report: false,
      result: false,
    };

    if (!this.activity.sent_to_lab) {
      this.disabledStatus.schedule = true;
    }

    if (!this.activity.lab_scheduled) {
      this.disabledStatus.record = true;
    }

    if (!this.activity.lab_activity_completed) {
      this.disabledStatus.report = true;
    }

    if (!this.activity.updated_report) {
      this.disabledStatus.result = true;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<div
  class="wrapper"
  [class]="report?.status"
>
  <div class="name text-type-5">
    {{ report?.created_by_name }}
  </div>
  <div class="filed-on text-type-10">
    {{ moment(report?.report_date).format('MMMM DD, YYYY') }}
  </div>
  <div class="status text-type-10">
    {{
      report.status === 'submitted' || report.status === 'reviewed' ?
        'Awaiting Approval' :
        report.status_text
    }}
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { OrganizationService } from 'src/app/shared/services';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loading = 0;
  public initialized = false;
  public records = [];
  public errorMessage: string;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };

  constructor(
    private service: OrganizationService
  ) { }

  ngOnInit(): void {
    this.fetchRecords();
  }

  fetchRecords() {
    this.loading++;

    this.service.getRecords(this.pagination)
      .subscribe({
        next: (resp: any) => {
          this.initialized = true;
          this.loading--;
          this.records = resp.result;
          this.pagination.length = resp.meta.totalRecords || 0;
        },
        error: err => {
          this.initialized = true;
          this.loading--;
          this.errorMessage = err.message || 'Oops! something went wrong.';
        }
      });
  }

  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords();
  }

}

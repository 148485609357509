import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppErrorStateMatcher } from 'src/app/shared/services';
import { MaterialTestLab } from '../material-test-lab';
import { MaterialTestLabService } from '../material-test-lab.service';

@Component({
  selector: 'app-material-test-lab-create',
  templateUrl: './material-test-lab-create.component.html',
  styleUrls: ['./material-test-lab-create.component.scss']
})
export class MaterialTestLabCreateComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  public lab: MaterialTestLab = new MaterialTestLab();

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required]],
    email: ['', [Validators.required]],
    phone: ['', [Validators.required]],
  });

  constructor(
    private mltSrv: MaterialTestLabService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<MaterialTestLabCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data instanceof MaterialTestLab) {
      this.lab = data;
    }
  }

  ngOnInit(): void {
    this.toReactiveForm(this.lab, this.inputForm);
    this.initialized = true;
  }

  /**
   * Close dialog
   * @param resp any
   */
  onClose(resp): void {
    this.dialogRef.close(resp);
  }

  /**
   * Assign base model to FormGroup
   * @param project Project
   * @param form FormGroup
   */
  toReactiveForm(lab: MaterialTestLab, form: UntypedFormGroup) {
    form.controls.name.setValue(lab.name);
    form.controls.phone.setValue(lab.phone);
    form.controls.email.setValue(lab.email);
  }

  /**
   * Form submit
   */
  onSubmit() {

    if (!this.inputForm.valid) {
      return;
    }

    this.lab = Object.assign(this.lab, this.inputForm.value);

    this.loading++;
    this.mltSrv.save(this.lab)
      .then((resp: any) => {
        this.onClose(resp);
      })
      .catch((resp: any) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }

        if (resp.error.error) {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        }
      })
      .finally(() => {
        this.loading--;
      });

  }

}

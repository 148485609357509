import { Injectable } from '@angular/core';
import  { saveAs } from 'file-saver';
import { AppHttpService } from './app-http.service';
import { Alignment, Site } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class AlignmentService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Alignment with pagination
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: Alignment[]}
   */
  getRecords(site: Site, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/site/${site?.id}/alignments`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Alignment(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Alignment
   * @param model Alignment
   * @param site Site
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Alignment>}
   */
  create(model: Alignment, site: Site = null, payload: any = null, qp: any = {}): Promise<Alignment> {
    return this.appHttpService
      .postService(`/v2/site/${site?.id}/alignment`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Alignment(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Alignment
   * @param model Alignment
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Alignment>}
   */
  update(payload: any = null, qp: any = {}): Promise<Alignment> {
    return this.appHttpService
      .putService(`/v2/alignment/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Alignment(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Alignment
   * @param model Alignment
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Alignment): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/alignment/${model?.id}`)
      .toPromise();
  }

  /**
   * Returns Alignment
   * @param id Alignment.id
   * @param qp query params
   * @returns {Promise<Alignment>}
   */
  getRecord(id: string, qp: any = {}): Promise<Alignment> {
    return this.appHttpService
      .getService(`/v2/alignment/${id}`, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Alignment(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  generate(alignment: Alignment, payload: any = {}, qp = {}): Promise<any> {
    return this.appHttpService
      .postService(`/v2/alignment/${alignment?.id}/generate-stations`, payload, qp)
      .toPromise()
      .then(resp => {
        if (resp.data) {
          return {
            result: new Alignment(resp.data),
            meta: Object.assign(resp.meta || {}, { type: resp.type })
          };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Download alignments stations csv
   * @param alignment Alignment
   * @param qp query-params
   * @returns {Promise<any>}
   */
  downloadStations(alignment: Alignment, qp: any = {}): Promise<any> {
    return this.appHttpService.getService(
      `/v2/alignment/${alignment.id}/stations-csv`,
      qp,
      { responseType: 'blob' }
    )
      .toPromise()
      .then((resp: Blob) => {
        const name = `${alignment?.name}-stations.csv`
        return saveAs(resp, name.trim());
      });
  }
}

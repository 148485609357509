import { Component, Input } from '@angular/core';
import { NgxSkeletonLoaderConfig } from 'ngx-skeleton-loader';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() url = '';
  @Input() alt = 'Image';
  @Input() loaderAppearance: NgxSkeletonLoaderConfig['appearance'] = '';
  @Input() loaderTheme: NgxSkeletonLoaderConfig['theme'] = {
    width: '100%',
    height: '100%'
  };

  loading = true;

  constructor() {
  }

  hideLoader() {
    this.loading = false;
  }

  handleError() {
    this.hideLoader();
    this.url = '/assets/images/png/broken-image.png';
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Annotation, Attachment, BidItem, DailyReport, ReportMaterialDelivery } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService, DailyReportService } from 'src/app/shared/services';
import { AnnotComponent } from '../../annot/annot.component';
import { BidItemSelectorComponent } from '../../components/bid-item-selector/bid-item-selector.component';
import { Ability } from '@casl/ability';

@Component({
  selector: 'app-report-material-delivery',
  templateUrl: './report-material-delivery.component.html',
  styleUrls: ['./report-material-delivery.component.scss']
})
export class ReportMaterialDeliveryComponent implements OnInit {

  @Input() data: any;
  @Input() dailyReport: DailyReport;

  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;
  public dialogOptions: any = {};
  public componentResult: any = null;

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = false;

  public reportMaterialDelivery: ReportMaterialDelivery;
  public annotationPreviewUrl: string;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    quantity: ['', [Validators.required]],
    comment: [''],
  });

  constructor(
    public ability: Ability,
    public appSrv: AppService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private dailyReportService: DailyReportService,
    public dialogRef: MatDialogRef<ReportMaterialDelivery>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.isDialog) {
      const { data, dailyReport, options } = this.inputData;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = data;
      this.dailyReport = dailyReport;
    }

    this.reportMaterialDelivery = new ReportMaterialDelivery(JSON.parse(JSON.stringify(this.data)));
    this.toReactiveForm(this.reportMaterialDelivery, this.inputForm);

    this.reportMaterialDelivery.annotations = this.reportMaterialDelivery.annotations || [];
    this.reportMaterialDelivery.pictures = this.reportMaterialDelivery.pictures || [];
    this.reportMaterialDelivery.tickets = this.reportMaterialDelivery.tickets || [];

    if (this.reportMaterialDelivery?.annotations?.length) {
      this.annotationPreviewUrl = this.reportMaterialDelivery.annotations[0].getThumbnail();
    }

    this.editable = this.ability.can('create', this.dailyReport);
    if (this.editable && !this.reportMaterialDelivery.id) {
      this.editing = true; // new record
      this.openBidItemSelector();
    }
    this.initialized = true;
  }

  /**
   * Closes component or Dialog with response|event
   * @param resp data to be sent to parent
   */
  close(resp: any = null): void {

    if (this.isDialog) {
      this.dialogRef.close(resp || this.componentResult);
    }
    // Todo: emit output event
  }

  /**
   * Opens BidItem Selector
   */
  openBidItemSelector(): void {
    if (!this.editing) {
      return;
    }

    const dialogRef = this.dialog.open(BidItemSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: this.dailyReport?.project,
        selectedBidItem: this.reportMaterialDelivery?.bid_item,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {

      // if annotation has been changed, update it in list
      if (resp instanceof BidItem) {
        this.reportMaterialDelivery.bid_item = resp;
      }
    });
  }

  /**
   * Assign base model to FormGroup
   * @param lineItem ReportMaterialDelivery
   * @param form FormGroup
   */
  toReactiveForm(lineItem: ReportMaterialDelivery, form: UntypedFormGroup) {
    form.controls.quantity.setValue(lineItem.quantity);
    form.controls.comment.setValue(lineItem.comment);
  }

  /**
   * Update ReportBidItem
   */
  saveItem() {

    if (!this.inputForm.valid) {
      return;
    }

    const payload = {
      id: this.reportMaterialDelivery.id,
      bid_item_id: this.reportMaterialDelivery.bid_item.id,
      quantity: this.inputForm.value.quantity,
      comment: this.inputForm.value.comment,
      pictures: (this.reportMaterialDelivery.pictures || []).map(o => o.id),
      tickets: (this.reportMaterialDelivery.tickets || []).map(o => o.id),
      annotations: (this.reportMaterialDelivery.annotations || []).map(o => o.id),
    };

    this.loading++;
    const qp = { include: [ 'bid_item', 'pictures', 'tickets', 'annotations' ]};

    this.dailyReportService.saveLineItem('material_delivery', payload, this.dailyReport, qp)
      .then((reportMaterialDelivery: ReportMaterialDelivery) => {
        reportMaterialDelivery.bid_item_id = reportMaterialDelivery.bid_item.id;
        reportMaterialDelivery.site_id = this.dailyReport.site_id;
        reportMaterialDelivery.daily_report_id = this.dailyReport.id;
        this.toReactiveForm(reportMaterialDelivery, this.inputForm);
        this.snackBar.open('Saved material delivery', '', { duration: 5000 });

          this.reportMaterialDelivery = reportMaterialDelivery;
          this.componentResult = this.reportMaterialDelivery;
          this.loading--;
          this.editing = false;
        })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Attach uploaded attachment to pictures
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.reportMaterialDelivery.pictures.push(a));
  }

  /**
   * Remove pic from list
   * @param pic Attachment
   */
  removePicture(pic) {
    this.reportMaterialDelivery.pictures.splice(this.reportMaterialDelivery.pictures.findIndex(p => p.id === pic.id), 1);
  }

  /**
   * Attach uploaded attachment to tickets
   * @param attachment Attachment
   */
  onTicketUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.reportMaterialDelivery.tickets.push(a));
  }

  /**
   * Remove ticket from list
   * @param ticket Attachment
   */
  removeTicket(ticket) {
    this.reportMaterialDelivery.tickets.splice(this.reportMaterialDelivery.tickets.findIndex(t => t.id === ticket.id), 1);
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.editing = false;
    this.reportMaterialDelivery = new ReportMaterialDelivery(JSON.parse(JSON.stringify(this.data)));
  }

  /**
   * Remove annot from list
   * @param annot Annotation
   */
  removeAnnot(annot) {
    this.reportMaterialDelivery.annotations.splice(this.reportMaterialDelivery.annotations.findIndex(a => a.id === annot.id), 1);
  }

  /**
   * Opens annotations in Modal
   * @param annotation Annotation
   */
  openAnnot(annotation: Annotation = null) {
    if (!annotation && !this.editing) {
      return;
    }

    const dialogRef = this.dialog.open(AnnotComponent, {
      disableClose: true,
      data: {
        annotation: annotation || new Annotation(),
        project: this.dailyReport.project,
        editable: this.editing,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      // if annotation has been changed, update it in list
      if (resp instanceof Annotation) {
        const index = this.reportMaterialDelivery.annotations.findIndex(o => o.id === resp.id);
        if (index > -1) {
          this.reportMaterialDelivery.annotations.splice(index, 1, resp);
        } else {
          this.reportMaterialDelivery.annotations.push(resp);
        }
      }
    });
  }

  /**
   * Deletes line item
   * @param li LineItem
   * @returns void
   */
  delete(li: ReportMaterialDelivery): void {
    if (!li.id || !this.editable) {
      return;
    }

    const message = `Deleting material delivery <b>${li.bid_item?.item}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.loading++;
      this.dailyReportService.deleteLineItem('material_delivery', li, this.dailyReport)
        .then(resp => {
          li.__deleted = true;
          li.daily_report_id = this.dailyReport.id;
          this.close(li);
          this.snackBar.open(resp.message || 'Deleted', '', { duration: 5000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        })
        .finally(() => {
          this.loading--;
        });
    });
  }

}

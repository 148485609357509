<div class="center">
  <div class="mat-headline"><a class="logo" href="/"></a></div>
  <div class="stroked-card"
    [ngClass]="{
      'email-phone-login': (email || phone) && !magicCode
    }"
  >
    <ng-container *ngIf="!email && !phone && !magicCode">
      <ng-container *ngTemplateOutlet="CheckUserContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="email && !phone && !magicCode">
      <ng-container *ngTemplateOutlet="EmailLoginContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="phone && !email && !magicCode">
      <ng-container *ngTemplateOutlet="PhoneLoginContent"></ng-container>
    </ng-container>

    <ng-container *ngIf="magicCode">
      <ng-container *ngTemplateOutlet="MagicLoginLoading"></ng-container>
    </ng-container>
  </div>
  <div *ngIf="email && !phone && !magicCode && !magicLinkMsg" class="forgot-label-row flex flex-row justify-start items-center gap-[10px] w-fit ml-[30px]">
    <div class="primary"><a class="mat-subheading-2 no-style" href="#">Forgot Password?</a></div>
    <div class="primary"><a (click)="resetLogin()" class="mat-subheading-2 no-style">Wrong email?</a></div>
  </div>

</div>

<ng-template #CheckUserContent>
  <section class="header text-left">
    <div class="text-type-2">Sign in</div>
  </section>

  <form [formGroup]="checkUserForm">
    <section class="body">

      <!-- Username -->
      <section class="username-section">
        <mat-form-field
          class="form-field w-full"
          hideRequiredMarker
        >
          <mat-label class="text-type-13">Email / phone</mat-label>
          <input [errorStateMatcher]="matcher" autocomplete="off" formControlName="username" matInput type="text" />
          <mat-error *ngIf="checkUserForm.controls.username.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error class="error" *ngIf="checkUserForm.controls.username.hasError('error')">
            Not a valid email or phone number
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /Username -->

      <!-- Form Actions -->
      <section>
        <div class="row">
          <button (click)="checkUser()" color="primary" mat-stroked-button>Next</button>
        </div>
      </section>
      <!-- Form Actions -->
    </section>
  </form>
</ng-template>


<!-- Login via email options -->
<ng-template #EmailLoginContent>
  <section class="header text-left">
    <div class="text-type-2">Sign in</div>
  </section>

  <section class="body">

    <div *ngIf="!magicLinkMsg">
      <form [formGroup]="loginForm">
        <!-- Password -->
        <section class="password-section">
          <div class="multi-line-label flex flex-col justify-center items-start gap-[4px]">
            <div class="text-type-13">
              Password
            </div>
            <div class="text-type-11">
              Enter password for <span class="email">{{ email }}</span>
            </div>
          </div>
          <mat-form-field
            class="form-field w-full password"
            hideRequiredMarker
          >
            <input [errorStateMatcher]="matcher" autocomplete="off" formControlName="password" id="password" matInput
                   type="password" />
            <mat-error *ngIf="loginForm.controls.password.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="loginForm.controls.password.hasError('error')">
              {{ loginForm.controls.password.errors.error }}
            </mat-error>
          </mat-form-field>
          <div
            (click)="getMagicLink()"
            class="get-magic-link text-type-9 cursor-pointer w-fit"
            [ngClass]="{ 'with-error': (loginForm.controls.password.hasError('error') || loginForm.controls.password.hasError('required')) && (loginForm.controls.password.touched || loginForm.controls.password.dirty) }"
          >
            or email me a sign in link
          </div>
        </section>
        <!-- Form Actions -->
        <section>
          <div class="row">
            <button (click)="loginWithPassword()" color="primary" mat-flat-button>Sign in</button>
          </div>
        </section>
        <!-- Form Actions -->
      </form>
    </div>

    <div *ngIf="magicLinkMsg">
      <section class="magic-link-msg center v-gap-10">
        <div class="mat-subheading-2">Check your email!</div>
        <p class="text-type-11">We've sent a sign-in link to</p>
        <p><strong>{{ email }}</strong></p>
        <p class="text-type-11">Just click the link to sign in.</p>
      </section>
      <section>
        <div class="row">
          <button (click)="resetLogin()" color="primary" mat-stroked-button>Back</button>
        </div>
      </section>
    </div>
  </section>

</ng-template>
<!-- Login via email options -->


<!-- MagicLoginLoading -->
<ng-template #MagicLoginLoading>
  <section class="header text-left">
    <div class="text-type-2">Error</div>
  </section>

  <section class="body magic-link-section">
    <div *ngIf="!magicError" class="mat-subheading-2 center">Validating your sign-in link.</div>
    <div *ngIf="magicError" class="mat-subheading-2 error">Link is no longer valid.</div>
  </section>
  <section>
    <div class="row">
      <button (click)="resetLogin()" color="primary" mat-stroked-button>Back</button>
    </div>
  </section>
</ng-template>
<!-- MagicLoginLoading -->


<!-- Login via Phone -->
<ng-template #PhoneLoginContent>
  <section class="header text-left">
    <div class="text-type-2">Verification</div>
  </section>

  <section class="body">
    <div>
      <form [formGroup]="phoneForm">
        <!-- OTP -->
        <section class="otp-section">
          <div class="multi-line-label flex flex-col justify-center items-start gap-[4px]">
            <div class="text-type-13">
              Enter the one time passcode we sent to your phone at {{ phone }}
            </div>
            <div class="text-type-11">
              PASSCODE
            </div>
          </div>
          <mat-form-field>
            <input [errorStateMatcher]="matcher" autocomplete="off"
                   formControlName="otp"
                   inputmode="numeric"
                   matInput
                   maxlength="6"
                   type="text" />
            <mat-error *ngIf="phoneForm.controls.otp.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="phoneForm.controls.otp.hasError('error')">
              {{ phoneForm.controls.otp.errors.error }}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /Username -->

        <!-- Form Actions -->
        <section>
          <div class="flex flex-row justify-center items-center w-full gap-[10px]">
            <button type="button" (click)="resetLogin()" class="w-1/2" color="primary" mat-stroked-button>Back</button>
            <button type="submit" (click)="loginWithPhone()" class="w-1/2" color="primary" mat-flat-button>Sign in</button>
          </div>
        </section>
        <!-- Form Actions -->
      </form>
    </div>

  </section>
</ng-template>
<!-- Login via Phone -->

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { Account, DailyReport, Invitation, IssueType, Project, ProjectReport, Site } from '../models';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProjectService {
  fetchIssues: Subject<null> = new Subject<null>();

  constructor(private appHttpService: AppHttpService) {}

  getRecord(id: string, qp = {}) {
    return this.appHttpService.getService(`/v1/project/${id}`, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Project(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Returns list of Records
   * @param pagination start
   * @param qp query params
   */
  getRecords(pagination, qp = {}) {
    qp = {
      start: pagination.pageIndex * pagination.pageSize,
      total: pagination.pageSize,
      all: 'all',
      include: ['sites', 'address', 'createdBy', 'owner_organization', 'cover_photo'],
    };
    const path = '/v1/project';
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Project(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Returns project's daily-reports for last n days
   * @param project Project
   * @param days number of recent days
   */
  getDailyReports(project, qp = {}) {
    qp = Object.assign({ total: 1000 }, qp);
    return this.appHttpService.getService(`/v1/daily-report/project/${project.id}`, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new DailyReport(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetches project reports
   * @param project Project or ProjectReport.id
   * @param qp query params
   */
  getReports(project: Project | string, qp: any = {}): Promise<any> {
    const param = typeof project === 'string' ? project : project.id;
    qp.projectId = param;
    return this.appHttpService
      .getService(`/v1/project-report`, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new ProjectReport(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  saveReport(project: Project, payload: any, qp = {}): Promise<ProjectReport> {
    return this.appHttpService
      .postService(`/v1/project-report/${project.id}/draft`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if ('data' in resp) {
          return new ProjectReport(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Saves project
   * @param project Project
   * @param payload json payload
   * @param qp query-params
   * @returns Promise<Project>
   */
  save(project: Project, payload: any = null, qp: any = {}): Promise<Project> {
    return this.appHttpService
      .postService(`/v1/project`, payload || project.toPayload(), qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Project(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Project
   * @param project Project
   */
  delete(project: Project) {
    // Todo check acl here
    return this.appHttpService.deleteService(`/v1/project/${project.id}`);
  }

  /**
   * Fetches Project accounts
   * @param project project
   * @param pagination pagination object
   * @param qp query-params
   */
  getAccounts(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = `/v1/project/${project.id}/accounts`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Account(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetches Project invites
   * @param project project
   * @param pagination pagination
   * @param qp query-params
   */
  getInvites(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    qp.project_id = project.id;
    const path = `/v1/invite/search`;
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Invitation(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Invite a user to project
   * @param project project
   * @param invitee email|phone
   * @param role Role.id
   * @param qp query-params
   */
  inviteUser(project: Project, invitee: string, role: string, qp: any = {}) {
    const payload = {
      invitee,
      invitee_role: role,
      invite_type: 'project',
      invite_to: project.id,
      invitee_organization_id: project.owner_organization.id,
    };

    return this.appHttpService.postService('/v1/invite/account', payload).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Invitation(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetches Project accounts
   * @param project project
   * @param qp query-params
   */
  fetchAccounts(project: Project, qp: any = {}): Promise<Account[]> {
    qp.start = 0;
    qp.total = 10000;
    qp.include = qp.include || ['picture', 'organization', 'roles'];
    const path = `/v1/project/${project.id}/accounts`;
    return this.appHttpService
      .getService(path, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new Account(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Fetches project sites
   * @param project Project
   * @param qp query params
   * @returns {Promise<Site[]>}
   */
  fetchSites(project: Project, qp: any = {}): Promise<Site[]> {
    qp.start = 0;
    qp.total = 10000;
    qp.include = qp.include || [];
    const path = `/v1/project/${project.id}/sites`;
    return this.appHttpService
      .getService(path, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new Site(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Returns IssueTypes
   * @param org Organization
   * @param qp query params
   * @returns {Promise<IssueType[]>}
   */
  getIssueTypes(project: Project, qp: any = {}) {
    return this.appHttpService
      .getService(`/v2/project/${project?.id}/issue-types`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new IssueType(r));
        }
        throw new Error('Error parsing response');
      });
  }
  /**
   * Returns IssueTypes
   * @param projectId query params
   */
  getMapLayers(projectId) {
    return this.appHttpService
      .getService(`/v2/project/${projectId}/map-layer`)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data;
        }
        throw new Error('Error parsing response');
      });
  }
}

import { BaseModel } from './base-model';
import { Organization } from './organization';

export class IssueType extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'OrganizationIssueType'; }

  /* tslint:disable */
  label: string;
  type: string;
  organization_id: string;
  organization: Organization;
  /* tslint:enable */

  get relations() {
    return {
      organization: Organization,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.organization_id = this.organization?.id || this.organization_id;
  }
}

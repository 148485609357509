<div class="wrapper">
  <ng-container *ngIf="data?.laborDetails; else addLaborHeading">
    <div class="heading text-type-2 mb-[40px]">
      {{ data?.laborDetails?.quantity }} {{ data?.laborDetails?.type }}
    </div>
  </ng-container>
  <ng-template #addLaborHeading>
    <div class="heading text-type-2 mb-[40px]">
      Add Labor
    </div>
  </ng-template>
  <div class="form">
    <app-old-labor-format-type-form
      [data]="data?.laborDetails"
    />
  </div>
</div>

<ng-container *ngIf="initialized">

  <section class="header">
    <app-account-icon [account]="account" *ngIf="account" size="48px"></app-account-icon>
    <div class="title">
      <div class="heading-3">{{issue.title}}</div>
      <div class="mat-caption" *ngIf="account">{{account.name}} {{issue.created_at | dateFormat : 'ago'}} ago</div>
    </div>
  </section>

  <section class="body">
    <div class="mat-body">{{issue.description}}</div>
  </section>

  <section class="footer">

    <div *ngIf="issue.status === 'closed'" class="mat-body-strong">
      {{issue.closedBy?.name}} closed on {{issue.closed_at | dateFormat: 'MMMM DD'}}
    </div>

    <div class="tags">
      <span class="tag">{{issueTypeLabel(issue.type)}}</span>

      <span class="tag" [ngSwitch]="issue.status" *ngIf="status">
        <ng-container *ngSwitchCase="'open'">Open</ng-container>
        <ng-container *ngSwitchCase="'closed'">Closed</ng-container>
      </span>

      <span class="tag" [ngSwitch]="issue.internal" *ngIf="internal">
        <ng-container *ngSwitchCase="true">Private</ng-container>
        <ng-container *ngSwitchCase="false">Public</ng-container>
      </span>
    </div>

  </section>

</ng-container>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProjectMapService } from '../../../../project-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  ScrollService
} from '../../../../../../../shared/services/scroll.service';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['../../styles.css', './wrapper.component.scss'],
})
export class MapSidenavLaborEquipmentWrapperComponent implements OnInit,
                                                                 OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  @Input() data: any;

  isLaborSectionExpanded = false;
  isEquipmentSectionExpanded = false;

  constructor(
    private projectMapService: ProjectMapService,
    private scrollService: ScrollService
  ) {
  }

  ngOnInit() {
    this.projectMapService.selectedMarkerData
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data.labor) {
          this.isLaborSectionExpanded = true;
          this.isEquipmentSectionExpanded = false;
        }

        if (data.equipment) {
          this.isLaborSectionExpanded = false;
          this.isEquipmentSectionExpanded = true;
        }

        setTimeout(() => {
          this.scrollService.scrollToElementById(data.id.trim());
        }, 200);
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

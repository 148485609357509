<div class="wrapper">
  <div class="heading text-type-15">STATUS</div>
  <div class="list-section flex flex-wrap">
    <ng-container *ngFor="let item of summary; let isLast = last">
      <div
        class="text-type-11"
        [classList]="item.type"
        (click)="handleClick(item.type)"
      >
        {{ item.count }} {{ item.label }}
      </div>
      <div
        *ngIf="!isLast"
        class="divider"
      ></div>
    </ng-container>
  </div>
</div>

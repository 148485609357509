<div class="header">
  <div class="left-icons">
    <button (click)="onClose()" mat-icon-button aria-label="Back">
      <mat-icon title="Back">arrow_back</mat-icon>
    </button>
  </div>
  <div class="title">
    <ng-container *ngIf="!isNew">Edit onsite test</ng-container>
    <ng-container *ngIf="isNew">Add onsite test</ng-container>
  </div>
  <div class="right-icons">
    <button type="button" class="mat-inline-button" mat-button color="primary" (click)="saveAll()"
      [disabled]="!lineItems?.length">Done</button>
  </div>
</div>

<div class="body">
  <ng-container *ngIf="initialized">

    <!-- Internal test selector -->
    <section>
      <div class="mat-subheading-1">Onsite test:</div>
      <mat-form-field>
        <mat-select [(value)]="internalTest" (selectionChange)="init()">
          <mat-option *ngFor="let it of internalTests" [value]="it">{{it.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <!-- /Internal test selector -->

    <!-- Bid Item section -->
    <section>
      <div class="mat-subheading-1">Bid item:</div>
      <div class="card link" (click)="openBidItemSelector()" *ngIf="biditem">
        <div class="mat-subheading-2">{{biditem?.item}}</div>
        <div>{{biditem.description}}</div>
      </div>

      <div class="input-placeholder" (click)="openBidItemSelector()" *ngIf="!biditem">
        <button mat-icon-button color="accent">
          <mat-icon>add</mat-icon>
        </button>Add bid item
      </div>
    </section>
    <!-- /Bid Item section -->

    <!-- Heading selector -->
    <section>
      <div class="mat-subheading-1">Heading:</div>
      <mat-form-field>
        <mat-select (selectionChange)="byStation && onHeadingChange($event.value)" [(value)]="heading">
          <mat-option *ngFor="let heading of headings" [value]="heading">{{heading.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </section>
    <!-- /Heading selector -->

    <!-- Add lineitem -->
    <section>
      <div class="row">
        <div class="mat-subheading-1">
          <ng-container *ngIf="byStation">Station</ng-container>
          <ng-container *ngIf="!byStation">Time</ng-container>
        </div>
        <div class="icons">
          <button mat-icon-button (click)="addLineItem()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </section>
    <!-- /Add lineitem -->

    <!-- Lineitem Forms -->
    <mat-accordion *ngIf="lineItems.length" class="line-items">
      <mat-expansion-panel *ngFor="let li of lineItems;" [expanded]="li._id === selectedItem?._id">
        <mat-expansion-panel-header (click)="selectLineItem(li)">
          <div class="header-row">
            <span>
              <mat-icon color="warn" *ngIf="(!li._inputForm.valid && !li._inputForm.pristine)">error</mat-icon>
              <ng-container *ngIf="byStation">{{li?.station?.name}}</ng-container>
              {{li?.recordTimeZ | dateFormat: "h:mm a"}}
              <span class="mat-caption"
                *ngIf="li.getFieldValueSummary(fields)">&nbsp;({{li.getFieldValueSummary(fields)}})
              </span>
            </span>
            <div class="icons">
              <!-- More menu -->
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="delete(li)">
                  <mat-icon>delete</mat-icon>
                  <span *ngIf="byStation">Remove station</span>
                  <span *ngIf="!byStation">Remove Time</span>
                </button>
              </mat-menu>
              <!-- /More menu -->
            </div>
          </div>
        </mat-expansion-panel-header>
        <ng-container [ngTemplateOutlet]="SingleForm" [ngTemplateOutletContext]="{'rit': li}">
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- /Lineitem Forms -->

    <!-- Delete all items -->
    <section>
      <br />
      <button type="button" class="mat-inline-button" mat-button color="primary" (click)="deleteAll()"
        [disabled]="!lineItems?.length">
        <mat-icon>delete</mat-icon> Delete onsite test
      </button>
    </section>
    <!-- /Delete all items -->


  </ng-container>
</div>

<div class="footer" *ngIf="initialized">
  <app-report-item-toolbar *ngIf="selectedItem" [lineItem]="selectedItem" [project]="project"
    (changed)="onLineItemChange($event)"></app-report-item-toolbar>
</div>


<ng-template #SingleForm let-rit="rit">
  <form [formGroup]="rit._inputForm">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>

    <section class="custom-fields">
      <!-- record_time section -->
      <section class="width-50">
        <div class="mat-subheading-1">Time:</div>
        <mat-form-field>
          <ngx-material-timepicker #recordTime></ngx-material-timepicker>
          <input matInput autocomplete="off" [ngxTimepicker]="recordTime" [format]="24" formControlName="record_time"
            [errorStateMatcher]="matcher" />
          <mat-error *ngIf="rit._inputForm.controls.record_time.hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="rit._inputForm.controls.record_time.hasError('error')">
            {{rit._inputForm.controls.record_time.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /record_time section -->

      <!-- station_id section -->
      <section class="width-50" *ngIf="byStation">
        <div class="mat-subheading-1">Station:</div>
        <mat-form-field>
          <input type="text" matInput formControlName="station_id" [errorStateMatcher]="matcher"
            [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let option of stationOptions | async" [value]="option">
              {{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="rit._inputForm.controls.station_id.hasError('required')">
            This field is required
          </mat-error>
          <mat-error *ngIf="rit._inputForm.controls.station_id.hasError('error')">
            {{rit._inputForm.controls.station_id.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /station_id section -->

      <app-field-input *ngFor="let field of fields" [field]="field" [form]="rit._inputForm" [matcher]="matcher"
        [class]="getWidth(field)"></app-field-input>
    </section>
    <div style="clear: both"></div>
  </form>
  <ng-container [ngTemplateOutlet]="readOnlyToolbarItems" [ngTemplateOutletContext]="{'li': rit}"></ng-container>
</ng-template>



<ng-template #readOnlyToolbarItems let-li="li">
  <section *ngIf="li.pictures.length">
    <div class="mat-subheading-1">Pictures:</div>
    <div class="preview-list">
      <div *ngFor="let pic of li.pictures">
        <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, li.pictures, pic)" />
      </div>
    </div>
  </section>

  <section *ngIf="li.annotations.length">
    <div class="mat-subheading-1">Annotations:</div>
    <div class="preview-list">
      <div *ngFor="let ann of li.annotations">
        <img [src]="ann.getThumbnail()" class="link" />
      </div>
    </div>
  </section>

  <section *ngIf="!li.location.isEmpty">
    <div class="mat-subheading-1">Location:</div>
    <div>
      <app-location-picker [isDialog]="false" [latitude]="li.location.lat" [longitude]="li.location.lng" height="100px"
        [readOnly]="true"></app-location-picker>
    </div>
  </section>

  <section *ngIf="li.comment">
    <div class="mat-subheading-1">Comment:</div>
    <mat-form-field appearance="outline">
      <textarea matInput rows="5" [value]="li.comment" [disabled]="true"></textarea>
    </mat-form-field>
  </section>
</ng-template>
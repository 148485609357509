<div class="wrapper flex flex-col gap-[40px]">
  <section class="header-section">
    <app-image
      url="{{siteDetails?.imageUrl}}"
      alt="{{ siteDetails?.name }}_picture"
      class="project-image"
    />
    <div class="heading-section">
      <div class="heading text-type-2">{{ siteDetails?.name }}
      </div>
      <div class="sub-heading text-type-10">
        {{ siteDetails?.project?.name }}, {{siteDetails?.project?.owner}}
      </div>
    </div>
  </section>
  <section class="content-section">
    <mat-tab-group
      #tabGroup
      class="contents-tab-group"
      [selectedIndex]="selectedTabIndex"
    >
      <mat-tab label="Daily report">
        <app-daily-report-wrapper/>
      </mat-tab>
      <mat-tab label="Details">
        Details
      </mat-tab>
      <mat-tab label="Changes">
        Changes
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

import { Component, Input, OnInit } from '@angular/core';
import { Project, ProjectReport } from 'src/app/shared/models';
import { AppService, ProjectReportService } from 'src/app/shared/services';
import * as moment from 'moment';

@Component({
  selector: 'app-latest-project-report-summaries',
  templateUrl: './latest-project-report-summaries.component.html',
  styleUrls: ['./latest-project-report-summaries.component.scss']
})
export class LatestProjectReportSummariesComponent implements OnInit {

  public initialized: boolean = false;
  public loading: number = 0;

  @Input() public project: Project;
  @Input() public days: number = 2;

  public projectReports: ProjectReport[] = [];
  public statuses: any[] = [];

  constructor(
    private appSrv: AppService,
    private prSrv: ProjectReportService
  ) { }

  ngOnInit(): void {
    this.fetchProjectReports(this.project, this.days);
  }

  /**
   * Fetches project reports
   * @param project Project
   * @param days number
   */
  fetchProjectReports(project: Project, days: number = 2) {
    const dates = this.appSrv.getLastNDates(days, moment(project.start_date), moment(project.end_date));

    this.loading++;
    const qp = {
      start: 0,
      total: dates.length,
      from: dates[dates.length - 1].format('YYYY-MM-DD'),
      to: dates[0].format('YYYY-MM-DD'),
    };
    this.prSrv.findRecords(this.project, qp)
      .then((prs: ProjectReport[]) => {
        this.projectReports = prs.map(o => {
          o.project_id = this.project.id;
          return o;
        });

        this.statuses = dates.map((d: moment.Moment) => {
          const datePrs = prs.find(pr => d.isSame(pr.report_date, 'day'));
          if (!datePrs) {
            return new ProjectReport({
              status: 'todo',
              report_date: d,
              project_id: this.project.id,
            });
          } else {
            return datePrs;
          }
        });

      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

}

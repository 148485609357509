import { BaseModel } from './base-model';
import { Account} from './account';
import { OrganizationType } from './organization-type';

export class Organization extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Organization'; }

  /* tslint:disable */
  name: string;
  type: OrganizationType;
  counter_party_type: string;
  createdBy: Account;
  updatedBy: Account;
  type_id: string;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      type: OrganizationType,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // add few attributes to assist acl module
    this.type_id = this.type ? this.type.id : null;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

import { Organization, Project, Report } from 'src/app/shared/models';
import { AppErrorStateMatcher, ReportService } from 'src/app/shared/services';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  public loading = 0;
  public initialized = false;

  @Input() project: Project;
  @Input() organization: Organization;
  public reports: Report[] = [];
  public report: Report;
  public newReport: Report; // for acl

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    template: ['', [Validators.required]],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private reportSrv: ReportService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.newReport = new Report({
      project: this.project,
      organization: this.organization,
    });
    this.fetchRecords();
  }

  fetchRecords(): void {
    this.loading++;
    const qp = { include: ['project', 'organization'] };
    this.reportSrv.getReports(this.project, this.organization, qp)
      .then((reports: Report[]) => {
        this.reports = reports;

        if (this.reports.length) {  // select first template
          this.changeSelection(this.reports[0]);
        }
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Toggles input form
   * @param model Report
   * @param show boolean
   */
  toggleForm(model: Report = null, show = true): void {
    if (show) {
      this.inputForm.reset({
        id: model?.id || null,
        name: model?.name || null,
        template: model?.template || null,
      });
      this.report = model;
    }
  }

  save(form: UntypedFormGroup) {
    if (!form.valid) {
      return;
    }
    const payload = form.value;
    if (this.project) {
      payload.project_id = this.project?.id;
    }

    this.loading++;
    const qp = { include: ['project', 'organization'] };
    const req = payload?.id
      ? this.reportSrv.update(payload, qp)
      : this.reportSrv.create(this.project, this.organization, payload, qp);

    req.then((report: Report) => {
      this.snackBar.open(`Saved "${report?.name}"`, '', { duration: 5000 });

      report.selected = true;
      const i = this.reports.findIndex(o => o.id === report.id);
      if (i >= 0) {
        this.reports.splice(i, 1, report);
      } else {
        this.reports.push(report);
      }
      this.changeSelection(report);
      this.toggleForm(report, false);
    })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  changeSelection(report: Report) {
    if (!report) {
      this.report = null;
      return;
    };
    this.report = report;

    // for initial selection
    this.reports.map((o) => o.selected = false);
    this.report.selected = true;

    this.toggleForm(this.report, true);
  }

  selectionChange(e: any) {
    this.changeSelection(e?.source?._value[0] || null);
  }

  /**
   * Deletes Template
   * @param model
   */
  delete(model: Report): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete?',
        `Deleting <b>${model.name}</b> cannot be undone. <br />Proceed to delete?`
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.reportSrv.delete(model)
        .then((resp: any) => {
          this.snackBar.open(resp.message, '', { duration: 3000 });
          this.reports.splice(
            this.reports.findIndex(o => o.id === model.id),
            1,
          );
          this.changeSelection(this.reports[0] || null);
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
    });
  }
}

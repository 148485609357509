import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as fromRoot from '../../state/app.state';
import * as SharedActions from '../core/shared.actions';
import { ClearState as ClearSharedState } from '../core/shared.actions';
import { ClearState as ClearProjectState } from '../../modules/projects/core/projects.actions';
import { FormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  closeLoadingScreen: Subject<null> = new Subject<null>();
  uploadedFilesInfo: Subject<any[]> = new Subject<any[]>();
  resetPaginator: Subject<any> = new Subject<any>();
  closeDialog: Subject<any> = new Subject<any>();
  openSuccessDialog: Subject<any> = new Subject<any>();
  clearRowSelection: Subject<any> = new Subject<any>();
  focusedIn: Subject<any> = new Subject<any>();

  constructor(
    private store?: Store<fromRoot.State>,
    private http?: HttpClient,
    private snackBar?: MatSnackBar,
  ) {
  }

  setToSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getFromSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  removeFromSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  callAPI(type: string, url: string, payload?: any, options: any = {}) {
    // @ts-ignore
    let apiCall = this.http[type]<any>(url, payload, {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      ...options,
    });

    if (type === 'delete') {
      apiCall = this.http[type]<any>(url, {
        observe: 'response',
        body: payload || {},
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
        ...options,
      });
    }

    return apiCall.pipe(
      map((response: any) => {
        if (response instanceof HttpResponse) {
          return response.body;
        }

        return response;
      }),
      catchError(error => throwError(error)),
    );
  }

  startLoading() {
    this.store.dispatch(SharedActions.StartLoading());
  }

  stopLoading() {
    this.store.dispatch(SharedActions.StopLoading());
  }

  clearAppState() {
    this.store.dispatch(ClearSharedState());
    this.store.dispatch(ClearProjectState());
  }

  clearStorage() {
    const appLocalStorageKeys = ['sitenotes.shared', 'sitenotes.project'];

    appLocalStorageKeys.forEach(key => {
      this.removeFromSessionStorage(key);
    });
  }

  checkFormValidation(form: FormGroup, errorMessageMap: any, currentField?: string) {
    const errorMessages = {};
    const formControls = form.controls || null;

    if (currentField) {
      const errors = form.controls[currentField].errors;
      const firstErrorType = errors ? Object.keys(errors)[0] : '';
      // @ts-ignore
      errorMessages[currentField] = errorMessageMap[currentField][firstErrorType];
      return errorMessages;
    }

    // tslint:disable-next-line:forin
    for (const eachControlName in formControls) {
      const isControlValid = form.controls[eachControlName].valid;
      if (!isControlValid) {
        const errors = form.controls[eachControlName].errors;
        const firstErrorType = errors ? Object.keys(errors)[0] : null;
        if (firstErrorType) {
          form.controls[eachControlName].markAsTouched();
          // @ts-ignore
          errorMessages[eachControlName] = errorMessageMap[eachControlName][firstErrorType];
        }
      }
    }
    return errorMessages;
  }

  notification(message: string, type = '', actionText?: string, duration = 5000) {
    if (type === 'success') {
      message = `${message}`;
    }
    this.snackBar.open(message, actionText, {
      duration,
      verticalPosition: 'top', // 'top' | 'bottom'
      horizontalPosition: 'center', // 'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: [type],
    });
  }
}

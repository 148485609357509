import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../../../../../../../../../shared/services/common.service';
import { Subject } from 'rxjs';
import { DailyReportService } from '../../../../daily-report.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../../../state/app.state';
import { dailyReport } from '../../../../../../../../core/projects.selectors';
import { ProjectsService } from '../../../../../../../../core/projects.service';
import { DeleteVideoFromMuxDbRequest } from '../../../../../../../../core/projects.actions';

@Component({
  selector: 'app-activity-item-details',
  templateUrl: './activity-item-details.component.html',
  styleUrls: ['./activity-item-details.component.scss', '../../input-component-styles.scss'],
})
export class ActivityItemDetailsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  @Input() options: any = {
    showActionsSection: true
  };
  mediaFiles = [];
  form: FormGroup = new FormGroup({
    title: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl(''),
    pictures: new FormControl('[]'),
    videos: new FormControl('[]'),
  });
  disableOptionMenu = false;
  videoToBeDeletedFromMuxDb = [];
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private projectsService: ProjectsService,
    private dailyReportService: DailyReportService,
    private store: Store<fromRoot.State>,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    if (this.options.disabled) {
      this.form.disable();
    }
    if (this.data) {
      this.form.patchValue({
        title: this.data?.title,
        latitude: this.data?.latitude,
        longitude: this.data?.longitude,
        pictures: JSON.stringify(this.data?.pictures),
        videos: JSON.stringify(this.data?.videos),
      });

      this.mediaFiles = this.data?.media;
    } else {
      this.disableOptionMenu = true;
      const newActivity = {
        id: `new_${new Date().getTime()}`,
        title: '',
        description: '',
        latitude: '',
        longitude: '',
        pictures: [],
        videos: [],
        address: null,
        annotation: null,
      };

      this.store.select(dailyReport)
        .subscribe((res: any) => {
          const index = res.activities?.length || 0;

          this.data = {
            index: index + 1,
            ...newActivity,
          };

          this.cdRef.detectChanges();
        });
    }

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const items = Array.isArray(data) ? data : [data];

        items.forEach((eachItem: any) => {
          if (eachItem?.['fileType'] === 'picture') {
            let pictures = this.form.get('pictures')?.value || '[]';
            pictures = JSON.parse(pictures);

            pictures.push(eachItem);
            this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
            this.mediaFiles.push(eachItem);  // Add to mediaFiles array

          } else if (eachItem?.['fileType'] === 'video') {
            let videos = this.form.get('videos')?.value || '[]';
            videos = JSON.parse(videos);

            videos.push(eachItem);
            this.form?.get('videos')?.setValue(JSON.stringify(videos));
            this.mediaFiles.push(eachItem);  // Add to mediaFiles array
          }
        });
      });


    this.dailyReportService.triggerSave
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.save();
        }
        if (this.videoToBeDeletedFromMuxDb.length) {
          this.videoToBeDeletedFromMuxDb.forEach(videoId => {
            this.store.dispatch(DeleteVideoFromMuxDbRequest({ payload: videoId }));
          });
        }
      });

    this.dailyReportService.triggerDelete
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        if (this.form) {
          this.delete();
        }
      });

    this.dailyReportService.selectedLocation
      .pipe(takeUntil(this.onDestroy))
      .subscribe((location) => {
        this.form.patchValue({
          latitude: location.latitude,
          longitude: location.longitude,
        });
      });

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((res: any) => {
        this.projectsService.isAnyFormDirty.next(res);
      });
  }

  save(): void {
    const { index, ...rest } = this.data;

    const values = this.form.getRawValue();
    const payload = {
      ...rest,
      ...values,
      pictures: JSON.parse(values.pictures),
      videos: JSON.parse(values.videos),
    };

    this.dailyReportService.save.next({
      type: 'activities',
      data: [payload],
    });
  }

  delete() {
    this.dailyReportService.delete.next({
      type: 'activities',
      id: this.data.id,
    });
  }

  deletePicture(pic) {
    if (pic.fileType === 'picture') {
      let pictures = this.getPictures();

      pictures = pictures?.filter(o => o.id !== pic.id);
      this.mediaFiles = this.mediaFiles?.filter(o => o.id !== pic.id);
      this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
    }

    if (pic.fileType === 'video') {
      let videos = this.getVideos();

      videos = videos?.filter(o => o.id !== pic.id);
      this.mediaFiles = this.mediaFiles?.filter(o => o.id !== pic.id);
      this.form?.get('videos')?.setValue(JSON.stringify(videos));
      this.videoToBeDeletedFromMuxDb.push(pic.id);
    }
  }

  deleteLocation() {
    this.form?.get('latitude').setValue(null);
    this.form?.get('longitude').setValue(null);
  }

  getPictures() {
    const pictures = this.form.controls['pictures']?.value || '[]';
    return JSON.parse(pictures);
  }

  getVideos() {
    const videos = this.form.controls['videos']?.value || '[]';
    return JSON.parse(videos);
  }

  showLocationSection() {
    return this.form?.controls?.latitude?.value !== null &&
      this.form?.controls?.longitude?.value !== null &&
      this.form?.controls?.latitude?.value !== '' &&
      this.form?.controls?.longitude?.value !== '';
  }

  ngOnDestroy() {
    this.cdRef.detach();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

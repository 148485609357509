<div class="wrapper">
  <div
    *ngIf="filteredProjectReportsList?.length"
    [class.collapsed]="!isExpanded"
    class="reports-list"
  >
    <app-project-reports-card
      (click)="selectReport(report, i)"
      *ngFor="let report of filteredProjectReportsList; let i = index"
      [report]="report"
      class="each-project-report-card"
    />
  </div>
  <div
    (click)="handleExpansion()"
    *ngIf="projectReportsList?.length > 3"
    class="see-more text-type-9 flex"
  >
    {{ isExpanded ? 'See less' : 'See more' }}
  </div>
</div>

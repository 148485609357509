<div class="wrapper">
  <ng-container *ngIf="data?.equipmentDetails; else addEquipmentHeading">
    <div class="heading text-type-2 mb-[40px]">
      {{ data?.equipmentDetails?.quantity }} {{ data?.equipmentDetails?.type }}
    </div>
  </ng-container>
  <ng-template #addEquipmentHeading>
    <div class="heading text-type-2 mb-[40px]">
      Add Equipment
    </div>
  </ng-template>
  <div class="form">
    <app-old-equipment-format-type-form
      [data]="data?.equipmentDetails"
    />
  </div>
</div>

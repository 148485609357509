export default [
  {
    code: 1000,
    text: 'Sunny',
    icon: 113
  },
  {
    code: 1003,
    text: 'Partly cloudy',
    icon: 116
  },
  {
    code: 1006,
    text: 'Cloudy',
    icon: 119
  },
  {
    code: 1009,
    text: 'Overcast',
    icon: 122
  },
  {
    code: 1030,
    text: 'Mist',
    icon: 143
  },
  {
    code: 1063,
    text: 'Patchy rain possible',
    icon: 176
  },
  {
    code: 1066,
    text: 'Patchy snow possible',
    icon: 179
  },
  {
    code: 1069,
    text: 'Patchy sleet possible',
    icon: 182
  },
  {
    code: 1072,
    text: 'Patchy freezing drizzle possible',
    icon: 185
  },
  {
    code: 1087,
    text: 'Thundery outbreaks possible',
    icon: 200
  },
  {
    code: 1114,
    text: 'Blowing snow',
    icon: 227
  },
  {
    code: 1117,
    text: 'Blizzard',
    icon: 230
  },
  {
    code: 1135,
    text: 'Fog',
    icon: 248
  },
  {
    code: 1147,
    text: 'Freezing fog',
    icon: 260
  },
  {
    code: 1150,
    text: 'Patchy light drizzle',
    icon: 263
  },
  {
    code: 1153,
    text: 'Light drizzle',
    icon: 266
  },
  {
    code: 1168,
    text: 'Freezing drizzle',
    icon: 281
  },
  {
    code: 1171,
    text: 'Heavy freezing drizzle',
    icon: 284
  },
  {
    code: 1180,
    text: 'Patchy light rain',
    icon: 293
  },
  {
    code: 1183,
    text: 'Light rain',
    icon: 296
  },
  {
    code: 1186,
    text: 'Moderate rain at times',
    icon: 299
  },
  {
    code: 1189,
    text: 'Moderate rain',
    icon: 302
  },
  {
    code: 1192,
    text: 'Heavy rain at times',
    icon: 305
  },
  {
    code: 1195,
    text: 'Heavy rain',
    icon: 308
  },
  {
    code: 1198,
    text: 'Light freezing rain',
    icon: 311
  },
  {
    code: 1201,
    text: 'Moderate or heavy freezing rain',
    icon: 314
  },
  {
    code: 1204,
    text: 'Light sleet',
    icon: 317
  },
  {
    code: 1207,
    text: 'Moderate or heavy sleet',
    icon: 320
  },
  {
    code: 1210,
    text: 'Patchy light snow',
    icon: 323
  },
  {
    code: 1213,
    text: 'Light snow',
    icon: 326
  },
  {
    code: 1216,
    text: 'Patchy moderate snow',
    icon: 329
  },
  {
    code: 1219,
    text: 'Moderate snow',
    icon: 332
  },
  {
    code: 1222,
    text: 'Patchy heavy snow',
    icon: 335
  },
  {
    code: 1225,
    text: 'Heavy snow',
    icon: 338
  },
  {
    code: 1237,
    text: 'Ice pellets',
    icon: 350
  },
  {
    code: 1240,
    text: 'Light rain shower',
    icon: 353
  },
  {
    code: 1243,
    text: 'Moderate or heavy rain shower',
    icon: 356
  },
  {
    code: 1246,
    text: 'Torrential rain shower',
    icon: 359
  },
  {
    code: 1249,
    text: 'Light sleet showers',
    icon: 362
  },
  {
    code: 1252,
    text: 'Moderate or heavy sleet showers',
    icon: 365
  },
  {
    code: 1255,
    text: 'Light snow showers',
    icon: 368
  },
  {
    code: 1258,
    text: 'Moderate or heavy snow showers',
    icon: 371
  },
  {
    code: 1261,
    text: 'Light showers of ice pellets',
    icon: 374
  },
  {
    code: 1264,
    text: 'Moderate or heavy showers of ice pellets',
    icon: 377
  },
  {
    code: 1273,
    text: 'Patchy light rain with thunder',
    icon: 386
  },
  {
    code: 1276,
    text: 'Moderate or heavy rain with thunder',
    icon: 389
  },
  {
    code: 1279,
    text: 'Patchy light snow with thunder',
    icon: 392
  },
  {
    code: 1282,
    text: 'Moderate or heavy snow with thunder',
    icon: 395
  }

];

<ng-container *ngIf="initialized">
  <web-viewer
    [readOnly]="!editing"
    [user]="account"
    [documentUrl]="annotation?.drawing?.url"
    (annotationsChange)="annotationsChanged($event)"
    [xfdf]="annotation.xfdf"
    [masterAnnotId]="annotation?.group_annotation_id"
  ></web-viewer>
</ng-container>
<div class="custom-controls">
  <button mat-stroked-button (click)="onClose()" color="accent">Close</button>
  <button *ngIf="initialized" [disabled]="!updatedXfdf" mat-flat-button color="primary" (click)="save()">Save</button>
</div>

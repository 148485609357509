<ng-container *ngIf="initialized">

  <div class="container" *ngIf="isEmpty">
    <section>
      <div class="section-label">Weather not available</div>
    </section>
  </div>

  <div class="container" *ngIf="!isEmpty">
    <section>
      <div class="temp-display">
        <div class="mat-display-1">{{weather.avgtemp}}&deg;</div>
        <div class="">
          <app-icon icon="weather/{{weather.getIcon}}.png"></app-icon>
        </div>
      </div>
      <div class="mat-caption">Temperature in {{weather.location}}</div>
    </section>

    <section>
      <div class="section-label">Temperature:</div>
      <div class="row">
        <div class="mat-body">Highest: {{weather.maxtemp}}&deg;</div>
        <div class="mat-body">Lowest: {{weather.mintemp}}&deg;</div>
      </div>
    </section>

    <section>
      <div class="section-label">Sky condition:</div>
      <div class="mat-body">{{weather.condition?.text}}</div>
    </section>

    <section>
      <div class="section-label">Wind conditions(mph):</div>
      <div class="mat-body">{{weather.wind}}</div>
    </section>

    <section>
      <div class="section-label">Note:</div>
      <div class="mat-body">{{weather.notes}}</div>
    </section>
  </div>
</ng-container>

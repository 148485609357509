<div
  class="wrapper"
  #filterComponent
>
  <mat-tab-group [selectedIndex]="selectedTabIndex">
    <mat-tab
      *ngFor="let tab of data"
      [label]="tab.label"
      [class]="tab.category + '-tab legend-tab'"
    >
      <div class="legend-content">
        <mat-checkbox
          *ngFor="let eachCheckbox of tab.children"
          disableRipple
          [checked]="eachCheckbox.isSelected"
          (change)="handleClick(eachCheckbox.key, $event.checked)"
          color="primary"
        >
          <div class="checkbox-label-container">
            <mat-icon
              *ngIf="eachCheckbox.icon; else noIcon"
              [svgIcon]="eachCheckbox.icon"
            />
            <ng-template #noIcon>
              <div class="legend-color-block"></div>
            </ng-template>

            <div class="checkbox-la">{{ eachCheckbox.label }}</div>
          </div>
        </mat-checkbox>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

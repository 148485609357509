import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment';
import { Issue, Project, Site } from 'src/app/shared/models';

@Component({
  selector: 'app-report-issues',
  templateUrl: './report-issues.component.html',
  styleUrls: ['./report-issues.component.scss']
})
export class ReportIssuesComponent implements OnInit, OnChanges {

  public initialized: boolean = false;
  @Input() public project: Project;
  @Input() public site: Site;
  @Input() public issues: Issue[] = [];
  @Input() public date: moment.Moment;

  @Output() bubbleClick = new EventEmitter<Issue>();

  public open: Issue[] = [];
  public closedToday: Issue[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.open = this.issues.filter((o: Issue) => {
      return o.status === 'open'
             || (o.status === 'closed' && moment(o.closed_at).isAfter(this.date, 'd'));
    });
    this.closedToday = this.issues.filter((o: Issue) => o.status === 'closed'
                                                        && moment(o.closed_at).isSame(this.date, 'd'));
    this.initialized = true;
  }

  /**
   * click event bubble
   * @param issue Issue
   */
  onClick(issue: Issue) {
    this.bubbleClick.emit(issue);
  }

}

<section [formGroup]="form">
  <div class="mat-subheading-1">
    {{field.label}}<span style="color: red;" *ngIf="isRequired">*</span>
  </div>
  <mat-form-field [appearance]="appearance" [class.custom-hints]="isValid && outOfRange">
    <ng-container [ngSwitch]="field.type">
      <ng-container *ngSwitchCase="'number'">
        <input
          onlyDigits
          matInput
          type="string"
          autocomplete="off"
          [formControlName]="field.id"
          [errorStateMatcher]="matcher"
        />
      </ng-container>

      <ng-container *ngSwitchCase="'string'">
        <input matInput type="text" autocomplete="off" [formControlName]="field.id" [errorStateMatcher]="matcher" />
      </ng-container>

      <ng-container *ngSwitchCase="'textarea'">
        <textarea matInput rows="4" [formControlName]="field.id" [errorStateMatcher]="matcher"></textarea>
      </ng-container>
    </ng-container>

    <span class="mat-subheading-1" matSuffix *ngIf="field?.uom && isValid && !outOfRange">({{field?.uom}})</span>

    <mat-icon *ngIf="!isValid" matSuffix color="warn">error</mat-icon>
    <mat-error *ngIf="!isValid">{{getErrorMessage()}}</mat-error>

    <mat-hint *ngIf="isValid && !outOfRange && hint?.value" align="start">
      <ng-container [ngSwitch]="hint?.type">
        <ng-container *ngSwitchCase="'estimate'">Estimate: {{hint.value}}</ng-container>
        <ng-container *ngSwitchCase="'range'">
          <ng-container *ngIf="Util.isNumeric(hint.value?.min) && Util.isNumeric(hint.value?.max)">Range: {{hint.value?.min}} - {{hint.value?.max}}</ng-container>
          <ng-container *ngIf="Util.isNumeric(hint.value?.min) && !Util.isNumeric(hint.value?.max)">Range min: {{hint.value?.min}}</ng-container>
          <ng-container *ngIf="Util.isNumeric(hint.value?.max) && !Util.isNumeric(hint.value?.min)">Range max: {{hint.value?.max}}</ng-container>
        </ng-container>
      </ng-container>
    </mat-hint>
    <mat-hint *ngIf="!outOfRange && prev" align="end">Previous: {{prev}}</mat-hint>

    <mat-hint *ngIf="isValid && outOfRange">
      <ng-container *ngIf="Util.isNumeric(range.min) && Util.isNumeric(range.max)">
        Out of range, value should be in between {{range.min}} and {{range.max}}
      </ng-container>
      <ng-container *ngIf="Util.isNumeric(range.min) && !Util.isNumeric(range.max)">
        Out of range, value should be greater than or equal to {{range.min}}
      </ng-container>
      <ng-container *ngIf="!Util.isNumeric(range.min) && Util.isNumeric(range.max)">
        Out of range, value should be lesser than or equal to {{range.max}}
      </ng-container>
    </mat-hint>
    <mat-icon *ngIf="isValid && outOfRange" matSuffix class="hint-icon">warning</mat-icon>

  </mat-form-field>
</section>

<div
  class="wrapper"
>
  <ng-container
    *ngIf="paginatedFilteredSites?.length; else noData"
  >
    <ng-container
      *ngFor="let site of paginatedFilteredSites;"
    >
      <app-site-reports-panel [site]="site" />
    </ng-container>
  </ng-container>
  <ng-template #noData>
    <div class="no-data flex justify-center items-center">
      No sites available for the current selection.
    </div>
  </ng-template>
  <app-paginator
    (pageChanged)="onPageChanged($event)"
    [itemsPerPage]="itemsPerPage"
    [totalItems]="filteredSites?.length"
    class="paginator"
  >
  </app-paginator>
</div>

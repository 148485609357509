import { BaseModel } from './base-model';
import { Account} from './account';
import { WorkflowState } from './workflow-state';

export class WorkflowLineage extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'WorkflowLineage'; }

  /* tslint:disable */
  ip: string;
  user_agent: string;
  ex: string;
  state: WorkflowState;
  actor: Account;
  /* tslint:enable */

  get relations() {
    return {
      state: WorkflowState,
      actor: Account,
    };
  }
}

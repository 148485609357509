import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Drawing, Project } from 'src/app/shared/models';
import { DrawingService } from 'src/app/shared/services';

@Component({
  selector: 'app-drawing-selector',
  templateUrl: './drawing-selector.component.html',
  styleUrls: ['./drawing-selector.component.scss']
})
export class DrawingSelectorComponent implements OnInit {
  @Input() project: Project;
  @Input() selectedDrawing: Drawing = new Drawing();

  private isDialog: boolean = false;
  public loading: number = 0;
  public initialized: boolean = false;

  public drawings: Drawing[] = [];

  constructor(
    private drawingService: DrawingService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DrawingSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any
  ) { }

  ngOnInit(): void {
    const { project, selectedDrawing } = this.inputData;
    if (project) {
      this.isDialog = true;
      this.project = project;
      this.selectedDrawing = selectedDrawing;
    }

    this.fetchRecords();
  }

  /**
   * Fetches project drawings
   */
  fetchRecords(): void {
    this.loading++;

    this.drawingService.getRecords(this.project, { pageSize: 10000, pageIndex: 0 })
    .subscribe({
      next: (resp: any) => {
        this.drawings = resp.result;
        this.initialized = true;
        this.loading--;
      },
      error: (err: HttpErrorResponse) => {
        this.loading--;
        this.initialized = true;

        this.snackBar.open(err.message, '', { duration: 5000 });
      },
    });
  }

  /**
   * close component on drawing selection
   * @param drawing Drawing
   */
  onSelected(drawing: Drawing): void {

    if (this.isDialog) {
      this.dialogRef.close(drawing);
    }
    // Todo: emit output event
  }
}

<div class="container">

  <nav mat-tab-nav-bar class="sub-nav" *ngIf="editing">
    <a mat-tab-link active="active">
      <ng-container *ngIf="field?.id">Update {{field?.label}}</ng-container>
      <ng-container *ngIf="!field?.id">Add field</ng-container>
    </a>
  </nav>
  <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- Fields listing -->
  <div class="row">
    <nav mat-tab-nav-bar class="sub-nav">
      <a mat-tab-link active="active">
        <span class="icon material-symbols-outlined" title="Fields">
          tune
          <span class="badge" color="accent" *ngIf="fields?.length">
            {{fields.length}}
          </span>
        </span>
      </a>
    </nav>
    <div class="icons">
      <button mat-icon-button aria-label="Add field" color="none" [disabled]="!canEdit"
        (click)="toggleForm(null, true)">
        <mat-icon title="Add">add</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!fields.length">
    No fields found for this {{entity?.modelName}}
    <button mat-button color="accent" class="mat-inline-button" [disabled]="!canEdit" (click)="toggleForm(null, true)"
      aria-label="Add" title="Add">
      Add field?</button>

  </div>

  <div class="list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div class="list-card" cdkDrag *ngFor="let record of fields">
      <div class="hover-icons">
        <a mat-icon-button [disabled]="!canEdit" (click)="toggleForm(record, true)" color="none" aria-label="Edit"
          title="Edit">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button [disabled]="!canEdit" (click)="delete(record)" color="warn" aria-label="Delete"
          title="Delete">
          <mat-icon>delete</mat-icon>
        </a>
      </div>

      <div class="body">
        <div class="mat-subheading-2">
          {{record.label}} <span class="mat-caption">{{record.name}}</span>
        </div>
        <div class="mat-caption row">
          <span class="mat-body-strong">
            <span style="text-transform: capitalize;">{{record.type}}</span>
            <span *ngIf="record.uom"> ({{record.uom}})</span>
          </span>
          <span *ngIf="record.estimate_station_property">
            Est: {{record.estimate_station_property}}
          </span>
        </div>
        <div class="tags muted">
          <div class="tag" *ngIf="record.is_optional">Optional</div>
          <div class="tag" *ngIf="record.validation_rules?.required">Required</div>
          <div class="tag" *ngIf="record.validation_rules?.min">
            Min: {{record.validation_rules?.min}}
          </div>
          <div class="tag" *ngIf="record.validation_rules?.max">
            Max: {{record.validation_rules?.max}}
          </div>
          <div class="tag" *ngIf="hasRange(record.range_values)">
            Range: {{record.range_values?.min}} - {{record.range_values?.max}}
          </div>
          <div class="tag" *ngIf="record.recent_field">
            Recent field: <b>{{record.recent_field?.label}}</b>
            <span *ngIf="record.recent_field_label"> ({{record.recent_field_label}})</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Fields listing -->
</ng-template>


<ng-template #InputFormContent>
  <!-- Field Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!editing">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
      <input matInput type="number" formControlName="display_order" />
    </section>
    <!-- label section -->
    <section>
      <mat-form-field>
        <mat-label>Label</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="label" (blur)="onLabelChange($event)"
          [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.label.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.label.hasError('error')">
          {{inputForm.controls.label.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /label section -->

    <!-- name section -->
    <section>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.name.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.name.hasError('error')">
          {{inputForm.controls.name.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /name section -->

    <!-- type section -->
    <section>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select formControlName="type" [errorStateMatcher]="matcher">
          <mat-option *ngFor="let type of fieldTypes" [value]="type">
            {{type}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.controls.type.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.type.hasError('error')">
          {{inputForm.controls.type.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /name section -->

    <!-- uom section -->
    <section>
      <mat-form-field>
        <mat-label>UOM</mat-label>
        <input matInput type="text" autocomplete="off" formControlName="uom" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.uom.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.uom.hasError('error')">
          {{inputForm.controls.uom.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /uom section -->

    <!-- is_optional section -->
    <div class="row">
      <div class="mat-body">Is Optional</div>
      <div class="icons">
        <mat-slide-toggle formControlName="is_optional"></mat-slide-toggle>
        <mat-error *ngIf="inputForm.get('is_optional').hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.get('is_optional').hasError('error')">
          {{inputForm.get('is_optional').errors.error}}
        </mat-error>
      </div>
    </div>
    <!-- /is_optional section -->

    <!-- validation rules -->
    <section formGroupName="validation_rules">
      <div class="row">
        <div class="mat-subheading-1">Validation</div>
      </div>

      <!-- required section -->
      <div class="row">
        <div class="mat-body">Required</div>
        <div class="icons">
          <mat-slide-toggle formControlName="required"></mat-slide-toggle>
          <mat-error *ngIf="inputForm.get('validation_rules.required').hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.get('validation_rules.required').hasError('error')">
            {{inputForm.get('validation_rules.required').errors.error}}
          </mat-error>
        </div>
      </div>
      <!-- /required section -->
      <!-- min section -->
      <div>
        <mat-form-field>
          <mat-label>Min</mat-label>
          <input matInput type="number" autocomplete="off" formControlName="min" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.get('validation_rules.min').hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.get('validation_rules.min').hasError('error')">
            {{inputForm.get('validation_rules.min').errors.error}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- /min section -->
      <!-- max section -->
      <div>
        <mat-form-field>
          <mat-label>Max</mat-label>
          <input matInput type="number" autocomplete="off" formControlName="max" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.get('validation_rules.max').hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.get('validation_rules.max').hasError('error')">
            {{inputForm.get('validation_rules.max').errors.error}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- /max section -->

    </section>
    <!-- /validation rules -->

    <!-- range_values -->
    <section formGroupName="range_values" [class.hide]="!recordRange">
      <div class="row">
        <div class="mat-subheading-1">Range</div>
      </div>

      <!-- min section -->
      <div>
        <mat-form-field>
          <mat-label>Min</mat-label>
          <input matInput type="number" autocomplete="off" formControlName="min" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.get('range_values.min').hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.get('range_values.min').hasError('error')">
            {{inputForm.get('range_values.min').errors.error}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- /min section -->
      <!-- max section -->
      <div>
        <mat-form-field>
          <mat-label>Max</mat-label>
          <input matInput type="number" autocomplete="off" formControlName="max" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.get('range_values.max').hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.get('range_values.max').hasError('error')">
            {{inputForm.get('range_values.max').errors.error}}
          </mat-error>
        </mat-form-field>
      </div>
      <!-- /max section -->

    </section>
    <!-- /range_values -->

    <!-- estimate_station_property section -->
    <section [class.hide]="!stationProperties?.length">
      <mat-form-field>
        <mat-label>Est Station Property</mat-label>
        <mat-select formControlName="estimate_station_property" [errorStateMatcher]="matcher">
          <mat-option>Select station property</mat-option>
          <mat-option *ngFor="let prop of stationProperties" [value]="prop">
            <div>{{prop}}</div>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.controls.estimate_station_property.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.estimate_station_property.hasError('error')">
          {{inputForm.controls.estimate_station_property.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /estimate_station_property section -->

    <!-- recent_field section -->
    <section [class.hide]="!captureRecentField">
      <mat-form-field>
        <mat-label>Recent field</mat-label>
        <mat-select formControlName="recent_field_id" [errorStateMatcher]="matcher">
          <mat-option>Select recent field</mat-option>
          <mat-option *ngFor="let record of fields" [value]="record.id">
            <div>{{record.label}}</div>
          </mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.controls.recent_field_id.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.recent_field_id.hasError('error')">
          {{inputForm.controls.recent_field_id.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /recent_field section -->

    <!-- recent_field_label section -->
    <section [class.hide]="!captureRecentField">
      <mat-form-field>
        <mat-label>Recent field label</mat-label>
        <input matInput autocomplete="off" formControlName="recent_field_label" [errorStateMatcher]="matcher" />
        <mat-error *ngIf="inputForm.controls.recent_field_label.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.recent_field_label.hasError('error')">
          {{inputForm.controls.recent_field_label.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /recent_field_label section -->

    <section>&nbsp;</section>
    <!-- Form Actions -->
    <section>
      <div class="row" *ngIf="editing">
        <button mat-stroked-button color="accent" [disabled]="!canEdit" (click)="save(inputForm)">
          <ng-container *ngIf="field?.id">Update</ng-container>
          <ng-container *ngIf="!field?.id">Create</ng-container>
        </button>
        <button mat-stroked-button (click)="onCancel()" [disabled]="!canEdit">Cancel</button>
      </div>
    </section>
    <!-- Form Actions -->
  </form>
  <!-- /Input form -->
</ng-template>
/* NgRx */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectsState } from './projects.reducer';

// Selector functions
const projectsData = createFeatureSelector<ProjectsState>('sitenotes.projects');

export const projectsList = createSelector(projectsData, state => state.projectsList);

export const projectsFilterList = createSelector(projectsData, (state) => state.projectsFilterList);

export const sitesFilterList = createSelector(projectsData, (state, props) => state.sitesFilterData[props.projectId]);

export const projectMembersFilterList = createSelector(projectsData, (state) => state.projectMembersFilterList);

export const projectDetails = createSelector(projectsData, (state) => state.projectDetails);

export const siteWiseDailyReportsSummary = createSelector(projectsData, (state) => state.siteWiseDailyReportsSummary);

export const existingReportId = createSelector(projectsData, (state) => state.existingReportId);

export const projectReportsList = createSelector(projectsData, (state) => state.projectReportsList);

export const projectReport = createSelector(projectsData, (state) => state.projectReport);

export const bidSheet = createSelector(projectsData, (state) => state.bidSheet);

export const bidItemsListInfo = createSelector(projectsData, (state) => state.bidItemsListInfo);

export const bidItemsSiteListInfo = createSelector(projectsData, (state) => state.bidItemsSiteListInfo);

export const changeOrderFilter = createSelector(projectsData, (state) => state.changeOrderFilter);

export const changeOrderFilterInSideNav = createSelector(projectsData, (state) => state.changeOrderFilterInSideNav);

export const changeOrderFilterInContextWithBidItems = createSelector(projectsData, (state) => state.changeOrderFilterInContextWithBidItems);

export const siteDetails = createSelector(projectsData, (state) => state.siteDetails);

export const dailyReportsByDate = createSelector(projectsData, (state) => state.dailyReportsByDate);

export const dailyReport = createSelector(projectsData, (state) => JSON.parse(JSON.stringify(state.dailyReport)));

export const selectPayappItems = createSelector(projectsData, state => state.payappItems);

export const selectPayapps = createSelector(projectsData, state => state.payapps);

export const allBidItems = createSelector(projectsData, (state) => state.allBidItems);

export const allBidItemsFilter = createSelector(projectsData, (state) => state.allBidItemsFilter);

export const issueTypesLabelMap = createSelector(projectsData, (state) => state.issueTypesLabelMap);

export const issueTypesFilter = createSelector(projectsData, (state) => state.issueTypesFilter);
export const qpForMap = createSelector(projectsData, (state) => state.qpForMap);

export const consolidatedProjectData = createSelector(projectsData, (state) => state.consolidatedProjectData);

export const subContractorFilter = createSelector(projectsData, (state) => state.subContractorFilter);

export const laborFilter = createSelector(projectsData, (state) => state.laborFilter);

export const equipmentFilter = createSelector(projectsData, (state) => state.equipmentFilter);

export const currentLocation = createSelector(projectsData, (state) => state.currentLocation);

import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment as ENV } from 'src/environments/environment';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AppService } from '../../services';
import { DailyReportService } from '../../../modules/projects/pages/site-details-page/tab-screens/daily-report/daily-report.service';

@Component({
  selector: 'app-location-picker',
  templateUrl: './location-picker.component.html',
  styleUrls: ['./location-picker.component.scss'],
})
export class LocationPickerComponent implements OnInit, OnChanges {

  @Input() public latitude: number;
  @Input() public longitude: number;

  @Input() public readOnly: boolean = true;
  @Input() public isDialog: boolean = true;

  @Input() public height: string = '100px';
  @Input() public width: string = '100%';

  @Output() locationChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  public apiLoaded: Observable<boolean>;
  public mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    // zoomControl: false,
    streetViewControl: false,
    width: '100%',
    height: '100px',
    zoom: 16,
    fields: ['geometry'],
  };

  public center: any = {};
  public resp: any;
  public modified: boolean = false;
  public marker: any;
  public defaultIcon = {
    // Todo replace with square svg path
    path: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z',
    fillColor: '#1036d6',
    fillOpacity: 1,
    strokeWeight: 0,
  };
  private originalData: string = '{}';

  constructor(
    private appService: AppService,
    private dailyReportService: DailyReportService,
    public dialogRef: MatDialogRef<LocationPickerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    httpClient: HttpClient,
  ) {
    if (!window?.google?.maps) {
      this.apiLoaded =
        httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?libraries=places&key=${ENV.GOOGLE_MAPS_API_KEY}`, 'callback')
          .pipe(
            map(() => true),
            catchError(() => of(false)),
          );
    } else {
      this.apiLoaded = of(true);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.redraw(true);
    }
  }

  ngOnInit(): void {

    if (this.isDialog && this.data) {
      const { latitude, longitude, height = '400px', width = '500px', readOnly = true } = this.data;
      this.latitude = latitude || this.latitude;
      this.longitude = longitude || this.longitude;
      this.height = height;
      this.width = width;
      this.readOnly = readOnly;
    }

    this.mapOptions = Object.assign(this.mapOptions, {
      height: this.height,
      width: this.width,
      styles: this.appService.getMapStyles(),
    });

    this.originalData = JSON.stringify({
      latitude: this.latitude,
      longitude: this.longitude,
    });

    const input = this.searchInput.nativeElement as HTMLInputElement;
    if (!this.readOnly) {
      const autocomplete = new window.google.maps.places.Autocomplete(input, this.mapOptions);
      window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        const { lat, lng } = place.geometry?.location;
        this.center = { lat: lat(), lng: lng() };
        this.onMapClick({ latLng: { lat, lng } });
      });
    }

    this.redraw(true);
  }

  getDefaultCoords() {
    // Todo: fetch user location from browser
    return { lat: 30.26798, lng: -97.74083 };
  }

  /**
   * Redraw markers and center the map
   * @param center boolean specify show map be centered
   */
  redraw(center: boolean = false) {

    const { lat, lng } = this.getDefaultCoords();

    if (center) {
      this.center = { lat: +this.latitude || +lat, lng: +this.longitude || +lng };
    }

    this.marker = {
      position: { lat: +this.latitude || +lat, lng: +this.longitude || +lng },
      options: { icon: this.defaultIcon },
    };
  }

  /**
   * callback for mapclick
   * @param event MapMouseEvent
   */
  onMapClick(event: any) {
    if (this.readOnly) {
      return;
    }

    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
    this.modified = true;

    this.redraw();

    this.resp = {
      latitude: this.latitude,
      longitude: this.longitude,
    };

  }

  /**
   * Resets map location
   */
  cancel() {
    const { latitude, longitude } = JSON.parse(this.originalData);
    this.latitude = latitude;
    this.longitude = longitude;
    this.modified = false;

    this.redraw(true);

    if (this.isDialog) {
      this.dialogRef.close();
    }
  }

  /**
   * Close component and emit data
   * @param resp updated data
   */
  close(resp: any = null) {
    this.dailyReportService.selectedLocation.next(resp || this.resp);
    if (this.isDialog) {
      this.dialogRef.close(resp || this.resp);
    } else {
      this.locationChange.emit(resp || this.resp);
    }
  }

}

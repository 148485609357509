import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
} from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {
  DailyReport,
  Project,
  ProjectReport,
  Site,
} from 'src/app/shared/models';
import {
  AppService,
  ProjectReportService,
  ProjectService,
} from 'src/app/shared/services';
import * as moment from 'moment';
import { switchMap, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { Ability } from '@casl/ability';
import { ProjectMapService } from '../project-map/project-map.service';

@Component({
  selector: 'app-project-report',
  templateUrl: './project-report.component.html',
  styleUrls: [
    './project-report.component.scss', '../../../material/material.css',
  ],
})
export class ProjectReportComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  public loading = 0;
  public initialized = false;
  public project: Project;

  public reportDate: any;
  public pickedDate: any;
  public dates: any[];

  public projectReport: ProjectReport;
  public dailyReports: DailyReport[] = [];
  public allDRsApproved = false;
  public showConsolidatedReport = false;

  sitesList: Site[] = [];
  mapLayers: any[] = [];

  mapFullScreen = false;

  /**
   * Filter dates to show in date-picker
   * @param d Date
   */
  public projectDates = (d: Date | null): boolean => {
    const { start_date, end_date } = this.project;
    return moment(d).isBetween(start_date, end_date, 'day', '[]');
  }

  constructor(
    private ability: Ability,
    private appSrv: AppService,
    private prSrv: ProjectReportService,
    private projectService: ProjectService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private projectMapService: ProjectMapService,
    private cdRef: ChangeDetectorRef,
  ) {
    this.router.events
      .pipe(takeUntil(this.onDestroy))
      .subscribe(value => {
        if (value instanceof NavigationEnd) {
          this.mapFullScreen = false;
          this.cdRef.detectChanges();
        }
      });

    this.projectMapService.closeMap
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.mapFullScreen = false;
        this.cdRef.detectChanges();
      });
  }

  ngOnInit(): void {
    // if no date is provided, take current day
    if (!this.route.snapshot.paramMap.has('date')) {
      this.router.navigate([moment().format('YYYY-MM-DD')], {
        relativeTo: this.route,
      });
      return;
    }

    if (this.route.snapshot.paramMap.has('id')) {
      const projectId = this.route.snapshot.paramMap.get('id');
      this.getProject(projectId);
      this.projectService.getMapLayers(projectId)
        .then((data) => {
          this.mapLayers = data;
        })
        .catch((error) => {
          this.mapLayers = [];
        });
    }

    // read report-date from url or set current date
    this.route.paramMap
      .pipe(switchMap((params) => of(params.get('date'))))
      .subscribe((v) => {
        this.reportDate = v ? moment(v) : moment();
        this.pickedDate = new Date(this.reportDate.format('YYYY-MM-DD'));

        // Take around dates of report-date between start_date and end_date
        this.dates = this.appSrv.getNearDates(
          2,
          this.reportDate,
          moment(this.project?.start_date),
          moment(this.project?.end_date),
        );

        if (this.initialized) {
          this.fetchProjectReport();
        }
      });
  }

  /**
   * callback after date is picked from date-picker
   * @param e Date
   */
  datePicked(e) {
    // navigate to picked date
    this.router.navigate(['../', moment(e).format('YYYY-MM-DD')], {
      relativeTo: this.route,
    });
  }

  /**
   * Fetches project and assigns to project
   * @param id Project.id
   */
  getProject(id: string) {
    this.projectService
      .getRecord(id, {
        include: [
          'cover_photo',
          'owner_organization',
          'delivery_tracking_fields',
          'materials',
          {
            sites: [
              'delivery_tracking_fields',
              {
                headings: ['stations']
              }
            ]
          },
          {
            bid_items: [
              'rollup_field',
              {
                fields: ['recent_field']
              },
              {
                headings: ['stations', 'alignment']
              },
              'materials'
            ]
          },
          {
            internal_tests: ['bid_item_ids', 'site_ids', 'fields']
          }
        ],
      })
      .subscribe({
        next: (project: Project) => {
          this.project = project;
          this.projectReport = new ProjectReport({
            report_date: this.reportDate.format('YYYY-MM-DD'),
            project: this.project,
          });

          // to check create permissions
          this.project.sites.map((site: any) => {
            site.newDR = new DailyReport({ site, project });
          });

          this.sitesList = this.project.sites;

          // Take around dates of report-date between start_date and end_date
          this.dates = this.appSrv.getNearDates(
            2,
            this.reportDate,
            moment(this.project?.start_date),
            moment(this.project?.end_date),
          );

          this.initialized = true;
          this.fetchProjectReport();
        },
        error: (err: any) => {
          if (err.status === 403) {
            this.router.navigate(['/page-404'], {
              queryParams: { url: this.router.url },
            });
            return;
          }

          console.log(err);
          // todo show some error message
        },
      });
  }

  /**
   * Fetches project report for given date in url
   */
  fetchProjectReport() {
    // Reset data to display data for another date
    this.showConsolidatedReport = false;
    this.allDRsApproved = false;
    // this.projectReport = null;
    this.dailyReports = [];

    const qp = {
      date: this.reportDate.format('YYYY-MM-DD'),
      total: 1,
    };

    this.loading++;
    this.projectService
      .getReports(this.project, qp)
      .then((resp: any) => {
        this.fetchDailyReports();
        // Project report might not be created yet.
        if (!resp.result.length) {
          return;
        }

        this.projectReport = resp.result[0];
        this.projectReport.project_id = this.project?.id;
        this.allDRsApproved = true; // report is already submitted
      })
      .catch((errResp) => {
        const msg =
          errResp.status === 404
            ? 'Unable to fetch project report, Please check logs'
            : errResp.error.error;
        this.snackBar.open(msg, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Fetches daily-report for reportDate
   */
  fetchDailyReports() {
    const qp = {
      date: this.reportDate.format('YYYY-MM-DD'),
      include: [
        'statusText',
        'site',
        'project',
        {
          bid_items: [
            'pictures',
            {
              bid_item: [
                'consumed',
                'created_at',
                'description',
                'id',
                'item',
                'quantity',
                'record_by_station',
                'rollup',
                'rollup_field',
                'rollup_formula',
                'rollup_quantity_editable',
                'track_material_quantity',
                'unit_price',
                'uom',
                'updated_at',
                'fields',
              ],
            },
            'station',
            'heading',
            'field_values',
            { annotations: ['drawing'] },
          ],
        },
        {
          material_deliveries: [
            'pictures',
            'tickets',
            'bid_item',
            'material',
            'field_values',
            'fields',
            { annotations: ['drawing'] },
          ],
        },
        { activities: ['pictures', 'address', { annotation: ['drawing'] }] },
        {
          issues: [
            'entity',
            'pictures',
            'attachments',
            'bid_item',
            'annotation',
            { createdBy: ['picture'] },
            { closedBy: ['picture'] },
          ],
        },
        {
          labor: [
            'sub_contractor',
            'bid_item',
            'job_code',
            'labor',
            'equipment',
            'field_values',
            'fields',
            'pictures',
            { annotations: ['drawing'] },
          ],
        },
        {
          equipment: [
            'sub_contractor',
            'bid_item',
            'equipment_type',
            'field_values',
            'pictures',
            { annotations: ['drawing'] },
            {
              equipment: [
                'description',
                'id',
                'make',
                'model',
                'number',
                'fields',
              ],
            },
          ],
        },
        {
          internal_tests: [
            'pictures',
            'bid_item',
            'station',
            'heading',
            'field_values',
            { annotations: ['drawing'] },
            {
              internal_test: [
                'created_at',
                'id',
                'issue_type',
                'name',
                'record_by_station',
                'status',
                'updated_at',
                'fields',
              ],
            },
          ],
        },
        { lastApprovalAction: ['state', 'actor'] },
        { createdBy: ['picture'] },
        'next-actions',
      ],
    };

    this.projectService.getDailyReports(this.project, qp)
      .subscribe({
      next: (resp: any) => {
        this.dailyReports = resp.result;

        this.allDRsApproved =
          this.dailyReports.length &&
          this.dailyReports.length ===
          this.dailyReports.filter((o) => o.status === 'approved').length;

        // If any site has no daily-reports, project report shouldn't be shown.
        this.project.sites.map((site) => {
          if (
            this.dailyReports.findIndex((dr) => dr.site.id === site.id) === -1
          ) {
            this.allDRsApproved = false;
          }
        });

        if (this.allDRsApproved) {
          // if all daily-reports are approved, create project-report
          this.saveReport();
        }
      },
      error: (err: any) => {
        console.log(err);
        // todo show some error message
      },
    });
  }

  /**
   * Create|Save Project Report
   */
  public saveReport() {
    if (
      this.projectReport?.id ||
      !this.ability.can('create', this.projectReport)
    ) {
      return;
    }

    const payload = {
      report_date: this.reportDate.format('YYYY-MM-DD'),
    };

    this.projectService
      .saveReport(this.project, payload)
      .then((projectReport: ProjectReport) => {
        this.projectReport = projectReport;
      })
      .catch((e: any) => {
        this.snackBar.open(e.error.error, '', { duration: 5000 });
      });
  }

  /**
   * Callback after project-report workflow action is performed
   * @param wfResp any
   */
  projectReportChanged(wfResp: any) {
    if (wfResp.data?.id) {
      this.prSrv.getRecord(wfResp.data.id).then((pr: ProjectReport) => {
        this.projectReport = pr;
        this.projectReport.project_id = this.project?.id;
        this.dailyReports = this.projectReport.dailyReports;
        this.allDRsApproved = true; // report is already submitted
      });
    }
  }

  toggleMapFullScreen() {
      this.mapFullScreen = !this.mapFullScreen;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Alignment, Heading } from 'src/app/shared/models';
import { AlignmentService, AppErrorStateMatcher, HeadingService } from 'src/app/shared/services';

@Component({
  selector: 'app-alignment-headings',
  templateUrl: './alignment-headings.component.html',
  styleUrls: ['./alignment-headings.component.scss']
})
export class AlignmentHeadingsComponent implements OnInit {

  public loading = 0;
  public initialized = false;
  public showInputForm = false;

  @Input() alignment: Alignment;
  public headings: Heading[];

  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcInputForm') vcInputForm;
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    alignment_id: [''],
    name: ['', [Validators.required]],
    starts_from: ['', [Validators.required]],
    ends_at: ['', [Validators.required]],
    direction: ['', [Validators.required]],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private alignmentSrv: AlignmentService,
    private headingSrv: HeadingService
  ) { }

  ngOnInit(): void {
    this.fetchRecord(this.alignment);
  }

  fetchRecord(alignment: Alignment): void {
    this.alignmentSrv.getRecord(alignment.id, { include: ['headings'] })
    .then((resp: Alignment) => {
      this.headings = resp.headings;
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
    })
    .finally(() => {
      this.initialized = true;
    });
  }

  /**
   * Toggles input form
   * @param model Alignment
   * @param show boolean
   */
  toggleInputForm(model: Heading = null, show = true): void {
    if (show) {
      this.vcInputForm?.nativeElement?.reset();
      this.inputForm.reset({
        id: model?.id || null,
        alignment_id: model?.alignment_id || this.alignment?.id || null,
        name: model?.name || null,
        starts_from: model?.starts_from,
        ends_at: model?.ends_at,
        direction: model?.direction,
      });
    }
    this.showInputForm = show;
  }

  /**
   * onSubmit() handler for station form
   * @param form FormGroup
   * @returns {void}
   */
  save(form: UntypedFormGroup): void {
    if (!form.valid) {
      return;
    }
    const payload = form.value;
    const req = payload?.id
      ? this.headingSrv.update(payload, { include: [] })
      : this.headingSrv.create(payload, { include: [] });

    req
      .then((heading: Heading) => {
        if (form) {
          this.snackBar.open(
            `${payload?.id ? 'Updated' : 'Created'} heading "${heading?.name}"`,
            '',
            { duration: 5000 },
          );
        }

        const i = this.headings.findIndex(o => o.id === heading.id);
        if (i >= 0) {
          this.headings.splice(i, 1, heading);
        } else {
          this.headings.push(heading);
          this.headings.sort((a, b) => {
            const p = a.name?.toUpperCase();
            const q = b.name?.toUpperCase();
            return p < q ? -1 : p > q ? 1 : 0;
          })
        }

        this.toggleInputForm(null, false);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes Heading
   * @param model
   */
  delete(model: Heading): void {
    const message = `Deleting <b>${model.name}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.headingSrv.delete(model)
        .then((resp: any) => {
          this.headings.splice(
            this.headings.findIndex(o => o.id === model.id),
            1,
          );
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
      });
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { Account } from 'src/app/shared/models';

@Component({
  selector: 'app-account-inline',
  templateUrl: './account-inline.component.html',
  styleUrls: ['./account-inline.component.scss']
})
export class AccountInlineComponent implements OnInit {

  @Input() public account: Account;

  public initialized: boolean = false;
  public lastChar: string;

  constructor() { }

  ngOnInit(): void {
    if (this.account) {
      this.initialized = true;
    }
  }

}

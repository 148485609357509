<div class="wrapper">
  <!--  WEATHER  -->
  <app-consolidated-report-expandable-block
    (click)="handleExpansion('weather')"
    [isExpanded]="expandedSection === 'weather'"
    [subheading]="weatherList.length + ' item' + (weatherList.length === 1 ? '' : 's')"
    heading="Weather"
    icon="daily-report-weather"
    id="cpr-weather"
  >
    <ng-container>
      <ng-container *ngIf="weatherList && weatherList.length > 0; else noWeatherData">
        <div (click)="$event.stopPropagation()"
             [class.collapsed]="!isWeathersPanelExpanded && weatherList.length > 3"
             class="panel weather-panel"
        >
          <ng-container *ngFor="let weather of weatherList">
            <app-consolidated-weather-summary-card
              (click)="triggerOpenDetailsPanel('weather', weather)"
              [data]="weather" />
          </ng-container>
        </div>
        <div
          (click)="isWeathersPanelExpanded = !isWeathersPanelExpanded; $event.stopPropagation()"
          *ngIf="weatherList?.length > 3"
          class="see-more text-type-9 flex"
        >
          {{ isWeathersPanelExpanded ? 'See less' : 'See more' }}
        </div>
      </ng-container>
      <ng-template #noWeatherData>
        <div class="no-data">
          No weather data available.
        </div>
      </ng-template>
    </ng-container>
  </app-consolidated-report-expandable-block>

  <hr class="divider" />

  <!--  BID ITEMS  -->
  <app-consolidated-report-expandable-block
    (click)="handleExpansion('bid-item')"
    [isExpanded]="expandedSection === 'bid-item'"
    [subheading]="bidItems?.length + ' item' + (bidItems?.length !== 1 ? 's' : '')"
    heading="Bid item"
    icon="daily-report-bid-item"
    id="cpr-bid-item"
  >
    <ng-container>
      <ng-container *ngIf="bidItems && bidItems?.length > 0; else noBidItemData">
        <div (click)="$event.stopPropagation()" class="bid-items-panel">
          <app-bid-items-expandable-row-table [data]="bidItems" />
        </div>
      </ng-container>
      <ng-template #noBidItemData>
        <div class="no-data">
          No bid item data available.
        </div>
      </ng-template>
    </ng-container>
  </app-consolidated-report-expandable-block>

  <hr class="divider" />

  <app-consolidated-report-expandable-block
    (click)="handleExpansion('material-delivery')"
    [isExpanded]="expandedSection === 'material-delivery'"
    [subheading]="materialDeliveryCount + ' item' + (materialDeliveryCount === 1 ? '' : 's')"
    heading="Material delivery"
    icon="daily-report-material-delivery"
    id="cpr-material-delivery"
  >
    <ng-container *ngIf="materialDeliveryCount > 0; else noMaterialDeliveryData">
      <mat-tab-group
        #tabGroup
        (click)="$event.stopPropagation()"
        class="contents-tab-group"
      >
        <mat-tab
          [label]="materialDelivery?.delivery?.count + ' Deliver' + (materialDelivery?.delivery?.count === 1 ? 'y' :
          'ies')"
        >
          <ng-container *ngIf="materialDelivery?.delivery?.count; else noDeliveryData">
            <div class="panel delivery-panel">
              <!--   ADD MATERIAL DELIVERY COMPONENT AND LOOP IT     -->
            </div>
          </ng-container>
          <ng-template #noDeliveryData>
            <div class="no-data">
              No delivery available.
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab
          [label]="materialDelivery?.usage?.length + ' Usage' + (materialDelivery?.usage?.length === 1 ? '' :
          's')"
        >
          <ng-container *ngIf="materialDelivery?.usage?.length; else noUsageData">
            <div class="panel usage-panel">
              <!--   ADD MATERIAL USAGE COMPONENT AND LOOP IT     -->
            </div>
          </ng-container>
          <ng-template #noUsageData>
            <div class="no-data">
              No usage available.
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #noMaterialDeliveryData>
      <div class="no-data">
        No material delivery available.
      </div>
    </ng-template>
  </app-consolidated-report-expandable-block>

  <hr class="divider" />

  <app-consolidated-report-expandable-block
    (click)="handleExpansion('material-testing')"
    [isExpanded]="expandedSection === 'material-testing'"
    [subheading]="materialTestingCount + ' items'"
    heading="Material testing"
    icon="daily-report-material-testing"
    id="cpr-material-testing"
  >

    <ng-container *ngIf="materialTestingCount > 0; else noMaterialTestingData">
      <mat-tab-group
        #tabGroup
        (click)="$event.stopPropagation()"
        class="contents-tab-group"
      >
        <mat-tab label="Internal">
          <ng-container *ngIf="materialTesting?.internal?.length; else noInternalData">
            <div (click)="$event.stopPropagation()" class="panel internal-panel">
              <!--   ADD MATERIAL TESTING INTERNAL COMPONENT AND LOOP IT     -->
            </div>
          </ng-container>
          <ng-template #noInternalData>
            <div class="no-data">
              No internal tests available.
            </div>
          </ng-template>
        </mat-tab>
        <mat-tab label="External"
        >
          <ng-container *ngIf="materialTesting?.external?.length; else noExternalData">
            <div (click)="$event.stopPropagation()" class="panel external-panel">
              <!--   ADD MATERIAL TESTING EXTERNAL COMPONENT AND LOOP IT     -->
            </div>
          </ng-container>
          <ng-template #noExternalData>
            <div class="no-data">
              No external tests available.
            </div>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </ng-container>
    <ng-template #noMaterialTestingData>
      <div class="no-data">
        No material testing data available.
      </div>
    </ng-template>

  </app-consolidated-report-expandable-block>

  <hr class="divider" />

  <!--  ISSUES  -->
  <app-consolidated-report-expandable-block
    (click)="handleExpansion('issues')"
    [isExpanded]="expandedSection === 'issues'"
    [subheading]="openIssuesCount + ' Open,'+ ' ' + closedIssuesCount + ' Closed' "
    heading="Issues"
    icon="daily-report-issues"
    id="cpr-issues"
  >
    <ng-container>
      <ng-container *ngIf="issues && issues?.length > 0; else noIssuesData">
        <ng-container *ngIf="openIssues?.length">
          <div class="category-heading my-[20px] text-type-6">
            Open :
          </div>
          <div
            (click)="$event.stopPropagation()"
            [class.collapsed]="!isOpenIssuesPanelExpanded && openIssues?.length > 4"
            class="panel open-issues-panel"
          >
            <div *ngFor="let issue of openIssues">
              <app-consolidated-report-issues-summary-card
                (click)="triggerOpenDetailsPanel('issue', issue);$event.stopPropagation()"
                [class.selected]="issue.id === selectedId"
                [issue]="issue"
              />
            </div>
          </div>
          <div
            (click)="isOpenIssuesPanelExpanded = !isOpenIssuesPanelExpanded"
            *ngIf="openIssues?.length > 4"
            class="see-more text-type-9 flex"
          >
            {{ isOpenIssuesPanelExpanded ? 'See less' : 'See more' }}
          </div>
        </ng-container>
        <ng-container *ngIf="closedIssues?.length">
          <div class="category-heading my-[20px] text-type-6">
            Closed :
          </div>
          <div (click)="$event.stopPropagation()"
               [class.collapsed]="!isClosedIssuesPanelExpanded && closedIssues?.length > 4"
               class="panel closed-issues-panel"
          >
            <ng-container *ngFor="let issue of closedIssues">
              <app-consolidated-report-issues-summary-card
                (click)="triggerOpenDetailsPanel('issue', issue);$event.stopPropagation()"
                [class.selected]="issue.id === selectedId"
                [issue]="issue"
              />
            </ng-container>
          </div>
          <div
            (click)="isClosedIssuesPanelExpanded = !isClosedIssuesPanelExpanded; $event.stopPropagation()"
            *ngIf="closedIssues?.length > 4"
            class="see-more text-type-9 flex"
          >
            {{ isClosedIssuesPanelExpanded ? 'See less' : 'See more' }}
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noIssuesData>
        <div class="no-data">
          No issues data available.
        </div>
      </ng-template>
    </ng-container>
  </app-consolidated-report-expandable-block>

  <hr class="divider" />

  <app-consolidated-report-expandable-block
    (click)="handleExpansion('labor-equipment')"
    [isExpanded]="expandedSection === 'labor-equipment'"
    heading="Labor & equipment"
    icon="daily-report-labor-equipment"
    id="cpr-labor-equipment"
    subheading="Labor & equipment subheading"
  >
    <ng-container>
      <ng-container *ngIf="laborEquipment.length; else noLaborEquipmentData">
        <!--   ADD LABOR & EQUIPMENT COMPONENT AND LOOP IT     -->
      </ng-container>
      <ng-template #noLaborEquipmentData>
        <div class="no-data">
          No labor equipment data available.
        </div>
      </ng-template>
    </ng-container>
  </app-consolidated-report-expandable-block>

  <hr class="divider" />

  <app-consolidated-report-expandable-block
    (click)="handleExpansion('activities')"
    [isExpanded]="expandedSection === 'activities'"
    [subheading]="activities?.length + (activities?.length !== 1 ? ' entries' : ' entry')"
    heading="Activities"
    icon="daily-report-activity"
    id="cpr-activities"
  >
    <ng-container>
      <ng-container *ngIf="activities && activities.length > 0; else noActivitiesData">
        <div (click)="$event.stopPropagation()"
             [class.collapsed]="!isActivitiesPanelExpanded && activities.length > 3"
             class="panel activity-panel"
        >
          <ng-container *ngFor="let activity of activities">
            <app-consolidated-report-activity-summary-card
              (click)="triggerOpenDetailsPanel('activity', activity)"
              [class.selected]="activity.id === selectedId"
              [data]="activity" />
          </ng-container>
        </div>
        <div
          (click)="isActivitiesPanelExpanded = !isActivitiesPanelExpanded; $event.stopPropagation()"
          *ngIf="activities?.length > 3"
          class="see-more text-type-9 flex"
        >
          {{ isActivitiesPanelExpanded ? 'See less' : 'See more' }}
        </div>
      </ng-container>
      <ng-template #noActivitiesData>
        <div class="no-data">
          No activities data available.
        </div>
      </ng-template>
    </ng-container>
  </app-consolidated-report-expandable-block>

</div>

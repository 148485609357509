<div class="" *ngIf="initialized">
  <div class="row">
    <div>
      <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
    </div>
    <div class="icons">
      <button mat-icon-button aria-label="Add" color="none" [disabled]="!('create' | able: newEquipment)"
        (click)="toggleForm(null, true)">
        <mat-icon title="Add">add</mat-icon>
      </button>
    </div>
  </div>
  <ng-container *ngTemplateOutlet="ListingContent"></ng-container>
</div>

<ng-template #ListingContent>
  <!-- listing -->
  <div class="mat-body-strong" *ngIf="!equipments.length">
    No equipments found for this project
    <button mat-button color="accent" class="mat-inline-button" [disabled]="!('create' | able: newEquipment)"
      (click)="toggleForm(null, true)" aria-label="Add" title="Add">
      Add equipment?</button>

  </div>

  <div class="list">
    <div class="list-card" *ngFor="let record of equipments">
      <div class="hover-icons">
        <a mat-icon-button [disabled]="!('update' | able: record)" (click)="toggleForm(record, true)" color="none"
          aria-label="Edit" title="Edit">
          <mat-icon>edit</mat-icon>
        </a>
        <a mat-icon-button [disabled]="!('delete' | able: record)" (click)="delete(record)" color="warn"
          aria-label="Delete" title="Delete">
          <mat-icon>delete</mat-icon>
        </a>
      </div>

      <div class="body">
        <div>
          <div class="mat-subheading-2">
            {{record.number}}
            <span class="mat-caption">{{record?.make}} {{record?.model}}</span>
          </div>
          <div class="mat-caption">{{record?.equipment_type?.name}}</div>
          <div class="mat-subheading-1" *ngIf="record?.sub_contractor">{{record?.sub_contractor?.name}}</div>
        </div>
      </div>
    </div>
  </div>
  <!-- listing -->
  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length" [length]="pagination.length"
    [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>
</ng-template>


<ng-template #InputFormContent>
  <!-- Field Form -->
  <form #vcInputForm [formGroup]="inputForm" [class.hide]="!editing">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>
    <div class="row">
      <div>
        <div class="row">
          <!-- number section -->
          <section>
            <mat-form-field>
              <mat-label>Number</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="number" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.number.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.number.hasError('error')">
                {{inputForm.controls.number.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /number section -->

          <!-- make section -->
          <section>
            <mat-form-field>
              <mat-label>Make</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="make" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.make.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.make.hasError('error')">
                {{inputForm.controls.make.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /make section -->

          <!-- model section -->
          <section>
            <mat-form-field>
              <mat-label>Model</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="model" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.model.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.model.hasError('error')">
                {{inputForm.controls.model.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /model section -->
        </div>

        <div class="row">
          <!-- equipment_type_id section -->
          <section>
            <mat-form-field>
              <mat-label>Equipment Type</mat-label>
              <mat-select formControlName="equipment_type_id" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let equipmentType of equipmentTypes" [value]="equipmentType.id">
                  <div>{{equipmentType.name}}</div>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="inputForm.controls.equipment_type_id.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.equipment_type_id.hasError('error')">
                {{inputForm.controls.equipment_type_id.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /equipment_type_id section -->

          <!-- sub_contractor_id section -->
          <section>
            <mat-form-field>
              <mat-label>Sub Contractor</mat-label>
              <mat-select formControlName="sub_contractor_id" [errorStateMatcher]="matcher">
                <mat-option value="">None</mat-option>
                <mat-option *ngFor="let sc of subContractors" [value]="sc.id">
                  <div>{{sc.name}}</div>
                </mat-option>
              </mat-select>
              <mat-error *ngIf="inputForm.controls.sub_contractor_id.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.sub_contractor_id.hasError('error')">
                {{inputForm.controls.sub_contractor_id.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /sub_contractor_id section -->

          <!-- description section -->
          <section>
            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea matInput formControlName="description" [errorStateMatcher]="matcher"></textarea>
              <mat-error *ngIf="inputForm.controls.description.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.description.hasError('error')">
                {{inputForm.controls.description.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /description section -->
        </div>
      </div>

      <!-- Form Actions -->
      <section class="icons" *ngIf="editing">

        <button mat-icon-button [disabled]="!('update' | able: equipment)" (click)="save(inputForm)">
          <mat-icon title="Save">save</mat-icon>
        </button>
        <button mat-icon-button (click)="onCancel()">
          <mat-icon title="Cancel">cancel</mat-icon>
        </button>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
  <!-- /Input form -->
</ng-template>
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.scss', '../../styles.css'],
})
export class MapSidenavDeliveriesWrapperComponent implements OnInit {
  @Input() data: any;

  name = '';

  usedToday = {
    percent: 0,
    label: '',
  };

  totalUsed = {
    percent: 0,
    label: '',
  };

  materialOnHand = 0;

  fields = [];

  constructor() {
  }

  ngOnInit() {
    this.name = this.data?.material?.name;

    this.usedToday.percent = this.getTodayUsedPercent();
    this.usedToday.label = this.getTodayUsedLabel();

    if (this.data.material) {
      this.totalUsed.percent = this.getTotalUsedPercent();
      this.totalUsed.label = this.getTotalUsedLabel();
    }

    this.materialOnHand = this.getMaterialOnHand();

    this.data?.field_values?.map(o => {
      const currentField = this.data?.fields?.find(i => o.id === i.id);

      if (currentField) {
        this.fields = [
          ...this.fields,
          {
            label: currentField.label,
            uom: currentField.uom,
            hint: currentField.hint,
            value: o.value,
          },
        ];
      }
    });
  }

  getTodayUsedPercent() {
    try {
      const { received, used } = this.data;

      if (this.data.material && received && used) {
        let value = (used / received) * 100;

        if (!value) {
          value = 0;
        } else if (value > 100) {
          value = 100;
        }

        value = Number(value.toFixed(2));

        return value;
      } else if (received && !used) {
        return 0;
      }

      return 100;
    }
    catch (e) {
      return 100;
    }
  }

  getTodayUsedLabel() {
    try {
      let value = '';
      if (this.data.material) {
        let { received, used } = this.data;

        received = received && Number(received.toFixed(2)) || 0;
        used = used && Number(used.toFixed(2)) || 0;

        value = `Received: ${received} / Used: ${used}`;
      } else {
        value = `Received: ${this.data.quantity}`;
      }

      return value;
    }
    catch (e) {
      return 'Received: 0 / Used: 0';
    }
  }

  getTotalUsedPercent() {
    const received = this.data?.material?.total_received;
    const used = this.data?.material?.total_used;

    try {
      if (received && used) {
        let value = (used / received) * 100;

        if (!value) {
          value = 0;
        } else if (value > 100) {
          value = 100;
        }

        value = Number(value.toFixed(2));

        return value;
      }

      return 100;
    }
    catch (e) {
      return 100;
    }
  }

  getTotalUsedLabel() {
    try {
      let received = this.data?.material?.total_received;
      let used = this.data?.material?.total_used;

      received = received && Number(received.toFixed(2)) || 0;
      used = used && Number(used.toFixed(2)) || 0;

      return `Received: ${received} / Used: ${used}`;
    }
    catch (e) {
      return 'Received: 0 / Used: 0';
    }
  }

  getMaterialOnHand() {
    if (this.data.material) {
      const onHand = this.data.material?.total_received - this.data.material?.total_used;

      if (onHand < 0) {
        return 0;
      }

      return Number(onHand.toFixed(2));
    }
  }
}

import { Account } from './account';
import { BaseModel } from './base-model';
import { Organization } from './organization';
import { Role } from './role';

export class Invitation extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Invitation'; }

  /* tslint:disable */
  invitee: string | Account;
  invite_type: string;
  expire: Date;
  status: string;

  // relations
  inviter: Account;
  invite_to: any;
  invitee_role: Role;
  invitee_organization: Organization;

  /* tslint:enable */

  get relations() {
    return {
      invitee_organization: Organization,
      inviter: Account,
      invitee_role: Role,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    this.invitee = typeof this.invitee === 'string' ? this.invitee : new Account(this.invitee);
    // cast
    this.expire = this.expire ? new Date(this.expire) : null;
  }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../../state/app.state';
import { ProjectsService } from '../../../../../../core/projects.service';
import { takeUntil } from 'rxjs/operators';
import {
  ConsolidatedReportWeatherDetailsComponent,
} from '../consolidated-reports-components/detail-components/consolidated-report-weather-details/consolidated-report-weather-details.component';
import { FetchIssueTypesRequest } from '../../../../../../core/projects.actions';
import {
  ConsolidatedReportIssueDetailsComponent,
} from '../consolidated-reports-components/detail-components/consolidated-report-issue-details/consolidated-report-issue-details.component';
import {
  ConsolidatedReportActivityDetailsComponent,
} from '../consolidated-reports-components/detail-components/consolidated-report-activity-details/consolidated-report-activity-details.component';
import { consolidatedProjectData } from '../../../../../../core/projects.selectors';

@Component({
  selector: 'app-consolidated-report',
  templateUrl: './consolidated-report.component.html',
  styleUrls: ['./consolidated-report.component.scss'],
})
export class ConsolidatedReportComponent implements OnInit, OnDestroy {
  @Input() data: any;
  weatherList: any = [];
  bidItems: any = [];
  materialDelivery: any = {};
  materialTesting: any = {};
  issues: any[] = [];
  openIssues: any[] = [];
  closedIssues: any[] = [];
  activities: any[] = [];
  laborEquipment: any[] = [];
  expandedSection = '';
  dailyReport: any;
  reportId: any;
  site: any;
  project: any;
  materialDeliveryCount = 0;
  materialTestingCount = 0;
  closedIssuesCount = 0;
  openIssuesCount = 0;
  isOpenIssuesPanelExpanded = false;
  isClosedIssuesPanelExpanded = false;
  isWeathersPanelExpanded = false;
  isActivitiesPanelExpanded = false;
  selectedId: string;
  protected readonly Object = Object;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private projectsService: ProjectsService,
    private cdRef: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.expandedSection = qp.expandedSection || null;
        this.cdRef.detectChanges();
        if (qp.selectedId) {
          this.selectedId = qp.selectedId;
        }
      });

    this.store.select(consolidatedProjectData)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.bidItems = [];
        if (data?.consolidatedBidItemsList && data?.consolidatedBidItemsList?.length > 0) {
          this.bidItems = data?.consolidatedBidItemsList;
        }
        this.weatherList = data?.consolidatedWeathersList;
        this.activities = data?.consolidatedActivityList;
        this.issues = data?.consolidatedIssues;
        if (this.issues.length) {
          this.openIssues = this.issues?.filter(issue => issue.status === 'open').sort((a, b) => {
            return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
          });

          this.closedIssues = this.issues?.filter(issue => issue.status === 'closed').sort((a, b) => {
            return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
          });
        }


        this.openIssuesCount = this.openIssues?.length;
        this.closedIssuesCount = this.closedIssues?.length;
        this.cdRef.detectChanges();
      });


    this.store.dispatch(FetchIssueTypesRequest({
      payload: {
        projectId: this.route.snapshot.params['projectId'],
      },
    }));
  }

  handleExpansion(type) {
    this.projectsService.closeRightPanel.next(true);
    if (this.expandedSection === type) {
      this.router.navigate([], {
        queryParams: {
          expandedSection: null,
          expandedRowId: null,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          expandedSection: type,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  triggerOpenDetailsPanel(type, data, index?) {
    this.router.navigate([], {
      queryParams: {
        selectedId: data.id,
      },
      queryParamsHandling: 'merge',
    }).then(() => {
      this.projectsService.isAnyFormDirty.next(false);
      switch (type) {
        case 'weather':
          this.projectsService.openRightPanel.next({
            component: ConsolidatedReportWeatherDetailsComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'issue':
          this.projectsService.openRightPanel.next({
            component: ConsolidatedReportIssueDetailsComponent,
            data: JSON.parse(JSON.stringify(data)),
          });
          break;

        case 'activity':
          this.projectsService.openRightPanel.next({
            component: ConsolidatedReportActivityDetailsComponent,
            data: JSON.parse(JSON.stringify({ ...data, index })),
          });
          break;

        default:
          break;
      }
    });
  }


  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

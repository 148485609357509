<div class="dialog-actions" *ngIf="isDialog">
  <button [mat-dialog-close]="componentResult" class="dialog-close" mat-mini-fab aria-label="Close Sidebar"
    *ngIf="dialogOptions.closable">
    <mat-icon title="Close Sidebar">close</mat-icon>
  </button>
</div>
<ng-container *ngIf="initialized">

  <ng-container *ngIf="!smt?.id">
    <app-site-material-test-create [smt]="smt" [site]="site"
      (dataChange)="onSMTChange($event)"></app-site-material-test-create>
  </ng-container>

  <!-- Summary view -->
  <ng-container *ngIf="smt.id">
    <mat-accordion>

      <!-- Draft -->
      <mat-expansion-panel *ngIf="smt.status === 'draft'">
        <mat-expansion-panel-header>
          <div class="panel-header">
            <div class="panel-title">Request</div>
            <div class="mat-body">Draft</div>
          </div>
        </mat-expansion-panel-header>

        <div>
          <div class="mat-body">From {{smt.createdBy?.name}}</div>
          <div class="mat-caption">{{smt.created_at | dateFormat: 'very-long'}}</div>
          <app-site-material-test-create class="stroked-card" editable="true" [smt]="smt"
            [site]="site" (dataChange)="onSMTChange($event)"></app-site-material-test-create>
        </div>
      </mat-expansion-panel>
      <!-- /Draft -->

      <!-- Test Request -->
      <mat-expansion-panel *ngIf="activity.sent_to_lab">
        <mat-expansion-panel-header>
          <div class="panel-header">
            <div class="panel-title">Request</div>
            <div class="mat-body" *ngIf="activity.sent_to_lab">
              {{activity.sent_to_lab.created_at | dateFormat: 'ago'}} ago
            </div>
          </div>
        </mat-expansion-panel-header>

        <div *ngIf="activity.sent_to_lab">
          <div class="mat-body">From {{activity.sent_to_lab.createdBy?.name}}</div>
          <div class="mat-caption">{{activity.sent_to_lab.created_at | dateFormat: 'very-long'}}</div>
          <app-site-material-test-create class="stroked-card" editable="false" [smt]="smt"
            [site]="site" (dataChange)="onSMTChange($event)"></app-site-material-test-create>
        </div>
      </mat-expansion-panel>
      <!-- Test Request -->

      <!-- Schedule -->
      <mat-expansion-panel [disabled]="!activity.sent_to_lab">
        <mat-expansion-panel-header>
          <div class="panel-header" [class.disabled]="!activity.sent_to_lab">
            <div class="panel-title">Schedule</div>
            <div class="mat-body">
              <ng-container *ngIf="!activity.lab_scheduled">Test request received</ng-container>
              <ng-container *ngIf="activity.lab_scheduled">
                {{smt.request_date | dateFormat: 'very-long'}}
              </ng-container>
            </div>
          </div>
        </mat-expansion-panel-header>

        <div class="schedule-block">
          <div class="stroked-card">
            <div class="label">Lab contacts:</div>
            <div class="details">
              <div class="details-row">{{ smt.lab?.email }}</div>
              <div class="details-row">{{ smt.lab?.phone }}</div>
            </div>

            <div class="label">Received on:</div>
            <div class="details">
              <div class="details-row">{{ smt.lab?.email }}</div>
            </div>
            <div
              *ngIf="smt.status == 'sent_to_lab'"
              class="actions-section"
            >
              <button mat-flat-button
                      color="primary"
                      (click)="acceptLabSchedule(smt)">Accept schedule
              </button>
            </div>

          </div>
        </div>
      </mat-expansion-panel>
      <!-- /Schedule -->

      <!-- Record lab activity -->
      <mat-expansion-panel [disabled]="!activity.lab_scheduled">
        <mat-expansion-panel-header>
          <div class="panel-header" [class.disabled]="!activity.lab_scheduled">
            <div class="panel-title">Record</div>
            <div class="mat-body">
              <ng-container *ngIf="!activity.lab_activity_completed">Fill in details</ng-container>
              <ng-container *ngIf="activity.lab_activity_completed">Visit details saved</ng-container>
            </div>
          </div>
        </mat-expansion-panel-header>

        <div>
          <app-smt-record  *ngIf="activity.lab_activity_completed"
            class="stroked-card"
            [smt]="smt"
            [editable]="false"
            (dataChange)="onSMTChange($event)"
          ></app-smt-record>
          <app-smt-record  *ngIf="!activity.lab_activity_completed"
            class="stroked-card"
            [smt]="smt"
            [editable]="true"
            (dataChange)="onSMTChange($event)"
          ></app-smt-record>
        </div>
      </mat-expansion-panel>
      <!-- /Record lab activity -->

      <!-- Test Report -->
      <mat-expansion-panel [disabled]="!activity.lab_activity_completed">
        <mat-expansion-panel-header>
          <div class="panel-header" [class.disabled]="!activity.lab_activity_completed">
            <div class="panel-title">Report</div>
            <div class="mat-body">
              <ng-container *ngIf="!activity.updated_report">Waiting for lab report</ng-container>
              <ng-container *ngIf="activity.updated_report">Lab report uploaded</ng-container>
            </div>
          </div>
        </mat-expansion-panel-header>

        <div>
          <app-smt-report *ngIf="activity.updated_report"
            class="stroked-card"
            [smt]="smt"
            [editable]="false"
            (dataChange)="onSMTChange($event)"
          ></app-smt-report>
          <app-smt-report *ngIf="!activity.updated_report"
            class="stroked-card"
            [smt]="smt"
            [editable]="true"
            (dataChange)="onSMTChange($event)"
          ></app-smt-report>
        </div>
      </mat-expansion-panel>
      <!-- /Test Report -->

      <!-- Result -->
      <mat-expansion-panel [disabled]="!activity.updated_report">
        <mat-expansion-panel-header>
          <div class="panel-header" [class.disabled]="!activity.updated_report">
            <div class="panel-title">Result</div>
            <div class="mat-body">
              <ng-container *ngIf="!activity.passed && !activity.failed">Waiting for result</ng-container>
              <ng-container *ngIf="activity.passed">Passed</ng-container>
              <ng-container *ngIf="activity.failed">Failed</ng-container>
            </div>
          </div>
        </mat-expansion-panel-header>

        <div>
          <app-smt-result *ngIf="activity.passed || activity.failed"
            class="stroked-card"
            [smt]="smt"
            [editable]="false"
            (dataChange)="onSMTChange($event)"
          ></app-smt-result>
          <app-smt-result *ngIf="!activity.passed && !activity.failed"
            class="stroked-card"
            [smt]="smt"
            [editable]="true"
            (dataChange)="onSMTChange($event)"
          ></app-smt-result>
        </div>
      </mat-expansion-panel>
      <!-- /Result -->

    </mat-accordion>
  </ng-container>
  <!-- /Summary view -->

</ng-container>

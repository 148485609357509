import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment as ENV} from 'src/environments/environment';

class LatLngBounds {
}

@Component({
  selector: 'app-google-maps',
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss']
})
export class GoogleMapsComponent implements OnChanges {

  apiLoaded: Observable<boolean>;

  public mapOptions = {
    mapTypeControl: false,
    fullscreenControl: false,
    // zoomControl: false,
    streetViewControl: false,
    width: '100%',
    height: '500px',
    zoom: 16,
    styles: this.mapStyles(),
    center: undefined
  };

  @Input() options: any = {};

  public markers: any[] = [];
  public defaultIcon = {
    // Todo replace with square svg path
    path: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z',
    fillColor: '#1036d6',
    fillOpacity: 1,
    strokeWeight: 0,
  };

  /**
   * Google maps styling
   */
  mapStyles() {
    return [
      { elementType: 'geometry', stylers: [{ color: '#d8ddeb' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#7c8ab3' }] },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#f8f9fb' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#bac0d8' }],
      },
      // hide all point-of-interest
      { featureType: 'poi', stylers: [{ visibility: 'off' }]},
      // { featureType: 'poi.business', stylers: [{ visibility: 'off' }]},
      // { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }]},
      // { featureType: 'poi.medical', stylers: [{ visibility: 'off' }]},
    ];
  }

  constructor(httpClient: HttpClient) {
    if (!window?.google?.maps) {
      this.apiLoaded = httpClient.jsonp(`https://maps.googleapis.com/maps/api/js?key=${ENV.GOOGLE_MAPS_API_KEY}`, 'callback')
        .pipe(
          map(() => true),
          catchError(() => of(false)),
        );
    } else {
      this.apiLoaded = of(true);
    }
  }

  ngOnChanges() {
    this.mapOptions = Object.assign(this.mapOptions, this.options);
    this.markers = this.options.markers.map(m => {
      return {
        position: { lat: m.lat, lng: m.lng },
        label: m.label ? { text: m.label } : null,
        title: m.title || null,
        options: {
          icon: this.defaultIcon,
        }
      };
    });
  }
}

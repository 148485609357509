import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Project, Site } from 'src/app/shared/models';
import { SiteMaterialTest } from '../../models';
import { SiteMaterialTestService } from '../../services';

@Component({
  selector: 'app-smt-summary',
  templateUrl: './smt-summary.component.html',
  styleUrls: ['./smt-summary.component.scss']
})
export class SmtSummaryComponent implements OnInit {
  @Input() data: any;
  @Input() site: Site;
  @Input() project: Project;

  public isDialog: boolean = true;
  public dialogOptions: any = {};
  public componentResult: any = null;

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = false;

  public smt: SiteMaterialTest;
  public activity: any = {};

  constructor(
    private smtSrv: SiteMaterialTestService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SiteMaterialTest>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    if (this.isDialog) {
      const { data, site, project, options } = this.inputData;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = data;
      this.site = site;
      this.project = project;
    }
    this.smt = new SiteMaterialTest(JSON.parse(JSON.stringify(this.data)));
    this.smt.project = this.smt?.project || this.project;
    this.smt.site = this.smt?.site || this.site;

    this.updateActivity(this.smt);
    this.initialized = true;
  }

  /**
   * Callback if smt changed in create component
   * @param e any
   */
  onSMTChange(e: any) {
    if (e instanceof SiteMaterialTest) {
      this.smt = e;
      this.updateActivity(this.smt);
      this.componentResult = this.smt;
    }
  }

  /**
   * Update activity by type for easy reference
   * @param smt SiteMaterialTest
   */
  updateActivity(smt: SiteMaterialTest): void {
    const activity = {
      sent_to_lab: (this.smt.activity_log || []).filter(o => o.type === 'sent_to_lab')[0] || null,
      lab_scheduled: (this.smt.activity_log || []).filter(o => o.type === 'lab_scheduled')[0] || null,
      lab_activity_completed: (this.smt.activity_log || []).filter(o => o.type === 'lab_activity_completed')[0] || null,
      updated_report: (this.smt.activity_log || []).filter(o => o.type === 'updated_report')[0] || null,
      passed: (this.smt.activity_log || []).filter(o => o.type === 'passed')[0] || null,
      failed: (this.smt.activity_log || []).filter(o => o.type === 'failed')[0] || null,
      cancelled: (this.smt.activity_log || []).filter(o => o.type === 'cancelled')[0] || null,
    };

    this.activity = activity;
  }

  /**
   * Accept lab schedule (as lab role)
   * @param smt SiteMaterialTest
   */
  acceptLabSchedule(smt: SiteMaterialTest): void {
    this.loading++;
    this.smtSrv.acceptLabSchedule(smt)
    .then((smt: SiteMaterialTest) => {
      this.smt = smt;
      this.updateActivity(this.smt);
      this.snackBar.open('Accepted schedule for test', '', { duration: 5000 });
    })
    .catch((resp: HttpErrorResponse) => {
      this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
    })
    .finally(() => {
      this.loading--;
    });
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-project-reports-card',
  templateUrl: './project-reports-card.component.html',
  styleUrls: ['./project-reports-card.component.scss'],
})
export class ProjectReportsCardComponent implements OnInit, OnDestroy {
  @Input() report: any;
  selectedReportDate = '';
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.selectedReportDate = qp.date;
      });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../../../shared/services/common.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../state/app.state';
import { existingReportId, projectDetails, projectsFilterList, sitesFilterList } from '../../core/projects.selectors';
import { workConditions } from '../../../../shared/core/shared.selectors';
import * as moment from 'moment';
import { CheckIfDailyReportCanBeCreatedRequest, CreateDailyReportRequest } from '../../core/projects.actions';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AuthService } from '../../../../shared/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-daily-report',
  templateUrl: './create-daily-report.component.html',
  styleUrls: ['./create-daily-report.component.scss'],
})
export class CreateDailyReportComponent implements OnInit, OnDestroy {
  showNote = false;
  existingReportId = '';
  errorMessages: any = {};
  errorMessageMap = {
    project: {
      required: 'This field is required.',
    },
    site: {
      required: 'This field is required.',
    },
    date: {
      required: 'This field is required.',
      exists: 'Report already exists for this date.',
    },
    dayType: {
      required: 'This field is required.',
    },
  };
  projectsList = [];
  sitesList = [];
  dayTypeList = [];

  form = this.fb.group({
    project: new FormControl({ value: null, disabled: false }, [Validators.required]),
    site: new FormControl({ value: null, disabled: false }, [Validators.required]),
    date: new FormControl({ value: null, disabled: false }, [Validators.required]),
    dayType: new FormControl({ value: null, disabled: false }, [Validators.required]),
    note: new FormControl(''),
  });

  currentUser: any;

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private dialogRef: MatDialogRef<CreateDailyReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.currentUser = this.authService?.getCurrentUser() || {};

    this.store.select(projectsFilterList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(list => {
        this.projectsList = list;
      });

    this.store.select(projectDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(project => {
        this.sitesList = project?.sites?.map(o => ({ value: o.id, label: o.name }));
      });

  }

  ngOnInit() {
    if (this.data.projectId) {

      this.form.controls.project.setValue(this.data.projectId);
      this.form.controls.project.disable({ emitEvent: false });

      this.form.controls.site.setValue(this.data.siteId);
      this.form.controls.date.setValue(this.data.date);


    } else {
      this.form.controls.project.valueChanges
        .pipe(takeUntil(this.onDestroy))
        .subscribe(projectId => {
          this.form.controls.site.reset();

          this.store.select(sitesFilterList, { projectId })
            .subscribe(list => {
              this.sitesList = list;
            }).unsubscribe();
        });

      this.store.select(sitesFilterList, { projectId: this.form.controls.project.value })
        .subscribe(list => {
          this.sitesList = list || [];
        });
    }

    this.store.select(workConditions)
      .pipe(takeUntil(this.onDestroy))
      // tslint:disable-next-line:no-shadowed-variable
      .subscribe((data) => {
        const workday = data.filter(o => o.value === 999);
        const nonWorkday = data.filter(o => o.value !== 999);

        this.dayTypeList = [
          ...workday,
          {
            label: 'Non-workday',
            children: nonWorkday,
          },
        ];
      });

    this.form.controls.dayType.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe((dayType) => {
        // In case work day is of type other. open the note text area.
        if (dayType === 1004) {
          this.showNote = true;
        } else {
          this.showNote = false;
          this.form.controls.note.setValue('');
        }
      });

    this.form.controls.project
      .valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.checkIfDailyReportExists();
      });

    this.form.controls.site
      .valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.checkIfDailyReportExists();
      });

    this.form.controls.date
      .valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.checkIfDailyReportExists();
      });

    this.store.select(existingReportId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(d => {
        const dateControl = this.form.get('date');

        const {
          project,
          site,
          date,
        } = this.form.getRawValue();


        if (project && site && date) {
          if (d) {
            dateControl.setErrors({ exists: true });
            this.existingReportId = d;
          } else {
            dateControl.setErrors(null);
            this.existingReportId = null;
          }
        }

      });
  }

  checkIfDailyReportExists() {
    const params = {
      project: this.form.get('project').value,
      site: this.form.get('site').value,
      date: this.form.get('date').value,
      createdBy: this.currentUser.id,
    };

    if (params.project && params.site && params.date && params.createdBy) {
      this.store.dispatch(CheckIfDailyReportCanBeCreatedRequest({
        payload: {
          project: { id: params.project },
          site: params.site,
          createdBy: params.createdBy,
          qp: {
            date: moment(params.date).format('YYYY-MM-DD'),
            include: [
              'createdBy',
              'site',
            ],
          },
        },
      }));
    }

  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }

  goToReport() {
    const {
      project,
      site,
      date,
    } = this.form.getRawValue();

    const url = `projects/${project}/sites/${site}`;
    const qp = {
      tab: 'daily-report',
      date: moment(date),
      reportId: this.existingReportId,
    };
    this.router.navigate([url], { queryParams: qp }).then(() => {
      this.dialogRef.close();
    });
  }

  onSubmit() {
    const {
      site,
      date,
      dayType,
      note,
    } = this.form.getRawValue();

    const payload = {
      siteId: site,
      report_date: moment(date).format('YYYY-MM-DD'),
      work_condition_code: dayType,
      ...(note ? { work_condition_comment: note } : {}),
    };

    this.store.dispatch(CreateDailyReportRequest({ payload }));
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

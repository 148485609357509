import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { DailyReport, ReportActivity, ReportBidItem, ReportEquipment, ReportLabor, ReportMaterialDelivery } from '../models';
import { CommonService } from './common.service';

@Injectable({ providedIn: 'root' })
export class DailyReportService {

  constructor(
    private appHttpService: AppHttpService,
    private commonService: CommonService,
  ) {}

  getRecord(id: string, qp = {}) {
    return this.appHttpService.getService(`/v1/daily-report/${id}`, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new DailyReport(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  delete(id: string) {
    this.appHttpService.deleteService(`/v1/daily-report/${id}`)
      .toPromise()
      .then(() => {
        this.commonService.notification('Report deleted successfully.', 'success');
        window.history.back();
      })
      .catch(() => {
        this.commonService.notification('Unable to delete report.', 'danger');
      });
  }

  /**
   * Saves ReportBidItem|ReportMaterialDelivery objects
   * @param type string
   * @param payload object
   * @param qp object
   */
  async saveLineItem(type: string, payload: any, dailyReport: DailyReport, qp = {}): Promise<any> {

    const path = `/v1/daily-report/${dailyReport.id}/line-item/${type}`;
    return await this.appHttpService.postService(path, payload, qp).toPromise()
      .then((resp: any) => {
        if (resp.data) {
          switch (resp.type) {
            case 'ReportBidItem':
              return new ReportBidItem(resp.data);
            case 'ReportMaterialDelivery':
              return new ReportMaterialDelivery(resp.data);
            case 'ReportActivity':
              return new ReportActivity(resp.data);
            case 'ReportLabor':
              return new ReportLabor(resp.data);
            case 'ReportEquipment':
              return new ReportEquipment(resp.data);
            default:
              return resp.data;
          }
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Saves dailyreport
   * @param dailyReport DailyReport
   * @param qp query-params
   * @return {Promise<DailyReport>}
   */
  save(dailyReport: DailyReport, payload: any = null, qp: any = {}): Promise<DailyReport> {

    if (!payload) {
      payload = dailyReport.toPayload(true);
    }
    return this.appHttpService.postService(`/v1/daily-report/${dailyReport.site.id}/draft`, payload, qp).toPromise()
      .then(resp => {
        return new DailyReport(resp.data);
      });
  }

  /**
   * Deletes dailyreport line item
   * @param type string
   * @param model ReportBidItem|ReportMaterialDelivery...
   * @param dailyReport DailyReport
   */
  deleteLineItem(type: string, model: any, dailyReport: DailyReport): Promise<any> {

    const path = `/v1/daily-report/${dailyReport.id}/line-item/${type}/${model.id}`;
    return this.appHttpService.deleteService(path).toPromise();
  }

}

<!-- add-comments-dialog.component.html -->
<div class="dialog-container">
  <div class="dialog-header">
    <h1 class="dialog-title">Add a reason</h1>
    <button mat-icon-button (click)="onCancelClick()">
      <mat-icon>close</mat-icon>
    </button>
  </div>  
  <label class="label">COMMENT:</label>
  <mat-form-field appearance="none">
    <textarea maxlength="200" class="comments" matInput [(ngModel)]="data.comments"></textarea>
  </mat-form-field>
  <div class="comments-actions">
    <app-primary-button
    (handleClick)="onConfirmClick()"
    >
      Done
    </app-primary-button>
  </div>
</div>

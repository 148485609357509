<div class="wrapper">
  <section>
    <div class="field-row">
      <app-view-field
        class="field-row"
        label="EQUIPMENT"
        [value]="make"
        [supportingValue]="model"
      />
    </div>
    <div class="bid-item-wrapper">
      <div class="field-row">
        <app-view-field
          label="BID ITEM"
          [value]="make"
          [supportingValue]="model"
        />
      </div>
      <div class="time-row field-row">
        <app-view-field
          class="half-width"
          label="STANDARD TIME"
          [value]="data.standard_time"
        />
        <app-view-field
          class="half-width"
          label="OVER TIME"
          [value]="data.over_time"
        />
      </div>
    </div>
  </section>
  <section
    *ngIf="data.pictures?.length"
    class="attachments-section"
  >
    <app-attachments-block [pictures]="data.pictures"/>
  </section>
</div>

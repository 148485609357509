import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  ConfirmDialogComponent,
  ConfirmDialogModel,
} from 'src/app/shared/components';
import { BidItem, BidSheet, Project } from 'src/app/shared/models';
import { BidService } from 'src/app/shared/services/bid.service';
import { BidItemCreateComponent } from '../bid-item-create/bid-item-create.component';
import { Ability, subject } from '@casl/ability';
import { environment as ENV } from 'src/environments/environment';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { AppService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-bid-items',
  templateUrl: './project-bid-items.component.html',
  styleUrls: ['./project-bid-items.component.scss'],
})
export class ProjectBidItemsComponent implements OnInit {
  public loading = 0;
  public downloading = false;
  public initialized = false;

  @Input()
  public project: Project;

  @Input()
  public pageSize = 20;

  public newBidItem: BidItem;

  public bidSheet: BidSheet;
  public bidItems: BidItem[] = [];

  public csvEndpoint: string;
  public clsBidItem: any = BidItem;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };
  constructor(
    private bidService: BidService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private appSrv: AppService,
    private ablity: Ability
  ) {}

  ngOnInit(): void {
    this.pagination.pageSize = this.pageSize;
    this.loadData();
  }

  loadData() {
    this.loading++;
    return this.getBidSheet()
      .then((resp: any) => {
        if (resp instanceof BidSheet) {
          this.bidSheet = resp;
          this.bidSheet.project_id = this.project.id;

          this.csvEndpoint = `${ENV.API_ENDPOINT}/v1/bid/sheet/${this.bidSheet.id}/csv`;
          this.newBidItem = new BidItem({
            project_id: this.project.id,
            bid_sheet_id: this.bidSheet.id,
          });
          return this.fetchBidItems();
        }
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.message, 'Close');
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Fetch project bid-sheet
   */
  getBidSheet(): Promise<any> {
    return this.bidService
      .getBidSheet(this.project.id)
      .toPromise()
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 404) {
          return this.createBidSheet();
        }
        throw resp;
      });
  }

  fetchBidItems() {
    this.loading++;
    const qp = {
      include: ['site'],
    };
    return this.bidService
      .getBidItems(this.bidSheet, this.pagination, qp)
      .then((resp: any) => {
        this.bidItems = resp.result.map((o) => {
          o.project_id = this.project.id;
          o.bid_sheet_id = this.bidSheet.id;
          return o;
        });
        this.pagination.length = resp.meta.totalRecords ?? 0;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.message, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Create bid sheet for project
   */
  createBidSheet() {
    if (
      !this.ablity.can(
        'create',
        subject('BidSheet', { project_id: this.project.id })
      )
    ) {
      this.snackBar.open('Bid sheet is not created yet', 'close');
      return;
    }
    return this.bidService
      .createSheet(this.project)
      .toPromise()
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.message || 'Bid sheet is not created yet',
          'close'
        );
      });
  }

  /**
   * Open bid-item for edit/create
   * @param bidItem BidItem
   */
  openBidItemEdit(bidItem) {
    const sidebarRef = this.appSrv.openSidebar(BidItemCreateComponent, {
      data: bidItem,
      bidSheet: this.bidSheet,
      project: this.project,
      editable: true,
    });

    sidebarRef.afterClosed().subscribe((resp) => {
      if (resp instanceof BidItem) {
        const i = this.bidItems.findIndex((o) => o.id === resp.id);
        if (i >= 0) {
          this.bidItems[i] = Object.assign(this.bidItems[i], resp);
          this.snackBar.open(`"${resp.item}" updated successfully`, '', {
            duration: 5000,
          });
        } else {
          this.bidItems.push(resp);
          this.pagination.length++;
          this.snackBar.open(`"${resp.item}" added successfully`, '', {
            duration: 5000,
          });
        }
      }
    });
  }

  /**
   * Deletes a bidItem
   * @param bidItem BidItem
   */
  delete(bidItem: BidItem) {
    const message = `Deleting ${bidItem.item} cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.bidService.delete(bidItem).subscribe({
        next: (resp: any) => {
          this.bidItems.splice(
            this.bidItems.findIndex((o) => o.id === bidItem.id),
            1
          );
          this.snackBar.open(`"${bidItem.item}" deleted successfully`, '', {
            duration: 3000,
          });
        },
        error: (err: any) => {
          this.snackBar.open(err.error.error, '', { duration: 3000 });
        },
      });
    });
  }

  /**
   * Callback on upload failure
   * @param resp Response
   */
  onUploadError(resp: HttpErrorResponse) {
    if (resp.error.status == 422) {
      // Todo create custom component to display multiple errors at once
      const errors = Object.values(resp.error.error);
      if (errors.length) {
        const msgs = errors.map((o: any) => {
          return `${o.location}.${o.param} ${o.msg}`;
        });
        this.snackBar.open(msgs.join(', '), 'Close');
      } else {
        this.snackBar.open('Upload failed, please check the logs', 'Close');
      }
      return;
    }
    if (resp.error.error) {
      // Todo Show some permanent visible error after design
      this.snackBar.open(resp.error.error, '', { duration: 5000 });
      return;
    }

    this.snackBar.open(resp.message, '', { duration: 5000 });
  }

  /**
   * Callback on upload success
   * @param attachment Attachment
   */
  onUploadComplete(resp: any) {
    if (Array.isArray(resp)) {
      this.snackBar.open(`Added ${resp.length} bid items from csv`, '', {
        duration: 5000,
      });
      this.fetchBidItems();
    }
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchBidItems();
  }

  /**
   * Download bid sheet as csv
   * @param sheet BidSheet
   */
  downloadBidItems(sheet: BidSheet): void {
    this.downloading = true;
    sheet.project = this.project;
    this.bidService
      .downloadBidSheet(sheet)
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.message, 'close');
      })
      .finally(() => {
        this.downloading = false;
      });
  }
}

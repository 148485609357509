<ng-container *ngIf="initialized">

  <div class="row">
    <div>&nbsp;</div>
    <div class="icons">
      <button mat-icon-button [disabled]="!('create' | able: newMaterial)" (click)="openMaterialEdit(newMaterial)"
        title="Add material" color="accent">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!materials.length">
    Found no materials for this project.
    <a class="mat-inline-button" color="accent" *ngIf="('create' | able: newMaterial)"
      (click)="openMaterialEdit(newMaterial)" aria-label="Add material" title="Add material">Add material?
    </a>
  </div>

  <!-- list -->
  <div class="list">
    <div class="list-card" *ngFor="let material of materials">

      <!-- actions -->
      <div class="hover-icons">
        <a mat-mini-fab [disabled]="!('update' | able: material)" (click)="openMaterialEdit(material)" color="accent"
          aria-label="Edit {{material.name}}" title="Edit {{material.name}}">
          <mat-icon>edit</mat-icon>
        </a>

        <button mat-mini-fab [disabled]="!('delete' | able: material)" (click)="delete(material)" color="warn"
          aria-label="Delete {{material.name}}" title="Delete {{material.name}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <!-- /actions -->

      <div class="body">
        <div class="mat-title row">
          <div class="mat-body-strong">
            {{material.name}}
            <span class="mat-caption">({{material.uom}})</span>
          </div>
          <div>
            <div class="mat-body-strong v-center">
              Received: &nbsp;
              <mat-icon style="color: green" *ngIf="material?.track_received">check</mat-icon>
              <mat-icon *ngIf="!material?.track_received">block</mat-icon>
            </div>
            <div class="mat-body-strong v-center">
              Used: &nbsp;
              <mat-icon style="color: green" *ngIf="material?.track_used">check</mat-icon>
              <mat-icon *ngIf="!material?.track_used">block</mat-icon>
            </div>
          </div>
          <div>
            <div>
              <span class="mat-body-strong">Bid items: </span>
              <ng-container *ngIf="!material?.bid_items?.length">None</ng-container>
            </div>
            <div *ngIf="material?.bid_items?.length">
              <div *ngFor="let bidItem of material?.bid_items">{{bidItem.item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./list -->

  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && materials.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

</ng-container>
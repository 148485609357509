<div class="content">
  <!-- Filters -->
  <section class="left-bar">
    <form [formGroup]="inputForm" *ngIf="initialized && reports.length">
      <div class="filters">
        <div>
          <button mat-stroked-button color="primary" (click)="onSubmit(inputForm)">
            Fetch report
          </button>
        </div>

        <!-- Reports section -->
        <section class="facet">
          <div class="facet-body">
            <mat-form-field>
              <mat-label>Select report</mat-label>
              <mat-select formControlName="reportId" [errorStateMatcher]="matcher">
                <mat-option *ngFor="let report of reports" [value]="report.id">
                  {{report.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="inputForm.controls.reportId.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.reportId.hasError('error')">
                {{inputForm.controls.scope_id.errors.error}}
              </mat-error>
            </mat-form-field>
          </div>
        </section>
        <!-- /Reports section -->

        <!-- Site selector -->
        <section class="facet">
          <div class="facet-title">Sites</div>
          <div class="facet-body">
            <mat-radio-group matInput aria-label="Select site" formControlName="siteId" class="facet-radio"
              [errorStateMatcher]="matcher">
              <div *ngFor="let site of sites">
                <div class="columns">
                  <mat-radio-button [value]="site.id">
                    <div class="mat-subheading-1">{{ site.name }}</div>
                  </mat-radio-button>
                </div>
              </div>
            </mat-radio-group>
            <mat-error *ngIf="inputForm.controls.siteId.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.siteId.hasError('error')">
              {{inputForm.controls.scope_id.errors.error}}
            </mat-error>
          </div>
        </section>
        <!-- /Site selector -->
        <!-- Dates -->
        <section class="facet">
          <div class="facet-title">Date</div>
          <div class="facet-body">
            <mat-form-field>
              <input matInput [matDatepicker]="reportDatePicker" formControlName="date" [errorStateMatcher]="matcher"
                autocomplete="off" />
              <mat-datepicker-toggle matSuffix [for]="reportDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #reportDatePicker></mat-datepicker>
              <mat-error *ngIf="inputForm.controls.date.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.date.hasError('error')">
                {{ inputForm.controls.date.errors.error }}
              </mat-error>
            </mat-form-field>
          </div>
        </section>
        <!-- Dates -->
      </div>
    </form>
    <!-- Filters -->
  </section>

  <!-- Report Preview -->
  <section class="content-body">
    <mat-spinner class="spinner" *ngIf="loading" color="accent" mode="indeterminate"></mat-spinner>

    <div class="mat-body-strong" style="text-align: center;" *ngIf="initialized && !reports.length">
      Found no reports for this project
    </div>

    <div [hidden]="!reportHtml" class="report-preview">
      <div class="mat-subheading-2 row" style="align-items: center">
        <mat-spinner strokeWidth="3" diameter="32" mode="indeterminate" color="accent" *ngIf="downloading">
        </mat-spinner>
        <button mat-icon-button title="Download pdf" (click)="downloadReport()" *ngIf="!downloading">
          <mat-icon>download</mat-icon>
        </button>
      </div>
      <div class="preview-container">
        <iframe #iframe class="preview-frame"></iframe>
      </div>
    </div>
  </section>
  <!-- Report Preview -->
</div>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProjectMapService } from '../../../../project-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-map-sidenav-bid-item-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['../../styles.css', './wrapper.component.scss'],
})
export class MapSidenavBidItemWrapperComponent implements OnInit, OnDestroy {
  @Input() data: any;

  itemName = '';

  pictures = [];

  constructor() {
  }

  ngOnInit() {
    this.itemName = this.data?.bid_item?.item;
  }

  ngOnDestroy() {

  }
}

<div
  class="wrapper"
  [class.selected]="isSelected"
>
  <div class="header">
    <app-account-icon
      [account]="account"
      *ngIf="account"
      size="48px" />
    <div class="title">
      <div class="heading-3">{{ issue.title }}</div>
      <div class="sub-heading">
        <span *ngIf="account">
          {{ account?.name }}
        </span>
        <span>
        {{ issue.created_at | dateFormat : 'ago' }} ago
        </span>
      </div>
    </div>
  </div>
  <div
    *ngIf="issue.description"
    class="description"
  >
    {{ issue.description }}
  </div>
  <div class="footer">
    <div class="site-name" *ngIf="issue?.entity">
      {{issue?.entity?.name}}
    </div>
    <div class="category">
      {{ issueTypeLabel(issue.type) }}
    </div>
    <div [class]="'status '+ issue.status + '-status'">
      <mat-icon
        [svgIcon]="issue.status + '-issues'"
        class="open-issues-icon"
      />
      {{ issue.status | titlecase }}
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BidItem, DailyReport, Material, Project, ReportMaterialDelivery, Site } from 'src/app/shared/models';
import { RmdComponent } from '../rmd/rmd.component';
import { AppService } from 'src/app/shared/services';
import { RmuComponent } from '../rmu/rmu.component';

@Component({
  selector: 'app-rmd-summary',
  templateUrl: './rmd-summary.component.html',
  styleUrls: ['./rmd-summary.component.scss']
})
export class RmdSummaryComponent implements OnInit {

  public initialized: boolean = false;

  @Output() changed = new EventEmitter<any>();
  @Output() summaryCaption = new EventEmitter<any>();
  @Input() drs: DailyReport[] = [];
  @Input() rmds: ReportMaterialDelivery[] = [];
  @Input() project: Project;

  public isSingleReport: boolean = true;
  public sites: Site[] = [];
  public biditems: BidItem[] = [];
  public materials: Material[] = [];

  constructor(
    private appSrv: AppService
  ) { }

  ngOnInit(): void {
    const { sites, bid_items, materials } = this.project;
    this.sites = (sites || []).slice();
    this.biditems = bid_items || [];
    this.materials = (materials || []).slice();

    this.isSingleReport = [...new Set(this.rmds.map(o => o.daily_report_id))].length === 1;
    this.sites.map((s: any) => {
      s.delivery = this.getDeliverySummary(
        this.rmds.filter(o => o.site_id === s?.id).slice()
      );
      s.usage = this.getUsageSummary(
        this.rmds.filter(o => o.site_id === s?.id).slice()
      );
    });
    this.updateCaption();
    this.initialized = true;
  }


  getDeliverySummary(lis: ReportMaterialDelivery[]) {
    const rmds = lis.filter(o => o.quantity || o.received);
    const biditems = [...new Set(rmds.map(o => o.bid_item_id).filter(o => !!o))]
      .map(id => this.biditems.find(o => o.id === id));
    const bs = biditems.map((o: any) => {
      return {
        headerItem: rmds.find(p => p.bid_item_id === o.id && !p.material),
        lineItems: rmds.filter(p => p.bid_item_id === o.id && p.material),
        biditem: o,
      };
    });

    let t = rmds.map(o => (o.material && !o.bid_item_id) ? o.material : null).filter(o => !!o);
    const materials = [...new Set(t.map(o => o.id))].map(id => t.find(s => s.id === id));
    const ms = materials.map((o: any) => {
      return {
        rmd: rmds.find(p => p.material?.id === o.id && !p.bid_item_id),
        material: o,
      }
    });
    return { bs, ms };
  }

  getUsageSummary(lis: ReportMaterialDelivery[]) {
    const rmds = lis.filter(o => o.used);
    const biditems = [...new Set(rmds.map(o => o.bid_item_id).filter(o => !!o))]
      .map(id => this.biditems.find(o => o.id === id));
    const bs = biditems.map((o: any) => {
      return {
        biditem: o,
        lineItems: rmds.filter(p => p.bid_item_id === o.id && p.material),
      };;
    });

    let t = rmds.map(o => (o.material && !o.bid_item_id) ? o.material : null).filter(o => !!o);
    const materials = [...new Set(t.map(o => o.id))].map(id => t.find(s => s.id === id));
    const ms = materials.map((o: any) => {
      return {
        material: o,
        rmd: rmds.find(p => p.material?.id === o.id && !p.bid_item_id)
      };
    });
    return { bs, ms };
  }

  updateCaption() {
    const delivery = this.sites.map((s: any) =>
      (s.delivery.bs.length + s.delivery.ms.length))
      .reduce((p, c) => (p + c), 0);
    const usage = this.sites.map((s: any) =>
      (s.usage.bs.length + s.usage.ms.length))
      .reduce((p, c) => (p + c), 0);
    this.summaryCaption.emit({
      delivery,
      usage,
      total: delivery + usage,
    });
  }

  /**
   * Open ReportBidItem in sidebar
   * @param instance LineItem
   */
  openRMD(rmd: ReportMaterialDelivery, type: string = 'delivery') {
    const biditem = this.biditems.find(o => o.id === rmd.bid_item_id);
    const dr = this.drs.find(o => o.id === rmd.daily_report_id);
    const material = this.materials.find(o => o.id === rmd.material?.id);

    const component = type === 'delivery'
      ? RmdComponent
      : RmuComponent;

    const sidebarRef = this.appSrv.openSidebar(component, {
      rmds: this.rmds.filter(o => o.daily_report_id === dr?.id),
      biditem,
      material,
      project: this.project,
      dailyReport: dr,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp) {
        this.changed.emit(resp);
      }
    });
  }

}

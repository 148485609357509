import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-usage-bar',
  templateUrl: './usage-bar.component.html',
  styleUrls: ['./usage-bar.component.scss']
})
export class UsageBarComponent {
  @Input() heading: string;
  @Input() percent: number;
  @Input() label: string;
}

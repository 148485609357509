import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from '../services';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    let clonedReq = req;
    if (this.authService.isLoggedIn()) {
      const user = this.authService.getCurrentUser();
      if (user.token) {
        clonedReq = req.clone({
          setHeaders: {
            token: user.token,
          },
        });
      }
    }

    return next.handle(clonedReq).pipe(
      catchError(err => {

        if (err.status === 401) {
          this.authService.clearCurrentUser();
          this.router.navigate(['/login']);
          // Todo add returnUrl
        }

        return throwError(err);
      })
    );
  }
}

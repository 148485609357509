import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { InternalTest, Project, IssueType } from 'src/app/shared/models';
import { AppErrorStateMatcher, InternalTestService, ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-internal-test-create',
  templateUrl: './internal-test-create.component.html',
  styleUrls: ['./internal-test-create.component.scss']
})
export class InternalTestCreateComponent implements OnInit {

  public loading = 0;
  public initialized = false;
  public editing = false;
  public issueTypes: IssueType[] = [];

  public dialogOptions: any = {};
  public componentResult: any = null;
  @Input() public data: any;
  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;

  public internalTest: InternalTest = new InternalTest();
  public project: Project;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required]],
    issue_type: [''],
    record_by_station: ['', [Validators.required]],
  });

  constructor(
    private internalTestSrv: InternalTestService,
    private projectSrv: ProjectService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<InternalTestCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  ngOnInit(): void {
    const { data, project, options } = this.inputData;
    if (data) {
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.project = project;
      this.data = data || { project_id: this.project?.id };
    }
    this.internalTest = new InternalTest(JSON.parse(JSON.stringify(this.data)));

    this.fetchIssueTypes(this.project);

    // open edit for new entity
    if (!this.internalTest?.id) {
      this.toggleForm(this.internalTest, true);
    }
    this.initialized = true;
  }

  /**
   * Toggles input form with model
   * @param model InternalTest
   * @param show boolean
   */
  toggleForm(model: InternalTest = null, show = true): void {
    this.internalTest = model?.id ? model : new InternalTest({
      project_id: this.project?.id,
    });
    this.editing = show;
    this.toReactiveForm(this.internalTest, this.inputForm);
  }

  /**
   * Assign base model to FormGroup
   * @param internalTest InternalTest
   * @param form FormGroup
   */
  toReactiveForm(internalTest: InternalTest = null, form: UntypedFormGroup) {
    form.controls.name.setValue(internalTest?.name);
    form.controls.issue_type.setValue(internalTest?.issue_type);
    form.controls.record_by_station.setValue(internalTest?.record_by_station || false);
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    if (this.internalTest?.id) {
      this.toggleForm(this.internalTest, false);
    } else {
      this.dialogRef.close(null);
    }
  }

  /**
   * Form submit
   */
  save() {

    if (!this.inputForm.valid) {
      return;
    }
    const isNew = !this.internalTest?.id;

    const body = this.inputForm.value;
    this.loading++;

    const qp = { include: ['bid_items'] };
    const req = this.internalTest.id
      ? this.internalTestSrv.update(this.internalTest, body, qp)
      : this.internalTestSrv.create(this.internalTest, this.project, body, qp);

    req.then((internalTest: InternalTest) => {
      internalTest.project_id = this.project?.id;
      this.toggleForm(internalTest, false);
      this.componentResult = this.internalTest;
      this.snackBar.open(
        `${isNew ? 'Added': 'Updated'} internal test "${internalTest?.name}"`,
        '',
        { duration: 5000 },
      );
    })
    .catch((resp: HttpErrorResponse) => {
      if (resp.status === 422) {
        this.matcher.setServerErrors(this.inputForm, resp);
        return;
      }

      if (resp.error.error) {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      }
    })
    .finally(() => {
      this.loading--;
    });
  }

  /**
 * Fetches IssueType[]
 * @param project Project
 */
  fetchIssueTypes(project: Project): void {
    this.loading++;
    this.projectSrv.getIssueTypes(project)
      .then((issueTypes: IssueType[]) => {
        this.issueTypes = issueTypes;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  issueTypeLabel(type) {
    return (this.issueTypes || []).find(o => o?.type === type)?.label || type;
  }
  
}

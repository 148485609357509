import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-checkbox-list-with-filter',
  templateUrl: './check-box-list-with-filter.component.html',
  styleUrls: ['./check-box-list-with-filter.component.scss'],
})
export class CheckBoxListWithFilterComponent implements OnInit {
  @Input() options: { label: string, value: any, isChecked: boolean }[] = [];
  @Input() parentFormGroup: FormGroup = new FormGroup({});
  @Input() controlName = '';
  @Input() label = '';
  @Input() displaySearch = true;
  @Input() displaySelectAll = true;
  @Output() selectionChange = new EventEmitter<any[]>();
  @Output() checkForErrors = new EventEmitter();

  filteredOptions: { label: string, value: any, isChecked: boolean }[] = [];
  isAllChecked = false;
  fieldSubscription: Subscription | undefined;
  isRequired = false;


  ngOnInit(): void {
    const control = this.parentFormGroup.controls[this.controlName];
    this.fieldSubscription = control?.valueChanges.subscribe(() => {

      // If field is marked as touched, mark it as untouched
      if (this.parentFormGroup.controls[this.controlName].touched) {
        this.parentFormGroup.controls[this.controlName].markAsUntouched();
      }

      // Get errors on the form field
      const errors = this.parentFormGroup.controls[this.controlName].errors;

      // If errors exist, handle it
      if (errors) {
        // Clear errors on the form field
        this.parentFormGroup.controls[this.controlName].setErrors(null);

        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);

        // Add errors again to the form field for showing messages in the future
        this.parentFormGroup.controls[this.controlName].setErrors(errors);
      } else {
        // Clear out the error messages on the screen (parent component)
        this.checkForErrors.emit(this.controlName);
      }
    });

    // Check if field is required
    if (control?.validator) {
      const validator = control?.validator({} as AbstractControl);
      this.isRequired = !!(validator && validator['required']);
    }

    this.filteredOptions = [...this.options];

    // Watch for search changes
    this.parentFormGroup.controls[this.controlName].valueChanges.subscribe(value => {
      this.filterOptions(value);
    });

    // Initial check for "All" checkbox state
    this.updateAllCheckedState();
  }

  filterOptions(searchValue: string): void {
    if (!searchValue) {
      this.filteredOptions = [...this.options];
    } else {
      this.filteredOptions = this.options.filter(option =>
        option.label.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
  }

  updateAllCheckedState(): void {
    this.isAllChecked = this.options.every(option => option.isChecked);
  }

  onAllCheckboxChange(event: any): void {
    const isChecked = !!event.checked;
    this.options.forEach(option => option.isChecked = isChecked);
    this.updateAllCheckedState();
    this.emitSelectionChange();
  }

  onCheckboxChange(event: any, index: number): void {
    this.filteredOptions[index].isChecked = event.checked;
    this.updateAllCheckedState();
    this.emitSelectionChange();
  }

  isPartiallyChecked(): boolean {
    const checkedCount = this.options.filter(option => option.isChecked).length;
    return checkedCount > 0 && checkedCount < this.options.length;
  }

  emitSelectionChange(): void {
    const selectedValues = this.options.filter(option => option.isChecked).map(option => option.value);
    this.selectionChange.emit(selectedValues);
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['../../styles.css', './wrapper.component.scss'],
})
export class MapSidenavActivitiesWrapperComponent implements OnInit {
  @Input() data: any;

  heading = '';

  ngOnInit() {
    this.heading = this.data?.title;
  }
}

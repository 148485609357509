import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UploadChangesComponent } from '../upload-changes/upload-changes.component';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ClearBidSheet,
  FetchBidItemListWithChangeOrderRequest,
  FetchBidItemsListRequest,
  FetchBidSheetRequest,
  FetchChangeOrderFilterRequest,
  UploadChangeOrderFileRequest,
} from '../../../../../../core/projects.actions';
import { bidItemsListInfo, bidSheet, changeOrderFilter } from '../../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectsService } from '../../../../../../core/projects.service';
import { BidItemsSideNavScreenComponent } from '../bidItemsSideNav/bid-items-side-nav-screen/bid-items-side-nav-screen.component';
import { CommonService } from '../../../../../../../../shared/services/common.service';
import { SuccessComponent } from '../success-component/success.component';
import * as $ from 'jquery';
import { FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-bid-items-list',
  templateUrl: './bid-items-list.component.html',
  styleUrls: ['./bid-items-list.component.scss', '../../../../../../../../../styles.projects.scss'],
})
export class BidItemsListComponent implements OnDestroy {
  @ViewChild('fileUploadRef') fileUploadRef: ElementRef;
  acceptedFileTypes = [
    'application/vnd.ms-excel', // XLS
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX,
    'text/csv',
  ];
  displayedColumns: string[] = ['item', 'quantity', 'unitPrice', 'uom'];
  dataSource: any = [];
  projectId: string;
  pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };
  currentPage = 1;
  totalRecords: number | undefined;
  bidSheetId: any;
  file: any;
  form = this.fb.group({
    changeOrder: new FormControl({ value: null, disabled: false }),
  });
  selectedValue: any = {};
  changeOrderFilter: any[] = [];
  isOpen = false;
  selectedRow: any;
  qp: any;
  itemsPerPage = 20;
  originalDataSource: any;
  defFilterValue = JSON.stringify({
    id: -1,
    value: 'Current',
    order_number: 'Current',
  });
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private store: Store,
    private projectsService: ProjectsService,
    private commonService: CommonService,
    private fb: FormBuilder,
  ) {
    this.projectId = this.route.snapshot.paramMap.get('projectId');

    this.route.queryParams.subscribe(qp => {
      this.qp = qp;

      if (qp['tab'] === 'change-orders') {
        const payload = { projectId: this.projectId };
        this.store.dispatch(FetchBidSheetRequest({ payload }));
        this.store.dispatch(FetchChangeOrderFilterRequest({ payload }));
      } else {
        this.store.dispatch(ClearBidSheet());
      }
    });

    this.store.select(bidSheet)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data?.id) {
          this.bidSheetId = data.id;
        }
      });

    this.store.select(bidItemsListInfo)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        if (data.list.length > 0) {
          this.originalDataSource = data?.list;
          this.dataSource = data?.list;
          this.totalRecords = data?.totalRecords;

          if (this.selectedValue.id !== -1) {
            this.paginateData();
          }
        }
      });

    this.store.select(changeOrderFilter)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.changeOrderFilter = [
          {
            key: this.defFilterValue,
            id: -1,
            value: 'Current',
            order_number: 'Current',
          },
          ...data?.list.map(o => ({
            key: JSON.stringify(o),
            ...o,
          })),
        ];

        this.form.controls.changeOrder.setValue(this.defFilterValue);
      });

    this.commonService.closeDialog
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.dialog.closeAll();
        this.store.dispatch(FetchChangeOrderFilterRequest({ payload: { projectId: this.projectId } }));
      });

    this.commonService.openSuccessDialog
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.openSuccessDialog();
      });

    this.form.controls.changeOrder.valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.selectedValue = JSON.parse(data);
        if (this.selectedValue.id !== -1) {
          const payload = {
            projectId: this.projectId,
            changeOrderId: this.selectedValue.id,
            qp: {
              include: ['bid_items'],
            },
          };
          this.store.dispatch(FetchBidItemListWithChangeOrderRequest({ payload }));
        } else {
          this.fetchBidItems();
        }
        this.currentPage = 1;
        this.onPageChanged(this.currentPage);
      });

    this.commonService.clearRowSelection.pipe(takeUntil(this.onDestroy)).subscribe(() => {
      this.selectedRow = null;
    });
  }


  rowClicked(data) {
    this.selectedRow = data;

    if (data.id) {
      data = {
        ...data,
        bid_item_id: data.id,
      };
    }

    this.projectsService.openRightPanel.next({
      component: BidItemsSideNavScreenComponent,
      data: {
        ...data,
        projectId: this.projectId,
        changeOrderFilterSelectedValue: this.selectedValue,
      },
    });
  }

  onUploadChangesClicked() {
    this.dialog.open(UploadChangesComponent, {
      disableClose: false,
      data: { x: 3 },
      panelClass: 'floating-dialog-box',
    });
  }

  openSuccessDialog() {
    this.dialog.open(SuccessComponent, {
      disableClose: false,
      data: { x: 3 },
      panelClass: 'floating-dialog-box',
    }).afterClosed().subscribe(() => {
      this.fetchBidItems();
    });
  }

  import(id) {
    $(`#${id}`).click();
  }

  handleFileUpload() {
    this.file = this.fileUploadRef.nativeElement.files[0];
    if (this.file) {
      if (!this.acceptedFileTypes.includes(this.file?.type)) {
        this.commonService.notification('Invalid file type.', 'danger');
        return;
      }

      const payload = {
        inputFile: this.file,
        bidSheetId: this.bidSheetId,
      };

      this.store.dispatch(UploadChangeOrderFileRequest({ payload }));
    }

    this.fileUploadRef.nativeElement.value = '';
  }

  onPageChanged(page: number): void {
    this.currentPage = page;
    this.pagination.pageIndex = page - 1;


    if (this.selectedValue.id !== -1) {
      this.paginateData();
    } else {
      this.fetchBidItems();
    }
  }

  fetchBidItems() {
    const payload = {
      bidSheetId: this.bidSheetId,
      qp: {
        start: this.pagination.pageIndex * this.pagination.pageSize,
        total: this.pagination.pageSize,
        include: [
          'sites',
        ],
      },
    };

    if (this.currentPage > 1) {
      this.onPageChanged(1);
    }

    this.store.dispatch(FetchBidItemsListRequest({ payload }));
  }

  paginateData(reset = false): void {
    if (reset) {
      this.currentPage = 1;
    }
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = Math.min(startIndex + this.itemsPerPage, this.originalDataSource?.length);

    this.dataSource = this.originalDataSource.slice(startIndex, endIndex).sort((a, b) => {
      return a.item - b.item;
    });
  }

  changeOrderFilterItemClicked(value: any) {
    this.isOpen = false;
    this.form.controls.changeOrder.setValue(value.key);
  }

  ngOnDestroy() {
    this.store.dispatch(ClearBidSheet());
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { BaseModel } from './base-model';
import { Account} from './account';
import { Site } from './site';
import { Alignment } from './alignment';

export class Station extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Station'; }

  /* tslint:disable */
  name: string;
  properties: any;
  distance: number;
  createdBy: Account;
  updatedBy: Account;
  site: Site;
  site_id: string;
  alignment_id: string;
  alignment: Alignment;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      site: Site,
      alignment: Alignment,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.site_id = this.site?.id || this.site_id;
    this.properties = this.properties || {};
  }

  getPropertiesAsArray() {
    const rs = [];
    for(const key of Object.keys(this.properties || {})) {
      rs.push({
        name: key,
        value: this.properties[key],
      });
    }
    return rs;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      name: this.name,
      alignment_id: this.alignment?.id || this.alignment_id,
      properties: this.properties || {},
    };
  }
}

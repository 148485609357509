<mat-form-field
  *ngIf="parentFormGroup.controls[controlName]"
  [formGroup]="parentFormGroup"
  appearance="legacy"
  class="form-field w-full"
  floatLabel="always"
  hideRequiredMarker
>
  <mat-label class="text-type-13">{{ label }}:</mat-label>
  <mat-icon
    *ngIf="prefixIcon"
    class="prefix"
    matPrefix
    svgIcon="{{prefixIcon}}"
  />
  <mat-icon
    *ngIf="suffixIcon"
    class="suffix"
    matSuffix
    svgIcon="{{suffixIcon}}"
  />
  <input
    #input
    [formControlName]="controlName"
    [max]="max"
    [maxlength]="maxLength"
    [min]="min"
    class="input number-input"
    matInput
    placeholder="{{placeholder}}"
    type="text"
  >
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error *ngIf="error">{{ error }}</mat-error>
</mat-form-field>


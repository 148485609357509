<div class="wrapper">
  <div class="heading">{{heading}}:</div>
  <div class="bar-wrapper">
    <div
      class="bar"
      [style]="{
        width: percent + '%'
      }"
    ></div>
  </div>
  <div class="label">{{label}}</div>
</div>

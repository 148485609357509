import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Account, Attachment } from 'src/app/shared/models';
import { AppErrorStateMatcher, AuthService } from 'src/app/shared/services';
import { AccountService } from '../../services';
import { AppDataService } from 'src/app/modules/admin/services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public loading: number = 0;
  public initialized: boolean = false;
  public account: Account;

  public locales: string[] = [];

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    first_name: ['', []],
    last_name: ['', []],
    phone: new UntypedFormControl({ value: '', disabled: true }),
    email: new UntypedFormControl({ value: '', disabled: true }),
    locale: [''],
    offline_support: [ true, [Validators.required]],
    sms_notifications: [ true, [Validators.required]],
    push_notifications: [ true, [Validators.required]],
    email_notifications: [ true, [Validators.required]],
  });

  public passwordForm: UntypedFormGroup = this.fb.group({
    password: ['', [Validators.required]],
  });

  constructor(
    private authSrv: AuthService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private appDataSrv: AppDataService,
    private accountSrv: AccountService
  ) { }

  ngOnInit(): void {
    this.getAccount();
    this.getLocales();
  }

  /**
   * Fetch logged in account details
   */
  public getAccount(): void {
    this.loading++;
    this.accountSrv.fetchAccount('me', { include: [ 'picture' ] })
      .then((account: Account) => {
        this.account = account;
        this.toReactiveForm(this.account, this.inputForm);
      })
      .catch((err: HttpErrorResponse) => {
        this.snackBar.open(err.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Assign base model to FormGroup
   * @param model Account
   * @param form FormGroup
   */
  toReactiveForm(model: Account, form: UntypedFormGroup) {
    form.controls.first_name.setValue(model.first_name);
    form.controls.last_name.setValue(model.last_name);
    form.controls.phone.setValue(model.phone);
    form.controls.email.setValue(model.email);
    form.controls.locale.setValue(model?.locale || null);
    form.controls.offline_support.setValue(model?.offline_support);
    form.controls.sms_notifications.setValue(model.sms_notifications);
    form.controls.push_notifications.setValue(model.push_notifications);
    form.controls.email_notifications.setValue(model.email_notifications);
  }

  onSubmit() {
    if (!this.inputForm.valid) {
      return;
    }

    this.account = new Account(Object.assign(this.account, this.inputForm.value));
    this.loading++;
    this.accountSrv.updateProfile(this.account, null, { include: [ 'picture' ]})
      .then((account: Account) => {
        this.account = account;
        this.authSrv.updateAccountDetails(account);
        this.toReactiveForm(this.account, this.inputForm);
        this.snackBar.open('Updated profile', '', { duration: 5000 });
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Update password
   */
  updatePassword() {
    if (!this.passwordForm.valid) {
      return;
    }

    const { password } = this.passwordForm.value;
    this.loading++;
    this.accountSrv.updatePassword(password)
      .then((resp: any) => {
        this.snackBar.open(resp.message || 'Changed password', '', { duration: 5000 });

        this.passwordForm.controls.password.setValue('');
        this.passwordForm.controls.password.setErrors(null);
        this.account.has_password = true;

      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.passwordForm, resp);
          return;
        }
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Attach uploaded cover_photo to project
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    this.account.picture = attachments[0];
  }

  /**
   * Remove cover photo
   */
  removePicture() {
    this.account.picture = null;
  }

  /**
   * Fetch logged in account details
   */
  public getLocales(): void {
    this.loading++;
    this.appDataSrv.getRecord('supported_locales')
      .then((resp: any) => {
        this.locales = JSON.parse(resp || '[]');
      })
      .catch((err: HttpErrorResponse) => {
        this.snackBar.open(err.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }
}

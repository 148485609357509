import { BaseModel, Location } from './base-model';
import { Attachment } from './attachment';
import { BidItem } from './bid-item';
import { Annotation } from './annotation';
import { Material } from './material';
import { Field } from './field';

export class ReportMaterialDelivery extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'ReportMaterialDelivery'; }

  /* tslint:disable */
  comment: string;
  quantity: number;
  received: number;
  used: number;
  latitude: string;
  longitude: string;
  pictures: Attachment[];
  tickets: Attachment[];
  bid_item: BidItem;
  material: Material;
  annotations: Annotation[];

  location: Location;
  bid_item_id: string;
  material_id: string;
  site_id: string;
  daily_report_id: string;
  __deleted: boolean;
  _isHeader: boolean;
  /* tslint:enable */

  get relations() {
    return {
      pictures: Attachment,
      tickets: Attachment,
      bid_item: BidItem,
      material: Material,
      annotations: Annotation,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.annotations = this.annotations || [];
    this.pictures = this.pictures || [];
    this.location = new Location(this.latitude, this.longitude);
    this._id = Math.random().toString(16).substring(2);

  }

  /**
   * Converts this model to POST payload json object
   * @param include boolean Include line-items or not
   * @return {object}
   */
  toPayload(values: any = {}, fields: Field[] = []) {
    return {
      id: this.id || null,
      quantity: values?.quantity || this.quantity || '',
      received: values?.received || this.received || '',
      used: values?.used || this.used || '',
      comment: values?.comment || this.comment || '',
      latitude: values?.latitude || this.location?.lat || '',
      longitude: values?.longitude || this.location?.lng || '',
      bid_item_id: values?.bid_item_id || this.bid_item?.id || this.bid_item_id || null,
      material_id: values?.material_id || this.material?.id || this.material_id || null,
      pictures: (values?.pictures || this.pictures || []).map(o => o.id),
      annotations: (values?.annotations || this.annotations || []).map(o => o.id),
      field_values: fields.map((f) => ({ id: f.id, value: values[f.id] })),
    };
  }

  get totalReceived () {
    return this.material?.site_total_received;
  }

  get totalUsed () {
    return (this.material?.site_total_received || 0) - (this.material?.site_total_used || 0);
  }
}

<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <mat-form-field
    [formGroup]="parentFormGroup"
    hideRequiredMarker
    appearance="legacy"
    class="form-field text-area w-full"
    floatLabel="always"
  >
    <mat-label class="text-type-13">{{ label }}:</mat-label>
    <textarea
      #textarea
      class="text-area"
      [formControlName]="controlName"
      [maxlength]="maxLength"
      rows="4"
      matInput
      placeholder="{{placeholder}}"
    >
    </textarea>
    <mat-error *ngIf="error">{{error}}</mat-error>
    <div
      class="char-count flex w-full justify-end items-center mt-[0.5rem]"
      *ngIf="showCharCount"
    >
      {{ (parentFormGroup?.controls[controlName]?.value?.length || 0) }}/{{
        maxLength }}
    </div>
  </mat-form-field>

</div>

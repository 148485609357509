import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as moment from 'moment';
import { Ability } from '@casl/ability';

import {
  BidItem,
  DailyReport,
  InternalTest,
  Project,
  ReportActivity,
  ReportBidItem,
  ReportEquipment,
  ReportInternalTest,
  ReportLabor,
  ReportMaterialDelivery,
  Site,
} from 'src/app/shared/models';
import { Issue } from 'src/app/shared/models/issue';
import { Weather } from 'src/app/shared/models/weather';
import { AppService, DailyReportService, ProjectService } from 'src/app/shared/services';
import { IssueComponent } from '../issues/issue/issue.component';
import { SmtSummaryComponent } from '../material-test/components/smt-summary/smt-summary.component';
import { SiteMaterialTest } from '../material-test/models';
import { SiteMaterialTestService } from '../material-test/services';
import { ReportMaterialDeliveryComponent } from '../report-components';
import { ReportEquipmentComponent } from '../report-components/report-equipment/report-equipment.component';
import { ReportLaborComponent } from '../report-components/report-labor/report-labor.component';
import { ReportWeatherComponent } from '../report-components/report-weather/report-weather.component';
import { RbiComponent } from '../components/report-components2/rbi/rbi.component';
import { RitComponent } from '../components/report-components2/rit/rit.component';
import { ActivitiesComponent } from '../components/report-components2/activities/activities.component';
import { RmdComponent } from '../components/report-components2/rmd/rmd.component';
import { RmuComponent } from '../components/report-components2/rmu/rmu.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IssueService } from '../../issue/issue.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-project-consolidated-report',
  templateUrl: './project-consolidated-report.component.html',
  styleUrls: ['./project-consolidated-report.component.scss']
})
export class ProjectConsolidatedReportComponent implements OnInit, OnChanges, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  public loading = 0;
  public initialized = false;
  public workConditions: any[] = [];
  public reportDate: moment.Moment;

  @Input() public dailyReports: DailyReport[] = [];
  @Input() public dailyReport: DailyReport;
  @Input() public editable = false;
  @Input() public externalIssuesOnly = false;
  @Input() public showConsolidatedReport = false;
  @Output() dailyReportChange = new EventEmitter<DailyReport>();

  private project: Project;
  public summary: any = {};
  public sites: Site[] = [];
  public weathers: any[] = [];
  public reportBidItems: ReportBidItem[] = [];
  public rits: ReportInternalTest[] = [];
  public internalTests: InternalTest[] = [];
  public rbiBidItems: BidItem[] = [];
  public rbiCaption: any;
  public reportIssues: any[] = [];
  public smts: SiteMaterialTest[] = [];

  public rmds: ReportMaterialDelivery[] = [];
  public rmdCaption: any;
  public laborNEquipment: any[] = [];
  public reportLabors: ReportLabor[] = [];
  public reportEquipments: ReportEquipment[] = [];
  public reportActivities: ReportActivity[] = [];

  projectId: string;

  constructor(
    public ability: Ability,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private dailyReportService: DailyReportService,
    private projectSrv: ProjectService,
    private smtSrv: SiteMaterialTestService,
    private appService: AppService,
    private issuesService: IssueService,
    private route: ActivatedRoute
  ) {
    this.smtSrv.fetchSiteMaterialTests
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchMaterialTests();
      });

    this.projectSrv.fetchIssues
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.fetchIssues();
      });
  }

  ngOnInit(): void {

    this.appService.getAppdata()
      .then((appData: any) => {
        this.workConditions = (appData.workConditions || []).filter(o => o.code !== 999);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
      if (this.dailyReport && (this.dailyReports || []).length === 0) {
        this.dailyReports = [this.dailyReport];
      }

      const drIds = this.dailyReports.map(o => o.id).filter(o => !!o);
      if (drIds.length) {
        this.fetchData(drIds);
      } else {
        // new daily report
        this.initialized = true;
      }
  }

  get isSingleReport(): boolean {
    return this.dailyReports?.length === 1;
  }

  fetchIssues() {
    this.projectId = this.route.snapshot.paramMap.get('projectId');

    if (this.projectId) {
      const project = new Project({id: this.projectId});
      this.issuesService.fetchRecords(project, {
          pageIndex: 0,
          length: 0,
          pageSize: 1000
        }, {})
        .then(resp => {
          const {result} = resp;
          this.reportIssues = result.filter(o => o.entity_type === 'site');
        });
    }
  }

  init() {
    this.reportDate = moment(this.dailyReports[0]?.report_date);

    this.fetchMaterialTests();
    this.prepareData(this.dailyReports);

    if (!this.reportIssues || !this.reportIssues?.length) {
      this.fetchIssues();
    }

    this.appService.getAppdata()
      .then((appData: any) => {
        this.workConditions = (appData.workConditions || []).filter(o => o.code != 999);
      });
  }

  /**
   * Fetch Material tests
   */
  fetchMaterialTests() {
    let type = 'project', entityId = this.project.id;
    if (this.isSingleReport) {
      type = 'site';
      entityId = this.dailyReports[0].site_id;
    }
    this.smtSrv.getRecords(type, { id: entityId }, { pageIndex: 0, pageSize: 1000 })
      .then((resp: any) => {
        this.smts = resp.result;
      });
  }

  /**
   * reorganize data for the view
   * @param drs DailyReport[]
   */
  private prepareData(drs) {
    if (!drs.length) {
      return {};
    }

    let t;
    this.summary.weather = drs[0].weather || null;
    this.weathers = drs.map(dr => {
      const weather = dr.weather || new Weather({ site_id: dr.site_id });
      weather.editable = this.ability.can('create', dr);
      weather.daily_report_id = dr?.id;
      return weather;
    });

    // bid items
    this.reportBidItems = drs.map(dr => {
      (dr.bid_items || []).map(o => {
        o.site_id = dr.site_id; // to display under site
        o.site = dr.site;
        o.daily_report_id = dr.id; // for ACL
      });
      return dr.bid_items || [];
    }).reduce((a, c) => a.concat(...c), []).slice();

    const rits = drs.map(dr => {
      (dr.internal_tests || []).map(o => {
        o.site_id = dr.site_id;
        o.daily_report_id = dr.id;
      });
      return dr.internal_tests || [];
    }).reduce((a, c) => a.concat(...c), []);
    this.internalTests = [...new Set(rits.map(li => li.internal_test_id))]
      .map(o => this.project?.internal_tests?.find(p => p.id === o));
    this.rits = [];
    setTimeout(() => this.rits = rits, 50);

    // material deliveries
    const rmds = drs.map(dr => {
      (dr.material_deliveries || []).map(o => {
        o.site_id = dr.site_id;
        o.daily_report_id = dr.id; // for ACL
      });
      return dr.material_deliveries || [];
    }).reduce((a, c) => a.concat(...c), []);
    this.rmds = null;
    setTimeout(() => this.rmds = rmds, 50);


    // issues
    t = drs.map(dr => dr.issues || []).reduce((a, c) => a.concat(...c), []);
    let issues = [...new Set(t.map(o => o.id))].map(id => t.find(o => o.id === id));
    issues = issues.filter(o => {
      // ignore project level issues
      return o.entity_type === 'site' && (this.externalIssuesOnly ? !o.internal : true);
    });
    this.reportIssues = issues;

    // labor
    this.reportLabors = drs.map(dr => {
      (dr.labor || []).map(o => {
        o.site_id = dr.site_id; // to display under site
        o.daily_report_id = dr.id; // for ACL
      });
      return dr.labor || [];
    }).reduce((a, c) => a.concat(...c), []);

    // equipment
    this.reportEquipments = drs.map(dr => {
      (dr.equipment || []).map(o => {
        o.site_id = dr.site_id; // to display under site
        o.daily_report_id = dr.id; // for ACL
      });
      return dr.equipment || [];
    }).reduce((a, c) => a.concat(...c), []);

    // activities
    this.reportActivities = drs.map(dr => {
      (dr.activities || []).map(o => {
        o.site_id = dr.site_id;
        o.daily_report_id = dr.id; // for ACL
      });
      return dr.activities || [];
    }).reduce((a, c) => a.concat(...c), []);

    this.initialized = true;
  }

  /**
   * Open ReportBidItem in sidebar
   * @param instance LineItem
   */
  openReportBidItem(e: Event, dr: DailyReport) {
    e.stopPropagation();

    const sidebarRef = this.appService.openSidebar(RbiComponent, {
      rbis: this.reportBidItems.filter(o => o.daily_report_id === dr.id),
      dailyReport: dr,
      project: this.project,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      this.onRbiChange(resp);
    });
  }

  /**
   * Open ReportActivity in sidebar
   * @param instance LineItem
   */
  openReportActivity(e: Event) {
    e?.stopPropagation();

    const sidebarRef = this.appService.openSidebar(ActivitiesComponent, {
      project: this.project,
      activities: this.reportActivities,
      dailyReport: this.dailyReport,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp) {
        const include = [
          { activities: ['pictures', 'address', { annotation: ['drawing'] }] },
        ];
        this.reportActivities = [];
        Promise.all(this.dailyReports.map((dr) => {
          return this.dailyReportService.getRecord(dr?.id, { include }).toPromise()
            .then((resp) => {
              dr.activities = resp.activities;
              return true;
            });
        })).then(() => {
          this.prepareData(this.dailyReports);
        });
      }
    });
  }

  /**
   * Open Issue in sidebar
   * @param instance issue
   */
  openIssue(instance: Issue) {
    const sidebarRef = this.appService.openSidebar(IssueComponent, {
      data: instance,
      site: this.dailyReports[0]?.site,
      project: this.project,
      editable: this.editable,
    });

    // sidebarRef.afterClosed().subscribe(resp => {
    //   if (resp instanceof Issue) {
    //     const dr = this.dailyReports.find(o => o.site?.id === resp.entity_id);
    //     const i = (dr?.issues || []).findIndex(o => o.id === resp.id);
    //     if (i >= 0) {
    //       dr.issues[i] = Object.assign(dr.issues[i], resp);
    //     } else {
    //       this.dailyReports[0].issues.push(resp); // not optimal but, project issue can go on any dr
    //     }
    //     this.prepareData(this.dailyReports); // redraw view again
    //   }
    // });
  }

  /**
   * Open SiteMaterialTest in sidebar
   * @param instance SiteMaterialTest
   */
  openSmt(instance: SiteMaterialTest) {
    const sidebarRef = this.appService.openSidebar(SmtSummaryComponent, {
      data: instance,
      site: instance.site || this.dailyReport?.site,
      project: instance.project || this.project,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof SiteMaterialTest) {
        const i = this.smts.findIndex(o => o.id === resp.id);
        if (i >= 0) {
          this.smts[i] = Object.assign(this.smts[i], resp);
        } else {
          this.smts.push(resp);
        }
      }
    });
  }

  /**
   * Open ReportLabor in sidebar
   * @param instance LineItem
   */
  openReportLabor(instance: ReportLabor) {
    const sidebarRef = this.appService.openSidebar(ReportLaborComponent, {
      data: instance,
      dailyReport: this.dailyReport || this.dailyReports.find(o => o.id == instance?.daily_report_id),
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof ReportLabor) {
        const dr = this.dailyReports.find(o => o.id === resp.daily_report_id);
        const i = dr.labor.findIndex(o => o.id === resp.id);
        if (i >= 0) {
          if (resp.__deleted) {
            dr.labor.splice(i, 1);
          } else {
            dr.labor[i] = Object.assign(dr.labor[i], resp);
          }
        } else {
          if (!resp.__deleted) {
            dr.labor.push(resp);
          }
        }
        this.prepareData(this.dailyReports); // redraw view again
      }
    });
  }


  /**
   * Open ReportEquipment in sidebar
   * @param instance LineItem
   */
  openReportEquipment(instance: ReportEquipment) {
    const sidebarRef = this.appService.openSidebar(ReportEquipmentComponent, {
      data: instance,
      dailyReport: this.dailyReport || this.dailyReports.find(o => o.id == instance?.daily_report_id),
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof ReportEquipment) {
        const dr = this.dailyReports.find(o => o.id === resp.daily_report_id);
        const i = dr.equipment.findIndex(o => o.id === resp.id);
        if (i >= 0) {
          if (resp.__deleted) {
            dr.equipment.splice(i, 1);
          } else {
            dr.equipment[i] = Object.assign(dr.equipment[i], resp);
          }
        } else {
          if (!resp.__deleted) {
            dr.equipment.push(resp);
          }
        }
        this.prepareData(this.dailyReports); // redraw view again
      }
    });
  }
  /**
   * Open ReportMaterialDeliveryComponent in sidebar
   * @param instance LineItem
   */
  openReportMaterialDelivery(instance) {
    const sidebarRef = this.appService.openSidebar(ReportMaterialDeliveryComponent, {
      data: instance,
      dailyReport: this.dailyReport || this.dailyReports.find(o => o.id == instance?.daily_report_id),
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof ReportMaterialDelivery) {
        const dr = this.dailyReports.find(o => o.id === resp.daily_report_id);
        const i = dr.material_deliveries.findIndex(o => o.id === resp.id);
        if (i >= 0) {
          if (resp.__deleted) {
            dr.material_deliveries.splice(i, 1);
          } else {
            dr.material_deliveries[i] = Object.assign(dr.material_deliveries[i], resp);
          }
        } else {
          if (!resp.__deleted) {
            dr.material_deliveries.push(resp);
          }
        }
        this.prepareData(this.dailyReports); // redraw view again
      }
    });
  }

  addLineItem(e: Event, lineItemType: string) {
    switch (lineItemType) {
      case 'ReportMaterialDelivery':
        this.openReportMaterialDelivery(new ReportMaterialDelivery());
        break;
      case 'ReportLabor':
        this.openReportLabor(new ReportLabor());
        break;
      case 'ReportEquipment':
        this.openReportEquipment(new ReportEquipment());
        break;
      case 'Issue':
        this.openIssue(new Issue());
        break;
      case 'SiteMaterialTest':
        this.openSmt(new SiteMaterialTest());
        break;
    }
  }

  /**
   * Open ReportMaterialDeliveryComponent in sidebar
   * @param instance LineItem
   */
  openReportWeather(instance) {
    const sidebarRef = this.appService.openSidebar(ReportWeatherComponent, {
      data: instance,
      dailyReport: this.dailyReport || this.dailyReports.find(o => o.id == instance?.daily_report_id),
    });
    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof Weather) {
        const i = this.dailyReports.findIndex(o => o.id === resp.daily_report_id);
        if (i > -1) {
          this.dailyReports[i].weather = resp;
          this.prepareData(this.dailyReports); // redraw view again
        }
      }
    });
  }

  /**
   * Saves daily-report
   */
  saveDailyReport(): void {
    this.loading++;
    this.dailyReportService.save(this.dailyReport)
      .then((dailyReport: DailyReport) => {
        const msg = this.dailyReport.id
          ? 'Updated Daily Report'
          : 'Created Daily Report';
        this.snackBar.open(msg, '', { duration: 5000 });

        this.dailyReport = dailyReport;
        this.dailyReportChange.emit(dailyReport);
        this.fetchData([this.dailyReport?.id]);
      })
      .catch((e: any) => {
        console.error(e);
        // Todo show some error here
      })
      .finally(() => {
        this.loading--;
      });
  }

  softCompare(v1, v2) {
    return v1 == v2;
  }

  onRbiChange(changes: number = null) {
    if (!changes) {
      return;
    }
    const include = [
      {
        bid_items: [
          'pictures',
          'annotations',
          'bid_item_id',
          'heading_id',
          'station_id',
          'field_values',
        ]
      }
    ];

    this.reportBidItems = [];
    this.loading++;
    Promise.all(this.dailyReports.map((dr) => {
      return this.dailyReportService.getRecord(dr?.id, { include }).toPromise()
        .then((resp) => {
          dr.bid_items = resp.bid_items;
          return true;
        });
    })).then(() => {
      this.prepareData(this.dailyReports);
    }).finally(() => {
      this.loading--;
    });
  }

  /**
   * Open ReportBidItem in sidebar
   * @param instance LineItem
   */
  openRIT(e: Event) {
    const sidebarRef = this.appService.openSidebar(RitComponent, {
      rits: this.rits.filter(o => o.daily_report_id === this.dailyReport?.id),
      dailyReport: this.dailyReport,
      project: this.project,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      this.onRitChange(resp);
    });
  }

  onRitChange(changes: number = null) {
    if (!changes) {
      return;
    }
    const include = [
      {
        internal_tests: [
          'bid_item_id',
          'internal_test_id',
          'station_id',
          'heading_id',
          'field_values',
          'pictures',
          { annotations: ['drawing'] },
        ],
      },
    ];
    this.rits = [];
    Promise.all(this.dailyReports.map((dr) => {
      return this.dailyReportService.getRecord(dr?.id, { include }).toPromise()
        .then((resp) => {
          dr.internal_tests = resp.internal_tests;
          return true;
        });
    })).then(() => {
      this.prepareData(this.dailyReports);
    });
  }

  /**
   * Open ReportBidItem in sidebar
   * @param instance LineItem
   */
  openRMD(e: Event, type: string) {
    const component = type === 'delivery'
      ? RmdComponent
      : RmuComponent;

    console.log(this.rmds)

    const dr = this.dailyReport;
    const sidebarRef = this.appService.openSidebar(component, {
      rmds: this.rmds.filter(o => o.daily_report_id === dr?.id),
      dailyReport: this.dailyReport,
      project: this.project,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      this.onRmdChange(resp);
    });
  }

  onRmdChange(changes: number = null) {
    if (!changes) {
      return;
    }
    const include = [
      {
        material_deliveries: [
          'pictures',
          'bid_item_id',
          'material',
          { annotations: ['drawing'] },
        ],
      },
    ];
    Promise.all(this.dailyReports.map((dr) => {
      return this.dailyReportService.getRecord(dr?.id, { include }).toPromise()
        .then((resp) => {
          dr.material_deliveries = resp.material_deliveries;
          return true;
        });
    })).then(() => {
      this.prepareData(this.dailyReports);
    });
  }

  updateValue(v, k) {
    setTimeout(() => this[k] = v, 50);
  }


  fetchData(drIds: string[] = []): void {
    let drs = [];
    const projectInclude = [
      {
        sites: [
          'delivery_tracking_fields',
          { headings: ['stations'] },
        ]
      },
      {
        bid_items: [
          'rollup_field',
          { fields: ['recent_field'] },
          { headings: ['stations', 'alignment'] },
          'materials',
        ]
      },
      { internal_tests: ['bid_item_ids', 'site_ids', 'fields'] },
      'delivery_tracking_fields',
      'materials',
    ];
    const drInclude = [
      'project_id',
      'site_id',
      {
        bid_items: [
          'pictures',
          { annotations: ['drawing'] },
          'bid_item_id',
          'heading_id',
          'station_id',
          'field_values',
        ]
      },
      {
        material_deliveries: [
          'pictures',
          { annotations: ['drawing'] },
          'field_values',
          'bid_item_id',
          'material',
        ]
      },
      { activities: ['pictures', 'address', { annotation: ['drawing'] }] },
      {
        internal_tests: [
          'bid_item_id',
          'internal_test_id',
          'station_id',
          'heading_id',
          'field_values',
          'pictures',
          { annotations: ['drawing'] },
        ],
      },
      {
        issues: ['entity', 'pictures', 'attachments', 'bid_item', 'annotation',
          { createdBy: ['picture'] },
          { closedBy: ['picture'] },
        ],
      },
      // { labor: ['sub_contractor'] },
      // { equipment: ['sub_contractor'] },
    ];
    this.loading++;
    Promise.all(drIds.map((drId) => {
      return this.dailyReportService.getRecord(drId, { include: drInclude }).toPromise();
    })).then((v) => {
      const [dr] = v;
      const projectId = dr?.project_id;
      drs = v;
      return this.projectSrv.getRecord(projectId, { include: projectInclude }).toPromise();
    }).then((project: Project) => {
      const { sites } = project;
      this.project = project;
      this.sites = sites;
      this.dailyReports = drs.map(dr => {
        dr.project = project;
        dr.site = sites.find(o => o.id === dr.site_id);
        return dr;
      });

      if (this.dailyReport) {
        this.dailyReport = drs.find(o => o.id === this.dailyReport?.id);
      }

      this.init();
    })
    .finally(() => {
      this.loading--;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SubContractor, SubContractorService } from 'src/app/modules/sub-contractor';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Equipment, EquipmentType, Project } from 'src/app/shared/models';
import { AppErrorStateMatcher, EquipmentService, EquipmentTypeService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.scss'],
})
export class EquipmentsComponent implements OnInit {

  public loading = 0;
  public initialized = false;
  public editing = false;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };

  @Input() public project: Project;
  public equipments: Equipment[] = [];
  public equipment: Equipment;
  public newEquipment: Equipment;

  public equipmentTypes: EquipmentType[] = [];
  public subContractors: SubContractor[] = [];

  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcInputForm') vcInputForm;
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    number: ['', [Validators.required]],
    make: ['', [Validators.required]],
    model: ['', [Validators.required]],
    description: [''],
    equipment_type_id: ['', [Validators.required]],
    sub_contractor_id: [''],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private equipmentTypeSrv: EquipmentTypeService,
    private subContractorSrv: SubContractorService,
    private equipmentSrv: EquipmentService,
  ) {
  }

  ngOnInit(): void {
    this.newEquipment = new Equipment({
      project: this.project,
    });
    this.fetchRecords();
    this.fetchEquipmentTypes();
    this.fetchSubContractors();
  }

  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords();
  }

  /**
   * Fetches Equipment[]
   */
  fetchRecords(): void {
    this.loading++;
    const qp = {
      include: ['equipment_type', 'sub_contractor'],
    };
    this.equipmentSrv.getRecords(this.project, this.pagination, qp)
      .then((resp: any) => {
        const { result, meta } = resp;
        this.equipments = (result || []).map(o => {
          o.project_id = this.project?.id;
          return o;
        });
        this.pagination.length = meta?.totalRecords || 0;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Toggles input form
   * @param model BaseModel
   * @param show boolean
   */
  toggleForm(model: Equipment = null, show = true): void {
    this.equipment = model?.id ? model : new Equipment({ project: this.project });
    this.editing = show;

    if (show) {
      this.vcInputForm?.nativeElement?.reset();
      this.inputForm.reset({
        id: model?.id || null,
        number: model?.number || null,
        make: model?.make || null,
        model: model?.model || null,
        description: model?.description || null,
        equipment_type_id: model?.equipment_type_id || null,
        sub_contractor_id: model?.sub_contractor_id || null,
      });
    }
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.toggleForm(null, false);
  }

  save(form: UntypedFormGroup = null): void {
    if (!form.valid) {
      return;
    }

    const payload = form.value;
    payload.project_id = this.project?.id;
    this.loading++;

    const qp = { include: ['equipment_type', 'sub_contractor'] };
    const req = payload?.id
      ? this.equipmentSrv.update(payload, qp)
      : this.equipmentSrv.create(payload, qp);

    req.then((equipment: Equipment) => {
      equipment.project_id = this.project?.id;

      this.snackBar.open(
        `${this.equipment?.id ? 'Updated' : 'Created'} ${equipment?.number} ${equipment?.make}`,
        '',
        { duration: 5000 },
      );


      const i = this.equipments.findIndex(o => o.id === equipment.id);
      if (i >= 0) {
        this.equipments.splice(i, 1, equipment);
      } else {
        this.equipments.push(equipment);
      }

      this.toggleForm(null, false);
    })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes Record
   * @param model BaseModel
   */
  delete(model: Equipment): void {
    const message = `Deleting <b>${model.number} ${model.make}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.equipmentSrv.delete(model)
        .then((resp: any) => {
          this.equipments.splice(
            this.equipments.findIndex(o => o.id === model.id),
            1,
          );
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
    });
  }

  /**
   * Fetches EquipmentType[]
   */
  fetchEquipmentTypes(): void {
    this.loading++;
    const qp = {
      include: [],
    };
    this.equipmentTypeSrv.getProjectRecords(this.project, qp)
      .then((equipmentTypes: EquipmentType[]) => {
        this.equipmentTypes = equipmentTypes;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Fetches SubContractor[]
   */
  fetchSubContractors(): void {
    this.loading++;
    const qp = {
      project_id: this.project?.id,
      include: [],
    };
    this.subContractorSrv.fetchRecords({ pageIndex: 0, pageSize: 10000 }, qp)
      .then((resp: any) => {
        this.subContractors = resp.result || [];
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

}

import { PipeTransform, Pipe } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'durationFormat',
})
export class DurationFormatPipe implements PipeTransform {
  transform(duration: string, type: string = 'asMinutes') {
    const o = moment.duration(duration);
    let str;
    switch (type) {
      default:
      case 'asMinutes':
        str = o.asMinutes();
        break;

    }
    return str;
  }
}

@Pipe({
  name: 'dateDurationFormat',
})
export class DateDurationFormatPipe implements PipeTransform {
  transform(date: string|any, duration: string, format: string = 'hh:mm A') {
    let df = moment(date), dt = moment(date);
    dt.add(moment.duration(duration));
    return df.format(format) + ' to ' + dt.format(format);
  }
}

<div class="wrapper mt-[40px] flex flex-col gap-[40px]">
  <div class="flex flex-col gap-[40px] w-[711px]">
    <app-horizontal-date-picker
      [date]="date"
      [triggerChange]="triggerDateChange"
      (onDateSelection)="handleDateChange($event)"
      [count]="9"
    />
    <app-daily-reports-list
      [selectedReportId]="reportId"
    />
  </div>
</div>

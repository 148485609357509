import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../state/app.state';
import {projectsList} from '../../../core/projects.selectors';
import {MapService} from '../../../../../shared/services/map.service';
import {DateFormatPipe} from '../../../../../shared/pipes';
import * as moment from 'moment';
import {ProjectsService} from '../../../core/projects.service';

@Component({
  selector: 'app-projects-location-map',
  templateUrl: './projects-location-map.component.html',
  styleUrls: ['./projects-location-map.component.scss']
})
export class ProjectsLocationMapComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  isFullScreen = false;

  mapComponentInstance: any;

  markersList: any[] = [];
  projects: any[] = [];

  showTooltip = false;
  tooltipContent = '';

  screenPoint = {
    x: 0,
    y: 0,
  };

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private dateFormatPipe: DateFormatPipe,
    private projectsService: ProjectsService,
  ) {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        this.isFullScreen = 'fs' in qp;
      });
  }

  ngOnInit() {
    this.projectsService.filteredProjectsForMap
      .pipe(takeUntil(this.onDestroy))
      .subscribe(projects => {
        this.createMarkersList(projects);
        this.drawPointsOnMap();
      });
  }

  createMarkersList(projects) {
    // while creating marker, add level = 2
    // this is needed for the esri map component to show tooltip
    this.markersList = projects.map(project => {
      const location = {
        longitude: project.longitude,
        latitude: project.latitude,
      };

      const data = {
        ...project,
        level: 2,
      };

      return this.mapService.createMarker(location, data, 'project');
    });

  }

  onMarkerClick($event: any) {

  }

  onShowTooltip($event: any) {

  }

  onMapLoaded($event: any) {

  }

  onMapComponentLoaded($event: any) {
    this.mapComponentInstance = $event;
    this.drawPointsOnMap();
  }

  onViewLoaded($event: any) {

  }

  drawPointsOnMap() {
    if (this.mapComponentInstance && this.markersList.length) {
      // Clear all markers previously drawn on the map
      this.mapService.removeAllMarkers(this.mapComponentInstance);

      // Draw markers on the map
      this.mapComponentInstance?.drawProjectSiteMarkers(this.markersList);

      // Reset the zoom level to get all points on available screen
      this.mapService.setExtent(this.mapComponentInstance, this.markersList, 1.2);
    }
  }

  protected _onShowTooltip($event) {
    if ($event) {
      this.showTooltip = true;
      const category = $event?.graphic?.attributes?.category;

      const data = {
        ...$event?.graphic?.attributes?.data,
        markerCategory: category,
      };

      this.screenPoint = $event?.screenPoint;

      const project = $event?.graphic?.attributes?.data;

      const lastUpdated = moment.max(moment(project.lastUpdatedDailyReportDate, 'YYYY-MM-DD'),
        moment(project.lastUpdatedIssueDate, 'YYYY-MM-DD'));

      let updatedAtLabel = '';

      if (moment(lastUpdated).isValid()) {
        updatedAtLabel =
          ` <span class="text-type-15 tooltip-light" style="font-weight: 400;">(${this.dateFormatPipe.transform(
            lastUpdated, 'ago')} ago)</span>`;
      }

      this.tooltipContent =
        `<span class="text-type-15 tooltip-bold" style="color: #060b1e;">${project.name}</span>${updatedAtLabel}`;
    } else {
      this.showTooltip = false;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

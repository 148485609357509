import {Component, Input, OnInit} from '@angular/core';
import {Account} from "../../../../shared/models";

@Component({
  selector: 'app-account-icon',
  templateUrl: './account-icon.component.html',
  styleUrls: ['./account-icon.component.scss']
})
export class AccountIconComponent implements OnInit {

  @Input() public account: Account;
  @Input() public size: string = '24px';

  public initialized: boolean = false;
  public lastChar: string;

  constructor() { }

  ngOnInit(): void {
    if (this.account) {
      this.initialized = true;
    }
  }

}

<div class="wrapper">
  <div class="left">
    <div class="map">
      <app-google-maps
        *ngIf="initialized"
        [options]="mapOptions"
      ></app-google-maps>
    </div>
    <div
      *ngIf="initialized"
      class="page-content"
    >

      <!-- Project details -->
      <app-project-card
        [project]="project"
        [showProgress]="false"
        picHeight="100px"
      />

      <div class="content">
        <div class="left-panel">
          <form [formGroup]="form">
            <div class="filter-section search-section">
              <div class="search">
                <mat-icon svgIcon="search"></mat-icon>
                <input
                  formControlName="searchText"
                  matInput
                  placeholder="Search for issues"
                >
              </div>
            </div>
            <div class="filter-section scope-section">
              <mat-form-field
                class="form-field"
                floatLabel="always"
              >
                <mat-label>SCOPE:</mat-label>
                <mat-select
                  formControlName="scope"
                  placeholder="Select a site or a project"
                >
                  <div
                    [class.no-site-data]="!filteredScope.length"
                    class="search-block"
                  >
                    <div class="search scope-search">
                      <mat-icon svgIcon="search" />
                      <input
                        formControlName="scopeFilterCtrl"
                        matInput
                        placeholder="Search for scope"
                      >
                    </div>
                  </div>
                  <mat-option
                    *ngIf="!filteredScope.length"
                    disabled
                  >
                    No Sites Found
                  </mat-option>
                  <mat-option
                    *ngFor="let scope of filteredScope"
                    [value]="scope.value"
                  >
                    <div
                      [class.pad-l-20]="scope.value.includes('site')"
                    >
                      {{ scope.value.includes('site') ? 'Site ' : '' }}{{
                        scope.label
                      }}
                    </div>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              class="filter-section status-section"
              formArrayName="status"
            >
              <mat-label>STATUS:</mat-label>
              <div class="checkbox-list">
                <mat-checkbox
                  *ngFor="let status
              of statusFormArray.controls; let i = index"
                  [disableRipple]="true"
                  [formControlName]="i"
                  class="checkbox-label"
                  color="primary"
                >
                  {{ statusOptions[i].label }}
                </mat-checkbox>
              </div>
            </div>
            <div
              *ngIf="categoriesFormArray.controls.length"
              class="filter-section category-section"
              formArrayName="categories"
            >
              <mat-label>CATEGORY:</mat-label>
              <div class="checkbox-list">
                <mat-checkbox
                  *ngFor="let category
              of categoriesFormArray.controls; let i = index"
                  [disableRipple]="true"
                  [formControlName]="i"
                  class="checkbox-label"
                  color="primary"
                >
                  {{ issueTypes[i].label }}
                </mat-checkbox>
              </div>
            </div>
          </form>
        </div>
        <div class="right-panel">
          <div class="heading-section">
            <div class="heading">
              {{ !loading ? filteredIssues.length : '' }} issue{{
                filteredIssues.length !== 1 || loading ? 's' : ''
              }}
            </div>
            <div class="action-section">
              <button
                (click)="createIssue()"
                color="primary"
                mat-flat-button
              >
                Create Issue
              </button>
            </div>
          </div>
          <div class="issues-list">
            <div
              *ngIf="loading; else loaded"
              class="loading"
            >
              <mat-progress-spinner
                color="primary"
                diameter="100"
                mode="indeterminate"
                strokeWidth="10"
              />
            </div>
            <ng-template #loaded>
              <div
                *ngIf="!filteredIssues.length; else showList"
                class="no-data"
              >
                No issues available for your selection
              </div>
              <ng-template #showList>
                <ng-container *ngFor="let issue of filteredIssues">
                  <app-issue-card-type-two
                    (click)="openIssue(issue)"
                    [isSelected]="issue.id === selectedIssueId"
                    [issueTypes]="issueTypes"
                    [issue]="issue"
                    [project]="project"
                    class="issue-card"></app-issue-card-type-two>
                </ng-container>
              </ng-template>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="showSidenav"
    [@rightAnimation]="showSidenav ? 'sidenavOpen' : 'sidenavClosed'"
    class="right"
  >
    <div class="close-block">
      <mat-icon
        (click)="close()"
        class="close-icon"
        svgIcon="close"
      />
    </div>
    <ng-container #container></ng-container>
  </div>
</div>

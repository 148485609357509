<div class="header">
  <div class="left-icons">
    <button (click)="onClose()" mat-icon-button aria-label="Back">
      <mat-icon title="Back">arrow_back</mat-icon>
    </button>
  </div>
  <div class="title">
    <ng-container *ngIf="!isNew">Edit usage</ng-container>
    <ng-container *ngIf="isNew">Add usage</ng-container>
  </div>
  <div class="right-icons">
    <button type="button" class="mat-inline-button" mat-button color="primary" (click)="saveAll()">Done</button>
  </div>
</div>

<div class="body">
  <ng-container *ngIf="initialized">

    <!-- Biditem section -->
    <section *ngIf="biditem">
      <div class="mat-headline">{{biditem?.item}}</div>
      <div>{{biditem.description}}</div>
    </section>
    <!-- /Biditem section -->

    <!-- Material section -->
    <section *ngIf="material && !biditem">
      <div class="mat-headline">{{material?.name}}</div>
      <div class="caption">
        <div>Total used: {{rmd?.totalUsed || 0}}</div>
        <div *ngIf="materialBiditems?.length">
          Bid items:
          <span *ngFor="let bi of materialBiditems" class="comma-separated">{{bi.item}}</span>
        </div>
      </div>
    </section>
    <!-- /Material section -->

    <!-- capture lineitems -->
    <section *ngIf="captureLineItems">
      <div class="row">
        <div>
          <mat-form-field>
            <mat-label>Add material</mat-label>
            <mat-select #materialDropdown>
              <mat-option *ngFor="let m of materials" [value]="m" [disabled]="duplicateLineItem(m)">
                {{m.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="icons">
          <button type="button" mat-icon-button [disabled]="!materialDropdown.value"
            (click)="addLineItem(null, materialDropdown.value)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </section>
    <!-- /capture lineitems -->

    <ng-container *ngIf="!captureLineItems" [ngTemplateOutlet]="SingleForm"
      [ngTemplateOutletContext]="{ 'li': headerItem }">
    </ng-container>

    <!-- Line items -->
    <mat-accordion *ngIf="lineItems.length" class="line-items">
      <mat-expansion-panel *ngFor="let li of lineItems;" [expanded]="li._id === selectedItem?._id">
        <mat-expansion-panel-header (click)="selectLineItem(li)">
          <div class="header-row">
            <span>
              <mat-icon color="warn" *ngIf="(!li._inputForm.valid && !li._inputForm.pristine)">error</mat-icon>
              <ng-container>{{li?.material?.name}}</ng-container>
            </span>
            <div class="icons">
              <!-- More menu -->
              <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="delete(li)">
                  <mat-icon>delete</mat-icon>
                  <span>Remove usage</span>
                </button>
              </mat-menu>
              <!-- /More menu -->
            </div>
          </div>
        </mat-expansion-panel-header>
        <ng-container [ngTemplateOutlet]="SingleForm" [ngTemplateOutletContext]="{'li': li}">
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
    <!-- /Line items -->

    <!-- Delete all items -->
    <section>
      <br />
      <button type="button" class="mat-inline-button" mat-button color="primary" (click)="deleteAll()">
        <mat-icon>delete</mat-icon> Delete usage
      </button>
    </section>
    <!-- /Delete all items -->

    <ng-container *ngIf="headerItem" [ngTemplateOutlet]="readOnlyToolbarItems"
      [ngTemplateOutletContext]="{'li': headerItem}"></ng-container>


  </ng-container>
</div>

<div class="footer" *ngIf="initialized">
  <app-report-item-toolbar *ngIf="selectedItem" [lineItem]="selectedItem" [project]="project"
    (changed)="onLineItemChange($event)"></app-report-item-toolbar>
</div>


<ng-template #SingleForm let-li="li">
  <form [formGroup]="li._inputForm">
    <section style="display: none;">
      <input matInput type="text" formControlName="id" />
    </section>

    <section class="width-50">
      <div class="mat-subheading-1">Used<span style="color: red;"
          *ngIf="isRequired(li._inputForm.controls.used)">*</span></div>
      <mat-form-field>
        <input matInput autocomplete="off" formControlName="used" [errorStateMatcher]="matcher" />
        <span class="mat-subheading-1" matSuffix *ngIf="material?.uom || li?.material?.uom">({{material?.uom ||
          li?.material?.uom}})</span>
        <mat-error *ngIf="li._inputForm.controls.used.hasError('required')">
          This field is required
        </mat-error>
        <mat-error *ngIf="li._inputForm.controls.used.hasError('error')">
          {{li._inputForm.controls.used.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
  </form>
  <ng-container *ngIf="!li._isHeader" [ngTemplateOutlet]="readOnlyToolbarItems"
    [ngTemplateOutletContext]="{'li': li}"></ng-container>
</ng-template>



<ng-template #readOnlyToolbarItems let-li="li">
  <section *ngIf="li.pictures.length">
    <div class="mat-subheading-1">Pictures:</div>
    <div class="preview-list">
      <div *ngFor="let pic of li.pictures">
        <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, li.pictures, pic)" />
      </div>
    </div>
  </section>

  <section *ngIf="li.annotations.length">
    <div class="mat-subheading-1">Annotations:</div>
    <div class="preview-list">
      <div *ngFor="let ann of li.annotations">
        <img [src]="ann.getThumbnail()" class="link" />
      </div>
    </div>
  </section>

  <section *ngIf="!li.location.isEmpty">
    <div class="mat-subheading-1">Location:</div>
    <div>
      <app-location-picker [isDialog]="false" [latitude]="li.location.lat" [longitude]="li.location.lng" height="100px"
        [readOnly]="true"></app-location-picker>
    </div>
  </section>

  <section *ngIf="li.comment">
    <div class="mat-subheading-1">Comment:</div>
    <mat-form-field appearance="outline">
      <textarea matInput rows="5" [value]="li.comment" [disabled]="true"></textarea>
    </mat-form-field>
  </section>
</ng-template>
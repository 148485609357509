<div class="component-container">
  <ng-container
    *ngIf="changeOrderFilter.length > 1"
  >
    <div
      #trigger="cdkOverlayOrigin"
      (click)="isOpen = true" cdkOverlayOrigin class="change-order-filter-button flex flex-row gap-[20px]"
      type="button"
    >
      <div class="label text-type-7 flex flex-row justify-between items-center"
      >
        <div
          class="pick-list-value"
          matTooltip="{{selectedValue['order_number']}}"
        >
          {{ selectedValue['order_number'] }}
        </div>
        <div
          *ngIf="selectedValue['key'] !== -1"
          class="current ml-[5px] text-[#60698B]"
        >
          ({{ selectedValue['created_at'] | date :'mediumDate' }})
        </div>
      </div>
      <mat-icon
        [ngClass]="{'rotate': isOpen}"
        class="dropdown-icon"
        svgIcon="chevron-down"
      />
    </div>
    <ng-template
      (backdropClick)="isOpen = false"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="isOpen"
      [cdkConnectedOverlayOrigin]="trigger"
      cdkConnectedOverlay
    >
      <div class="change-order-filter-popup">
        <div class="change-order-filter-list">
          <div
            (click)="changeOrderFilterItemClicked(eachChangeOrder)"
            *ngFor="let eachChangeOrder of changeOrderFilter; let i = index"
            class="change-order-filter-item"
          >
            <div class="flex flex-col justify-center">
              <div
                *ngIf="eachChangeOrder['order_number']"
                [ngClass]="{'selected-filter-option': selectedValue['id'] === eachChangeOrder['id']}"
                class="pick-list-option-value text-type-11"
                matTooltip="{{eachChangeOrder['order_number']}}"
              >
                {{ eachChangeOrder['order_number'] }}
              </div>
              <div *ngIf="eachChangeOrder['key'] !== -1" class="text-type-10">
                ({{ eachChangeOrder['created_at'] | date :'mediumDate' }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <form class="flex flex-col gap-[calc(40px-1.25em)]">
    <app-view-field
      [value]="form?.controls['unitPrice']?.value"
      label="Unit Price"
    />

    <app-view-field
      [value]="form?.controls['totalQuantity']?.value"
      label="Total Quantity"
    />

    <app-view-field
      [value]="form?.controls['desc']?.value"
      label="Description"
    />

    <app-view-field
      [value]="form?.controls['uom']?.value"
      label="Uom"
    />

    <ng-container *ngFor="let site of iterableSitesList,let i = index">
      <app-view-field
        [label]="site.name || site.site_name"
        [value]="form?.controls?.[site.name || site.site_name]?.value"
      />
    </ng-container>
  </form>
</div>

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { environment as ENV } from 'src/environments/environment';
import { CommonService } from './shared/services/common.service';
import { select, Store } from '@ngrx/store';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
  LoadingScreenComponent,
} from './shared/components/loading-screen/loading-screen.component';
import { isLoading } from './shared/core/shared.selectors';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import * as fromRoot from 'src/app/state/app.state';
import {
  ClearLoading,
  FetchAppDataRequest,
} from './shared/core/shared.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  title = 'Sitenotes';

  isLoadingScreenOpen = false;
  isLoadingData = [];

  constructor(
    public commonService: CommonService,
    private cdRef: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<LoadingScreenComponent>,
  ) {
    this.store.dispatch(FetchAppDataRequest());
    this.store.dispatch(ClearLoading());
  }

  ngOnInit(): void {
    console.log(`Running ${ENV.APP_NAME} v${ENV.APP_VERSION}`);

    this.store
      .pipe(
        select(isLoading),
        // debounceTime(10),
        takeUntil(this.onDestroy)
      )
      .subscribe(data => {
        this.isLoadingData = data;
        this.handleLoadingScreen();
      });
  }

  handleLoadingScreen() {
    if (this.isLoadingData.length && !this.isLoadingScreenOpen) {
      this.isLoadingScreenOpen = true;
      this.dialog.open(LoadingScreenComponent, {
        backdropClass: 'loader-backdrop',
        panelClass: 'loader-panel',
      });
    }

    if (!this.isLoadingData.length) {
      this.isLoadingScreenOpen = false;
      this.commonService.closeLoadingScreen.next(null);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

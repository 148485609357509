import { Component, Input, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-offsite-test-report-block',
  templateUrl: './offsite-test-report-block.component.html',
  styleUrls: [
    '../../../styles.css',
    '../styles.css',
    './offsite-test-report-block.component.scss',
  ],
})
export class OffsiteTestReportBlockComponent implements OnChanges {
  @Input() activity: any;
  @Input() data: any;
  @Input() isDisabled: boolean;

  subheading = '';
  isExpanded = false;

  uploadedBy = '';
  uploadedOn = '';
  pictures = [];
  comment = '';

  constructor() {

  }

  ngOnChanges() {
    this.createSubheading();

    this.uploadedBy = this.data?.material_test_report?.createdBy?.name;
    this.uploadedOn = moment(this.data?.material_test_report?.created_at).format('MMMM DD, k:mm A');
    this.pictures = this.data?.material_test_report?.attachments || [];
    this.comment = this.data?.material_test_report?.comment;
  }

  createSubheading() {
    if (this.activity.updated_report) {
      this.subheading = 'Lab report uploaded';
    } else {
      this.subheading = 'Waiting for lab report';
    }
  }
}

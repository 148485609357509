<div class="wrapper">
  <mat-tab-group [selectedIndex]="0">
    <mat-tab>
      <ng-template mat-tab-label>
        <div class="row"
             hoverable>
          <span class="material-symbols-outlined"
                title="Sites">map</span>
          <span hover-toggle>Sites</span>
        </div>
      </ng-template>
      <ng-template matTabContent>
        <app-project-sites [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Members">
          group
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-accounts [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Invites">
          person_add
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-invites [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Material tests">
          science
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-material-tests [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Bid items">
          work
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-bid-items [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Drawings">
          architecture
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-drawings [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="External labs">
          diagnosis
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-labs [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Sub contractors">
          diversity_3
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-project-sub-contractors [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Materials">
          package_2
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-materials [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Internal Tests">
          labs
        </span>
      </ng-template>
      <ng-template matTabContent>
        <app-internal-tests [project]="projectDetails"/>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Delivery tracking fields">
          settings_timelapse
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-large">
          <app-fields
            recordRange="true"
            entityType="project"
            context="delivery_tracking_fields"
            [entity]="projectDetails"
          />
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Project tags">
          sell
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-large">
          <app-project-lookups [project]="projectDetails"/>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <span class="material-symbols-outlined"
              title="Settings">
          settings
        </span>
      </ng-template>
      <ng-template matTabContent>
        <div class="tab-content-large">
          <app-project-settings [project]="projectDetails" />
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  MapSidenavBidItemWrapperComponent,
} from '../sidenav-components/bid-items/wrapper/wrapper.component';
import {
  MapSidenavActivitiesWrapperComponent,
} from '../sidenav-components/activities/wrapper/wrapper.component';
import {
  MapSidenavDeliveriesWrapperComponent,
} from '../sidenav-components/deliveries/wrapper/wrapper.component';
import {
  MapSidenavIssuesWrapperComponent,
} from '../sidenav-components/issues/wrapper/wrapper.component';
import {
  MapSidenavLaborEquipmentWrapperComponent,
} from '../sidenav-components/labor-equipment/wrapper/wrapper.component';
import {
  MapSidenavQualityWrapperComponent,
} from '../sidenav-components/quality-test/wrapper/wrapper.component';
import { ProjectMapService } from '../../project-map.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-project-map-sidenav',
  templateUrl: './project-map-sidenav.component.html',
  styleUrls: ['./project-map-sidenav.component.scss'],
})
export class ProjectMapSidenavComponent
  implements OnInit,
             AfterViewInit,
             OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() data: any = {};
  componentRef: ComponentRef<any>;

  @ViewChild('container', { read: ViewContainerRef }) container: any;

  picturesData: any;
  isSideNavEnable: boolean;

  constructor(
    private projectMapService: ProjectMapService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    setTimeout(() => {

      this.picturesData = this.data;
      this.cdRef.detectChanges();
    }, 200);
    this.isSideNavEnable = this.data?.isSideNavEnable? true: false;
    this.projectMapService.selectedPicturesData
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.picturesData = data;
      });

    this.projectMapService.closePicturesPanel
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.clearPicturesData();
      });
  }

  ngAfterViewInit() {
    this.container?.clear();

    switch (this.data.markerCategory) {
      case 'bid_items':
        this.componentRef = this.container.createComponent(MapSidenavBidItemWrapperComponent);
        break;
      case 'deliveries':
        this.componentRef = this.container.createComponent(MapSidenavDeliveriesWrapperComponent);
        break;
      case 'activities':
        this.componentRef = this.container.createComponent(MapSidenavActivitiesWrapperComponent);
        break;
      case 'issues':
        this.componentRef = this.container.createComponent(MapSidenavIssuesWrapperComponent);
        break;
      case 'labor_equipment':
        this.componentRef = this.container.createComponent(MapSidenavLaborEquipmentWrapperComponent);
        break;
      case 'quality_test':
        this.componentRef = this.container.createComponent(MapSidenavQualityWrapperComponent);
        break;
    }


    if (this.componentRef) {
      this.componentRef.instance.data = this.data;
    }

    this.cdRef.detectChanges();
  }

  closeSidenav() {
    this.projectMapService.closeSidenav.next(null);
  }

  clearPicturesData() {
    this.picturesData = null;
  }

  ngOnDestroy() {
    this.clearPicturesData();
    this.componentRef?.destroy();
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppErrorStateMatcher, AuthService, ProjectService } from 'src/app/shared/services';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Attachment, Project } from 'src/app/shared/models';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationPickerComponent } from 'src/app/shared/components/location-picker/location-picker.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.scss']
})
export class ProjectCreateComponent implements OnInit {

  public loading: number = 0;
  public initialized: boolean = false;

  @Input() public data: any;
  @Input() public isDialog: boolean = true;

  public dialogOptions: any = {};
  public componentResult: any = null;
  public project: Project;

  public matcher = new AppErrorStateMatcher();
  get inputFormCtls() {
    return this.inputForm.controls;
  }
  public inputForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required]],
    number: [''],
    description: ['', [Validators.required]],
    start_date: ['', [Validators.required]],
    end_date: ['', [Validators.required]],
    project_value: ['', [Validators.required]],
    status: ['active'],
    new_labor_format: ['', Validators.required],
    offline_support: ['', Validators.required],
  });

  constructor(
    private authSrv: AuthService,
    private projectService: ProjectService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ProjectCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {

    if (this.isDialog) {
      const { data, options } = this.inputData;
      this.dialogOptions = options;
      this.data = data;
    }

    this.project = new Project(JSON.parse(JSON.stringify(this.data)));
    this.toReactiveForm(this.project, this.inputForm);
    this.initialized = true;

  }

  /**
   * Assign base model to FormGroup
   * @param project Project
   * @param form FormGroup
   */
  toReactiveForm(project: Project, form: UntypedFormGroup) {
    form.controls.name.setValue(project.name);
    form.controls.number.setValue(project.number);
    form.controls.description.setValue(project.description);
    form.controls.start_date.setValue(project.start_date);
    form.controls.end_date.setValue(project.end_date);
    form.controls.project_value.setValue(project.project_value);
    form.controls.status.setValue(project.status);
    form.controls.new_labor_format.setValue(project.new_labor_format || false);
    form.controls.offline_support.setValue(project.offline_support || false);
  }

  onSubmit() {

    if (!this.inputForm.valid) {
      return;
    }

    this.project = new Project(Object.assign(this.project, this.inputForm.value));

    this.loading++;
    this.projectService.save(this.project, null, { include: [ 'cover_photo', 'address' ]})
      .then((project: Project) => {
        this.project = project;
        this.componentResult = project;
        this.snackBar.open(`Saved project "${project.name}"`, '', { duration: 5000 });
        this.toReactiveForm(this.project, this.inputForm);
        this.authSrv.refreshAbilities();

      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Attach uploaded cover_photo to project
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    this.project.cover_photo = attachments[0];
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.project = new Project(JSON.parse(JSON.stringify(this.data)));
    this.toReactiveForm(this.project, this.inputForm);
    if (!this.project?.id) {
      this.dialogRef.close();
    }
  }

  /**
   * Open location to edit
   * @param project Project
   */
  editLocation(project: Project): void {
    project = project || new Project({});

    const dialogRef = this.dialog.open(LocationPickerComponent, {
      disableClose: true,
      data: {
        latitude: project.latitude,
        longitude: project.longitude,
        readOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp && 'latitude' in resp && 'longitude' in resp) {
        this.project.latitude = resp.latitude;
        this.project.longitude = resp.longitude;
      }
    });
  }

  /**
   * Remove cover photo
   */
  removePicture() {
    this.project.cover_photo = null;
  }

}

<div class="component-container">
  <div class="issue-count text-type-15">
    {{ data?.total }}
    {{ data?.total !== 1 ? 'Issues' : 'Issue' }}
  </div>
  <div class="issue-summary">
    <div class="open-issues">
      <mat-icon class="open-issues-icon" svgIcon="open-issues"/>
      <div class="details-section" (click)="redirectToProjectIssues('open')">
        <div class="label text-type-8">{{ data?.open }} open</div>
        <div class="sub-label text-type-14">({{ data?.open_today }} today)</div>
      </div>
    </div>
    <div class="closed-issues">
      <mat-icon class="closed-issues-icon" svgIcon="closed-issues"/>
      <div class="details-section" (click)="redirectToProjectIssues('closed')">
        <div class="label text-type-8">{{ data?.closed }} closed</div>
        <div class="sub-label text-type-14">({{ data?.closed_today }} today)</div>
      </div>
    </div>
  </div>
</div>

import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'src/app/state/app.state';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import * as $ from 'jquery';
import { AddContractorStatusRequest, DeleteVideoFromMuxDbRequest } from 'src/app/modules/projects/core/projects.actions';
import { GetVideoIdRequest, UploadFileRequest } from 'src/app/shared/core/shared.actions';
import { takeUntil } from 'rxjs/operators';
import { limits } from '../../../../../../../shared/core/shared.selectors';

@Component({
  selector: 'app-add-reason',
  templateUrl: './add-reason.component.html',
  styleUrls: ['./add-reason.component.scss'],
})
export class AddReasonComponent implements OnInit, OnDestroy {
  @ViewChild('fileUploadRef') fileUploadRef: ElementRef;

  errorMessages: any = {};
  errorMessageMap = {
    comment: {
      required: 'This field is required.',
    },
    quantity: {
      required: 'This field is required.',
    },
  };

  form = this.fb.group({
    quantity: new FormControl({ value: '', disabled: false }),
    comment: new FormControl({ value: '', disabled: false }),
    pictures: new FormControl('[]'),
    videos: new FormControl('[]'),
  });

  file: any;
  errors: any;
  dailyReportId: any;
  reportBidItemId: any;
  mediaFiles = [];
  projectId: any;
  videoToBeDeletedFromMuxDb = [];
  limits: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private commonService: CommonService,
    private store: Store<fromRoot.State>,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {


  }

  ngOnInit() {
    this.dailyReportId = this.data.selectedRowData.dailyReportId;
    this.reportBidItemId = this.data.selectedRowData.id;
    this.form.patchValue({
      quantity: this.data.selectedRowData.contractor_suggested_quantity,
      comment: this.data.selectedRowData.contractor_comments,
      pictures: JSON.stringify(this.data.selectedRowData.contractor_pictures),
      videos: JSON.stringify(this.data.selectedRowData.contractor_videos),
    });
    this.mediaFiles = this.data.selectedRowData.media;

    this.commonService.uploadedFilesInfo
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        const items = Array.isArray(data) ? data : [data];

        items.forEach((eachItem: any) => {
          if (eachItem?.['fileType'] === 'picture') {
            let pictures: any = this.form.get('pictures')?.value || '[]';
            pictures = JSON.parse(pictures);

            pictures.push(eachItem);
            this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
            this.mediaFiles = [...this.mediaFiles, eachItem];

          } else if (eachItem?.['fileType'] === 'video') {
            let videos: any = this.form.get('videos')?.value || '[]';
            videos = JSON.parse(videos);

            videos.push(eachItem);
            this.form?.get('videos')?.setValue(JSON.stringify(videos));
            this.mediaFiles = [...this.mediaFiles, eachItem];
          }
        });
        this.form.markAsDirty();
      });

    this.store.select(limits)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.limits = data;
      });
  }

  checkForErrors(currentField?: string) {
    this.errorMessages = {
      ...this.errorMessages,
      ...(this.commonService.checkFormValidation(this.form, this.errorMessageMap, currentField)),
    };
  }

  deletePicture(pic) {
    if (pic.fileType === 'picture') {
      let pictures = this.getPictures();

      pictures = pictures?.filter(o => o.id !== pic.id);
      this.mediaFiles = this.mediaFiles?.filter(o => o.id !== pic.id);
      this.form?.get('pictures')?.setValue(JSON.stringify(pictures));
    }

    if (pic.fileType === 'video') {
      let videos = this.getVideos();

      videos = videos?.filter(o => o.id !== pic.id);
      this.mediaFiles = this.mediaFiles?.filter(o => o.id !== pic.id);
      this.form?.get('videos')?.setValue(JSON.stringify(videos));
      this.videoToBeDeletedFromMuxDb.push(pic.id);
    }
    this.form.markAsDirty();
  }

  getPictures() {
    const pictures = this.form.controls['pictures']?.value || '[]';
    return JSON.parse(pictures);
  }

  getVideos() {
    const videos = this.form.controls['videos']?.value || '[]';
    return JSON.parse(videos);
  }

  onSubmit() {
    if (this.form.invalid) {
      this.checkForErrors();
      return;
    }

    const {
      comment,
      quantity,
      pictures,
    } = this.form.getRawValue();


    const picturesIdArray = [];
    JSON.parse(pictures).map((picture) => {
      picturesIdArray.push(picture.uuid || picture.id);
    });

    const payload = {
      comments: this.data.selectedRowData.status === 1 ? '' : comment,
      suggested_quantity: this.data.selectedRowData.status === 1 ? null : quantity,
      reportBidItemId: this.reportBidItemId,
      dailyReportId: this.dailyReportId,
      status: this.data.selectedRowData.status === 1 ? 'agree' : 'disagree',
      pictures: this.data.selectedRowData.status === 1 ? [] : picturesIdArray,
    };

    this.store.dispatch(AddContractorStatusRequest({ payload }));
    if (this.videoToBeDeletedFromMuxDb.length) {
      this.videoToBeDeletedFromMuxDb.forEach(videoId => {
        this.store.dispatch(DeleteVideoFromMuxDbRequest({ payload: videoId }));
      });
    }
    const resultData = {
      suggested_quantity: payload.suggested_quantity,
      comments: payload.comments,
      pictures: payload.pictures,
    };
    this.dialogRef.close(resultData);
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;  // Get selected files
    const imageFormData = new FormData();
    const videoFiles: File[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      const fileType = file.type;

      if (fileType.startsWith('image/')) {
        imageFormData.append('inputFiles', file);
      }

      if (fileType.startsWith('video/')) {
        videoFiles.push(file);
      }
    }

    if (imageFormData.has('inputFiles')) {
      this.store.dispatch(UploadFileRequest({ payload: imageFormData }));
    }

    if (videoFiles.length > 0) {
      videoFiles.forEach((videoFile: File) => {
        const videoElement = document.createElement('video');
        const reader = new FileReader();

        reader.onloadend = () => {
          videoElement.src = reader.result as string;

          videoElement.onloadedmetadata = () => {
            const videoDuration = videoElement.duration;
            const maxDuration = this.limits.upload.video_size_in_secs;

            if (videoDuration > maxDuration) {
              this.commonService.notification(`Video duration is ${videoDuration} seconds, should be less than ${maxDuration} seconds.`, 'danger');
            } else {
              this.store.dispatch(GetVideoIdRequest({ payload: videoFile }));
            }
          };
        };

        reader.readAsDataURL(videoFile);
      });
    }

    this.fileUploadRef.nativeElement.value = '';
  }

  openFile(id) {
    $(`#${id}`).click();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button mat-icon-button [matMenuTriggerFor]="moreActionsMenu" aria-label="More actions">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #moreActionsMenu="matMenu">
      <button mat-menu-item (click)="delete(reportMaterialDelivery)" [disabled]="!editable">
        <mat-icon>delete</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>

    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>

    <button *ngIf="initialized" mat-mini-fab (click)="editing = !editing" [disabled]="!editable">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="initialized">
    <form [formGroup]="inputForm">
      <div class="container">

        <section class="header" *ngIf="annotationPreviewUrl">
          <div class="annotation-preview">
            <img [src]="annotationPreviewUrl" alt="Annotation Preview" />
          </div>
        </section>

        <!-- Bid Item section -->
        <section class="header">
          <div class="bid-item link" (click)="openBidItemSelector()" *ngIf="reportMaterialDelivery.bid_item">
            <div class="title">{{reportMaterialDelivery.bid_item?.item}}</div>
          </div>

          <div class="input-placeholder" *ngIf="!reportMaterialDelivery.bid_item" (click)="openBidItemSelector()">
            <button mat-icon-button [disabled]="!editing" color="accent">
              <mat-icon>add</mat-icon>
            </button>Add bid item
          </div>
        </section>
        <!-- /Bid Item section -->

        <nav mat-tab-nav-bar class="sub-nav">
          <a mat-tab-link active="active">Details</a>
          <a mat-tab-link>Discussion</a>
        </nav>


        <!-- Bid Item section -->
        <section *ngIf="reportMaterialDelivery.bid_item">
          <div class="bid-item link" (click)="openBidItemSelector()">
            <div class="mat-body">{{reportMaterialDelivery.bid_item?.description}}</div>
            <div class="mat-caption">UOM: {{reportMaterialDelivery.bid_item?.uom}}</div>
          </div>
        </section>
        <!-- /Bid Item section -->

        <!-- quantity section -->
        <section>
          <div class="mat-subheading-1 row">Quantity:</div>
          <div class="mat-body" *ngIf="!editing">{{reportMaterialDelivery.quantity}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="number" autocomplete="off" formControlName="quantity" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.quantity.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.quantity.hasError('error')">
              {{inputForm.controls.quantity.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /quantity section -->

        <!-- comment section -->
        <section>
          <div class="mat-subheading-1 row">Comment:</div>
          <div class="mat-body" *ngIf="!editing">{{reportMaterialDelivery.comment}}</div>
          <mat-form-field *ngIf="editing">
            <textarea matInput formControlName="comment" [errorStateMatcher]="matcher">
              </textarea>
            <mat-error *ngIf="inputForm.controls.comment.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.comment.hasError('error')">
              {{inputForm.controls.comment.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /comment section -->

        <!-- Annotations section -->
        <section>
          <div class="mat-subheading-1">Annotations:</div>
          <div class="preview-list">
            <div *ngFor="let ann of reportMaterialDelivery.annotations">
              <img [src]="ann.getThumbnail()" class="link" (click)="openAnnot(ann)" />
              <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
                (click)="removeAnnot(ann)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="input-placeholder small" (click)="openAnnot()">
              <button mat-icon-button [disabled]="!editing" color="accent">
                <mat-icon>add</mat-icon>
              </button>
            </div>
          </div>
        </section>
        <!-- /Annotations section -->

        <!-- Pictures section -->
        <section>
          <div class="mat-subheading-1">Pictures:</div>
          <div class="preview-list">
            <div *ngFor="let pic of reportMaterialDelivery.pictures">
              <img [src]="pic.getThumbnail()" class="link"
                (click)="appSrv.openCarousel($event, reportMaterialDelivery.pictures, pic)" />
              <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
                (click)="removePicture(pic)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <app-file-uploader class="input-placeholder small" [disabled]="!editing" icon="image" accept="image/*"
              (complete)="onUploadComplete($event)">
            </app-file-uploader>
          </div>
        </section>
        <!-- /Pictures section -->

        <!-- Tickets section -->
        <section>
          <div class="mat-subheading-1">Tickets:</div>
          <div class="preview-list">
            <div *ngFor="let ticket of reportMaterialDelivery.tickets">
              <img [src]="ticket.getThumbnail()" class="link"
                (click)="appSrv.openCarousel($event, reportMaterialDelivery.tickets, ticket)" />
              <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
                (click)="removeTicket(ticket)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <app-file-uploader class="input-placeholder small" [disabled]="!editing" icon="attach_file"
              accept="application/pdf" (complete)="onTicketUploadComplete($event)">
            </app-file-uploader>
          </div>
        </section>
        <!-- /Tickets section -->

        <!-- Form Actions -->
        <section>
          <div class="row" *ngIf="editing">
            <button [disabled]="!editable" mat-stroked-button color="accent" (click)="saveItem()">
              <ng-container *ngIf="reportMaterialDelivery.id">Update</ng-container>
              <ng-container *ngIf="!reportMaterialDelivery.id">Create</ng-container>
            </button>
            <button mat-stroked-button (click)="onCancel()">Cancel</button>
          </div>
        </section>
        <!-- Form Actions -->

      </div>
    </form>
  </ng-container>

</div>

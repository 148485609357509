<ng-container *ngIf="initialized">
  <form [formGroup]="inputForm">
    <div class="container">

      <!-- Msg -->
      <section *ngIf="materialTestReport.id">
        <div class="mat-body">Uploaded by {{materialTestReport.createdBy?.name}}</div>
        <div class="mat-caption">{{materialTestReport.created_at | dateFormat : 'MMMM DD, k:mm A'}}</div>
      </section>

      <!-- Attachments section -->
      <section>
        <div class="mat-subheading-1">Attachments:</div>
        <div class="preview-list">
          <app-file-uploader class="input-placeholder small" [disabled]="!editing" icon="attach_file" accept="image/*"
            (complete)="onUploadComplete($event)">
          </app-file-uploader>
          <div *ngFor="let att of materialTestReport.attachments">
            <img [src]="att.getThumbnail()" class="link"
            (click)="appSrv.openCarousel($event, materialTestReport.attachments, att)" />
            <button *ngIf="editing" mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
              (click)="removeAttachment(att)">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </div>
        <mat-error *ngIf="inputForm.controls.attachments.hasError('error')">
          {{inputForm.controls.attachments.errors.error}}
        </mat-error>
      </section>
      <!-- /Attachments section -->

      <!-- Comment section -->
      <section>
        <div class="mat-subheading-1">Report note:</div>
        <div class="mat-body" *ngIf="!editing">{{materialTestReport.comment}}</div>
        <mat-form-field *ngIf="editing">
          <textarea matInput formControlName="comment" [errorStateMatcher]="matcher"></textarea>
          <mat-error *ngIf="inputForm.controls.comment.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.comment.hasError('error')">
            {{inputForm.controls.comment.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /Comment section -->

      <!-- Form Actions -->
      <section>
        <div class="row" *ngIf="editing">
          <button mat-stroked-button color="accent" (click)="saveItem()">Upload report</button>
        </div>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
</ng-container>

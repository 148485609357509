
<div
  #trigger="cdkOverlayOrigin"
  (click)="isOpen = true" cdkOverlayOrigin class="filter-button status-filter payapp-multi-filter flex flex-row gap-[20px]" type="button"
>
  <div class="label text-type-8">{{ buttonLabel }}</div>
  <mat-icon
    class="filter-icon"
    svgIcon="filters"
  />
</div>

<ng-template
  (backdropClick)="isOpen = false"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  cdkConnectedOverlay
>
  <div class="project-details-page-status-filter-popup">
    <mat-tab-group>
      <mat-tab *ngIf="!isContractorMode" label="Status">
        <app-checkbox-list-with-filter
          (selectionChange)="onStatusCheckboxChange($event)"
          [displaySearch]="false"
          [displaySelectAll]="true"
          [options]="statusOptions"
          [parentFormGroup]="searchForm"
          controlName="statusSearch"
        />
      </mat-tab>
      <mat-tab *ngIf="isContractorMode" label="Status">
        <app-checkbox-list-with-filter
          (selectionChange)="onContractorStatusCheckboxChange($event)"
          [displaySearch]="false"
          [displaySelectAll]="true"
          [options]="contractorStatusOptions"
          [parentFormGroup]="searchForm"
          controlName="contractorStatusSearch"
        />
      </mat-tab>
      <mat-tab label="Sites">
        <app-checkbox-list-with-filter
          (selectionChange)="onSiteCheckboxChange($event)"
          [displaySearch]="true"
          [displaySelectAll]="true"
          [options]="sitesOptions"
          [parentFormGroup]="searchForm"
          controlName="sitesSearch"
        />
      </mat-tab>
      <mat-tab label="Created by">
        <app-checkbox-list-with-filter
          (selectionChange)="onCreatorCheckboxChange($event)"
          [displaySearch]="true"
          [displaySelectAll]="true"
          [options]="creatorOptions"
          [parentFormGroup]="searchForm"
          controlName="creatorsSearch"
        />
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-template>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../state/app.state';
import { ActivatedRoute, Router } from '@angular/router';
import { dailyReportsByDate, siteDetails } from '../../../../../core/projects.selectors';
import { takeUntil } from 'rxjs/operators';
import { ExecuteCurrentActionStateRequest } from '../../../../../core/projects.actions';
import { FormControl, FormGroup } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../../../../../../../shared/components';
import { ProjectsService } from '../../../../../core/projects.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-daily-reports-list',
  templateUrl: './daily-reports-list.component.html',
  styleUrls: ['./daily-reports-list.component.scss'],
})
export class DailyReportsListComponent implements OnInit, OnDestroy {
  @Input() selectedReportId = '';
  dailyReportsList: any[] = [];
  filteredDailyReportsList: any[] = [];
  site: any = {};
  projectId: any;
  siteId: any;
  form: FormGroup = new FormGroup({});
  formInputInfo: any;
  reportId: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private projectsService: ProjectsService,
    public dialog: MatDialog,
  ) {
    this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
      this.siteId = params['siteId'];
    });
    this.route.queryParams.subscribe(params => {
      this.reportId = params['reportId'];
    });
  }

  ngOnInit() {
    this.store.select(dailyReportsByDate)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(dailyReports => {
        this.dailyReportsList = dailyReports;
        this.filterReportsList();
      });

    this.store.select(siteDetails)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(site => {
        this.site = site;
        this.filterReportsList();
      });
  }

  filterReportsList() {
    if (this.site.id && this.dailyReportsList.length > 0) {
      this.filteredDailyReportsList = this.dailyReportsList.filter(o => o.site_id === this.site.id);
    } else {
      this.filteredDailyReportsList = [];
    }
    if (!this.selectedReportId && this.filteredDailyReportsList.length > 0) {
      this.selectReportId(this.filteredDailyReportsList[0].id);
    }

    const firstAction = this.filteredDailyReportsList?.[0]?.['next-actions']?.[0]?.actions?.[0];
    this.formInputInfo = firstAction?.options?.formInputs?.[0];
    this.form.addControl(`${this.formInputInfo?.name}`, new FormControl({ value: '', disabled: false }));
  }

  selectReportId(reportId) {
    if (this.selectedReportId === reportId) {
      reportId = null;
    }

    this.router.navigate([], {
      queryParams: {
        reportId,
        expandedSection: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  executeState(action) {
    const { wf, ex, state } = action;
    const qp = { wf, ex, state, include: '' };
    const formRawValues = this.form.getRawValue();
    const formValues = formRawValues[this.formInputInfo?.name];

    const payload = {
      executeCurrentActionStatePayload: {
        qp,
        input: {
          formValues,
          id: this.reportId,
        },
      },
      fetchNextActionsStatePayload: {
        qp: {
          wf,
          project_id: this.projectId,
          site_id: this.siteId,
          id: this.reportId,
        },
      },
    };

    const isFormDirty = this.projectsService.isAnyFormDirty.getValue();

    if (isFormDirty) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: new ConfirmDialogModel(
          'Unsaved changes',
          `There are unsaved changes.<br/>Are you sure you want to discard?`,
        ),
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.store.dispatch(ExecuteCurrentActionStateRequest({ payload }));
          this.projectsService.isAnyFormDirty.next(false);
        }
      });
    } else {
      this.store.dispatch(ExecuteCurrentActionStateRequest({ payload }));
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { DailyReport, Project, ReportActivity } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService, DailyReportService } from 'src/app/shared/services';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  public initialized: boolean = false;
  public loading: number = 0;
  public changes: number = 0;

  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;
  public dialogOptions: any = {};
  public componentResult: any = null;

  @Input() activities: ReportActivity[] = [];
  @Input() dr: DailyReport;
  @Input() project: Project;

  public selectedItem: ReportActivity;
  public lineItems: ReportActivity[] = [];

  public matcher = new AppErrorStateMatcher();
  public tools: any = {
    comment: false,
  };

  get hasDirtyItems(): boolean {
    return this.lineItems.map(li => li.isDirty)
      .reduce((p, c) => p || c, false);
  }

  constructor(
    private drSrv: DailyReportService,
    public appSrv: AppService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ActivitiesComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.isDialog) {
      const { activities, project, dailyReport, options } = this.inputData;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.project = project;
      this.activities = activities.slice();
      this.dr = dailyReport;
    }
    this.init();
  }

  init(): void {
    this.activities.map(o => this.addLineItem(o));
    if (!this.selectedItem) {
      this.selectLineItem(this.lineItems[0]);
    }
    this.initialized = true;
  }

  addLineItem(li: any) {
    li = li ?? new ReportActivity({
      daily_report_id: this.dr?.id,
    });

    this.toReactiveForm(li);
    this.lineItems.push(li);

    if (!li?.id) {  //open newly added lineitem
      this.selectLineItem(li);
    }
  }

  selectLineItem(li) {
    if (!li) {
      this.selectedItem = undefined;
      return;
    }

    if (this.selectedItem?._id !== li?._id) {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = li, 200);
    }
  }

  toReactiveForm(m: any) {
    let form = this.fb.group({
      id: [''],
      title: [m?.record_time, Validators.required],
    });

    form.reset({
      id: m?.id || null,
      title: m?.title,
    });
    m._inputForm = form;
  }

  onLineItemChange(li: ReportActivity) {
    const { pictures, annotations, location } = li;
    this.selectedItem.setValue('pictures', pictures);
    this.selectedItem.setValue('annotations', annotations);
    this.selectedItem.setValue('location', location);
  }

  onClose() {
    if (!this.hasDirtyItems) {
      this.dialogRef.close(this.changes);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Unsaved changes',
        `There are unsaved changes since the last opening of this item.
        <br />Are you sure you want to discard?`,
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(this.changes);
      }
    });
  }

  saveAll() {
    // dirty workaround to trigger validations
    // mark all forms as touched
    let isValid = true;
    this.lineItems.map((o) => {
      o._inputForm?.markAsDirty();
      o._inputForm?.markAllAsTouched();
      isValid = isValid && o._inputForm.valid;
    });

    if (!isValid) {
      // show some error
      return;
    }

    // attempt save
    Promise.all(this.lineItems.map((o) => this.save(o, { showMessage: false })))
      .then(() => {
        this.snackBar.open('Saved activities', '', { duration: 5000 });
        this.dialogRef.close(this.changes);
      });
  }

  /**
   * onSubmit() handler for station form
   * @param form FormGroup
   * @returns {void}
   */
  save(li: ReportActivity, options: any = {}): Promise<any> {
    const { showMessage = true } = options;
    const form = li._inputForm;
    if (!form.valid) {
      return;
    }

    const payload = li.toPayload({ ...form.value });
    const dr = new DailyReport({
      id: li?.daily_report_id,
    });
    const include = ['pictures', 'address', { annotation: ['drawing'] }]

    this.loading++;
    return this.drSrv.saveLineItem('activity', payload, dr, { include })
      .then((resp: ReportActivity) => {
        if (showMessage) {
          this.snackBar.open('Saved activity', '', { duration: 5000 });
        }
        this.changes++;

        li = Object.assign(li, new ReportActivity(resp));
        this.toReactiveForm(li);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes line item
   * @param li LineItem
   * @returns void
   */
  delete(li: ReportActivity): void {
    if (!li.id && li._id) {
      this.lineItems.splice(this.lineItems.findIndex(o => o._id === li._id), 1);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting activity cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.loading++;
      this.drSrv.deleteLineItem('activity', li, this.dr)
        .then(resp => {
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
          this.changes++;
          this.snackBar.open('Activity deleted', '', { duration: 5000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        })
        .finally(() => {
          this.loading--;
        });
    });
  }
}

<section class="tabs">
  <mat-tab-group class="toolbar" [(selectedIndex)]="selectedTabIndex" headerPosition="below" animationDuration="0"
    mat-align-tabs="center" color="primary">
    <mat-tab class="nullified"></mat-tab>
    <mat-tab *ngIf="tools.pictures">
      <ng-template mat-tab-label>
        <mat-icon (click)="tabClicked(1)">photo_camera</mat-icon>
      </ng-template>
      <ng-container *ngTemplateOutlet="PicturesTemplate"></ng-container>
    </mat-tab>
    <mat-tab *ngIf="tools.location">
      <ng-template mat-tab-label>
        <mat-icon (click)="tabClicked(2)">location_on</mat-icon>
      </ng-template>
      <ng-container *ngTemplateOutlet="LocationTemplate"></ng-container>
    </mat-tab>
<!--    <mat-tab *ngIf="tools.annotations">-->
<!--      <ng-template mat-tab-label>-->
<!--        <mat-icon (click)="tabClicked(3)">edit</mat-icon>-->
<!--      </ng-template>-->
<!--      <ng-container *ngTemplateOutlet="AnnotationTemplate"></ng-container>-->
<!--    </mat-tab>-->
    <mat-tab *ngIf="tools.comment">
      <ng-template mat-tab-label>
        <mat-icon (click)="tabClicked(4)">comment</mat-icon>
      </ng-template>
      <ng-container *ngTemplateOutlet="CommentTemplate"></ng-container>
    </mat-tab>

  </mat-tab-group>

</section>

<ng-template #PicturesTemplate>
  <section class="toolbar-body">
    <div class="mat-subheading-1">Pictures:</div>
    <div class="preview-list">
      <div *ngFor="let pic of pictures">
        <img [src]="pic.getThumbnail()" class="link" (click)="appSrv.openCarousel($event, pictures, pic)" />
        <button mat-icon-button aria-label="Delete" title="Delete" class="hover-icon" (click)="removePicture(pic)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <app-file-uploader class="input-placeholder small" icon="image" accept="image/*"
        (complete)="onPictureUpload($event)">
      </app-file-uploader>
    </div>
  </section>
</ng-template>

<ng-template #AnnotationTemplate>
  <section class="toolbar-body">
    <div class="mat-subheading-1">Annotations:</div>
    <div class="preview-list">
      <div *ngFor="let ann of annotations">
        <img [src]="ann.getThumbnail()" class="link" (click)="openAnnot(ann)" />
        <button mat-icon-button aria-label="Delete" title="Delete" class="hover-icon"
          (click)="removeAnnot(ann)">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <div class="input-placeholder small" (click)="openAnnot()">
        <button mat-icon-button color="accent">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #LocationTemplate>
  <section class="toolbar-body">
    <div class="mat-subheading-1">Location:</div>
    <div class="input-placeholder" *ngIf="location.isEmpty" (click)="updateLocation(location)">
      <button mat-icon-button color="accent">
        <mat-icon>location_on</mat-icon>
      </button>Add Location
    </div>

    <div (click)="updateLocation(location)" *ngIf="!location.isEmpty">
      <app-location-picker [isDialog]="false" [latitude]="location.lat" [longitude]="location.lng" height="100px"
        [readOnly]="true"></app-location-picker>
    </div>
  </section>
</ng-template>

<ng-template #CommentTemplate>
  <section class="toolbar-body">
    <div class="mat-subheading-1">Comment:</div>
    <mat-form-field appearance="outline">
      <textarea matInput rows="5" [(ngModel)]="comment" (change)="onCommentChange($event)"></textarea>
    </mat-form-field>
  </section>
</ng-template>

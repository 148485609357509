import { BaseModel, Account, Project, Attachment } from 'src/app/shared/models';
import * as moment from 'moment';
import { SubmittalLog } from './submittal-log';
import { SubmittalPackage } from './submittal-package';

export class Submittal extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'Submittal';
  }

  /* tslint:disable */
  submittal_no: string;
  revision: string;
  type: string;
  title: string;
  description: string;
  status: string;
  sent_date: string | Date | moment.Moment;
  due_date: string | Date | moment.Moment;
  returned_date: string | Date | moment.Moment;
  attachments: Attachment[];

  submittal_log: SubmittalLog;
  package: SubmittalPackage;
  project: Project;
  createdBy: Account;
  updatedBy: Account;

  // acl assist variables
  project_id: string;
  submittal_log_id: string;

  // internal variables
  _status: string;
  /* tslint:enable */

  init() {
    this.attachments = [];
  }

  get relations() {
    return {
      submittal_log: SubmittalLog,
      package: SubmittalPackage,
      createdBy: Account,
      updatedBy: Account,
      project: Project,
      attachments: Attachment,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    this.sent_date = this.sent_date && typeof this.sent_date === 'string' ? new Date(this.sent_date) : null;
    this.due_date = this.due_date && typeof this.due_date === 'string' ? new Date(this.due_date) : null;
    this.returned_date =
      this.returned_date && typeof this.returned_date === 'string' ? new Date(this.returned_date) : null;

    // add few attributes to assist acl module
    this.project_id = this.project?.id || this.project_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      submittal_no: this.submittal_no,
      type: this.type,
      title: this.title,
      description: this.description,
      submittal_log_id: this.submittal_log?.id || this.submittal_log_id,
      attachments: this.attachments.map(o => o.id),
    };
    return payload;
  }
}

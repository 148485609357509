import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { DailyReportService } from '../../../../daily-report.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-issue-details-wrapper',
  templateUrl: './issue-details-wrapper.component.html',
  styleUrls: ['./issue-details-wrapper.component.scss'],
})
export class IssueDetailsWrapperComponent implements OnInit, OnDestroy {
  @Input() data: any;
  disableOptionMenu = false;
  hideDeleteOption = false;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private dailyReportService: DailyReportService,
  ) {

    this.dailyReportService.disableOptionMenu
      .pipe(takeUntil(this.onDestroy))
      .subscribe(disableOptionMenu => {
        this.disableOptionMenu = disableOptionMenu;
      });

    this.dailyReportService.hideDeleteOption
      .pipe(takeUntil(this.onDestroy))
      .subscribe(hideDeleteOption => {
        this.hideDeleteOption = hideDeleteOption;
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { BaseModel } from './base-model';
import { Account } from './account';
import { Organization } from './organization';
import { Address } from './address';
import { Attachment } from './attachment';
import { Site } from './site';
import { ProjectReport } from './project-report';
import * as moment from 'moment';
import { Issue } from './issue';
import { Submittal } from 'src/app/modules/submittals/models';
import { DailyReport } from './daily-report';
import { Report } from './report';
import { Field } from './field';
import { BidItem } from './bid-item';
import { Material } from './material';
import { InternalTest } from './internal-test';

export class Project extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'Project';
  }

  /* tslint:disable */
  name: string;
  number: string;
  new_labor_format: boolean;
  offline_support: boolean;
  description: string;
  duration: number;
  duration_used: number;
  project_value: number;
  earned_value: number;
  latitude: any;
  longitude: any;
  status: string;
  address: Address;
  end_date: string | Date | moment.Moment;
  start_date: string | Date | moment.Moment;
  createdBy: Account;
  updatedBy: Account;
  owner_organization: Organization;
  cover_photo: Attachment;
  sites: Site[];
  accounts: Account[];
  reports: Report[];
  delivery_tracking_fields: Field[];
  bid_items: BidItem[];
  materials: Material[];
  internal_tests: InternalTest[];
  geometry: any;

  // acl assist variables
  owner_organization_id: string;
  _project_report: ProjectReport;
  _project_issue: Issue;
  _project_submittal: Submittal;
  _daily_report: DailyReport;
  _report: Report;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      owner_organization: Organization,
      address: Address,
      cover_photo: Attachment,
      sites: Site,
      accounts: Account,
      reports: Report,
      delivery_tracking_fields: Field,
      bid_items: BidItem,
      materials: Material,
      internal_tests: InternalTest,
    };
  }

  /**
   * Workaround to initialize default values
   */
  init() {
    this.duration_used = 0;
    this.earned_value = 0;
    this.sites = [];
    this.accounts = [];
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast

    // re-adjust dates to local timezones
    const tzOffset = new Date().getTimezoneOffset() * 60000;
    if (this.start_date && typeof this.start_date === 'string' && this.start_date.length === 10) {
      // this.start_date = new Date((new Date(this.start_date)).getTime() + tzOffset);
      this.start_date = new Date(`${this.start_date}T00:00:00`);
    }
    if (this.end_date && typeof this.end_date === 'string' && this.end_date.length === 10) {
      // Todo: fix Daylight savings is effecting tzOffset by +- 60 mins and end_date is sliding to prev day
      // this.end_date = new Date((new Date(this.end_date)).getTime() + tzOffset);
      this.end_date = new Date(`${this.end_date}T00:00:00`);
    }

    this.latitude = parseFloat(this.latitude);
    this.longitude = parseFloat(this.longitude);

    // add few attributes to assist acl module
    this.owner_organization_id = this.owner_organization?.id;
    this._project_report = new ProjectReport({ project_id: this.id });
    this._project_issue = new Issue({ project_id: this.id });
    this._project_submittal = new Submittal({ project_id: this.id });
    this._daily_report = new DailyReport({ project_id: this.id });
    this._report = new Report({ project_id: this.id });

    this.duration_used = this.duration_used || 0;
  }

  get durationProgress() {
    return (100 * this.duration_used) / this.duration;
  }

  get valueProgress() {
    return (100 * this.earned_value) / this.project_value;
  }

  /**
   * Returns true if project end_date is less than current date
   * @returns {boolean}
   */
  get ended() {
    return moment().isAfter(this.end_date);
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      name: this.name,
      number: this.number,
      description: this.description,
      start_date: moment(this.start_date).format('YYYY-MM-DD'),
      end_date: moment(this.end_date).format('YYYY-MM-DD'),
      project_value: this.project_value,
      duration: this.duration,
      latitude: this.latitude,
      longitude: this.longitude,
      cover_photo_id: this.cover_photo?.id,
      status: this.status,
      new_labor_format: this.new_labor_format || false,
      offline_support: this.offline_support,
      // address: this.address.toPayload(),
    };
    this.duration = payload.duration = moment(this.end_date).diff(moment(this.start_date), 'd') + 1;
    return payload;
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SubContractor, SubContractorService } from 'src/app/modules/sub-contractor';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { JobCode, Labor, Project } from 'src/app/shared/models';
import { AppErrorStateMatcher, JobCodeService, LaborService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-labors',
  templateUrl: './labors.component.html',
  styleUrls: ['./labors.component.scss'],
})
export class LaborsComponent implements OnInit {

  public loading = 0;
  public initialized = false;
  public editing = false;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };

  @Input() public project: Project;
  public labors: Labor[] = [];
  public labor: Labor;
  public newLabor: Labor;

  public jobCodes: JobCode[] = [];
  public subContractors: SubContractor[] = [];

  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcInputForm') vcInputForm;
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    first_name: ['', [Validators.required]],
    last_name: ['', [Validators.required]],
    default_job_code_id: ['', [Validators.required]],
    sub_contractor_id: [''],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private jobCodeSrv: JobCodeService,
    private subContractorSrv: SubContractorService,
    private laborSrv: LaborService,
  ) {
  }

  ngOnInit(): void {
    this.newLabor = new Labor({
      project: this.project,
    });
    this.fetchRecords();
    this.fetchJobCodes();
    this.fetchSubContractors();
  }

  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords();
  }

  /**
   * Fetches Labor[]
   */
  fetchRecords(): void {
    this.loading++;
    const qp = {
      include: ['default_job_code', 'sub_contractor'],
    };
    this.laborSrv.getRecords(this.project, this.pagination, qp)
      .then((resp: any) => {
        const { result, meta } = resp;
        this.labors = (result || []).map(o => {
          o.project_id = this.project?.id;
          return o;
        });
        this.pagination.length = meta?.totalRecords || 0;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Toggles input form
   * @param model BaseModel
   * @param show boolean
   */
  toggleForm(model: Labor = null, show = true): void {
    this.labor = model?.id ? model : new Labor({ project: this.project });
    this.editing = show;

    if (show) {
      this.vcInputForm?.nativeElement?.reset();
      this.inputForm.reset({
        id: model?.id || null,
        first_name: model?.first_name || null,
        last_name: model?.last_name || null,
        default_job_code_id: model?.default_job_code_id || null,
        sub_contractor_id: model?.sub_contractor_id || null,
      });
    }
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.toggleForm(null, false);
  }

  save(form: UntypedFormGroup = null): void {
    if (!form.valid) {
      return;
    }

    const payload = form.value;
    payload.project_id = this.project?.id;
    this.loading++;

    const qp = { include: ['default_job_code', 'sub_contractor'] };
    const req = payload?.id
      ? this.laborSrv.update(payload, qp)
      : this.laborSrv.create(payload, qp);

    req.then((labor: Labor) => {
      labor.project_id = this.project?.id;

      this.snackBar.open(
        `${this.labor?.id ? 'Updated' : 'Created'} ${labor?.first_name} ${labor?.last_name}`,
        '',
        { duration: 5000 },
      );


      const i = this.labors.findIndex(o => o.id === labor.id);
      if (i >= 0) {
        this.labors.splice(i, 1, labor);
      } else {
        this.labors.push(labor);
      }

      this.toggleForm(null, false);
    })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes Record
   * @param model BaseModel
   */
  delete(model: Labor): void {
    const message = `Deleting <b>${model.first_name} ${model.last_name}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.laborSrv.delete(model)
        .then((resp: any) => {
          this.labors.splice(
            this.labors.findIndex(o => o.id === model.id),
            1,
          );
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
    });
  }

  /**
   * Fetches JobCode[]
   */
  fetchJobCodes(): void {
    this.loading++;
    const qp = {
      include: [],
    };
    this.jobCodeSrv.getProjectRecords(this.project, qp)
      .then((jobCodes: JobCode[]) => {
        this.jobCodes = jobCodes;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Fetches SubContractor[]
   */
  fetchSubContractors(): void {
    this.loading++;
    const qp = {
      project_id: this.project?.id,
      include: [],
    };
    this.subContractorSrv.fetchRecords({ pageIndex: 0, pageSize: 10000 }, qp)
      .then((resp: any) => {
        this.subContractors = resp.result || [];
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

}

<div *ngIf="initialized">
  <h1>Select Drawing</h1>
  <div>
    <div *ngIf="!drawings.length" class="mat-body-strong">No drawings found for this project.</div>
    <div class="cards-container">
      <ng-container *ngFor="let drawing of drawings" >
        <div class="stroked-card"
          (click)="onSelected(drawing)"
          [class.selected]="selectedDrawing?.id === drawing.id">
          <div class="">{{drawing.original_name}}</div>
          <img [src]="drawing.thumb_url" alt="{{drawing.original_name}} Preview" />
          <div class="">{{drawing.tags}}</div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="header flex justify-between items-start">
    <div class="left flex gap-[20px]">
      <div class="image-section">
        <mat-icon
          class="category-icon"
          [svgIcon]="icon"
        />
      </div>
      <div
        class="details-section flex flex-col  items-start"
        [class.justify-center]="!!subheading"
        [class.justify-start]="!subheading"
      >
        <div class="heading text-type-6 mb-[4px]">{{ heading }}</div>
        <div class="subheading text-type-10">{{ subheading }}</div>
      </div>
    </div>
    <div class="right">
      <mat-icon
        class="chevron"
        [svgIcon]="isExpanded ? 'chevron-up' : 'chevron-down'"
      />
    </div>
  </div>
  <div class="content" *ngIf="isExpanded">
    <ng-content />
  </div>
</div>

import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-offsite-test-result-block',
  templateUrl: './offsite-test-result-block.component.html',
  styleUrls: [
    '../../../styles.css',
    '../styles.css',
    './offsite-test-result-block.component.scss',
  ],
})
export class OffsiteTestResultBlockComponent implements OnChanges {
  @Input() activity: any;
  @Input() data: any;
  @Input() isDisabled: boolean;

  isExpanded = false;
  status = '';

  constructor() {

  }

  ngOnChanges() {
    this.getStatus();
  }

  getStatus() {
    if (!this.activity.passed && !this.activity.failed) {
      this.status = 'Waiting for result';
      return;
    }

    if (this.activity.passed) {
      this.status = 'Passed';
      return;
    }

    if (this.activity.failed) {
      this.status = 'Failed';
      return;
    }
  }
}

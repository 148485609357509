import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { Lookup, Project } from 'src/app/shared/models';
import { AppErrorStateMatcher, LookupService } from 'src/app/shared/services';

@Component({
  selector: 'app-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.scss']
})
export class LookupsComponent implements OnInit {
  public loading = 0;
  public initialized: boolean = false;
  public editing: boolean = false;

  @Input() canEdit: boolean = true;

  @Input() project: Project;
  @Input() type: string;

  public lookups: Lookup[] = [];
  public lookup: Lookup;

  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcInputForm') vcInputForm;
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    value: ['', [Validators.required]],
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private lookupSrv: LookupService
  ) { }

  ngOnInit(): void {
    this.fetchRecords(this.project?.id, this.type);
  }

  /**
   * Fetches project Lookups
   * @param id Project.id
   * @param type string
   */
  fetchRecords(id: string, type: string): void {
    this.loading++;
    this.lookupSrv.getProjectRecords(type, id)
      .then((resp: Lookup[]) => {
        this.lookups = resp;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Toggles input form with Lookup
   * @param lookup Lookup
   * @param show boolean
   */
  toggleForm(lookup: Lookup = null, show = true): void {
    this.lookup = lookup?.id ? lookup : new Lookup();
    this.editing = show;

    if (show) {
      this.vcInputForm?.nativeElement?.reset();
      this.inputForm.reset({
        id: lookup?.id || null,
        value: lookup?.value || null,
      });
    }
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.toggleForm(null, false);
  }

  /**
   * Saves lookup
   * @returns {void}
   */
  save(form: UntypedFormGroup = null): void {
    if (!form.valid) {
      return;
    }

    const payload = form.value;
    this.loading++;

    const req = this.lookupSrv.create(this.project?.id, this.type, payload);

    req
      .then((lookup: Lookup) => {
        if (form) {
          this.snackBar.open(
            `${this.lookup?.id ? 'Updated' : 'Created'} lookup ${lookup?.value}`,
            '',
            { duration: 5000 },
          );
        }

        const i = this.lookups.findIndex(o => o.id === lookup.id);
        if (i >= 0) {
          this.lookups.splice(i, 1, lookup);
        } else {
          this.lookups.push(lookup);
        }

        this.toggleForm(null, false);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

}

<div class="project-card">
  <div class="project-details-section flex justify-between items-start">
    <div class="left">
      <div
        *ngIf="moment(project.end_date, 'YYYY-MM-DD').isBefore(moment()) ||
        project.status === 'closed'"
        class="closed"
      >closed</div>
      <div class="project-image-container">
        <img class="img" [src]="project.cover_photo?.thumb_url"
             alt="{{ project.name }} picture"/>
      </div>
      <div class="project-description-section">
        <div class="project-title text-type-2"
             (click)="redirectToProjectDetails(project)">{{ project.name }}</div>
        <div class="project-sub-heading text-type-10">{{ project.number }}</div>
        <div class="contract-details">
          <div class="contract-duration">
            <app-contract-progress-card
              heading="Contract duration"
              percent="{{ project.durationProgress }}"
              caption="Used: {{ project.duration_used }} of {{ project.duration }} days"
            />
          </div>
          <div class="contract-value">
            <app-contract-progress-card
              heading="Contract value"
              percent="{{ project.valueProgress }}"
              caption="Earned: {{ project.earned_value | humanReadable : 1 }} / {{
              project.project_value | humanReadable : 1
            }}"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div
        class="menu-button"
        [matMenuTriggerFor]="menu"
      >
        <mat-icon>more_vert</mat-icon>
      </div>

      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          class="text-type-13 each-menu"
          (click)="editProject(project)"
        >
          <mat-icon class="menu-icon">edit</mat-icon>
          <div class="menu-text">Edit</div>
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="issues-summary-section">
    <app-issues-summary
      [data]="project?.summary?.issues[0] || {}"
      [projectId]="project?.id"
    />
  </div>
  <div class="project-status-summary">
    <app-status-summary
      class="cursor-pointer"
      [data]="project?.summary?.daily_reports"
      [projectId]="project?.id"
    />
  </div>
</div>

import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BidItem, BidSheet, Heading, ReportBidItem, Site } from '../models';
import { AppHttpService } from './app-http.service';
import { saveAs } from 'file-saver';
import { Util } from '../helpers';

@Injectable({ providedIn: 'root' })
export class BidService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  getBidSheet(projectId: string, qp = {}) {
    return this.appHttpService.getService(`/v1/bid/${projectId}/sheet`, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new BidSheet(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Deletes bidItem
   * @param bidItem BidItem
   */
  delete(bidItem: BidItem) {
    // Todo check acl here
    return this.appHttpService.deleteService(`/v1/bid/item/${bidItem.id}`);
  }

  /**
   * Create a new Bid Item
   * @param payload object
   */
  createBidItem(payload: any, qp: any = {}) {
    return this.appHttpService.postService(`/v1/bid/sheet/${payload.bid_sheet_id}/item`, payload, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new BidItem(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Update Bid Item
   * @param id string
   * @param payload object
   */
  updateBidItem(id, payload: any, qp: any = {}) {
    return this.appHttpService.putService(`/v1/bid/item/${id}`, payload, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new BidItem(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Create new bidsheet for project
   * @param project Project
   */
  createSheet(project) {
    const body = { name: `bid sheet` };
    return this.appHttpService.postService(`/v1/bid/${project.id}/sheet`, body).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new BidSheet(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Fetches bid items
   * @param bidSheet BidSheet
   * @param pagination Pagination
   * @param qp query-params
   * @returns {Promise<any>}
   */
  getBidItems(bidSheet: BidSheet, pagination: any = {}, qp: any = {}): Promise<any> {
    qp = Object.assign({
      start: (pagination.pageIndex ?? 0) * (pagination.pageSize ?? 10),
      total: pagination.pageSize ?? 10,
    }, qp);
    return this.appHttpService.getService(`/v1/bid/sheet/${bidSheet.id}/item`, qp).toPromise()
      .then((resp: any) => {
        if (Array.isArray(resp.data)) {
          return {
            result: resp.data.map(o => new BidItem(o)),
            meta: Object.assign(resp.meta || {}, { type: resp.type }),
          };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Downloads bid sheet as csv
   * @param sheet BidSheet
   * @param qp query-params
   * @returns {Promise}
   */
  downloadBidSheet(sheet: BidSheet, qp: any = {}): Promise<any> {
    return this.appHttpService.getService(`/v1/bid/sheet/${sheet.id}/csv`, qp, { responseType: 'blob' })
      .toPromise()
      .then((resp: Blob) => {
        const name = `${sheet.project?.number || sheet.project?.name} bidsheet.csv`
        return saveAs(resp, name.trim());
      });
  }

  /**
   * Fetches BidItem
   * @param id string
   * @param qp query params
   * @returns {Promise<BidItem>}
   */
  getBidItem(id: string, qp: any = {}): Promise<BidItem> {
    return this.appHttpService.getService(`/v1/bid/item/${id}`, qp)
      .toPromise()
      .then((resp: any) => new BidItem(resp.data));
  }

  /**
   * Add heading to biditem
   * @param heading Heading
   * @param biditem Biditem
   * @returns {Promise<string>} message
   */
  addHeading(heading: Heading, biditem: BidItem): Promise<string> {
    return this.appHttpService
      .putService(`/v2/heading/${heading?.id}/bid-item/${biditem.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  /**
   * Remove heading from biditem
   * @param heading Heading
   * @param biditem Biditem
   * @returns {Promise<string>} message
   */
  removeHeading(heading: Heading, biditem: BidItem): Promise<string> {
    return this.appHttpService
      .deleteService(`/v2/heading/${heading?.id}/bid-item/${biditem.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  getRollup(biditem: BidItem, headerItem: ReportBidItem, lineItems: ReportBidItem[] = []) {

    const { rollup_field: rollupField, headings, record_by_station } = biditem;
    const heading = headings.find(o => headerItem?.heading?.id === o.id || headerItem?.heading_id === o.id);
    if (!rollupField || !record_by_station || !heading || !heading.stations?.length) {
      return headerItem?.quantity || 0;
    }

    lineItems.map((li: any, i) => {
      if (li.station_id && !li?.station) {
        li.station = (heading.stations || []).find(o => o.id === li.station_id);
      }
    });

    heading.sortLineItems(lineItems); // sort lineitems as server won't give same order

    lineItems.map((li: any, i) => {
      if (i === 0) { // do not calc rollup for first li
        li.quantity = 0;
        return;
      }
      const prev = lineItems[i - 1];
      const distance = heading.getDistance(prev.station, li.station);
      const prevValue = Number.parseFloat(prev.getFieldValue(rollupField, { getDirty: true }));
      const currValue = Number.parseFloat(li.getFieldValue(rollupField, { getDirty: true }));

      if (Util.isNumeric(prevValue) && Util.isNumeric(currValue)) {
        li.quantity = distance * ((prevValue + currValue) / 2);
      }
    });
    return lineItems.map(li => li.quantity).reduce((p, c) => (p + c), 0);
  }

  updateSiteQuantity(biditem: BidItem, site: Site, qty: number): Promise<BidItem> {
    return this.appHttpService
      .putService(`/v2/bid-item/${biditem?.id}/site/${site.id}/${qty}`, {})
      .toPromise();
  }
}

import { Account, BaseModel, Project } from 'src/app/shared/models';

export class GuestAccount extends BaseModel {
  // for CASL subject detection on code minification
  static get modelName() {
    return 'GuestAccount';
  }

  /* tslint:disable */
  email: string;
  phone: string;
  display_name: string;
  status: string;

  /* tslint:enable */

  get name() {
    if (this.display_name) {
      return `${this.display_name} ${this.email || this.phone}`;
    }
    return this.email || this.phone;
  }
}

import { BaseModel } from './base-model';
import { Account} from './account';
import { Project } from './project';
import { BidItem } from './bid-item';

export class Material extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Material'; }

  /* tslint:disable */
  name: string;
  uom: string;
  track_received: boolean;
  track_used: boolean;
  status: string
  createdBy: Account;
  updatedBy: Account;
  project: Project;
  project_id: string;
  bid_items: BidItem[];
  site_total_received: number;
  site_total_used: number;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      project: Project,
      bid_items: BidItem,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.project_id = this.project?.id || this.project_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      name: this.name,
      uom: this.uom,
      track_received: this.track_received,
      track_used: this.track_used,
    };
  }
}

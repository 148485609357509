<div [style.max-height]="maxHeight" class="wrapper">
  <div class="pictures-section-label">PICTURE:</div>
  <div class="pictures-row flex flex-wrap gap-[20px]">
    <ng-container *ngFor="let picture of pictures">
      <div class="picture-block">
        <mat-icon
          (click)="isEditable && deletePicture(picture)"
          [class.editable]="isEditable"
          class="delete-picture"
          svgIcon="trash"
        />

        <ng-container *ngIf="picture.fileType === 'picture'; else video">
          <app-image
            (click)="appService.openCarousel($event, pictures, picture)"
            [url]="picture.thumb_url"
            class="each-picture"
          />
        </ng-container>
        <ng-template #video>
          <div (click)="appService.openCarousel($event, pictures, picture)"
               [matTooltipDisabled]="picture?.preview_gif_url"
               [matTooltip]="!picture?.preview_gif_url ? 'Your video is still processing' : ''"
               class="media-container flex flex-row justify-center items-center"
          >
            <!-- Display image or GIF -->
            <app-shimmer-loader
              *ngIf="loading"
              [appearance]="loaderAppearance"
              [count]="1"
              [theme]="loaderTheme"
              class="loader"
            />
            <img
              (error)="handleError()"
              (load)="hideLoader()"
              [class.hidden]="loading"
              [src]="picture?.preview_gif_url || dummyUrl"
              alt="Media"
              class="image"
            >

            <!-- Show video length overlay if the fileType is video -->
            <div *ngIf="picture.fileType === 'video' && picture?.preview_gif_url" class="video-length text-type-7">
              {{ picture.duration | videoDuration }}
            </div>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </div>
</div>

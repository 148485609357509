<div class="wrapper">
  <form>
    <app-dropdown
      (checkForErrors)="checkForErrors($event)"
      [enableAddItem]="true"
      [enableSearch]="true"
      [error]="errorMessages['type']"
      [options]="equipmentOptions"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="type"
      label="Equipment"
    />
    <div class="flex flex-row justify-between items-center mb-[20px] gap-[20px] w-full">
      <div class="label text-type-13 ">
        RENTAL:
      </div>
      <mat-slide-toggle
        (change)="onToggle($event)"
        [checked]="isRental"
        [disableRipple]="false"
        class="slide-toggle-btn"
        color="primary"
      >
      </mat-slide-toggle>
    </div>

    <app-dropdown
      (checkForErrors)="checkForErrors($event)"
      [enableClearSelection]="true"
      [error]="errorMessages['sub_contractor']"
      [options]="subContractorOptions"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="sub_contractor"
      label="SubContractor"
    />
    <app-number-input
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['quantity']"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="quantity"
      label="quantity"
    />
    <div class="flex flex-row justify-center items-center mb-[20px] gap-[20px] w-full">
      <app-number-input
        [parentFormGroup]="form"
        class="w-1/2"
        controlName="duration"
        label="hours"
      />
      <app-number-input
        [parentFormGroup]="form"
        class="w-1/2"
        controlName="idle_duration"
        label="Hours Idle"
      />
    </div>

    <app-text-area
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="comment"
      label="comment"
    />

  </form>
</div>

<div
  *ngIf="parentFormGroup &&
          parentFormGroup.controls &&
          parentFormGroup.controls[this.controlName]"
>
  <mat-form-field
    [formGroup]="parentFormGroup"
    hideRequiredMarker
    appearance="legacy"
    class="form-field text-area w-full"
    floatLabel="always"
  >
    <mat-label class="text-type-13">{{ label }}:</mat-label>
    <input
      type="text"
      [placeholder]="placeholder"
      aria-label="Number"
      matInput
      [formControlName]="controlName"
      [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="error">{{ error }}</mat-error>
  </mat-form-field>

</div>

import { Component, OnInit } from '@angular/core';
import { AppService, ProjectService } from 'src/app/shared/services';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Project } from 'src/app/shared/models';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ProjectCreateComponent } from './project-create/project-create.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit {
  public loading = 0;
  public initialized = false;
  public projects = [];
  public errorMessage: string;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };

  constructor(
    private appService: AppService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.fetchRecords();
  }

  fetchRecords() {
    this.loading++;

    this.projectService.getRecords(this.pagination).subscribe({
      next: (resp: any) => {
        this.initialized = true;
        this.loading--;
        this.projects = resp.result;
        this.pagination.length = resp.meta.totalRecords || 0;
      },
      error: err => {
        this.initialized = true;
        this.loading--;
        this.errorMessage = err.message || 'Oops! something went wrong.';
      },
    });
  }

  /**
   * Deletes a project
   * @param project Project
   */
  delete(project: Project) {
    const message = `Deleting ${project.name} cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.projectService.delete(project).subscribe({
        next: (resp: any) => {
          this.projects.splice(
            this.projects.findIndex(o => o.id === project.id),
            1
          );
          this.pagination.length--;
          this.snackBar.open(resp.message, '', { duration: 3000 });
        },
        error: (err: any) => {
          this.snackBar.open(err.error.error, '', { duration: 3000 });
        },
      });
    });
  }

  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords();
  }

  /**
   * Open Project in sidebar
   * @param instance issue
   */
  addProject(model: Project = null) {
    const sidebarRef = this.appService.openSidebar(ProjectCreateComponent, {
      data: model || {},
      editable: true,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof Project) {
        const i = (this.projects || []).findIndex(o => o.id === resp.id);
        if (i >= 0) {
          this.projects[i] = Object.assign(this.projects[i], resp);
        } else {
          this.projects.push(resp);
          this.pagination.length++;
        }
      }
    });
  }

  /**
   * callback for project change
   * @param project Project
   */
  onProjectChange(project: Project) {
    const i = (this.projects || []).findIndex(o => o.id === project.id);
    if (i >= 0) {
      this.projects[i] = Object.assign(this.projects[i], project);
    } else {
      this.projects.push(project);
      this.pagination.length++;
    }
  }
}

<div class="wrapper flex flex-col gap-[40px]">
  <section class="header-section">
    <app-image
      alt="{{ projectDetails?.name }}_picture"
      class="project-image"
      url="{{projectDetails?.cover_photo?.thumb_url}}"
    />
    <div class="heading-section">
      <div class="heading text-type-2">{{ projectDetails?.name }}
      </div>
      <div class="sub-heading text-type-10">
        {{ projectDetails?.owner_organization?.name }}
        ({{ sitesCount }} site{{ sitesCount !== 1 ? 's' : '' }})
      </div>
    </div>
  </section>
  <section class="content-section">
    <div
      *ngIf="selectedTabIndex === 0"
      class="filters-section flex flex-row gap-[20px]"
    >
      <app-project-details-page-status-site-filter
        [projectId]="projectDetails?.id || null"
      />
      <app-project-details-page-date-filter
        [defaultDateValue]="defaultDateValue"
      />
    </div>
    <mat-tab-group
      #tabGroup
      [selectedIndex]="selectedTabIndex"
      class="contents-tab-group"
    >
      <mat-tab label="Sites">
        <app-site-wise-reports class="tab-content" />
      </mat-tab>
      <mat-tab label="Consolidated reports">
        <app-consolidated-report-wrapper class="tab-content" />
      </mat-tab>
      <mat-tab *ngIf="!isContractorMode" label="Settings">
        <app-each-project-settings class="tab-content" />
      </mat-tab>
      <mat-tab
        label="Payapp"
      >
        <app-pay-app class="tab-content"
        />
      </mat-tab>
      <mat-tab *ngIf="!isContractorMode" label="Change orders">
        <app-bid-items-list class="tab-content" />
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

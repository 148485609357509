import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { WorkflowAction, WorkflowLineage } from '../models';

@Injectable({ providedIn: 'root' })
export class WorkflowService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Fetches next actions in given workflow
   * @param wf string
   * @param id subject.id
   * @param projectId Project.id
   */
  getNextActions(wf: string, id: string, projectId: string) {
    const qp = { wf, id, project_id: projectId };
    return this.appHttpService.getService('/v1/workflow/next-state', qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(o => new WorkflowAction(o));
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Execute workflow-actions via REST
   * @param ex ex-id
   * @param wf workflow
   * @param state state
   * @param projectId Project.id
   * @param input input-params
   */
  executeState(ex: string, wf: string, state: string, projectId: string, input: any) {
    const qp = { wf, ex, state, project_id: projectId, include: '' };
    const payload = { input };
    return this.appHttpService.postService('/v1/workflow/execute', payload, qp);
  }

  /**
   * Fetches workflow lineage
   * @param ex execution id
   * @param pagination pagination obj
   * @param qp query-params
   */
  fetchLineage(ex: any, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    qp.ex = ex;
    return this.appHttpService.getService('/v1/workflow/lineage', qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new WorkflowLineage(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

}

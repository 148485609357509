/* NgRx */
import { createAction, props } from '@ngrx/store';

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[shared] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** LOADING ***********/
const START_LOADING = '[shared] start loading';
export const StartLoading = createAction(START_LOADING);

const STOP_LOADING = '[shared] stop loading';
export const StopLoading = createAction(STOP_LOADING);

const CLEAR_LOADING = '[shared] clear loading';
export const ClearLoading = createAction(CLEAR_LOADING);

/******** FETCH APP DATA ***********/
const FETCH_APP_DATA_REQUEST = '[shared] fetch app data request';
export const FetchAppDataRequest = createAction(FETCH_APP_DATA_REQUEST);

const FETCH_APP_DATA_SUCCESS = '[shared] fetch app data success';
export const FetchAppDataSuccess = createAction(FETCH_APP_DATA_SUCCESS, props<any>());

const FETCH_APP_DATA_FAILURE = '[shared] fetch app data failure';
export const FetchAppDataFailure = createAction(FETCH_APP_DATA_FAILURE, props<any>());

/******** UPLOAD FILE ***********/
const UPLOAD_FILE_REQUEST = '[projects]  upload file request';
export const UploadFileRequest = createAction(UPLOAD_FILE_REQUEST, props<any>());

const UPLOAD_FILE_SUCCESS = '[projects]  upload file success';
export const UploadFileSuccess = createAction(UPLOAD_FILE_SUCCESS, props<any>());

const UPLOAD_FILE_FAILURE = '[projects]  upload file failure';
export const UploadFileFailure = createAction(UPLOAD_FILE_FAILURE);

/******** GET VIDEO BY ID ***********/
const GET_VIDEO_BY_ID_REQUEST = '[projects] get video by id request';
export const GetVideoByIdRequest = createAction(GET_VIDEO_BY_ID_REQUEST, props<any>());

const GET_VIDEO_BY_ID_SUCCESS = '[projects] get video by id success';
export const GetVideoByIdSuccess = createAction(GET_VIDEO_BY_ID_SUCCESS);

const GET_VIDEO_BY_ID_FAILURE = '[projects] get video by id failure';
export const GetVideoByIdFailure = createAction(GET_VIDEO_BY_ID_FAILURE);

/******** GET VIDEO ID ***********/
const GET_VIDEO_ID_REQUEST = '[projects] get video id request';
export const GetVideoIdRequest = createAction(GET_VIDEO_ID_REQUEST, props<any>());

const GET_VIDEO_ID_SUCCESS = '[projects] get video id success';
export const GetVideoIdSuccess = createAction(GET_VIDEO_ID_SUCCESS, props<any>());

const GET_VIDEO_ID_FAILURE = '[projects] get video id failure';
export const GetVideoIdFailure = createAction(GET_VIDEO_ID_FAILURE);

/******** UPLOAD VIDEO FILE ***********/
const UPLOAD_VIDEO_FILE_REQUEST = '[projects] upload video file request';
export const UploadVideoFileRequest = createAction(UPLOAD_VIDEO_FILE_REQUEST, props<any>());

const UPLOAD_VIDEO_FILE_SUCCESS = '[projects] upload video file success';
export const UploadVideoFileSuccess = createAction(UPLOAD_VIDEO_FILE_SUCCESS);

const UPLOAD_VIDEO_FILE_FAILURE = '[projects] upload video file failure';
export const UploadVideoFileFailure = createAction(UPLOAD_VIDEO_FILE_FAILURE);

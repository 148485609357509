<button [mat-dialog-close]="componentResult" class="dialog-close" mat-mini-fab aria-label="Close Sidebar"
  *ngIf="dialogOptions.closable">
  <mat-icon title="Close Sidebar">close</mat-icon>
</button>
<div class="container" *ngIf="initialized">

  <section>
    <div class="temp-display">
      <div class="mat-display-1" *ngIf="!editing">{{weather.avgtemp}}&deg;</div>
      <mat-form-field *ngIf="editing">
        <input matInput type="number" [(ngModel)]="weather.avgtemp" autocomplete="off" />
      </mat-form-field>
      <div class="">
        <app-icon icon="weather/{{weather.getIcon}}.png"></app-icon>
      </div>
      <div class="icons">
        <button [disabled]="!('create' | able: dailyReport)" mat-icon-button (click)="toggleEdit()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
    <div class="mat-caption">Temperature in {{weather.location}}</div>
  </section>

  <section>
    <div class="section-label">Temperature:</div>
    <div class="row">
      <div class="mat-body">
        Highest:
        <ng-container *ngIf="!editing">{{weather.maxtemp}}&deg;</ng-container>
        <mat-form-field *ngIf="editing">
          <input matInput type="number" [(ngModel)]="weather.maxtemp" />
        </mat-form-field>
      </div>
      <div class="mat-body">
        Lowest:
        <ng-container *ngIf="!editing">{{weather.mintemp}}&deg;</ng-container>
        <mat-form-field *ngIf="editing">
          <input matInput type="number" [(ngModel)]="weather.mintemp" />
        </mat-form-field>
      </div>
    </div>
  </section>

  <section>
    <div class="section-label">Sky condition:</div>
    <div class="mat-body">
      <ng-container *ngIf="!editing">{{weather.condition?.text}}</ng-container>
      <mat-form-field *ngIf="editing">
        <mat-select
          (selectionChange)="onWeatherConditionChange($event.value)"
          [(value)]="weather.condition.code">
          <mat-option *ngFor="let wc of weatherConditions" [value]="wc.code">
            {{wc.text}}
          </mat-option>
      </mat-select>
      </mat-form-field>
    </div>
  </section>

  <section>
    <div class="section-label">Wind conditions(mph):</div>
    <div class="mat-body">
      <ng-container *ngIf="!editing">{{weather.wind}}</ng-container>
      <mat-form-field *ngIf="editing">
        <input matInput type="number" [(ngModel)]="weather.wind" />
      </mat-form-field>
    </div>
  </section>

  <section>
    <div class="section-label">Note:</div>
    <div class="mat-body">
      <ng-container *ngIf="!editing">{{weather.notes}}</ng-container>
      <mat-form-field *ngIf="editing" class="full-width">
        <textarea matInput type="number" [(ngModel)]="weather.notes"></textarea>
      </mat-form-field>
    </div>
  </section>

  <div class="row" *ngIf="editing">
    <button [disabled]="!('create' | able: dailyReport)" mat-stroked-button color="accent" (click)="saveItem()">
      Save
    </button>
    <button mat-stroked-button (click)="onCancel()">Cancel</button>
  </div>

</div>

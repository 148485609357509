import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BidItem, Field, InternalTest } from '../../models';
import { AppErrorStateMatcher, FieldService } from '../../services';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogComponent, ConfirmDialogModel } from '../confirm-dialog/confirm-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss']
})
export class FieldsComponent implements OnInit {
  public loading = 0;
  public initialized: boolean = false;
  public editing: boolean = false;

  @Input() canEdit: boolean = true;
  @Input() entityType: string; // bid-item, internal-test
  @Input() entity: BidItem|InternalTest;
  @Input() stationProperties: string[] = [];
  @Input() captureRecentField: boolean = false;
  @Input() recordRange: boolean = false;
  @Input() context: string = 'fields';

  public fieldTypes: string[] = Field.types;
  public fields: Field[] = [];
  public field: Field;


  public matcher = new AppErrorStateMatcher();
  @ViewChild('vcInputForm') vcInputForm;
  public inputForm: UntypedFormGroup = this.fb.group({
    id: [''],
    display_order: [''],
    name: ['', [Validators.required]],
    label: ['', [Validators.required]],
    type: ['', [Validators.required]],
    uom: [''],
    estimate_station_property: [''],
    validation_rules: this.fb.group({
      required: ['', [Validators.required]],
      min: [''],
      max: [''],
    }),
    recent_field_id: [''],
    recent_field_label: [''],
    is_optional: ['', [Validators.required]],
    range_values: this.fb.group({
      min: [''],
      max: [''],
    }),
  });

  constructor(
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private fieldSrv: FieldService
  ) { }

  ngOnInit(): void {
    this.fetchFields(this.entityType, this.entity, this.context);
  }

  /**
   * Fetches fields
   * @param type string bid-item, internal-test
   * @param entity BidItem|InternalTest
   */
  fetchFields(type: string, entity: BidItem|InternalTest, context: string = 'fields'): void {
    this.loading++;
    const qp = { context };
    this.fieldSrv.getRecords(type, entity?.id, qp)
      .then((fields: Field[]) => {
        this.fields = fields;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
        this.initialized = true;
      });
  }

  /**
   * Toggles input form with Field
   * @param field Field
   * @param show boolean
   */
  toggleForm(field: Field = null, show = true): void {
    this.field = field?.id ? field : new Field();
    this.editing = show;

    if (show) {
      this.vcInputForm?.nativeElement?.reset();
      this.inputForm.reset({
        id: field?.id || null,
        display_order: field?.display_order >= 0 ? field.display_order : this.fields.length,
        name: field?.name || null,
        label: field?.label || null,
        type: field?.type || null,
        uom: field?.uom || null,
        estimate_station_property: field?.estimate_station_property || null,
        validation_rules: {
          required: field?.validation_rules?.required || false,
          min: field?.validation_rules?.min,
          max: field?.validation_rules?.max,
        },
        recent_field_id: field?.recent_field_id || null,
        recent_field_label: field?.recent_field_label || null,
        is_optional: field?.is_optional || false,
        range_values: {
          min: field?.range_values?.min,
          max: field?.range_values?.max,
        }
      });
    }
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.toggleForm(null, false);
  }

  /**
   * Saves field
   * @returns {void}
   */
  save(form: UntypedFormGroup = null): void {
    if (!form.valid) {
      return;
    }

    const payload = form.value;
    this.loading++;

    const qp = {
      context: this.context,
      include: [],
    };
    const req = payload?.id
      ? this.fieldSrv.update(payload, qp)
      : this.fieldSrv.create(this.entityType, this.entity?.id, payload, qp);

    req
      .then((field: Field) => {
        if (form) {
          this.snackBar.open(
            `${this.field?.id ? 'Updated' : 'Created'} field ${field?.label}`,
            '',
            { duration: 5000 },
          );
        }

        const i = this.fields.findIndex(o => o.id === field.id);
        if (i >= 0) {
          this.fields.splice(i, 1, field);
        } else {
          this.fields.push(field);
        }

        this.toggleForm(null, false);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * Deletes Field
   * @param field
   */
  delete(field: Field): void {
    const message = `Deleting <b>${field.label}</b> cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.fieldSrv.delete(field)
        .then((resp: any) => {
          this.fields.splice(
            this.fields.findIndex(o => o.id === field.id),
            1,
          );
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
    });
  }

  /**
   * Prepopulate name as Label.toPascalCase()
   * @param event
   */
  onLabelChange(event: FocusEvent): void {
    const label = (event.target as any).value || '';
    if (this.inputForm.controls.name.value || !label) {
      return;
    }
    // name to pascal case of label
    const pascal = (label.match(/[a-zA-Z0-9]+/g) || [])
      .map(w => `${w.charAt(0).toUpperCase()}${w.slice(1)}`).join('')
    this.inputForm.controls.name.setValue(pascal);
  }

  /**
   * Callback on drag and drop change
   * @param event CdkDragDrop
   */
  drop(event: CdkDragDrop<Field[]>) {
    if (!this.canEdit) {
      return;
    }

    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
    const fs = this.fields.map((o, i) => {
      o.display_order = i;
      return o;
    });
    this.fieldSrv.changeOrder(this.entityType, this.entity?.id, this.fields)
        .then((resp: any) => {
          this.snackBar.open(resp.message, '', { duration: 3000 });
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(
            resp.error?.error || 'Oops! something went wrong.',
            '',
            { duration: 5000 },
          );
        });
  }

  hasRange(obj: any): boolean {
    return ('min' in (obj || {}) && obj.min !== null && obj.min !== '')
    || ('max' in (obj || {}) && obj.max !== null && obj.max !== '');
  }
}

import { BaseModel } from './base-model';
import { Account } from './account';
import { Project } from './project';
import * as moment from 'moment';
import { Attachment } from './attachment';
import { BidItem } from './bid-item';
import { Annotation } from './annotation';
import { Site } from './site';

export class Issue extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Issue'; }

  /* tslint:disable */
  title: string;
  description: string;
  type: string;
  entity_type: string;
  status: string;
  closed_at: Date | any;
  latitude: number;
  longitude: number;
  internal: boolean;

  pictures: Attachment[];
  attachments: Attachment[];
  entity: Site | Project;
  bid_item: BidItem;
  annotation: Annotation;
  createdBy: Account;
  updatedBy: Account;
  closedBy: Account;
  project: Project;
  /* tslint:enable */

  // for internal purposes
  checked: boolean = false;
  project_id: string;
  entity_id: string;
  site_id: string;

  get relations() {
    return {
      createdBy: Account,
      updatedBy: Account,
      closedBy: Account,
      bid_item: BidItem,
      annotation: Annotation,
      pictures: Attachment,
      attachments: Attachment,
      project: Project,
    };
  }

  /**
   * Workaround to initialize default values
   */
  init() {
    this.created_at = moment();
    this.internal = true;
    this.type = 'standard';
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    if (this.entity?.id) {
      this.entity_id = this.entity.id;
      switch (this.entity_type) {
        case 'site':
          this.entity = new Site(this.entity);
          this.site_id = this.entity.id;
          break;
        case 'project':
          this.entity = new Project(this.entity);
          this.project_id = this.entity.id;
          break;
      }
    }

    // cast
    this.closed_at = this.closed_at ? moment(this.closed_at) : null;
    this.project_id = this.project?.id || this.project_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || null,
      type: this.type,
      title: this.title,
      description: this.description,
      latitude: this.latitude || null,
      longitude: this.longitude || null,
      entity_type: this.entity_type,
      entity_id: this.entity?.id,
      bid_item_id: this.bid_item?.id,
      annotation_id: this.annotation?.id,
      internal: this.internal,
      pictures: (this.pictures || []).map(o => o.id),
      attachments: (this.attachments || []).map(o => o.id),
    };

    return payload;
  }
}

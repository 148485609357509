import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/shared/guards';

import { ProjectsComponent } from './projects.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectReportComponent } from './project-report/project-report.component';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectReportDetailComponent } from './components/project-report-detail/project-report-detail.component';
import { ReportRendererComponent } from './components/report-renderer/report-renderer.component';

const routes: Routes = [
  { path: '', component: ProjectsComponent },
  { path: 'create', component: ProjectCreateComponent, canActivate: [AuthGuard], data: { s: 'Project', a: 'create' } },
  { path: ':id/edit', component: ProjectCreateComponent, canActivate: [AuthGuard],
    data: { s: 'Project', a: 'update', r: /\/([0-9a-z]+)\/edit/i }
  },
  { path: ':id/report', canActivate: [AuthGuard], component: ProjectReportComponent },
  { path: ':id/report/:date', canActivate: [AuthGuard], component: ProjectReportComponent },
  { path: ':projectId/daily-report/create', canActivate: [AuthGuard], component: DailyReportComponent },
  { path: ':projectId/daily-report/:drId', canActivate: [AuthGuard], component: DailyReportComponent },
  { path: ':id', component: ProjectDetailComponent, canActivate: [AuthGuard],
    data: { s: 'Project', a: 'read', r: /\/([0-9a-z]+)\/?$/i }
  },
  { path: ':id/reports', component: ReportRendererComponent, canActivate: [AuthGuard],
    // data: { s: 'Project', a: 'read', r: /\/([0-9a-z]+)\/?$/i }
  },
  { path: ':projectId/project-report/:prId', canActivate: [AuthGuard], component: ProjectReportDetailComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [
    RouterModule,
  ]
})
export class ProjectRoutingModule { }

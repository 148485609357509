<div
  *ngIf="parentFormGroup.controls[controlName]"
  class="font-work-sans font-regular"
>
  <mat-form-field
    [formGroup]="parentFormGroup"
    appearance="legacy"
    class="form-field w-full"
    hideRequiredMarker
  >
    <mat-label>Choose a date</mat-label>
    <input
      [disabled]="isDisabled"
      [formControlName]="controlName"
      [max]="max"
      [min]="min"
      [required]="isRequired"
      autocomplete="false"
      class="input date-input"
      date
      placeholder="{{placeholder}}"
      matInput
      [matDatepicker]="picker"
    >

    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon
        matDatepickerToggleIcon
        *ngIf="parentFormGroup.controls[this.controlName].status !== 'DISABLED'"
        class="suffix-icon"
        matRipple
        svgIcon="calendar"
      />
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-error *ngIf="error">{{ error }}</mat-error>
  </mat-form-field>
</div>



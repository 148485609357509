import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() totalItems = 0;
  @Input() itemsPerPage = 2;
  @Input() maxVisiblePages = 4;
  @Output() pageChanged = new EventEmitter<number>();


  currentPage = 1;
  totalPages = 1;
  pages: number[] = [];
  currentStartIndex = 1;
  currentEndIndex = 1;

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnChanges(): void {
    this.currentPage = 1;
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage) || 1;
    this.updatePagination();
  }

  ngOnInit(): void {
  }

  updatePagination(): void {
    this.updatePageIndices();
    this.updateVisiblePages();
  }

  updatePageIndices(): void {
    this.currentStartIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
    this.currentEndIndex = Math.min(this.currentStartIndex + this.itemsPerPage - 1, this.totalItems);
  }

  updateVisiblePages(): void {
    let startPage = this.currentPage;
    let endPage = startPage + this.maxVisiblePages - 1;

    if (endPage > this.totalPages) {
      endPage = this.totalPages;
      startPage = Math.max(endPage - this.maxVisiblePages + 1, 1);
    }

    this.pages = [];
    for (let i = startPage; i <= endPage; i++) {
      this.pages.push(i);
    }
  }

  prevPage(): void {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePagination();
      this.emitPageChange();
    }
  }

  nextPage(): void {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePagination();
      this.emitPageChange();
    }
  }

  goToPage(page: number): void {
    if (page !== this.currentPage && page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.updatePagination();
      this.emitPageChange();
    }
  }

  emitPageChange(): void {
    this.pageChanged.emit(this.currentPage);
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { EquipmentType, Organization, Project } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class EquipmentTypeService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns EquipmentTypes with pagination
   * @param org Organization
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: EquipmentType[]}
   */
  getRecords(org: Organization, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/organization/${org?.id}/equipment-types`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new EquipmentType(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Returns EquipmentTypes
   * @param project Project
   * @param qp query params
   * @returns {Promise<EquipmentType[]>}
   */
  getProjectRecords(project: Project, qp: any = {}): Promise<EquipmentType[]> {
    return this.appHttpService
      .getService(`/v2/project/${project?.id}/equipment-types`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new EquipmentType(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new EquipmentType
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<EquipmentType>}
   */
  create(payload: any = null, qp: any = {}): Promise<EquipmentType> {
    return this.appHttpService
      .postService(`/v2/organization/${payload?.organization_id}/equipment-type`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new EquipmentType(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates EquipmentType
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<EquipmentType>}
   */
  update(payload: any = null, qp: any = {}): Promise<EquipmentType> {
    return this.appHttpService
      .putService(`/v2/equipment-type/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new EquipmentType(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes EquipmentType
   * @param model EquipmentType
   * @returns {Promise<any>} {status, message}
   */
  delete(model: EquipmentType): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/equipment-type/${model?.id}`)
      .toPromise();
  }

  /**
   * Add EquipmentType to Project
   * @param equipmentType EquipmentType
   * @param project Project
   * @returns {Promise<string>} message
   */
  addToProject(equipmentType: EquipmentType, project: Project): Promise<string> {
    return this.appHttpService
      .putService(`/v2/project/${project?.id}/equipment-type/${equipmentType.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  /**
   * Remove EquipmentType from Project
   * @param equipmentType EquipmentType
   * @param project Project
   * @returns {Promise<string>} message
   */
  removeFromProject(equipmentType: EquipmentType, project: Project): Promise<string> {
    return this.appHttpService
      .deleteService(`/v2/project/${project?.id}/equipment-type/${equipmentType.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }
}

<div class="container" *ngIf="initialized">


  <div class="mat-body-strong" *ngIf="!equipmentTypes.length">
    No data found
  </div>

  <div class="columns">
    <div>
      <div class="mat-subheading-2">Org equipment</div>
      <div *ngFor="let equipmentType of equipmentTypes">
        <mat-checkbox [checked]="equipmentType.checked" (change)="onCheckToggle(equipmentType, $event.checked)">
          <div class="mat-body-strong">
            {{equipmentType.name}}
          </div>
        </mat-checkbox>
      </div>
    </div>

    <div>
      <div class="mat-subheading-2">Project equipment</div>
      <div class="mat-body-strong" *ngFor="let equipmentType of projectEquipmentTypes">
        {{equipmentType.name}}
      </div>
    </div>
  </div>

</div>
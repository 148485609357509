import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-labor-summary',
  templateUrl: './labor-summary.component.html',
  styleUrls: ['./labor-summary.component.scss'],
})
export class LaborSummaryComponent implements OnInit, OnDestroy {
  @Input() data: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { BaseModel } from './base-model';
import { Organization } from './organization';
import { Project } from './project';

export class JobCode extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'JobCode'; }

  /* tslint:disable */
  name: string;
  is_master: boolean;
  organization: Organization;
  organization_id: string;
  project: Project;
  project_id: string;
  /* tslint:enable */

  get relations() {
    return {
      organization: Organization,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
    this.organization_id = this.organization?.id || this.organization_id;
    this.project_id = this.project?.id || this.project_id;
  }



  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      name: this.name,
      organization_id: this.organization?.id || this.organization_id,
      project_id: this.project?.id || this.project_id,
    };
  }
}

<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>

    <button *ngIf="initialized" mat-mini-fab (click)="toggleForm(internalTest, true)"
      [disabled]="!('update' | able: internalTest)">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <div class="container">
    <!-- title section -->
    <section class="header">
      <div class="title">
        <ng-container *ngIf="internalTest?.id">{{internalTest.name}}</ng-container>
        <ng-container *ngIf="!internalTest?.id">Add internal test</ng-container>
      </div>
    </section>
    <!-- /title section -->
  </div>

  <mat-tab-group>
    <mat-tab label="Details">
      <ng-template matTabContent>
        <ng-container *ngIf="initialized">
          <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
        </ng-container>
      </ng-template>
    </mat-tab>
    <mat-tab label="Fields">
      <ng-template matTabContent>
        <app-fields
          recordRange="true"
          context="fields"
          entityType="internal-test"
          [entity]="internalTest"
          ></app-fields>
      </ng-template>
    </mat-tab>
</mat-tab-group>
</div>


<ng-template #InputFormContent>
  <!-- Input Form -->
  <form [formGroup]="inputForm">
    <div class="container">
      <!-- name section -->
      <section>
        <div class="mat-subheading-1 row">Name:</div>
        <div class="mat-body" *ngIf="!editing">{{internalTest.name}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.name.hasError('error')">
            {{inputForm.controls.name.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /name section -->

      <!-- record_by_station section -->
      <section>
        <div class="mat-subheading-1 row">
          <div>Record by station:</div>
          <div class="icons">
            <ng-container *ngIf="!editing">
              <ng-container *ngIf="internalTest?.record_by_station">Yes</ng-container>
              <ng-container *ngIf="!internalTest?.record_by_station">No</ng-container>
            </ng-container>
            <ng-container *ngIf="editing">
              <mat-slide-toggle formControlName="record_by_station"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.controls.record_by_station.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.record_by_station.hasError('error')">
                {{inputForm.controls.record_by_station.errors.error}}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- /record_by_station section -->

    <!-- issuetype section -->
    <section>
      <div class="mat-subheading-1 row">Failed Issue Category:</div>
      <div class="mat-body" *ngIf="!editing">{{internalTest.issue_type}}</div>
      <mat-form-field *ngIf="editing">
        <mat-label>Failed Issue Category</mat-label>
        <mat-select formControlName="issue_type" [errorStateMatcher]="matcher">
          <mat-option [value]="">- select one -</mat-option>
          <mat-option *ngFor="let issueType of issueTypes" [value]="issueType.type">
            {{issueType.type}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="inputForm.controls.issue_type.hasError('error')">
          {{inputForm.controls.type.errors.error}}
        </mat-error>
      </mat-form-field>
    </section>
    <!-- /name section -->

      <!-- Form Actions -->
      <section>
        <div class="row" *ngIf="editing">
          <button mat-stroked-button color="accent" (click)="save()">
            <ng-container *ngIf="internalTest?.id">Update</ng-container>
            <ng-container *ngIf="!internalTest?.id">Create</ng-container>
          </button>
          <button mat-stroked-button (click)="onCancel()">Cancel</button>
        </div>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
  <!-- /Input form -->
</ng-template>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DefaultComponent } from './default.component';
import { LoginComponent } from 'src/app/modules/login/login.component';
import { Page404Component } from 'src/app/modules/page404/page404.component';
import { Page403Component } from 'src/app/modules/page403/page403.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SignUpComponent } from 'src/app/modules/sign-up/sign-up.component';

@NgModule({
  declarations: [
    DefaultComponent,
    LoginComponent,
    Page404Component,
    Page403Component,
    SignUpComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    MaterialModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class DefaultModule { }

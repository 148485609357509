import { BaseModel, Account, Attachment } from 'src/app/shared/models';
import * as moment from 'moment';

export class MaterialTestInspection extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'MaterialTestInspection'; }

  /* tslint:disable */
  arrival_time: any;
  departure_time: any;
  break_duration: string|number;
  comment: string;
  pictures: Attachment[];
  /* tslint:enable */

  /**
   * Workaround to initialize default values
   */
  init() {
    this.pictures = [];
    this.arrival_time = moment();
    this.departure_time = moment();
    this.break_duration = 'PT30M';
  }

  get relations() {
    return {
      createdBy: Account,
      pictures: Attachment,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.arrival_time = this.arrival_time ? moment(this.arrival_time) : null;
    this.departure_time = this.departure_time ? moment(this.departure_time) : null;

  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    const payload: any = {
      id: this.id || undefined,
      arrival_time: moment(this.arrival_time).format(),
      departure_time: moment(this.departure_time).format(),
      break_duration: this.break_duration,
      pictures: (this.pictures || []).map(o => o.id),
      comment: this.comment,
    };
    return payload;
  }

}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Ability, subject } from '@casl/ability';
import { Project } from 'src/app/shared/models';
import { AppService } from 'src/app/shared/services';
import { SubContractor } from '../../sub-contractor';
import { SubContractorService } from '../../sub-contractor.service';

@Component({
  selector: 'app-project-sub-contractors',
  templateUrl: './project-sub-contractors.component.html',
  styleUrls: ['./project-sub-contractors.component.scss']
})
export class ProjectSubContractorsComponent implements OnInit {

  @Input() public project: Project;

  public initialized: boolean = false;
  public loading: number = 0;
  public subContractors: SubContractor[] = [];
  public canAdd: boolean = false;
  public canRemove: boolean = false;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };

  constructor(
    private ablity: Ability,
    private appSrv: AppService,
    private scSrv: SubContractorService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.fetchRecords(this.project);
    this.canAdd = this.ablity.can('create', subject('ProjectSubContractor', { project_id: this.project.id }));
    this.canRemove = this.ablity.can('delete', subject('ProjectSubContractor', { project_id: this.project.id }));
  }

  /**
   * Fetch records
   * @param project Project
   */
  fetchRecords(project: Project): void {
    this.loading++;
    const qp = {
      organization_id: project.owner_organization_id,
      include: 'address',
    };
    this.scSrv.fetchRecords(this.pagination, qp)
      .then((resp: any) => {
        this.subContractors = resp.result;
        this.pagination.length = resp.meta.totalRecords || 0;
        return this.scSrv.fetchRecords({ pageIndex: 0, pageSize: 10000 }, { project_id: project.id, include: '' });
      })
      .then((resp: any) => {
        const projectScs = resp.result ?? [];
        this.subContractors.map(sc => {
          if (projectScs.findIndex(o => o.id === sc.id) >= 0) {
            sc.checked = true;
          }
        });
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error || 'Failed to fetch sub contractors.', '', { duration: 5000 });
      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

  /**
   * OnChange event of checkbox
   * @param sc SubContractor
   * @param checked boolean
   */
  onChange(sc: SubContractor, checked: boolean) {
    if (checked) {
      this.addSubContractorToProject(sc);
    } else {
      this.removeSubContractorFromProject(sc);
    }
  }

  /**
   * Add sub-contractor to project
   * @param sc SubContractor
   */
  addSubContractorToProject(sc: SubContractor) {
    if (!this.canAdd) {
      return;
    }

    this.loading++;
    this.scSrv.addSubContractorToProject(this.project, sc)
      .then((resp: any) => {
        this.snackBar.open(`Added "${sc.name}" to "${this.project.number}"`, '', { duration: 5000 });
      })
      .catch((errResp: HttpErrorResponse) => {
        this.snackBar.open(errResp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      })
  }

  /**
   * remove sub-contractor from project
   * @param sc SubContractor
   */
  removeSubContractorFromProject(sc: SubContractor) {
    if (!this.canRemove) {
      return;
    }

    this.loading++;
    this.scSrv.removeSubContractorFromProject(this.project, sc)
      .then((resp: any) => {
        this.snackBar.open(`Removed "${sc.name}" from "${this.project.number}"`, '', { duration: 5000 });
      })
      .catch((errResp: HttpErrorResponse) => {
        this.snackBar.open(errResp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      })
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords(this.project);
  }

}

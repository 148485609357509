import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'videoDuration',
})
export class VideoDurationPipe implements PipeTransform {
  transform(value: number): string {
    if (!value || value <= 0) {
      return '00:00';
    }

    // Round the value to the nearest whole number
    const totalSeconds = Math.round(value);

    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    const formattedMinutes = this.pad(minutes);
    const formattedSeconds = this.pad(seconds);

    return hours > 0
      ? `${hours}:${formattedMinutes}:${formattedSeconds}`  // Include hours if available
      : `${formattedMinutes}:${formattedSeconds}`;         // Only minutes and seconds
  }

  private pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}

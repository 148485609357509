import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, BidSheet, Heading, Project, Site } from 'src/app/shared/models';
import { AppErrorStateMatcher, ProjectService } from 'src/app/shared/services';
import { BidService } from 'src/app/shared/services/bid.service';

@Component({
  selector: 'app-bid-item-create',
  templateUrl: './bid-item-create.component.html',
  styleUrls: ['./bid-item-create.component.scss']
})
export class BidItemCreateComponent implements OnInit {

  public loading = 0;
  public initialized = false;
  public editing = false;

  public dialogOptions: any = {};
  public componentResult: any = null;
  @Input() public data: any;
  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;

  public bidItem: BidItem = new BidItem();
  public project: Project;
  public bidSheet: BidSheet;
  public sites: Site[] = [];
  public stationProperties: string[] = [];

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    item: ['', [Validators.required]],
    quantity: ['', [Validators.required]],
    unit_price: ['', [Validators.required]],
    uom: ['', [Validators.required]],
    description: ['', [Validators.required]],
    record_by_station: ['', [Validators.required]],
    track_material_quantity: ['', [Validators.required]],
    site_id: [''],
    rollup_field_id: [''],
    rollup_quantity_editable: ['', [Validators.required]],
    rollup_formula: [''],
  });

  constructor(
    private bidService: BidService,
    private projectSrv: ProjectService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<BidItemCreateComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
  ) { }

  ngOnInit(): void {
    const { data, bidSheet, project, options } = this.inputData;
    if (data) {
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.data = data;
      this.bidSheet = bidSheet;
      this.project = project;
    }
    this.bidItem = new BidItem(JSON.parse(JSON.stringify(this.data)));

    // open edit mode for new biditem
    if (!this.bidItem?.id) {
      this.toggleForm(this.bidItem, true);
    }
    this.fetchSites();
  }

  /**
   * Close dialog
   * @param resp any
   */
  onClose(resp): void {
    this.dialogRef.close(resp);
  }

  /**
   * Assign base model to FormGroup
   * @param bidItem BidItem
   * @param form FormGroup
   */
  toReactiveForm(bidItem: BidItem = null, form: UntypedFormGroup) {
    form.controls.item.setValue(bidItem?.item);
    form.controls.quantity.setValue(bidItem?.quantity);
    form.controls.unit_price.setValue(bidItem?.unit_price);
    form.controls.uom.setValue(bidItem?.uom);
    form.controls.description.setValue(bidItem?.description);
    form.controls.track_material_quantity.setValue(bidItem?.track_material_quantity || false);
    form.controls.record_by_station.setValue(bidItem?.record_by_station || false);
    form.controls.site_id.setValue(bidItem?.site_id);
    form.controls.rollup_field_id.setValue(bidItem?.rollup_field?.id);
    form.controls.rollup_quantity_editable.setValue(bidItem?.rollup_quantity_editable || false);
    form.controls.rollup_formula.setValue(bidItem?.rollup_formula);
  }

  /**
   * Form submit
   */
  save() {

    if (!this.inputForm.valid) {
      return;
    }
    const isNew = !this.bidItem.id;

    const body = this.inputForm.value;
    body.project_id = this.bidSheet?.project_id;
    body.bid_sheet_id = this.bidSheet?.id;
    if (!body.record_by_station) {
      body.site_id = null;
      body.rollup_field_id = null;
    }
    this.loading++;

    const qp = { include: ['site', 'rollup_field', 'fields'] };
    const sub = this.bidItem.id
      ? this.bidService.updateBidItem(this.bidItem.id, body, qp)
      : this.bidService.createBidItem(body, qp);

    sub.subscribe({
      next: (resp: any) => {
        this.loading--;
        resp.project_id = this.bidSheet?.project_id;
        resp.bid_sheet_id = this.bidSheet?.id;
        this.bidItem = resp;
        this.toggleForm(this.bidItem, false);
        this.componentResult = this.bidItem;
        this.snackBar.open(
          `${isNew ? 'Added': 'Updated'} biditem ${this.bidItem?.item}`,
          '',
          { duration: 5000 },
        );
      },
      error: (resp: any) => {
        this.loading--;
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }

        if (resp.error.error) {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        }
      }
    });
  }

  /**
   * Toggles input form with model
   * @param model BidItem
   * @param show boolean
   */
  toggleForm(model: BidItem = null, show = true): void {
    this.bidItem = model?.id ? model : new BidItem({
      project_id: this.bidSheet?.project_id,
      bid_sheet_id: this.bidSheet?.id,
    });
    this.editing = show;
    this.toReactiveForm(this.bidItem, this.inputForm);
  }

  /**
   * Cancel update, and ignore form changes
   */
  onCancel() {
    this.toggleForm(null, false);
  }

  /**
   * Fetches Station[] from API
   */
  fetchSites(): void {
    this.loading++;

    this.projectSrv
      .fetchSites(this.project, { include: [] })
      .then((sites: Site[]) => {
        this.sites = sites;

        if (this.bidItem.site_id) {
          this.bidItem.site = this.sites.find(o => o.id === this.bidItem.site_id);
          this.bidItem.site_id = this.bidItem.site?.id;
        }
        return this.fetchBidItem(this.bidItem?.id);
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error, '', { duration: 5000 });
      })
      .finally(() => {
        this.initialized = true;
        this.loading--;
      });
  }

  fetchBidItem(id: string): void {
    const qp = {
      include: [
        { headings: [ 'alignment' ] },
        'rollup_field',
        'fields',
      ],
    }
    this.bidService.getBidItem(id, qp)
      .then((bidItem: BidItem) => {
        this.bidItem.rollup_field = bidItem?.rollup_field;
        this.bidItem.fields = bidItem?.fields;
        // Grab station.properties from selected headings
        this.stationProperties = (bidItem.headings || []).reduce((p, c: Heading) => {
          return [...new Set(p.concat(c?.alignment?.station_properties || []))];
        }, []);
      });
  }

}

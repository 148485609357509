<div class="wrapper">
  <form>
    <app-number-input
      [isDecimalAllowed]="true"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="avgtemp"
      label="current temperature"
    />
    <div class="flex flex-row justify-center items-center mb-[20px] gap-[20px] w-full">
      <app-number-input
        [isDecimalAllowed]="true"
        [parentFormGroup]="form"
        class="w-1/2"
        controlName="maxtemp"
        label="Highest temperature"
      />
      <app-number-input
        [isDecimalAllowed]="true"
        [parentFormGroup]="form"
        class="w-1/2"
        controlName="mintemp"
        label="Lowest temperature"
      />
    </div>
    <app-dropdown
      [options]="skyConditionOptions"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="skyCondition"
      label="sky Condition"
    />
    <app-number-input
      [isDecimalAllowed]="true"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="wind"
      label="Wind Conditions (Mp/H)"
    />
    <app-text-area
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="note"
      label="comment"
    />

  </form>
</div>

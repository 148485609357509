<div class="wrapper">
  <div class="heading text-type-2">{{ data?.bid_item?.item }}</div>
  <div class="description text-type-10">{{ data?.bid_item?.description }}</div>
  <app-text-input
    [parentFormGroup]="form"
    controlName="quantity"
    label="Quantity"
    class="quantity-input"
  />
  <app-text-area
    [parentFormGroup]="form"
    controlName="comment"
    label="comment"
    class="comment-input"
  />
  <div
    *ngIf="pictures?.length"
    class="pictures-section"
  >
    <div class="pictures-section-label">PICTURES:</div>
    <div class="pictures-row flex flex-wrap gap-[20px]">
      <app-image
        *ngFor="let picture of pictures"
        [url]="picture.thumb_url"
        class="each-picture"
      />
    </div>
  </div>
  <div
    *ngIf="form.controls.latitude.value !== null &&
          form.controls.longitude.value  !== null &&
          form.controls.latitude.value !== '' &&
          form.controls.longitude.value  !== ''"
    class="location-section"
  >
    <div class="location-section-label">LOCATION:</div>
    <div class="location-row">
      <app-location-picker
        [isDialog]="false"
        [latitude]="form?.controls?.latitude?.value"
        [longitude]="form?.controls?.longitude?.value"
        [readOnly]="true"
        height="100px"
      />
    </div>
  </div>
</div>

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bid-items-side-nav-screen',
  templateUrl: './bid-items-side-nav-screen.component.html',
  styleUrls: ['./bid-items-side-nav-screen.component.scss'],
})
export class BidItemsSideNavScreenComponent implements OnInit {
  @Input() data;

  ngOnInit() {
  }
}

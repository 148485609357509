import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppHttpService } from './app-http.service';
import { Role } from '../models';

@Injectable({ providedIn: 'root' })
export class RoleService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns list of Records
   * @param pagination start
   * @param qp query params
   */
  getRecords(pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;
    const path = '/v1/role';
    return this.appHttpService.getService(path, qp).pipe(
      map((resp: any) => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Role(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      })
    );
  }

}

<div class="container">
  <ng-container *ngIf="initialized">

    <div class="mat-body-strong" *ngIf="!materials.length">
        This project has no materials.
    </div>

    <div *ngFor="let material of materials">
      <mat-checkbox
        [checked]="material.checked"
        (change)="onChange(material, $event.checked)">
        <div class="mat-body-strong">
          {{material.name}}
          <span class="mat-caption">({{material.uom}})</span>
        </div>

      </mat-checkbox>
    </div>
  </ng-container>

</div>


import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  transform(
    items: any[],
    key: string,
    value: any,
    comp: string = '===',
    ignore: boolean = false,
  ) {

    if (!items || !key || ignore) {
      return items;
    }

    let result = items;
    switch(comp) {
      case '==':
      case '===':
        result = items.map(o => o || {}).filter(i => i[key] == value);
        break;

      // Todo add >, <, regex
      case 'in':
        result = items.map(o => o || {}).filter(i => (value || []).indexOf(i[key]) >= 0);
        break;
      case 'not in':
        result = items.map(o => o || {}).filter(i => (value || []).indexOf(i[key]) < 0);
    }
    return result;
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DateFormatPipe } from '../../../../../../../../shared/pipes';

@Component({
  selector: 'app-offsite-test-request-block',
  templateUrl: './offsite-test-request-block.component.html',
  styleUrls: ['../../../styles.css', '../styles.css', './offsite-test-request-block.component.scss'],
})
export class OffsiteTestRequestBlockComponent implements OnChanges {
  @Input() activity: any;
  @Input() data: any;
  @Input() isDisabled: boolean;

  subheading = '';
  isExpanded = false;

  constructor(
    private dateFormatPipe: DateFormatPipe,
  ) {
  }

  ngOnChanges() {
    this.createSubheading();
  }

  createSubheading() {
    if (this.data.status === 'draft') {
      this.subheading = 'Draft';
      return;
    }

    if (this.activity.sent_to_lab) {
      this.subheading = this.dateFormatPipe.transform(this.activity?.sent_to_lab?.created_at, 'ago') + ' ago';
      return;
    }
  }
}

<div class="wrapper">
  <div class="heading text-type-2">Add bid item</div>
  <div class="search-section mt-[40px]">
    <form [formGroup]="form">
      <app-text-input
        [parentFormGroup]="form"
        controlName="searchText"
        label="BID ITEM"
        placeholder="Search for bid item"
        suffixIcon="search"
      />
    </form>

    <div *ngIf="filteredBidItemsList.length > 0 && focusedIn" class="bid-items-list">
      <div
        (click)="addBidItem(eachBidItem)"
        *ngFor="let eachBidItem of filteredBidItemsList; let first = first; let last = last"
        [class.first]="first"
        [class.last]="last"
        class="each-item flex justify-start items-center gap-[16px]"
      >
        <div class="left flex justify-start items-center">
          <div class="icon-wrapper">
            <mat-icon svgIcon="daily-report-page-menu-bid-item" />
          </div>
        </div>
        <div class="right flex flex-col justify-center items-start">
          <div class="bid-item-heading text-type-6">Bid item {{ eachBidItem.item }}</div>
          <div class="description text-type-14">{{ eachBidItem.description }}</div>
        </div>
      </div>
    </div>
  </div>
</div>

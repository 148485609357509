import { Component, Input, OnInit } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {Account, Project} from 'src/app/shared/models';
import { ProjectService } from 'src/app/shared/services';

@Component({
  selector: 'app-project-accounts',
  templateUrl: './project-accounts.component.html',
  styleUrls: ['./project-accounts.component.scss']
})
export class ProjectAccountsComponent implements OnInit {

  public loading = 0;
  public initialized = false;

  @Input()
  public project: Project;

  @Input()
  public pageSize = 20;

  public accounts: Account[] = [];
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };

  public displayedColumns: string[] = [
    'name',
    'email',
    'phone',
    'role',
    'status',
    'created_at',
  ];

  constructor(
    private projectService: ProjectService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.pagination.pageSize = this.pageSize;
    this.getAccounts();
  }

  /**
   * Get project accounts
   */
  getAccounts() {
    this.projectService.getAccounts(this.project, this.pagination, { include: 'roles' })
      .subscribe({
        next: (resp: any) => {
          this.accounts = resp.result;
          this.pagination.length = resp.meta.totalRecords || 0;
          this.initialized = true;
        },
        error: (err: any) => {
          this.snackBar.open(err.message, '', { duration: 6000 });
        }
      });
  }

  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.getAccounts();
  }

}

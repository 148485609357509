import { BaseModel } from './base-model';

export class MaterialTest extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'MaterialTest'; }

  /* tslint:disable */
  name: string;
  /* tslint:enable */
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './app-icon.component.html',
  styleUrls: ['./app-icon.component.scss']
})
export class AppIconComponent implements OnInit {

  @Input() public inline: boolean = true;
  @Input() public icon;
  @Input() public size: number = 48;

  public iconPath: string;

  constructor() { }

  ngOnInit(): void {
    if (this.icon) {
      this.iconPath = `/assets/icons/${this.icon}`;
    }
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-consolidated-report-activity-summary-card',
  templateUrl: './consolidated-report-activity-summary-card.component.html',
  styleUrls: ['./consolidated-report-activity-summary-card.component.scss'],
})
export class ConsolidatedReportActivitySummaryCardComponent implements OnInit, OnDestroy {
  @Input() data: any;
  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor() {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Injectable } from '@angular/core';
import { Issue, IssueSummary, Project } from 'src/app/shared/models';
import { AppHttpService } from 'src/app/shared/services';
import { Subject } from 'rxjs';
// import { Issue, IssueSummary } from '../models';

@Injectable({ providedIn: 'root' })
export class IssueService {

  fetchIssuesList: Subject<any> = new Subject<any>();

  constructor(
    private appHttpService: AppHttpService
  ) { }

  fetchRecords(project: Project, pagination: any = {}, qp: any = {}): Promise<any> {
    qp.projectId = qp.projectId ?? project.id;
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService.getService(`/v1/issue`, qp).toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Issue(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Fetches entity's issue summary
   * @param entityType string
   * @param id string
   * @returns {Promise<IssueSummary>}
   */
  fetchSummary(entityType: string, id: string) {
    return this.appHttpService.getService(`/v1/issue/summary/${entityType}/${id}`).toPromise()
      .then(resp => new IssueSummary(resp.data));
  }

}

<div
  class="filter-button datepicker flex flex-row"
>
  <div (click)="picker.open()">
    <div class="flex flex-row gap-[20px]">
      <div class="label text-type-8">
        {{
          !form.controls.startDate.value && !form.controls.endDate.value ?
            'All Time' :
            form.controls.startDate.value === form.controls.endDate.value ?
              (form.controls.startDate.value | date: 'd, MMM') :
              (
                (
                  form.controls.startDate.value &&
                  (form.controls.startDate.value | date: 'd, MMM') ||
                  'From'
                )

                + ' - ' +

                (
                  form.controls.endDate.value &&
                  (form.controls.endDate.value |date: 'd, MMM') ||
                  'To'
                )
              )
        }}
      </div>
      <mat-icon
        class="filter-icon"
        svgIcon="calendar"
      />
    </div>
  </div>
  <mat-icon
    (click)="removeDateFilter($event)"
    *ngIf="form.controls.startDate.value || form.controls.endDate.value"
    class="filter-icon"
  >close
  </mat-icon>

  <form [formGroup]="form"
        class="h-0">
    <mat-form-field class="date-picker">
      <mat-date-range-input [rangePicker]="picker">
        <input formControlName="startDate"
               matStartDate />
        <input formControlName="endDate"
               matEndDate />
      </mat-date-range-input>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </form>
</div>

<div class="component-container">
  <section class="header-section mb-[40px]">
    <div class="heading text-type-2">{{ data.item }}
    </div>
  </section>
  <section class="content-section mb-[40px]">
    <mat-tab-group
      class="contents-tab-group"
    >
      <mat-tab label="Sites">
        <app-bid-items-sites-details [data]="data" />
      </mat-tab>
    </mat-tab-group>
  </section>
</div>

<ng-container *ngIf="initialized">
  <div>
    <h1 *ngIf="!drawing.id">Add drawing</h1>
    <h1 *ngIf="drawing.id">Update drawing</h1>
  </div>

  <form [formGroup]="inputForm">
    <div class="form-inputs">

      <mat-form-field>
        <mat-label>Tags</mat-label>
        <mat-chip-list #chipList aria-label="Tags" multiple formControlName="tags">
          <mat-chip *ngFor="let tag of drawingTags" (removed)="removeTag(tag)" [removable]="true">
            {{tag}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input placeholder="New tag..." [matChipInputFor]="chipList" (matChipInputTokenEnd)="addTagFromInput($event)">
        </mat-chip-list>
        <mat-error *ngIf="inputForm.controls.tags.hasError('required')">
          This field is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="inputForm.controls.tags.hasError('error')">{{inputForm.controls.tags.errors.error}}
        </mat-error>
      </mat-form-field>

      <!-- Drawing -->
      <section>
        <div class="mat-subheading-1">Update drawing:</div>
        <div class="preview-list">
          <div *ngIf="drawing.thumb_url">
            <img [src]="drawing.thumb_url" />
            <app-file-uploader param="inputFile" [targetClass]="clsDrawing" httpMethod="PUT" class="hover-icon"
              color="white" [formData]="inputForm.value" [target]="drawingUpdateUrl" icon="file_upload"
              accept="application/pdf" (onError)="onUploadError($event)" (complete)="onUploadComplete($event)"
              title="Upload pdf">
            </app-file-uploader>
          </div>

        </div>
      </section>
      <!-- /Drawing -->

    </div>

    <div class="form-actions">
      <button *ngIf="!drawing.id" mat-stroked-button color="primary" type="button" [disabled]="!inputForm.valid"
        (click)="onSubmit()">Create</button>
      <button *ngIf="drawing.id" mat-stroked-button color="primary" type="button" [disabled]="!inputForm.valid"
        (click)="onSubmit()">Update</button>
      <button mat-stroked-button color="accent" (click)="onClose()" disableRipple>Close</button>
    </div>
  </form>

</ng-container>

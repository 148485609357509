<div class="wrapper flex flex-col">
  <div class="heading text-type-1 mb-[20px]">
    {{ errors ? 'Error' : 'Upload changes' }}
  </div>
  <div
    *ngIf="errors"
    class="errors mb-[20px] text-type-14"
  >
    <span class="flex flex-row justify-start items-center">
      <mat-icon
        class="error-icon mr-[8px]"
      >
        error_outline
      </mat-icon>
      {{ typeOf(errors) === 'string' ? errors : (errors.length + ' issues were found. Re-upload the data after fixing the data on:') }}
    </span>
    <div
      *ngIf="typeOf(errors) !== 'string'"
      class="error-list ml-[33px]">
      <div class="each-error">
        <li *ngFor="let eachError of errors;">
          <strong>Row {{ eachError.rowNumber }} - Column {{ eachError.columnName }}: </strong>{{ eachError.msg }}
        </li>
      </div>
    </div>
  </div>
  <form class="flex flex-col gap-[calc(40px-1.25em)]">
    <app-text-input
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['changeOrderNumber']"
      [parentFormGroup]="form"
      controlName="changeOrderNumber"
      label="CHANGE ORDER NUMBER"
    />
    <app-text-area-type-two
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['specifyOtherRelationDetails']"
      [parentFormGroup]="form"
      class="input w-full"
      controlName="comment"
      label="COMMENT:"
    />
  </form>
  <div class="template-section text-type-10 mb-[12px]">
    Use this <span (click)="getTemplateFile()" class="clickable">template</span> to
    upload changes for bid items
  </div>
  <div class="file-upload-section">
    <div
      *ngIf="file"
      class="file-name flex flex-row justify-start items-center gap-[5px] mb-[12px] italic">
      <span class="underline underline-offset-4">{{ file.name }}</span>
      <mat-icon (click)="removeSelectedFile()" class="close-icon mt-[5px]">close</mat-icon>
    </div>
    <div class="thumbnail-action-section flex flex-row gap-[1rem]">
      <button
        (click)="openFile('fileUploadRef')"
        class="upload-btn"
        color="primary"
        mat-stroked-button
      >
        Upload
      </button>
      <input
        #fileUploadRef
        (change)="handleFileUpload()"
        [id]="'fileUploadRef'"
        class="input-files"
        type="file"
      />
    </div>

  </div>
  <div class="actions mt-[calc(100px-1.25em)]">
    <app-primary-button
      (handleClick)="onSubmit()"
      [isDisabled]="!form.dirty"
    >
      Submit
    </app-primary-button>
  </div>
</div>

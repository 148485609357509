<div
  class="wrapper"
  *ngIf="initialized"
  (click)="goToIssues()"
>
  <div class="mat-subheading-1">{{ summary.total }} Issue
    {{ summary.total !== 1 ? 's' : '' }}
  </div>
  <div class="issue-summary">
    <div class="open-issues">
      <mat-icon
        svgIcon="open-issues"
        class="open-issues-icon"
      />
      <div class="details-section">
        <div class="label">
          {{ summary.open }} open
        </div>
        <div class="sub-label">
          ({{ summary.opened_today }} today)
        </div>
      </div>
    </div>
    <div class="closed-issues">
      <mat-icon
        svgIcon="closed-issues"
        class="closed-issues-icon"
      />
      <div class="details-section">
        <div class="label">
          {{ summary.closed }} closed
        </div>
        <div class="sub-label">
          ({{ summary.closed_today }} today)
        </div>
      </div>
    </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DailyReport, Heading, InternalTest, Project, ReportInternalTest, Site } from 'src/app/shared/models';
import { AppService } from 'src/app/shared/services';
import { RitComponent } from '../rit/rit.component';

@Component({
  selector: 'app-rit-summary',
  templateUrl: './rit-summary.component.html',
  styleUrls: ['./rit-summary.component.scss']
})
export class RitSummaryComponent implements OnInit {

  public initialized: boolean = false;
  @Output() changed = new EventEmitter<any>();
  @Input() rits: ReportInternalTest[] = [];
  @Input() project: Project;
  @Input() drs: DailyReport[] = [];

  public isSingleReport: boolean = true;
  public sites: Site[] = [];

  public internalTests: InternalTest[] = [];

  public siteSummaries: any[] = [];
  public headings: Heading[] = [];

  constructor(
    private appSrv: AppService,
  ) { }

  ngOnInit(): void {

    const { sites, internal_tests } = this.project;
    this.sites = (sites || []).slice();
    this.internalTests = internal_tests || [];
    this.headings = this.project.sites.map(s => s.headings || [])
      .reduce((p, c) => p.concat(c), []);

    this.isSingleReport = [...new Set(this.rits.map(o => o.daily_report_id))].length === 1;
    this.siteSummaries = this.sites.map((o: any) => {
      return {
        summary: this.getSiteSummary(o),
        site: o,
      }
    });

    this.initialized = true;
  }

  getSiteSummary(site: Site) {
    const rits = this.rits.filter(o => o.site_id === site?.id).slice();
    const internalTests = [...new Set(rits.map(o => o.internal_test_id))]
      .map(id => this.internalTests.find(o => o.id === id));
    return internalTests.map(it => this.getSummaryLines(it, rits));
  }

  getSummaryLines(it: InternalTest, rits: ReportInternalTest[] = []) {
    const headings = [...new Set(
      rits.filter(o => o.internal_test_id === it.id).map(o => o.heading_id)
    )].map(id => this.headings.find(o => o.id === id));
    const s = headings.map(h => {
        const rows = this.rits.filter(r => r.heading_id === h?.id && r.internal_test_id === it.id && r.station_id);
      return {
        heading: h,
        rows: rows.length,
      }
    });
    return {
      it,
      headings: s,
    };
  }

  /**
   * Open ReportBidItem in sidebar
   * @param instance LineItem
   */
  openReportInternalTest(it: InternalTest, heading: Heading, site: Site) {
    const dr = this.drs.find(o => o.site_id === site?.id);

    const sidebarRef = this.appSrv.openSidebar(RitComponent, {
      rits: this.rits.filter(o => o.daily_report_id === dr?.id),
      project: this.project,
      internalTest: it,
      heading,
      dailyReport: dr,
    });

    sidebarRef.afterClosed().subscribe(n => {
      if (n) {
        this.changed.emit(n);
      }
    });
  }

}

<div class="wrapper flex flex-col">
  <div class="heading text-type-1 mb-[20px]">
    Success
  </div>
  <div class="flex flex-col justify-center items-center gap-[20px]">
    <mat-icon
      class="success-image"
      svgIcon="success-check"
    />
    <div class="message px-[40px] text-center">
      Your changes have been uploaded and updated successfully
    </div>
  </div>
  <div class="actions mt-[calc(100px-1.25em)]">
    <app-primary-button
      (handleClick)="onSubmit()"
    >
      Done
    </app-primary-button>
  </div>
</div>

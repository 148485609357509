import { Component, Input } from '@angular/core';
import { ProjectMapService } from '../../../project-map.service';

@Component({
  selector: 'app-attachments-block',
  templateUrl: './attachments-block.component.html',
  styleUrls: ['../styles.css', './attachments-block.component.scss'],
})
export class AttachmentsBlockComponent {
  @Input() pictures: any[] = [];

  constructor(
    private projectMapService: ProjectMapService,
  ) {
  }

  openPictures() {
    this.projectMapService.selectedPicturesData.next({picturesList: this.pictures});
  }
}

<div class="wrapper">
  <div class="heading">
    {{ heading }}
  </div>
  <section class="content section">
    <app-attachments-block
      *ngIf="data.pictures?.length"
      class="attachments-section"
      [pictures]="data.pictures"
    />
  </section>
</div>

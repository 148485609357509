<div *ngIf="initialized">

  <div class="mat-body-strong" *ngIf="!summaries.length">No status</div>

  <div class="status-container">
    <div *ngFor="let summary of summaries" class="status-card link" [class.active]="summary.completed"
      [routerLink]="[project.id, 'report', summary.date | date: 'yyyy-MM-dd']">
      <div class="mat-subheading-2">
        <a class="no-style link" [routerLink]="[project.id, 'report', summary.date | date: 'yyyy-MM-dd']">
          {{summary.date | dateFormat: 'long'}}
        </a>
      </div>

      <div *ngIf="summary.todo" class="mat-caption" i18n>
        Awaiting creation ({summary.todo, plural, =1 {1 report} other {{{summary.todo}} reports}})
      </div>
      <div *ngIf="summary.draft" class="mat-caption" i18n>
        Awaiting submissions ({summary.draft, plural, =1 {1 report} other {{{summary.draft}} reports}})
      </div>
      <div *ngIf="summary.submitted" class="mat-caption" i18n>
        Awaiting approvals ({summary.submitted, plural, =1 {1 report} other {{{summary.submitted}} reports}})
      </div>
      <div *ngIf="summary.rejected" class="mat-caption" i18n>
        Rejected ({summary.rejected, plural, =1 {1 report} other {{{summary.rejected}} reports}})
      </div>
      <div *ngIf="summary.approved" class="mat-caption" i18n>
        Approved ({summary.approved, plural, =1 {1 report} other {{{summary.approved}} reports}})
      </div>
    </div>
  </div>

</div>

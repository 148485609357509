<ng-container *ngIf="initialized">
  <div *ngFor="let test of materialTests">
    <mat-checkbox
      [disabled]="!('manage-project-test' | able: project)"
      [checked]="test.checked"
      (change)="onChange(test, $event.checked)">
      {{test.name}}
    </mat-checkbox>
  </div>
</ng-container>

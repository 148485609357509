import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss']
})
export class ProgressCardComponent implements OnInit {

  @Input() public heading: string;
  @Input() public percent: number = 0.0;
  @Input() public caption: string;

  public progressColor: string = '#8394b3ff';

  constructor() { }

  ngOnInit(): void {
    if (this.percent > 100) {
      this.percent = 100;
    }
  }

}

<div class="wrapper">
  <div class="description">
    {{ description }}
  </div>
  <app-usage-bar
    class="usage-bar quantity-usage"
    heading="QUANTITY"
    [percent]="quantityUsedPercent"
    [label]="quantityUsedLabel"
  />
  <section
    *ngIf="data.comment"
    class="section">
    <div class="section-heading">DESCRIPTION:</div>
    <div class="section-content">{{data.comment}}</div>
  </section>
  <section class="block section">
    <div
      *ngIf="headingDetails"
      class="block-heading"
    >
      {{ headingDetails?.name }}
    </div>
    <section *ngIf="sectionsList.length" class="each-bid-item">
      <ng-container *ngFor="let section of sectionsList">
        <mat-expansion-panel
          hideToggle
          [(expanded)]="section.isExpanded"
          class="station-section"
          (opened)="section.isExpanded = true"
          (closed)="section.isExpanded = false"
          id="{{section.id}}"
        >
          <mat-expansion-panel-header
            class="accordion-header"
          >
            <mat-panel-title class="station-heading">
              <div>{{section.heading}}</div>
              <mat-icon
                class="chevron-icon"
                [svgIcon]="section.isExpanded ? 'chevron-up' : 'chevron-down'"
              />
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="section-content">
            <div
              *ngIf="section.isStartOfWork"
              class="start-location"
            >
              <mat-icon svgIcon="flag"/>
              <div>Starting station</div>
            </div>
            <section class="values-section">
              <app-view-field
                *ngIf="!data.bid_item.record_by_station"
                class="value-field"
                label="QUANTITY"
                [value]="data.quantity"
                hint=""
                [uom]="data.bid_item?.uom"
              />
              <app-view-field
                *ngFor="let field of section.fields"
                class="value-field"
                [label]="field.label"
                [value]="field.value"
                [hint]="field.hint"
                [uom]="field.uom"
              />
            </section>
            <section
              *ngIf="section.comment"
              class="section">
              <div class="section-heading">DESCRIPTION:</div>
              <div class="section-content">{{section.comment}}</div>
            </section>
            <section
              *ngIf="section.pictures?.length"
              class="section">
              <app-attachments-block
                [pictures]="section.pictures"
              />
            </section>
          </div>
        </mat-expansion-panel>
      </ng-container>
      <section
        *ngIf="data.comment"
        class="section description-section">
        <div class="section-heading">DESCRIPTION:</div>
        <div class="section-content">{{data.comment}}</div>
      </section>
      <section
        *ngIf="data.pictures?.length"
        class="section">
        <app-attachments-block
          [pictures]="data.pictures"
        />
      </section>
    </section>
    <section *ngIf="!sectionsList.length" class="section">
      <section class="values-section">
        <app-view-field
          class="value-field"
          label="QUANTITY"
          [value]="data.quantity || 0"
          hint=""
          [uom]="data.bid_item?.uom"
        />
        <app-view-field
          *ngFor="let field of fieldsList"
          class="value-field"
          [label]="field.label"
          [value]="field.value"
          [hint]="field.hint"
          [uom]="field.uom"
        />
      </section>
      <section
        *ngIf="data.pictures?.length"
        class="section">
        <app-attachments-block
          [pictures]="data.pictures"
        />
      </section>
    </section>
  </section>
</div>

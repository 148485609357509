import { BaseModel } from './base-model';
import { Site } from './site';
import { DailyReport } from './daily-report';
import weatherConditions from '../services/weather-conditions';

export class Weather extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Weather'; }

  /* tslint:disable */
  avgtemp: number;
  maxtemp: number;
  mintemp: number;
  wind: number;
  condition: any;
  work_condition: any;
  icon: number;
  location: string;
  notes: string;

  site_id: string;
  daily_report_id: string;

  site: Site;
  dailyReport: DailyReport;
  /* tslint:enable */

  get relations() {
    return {
      site: Site,
      dailyReport: DailyReport,
    };
  }

  get isEmpty(): boolean {
    return !(this.avgtemp || this.maxtemp || this.mintemp);
  }

  get getIcon() {
    const wc = weatherConditions.find(o => o.code === this.condition?.code);
    return wc?.icon || '116';
  }

  /**
   * Workaround to initialize default values
   */
  init() {
    this.condition = { code: 1000, text: 'Sunny', icon: 113 };
  }

  toPayload() {
    return {
      wind: this.wind || 0,
      avgtemp: this.avgtemp || 0,
      maxtemp: this.maxtemp || 0,
      mintemp: this.mintemp || 0,
      location: this.location || '',
      condition: this.condition || { code: 1000, text: 'Sunny', icon: 113 },
      notes: this.notes || '',
    };
  }

}

<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>

    <button *ngIf="initialized" mat-mini-fab (click)="toggleForm(material, true)"
      [disabled]="!('update' | able: material)">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <div class="container">
    <!-- title section -->
    <section class="header">
      <div class="title">
        <ng-container *ngIf="material?.id">{{material.name}}</ng-container>
        <ng-container *ngIf="!material?.id">Add Material</ng-container>
      </div>
    </section>
    <!-- /title section -->
  </div>

  <mat-tab-group selectedIndex="0">
    <mat-tab label="Details">
      <ng-template matTabContent>
        <ng-container *ngIf="initialized">
          <ng-container *ngTemplateOutlet="InputFormContent"></ng-container>
        </ng-container>
      </ng-template>
    </mat-tab>
</mat-tab-group>
</div>


<ng-template #InputFormContent>
  <!-- Input Form -->
  <form [formGroup]="inputForm">
    <div class="container">
      <!-- name section -->
      <section>
        <div class="mat-subheading-1 row">Name:</div>
        <div class="mat-body" *ngIf="!editing">{{material.name}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.name.hasError('error')">
            {{inputForm.controls.name.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /name section -->

      <!-- uom section -->
      <section>
        <div class="mat-subheading-1 row">UOM:</div>
        <div class="mat-body" *ngIf="!editing">{{material.uom}}</div>
        <mat-form-field *ngIf="editing">
          <input matInput type="text" autocomplete="off" formControlName="uom" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.uom.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.uom.hasError('error')">
            {{inputForm.controls.uom.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>
      <!-- /uom section -->

      <!-- track_received section -->
      <section>
        <div class="mat-subheading-1 row">
          <div>Track received:</div>
          <div class="icons">
            <ng-container *ngIf="!editing">
              <ng-container *ngIf="material?.track_received">Yes</ng-container>
              <ng-container *ngIf="!material?.track_received">No</ng-container>
            </ng-container>
            <ng-container *ngIf="editing">
              <mat-slide-toggle formControlName="track_received"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.controls.track_received.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.track_received.hasError('error')">
                {{inputForm.controls.track_received.errors.error}}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- /track_received section -->

      <!-- track_used section -->
      <section>
        <div class="mat-subheading-1 row">
          <div>Track used:</div>
          <div class="icons">
            <ng-container *ngIf="!editing">
              <ng-container *ngIf="material?.track_used">Yes</ng-container>
              <ng-container *ngIf="!material?.track_used">No</ng-container>
            </ng-container>
            <ng-container *ngIf="editing">
              <mat-slide-toggle formControlName="track_used"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.controls.track_used.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.track_used.hasError('error')">
                {{inputForm.controls.track_used.errors.error}}
              </mat-error>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- /track_used section -->

      <!-- Form Actions -->
      <section>
        <div class="row" *ngIf="editing">
          <button mat-stroked-button color="accent" (click)="save()">
            <ng-container *ngIf="material?.id">Update</ng-container>
            <ng-container *ngIf="!material?.id">Create</ng-container>
          </button>
          <button mat-stroked-button (click)="onCancel()">Cancel</button>
        </div>
      </section>
      <!-- Form Actions -->
    </div>
  </form>
  <!-- /Input form -->
</ng-template>
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BidItem, DailyReport, Material, Project, ReportMaterialDelivery, Site } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService, DailyReportService } from 'src/app/shared/services';
import { MaterialBiditemSelectorComponent } from '../../material-biditem-selector/material-biditem-selector.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';

@Component({
  selector: 'app-rmu',
  templateUrl: './rmu.component.html',
  styleUrls: ['./rmu.component.scss']
})
export class RmuComponent implements OnInit {

  public initialized: boolean = false;
  public loading: number = 0;
  public changes: number = 0;

  @Input() public editable: boolean = false;
  @Input() public isDialog: boolean = true;
  public dialogOptions: any = {};

  @Input() rmds: ReportMaterialDelivery[] = [];
  @Input() dr: DailyReport;
  @Input() biditem: BidItem;
  @Input() material: Material;
  @Input() project: Project;

  public biditems: BidItem[] = [];
  public rmd: ReportMaterialDelivery;
  public site: Site;
  public selectedItem: ReportMaterialDelivery;

  public headerItem: ReportMaterialDelivery;
  @ViewChild('materialDropdown') materialDropdown;
  public lineItems: ReportMaterialDelivery[] = [];
  public materialBiditems: BidItem[] = [];

  public matcher = new AppErrorStateMatcher();

  get hasDirtyItems(): boolean {
    return [
      this.headerItem?.isDirty,
      ...this.lineItems.map(li => li.isDirty)
    ].reduce((p, c) => p || c, false);
  }

  get materials(): Material[] {
    return (this.biditem?.materials || []).filter(o => o.track_used);
  }

  constructor(
    private drSrv: DailyReportService,
    public appSrv: AppService,
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<RmuComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.isDialog) {
      const { rmds = [], biditem, material, project, dailyReport, options } = this.inputData;
      this.dialogOptions = Object.assign(this.dialogOptions, options);
      this.rmds = rmds.slice();
      this.biditem = biditem ?? null;
      this.material = material ?? null;
      this.dr = dailyReport;
      this.project = project;
    }

    // link some project level data to line items
    this.biditems = this.project.bid_items || [];
    this.rmds.map(o => {
      o.bid_item = this.biditems.find(bi => bi.id === o.bid_item_id);
    });

    // this.fetchData();
    if (!this.biditem && !this.material) {
      this.openSelector();
    } else {
      this.init();
    }
  }

  get isNew(): boolean {
    return !this.rmd?.id;
  }

  get captureLineItems(): boolean {
    return !!(this.biditem?.materials || []).filter(o => o.track_used).length;
  }

  init() {
    const rmds = this.rmds.filter(o => o.used);

    // grab data from existing records
    if (this.biditem) {
      const rows = rmds
        .filter(o => o.bid_item_id === this.biditem?.id && !o.material?.id);
      if (rows.length) {
        this.rmd = rows[0];
      }
      if (this.captureLineItems) {
        rmds
          .filter(o => o.bid_item_id === this.biditem?.id && o.material?.id)
          .map(o => this.addLineItem(o, o.material));
      }
    }

    if (this.material) {
      const rows = rmds
        .filter(o => o.material?.id === this.material?.id && !o.bid_item_id);
      if (rows.length) {
        this.rmd = rows[0];
      }
      this.materialBiditems = (this.project?.bid_items || [])
        .filter(o => o.materials.map(p => p.id).indexOf(this.material?.id) > -1)
    }

    if (!this.biditem) {
      // create header item
      this.headerItem = this.rmd || new ReportMaterialDelivery({
        bid_item: this.biditem,
        material: this.material,
      });
      this.headerItem._isHeader = true;

      this.toReactiveForm(this.headerItem);
      this.selectLineItem(this.headerItem);
    }

    this.initialized = true;
  }

  addLineItem(li: any, material: Material) {

    li = li ?? new ReportMaterialDelivery({
      bid_item: this.biditem ?? null,
      material: material ?? null,
    });

    this.toReactiveForm(li);
    this.lineItems.push(li);
    if (!this.selectedItem || !li?.id) {
      this.selectLineItem(li);
    }

    if (this.materialDropdown) {
      this.materialDropdown.value = null;
    }
  }

  toReactiveForm(m: any) {

    let form = this.fb.group({
      id: [''],
      used: [m?.used, Validators.required],
    });

    form.reset({
      id: m?.id || null,
      used: m?.used || null,
    });
    m._inputForm = form;
  }

  onClose() {
    if (!this.hasDirtyItems) {
      this.dialogRef.close(this.changes);
      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Unsaved changes',
        `There are unsaved changes since the last opening of this item.
        <br />Are you sure you want to discard?`,
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(this.changes);
      }
    });
  }

  /**
   * onSubmit() handler for station form
   * @param form FormGroup
   * @returns {void}
   */
  save(li: ReportMaterialDelivery = null, options: any = {}): Promise<any> {
    if (!li) {
      return;
    }
    const { showMessage = true } = options;
    const form = li._inputForm;
    if (!form.valid) {
      return;
    }

    const payload = li.toPayload(form.value);

    this.loading++;
    return this.drSrv.saveLineItem('material_delivery', payload, this.dr, { include: ['pictures', 'annotations'] })
      .then((resp: ReportMaterialDelivery) => {
        if (showMessage) {
          this.snackBar.open('Saved delivery', '', { duration: 5000 });
        }
        this.changes++;

        li = Object.assign(li, resp);
        this.toReactiveForm(li);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(form, resp);
          if (!showMessage) {
            throw new Error('422');
          }
          return;
        }

        if (!showMessage) {
          throw new Error(resp.error?.error || 'Oops! something went wrong.');
        }

        this.snackBar.open(
          resp.error?.error || 'Oops! something went wrong.',
          '',
          { duration: 5000 },
        );
      })
      .finally(() => {
        this.loading--;
      });
  }

  saveAll() {
    // dirty workaround to trigger validations
    // mark all forms as touched
    const isValid = [
      this.headerItem,
      ...this.lineItems,
    ].filter(o => !!o)
      .map(o => {
        o._inputForm?.markAsDirty();
        o._inputForm?.markAllAsTouched();
        return o._inputForm?.valid;
      }).reduce((p, c) => p && c, true);

    if (!isValid) {
      console.log('is invalid');
      console.log(this.headerItem._inputForm);
      // show some error
      return;
    }

    // attempt save
    Promise.all([
      this.save(this.headerItem, { showMessage: false }),
      ...this.lineItems.map((o) => this.save(o, { showMessage: false })),
    ]).then(() => {
      this.snackBar.open('Saved delivery', '', { duration: 5000 });
      this.dialogRef.close(this.changes);
    })
      .catch((e) => {
        if (e.message == '422') {
          // show validation error msg
          return;
        }
        this.snackBar.open(
          e.message || 'Oops! something went wrong',
          '',
          { duration: 5000 }
        );
      });
  }

  /**
   * Opens Selector
   */
  openSelector(): void {
    const dialogRef = this.dialog.open(MaterialBiditemSelectorComponent, {
      disableClose: false,
      width: '700px',
      height: '500px',
      data: {
        project: this.dr?.project,
        type: 'usage',
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (!resp) {
        this.dialogRef.close(null);
        return;
      }
      if (resp instanceof BidItem) {
        this.biditem = resp;
      }
      if (resp instanceof Material) {
        this.material = resp;
      }
      this.init();
    });
  }

  getWidth(field) {
    return field?.type === 'textarea' ? 'width-100' : 'width-50';
  }

  isRequired(formControl: UntypedFormControl): boolean {
    if ('validator' in formControl && typeof formControl.validator === 'function') {
      const validator = formControl?.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    return false;
  }

  /**
   * Deletes line item
   * @param li LineItem
   * @returns void
   */
  delete(li: ReportMaterialDelivery, options: any = {}) {
    if (!li) {
      return;
    }
    const { showMessage = true } = options;
    if (!li.id && li._id) {
      this.lineItems.splice(this.lineItems.findIndex(o => o._id === li._id), 1);
      return;
    }

    if (!showMessage) {
      return this.drSrv.deleteLineItem('material_delivery', li, this.dr)
        .then(() => {
          this.changes++;
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
        });
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting delivery cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.loading++;
      return this.drSrv.deleteLineItem('material_delivery', li, this.dr)
        .then(resp => {
          this.lineItems.splice(this.lineItems.findIndex(o => o.id === li.id), 1);
          this.changes++;
          this.snackBar.open(resp.message || 'Deleted', '', { duration: 5000 });
          if (li.id === this.headerItem?.id) {
            this.dialogRef.close(this.changes);
          }
        })
        .catch((resp: HttpErrorResponse) => {
          this.snackBar.open(resp.error.error, '', { duration: 5000 });
        })
        .finally(() => {
          this.loading--;
        });
    });
  }

  deleteAll() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: new ConfirmDialogModel(
        'Delete',
        'Deleting delivery cannot be undone.<br />Proceed to delete?'
      ),
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      Promise.all([
        ...this.lineItems.map((o) => this.delete(o, { showMessage: false })),
        this.delete(this.headerItem, { showMessage: false }),
      ]).then(() => {
        this.snackBar.open('Delivery deleted', '', { duration: 5000 });
        this.dialogRef.close(this.changes);
      });
    });
  }

  selectLineItem(li: any = null) {
    if (!li) {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = this.headerItem || undefined, 200);
      return;
    }

    if (this.selectedItem?._id === li?._id) {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = this.headerItem || undefined, 200);
    } else {
      this.selectedItem = undefined;
      setTimeout(() => this.selectedItem = li, 200);
    }
  }

  duplicateLineItem(m: Material): boolean {
    return !!this.lineItems.find(o => o.material?.id === m.id);
  }

  onLineItemChange(li: ReportMaterialDelivery) {
    const { comment, pictures, annotations, location } = li;
    this.selectedItem.setValue('comment', comment);
    this.selectedItem.setValue('pictures', pictures);
    this.selectedItem.setValue('annotations', annotations);
    this.selectedItem.setValue('location', location);
  }
}

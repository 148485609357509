import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IssueModule } from 'src/app/modules/issue/issue.module';
import { SubContractorModule } from 'src/app/modules/sub-contractor/sub-contractor.module';
import { SharedModule } from 'src/app/shared/shared.module';

/* current module imports */
import { AnnotComponent } from './annot/annot.component';
import { BidItemCreateComponent } from './bid-item-create/bid-item-create.component';
import { BiditemHeadingsComponent } from './biditem/biditem-headings/biditem-headings.component';
import { BiditemInternalTestsComponent } from './biditem/biditem-internal-tests/biditem-internal-tests.component';
import { BiditemMaterialsComponent } from './biditem/biditem-materials/biditem-materials.component';
import { BiditemSiteQuantitiesComponent } from './biditem/biditem-site-quantities/biditem-site-quantities.component';
import { AccountInlineComponent } from './components/account/account-inline/account-inline.component';
import { BidItemSelectorComponent } from './components/bid-item-selector/bid-item-selector.component';
import { DrawingSelectorComponent } from './components/drawing-selector/drawing-selector.component';
import { DrawingCreateComponent } from './components/drawings/drawing-create/drawing-create.component';
import { EquipmentSetupComponent } from './components/equipment-setup/equipment-setup.component';
import { EquipmentTypesComponent } from './components/equipment-setup/equipment-types/equipment-types.component';
import { EquipmentsComponent } from './components/equipment-setup/equipments/equipments.component';
import { IssuesSummaryComponent } from './components/issues-summary/issues-summary.component';
import { CrewsComponent } from './components/labor-setup/crews/crews.component';
import { JobCodesComponent } from './components/labor-setup/job-codes/job-codes.component';
import { LaborSetupComponent } from './components/labor-setup/labor-setup.component';
import { LaborsComponent } from './components/labor-setup/labors/labors.component';
import { LatestProjectReportSummariesComponent } from './components/latest-project-report-summaries/latest-project-report-summaries.component';
import { MaterialBiditemSelectorComponent } from './components/material-biditem-selector/material-biditem-selector.component';
import { ProjectReportDetailComponent } from './components/project-report-detail/project-report-detail.component';
import { ProjectStatusSummaryComponent } from './components/project-status-summary/project-status-summary.component';
import { ProjectSubmittalsSummaryComponent } from './components/project-submittals-summary/project-submittals-summary.component';
import { ReportActivityCardComponent } from './components/report-activity-card/report-activity-card.component';
import { ReportBidItemCardComponent } from './components/report-bid-item-card/report-bid-item-card.component';
import { ActivitiesComponent } from './components/report-components2/activities/activities.component';
import { RbiSummaryComponent } from './components/report-components2/rbi-summary/rbi-summary.component';
import { RbiComponent } from './components/report-components2/rbi/rbi.component';
import { ReportItemToolbarComponent } from './components/report-components2/report-item-toolbar/report-item-toolbar.component';
import { RitSummaryComponent } from './components/report-components2/rit-summary/rit-summary.component';
import { RitComponent } from './components/report-components2/rit/rit.component';
import { RmdSummaryComponent } from './components/report-components2/rmd-summary/rmd-summary.component';
import { RmdComponent } from './components/report-components2/rmd/rmd.component';
import { RmuComponent } from './components/report-components2/rmu/rmu.component';
import { ReportIssuesComponent } from './components/report-issues/report-issues.component';
import { ReportLaborEquipmentCardComponent } from './components/report-labor-equipment-card/report-labor-equipment-card.component';
import { ReportMaterialDeliveryCardComponent } from './components/report-material-delivery-card/report-material-delivery-card.component';
import { ReportRendererComponent } from './components/report-renderer/report-renderer.component';
import { ReportWeatherCardComponent } from './components/report-weather-card/report-weather-card.component';
import { SettingsComponent } from './components/settings/settings.component';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { InternalTestCreateComponent } from './internal-test/internal-test-create/internal-test-create.component';
import { InternalTestsComponent } from './internal-test/internal-tests/internal-tests.component';
import { ProjectInviteCreateComponent } from './invitation/components/project-invite-create/project-invite-create.component';
import { ProjectInvitesComponent } from './invitation/components/project-invites/project-invites.component';
import { IssueComponent } from './issues/issue/issue.component';
import { LookupsComponent } from './lookup/lookups/lookups.component';
import { ProjectLookupsComponent } from './lookup/project-lookups/project-lookups.component';
import { ManageSiteMembersComponent } from './manage-site-members/manage-site-members.component';
import { SiteMaterialTestCreateComponent } from './material-test/components/site-material-test-create/site-material-test-create.component';
import { SiteMaterialTestShortComponent } from './material-test/components/site-material-test-short/site-material-test-short.component';
import { SmtRecordComponent } from './material-test/components/smt-record/smt-record.component';
import { SmtReportComponent } from './material-test/components/smt-report/smt-report.component';
import { SmtResultComponent } from './material-test/components/smt-result/smt-result.component';
import { SmtSummaryComponent } from './material-test/components/smt-summary/smt-summary.component';
import { MaterialTestLabCreateComponent } from './material-test/lab/material-test-lab-create/material-test-lab-create.component';
import { ProjectLabsComponent } from './material-test/lab/project-labs/project-labs.component';
import { MaterialCreateComponent } from './material/material-create/material-create.component';
import { MaterialsComponent } from './material/materials/materials.component';
import { ProjectAccountsComponent } from './project-accounts/project-accounts.component';
import { ProjectBidItemsComponent } from './project-bid-items/project-bid-items.component';
import { ProjectConsolidatedReportComponent } from './project-consolidated-report/project-consolidated-report.component';
import { ProjectCreateComponent } from './project-create/project-create.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { ProjectDrawingsComponent } from './project-drawings/project-drawings.component';
import { ProjectMaterialTestsComponent } from './project-material-tests/project-material-tests.component';
import { ProjectReportComponent } from './project-report/project-report.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectSitesComponent } from './project-sites/project-sites.component';
import { ProjectsComponent } from './projects.component';
import { ReportBidItemComponent, ReportMaterialDeliveryComponent } from './report-components';
import { ReportEquipmentCardComponent } from './report-components/report-equipment-card/report-equipment-card.component';
import { ReportEquipmentComponent } from './report-components/report-equipment/report-equipment.component';
import { ReportLaborCardComponent } from './report-components/report-labor-card/report-labor-card.component';
import { ReportLaborComponent } from './report-components/report-labor/report-labor.component';
import { ReportWeatherComponent } from './report-components/report-weather/report-weather.component';
import { SiteCreateComponent } from './site-create/site-create.component';
import { SiteInternalTestsComponent } from './site/site-internal-tests/site-internal-tests.component';
import { AlignmentHeadingsComponent } from './site/stations/alignment-headings/alignment-headings.component';
import { AlignmentStationsComponent } from './site/stations/alignment-stations/alignment-stations.component';
import { SiteAlignmentsComponent } from './site/stations/site-alignments/site-alignments.component';
import { ProjectMapComponent } from './project-map/wrapper/project-map.component';
import { ProjectMapLegendComponent } from './project-map/components/project-map-legend/project-map-legend.component';
import { MapSidenavBidItemWrapperComponent } from './project-map/components/sidenav-components/bid-items/wrapper/wrapper.component';
import { ProjectMapSidenavComponent } from './project-map/components/project-map-sidenav/project-map-sidenav.component';
import {
  MapSidenavActivitiesWrapperComponent
} from './project-map/components/sidenav-components/activities/wrapper/wrapper.component';
import {
  MapSidenavDeliveriesWrapperComponent
} from './project-map/components/sidenav-components/deliveries/wrapper/wrapper.component';
import { MapSidenavIssuesWrapperComponent } from './project-map/components/sidenav-components/issues/wrapper/wrapper.component';
import {
  MapSidenavLaborEquipmentWrapperComponent
} from './project-map/components/sidenav-components/labor-equipment/wrapper/wrapper.component';
import { MapSidenavQualityWrapperComponent } from './project-map/components/sidenav-components/quality-test/wrapper/wrapper.component';
import { MapSidenavBidItemDetailsComponent } from './project-map/components/sidenav-components/bid-items/details/details.component';
import { UsageBarComponent } from './project-map/components/usage-bar/usage-bar.component';
import { LaborBlockComponent } from './project-map/components/sidenav-components/labor-equipment/labor-block/labor-block.component';
import { AttachmentsBlockComponent } from './project-map/components/sidenav-components/attachments-block/attachments-block.component';
import { EquipmentBlockComponent } from './project-map/components/sidenav-components/labor-equipment/equipment-block/equipment-block.component';
import { StationComponent } from './project-map/components/sidenav-components/bid-items/station/station.component';
import { OnsiteTestsComponent } from './project-map/components/sidenav-components/quality-test/onsite-tests/onsite-tests.component';
import { OffsiteTestsComponent } from './project-map/components/sidenav-components/quality-test/offsite-tests/offsite-tests.component';
import { DateFormatPipe } from '../../shared/pipes';
import { OffsiteTestRequestBlockComponent } from './project-map/components/sidenav-components/quality-test/offsite-test-components/offsite-test-request-block/offsite-test-request-block.component';
import { OffsiteTestScheduleBlockComponent } from './project-map/components/sidenav-components/quality-test/offsite-test-components/offsite-test-schedule-block/offsite-test-schedule-block.component';
import { OffsiteTestRecordBlockComponent } from './project-map/components/sidenav-components/quality-test/offsite-test-components/offsite-test-record-block/offsite-test-record-block.component';
import { OffsiteTestReportBlockComponent } from './project-map/components/sidenav-components/quality-test/offsite-test-components/offsite-test-report-block/offsite-test-report-block.component';
import { OffsiteTestResultBlockComponent } from './project-map/components/sidenav-components/quality-test/offsite-test-components/offsite-test-result-block/offsite-test-result-block.component';
import { MapTooltipComponent } from './project-map/components/map-tooltip/map-tooltip.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxMaterialTimepickerModule,
    SharedModule,
    ProjectRoutingModule,
    FormsModule,
    IssueModule,
    SubContractorModule,
  ],
  declarations: [
    ProjectsComponent,
    ProjectCreateComponent,
    ProjectReportComponent,
    DailyReportComponent,
    ReportBidItemComponent,
    ReportMaterialDeliveryComponent,
    ProjectDetailComponent,
    ProjectSitesComponent,
    SiteCreateComponent,
    ProjectAccountsComponent,
    ProjectInvitesComponent,
    ProjectMaterialTestsComponent,
    ProjectBidItemsComponent,
    BidItemCreateComponent,
    ManageSiteMembersComponent,
    ProjectDrawingsComponent,
    AnnotComponent,
    ProjectConsolidatedReportComponent,
    ReportBidItemCardComponent,
    ReportMaterialDeliveryCardComponent,
    ReportLaborEquipmentCardComponent,
    ReportActivityCardComponent,
    BidItemSelectorComponent,
    DrawingSelectorComponent,
    ReportWeatherCardComponent,
    ReportWeatherComponent,
    ReportLaborComponent,
    ReportLaborCardComponent,
    ReportEquipmentComponent,
    ReportEquipmentCardComponent,
    IssueComponent,
    AccountInlineComponent,
    ProjectLabsComponent,
    MaterialTestLabCreateComponent,
    SiteMaterialTestShortComponent,
    SiteMaterialTestCreateComponent,
    SmtSummaryComponent,
    SmtRecordComponent,
    SmtReportComponent,
    SmtResultComponent,
    LatestProjectReportSummariesComponent,
    ProjectReportDetailComponent,
    ProjectInviteCreateComponent,
    ProjectStatusSummaryComponent,
    IssuesSummaryComponent,
    ReportIssuesComponent,
    DrawingCreateComponent,
    ProjectSubmittalsSummaryComponent,
    BiditemHeadingsComponent,
    BiditemSiteQuantitiesComponent,
    MaterialsComponent,
    MaterialCreateComponent,
    BiditemMaterialsComponent,
    InternalTestsComponent,
    InternalTestCreateComponent,
    BiditemInternalTestsComponent,
    SiteAlignmentsComponent,
    AlignmentStationsComponent,
    SiteInternalTestsComponent,
    AlignmentHeadingsComponent,
    ProjectLookupsComponent,
    LookupsComponent,
    SettingsComponent,
    ReportRendererComponent,
    RbiSummaryComponent,
    RbiComponent,
    ReportItemToolbarComponent,
    RitSummaryComponent,
    RitComponent,
    ActivitiesComponent,
    RmdSummaryComponent,
    RmdComponent,
    MaterialBiditemSelectorComponent,
    RmuComponent,
    LaborSetupComponent,
    JobCodesComponent,
    LaborsComponent,
    CrewsComponent,
    EquipmentSetupComponent,
    EquipmentTypesComponent,
    EquipmentsComponent,
    ProjectMapComponent,
    ProjectMapLegendComponent,
    MapSidenavBidItemWrapperComponent,
    ProjectMapSidenavComponent,
    MapSidenavActivitiesWrapperComponent,
    MapSidenavDeliveriesWrapperComponent,
    MapSidenavIssuesWrapperComponent,
    MapSidenavLaborEquipmentWrapperComponent,
    MapSidenavQualityWrapperComponent,
    MapSidenavBidItemDetailsComponent,
    UsageBarComponent,
    LaborBlockComponent,
    AttachmentsBlockComponent,
    EquipmentBlockComponent,
    StationComponent,
    OnsiteTestsComponent,
    OffsiteTestsComponent,
    OffsiteTestRequestBlockComponent,
    OffsiteTestScheduleBlockComponent,
    OffsiteTestRecordBlockComponent,
    OffsiteTestReportBlockComponent,
    OffsiteTestResultBlockComponent,
    MapTooltipComponent,
  ],
  exports: [
    ProjectsComponent,
    ProjectSitesComponent,
    ProjectAccountsComponent,
    ProjectInvitesComponent,
    ProjectMaterialTestsComponent,
    ProjectBidItemsComponent,
    ProjectDrawingsComponent,
    ProjectLabsComponent,
    MaterialsComponent,
    InternalTestsComponent,
    ProjectLookupsComponent,
    SettingsComponent,
    MapTooltipComponent,
    ProjectConsolidatedReportComponent,
    DailyReportComponent,
    ProjectMapLegendComponent
  ],
  providers: [DateFormatPipe],
})
export class ProjectModule {}

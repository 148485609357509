import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LocationPickerComponent } from 'src/app/shared/components/location-picker/location-picker.component';
import { Annotation, Attachment, Location, Project } from 'src/app/shared/models';
import { AppService } from 'src/app/shared/services';
import { AnnotComponent } from '../../../annot/annot.component';

@Component({
  selector: 'app-report-item-toolbar',
  templateUrl: './report-item-toolbar.component.html',
  styleUrls: ['./report-item-toolbar.component.scss']
})
export class ReportItemToolbarComponent implements OnInit {

  @Output() changed = new EventEmitter<any>();
  @Input() lineItem: any;
  @Input() project: Project;
  @Input() tools: any;
  public comment: string;
  public pictures: Attachment[] = [];
  public annotations: Annotation[] = [];
  public location: Location;


  public selectedTab: number;
  public selectedTabIndex: number = 0;

  constructor(
    private appSrv: AppService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    const { comment, pictures, annotations, location } = this.lineItem;

    this.comment = `${comment || ''}`;
    this.pictures = (pictures || []).slice();
    this.annotations = (annotations || []).slice();
    this.location = new Location(location?.lat, location?.lng);
    this.tools = {
      pictures: true,
      location: true,
      annotations: true,
      comment: true,
      ...this.tools
    };
  }

  emitChange() {
    this.changed.emit({
      comment: this.comment,
      pictures: this.pictures,
      annotations: this.annotations,
      location: this.location,
    });
  }

  // dirty trick to unselect a tab
  tabClicked(i: number) {
    if (i === this.selectedTabIndex) {
      setTimeout(() => this.selectedTabIndex = 0, 50);
    }
  }

  /**
   * Attach uploaded attachment to pictures
   * @param attachment Attachment
   */
  onPictureUpload(attachments: Attachment[]) {
    attachments.map(a => this.pictures.push(a));
    this.emitChange();
  }

  /**
   * Remove pic from list
   * @param pic Attachment
   */
  removePicture(pic) {
    this.pictures.splice(this.pictures.findIndex(p => p.id === pic.id), 1);
    this.emitChange();
  }

  /**
   * Remove annot from list
   * @param annot Annotation
   */
  removeAnnot(annot) {
    this.annotations.splice(this.annotations.findIndex(a => a.id === annot.id), 1);
    this.emitChange();
  }

  /**
   * Opens annotations in Modal
   * @param annotation Annotation
   */
  openAnnot(annotation: Annotation = null) {
    const dialogRef = this.dialog.open(AnnotComponent, {
      disableClose: true,
      data: {
        annotation: annotation || new Annotation(),
        project: this.project,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (resp instanceof Annotation) {
        const index = this.annotations.findIndex(o => o.id === resp.id);
        if (index > -1) {
          this.annotations.splice(index, 1, resp);
        } else {
          this.annotations.push(resp);
        }
      }
    });
  }

  /**
   * Open location to edit
   * @param loc Location
   */
  updateLocation(loc: Location = null): void {
    const dialogRef = this.dialog.open(LocationPickerComponent, {
      disableClose: true,
      data: {
        latitude: loc?.lat,
        longitude: loc?.lng,
        readOnly: false,
      },
    });

    dialogRef.afterClosed().subscribe(resp => {
      const { latitude: lat = null, longitude: lng = null } = resp || {};
      if (lat && lng) {
        this.location = new Location();
        setTimeout(() => {
          this.location = new Location(lat, lng);
          this.emitChange();
        }, 200);
      }
    });
  }

  onCommentChange(e: any) {
    this.emitChange();
  }
}

import { BaseModel } from './base-model';

export class Role extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Role'; }

  /* tslint:disable */
  name: string;
  code: string;
  /* tslint:enable */
}

import { Component } from '@angular/core';
import { CommonService } from '../../../../../../../../shared/services/common.service';

@Component({
  selector: 'app-success-component',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent {
  constructor(private commonService: CommonService) {
  }

  onSubmit() {
    this.commonService.closeDialog.next();
  }

}

<div class="wrapper">
  <div class="quantity-input">
    <app-number-input
      [error]="form.controls.quantity.errors?.required ? 'This field is required' : null"
      [label]="'USED (' + uom + ')'"
      [parentFormGroup]="form"
      controlName="quantity"
    />
  </div>
  <div
    *ngIf="!!form.controls['comment']"
    class="comment-section"
  >
    <app-text-area
      [parentFormGroup]="form"
      controlName="comment"
      label="COMMENT"
    />
  </div>
  <app-pictures-section
    (onDelete)="deletePicture($event)"
    *ngIf="selectedMediaFiles.length"
    [pictures]="selectedMediaFiles"
    class="pictures-section"
  />
  <app-location-section
    (onDelete)="deleteLocation()"
    *ngIf="showLocationSection()"
    [latitude]="form?.controls?.latitude?.value"
    [longitude]="form?.controls?.longitude?.value"
    class="location-section"
  />
</div>

import { BaseModel } from './base-model';
import { Account } from './account';
import { Project } from './project';
import * as moment from 'moment';
import { DailyReport } from './daily-report';
import { Issue } from './issue';
import { WorkflowLineage } from './workflow-lineage';

export class ProjectReport extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'ProjectReport'; }

  /* tslint:disable */
  report_date: Date|any;
  status: string;
  project: Project;
  createdBy: Account;
  issues: Issue[];
  dailyReports: DailyReport[];
  lastApprovalAction: WorkflowLineage;

  /* for acl */
  project_id: string;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      dailyReports: DailyReport,
      issues: Issue,
      project: Project,
      lastApprovalAction: WorkflowLineage,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.report_date = this.report_date ? moment(this.report_date) : null;

    // for acl
    this.project_id = this.project?.id || this.project_id;
  }
}

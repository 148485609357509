<input #fileInput type="file" name="fileUpload" [multiple]="multiple" style="display: none" />

<div class="icon" *ngIf="icon">
  <button
    class="button"
    mat-button
    *ngIf="!files.length"
    [disabled]="disabled"
    [color]="color"
    (click)="onClick()">

    <mat-icon>{{ icon }}</mat-icon>
  </button>

  <ng-container *ngFor="let file of files">
    <mat-spinner
      strokeWidth="3"
      diameter="32"
      mode="indeterminate"
      [color]="color"
    ></mat-spinner>
  </ng-container>
</div>

<ng-container *ngIf="!icon">
  <button
    class="button"
    *ngIf="!icon"
    mat-button
    [disabled]="disabled"
    [color]="color"
    (click)="onClick()"
  >
    <mat-icon>file_upload</mat-icon>
    {{ text }}
  </button>
  <ul>
    <li *ngFor="let file of files">
      <mat-progress-bar [value]="file.progress"></mat-progress-bar>
      <span class="file-label">
        {{ file.data.name }}
      </span>
    </li>
  </ul>
</ng-container>


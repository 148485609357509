import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Attachment } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService } from 'src/app/shared/services';
import { MaterialTestReport, SiteMaterialTest } from '../../models';
import { SiteMaterialTestService } from '../../services';
@Component({
  selector: 'app-smt-report',
  templateUrl: './smt-report.component.html',
  styleUrls: ['./smt-report.component.scss']
})
export class SmtReportComponent implements OnInit {

  @Input() public smt: SiteMaterialTest;
  @Input() editable: boolean = false;
  @Output() public dataChange: EventEmitter<any> = new EventEmitter<any>();

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = true;

  public materialTestReport: MaterialTestReport;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    attachments: [[]],
    comment: [''],
  });

  constructor(
    public appSrv: AppService,
    private smtSrv: SiteMaterialTestService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.materialTestReport = this.smt.material_test_report || new MaterialTestReport({});
    this.toReactiveForm(this.materialTestReport, this.inputForm);

    this.initialized = true;
    this.editing = this.editable;

  }

  /**
   * Assign base model to FormGroup
   * @param model MaterialTestReport
   * @param form FormGroup
   */
  toReactiveForm(model: MaterialTestReport, form: UntypedFormGroup): void {
    form.controls.comment.setValue(model.comment);
  }

  /**
   * Attach uploaded attachment to pictures
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.materialTestReport.attachments.push(a));
    this.inputForm.controls.attachments.setErrors(null);
  }

  /**
   * Remove attachments from list
   * @param att Attachment
   */
  removeAttachment(att) {
    this.materialTestReport.attachments.splice(this.materialTestReport.attachments.findIndex(o => o.id === att.id), 1);
    this.inputForm.controls.attachments.setErrors(null);
  }

  /**
   * Update model
   */
  saveItem() {

    if (!this.inputForm.valid) {
      return;
    }

    this.materialTestReport.comment = this.inputForm.value.comment;
    const payload = this.materialTestReport.toPayload();
    this.loading++;
    this.smtSrv.updateTestReport(this.smt, payload)
      .then((smt: SiteMaterialTest) => {
        this.smt = smt;
        this.toReactiveForm(smt.material_test_report, this.inputForm);
        this.snackBar.open('Updated test report', '', { duration: 5000 });
        this.dataChange.emit(this.smt);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

}

import { createReducer, on } from '@ngrx/store';
import {
  ClearLoading,
  ClearState, FetchAppDataFailure,
  FetchAppDataRequest, FetchAppDataSuccess,
  StartLoading,
  StopLoading,
} from './shared.actions';

// State for this feature (User)
export interface SharedState {
  loading: number[];
  abilities: any[];
  dynamicReportOrganizations: string[];
  limits: any;
  name: string;
  platforms: any;
  weatherConditions: any[];
  workConditions: any[];
}

const initialState: SharedState = {
  loading: [],
  abilities: [],
  dynamicReportOrganizations: [],
  limits: null,
  name: '',
  platforms: null,
  weatherConditions: [],
  workConditions: []
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE
  on(ClearState, state => ({
    ...initialState,
  })),

  // START LOADING
  on(StartLoading, state => ({
    ...state,
    loading: [...state.loading, 1],
  })),

  // STOP LOADING
  on(StopLoading, state => ({
    ...state,
    loading: [...state.loading].slice(1),
  })),

  // CLEAR LOADING
  on(ClearLoading, state => ({
    ...state,
    loading: [],
  })),

  // FETCH APP Data
  on(FetchAppDataRequest, state => ({
    ...state,
    loading: [...state.loading, 1],
    abilities: [],
    dynamicReportOrganizations: [],
    limits: null,
    name: '',
    platforms: null,
    weatherConditions: [],
    workConditions: []
  })),

  on(FetchAppDataSuccess, (state, props) => ({
    ...state,
    loading: [...state.loading].slice(1),
    abilities: props.data.abilities || [],
    dynamicReportOrganizations: props.data.dynamicReportOrganizations || [],
    limits: props.data.limits || null,
    name: props.data.name || '',
    platforms: props.data.platforms || null,
    weatherConditions: props.data.weatherConditions || [],
    workConditions: props.data.workConditions || []
  })),

  on(FetchAppDataFailure, state => ({
    ...state,
    loading: [...state.loading].slice(1),
    abilities: [],
    dynamicReportOrganizations: [],
    limits: null,
    name: '',
    platforms: null,
    weatherConditions: [],
    workConditions: []
  })),
);

<div class="wrapper">
  <div class="heading text-type-5">{{ data.quantity }} {{ data?.type }}</div>
  <div class="time text-type-14 mt-[10px] flex gap-[12px]">
    <div>
      {{ data.duration }} hour{{ data.duration !== 1 ? 's' : '' }}
    </div>
    <div>
      {{ data.idle_duration }} hour{{ data.idle_duration !== 1 ? 's' : '' }} idle
    </div>
  </div>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProjectMapService } from '../../../../project-map.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['../../styles.css', './wrapper.component.scss'],
})
export class MapSidenavQualityWrapperComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() data: any;

  heading = '';
  subheading = '';

  testType = '';

  constructor(
    private projectMapService: ProjectMapService,
  ) {
  }

  ngOnInit() {
    this.setTestType(this.data);

    this.projectMapService.selectedMarkerData
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        this.setTestType(data);
      });
  }

  setTestType(data) {
    this.testType = data.test_type;

    switch (this.testType) {
      case 'internal':
        this.heading = data?.internal_test?.name;
        this.subheading = '';
        break;
      case 'external':
        this.heading = data?.bid_item?.item;
        this.subheading = data?.bid_item?.description;
        this.data = data;
        break;
      default:
        this.heading = '';
        this.subheading = '';
        break;
    }
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

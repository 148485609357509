<div class="wrapper">
  <div class="quantity-input">
    <app-number-input
      [error]="form?.controls?.quantity?.errors?.required ? 'This field is required' : null"
      [isDecimalAllowed]="true"
      [label]="'RECEIVED (' + uom + ')'"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="quantity"
    />
  </div>

  <div *ngIf="customFields?.length > 0" class="fields-row input-block">
    <ng-container *ngFor="let field of customFields">
      <ng-container *ngIf="field.type === 'string' || false">
        <app-text-input
          [controlName]="field.id"
          [label]="field.label"
          [parentFormGroup]="form"
        />
      </ng-container>
      <ng-container *ngIf="field.type === 'number' || false">
        <app-number-input
          [controlName]="field.id"
          [label]="field.label"
          [parentFormGroup]="form"
        />
      </ng-container>
    </ng-container>
  </div>
  <div
    *ngIf="!!form.controls['comment']"
    class="comment-section"
  >
    <app-text-area
      [parentFormGroup]="form"
      controlName="comment"
      label="COMMENT"
    />
  </div>
  <app-pictures-section
    (onDelete)="deletePicture($event)"
    *ngIf="selectedMediaFiles.length"
    [pictures]="selectedMediaFiles"
    class="pictures-section"
  />
  <app-location-section
    (onDelete)="deleteLocation()"
    *ngIf="showLocationSection()"
    [latitude]="form?.controls?.latitude?.value"
    [longitude]="form?.controls?.longitude?.value"
    class="location-section"
  />
</div>

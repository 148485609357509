import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Field, Site, Station } from '../models';
import { HttpResponse } from '@angular/common/http';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class FieldService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Fields
   * @param type string bid-item, internal-test
   * @param id string {BidItem|InternalTest}.id
   * @param qp query params
   * @returns {Promise<Field[]>}
   */
  getRecords(type: string, id: string, qp: any = {}) {
    return this.appHttpService
      .getService(`/v2/${type}/${id}/fields`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          return resp.data.map(r => new Field(r));
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Update Field
   * @param payload Field
   * @param qp any
   * @returns {Promise<Field>}
   */
  update(payload: any, qp: any): Promise<Field> {
    return this.appHttpService
      .putService(`/v2/field/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => new Field(resp?.data));
  }

  /**
   * Create Field
   * @param type string
   * @param id Field.id
   * @param payload Field
   * @param qp any
   * @returns {Promise<Field>}
   */
  create(type: string, id: string, payload: any, qp: any = {}): Promise<Field> {
    return this.appHttpService
      .postService(`/v2/${type}/${id}/field`, payload, qp)
      .toPromise()
      .then((resp: any) => new Field(resp?.data));
  }

  /**
   * Deletes field
   * @param model Field
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Field): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/field/${model?.id}`)
      .toPromise();
  }

  /**
   * Save display_order of all fields
   * @param type bid-item|internal-test
   * @param id Model.id
   * @param fields {id, display_order}
   * @param qp any
   * @returns {Promise<string>}
   */
  changeOrder(type: string, id: string, fields: Field[], qp: any = {}): Promise<string> {
    const payload = fields.map((f: Field) => ({ id: f.id, display_order: f.display_order }));
    return this.appHttpService
      .putService(`/v2/${type}/${id}/change-order`, payload, qp)
      .toPromise();
  }

  toFormGroup(form: UntypedFormGroup, fields: Field[]) {
    (fields || []).forEach(field => {
      const { required = false, min = null, max = null } = field.validation_rules || {};

      const validators = [];
      if (required) {
        validators.push(Validators.required);
      }
      if (!isNaN(min)) {
        validators.push(Validators.min(min));
      }
      if (!isNaN(max)) {
        validators.push(Validators.max(max));
      }

      const ctrl = new UntypedFormControl(field.value || '', validators);
      form.addControl(field.id, ctrl);
    });
    return form;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageProcessingService {

  constructor() { }

  createCircularImage(url: string, size: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = url;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          // Clip to a circular path for transparency
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2, true);
          ctx.closePath();
          ctx.clip();

          // Draw the image, centered and resized to fit the circle
          ctx.drawImage(image, 0, 0, size, size);

          // Convert canvas to a Base64 URL
          const base64Url = canvas.toDataURL('image/png');

          // Clean up by setting references to null
          canvas.remove();  // Explicitly remove canvas from memory
          image.src = '';   // Clean up the image source
          resolve(base64Url); // Return the Base64 URL with transparent background
          
        } else {
          reject('Could not get canvas context');
        }
      };

      image.onerror = () => {
        reject('Image loading failed');
      };
    });
  }
}

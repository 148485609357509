<div class="wrapper">
  <div class="popup-row">
    <div class="title">{{ data.title }}</div>
  </div>
  <div class="popup-row">
    <div
      *ngIf="data?.heading"
      class="heading"
    >
      {{ data?.heading }}
    </div>
    <div
      *ngIf="data?.subheading1"
      class="subheading-1"
    >
      {{ data?.subheading1 }}
    </div>
    <div
      *ngIf="data?.subheading2"
      class="subheading-2"
    >
      {{ data?.subheading2 }}
    </div>
  </div>
  <div
    *ngIf="data.summary"
    class="popup-row"
  >
    <app-map-popup-summary-type-one
      *ngIf="data?.type === 'bid_items' || data?.type === 'deliveries'"
      [data]="data?.summary"
    />
    <app-map-popup-summary-type-two
      *ngIf="data?.type === 'quality_test' || data?.type === 'issues'"
      [data]="data?.summary"
    />
  </div>
  <div
    *ngIf="data?.description"
    class="description-section"
  >
    <div class="label">COMMENT</div>
    <div class="description">
      {{ data?.description }}
    </div>
  </div>
</div>

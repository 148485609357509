<div [class.sidebar]="isDialog">
  <div class="dialog-actions">
    <button [mat-dialog-close]="componentResult" mat-mini-fab aria-label="Close Sidebar" *ngIf="dialogOptions.closable">
      <mat-icon title="Close Sidebar">close</mat-icon>
    </button>

    <button *ngIf="initialized" mat-mini-fab (click)="editing = !editing" [disabled]="!editable">
      <mat-icon>edit</mat-icon>
    </button>
  </div>

  <ng-container *ngIf="initialized">
    <form [formGroup]="inputForm">
      <div class="container">

        <!-- title section -->
        <section class="header">
          <div class="title">&nbsp;</div>
        </section>
        <!-- /title section -->

        <nav mat-tab-nav-bar class="sub-nav">
          <a mat-tab-link active="active">Details</a>
          <a mat-tab-link>Members</a>
          <a mat-tab-link>Stations</a>
        </nav>

        <!-- name section -->
        <section>
          <div class="mat-subheading-1 row">Name:</div>
          <div class="mat-body" *ngIf="!editing">{{site.name}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.name.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.name.hasError('error')">
              {{inputForm.controls.name.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /name section -->

        <!-- duration section -->
        <section>
          <div class="mat-subheading-1 row">Site duration:</div>
          <div class="mat-body" *ngIf="!editing">
            {{site.start_date | dateFormat: "MMMM DD YYYY"}} -
            {{site.end_date | dateFormat: "MMMM DD YYYY"}}
          </div>
          <mat-form-field *ngIf="editing">
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="Start date" formControlName="start_date" />
              <input matEndDate placeholder="End date" formControlName="end_date" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <mat-error *ngIf="inputForm.controls.end_date.hasError('required') || inputForm.controls.start_date.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /duration section -->

        <!-- site value section -->
        <section>
          <div class="mat-subheading-1 row">Site value:</div>
          <div class="mat-body" *ngIf="!editing">{{site.site_value}}</div>
          <mat-form-field *ngIf="editing">
            <input matInput type="number" autocomplete="off" formControlName="site_value" [errorStateMatcher]="matcher" />
            <mat-error *ngIf="inputForm.controls.site_value.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.site_value.hasError('error')">
              {{inputForm.controls.site_value.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /site value section -->

        <!-- description section -->
        <section>
          <div class="mat-subheading-1 row">Description:</div>
          <div class="mat-body" *ngIf="!editing">{{site.description}}</div>
          <mat-form-field *ngIf="editing">
            <textarea matInput formControlName="description" [errorStateMatcher]="matcher"></textarea>
            <mat-error *ngIf="inputForm.controls.description.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.description.hasError('error')">
              {{inputForm.controls.description.errors.error}}
            </mat-error>
          </mat-form-field>
        </section>
        <!-- /description section -->

        <!-- location section -->
        <section>
          <div class="mat-subheading-1">Location:</div>

          <div class="input-placeholder" *ngIf="!site.latitude || !site.longitude" (click)="editLocation(site)">
            <button mat-icon-button [disabled]="!editing" color="accent">
              <mat-icon>location_on</mat-icon>
            </button>Add Location
          </div>

          <div (click)="editLocation(site)" *ngIf="site.latitude">
            <app-location-picker
              [isDialog]="false"
              [latitude]="site.latitude"
              [longitude]="site.longitude"
              height="100px"
              [readOnly]="true"></app-location-picker>
          </div>

          <mat-form-field style="display: none;">
            <input matInput type="number" autocomplete="off" formControlName="latitude"
              [errorStateMatcher]="matcher" />
          </mat-form-field>
          <mat-form-field style="display: none;">
            <input matInput type="number" autocomplete="off" formControlName="longitude"
              [errorStateMatcher]="matcher" />
          </mat-form-field>
          <mat-error *ngIf="inputForm.controls.latitude.hasError('error') || inputForm.controls.longitude.hasError('error')">
            {{inputForm.controls.latitude.errors.error}}
          </mat-error>
        </section>
        <!-- /location section -->

        <!-- Form Actions -->
        <section>
          <div class="row" *ngIf="editing">
            <button [disabled]="!editable" mat-stroked-button color="accent" (click)="onSubmit()">
              <ng-container *ngIf="site.id">Update</ng-container>
              <ng-container *ngIf="!site.id">Create</ng-container>
            </button>
            <button mat-stroked-button (click)="onCancel()">Cancel</button>
          </div>
        </section>
        <!-- Form Actions -->

      </div>
    </form>
  </ng-container>

</div>

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Crew, Project } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CrewService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Crews with pagination
   * @param project Project
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: Crew[]}
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/project/${project?.id}/crews`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Crew(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Crew
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Crew>}
   */
  create(payload: any = null, qp: any = {}): Promise<Crew> {
    return this.appHttpService
      .postService(`/v2/project/${payload?.project_id}/crew`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Crew(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Crew
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Crew>}
   */
  update(payload: any = null, qp: any = {}): Promise<Crew> {
    return this.appHttpService
      .putService(`/v2/crew/${payload?.id}`, payload, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Crew(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Crew
   * @param model Crew
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Crew): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/crew/${model?.id}`)
      .toPromise();
  }
}

import { SubContractor } from 'src/app/modules/sub-contractor';
import { BaseModel } from './base-model';

export class ReportLabor extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'ReportLabor'; }

  /* tslint:disable */
  type: string;
  quantity: number;
  duration: number;
  idle_duration: number;
  comment: string;
  sub_contractor: SubContractor;

  site_id: string;
  daily_report_id: string;
  sub_contractor_id: string;
  __deleted: boolean;
  /* tslint:enable */

  get relations() {
    return {
      sub_contractor: SubContractor,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // add few attributes to assist acl module
    this.sub_contractor_id = this.sub_contractor?.id || this.sub_contractor_id;
  }

  /**
   * Converts this model to POST payload json object
   * @return {object}
   */
  toPayload() {
    return {
      id: this.id || null,
      type: this.type || null,
      quantity: this.quantity || 0,
      duration: this.duration || 0,
      idle_duration: this.idle_duration || 0,
      comment: this.comment || null,
      sub_contractor_id: this.sub_contractor?.id || this.sub_contractor_id,
    };
  }
}

<div class="wrapper">
  <div
    class="input number-input view-mode"
  >
    <div class="label">
      <div class="label-name">{{ label }}</div>
      <div *ngIf="uom" class="uom">&nbsp;({{ uom }})</div>
      :
    </div>
    <div class="value-wrapper">
      <div [innerHTML]="value"></div>
      <div *ngIf="supportingValue" class="supporting-value"> ({{ supportingValue }})</div>
    </div>

    <div class="hint">{{ hint }}</div>
  </div>
</div>


<div class="wrapper">
  <div class="heading">
    Labor and Equipment
  </div>
  <div class="content">

    <mat-expansion-panel
      hideToggle
      [(expanded)]="isLaborSectionExpanded"
      class="section labor-section"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="block-heading">
          <div>LABOR</div>
          <mat-icon
            class="chevron-icon"
            [svgIcon]="isLaborSectionExpanded ? 'chevron-up' : 'chevron-down'"
          />
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="section-content">
        <ng-container
          *ngFor="let labor of data.labor; let isLast = last"
        >
          <app-labor-block
            [id]="labor.id"
            class="each-labor-block"
            [data]="labor"
          />
        </ng-container>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel
      hideToggle
      [(expanded)]="isEquipmentSectionExpanded"
      class="section equipment-section"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="block-heading">
          <div>EQUIPMENT</div>
          <mat-icon
            class="chevron-icon"
            [svgIcon]="isEquipmentSectionExpanded ? 'chevron-up' : 'chevron-down'"
          />
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="section-content">
        <ng-container
          *ngFor="let equipment of data.equipment; let isLast = last"
        >
          <app-equipment-block
            [id]="equipment.id"
            class="each-equipment-block"
            [data]="equipment"
          />
        </ng-container>
      </div>
    </mat-expansion-panel>

  </div>
</div>

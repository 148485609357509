<div class="sidebar" *ngIf="isDialog">
  <button [mat-dialog-close]="componentResult" class="dialog-close" mat-mini-fab aria-label="Close Sidebar"
    *ngIf="dialogOptions.closable">
    <mat-icon title="Close Sidebar">close</mat-icon>
  </button>


  <ng-container *ngIf="initialized">
    <div class="content">
      <form [formGroup]="inputForm">
        <div class="container">

          <section>
            <h1 *ngIf="!project.id">Add project</h1>
            <h1 *ngIf="project.id">Update project "{{project.name}}"</h1>
          </section>

          <!-- Name -->
          <section>
            <mat-form-field>
              <mat-label>Name</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="name" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.name.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.name.hasError('error')">{{inputForm.controls.name.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Name -->

          <!-- Number -->
          <section>
            <mat-form-field>
              <mat-label>Number</mat-label>
              <input matInput type="text" autocomplete="off" formControlName="number" [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.number.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.number.hasError('error')">{{inputForm.controls.number.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Number -->

          <!-- Project duration -->
          <section>
            <mat-form-field>
              <mat-label>Select project duration</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="Start date" formControlName="start_date" />
                <input matEndDate placeholder="End date" formControlName="end_date" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <mat-error
                *ngIf="inputForm.controls.end_date.hasError('required') || inputForm.controls.start_date.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Project duration -->

          <!-- Project value -->
          <section>
            <mat-form-field>
              <mat-label>Project Value</mat-label>
              <input matInput type="number" autocomplete="off" formControlName="project_value"
                [errorStateMatcher]="matcher" />
              <mat-error *ngIf="inputForm.controls.project_value.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.project_value.hasError('error')">
                {{inputForm.controls.project_value.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Project value -->

          <!-- Status -->
          <section>
            <div class="mat-subheading-1">Status:</div>
            <mat-radio-group matInput aria-label="Select status" formControlName="status"
              [errorStateMatcher]="matcher">
              <mat-radio-button value="active">Open</mat-radio-button>
              <mat-radio-button value="inactive">Close</mat-radio-button>
              <!-- <mat-radio-button value="archived">Archived</mat-radio-button> -->
            </mat-radio-group>
            <mat-error *ngIf="inputForm.controls.status.hasError('required')">
              This field is <strong>required</strong>
            </mat-error>
            <mat-error *ngIf="inputForm.controls.status.hasError('error')">
              {{inputForm.controls.status.errors.error}}
            </mat-error>
          </section>
          <!-- /Status -->

          <!-- Description -->
          <section>
            <mat-form-field>
              <mat-label>Description</mat-label>
              <textarea matInput formControlName="description" [errorStateMatcher]="matcher"></textarea>
              <mat-error *ngIf="inputForm.controls.description.hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.controls.description.hasError('error')">
                {{inputForm.controls.description.errors.error}}
              </mat-error>
            </mat-form-field>
          </section>
          <!-- /Description -->

          <!-- new_labor_format section -->
          <section class="row">
            <div class="mat-body">New labor format</div>
            <div class="icons">
              <mat-slide-toggle formControlName="new_labor_format"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.get('new_labor_format').hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.get('new_labor_format').hasError('error')">
                {{inputForm.get('new_labor_format').errors.error}}
              </mat-error>
            </div>
          </section>
          <!-- /new_labor_format section -->

          <!-- offline_support section -->
          <section class="row">
            <div class="mat-body">Offline support</div>
            <div class="icons">
              <mat-slide-toggle formControlName="offline_support"></mat-slide-toggle>
              <mat-error *ngIf="inputForm.get('offline_support').hasError('required')">
                This field is <strong>required</strong>
              </mat-error>
              <mat-error *ngIf="inputForm.get('offline_support').hasError('error')">
                {{inputForm.get('offline_support').errors.error}}
              </mat-error>
            </div>
          </section>
          <!-- /offline_support section -->

          <!-- location section -->
          <section>
            <div class="mat-subheading-1">Location:</div>

            <div class="input-placeholder" *ngIf="!project.latitude || !project.longitude"
              (click)="editLocation(project)">
              <button mat-icon-button color="accent">
                <mat-icon>location_on</mat-icon>
              </button>Add Location
            </div>

            <div (click)="editLocation(project)" *ngIf="project.latitude">
              <app-location-picker [isDialog]="false" [latitude]="project.latitude" [longitude]="project.longitude"
                height="100px" [readOnly]="true"></app-location-picker>
            </div>
          </section>
          <!-- /location section -->

          <!-- Cover photo -->
          <section>
            <div class="mat-subheading-1">Cover photo:</div>
            <div class="preview-list">
              <div *ngIf="project.cover_photo?.id">
                <img [src]="project.cover_photo.getThumbnail()" />
                <button mat-icon-button aria-label="Delete" title="Delete" class="hover-icon" (click)="removePicture()">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <app-file-uploader *ngIf="!project.cover_photo" class="input-placeholder small" icon="image"
                accept="image/*" (complete)="onUploadComplete($event)">
              </app-file-uploader>
            </div>
          </section>
          <!-- /Cover photo -->


          <!-- Form Actions -->
          <section>
            <div class="row">
              <button mat-stroked-button color="primary" (click)="onSubmit()">
                <ng-container>Save</ng-container>
              </button>
              <button mat-stroked-button (click)="onCancel()">Cancel</button>
            </div>
          </section>
          <!-- Form Actions -->

        </div>
      </form>
    </div>
  </ng-container>

</div>

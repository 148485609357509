<ng-container *ngIf="initialized">

  <section>
    <div class="mat-headline">Personal info</div>
    <div class="mat-title">You can update your personal info, notification preferences on Sitenotes</div>
  </section>

  <section class="stroked-card">

    <form [formGroup]="inputForm">

      <div>
        <div class="mat-subheading-2">Basic info</div>
        <div class="mat-title">Some info (name, email, picture) may be visible to other people</div>
      </div>

      <section>
        <div class="mat-subheading-1">Photo</div>
        <div class="preview-list">
          <div *ngIf="account.picture?.id">
            <img [src]="account.picture.getThumbnail()" />
            <button mat-icon-button aria-label="Delete" title="Delete" class="hover-icon" (click)="removePicture()">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <app-file-uploader *ngIf="!account.picture" class="input-placeholder small" icon="image"
            accept="image/*" (complete)="onUploadComplete($event)">
          </app-file-uploader>
        </div>
      </section>

      <section>
        <div class="mat-subheading-1">Name</div>
        <!-- First Name -->
        <mat-form-field>
          <mat-label>First name</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="first_name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.first_name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.first_name.hasError('error')">
            {{inputForm.controls.first_name.errors.error}}
          </mat-error>
        </mat-form-field>
        <!-- /First Name -->
        <!-- Last Name -->
        <mat-form-field>
          <mat-label>Last name</mat-label>
          <input matInput type="text" autocomplete="off" formControlName="last_name" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.last_name.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.last_name.hasError('error')">
            {{inputForm.controls.last_name.errors.error}}
          </mat-error>
        </mat-form-field>
        <!-- /Last Name -->
      </section>

      <section>
        <div class="mat-subheading-1">Phone</div>
        <!-- Phone -->
        <mat-form-field>
          <input matInput type="text" autocomplete="off" formControlName="phone" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.phone.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.phone.hasError('error')">
            {{inputForm.controls.phone.errors.error}}
          </mat-error>
        </mat-form-field>
        <!-- /Phone -->
      </section>

      <section>
        <div class="mat-subheading-1">Email</div>
        <!-- Email -->
        <mat-form-field>
          <input matInput type="text" autocomplete="off" formControlName="email" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="inputForm.controls.email.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.email.hasError('error')">
            {{inputForm.controls.email.errors.error}}
          </mat-error>
        </mat-form-field>
        <!-- /Email -->
      </section>

      <section>
        <div class="mat-subheading-1">Locale</div>
        <mat-form-field>
          <mat-select formControlName="locale" [errorStateMatcher]="matcher">
            <mat-option *ngFor="let locale of locales" [value]="locale">
              {{locale}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="inputForm.controls.locale.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="inputForm.controls.locale.hasError('error')">
            {{inputForm.controls.locale.errors.error}}
          </mat-error>
        </mat-form-field>
      </section>

      <section>
        <div class="mat-subheading-1">Offline support</div>
        <mat-slide-toggle formControlName="offline_support" (change)="onSubmit()"></mat-slide-toggle>
      </section>

      <div class="form-actions">
        <button mat-flat-button color="primary" (click)="onSubmit()">Update basic info</button>
      </div>

    </form>

  </section>


  <section class="stroked-card">

    <form [formGroup]="inputForm">

      <div>
        <div class="mat-subheading-2">Notification preferences</div>
        <div class="mat-title">Configure you notification settings here</div>
      </div>

      <section>
        <div class="mat-subheading-1">SMS notifications</div>
        <mat-slide-toggle formControlName="sms_notifications" (change)="onSubmit()"></mat-slide-toggle>
      </section>
      <section>
        <div class="mat-subheading-1">Push notifications</div>
        <mat-slide-toggle formControlName="push_notifications" (change)="onSubmit()"></mat-slide-toggle>
      </section>
      <section>
        <div class="mat-subheading-1">Email notifications</div>
        <mat-slide-toggle formControlName="email_notifications" (change)="onSubmit()"></mat-slide-toggle>
      </section>

    </form>

  </section>

  <section class="stroked-card">

    <form [formGroup]="passwordForm">

      <div>
        <div class="mat-subheading-2">
          <ng-container *ngIf="account.has_password">Change password</ng-container>
          <ng-container *ngIf="!account.has_password">Set password</ng-container>
        </div>
      </div>

      <section>
        <div class="mat-subheading-1">Password</div>
        <!-- password -->
        <mat-form-field>
          <mat-label>Type in new password</mat-label>
          <input matInput type="password" autocomplete="off" formControlName="password" [errorStateMatcher]="matcher" />
          <mat-error *ngIf="passwordForm.controls.password.hasError('required')">
            This field is <strong>required</strong>
          </mat-error>
          <mat-error *ngIf="passwordForm.controls.password.hasError('error')">
            {{passwordForm.controls.password.errors.error}}
          </mat-error>
        </mat-form-field>
        <!-- /password -->
      </section>

      <div class="form-actions">
        <button mat-flat-button color="primary" (click)="updatePassword()">Update password</button>
      </div>

    </form>

  </section>

</ng-container>

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { Project, Site } from 'src/app/shared/models';
import { AppService, SiteService } from 'src/app/shared/services';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/shared/components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SiteCreateComponent } from '../site-create/site-create.component';
import { ManageSiteMembersComponent } from '../manage-site-members/manage-site-members.component';
import { SiteAlignmentsComponent } from '../site/stations/site-alignments/site-alignments.component';

@Component({
  selector: 'app-project-sites',
  templateUrl: './project-sites.component.html',
  styleUrls: ['./project-sites.component.scss'],
})
export class ProjectSitesComponent implements OnChanges {
  public loading = 0;
  public initialized = false;

  @Input()
  public project: Project;

  @Input()
  public pageSize = 20;

  public sites: Site[] = [];
  public newSite: Site; // for acl purpose
  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: this.pageSize,
  };

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private appSrv: AppService,
    private siteService: SiteService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.project && changes.project.currentValue) {
      this.pagination.pageSize = this.pageSize;
      this.getSites();
    }
  }

  getSites() {
    this.siteService.getRecords(this.project, this.pagination, { include: 'createdBy' }).subscribe({
      next: (resp: any) => {
        this.sites = resp.result;
        this.newSite = new Site({ project_id: this.project.id }); // for acl purpose
        this.sites.map(s => (s.project_id = this.project.id));
        this.pagination.length = resp.meta.totalRecords || 0;
        this.initialized = true;
      },
      error: (err: any) => {
        if (err.status === 403) {
          this.router.navigate(['/page-404'], { queryParams: { url: this.router.url } });
          return;
        }

        this.snackBar.open(err.error?.error || err.message, '', { duration: 5000 });
      },
    });
  }

  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.getSites();
  }

  /**
   * Deletes a site
   * @param site Site
   */
  delete(site: Site) {
    const message = `Deleting ${site.name} cannot be undone.
      <br />Proceed to delete?`;
    const dialogData = new ConfirmDialogModel('Delete?', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }

      this.siteService.delete(site).subscribe({
        next: (resp: any) => {
          this.sites.splice(
            this.sites.findIndex(o => o.id === site.id),
            1
          );
          this.pagination.length--;
          this.snackBar.open(resp.message, '', { duration: 3000 });
        },
        error: (err: any) => {
          this.snackBar.open(err.error.error, '', { duration: 5000 });
        },
      });
    });
  }

  /**
   * Opens dialog for EditView
   * @param site Site
   */
  openEdit(site: Site) {
    const sidebarRef = this.appSrv.openSidebar(SiteCreateComponent, {
      data: site,
      editable: true,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (!(resp instanceof Site)) {
        return;
      }

      // object might've changed, update list
      resp.project_id = this.project.id; // for acl purposes
      const i = this.sites.findIndex(s => s.id === resp.id);
      if (i >= 0) {
        // update existing site
        this.sites.splice(i, 1, resp);
      } else {
        // add new site at the end
        this.sites.push(resp);
      }
    });
  }

  /**
   * Opens manage-site-members in dialog
   * @param site Site
   */
  openManageSiteMembers(site: Site) {
    this.dialog.open(ManageSiteMembersComponent, {
      disableClose: true,
      data: site,
    });
  }

  openStations(site: Site) {
    const sidebarRef = this.appSrv.openSidebar(SiteAlignmentsComponent, {
      data: site,
    });
  }
}

import { BaseModel } from './base-model';
import { Account } from './account';

export class Activity extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Activity'; }

  /* tslint:disable */
  change: any;
  entity_type: string;
  type: string;
  createdBy: Account;

  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
    };
  }

}

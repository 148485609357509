const { version, name } = require('../../package.json');
export const environment = {
  production: false,
  API_ENDPOINT: 'http://beta-api.sitenotesapp.com',
  ESRI_MAPS_API_KEY: 'AAPTxy8BH1VEsoebNVZXo8HurKtgi3n7C2PMMvf-uA_dStXSGDXgR-91lQEn_hM5hAYfu4HaounyKZkzjdBd9a_RpmiYar3JGV41mgjp2CBd1-jlNSuYjZPcR8112-5wtahhta8ymzBQnM0wCDaT3TOw4SHj9U0pA-Hsi_qWUnAIwpqGKpGINkMsfVY-1VNONbTYBgggvu4Jeh6E25R_bC_znRKyQ1OGM5fZ5j7CY5aKKM-ioNZytZ78n9ndB8ekoxxyAT1_H6asjURI',
  GOOGLE_MAPS_API_KEY: 'AIzaSyAh3sGhzoV8YDk4PnLHEiq5CKQfzhBaPMk',
  PDFTRON_LICENSE_KEY: 'SITENOTES INC:OEM:SiteNotes::B+:AMS(20220228):D6576C021FD7F4D0A33352184F716F2F2292EC45067239098DD78375567931F5C7',
  PDFTRON_SERVER_URL: 'https://pdftron.sitenotesapp.com',
  SENTRY_DSN: '',
  AUTOSAVE_DEBOUNCE: 1500,
  APP_VERSION: version,
  APP_NAME: name,
};

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import * as moment from 'moment';
import { Attachment } from 'src/app/shared/models';
import { AppErrorStateMatcher, AppService } from 'src/app/shared/services';
import { MaterialTestInspection, SiteMaterialTest } from '../../models';
import { SiteMaterialTestService } from '../../services';

@Component({
  selector: 'app-smt-record',
  templateUrl: './smt-record.component.html',
  styleUrls: ['./smt-record.component.scss']
})
export class SmtRecordComponent implements OnInit {

  @Input() public smt: SiteMaterialTest;
  @Input() editable: boolean = false;
  @Output() public dataChange: EventEmitter<any> = new EventEmitter<any>();

  public loading: number = 0;
  public initialized: boolean = false;
  public editing = true;

  public materialTestInspection: MaterialTestInspection;

  public matcher = new AppErrorStateMatcher();
  public inputForm: UntypedFormGroup = this.fb.group({
    arrival_time: ['', [Validators.required]],
    departure_time: ['', [Validators.required]],
    break_duration: ['', [Validators.required]],
    comment: [''],
  });

  constructor(
    public appSrv: AppService,
    private smtSrv: SiteMaterialTestService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {

    this.materialTestInspection = this.smt.inspection_detail || new MaterialTestInspection({});
    this.toReactiveForm(this.materialTestInspection, this.inputForm);

    this.initialized = true;
    this.editing = this.editable;

  }

  /**
   * Assign base model to FormGroup
   * @param model MaterialTestInspection
   * @param form FormGroup
   */
  toReactiveForm(model: MaterialTestInspection, form: UntypedFormGroup): void {
    form.controls.arrival_time.setValue(moment(model.arrival_time).format('HH:mm'));
    form.controls.departure_time.setValue(moment(model.departure_time).format('HH:mm'));
    form.controls.break_duration.setValue(moment.duration(model.break_duration).asMinutes());
    form.controls.comment.setValue(model.comment);
  }

  /**
   * Attach uploaded attachment to pictures
   * @param attachment Attachment
   */
  onUploadComplete(attachments: Attachment[]) {
    attachments.map(a => this.materialTestInspection.pictures.push(a));
  }

  /**
   * Remove pic from list
   * @param pic Attachment
   */
  removePicture(pic) {
    this.materialTestInspection.pictures.splice(this.materialTestInspection.pictures.findIndex(p => p.id === pic.id), 1);
  }

  /**
   * Update model
   */
  saveItem() {

    if (!this.inputForm.valid) {
      return;
    }

    // Convert dates and durations to ISO formats
    let { arrival_time, departure_time, break_duration } = this.inputForm.value;
    break_duration = moment.duration(break_duration, 'minutes').toISOString();
    arrival_time = moment(this.smt.request_date.format('YYYY-MM-DD')).add(moment.duration(arrival_time));
    departure_time = moment(this.smt.request_date.format('YYYY-MM-DD')).add(moment.duration(departure_time));

    this.materialTestInspection = Object.assign(this.materialTestInspection, this.inputForm.value, {
      break_duration,
      arrival_time,
      departure_time,
    });
    const payload = this.materialTestInspection.toPayload();
    this.loading++;
    this.smtSrv.recordLabActivity(this.smt, payload)
      .then((smt: SiteMaterialTest) => {
        this.smt = smt;
        this.toReactiveForm(smt.inspection_detail, this.inputForm);
        this.snackBar.open('Saved lab activity', '', { duration: 5000 });
        this.dataChange.emit(this.smt);
      })
      .catch((resp: HttpErrorResponse) => {
        if (resp.status === 422) {
          this.matcher.setServerErrors(this.inputForm, resp);
          return;
        }
        this.snackBar.open(resp.error?.error || 'Oops! something went wrong.', '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

}

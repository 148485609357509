<div class="wrapper">
  <div class="header-section flex gap-[12px] w-full">
    <div class="left w-2/12">
      <div class="avatar">
        {{ issue?.createdBy?.first_name[0] }}
      </div>
    </div>
    <div class="right w-10/12">
      <div class="heading text-type-6 w-full">
        {{ issue?.title }}
      </div>
      <div class="subheading flex gap-[12px] text-type-14 w-full">
        <div class="name">
          {{
            issue?.createdBy?.first_name + (issue?.createdBy?.last_name ? ' ' + issue?.createdBy?.last_name[0] + '.' :
              '')
          }}
        </div>
        <div class="last-updated-at">
          {{ issue?.updated_at | dateFormat: 'ago-tiny' }}
        </div>
      </div>
    </div>
  </div>
  <div class="content text-type-10">
    {{ issue?.description }}
  </div>
  <div class="site-details flex flex-row justify-start items-center mb-[10px]">
    <div class="label uppercase text-type-13">
      Site:
    </div>
    <div class="site text-type-14 description-text mb-[2px] ml-[5px]">
      {{ issue?.entity?.name }}
    </div>
  </div>
  <div class="status-section flex gap-[20px]">
    <div class="issue-type text-type-14">{{ issueTypesLabelMap[issue?.type] }}</div>
    <div [ngClass]="issue?.status" class="status text-type-12">
      <mat-icon [svgIcon]="issue?.status + '-issues'" />
      {{ issue?.status }}
    </div>
  </div>
</div>

<ng-container *ngIf="initialized">

  <div class="row">
    <div>&nbsp;</div>
    <div class="icons">
      <button mat-icon-button [disabled]="!('create' | able: newInternalTest)" (click)="openEdit(newInternalTest)"
        title="Add internal test" color="accent">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-body-strong" *ngIf="!internalTests.length">
    Found no internal tests for this project.
    <a class="mat-inline-button" color="accent" *ngIf="('create' | able: newInternalTest)"
      (click)="openEdit(newInternalTest)" aria-label="Add internal test" title="Add internal test">Add internal test?
    </a>
  </div>

  <!-- list -->
  <div class="list">
    <div class="list-card" *ngFor="let internalTest of internalTests">

      <!-- actions -->
      <div class="hover-icons">
        <a mat-mini-fab [disabled]="!('update' | able: internalTest)" (click)="openEdit(internalTest)" color="accent"
          aria-label="Edit {{internalTest.name}}" title="Edit {{internalTest.name}}">
          <mat-icon>edit</mat-icon>
        </a>

        <button mat-mini-fab [disabled]="!('delete' | able: internalTest)" (click)="delete(internalTest)" color="warn"
          aria-label="Delete {{internalTest.name}}" title="Delete {{internalTest.name}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <!-- /actions -->

      <div class="body">
        <div class="mat-title row">
          <div class="mat-body-strong">
            {{internalTest.name}}
            <span class="mat-caption" *ngIf="internalTest.record_by_station">(by station)</span>
          </div>
          <div>
            <div>
              <span class="mat-body-strong">Fields: </span>
              <ng-container *ngIf="!internalTest?.fields?.length">None</ng-container>
            </div>
            <div *ngIf="internalTest?.fields?.length">
              <div *ngFor="let field of internalTest?.fields">
                <span class="mat-body-strong">{{field.label}}</span>
                <span class="mat-caption"> ({{field.type}})</span>
              </div>
            </div>
          </div>
          <div>
            <div>
              <span class="mat-body-strong">Bid items: </span>
              <ng-container *ngIf="!internalTest?.bid_items?.length">None</ng-container>
            </div>
            <div *ngIf="internalTest?.bid_items?.length">
              <div *ngFor="let bidItem of internalTest?.bid_items">{{bidItem.item}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./list -->

  <mat-paginator (page)="pageChanged($event)" *ngIf="initialized && pagination.length && internalTests.length"
    [length]="pagination.length" [pageIndex]="pagination.pageIndex" [pageSize]="pagination.pageSize">
  </mat-paginator>

</ng-container>
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ProjectMapService } from '../../../../project-map.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  ScrollService
} from '../../../../../../../shared/services/scroll.service';

@Component({
  selector: 'app-map-sidenav-bid-item-details',
  templateUrl: './details.component.html',
  styleUrls: ['../../styles.css', './details.component.scss'],
})
export class MapSidenavBidItemDetailsComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();
  @Input() data: any;

  description = '';

  quantityUsedPercent = 0;
  quantityUsedLabel = '';

  headingDetails: any = null;

  isExpanded = false;

  stationsList = [];
  linesList = [];
  fieldsList = [];

  sectionsList = [];

  constructor(
    private projectMapService: ProjectMapService,
    private scrollService: ScrollService
  ) {
  }

  ngOnInit() {
    this.description = this.data?.bid_item?.description || '';

    this.quantityUsedPercent = this.getQuantityUsedPercent();
    this.quantityUsedLabel = this.getQuantityUsedLabel();

    this.headingDetails = this.data?.heading;

    if (this.data.children) {
      this.data?.children?.forEach(bidItem => {
        if (bidItem.station) {
          this.stationsList = [
            ...this.stationsList,
            {
              id: bidItem.id,
              heading: bidItem.station?.name,
              orderBy: Number(bidItem.station?.name?.replace(/\+/g, '')) || 0,
              fields: this.getFields(bidItem.bid_item?.fields, bidItem.field_values),
              isExpanded: false,
              comment: bidItem.comment,
              pictures: bidItem.pictures,
              ...(this.data?.heading?.is_circular ? { isStartOfWork: bidItem.start_of_work } : {}),
            },
          ];
        } else {
          this.linesList = [
            ...this.linesList,
            {
              id: bidItem.id,
              heading: moment(bidItem.created_at)
                .format('MMMM DD, YYYY, hh:mm a'),
              orderBy: moment(bidItem.created_at),
              fields: this.getFields(bidItem.bid_item?.fields, bidItem.field_values),
              isExpanded: false,
              comment: bidItem.comment,
              pictures: bidItem.pictures,
            },
          ];
        }
      });

      if (this.data?.heading?.direction) {
        if (this.data?.heading?.direction === 'desc') {
          this.stationsList = this.stationsList.sort((a, b) => b.orderBy - a.orderBy);
        } else {
          this.stationsList = this.stationsList.sort((a, b) => a.orderBy - b.orderBy);
        }
      }

      this.linesList = this.linesList.sort((a, b) => a.orderBy - b.orderBy);


      if (this.stationsList.length) {
        this.sectionsList = [...this.stationsList];
      } else {
        this.sectionsList = [...this.linesList];
      }
    } else {
      this.fieldsList = this.getFields(this.data?.bit_item?.fields, this.data?.field_values);
    }

    this.projectMapService.selectedMarkerData
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        setTimeout(() => {
          this.scrollService.scrollToElementById(data.id.trim());
        }, 200);

        this.sectionsList = this.sectionsList.map(section => {
          section.isExpanded = section.id === data.id;
          return section;
        });
      });
  }

  getQuantityUsedPercent() {
    try {
      let value = (this.data?.quantity / this.data?.bid_item?.quantity) * 100;

      if (!value) {
        value = 0;
      } else if (value > 100) {
        value = 100;
      }

      value = Number(value.toFixed(2));

      this.quantityUsedPercent = value;
    }
    catch (e) {
      return 0;
    }
  }

  getQuantityUsedLabel() {
    const numerator = `${Number(this.data?.quantity?.toFixed(2))}${this.data?.bid_item?.uom}`;
    const denominator = `${Number(this.data?.bid_item?.quantity?.toFixed(2))}${this.data?.bid_item?.uom}`;

    return `${numerator} / ${denominator}`;
  }

  getFields(fields = [], fieldValues = []) {
    let fieldsList = [];
    fieldValues.map(o => {
      const currentField = fields.find(i => o.id === i.id);

      if (currentField) {
        fieldsList = [
          ...fieldsList,
          {
            label: currentField.label,
            uom: currentField.uom,
            hint: currentField.hint,
            value: o.value,
          },
        ];
      }
    });

    return fieldsList;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<div class="wrapper flex flex-col">
  <div class="heading text-type-2">Weather</div>
  <app-weather-details-form
    [data]="data"
    class="weather-form mb-[40px]"
  />
  <app-actions-section
    [disableAddMenu]="disableAddMenu"
    [disableOptionMenu]="disableOptionMenu"
    class="actions-section w-[455px]"
  />
</div>

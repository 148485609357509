<ng-container *ngIf="initialized">
  <div class="title">{{smt.bid_item?.item}}</div>
  <div class="caption">{{smt.material_test?.name}}</div>
  <div [ngSwitch]="smt.status" class="mat-body">
    <ng-container *ngSwitchCase="'draft'">Draft by {{smt.createdBy?.name}}</ng-container>
    <ng-container *ngSwitchCase="'sent_to_lab'">Request sent to lab</ng-container>
    <ng-container *ngSwitchCase="'lab_scheduled'">
      Lab visit on {{smt.request_date | dateFormat : 'MMMM DD'}}
    </ng-container>
    <ng-container *ngSwitchCase="'lab_activity_completed'">Waiting for lab report</ng-container>
    <ng-container *ngSwitchCase="'updated_report'">Lab report uploaded</ng-container>
    <ng-container *ngSwitchCase="'passed'">
      <mat-icon style="color: green;">done</mat-icon>  Passed
    </ng-container>
    <ng-container *ngSwitchCase="'failed'">
      <mat-icon style="color: red;">error_outline</mat-icon> Failed
    </ng-container>
    <ng-container *ngSwitchCase="'cancelled'">
      <mat-icon style="color: orange;">close</mat-icon> Cancelled
    </ng-container>
  </div>
</ng-container>

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { BidItem, Material, Project } from '../models';
import { HttpResponse } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class MaterialService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Returns Materials with pagination
   * @param pagination start
   * @param qp query params
   * @returns {Promise<any>} {meta, result: Material[]}
   */
  getRecords(project: Project, pagination, qp: any = {}) {
    qp.start = pagination.pageIndex * pagination.pageSize;
    qp.total = pagination.pageSize;

    return this.appHttpService
      .getService(`/v2/project/${project?.id}/materials`, qp)
      .toPromise()
      .then(resp => {
        if (resp.data && Array.isArray(resp.data)) {
          const result = resp.data.map(r => new Material(r));
          const meta = Object.assign(resp.meta || {}, { type: resp.type });
          return { result, meta };
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Creates a new Material
   * @param model Material
   * @param project Project
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Material>}
   */
  create(model: Material, project: Project = null, payload: any = null, qp: any = {}): Promise<Material> {
    const body = payload || model.toPayload();

    return this.appHttpService
      .postService(`/v2/project/${project?.id}/material`, body, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Material(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Updates Material
   * @param model Material
   * @param payload json payload
   * @param qp query params
   * @returns {Promise<Material>}
   */
  update(model: Material, payload: any = null, qp: any = {}): Promise<Material> {
    const body = payload || model.toPayload();

    return this.appHttpService
      .putService(`/v2/material/${model?.id}`, body, qp)
      .toPromise()
      .then((resp: any) => {
        if (resp.data) {
          return new Material(resp.data);
        }
        throw new Error('Error parsing response');
      });
  }

  /**
   * Deletes Material
   * @param model Material
   * @returns {Promise<any>} {status, message}
   */
  delete(model: Material): Promise<HttpResponse<any>> {
    return this.appHttpService
      .deleteService(`/v2/material/${model?.id}`)
      .toPromise();
  }

  /**
   * Add material to biditem
   * @param material Material
   * @param biditem Biditem
   * @returns {Promise<string>} message
   */
  addBidItem(material: Material, biditem: BidItem): Promise<string> {
    return this.appHttpService
      .putService(`/v2/material/${material?.id}/bid-item/${biditem.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }

  /**
   * Remove material to biditem
   * @param material Material
   * @param biditem Biditem
   * @returns {Promise<string>} message
   */
  removeBidItem(material: Material, biditem: BidItem): Promise<string> {
    return this.appHttpService
      .deleteService(`/v2/material/${material?.id}/bid-item/${biditem.id}`, {})
      .toPromise()
      .then((resp: any) => resp?.message);
  }
}

import { Injectable } from '@angular/core';
import { AppHttpService } from './app-http.service';
import { Annotation, Drawing } from '../models';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AnnotationService {

  constructor(
    private appHttpService: AppHttpService
  ) {}

  /**
   * Updates Annotation
   * @param id Annotation.id
   * @param xfdf string
   * @param file blob of pdf file
   * @param qp query-params
   */
  update(id, xfdf, file = null, qp = {}, opts: any = {}) {
    const payload = {
      xfdf,
      inputFile: file,
      group_annotation_id: opts.masterAnnotId || null,
    };
    return this.appHttpService.sendMultipartData('PUT', `/v1/annotation/${id}`, payload, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Annotation(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }

  /**
   * Updates Annotation
   * @param id Annotation.id
   * @param xfdf string
   * @param file blob of pdf file
   * @param qp query-params
   */
  add(drawing: Drawing, xfdf, file = null, qp = {}, opts: any = {}) {
    const payload = {
      xfdf,
      inputFile: file,
      group_annotation_id: opts.masterAnnotId || null,
    };
    return this.appHttpService.sendMultipartData('POST', `/v1/annotation/${drawing.id}`, payload, qp).pipe(
      map((resp: any) => {
        if (resp.data) {
          return new Annotation(resp.data);
        }
        throw new Error('Error parsing response');
      })
    );
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-daily-report-expandable-block',
  templateUrl: './daily-report-expandable-block.component.html',
  styleUrls: ['./daily-report-expandable-block.component.scss']
})
export class DailyReportExpandableBlockComponent implements OnInit, OnDestroy {
  private readonly onDestroy: Subject<any> = new Subject<any>();

  @Input() icon = '';
  @Input() heading = '';
  @Input() subheading = '';
  @Input() isExpanded = false;

  constructor() {
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

<div class="wrapper">
  <form>
    <app-dropdown
      [enableAddItem]="true"
      [enableSearch]="true"
      [options]="laborOptions"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="type"
      label="Labor"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['type']"
    />
    <app-dropdown
      [options]="subContractorOptions"
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="sub_contractor"
      label="SubContractor"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['sub_contractor']"
      [enableClearSelection]="true"
    />
    <app-number-input
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="quantity"
      label="quantity"
      (checkForErrors)="checkForErrors($event)"
      [error]="errorMessages['quantity']"
    />
    <div class="flex flex-row justify-center items-center mb-[20px] gap-[20px] w-full">
      <app-number-input
        [parentFormGroup]="form"
        class="w-1/2"
        controlName="duration"
        label="hours"
      />
      <app-number-input
        [parentFormGroup]="form"
        class="w-1/2"
        controlName="idle_duration"
        label="Hours Idle"
      />
    </div>

    <app-text-area
      [parentFormGroup]="form"
      class="mb-[20px]"
      controlName="comment"
      label="comment"
    />

  </form>
</div>

import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-site-report-card',
  templateUrl: './site-report-card.component.html',
  styleUrls: ['./site-report-card.component.scss'],
})
export class SiteReportCardComponent {
  @Input() report: any;
  @Input() siteId: any;
  moment = moment;

  projectId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
  }
}

import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import { projectMembersFilterList, sitesFilterList } from '../../../../../core/projects.selectors';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../../../../../../state/app.state';
import { filter, takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-project-details-page-status-site-filter',
  templateUrl: './status-site-filter.component.html',
  styleUrls: ['../styles.scss', './status-site-filter.component.scss'],
})
export class StatusSiteFilterComponent implements OnInit, OnChanges, OnDestroy {
  @Input() projectId: string;

  isStatusAllChecked = false;
  isSitesAllChecked = false;
  isCreatorsAllChecked = false;
  buttonLabel = 'Filters (0)';
  isOpen = false;
  sitesOptions = [];
  creatorOptions = [];

  statusList = [];
  sitesList = [];
  creatorsList = [];

  statusOptions = [
    {
      label: 'Awaiting Submission',
      value: 'draft',
      isChecked: false,
    },
    {
      label: 'Awaiting Approval',
      value: 'submitted-reviewed',
      isChecked: false,
    },
    {
      label: 'Approved',
      value: 'approved',
      isChecked: false,
    },
    {
      label: 'Rejected',
      value: 'rejected',
      isChecked: false,
    },
  ];

  searchForm = new FormGroup({
    statusSearch: new FormControl(''),
    sitesSearch: new FormControl(''),
    creatorsSearch: new FormControl(''),
  });

  private readonly onDestroy: Subject<any> = new Subject<any>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.route.queryParams
      .pipe(takeUntil(this.onDestroy))
      .subscribe(qp => {
        if (qp.status) {
          if (Array.isArray(qp.status)) {
            this.statusList = qp.status;
          } else {
            this.statusList = [qp.status];
          }

          this.statusOptions.forEach(o => {
            o.isChecked = this.statusList.includes(o.value);
          });

          this.isStatusAllChecked = this.statusList.length === this.statusOptions.length;
        } else {
          this.statusList = [];
        }

        if (qp.site) {
          if (Array.isArray(qp.site)) {
            this.sitesList = qp.site;
          } else {
            this.sitesList = [qp.site];
          }

          this.sitesOptions.forEach(o => {
            o.isChecked = this.sitesList.includes(o.value);
          });

          this.isSitesAllChecked = this.sitesList.length === this.sitesOptions.length;
        } else {
          this.sitesList = [];
        }

        if (qp.creator) {
          if (Array.isArray(qp.creator)) {
            this.creatorsList = qp.creator;
          } else {
            this.creatorsList = [qp.creator];
          }

          this.creatorOptions.forEach(o => {
            o.isChecked = this.creatorsList.includes(o.value);
          });

          this.isCreatorsAllChecked = this.creatorsList.length === this.creatorOptions.length;
        } else {
          this.creatorsList = [];
        }

        this.generateLabel();
      });

    this.store.select(projectMembersFilterList)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(list => {
        this.creatorOptions = JSON.parse(JSON.stringify(list));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.projectId) {
      this.store.select(sitesFilterList, { projectId: this.projectId })
        .pipe(filter(o => !!o))
        .subscribe(list => {
          this.sitesOptions = JSON.parse(JSON.stringify(list));
        }).unsubscribe();
    }
  }

  onStatusCheckboxChange(event) {
    this.router.navigate([], {
      queryParams: {
        status: event,
      },
      queryParamsHandling: 'merge',
    });
  }

  onSiteCheckboxChange(event) {
    this.router.navigate([], {
      queryParams: {
        site: event,
      },
      queryParamsHandling: 'merge',
    });
  }

  onCreatorCheckboxChange(event) {
    this.router.navigate([], {
      queryParams: {
        creator: event,
      },
      queryParamsHandling: 'merge',
    });
  }

  generateLabel() {
    const statusCount: any = this.statusList?.length ? 1 : 0;
    const sitesCount: any = this.sitesList?.length ? 1 : 0;
    const creatorsCount: any = this.creatorsList?.length ? 1 : 0;

    const totalFilters = statusCount + sitesCount + creatorsCount;

    this.buttonLabel = `Filter${totalFilters !== 1 ? 's' : ''} (${totalFilters})`;
  }

  ngOnDestroy() {
    this.onDestroy.next(null);
    this.onDestroy.complete();
  }
}

import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { Attachment } from '../../models';
import SwiperCore, { Keyboard, Lazy, Navigation, Pagination } from 'swiper/core';
import { saveAs } from 'file-saver';

SwiperCore.use([Navigation, Pagination, Keyboard, Lazy]);

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  @Input() public attachments: Attachment[] = [];
  @Input() public initialSlide = 0;
  @Input() public isDialog = true;
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public dialogRef: MatDialogRef<CarouselComponent>,
    @Inject(MAT_DIALOG_DATA) public inputData: any,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    if (this.isDialog) {
      const { attachments, initialSlide } = this.inputData;
      this.attachments = attachments;
      this.initialSlide = initialSlide;
    }
  }

  download(attachment: Attachment) {
    saveAs(
      attachment.original_url || attachment.preview,
      attachment.original_name || 'file',
    );
  }

  close(event) {
    if (this.onClose) {
      event.stopPropagation();
      this.onClose.emit();
    }
  }
}

import { BaseModel } from './base-model';
import { Account } from './account';
import { Site } from './site';
import { Project } from './project';
import { ReportBidItem } from './report-bid-item';
import { ReportMaterialDelivery } from './report-material-delivery';
import * as moment from 'moment';
import { Issue } from './issue';
import { ReportActivity } from './report-activity';
import { Weather } from './weather';
import { ReportLabor } from './report-labor';
import { ReportEquipment } from './report-equipment';
import { WorkflowLineage } from './workflow-lineage';
import { ReportInternalTest } from './report-internal-test';
import { MaterialTest } from './material-test';

export class DailyReport extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'DailyReport'; }

  /* tslint:disable */
  report_date: Date|any;
  weather: Weather;
  work_condition_code: number;
  work_condition_comment: string;
  status: string;
  site: Site;
  project: Project;
  createdBy: Account;
  bid_items: ReportBidItem[];
  material_deliveries: ReportMaterialDelivery[];
  internal_tests: ReportInternalTest[];
  issues: Issue[];
  activities: ReportActivity[];
  labor: ReportLabor[];
  equipment: ReportEquipment[];
  lastApprovalAction: WorkflowLineage;
  external_tests?: MaterialTest[];

  /* for acl */
  project_id: string;
  site_id: string;
  /* tslint:enable */

  get relations() {
    return {
      createdBy: Account,
      site: Site,
      project: Project,
      bid_items: ReportBidItem,
      material_deliveries: ReportMaterialDelivery,
      internal_tests: ReportInternalTest,
      issues: Issue,
      activities: ReportActivity,
      weather: Weather,
      labor: ReportLabor,
      equipment: ReportEquipment,
      lastApprovalAction: WorkflowLineage,
    };
  }

  /**
   * Workaround to initialize default values
   */
  init() {
    this.status = 'draft';
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);

    // cast
    this.report_date = this.report_date ? moment(this.report_date) : null;

    // for acl
    if (this.project?.id) {
      this.project_id = this.project.id;
    }
    this.site_id = this.site?.id || this.site_id;

    if (this.weather instanceof Weather) {
      this.weather.site_id = this.site_id;
      this.weather.daily_report_id = this.id;
    }
  }

  /**
   * Converts this model to POST payload json object
   * @param include boolean Include line-items or not
   * @return {object}
   */
  toPayload(include: boolean = false) {
    const payload: any = {
      id: this.id || null,
      report_date: this.report_date.format('YYYY-MM-DD'), // || today
      work_condition_code: this.work_condition_code || 999,
      work_condition_comment: this.work_condition_code === 1004 ? this.work_condition_comment: null,
    };

    if (include) {
      payload.weather = this.weather || null;
      payload.bid_items = (this.bid_items || []).map(o => o.toPayload());
      payload.material_deliveries = (this.material_deliveries || []).map(o => o.toPayload());
      payload.labor = (this.labor || []).map(o => o.toPayload());
      payload.equipment = (this.equipment || []).map(o => o.toPayload());
      payload.activities = (this.activities || []).map(o => o.toPayload());
    }

    return payload;
  }
}

import { Injectable, Type } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppHttpService } from './app-http.service';
import weatherConditions from './weather-conditions';
import * as moment from 'moment';
import { Attachment } from '../models';
import { CarouselComponent } from '../components/carousel/carousel.component';

@Injectable({ providedIn: 'root' })
export class AppService {
  private sideBarRef: any;
  private modalRef: any;
  private appData: any[];

  constructor(private appHttpService: AppHttpService, private dialog: MatDialog) {
  }

  /**
   * Opens given component in sidebar
   * @param component Any component
   * @param data data to be sent via attributes
   * @return {Observable<any>} close reason|object
   */
  openSidebar(component: Type<any>, data: any) {
    if (this.sideBarRef) {
      this.sideBarRef.close(null);
    }

    const options = Object.assign(
      {
        closable: true,
      },
      data?.options,
    );
    data.options = options;

    this.sideBarRef = this.dialog.open(component, {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      height: '100%',
      width: '535px',
      position: { right: '0', top: '0', bottom: '0' },
      data,
    });
    return this.sideBarRef;
  }

  getWeatherCondition(code = null) {
    if (!code) {
      return weatherConditions;
    }

    return weatherConditions.find(o => o.code === code);
  }

  /**
   * Opens given component in Modal
   * @param component Any component
   * @param data data to be sent via attributes
   */
  openModal(component: Type<any>, data: any) {
    if (this.modalRef) {
      this.modalRef.close(null);
    }

    // const modalRef = this.dialog.open(ModalComponent, {
    //   hasBackdrop: true,
    //   data: new ModalItem(component, data),
    // });

    const options = Object.assign(
      {
        closable: true,
      },
      data?.options,
    );
    data.options = options;

    this.modalRef = this.dialog.open(component, {
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      width: '80%',
      height: '80vh',
      data,
    });
    return this.modalRef;
  }

  /**
   * Fetches Appdata
   * @param refetch boolean
   * @return Promise<any>
   */
  getAppdata(refetch: boolean = false) {
    return new Promise((resolve, reject) => {
      if (this.appData && !refetch) {
        resolve(this.appData);
        return;
      }

      this.appHttpService
        .getService('/v1/appdata')
        .toPromise()
        .then(resp => {
          this.appData = resp.data;
          resolve(this.appData);
        });
    });
  }

  /**
   * google map styles
   * @returns array
   */
  getMapStyles() {
    return [
      { elementType: 'geometry', stylers: [{ color: '#d8ddeb' }] },
      { elementType: 'labels.text.fill', stylers: [{ color: '#7c8ab3' }] },
      {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{ color: '#f8f9fb' }],
      },
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#bac0d8' }],
      },
      // hide all point-of-interest
      { featureType: 'poi', stylers: [{ visibility: 'off' }] },
      // { featureType: 'poi.business', stylers: [{ visibility: 'off' }]},
      // { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }]},
      // { featureType: 'poi.medical', stylers: [{ visibility: 'off' }]},
    ];
  }

  /**
   * Returns last n dates
   * @param n number of dates to retrun
   * @param from limit/crop from date
   * @param to to/crop to date
   * @returns {moment.Moment[]}
   */
  getLastNDates(n: number = 2, from: any = null, to: any = null): moment.Moment[] {
    let start: moment.Moment = moment(); // today

    if (to.isBefore(start, 'day')) {
      start = to;
    } else if (from.isAfter(start, 'day')) {
      start = moment(from).add(n - 1, 'd');
    }

    const dates = [...Array(n)].map((v, i) => moment(start).subtract(i, 'd'));

    return dates;
  }

  /**
   * Returns near n dates around pivot
   * @param n number of dates on left and right of pivot
   * @param pivot center/pivot date
   * @param low lower date
   * @param high upper date
   * @returns {moment.Moment[]}
   */
  getNearDates(n: number = 2, pivot: moment.Moment, low: moment.Moment, high: moment.Moment): moment.Moment[] {
    let hDays = high.diff(pivot, 'days'),
      lDays = pivot.diff(low, 'days'),
      hCount = hDays >= n ? n : hDays < 0 ? 0 : hDays,
      lCount = lDays >= n ? n : lDays < 0 ? 0 : lDays,
      dates = [pivot];

    if (lCount < n) {
      hCount += n - lCount;
    }
    if (hCount < n) {
      lCount += n - hCount;
    }

    [...Array(lCount)].map((v, i) => {
      dates.unshift(moment(pivot).subtract(i + 1, 'd'));
    });
    [...Array(hCount)].map((v, i) => {
      dates.push(moment(pivot).add(i + 1, 'd'));
    });

    return dates;
  }

  getDatesBetween(from: any, to: any, format: string = 'YYYY-MM-DD') {
    to = moment(to);
    from = moment(from);
    const dates = [];
    for (let i = 0; i < to.diff(from, 'days') + 1; ++i) {
      dates.push(moment(from).add(i, 'd').format(format));
    }
    return dates;
  }

  /**
   * Opens attachment in carousel
   * @param attachments Attachment[]
   * @param current Attachment
   */
  openCarousel(e: Event, attachments: Attachment[], current: Attachment = null) {
    e.stopPropagation();
    let initialSlide = 0;
    if (current) {
      const i = attachments.findIndex(o => o.id === current.id);
      initialSlide = i >= 0 ? i : 0;
    }

    const dialogRef = this.dialog.open(CarouselComponent, {
      disableClose: false,
      data: {
        attachments,
        initialSlide,
        options: {},
      },
    });
  }
}

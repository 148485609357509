import { BaseModel } from './base-model';

export class WorkflowAction extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'WorkflowAction'; }

  /* tslint:disable */
  state: string;
  wf: string;
  ex: string;
  project_id: string;
  options: any;
  /* tslint:enable */
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Project } from 'src/app/shared/models';
import { AppService } from 'src/app/shared/services';
import { Invitation } from '../../invitation';
import { InvitationService } from '../../invitation.service';
import { ProjectInviteCreateComponent } from '../project-invite-create/project-invite-create.component';

@Component({
  selector: 'app-project-invites',
  templateUrl: './project-invites.component.html',
  styleUrls: ['./project-invites.component.scss']
})
export class ProjectInvitesComponent implements OnInit, OnChanges {

  @Input() public project: any;

  public initialized: boolean = false;
  public loading: number = 0;
  public invitations: Invitation[] = [];
  public newInvite: Invitation;

  public pagination = {
    pageIndex: 0,
    length: 0,
    pageSize: 20,
  };

  constructor(
    private appSrv: AppService,
    private invSrv: InvitationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.project && changes.project.currentValue) {

      this.fetchRecords(changes.project.currentValue);
      this.newInvite = new Invitation({ project: changes.project.currentValue});
    }
  }

  ngOnInit() {
    this.initialized = true;
  }

  /**
   * Fetch records
   * @param project Project
   */
  fetchRecords(project: Project): void {
    this.loading++;
    const qp = {
      include: [ 'role', 'invitee', 'inviter', 'site', 'project', 'organization' ],
    };
    this.invSrv.getRecords(project, this.pagination, qp)
      .then((resp: any) => {
        this.invitations = resp.result;
        this.pagination.length = resp.meta.totalRecords || 0;
      })
      .catch((resp: HttpErrorResponse) => {
        this.snackBar.open(resp.error.error || 'Failed to fetch invitations.' , '', { duration: 5000 });
      })
      .finally(() => {
        this.loading--;
      });
  }

  /**
   * callback on pagination change
   * @param event PageEvent
   */
  pageChanged(event: PageEvent) {
    this.pagination = event;
    this.fetchRecords(this.project);
  }

  openInviteCreate(invite: Invitation = null) {
    invite = invite || new Invitation({ project_id: this.project.id });
    const sidebarRef = this.appSrv.openSidebar(ProjectInviteCreateComponent, {
      data: invite,
      project: this.project,
      editable: true,
    });

    sidebarRef.afterClosed().subscribe(resp => {
      if (resp instanceof Invitation) {
        const i = this.invitations.findIndex(o => o.id === resp.id);
        if (i >= 0) {
          this.invitations[i] = Object.assign(this.invitations[i], resp);
        } else {
          this.invitations.push(resp);
        }
        this.pagination.length++;
      }
    });
  }
}

import { CircularArray } from '../helpers';
import { Alignment } from './alignment';
import { BaseModel } from './base-model';
import { Station } from './station';

export class Heading extends BaseModel {

  // for CASL subject detection on code minification
  static get modelName() { return 'Heading'; }

  /* tslint:disable */
  name: string;
  starts_from: string;
  ends_at: string;
  direction: string;
  is_circular: boolean;
  stations: Station[];
  alignment: Alignment;

  alignment_id: string;
  alignment_name: string;
  /* tslint:enable */

  get relations() {
    return {
      stations: Station,
      alignment: Alignment,
    };
  }

  /**
   * Processes json obj and sets respective properties.
   * @param jsonObj json from api
   */
  parseJSONData(jsonObj: any) {
    super.parseJSONData(jsonObj);
  }

  getDistance(s1: Station, s2: Station) {

    const distance = new CircularArray(this.stations).slice(
      this.stations.findIndex((o) => o.id === s1?.id) + 1,
      this.stations.findIndex((o) => o.id === s2?.id) + 1,
    ).reduce((p, c) => (p + c.distance), 0);

    return distance;
  }

  sortLineItems(lis: any) {
    // sort lineitems wrt heading.stations
    const so = (this.stations || []).map(o => o.name);
    lis.sort((a, b) => {
      const i = so.indexOf(a.station?.name);
      const j = so.indexOf(b.station?.name);
      return i < j ? -1 : i > j ? 1 : 0;
    });
  }
}

<div class="container">
  <ng-container *ngIf="initialized">

    <div class="row" *ngFor="let site of sites">
      <div>
        <mat-form-field>
          <input matInput type="number" placeholder="{{site.name}} quantity" autocomplete="off"
            [(ngModel)]="site.quantity" />
        </mat-form-field>
      </div>
      <div class="icons">
        <button type="button" mat-icon-button aria-label="Save" color="none" (click)="save(site)">
          <mat-icon title="Save">save</mat-icon>
        </button>
      </div>
    </div>
  </ng-container>

</div>